import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import api from "utils/api";
import { Loader } from "atoms";
import useAuth from "contexts/Auth";
import { persist, hydrate } from "utils/persist";
import { AUTH_TOKEN_COOKIE } from "utils/api/getTokens";
import { useLocationQueryValue } from "hooks/useLocationQuery";

export const LoginWithSSO = () => {
  const token = useLocationQueryValue("t");
  const navigate = useNavigate();

  const {
    actions: { setLoggedIn },
  } = useAuth();

  useEffect(() => {
    if (token) {
      persist(AUTH_TOKEN_COOKIE, token);
      navigate({ pathname: "/dashboard", search: null }, { replace: true });
    } else if (hydrate(AUTH_TOKEN_COOKIE)) {
      // validate token before setting user as logged in
      api(`/subscriptions`)
        .then(() => {
          setLoggedIn(true, true);
          navigate({ pathname: "/", search: null }, { replace: true });
        })
        .catch(() => navigate("/login", { replace: true }));
    } else {
      navigate("/login", { replace: true });
    }
  }, [setLoggedIn, token]);

  return <Loader loading relative />;
};
