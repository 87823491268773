import * as React from "react";

function NotificationBell(props) {
  return (
    <svg
      width="17"
      height="18"
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.2554 10.2987V7.11111C14.2554 4.25156 12.3132 1.84267 9.68209 1.11822C9.42165 0.462222 8.7852 0 8.0332 0C7.2812 0 6.64476 0.462222 6.38431 1.11822C3.7532 1.84356 1.81098 4.25156 1.81098 7.11111V10.2987L0.293648 11.816C0.126536 11.9822 0.0332031 12.208 0.0332031 12.4444V14.2222C0.0332031 14.7138 0.430536 15.1111 0.922092 15.1111H15.1443C15.6359 15.1111 16.0332 14.7138 16.0332 14.2222V12.4444C16.0332 12.208 15.9399 11.9822 15.7728 11.816L14.2554 10.2987ZM14.255 13.3337H1.81055V12.8128L3.32788 11.2954C3.49499 11.1292 3.58832 10.9034 3.58832 10.667V7.11144C3.58832 4.66077 5.5821 2.66699 8.03277 2.66699C10.4834 2.66699 12.4772 4.66077 12.4772 7.11144V10.667C12.4772 10.9034 12.5705 11.1292 12.7377 11.2954L14.255 12.8128V13.3337ZM8.03312 17.7778C9.19846 17.7778 10.1727 17.0365 10.538 16H5.52824C5.89357 17.0365 6.86779 17.7778 8.03312 17.7778Z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoNotificationBellIcon = React.memo(NotificationBell);
export default MemoNotificationBellIcon;
