import React from "react";

import { Cart as TemplateCart } from "templates";
export const Cart = () => {
  return (
    <>
      <TemplateCart />
    </>
  );
};
