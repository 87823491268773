import * as React from "react";

function Calendar(props) {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9 1.2H11.4C11.5591 1.2 11.7117 1.26321 11.8243 1.37574C11.9368 1.48826 12 1.64087 12 1.8V11.4C12 11.5591 11.9368 11.7117 11.8243 11.8243C11.7117 11.9368 11.5591 12 11.4 12H0.6C0.44087 12 0.288258 11.9368 0.175736 11.8243C0.0632141 11.7117 0 11.5591 0 11.4V1.8C0 1.64087 0.0632141 1.48826 0.175736 1.37574C0.288258 1.26321 0.44087 1.2 0.6 1.2H3V0H4.2V1.2H7.8V0H9V1.2ZM10.8 4.8V2.4H9V3.6H7.8V2.4H4.2V3.6H3V2.4H1.2V4.8H10.8ZM10.8 6H1.2V10.8H10.8V6ZM2.4 7.2H5.4V9.6H2.4V7.2Z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoCalendarIcon = React.memo(Calendar);
export default MemoCalendarIcon;
