import * as React from "react";

function Email(props) {
  return (
    <svg
      width="40"
      height="30"
      viewBox="0 0 40 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.344 17.668L20.004 21.538L25.46 17.774L37.69 29.84C37.368 29.944 37.024 30 36.666 30H3.334C2.894 30 2.474 29.914 2.088 29.76L14.344 17.668V17.668ZM40 7.752V26.666C40 27.16 39.892 27.628 39.7 28.05L27.712 16.222L40 7.752ZM1.8872e-06 7.858L12.084 16.122L0.212002 27.838C0.0713934 27.4633 -0.000424467 27.0662 1.8872e-06 26.666V7.86V7.858ZM36.666 0C38.506 0 40 1.492 40 3.334V4.506L19.996 18.296L1.8872e-06 4.62V3.334C1.8872e-06 1.494 1.492 0 3.334 0H36.666V0Z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoEmailIcon = React.memo(Email);
export default MemoEmailIcon;
