import React, { useEffect, useState } from "react";

import { Box, Flex } from "atoms";
import MemoBulbIcon from "assets/icons/Bulb";
import { ChooseService } from "organisms";
import mapServiceIcons from "utils/services.icon.constants";
import useAuth from "contexts/Auth";
import useService from "contexts/Service";
import FAQ from "organisms/FAQ";

const getRandomColor = () => {
  // const colors = ["primary.700", "secondary.200"];
  const colors = ["primary.700"];
  const rand = Math.floor(Math.random() * 2);
  return colors[rand];
};
const ChooseServiceCards = [
  {
    title: "Register a Company",
    subtitle: "Incorporation of New Entity",
    body: "Hassle-free company registration in Singapore. (Singaporeans, PRs & Foreigners)",
    button: "Get Started",
    link: "/service/incorporation",
    icon: mapServiceIcons["incorporation"],
    iconColor: getRandomColor(),
  },
    {
    title: "KYC",
    subtitle: "KYC Verification",
    body: "Get done your KYC verification",
    button: "Get Started",
    link: "/service/kyc",
    icon: mapServiceIcons["mom"],
    iconColor: getRandomColor(),
  },
  {
    title: "Secretarial Services",
    subtitle: "Corporate Secretarial Services",
    body: "Get your company in check and compliant with local regulations.",
    button: "Get Started",
    link: "/service/corporate-secretarial",
    icon: mapServiceIcons["corporate secretarial"],
    iconColor: getRandomColor(),
  },
  {
    title: "Set up Accounting",
    subtitle: "Outsourced Accounting Services",
    body: "Bookkeeping | Accounting | Payrolls | Invoice Management",
    button: "Get Started",
    link: "/service/accounting",
    icon: mapServiceIcons["accounting"],
    iconColor: getRandomColor(),
  },
  {
    title: "Taxation",
    subtitle: "Corporate Tax Filing | ECI Filing | GST",
    body: "Staying compliant with IRAS",
    button: "Get Started",
    link: "/service/tax",
    icon: mapServiceIcons["tax"],
    iconColor: getRandomColor(),
  },
  {
    title: "Applications",
    subtitle: "Employment Pass & Visa Application",
    body: "Are you considering hiring foreigners to work or start a company in Singapore?",
    button: "Get Started",
    link: "/service/mom",
    icon: mapServiceIcons["mom"],
    iconColor: getRandomColor(),
  },
];

export const Dashboard = () => {
  const {
    state: { userData },
  } = useAuth();

  const {
    actions: { getProductPrices },
  } = useService();
  const [isFAQ, setIsFAQ] = useState(false);
  useEffect(() => {
    getProductPrices();
  }, [getProductPrices]);

  return (
    <Flex w="100%" h="100%" jC="flex-start" aI="center" flexDirection="column">
      <ChooseService
        title={`Hello, ${userData?.firstName}. Welcome to Chartswood, select the service you desire.`}
        // title={`👋 Hi ${userData?.firstName}. Welcome to Chartswood, select the service you desire.`}
        cards={ChooseServiceCards}
        px="3.2rem"
        titleMaxLines="1"
        subTitleMaxLines="2"
        bodyMaxLines="2"
      />

<Box
        position="absolute"
        left="90%"
        top="calc(100vh - 22.8rem)"
        cursor="pointer"
        onClick={() => setIsFAQ(true)}
      >
        <Flex
          position="fixed"
          width="5.5rem"
          height="5.5rem"
          bg="primary.900"
          borderRadius="100%"
          aI="center"
          jC="center"
        >
          <MemoBulbIcon height="2.4rem" />
        </Flex>
      </Box>
      <FAQ isOpen={isFAQ} onClose={() => setIsFAQ(false)} />
    </Flex>
  );
};
