import * as React from "react";

function Profile(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 10 13" {...props}>
      <path
        d="M10 13H0v-1.238c0-.821.33-1.608.915-2.189a3.14 3.14 0 012.21-.906h3.75a3.14 3.14 0 012.21.906A3.08 3.08 0 0110 11.762V13zM5 7.429A3.781 3.781 0 012.348 6.34 3.712 3.712 0 011.25 3.714a3.684 3.684 0 011.098-2.626A3.753 3.753 0 015 0c.995 0 1.948.391 2.652 1.088A3.696 3.696 0 018.75 3.714c0 .985-.395 1.93-1.098 2.627A3.768 3.768 0 015 7.429z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoProfile = React.memo(Profile);
export default MemoProfile;
