import React, { useCallback, useEffect, useRef, useState } from "react";

import { Sheet } from "organisms";
import { Box, Button, Flex, Loader, Text } from "atoms";
import MemoChatIcon from "assets/icons/Chat";
import MemoCross from "styles/icons/Cross";
import { Form, Formik } from "formik";
import { FormInput } from "molecules";
import { ChatCard } from "templates/MyTask/components/ChatCard";
import useService from "contexts/Service";
import { OWNER_ROLE_CODE, USER_ROLE_CODE } from "utils/constants";

export const ChatModal = ({ isOpen, onClose, position, taskId, isRole }) => {
  // update task
  const [task, setTask] = useState({});
  const [taskLoading, setTaskLoading] = useState(false);
  const messageListRef = useRef();

  const {
    actions: { getTasksById, addTaskComments },
  } = useService();

  const fetchTask = useCallback(async () => {
    setTaskLoading(true);
    getTasksById(taskId)
      .then((data) => {
        setTask(data?.data);
      })
      .finally(() => {
        setTaskLoading(false);
      });
  }, [getTasksById, taskId]);

  useEffect(() => {
    fetchTask();
  }, [fetchTask]);

  // update master task starts
  const addComments = useCallback(
    async ({ message }) => {
      await addTaskComments(
        task?._id,
        message,
        {
          [USER_ROLE_CODE]: task?.taskOwner?._id,
          [OWNER_ROLE_CODE]: task?.user?._id,
        }[isRole]
      );
      fetchTask();
    },
    [addTaskComments, task, fetchTask, isRole]
  );
  // update master task ends

  useEffect(() => {
    messageListRef?.current?.scrollIntoView();
  }, [messageListRef, task]);

  return (
    <Sheet isOpen={isOpen} onClose={undefined}>
      <Flex
        flexDirection="column"
        // p="2.4rem"
        minWidth="40rem"
        minHeight="50rem"
        maxWidth="40rem"
        maxHeight="50rem"
        boxShadow="m"
        bg="white"
        zIndex="90"
        position="fixed"
        borderRadius="0.8rem"
        // top="110%"
        // right="0%"
        top={`calc(${position?.bottom}px - 50rem)`}
        right={`calc(100vw - ${position?.right}px)`}
      >
        {/* top */}
        <Flex
          bg="primary.900"
          w="100%"
          py="1.2rem"
          px="1.8rem"
          borderRadius="0.8rem 0.8rem 0rem 0rem"
          aI="center"
        >
          <Box>
            <MemoChatIcon width="4rem" height="4rem" />
          </Box>
          <Text
            color="white"
            variant="subtitleMedium"
            fontWeight="500"
            ml="1.6rem"
            flex="1"
          >
            Chat with us
          </Text>
          <Box
            color="white"
            width="1.8rem"
            height="1.8rem"
            cursor="pointer"
            onClick={onClose}
          >
            <MemoCross width="1.8rem" height="1.8rem" />
          </Box>
        </Flex>

        <Loader loading={taskLoading}>
          {/* chat */}
          <Box flex="1" overflowY="scroll" overflowX="hidden" pr="1.6rem">
            {/* chat cards */}
            {task?.comments?.map((chat, index) => (
              <ChatCard
                key={index}
                // avatar={item?.avatar}
                name={chat?.fromUserName || chat?.fromUserEmail}
                time={chat?.createdAt}
                chatContent={chat?.message}
                chatContentProps={{ whiteSpace: "pre-wrap" }}
              />
            ))}
            <Box ref={messageListRef} />
          </Box>
        </Loader>

        {/* bottom */}
        {/* publish */}
        <Box position="sticky" bottom="0rem">
          <Formik
            initialValues={{ message: "" }}
            onSubmit={(values, { resetForm }) => {
              addComments(values);
              resetForm();
            }}
            enableReinitialize
          >
            {() => (
              <Form>
                <FormInput
                  name="message"
                  label="Type something here ...."
                  type="textarea"
                  wrap="hard"
                  height="15.5rem"
                />
                <Box
                  w="9.6rem"
                  position="absolute"
                  bottom="1.6rem"
                  right="1.6rem"
                >
                  <Button type="submit">Publish</Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </Flex>
    </Sheet>
  );
};
