import * as React from "react";

function Filter(props) {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.2712 18H14.3793V16H10.2712V18ZM3.08203 6V8H21.5685V6H3.08203ZM6.16311 13H18.4874V11H6.16311V13Z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoFilterIcon = React.memo(Filter);
export default MemoFilterIcon;
