import * as React from "react";

function EmptyCartIcon(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 138 104" fill="none" {...props}>
      <path
        d="M98.578 73.565V60.528h25.815v13.034l-25.815.003z"
        fill="#AC92EA"
      />
      <path
        d="M64.212 49.66l-1.696-15.626h35.253v15.625H64.212z"
        fill="#46CEAC"
      />
      <path
        d="M98.583 77.048l-41.768.661-6.23-28.05 47.998.002v27.387z"
        fill="#5E9CEA"
      />
      <path
        d="M132.556 44.683l.003-5.08-13.952-.01 2.013-14.568-5.049-.693-2.109 15.258-11.18-.007.927-14.799-5.086-.316-.948 15.112-12.914-.008-1.208-15.401-5.082.395 1.176 15.005-14.667-.01-4.435-16.468-4.924 1.32 4.08 15.145-12.067-.006-.005 5.08 13.441.01 3.22 11.953-13.076-.008-.005 5.084 14.447.008 3.797 14.094 4.922-1.32-3.438-12.773 10.445.007 1.016 12.972 5.083-.398-.987-12.572 9.794.007-.883 14.1 5.087.314.903-14.41 9.509.003-2.115 15.312 5.05.695 2.212-16.003 13.171.009.817-5.084-13.288-.007 1.654-11.95 14.651.008zM69.067 56.597L65.85 44.645l13.696.007.94 11.954-11.418-.01zm16.53.013l-.939-11.952 12.197.006-.748 11.948-10.51-.002zm25.512.012l-9.895-.005.749-11.953 10.797.01-1.651 11.948z"
        fill="#E4E8EB"
      />
      <path
        d="M55.217 82.264l.002-.002 4.38-3.405c.411-.29.914-.471 1.458-.475v-.001h61.96c1.238 0 2.269-.88 2.5-2.046l12.419-49.385-.002-.002c.04-.177.064-.361.064-.551a2.544 2.544 0 00-2.55-2.54h-.014l-93.592-1.02-4.744-13.34a2.55 2.55 0 00-1.949-1.75l-.001-.005L2.476.909 1.43 5.882l31.303 6.55L55.255 75.79l-3.172 2.464a7.6 7.6 0 00-1.33 10.685 7.63 7.63 0 006.022 2.932h70.213a2.548 2.548 0 002.551-2.541 2.547 2.547 0 00-2.551-2.542l-70.213.002a2.528 2.528 0 01-2-.977 2.517 2.517 0 01-.518-1.865 2.514 2.514 0 01.96-1.685zM43.654 27.937l88.536.968-11.165 44.392H59.778l-16.124-45.36z"
        fill="#CAD0D7"
      />
      <path
        d="M0 6.246L1.313 0 25.83 5.126l-1.313 6.249L0 6.246z"
        fill="#424852"
      />
      <path
        d="M113.047 101.457c-4.072 0-7.387-3.22-7.387-7.178 0-3.955 3.315-7.175 7.387-7.175 4.073.002 7.385 3.218 7.385 7.175 0 3.959-3.312 7.178-7.385 7.178z"
        fill="#E4E8EB"
      />
      <path
        d="M113.048 84.562c-5.486.002-9.935 4.352-9.935 9.72 0 5.367 4.449 9.717 9.935 9.717 5.486 0 9.935-4.35 9.935-9.72.001-5.367-4.449-9.715-9.935-9.717zm0 14.353c-2.667.002-4.836-2.078-4.838-4.636.002-2.555 2.171-4.635 4.838-4.635 2.668.002 4.837 2.08 4.839 4.635 0 2.558-2.171 4.636-4.839 4.636z"
        fill="#2F3842"
      />
      <path
        d="M66.195 101.458c-4.07 0-7.384-3.22-7.382-7.178-.002-3.955 3.312-7.175 7.382-7.174 4.075 0 7.386 3.217 7.386 7.174 0 3.959-3.312 7.178-7.386 7.178z"
        fill="#E4E8EB"
      />
      <path
        d="M66.197 84.564c-5.484 0-9.931 4.349-9.931 9.716 0 5.369 4.447 9.72 9.931 9.72 5.488 0 9.936-4.351 9.936-9.72.002-5.367-4.448-9.716-9.936-9.716zm0 14.351c-2.665 0-4.834-2.077-4.835-4.635 0-2.555 2.17-4.635 4.835-4.633 2.669 0 4.84 2.078 4.841 4.633-.002 2.56-2.172 4.635-4.84 4.635z"
        fill="#2F3842"
      />
    </svg>
  );
}

const MemoEmptyCartIcon = React.memo(EmptyCartIcon);
export default MemoEmptyCartIcon;
