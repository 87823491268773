import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  // FormDetails,
  FormRemoveShareholder,
  FormUpdateShareholder,
  FormNomineeShareholderDetails,
  FormTransferShares,
} from "../components";
import { FormManagerLayout } from "./FormManagerLayout";
import FORM_NAMES from "../components/form.names";
import { useFormNavigation } from "hooks/useFormNavigation";
import { onChangeFormUploadFile } from "utils/utilities";
import useService from "contexts/Service";
import SERVICE_TYPE_CODE from "utils/services.type.constants";
import { Form } from "formik";
import { csShareholderValidation } from "utils/validation";

const FORM_COMPONENT_NAMED_NAVIGATION = [
  {
    name: FORM_NAMES.FormUpdateShareholder,
    Component: FormUpdateShareholder,
  },
  // {
  //   name: FORM_NAMES.FormAddShareholder,
  //   Component: FormAddShareholder,
  // },
  {
    name: FORM_NAMES.FormRemoveShareholder,
    Component: FormRemoveShareholder,
    attributes: {
      isLastStep: true,
    },
  },
  {
    name: FORM_NAMES.FormNomineeShareholderDetails,
    Component: FormNomineeShareholderDetails,
    attributes: {
      isLastStep: true,
    },
  },
  {
    name: FORM_NAMES.FormTransferShares,
    Component: FormTransferShares,
    attributes: {
      isLastStep: true,
    },
  },
  // {
  //   name: FORM_NAMES.FormDetails,
  //   Component: FormDetails,
  //   onNext: {
  //     next: FORM_NAMES.Cart,
  //   },
  //   attributes: {
  //     docs: [
  //       {
  //         id: "previousSecretaryResignationLetter",
  //         name: "Previous Secretary Resignation Letter",
  //       },
  //     ],
  //   },
  // },
];

const getFormElement = (name) => {
  return (
    FORM_COMPONENT_NAMED_NAVIGATION?.find((item) => item?.name === name) ||
    FORM_COMPONENT_NAMED_NAVIGATION?.find(
      (item) => item?.name === FORM_NAMES.FormUpdateShareholder
    )
  );
};

const breadcrum = {
  firstText: "Home",
  firstUrl: "/dashboard",
  secondText: "Corporate Secretarial Services",
  secondUrl: "/service/corporate-secretarial",
  thirdText: "Add or Remove Shareholder",
};
export const Shareholder = () => {
  const navigate = useNavigate();
  const [FormElement, setFormElement] = useState(getFormElement());
  const {
    actions: { createCS },
  } = useService();

  const [stepCount, currentFormCode, { formNavigationHandler }] =
    useFormNavigation(
      FORM_NAMES.FormUpdateShareholder,
      FORM_NAMES,
      getFormElement,
      "/service/corporate-secretarial/update-shareholder"
    );

  useEffect(() => {
    setFormElement(getFormElement(currentFormCode));
  }, [currentFormCode]);

  const onSubmit = useCallback(
    ({ cartSummary, cartDetails, ...values }) => {
      const formattedValues = {};
      formattedValues.actionType = values?.actionType;

      switch (formattedValues.actionType) {
        case "add": {
          formattedValues.shareholderDetails = values?.shareholderDetails;
          formattedValues.corporateShareholderDetails =
            values?.corporateShareholderDetails;
          break;
        }
        case "remove": {
          formattedValues.removeMembers = values?.removeMembers;
          break;
        }
        case "update": {
          formattedValues.transferShares = values?.transferShares;
          break;
        }
        default: {
          break;
        }
      }

      formattedValues.actionType = values?.actionType;
      // const formattedKeys = Object.keys(values || {})?.filter((item) =>
      //   item?.includes("loading")
      // );
      // const formattedValues = JSON.parse(JSON.stringify(values));

      // formattedKeys?.forEach((item) => {
      //   delete formattedValues[item];
      // });

      createCS(
        formattedValues,
        SERVICE_TYPE_CODE.CORPORATE_SECRETARIAL.additionAndRemovalOfShareholder
          .code,
        cartSummary
      ).then(() => {
        navigate("/cart");
      });
    },
    [createCS]
  );

  return (
    <FormManagerLayout
      tabHeader={{
        title:
          SERVICE_TYPE_CODE.CORPORATE_SECRETARIAL
            .additionAndRemovalOfShareholder.title,
        breadcrum: breadcrum,
        onPrevious: () => {
          navigate("/service/corporate-secretarial");
        },
      }}
      formikProps={{
        initialValues: {
          cartSummary: "",
          cartDetails: "",
          actionType: "",
          shareholderDetails: [],
          corporateShareholderDetails: [],
          removeMembers: [],
          transferShares: [],
        },
        enableReinitialize: true,
        validationSchema: csShareholderValidation,
      }}
    >
      {({
        values,
        setFieldValue,
        validateForm,
        setTouched,
        setErrors,
        errors,
        touched,
      }) => {
        return (
          <Form>
            <FormElement.Component
              step={`Step ${stepCount || ""}/2`}
              onNext={(next, isStepIncrement) => {
                FormElement?.onNext
                  ? formNavigationHandler(
                      FormElement?.onNext?.next,
                      FormElement?.onNext?.isStepIncrement
                    )
                  : formNavigationHandler(next, isStepIncrement);
              }}
              breadcrum={
                (parseInt(stepCount) || 0) > 1 && { firstText: "Go Back" }
              }
              onPrevious={() => {
                (parseInt(stepCount) || 0) > 1 && navigate(-1);
              }}
              formUpload={onChangeFormUploadFile}
              values={values}
              setFieldValue={setFieldValue}
              {...FormElement?.attributes}
              onSubmit={onSubmit}
              cartDetails={values?.cartDetails || []}
              validateForm={validateForm}
              setTouched={setTouched}
              setErrors={setErrors}
              errors={errors}
              touched={touched}
            />
          </Form>
        );
      }}
    </FormManagerLayout>
  );
};
