import * as React from "react";

function Companydetails(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 18 14" fill="none" {...props}>
      <path
        d="M.75 13.75V3.344h3.781V.25h8.938v6.188h3.781v7.312h-7.035v-3.094h-2.43v3.094H.75zm1.375-1.125h2.406v-1.969H2.125v1.969zm0-3.094h2.406V7.562H2.125v1.97zm0-3.094h2.406V4.47H2.125v1.968zm3.781 3.094h2.407V7.562H5.905v1.97zm0-3.094h2.407V4.47H5.905v1.968zm0-3.093h2.407V1.375H5.905v1.969zM9.688 9.53h2.406V7.562H9.688v1.97zm0-3.094h2.406V4.47H9.688v1.968zm0-3.093h2.406V1.375H9.688v1.969zm3.78 9.281h2.407v-1.969h-2.406v1.969zm0-3.094h2.407V7.562h-2.406v1.97z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoCompanydetails = React.memo(Companydetails);
export default MemoCompanydetails;
