import * as React from "react";

function BxTask(props) {
  return (
    <svg
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.77778 18H14.2222C15.2027 18 16 17.1927 16 16.2V2.7C16 1.7073 15.2027 0.9 14.2222 0.9H12.4444C12.4444 0.4023 12.0471 0 11.5556 0H4.44444C3.95289 0 3.55556 0.4023 3.55556 0.9H1.77778C0.797333 0.9 0 1.7073 0 2.7V16.2C0 17.1927 0.797333 18 1.77778 18ZM1.77734 2.7002H3.55512V4.5002H12.444V2.7002H14.2218V16.2002H1.77734V2.7002ZM5.51665 8.81367L7.11043 10.4274L10.482 7.01367L11.7389 8.28627L7.11043 12.9726L4.25977 10.0863L5.51665 8.81367Z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoBxTaskIcon = React.memo(BxTask);
export default MemoBxTaskIcon;
