import React, { useCallback, useEffect, useState } from "react";

import { Sheet } from "organisms";
import { Box, Button, Flex, Text } from "atoms";
import { Form, Formik } from "formik";
import { cwToast, FormDateInput, FormSelect, ProfileAvatar } from "molecules";
import { filterStateHandler } from "utils/utilities";
import { compareAsc, format } from "date-fns";
import { OWNER_ROLE_CODE, USER_ROLE_CODE } from "utils/constants";

export const FilterModal = ({
  isOpen,
  onClose,
  initFilterState,
  onApplyFilter,
  onClearAll,
  role,
}) => {
  const [filterState, setFilterState] = useState({});

  const handleFilter = useCallback(
    /**
     * @param {any} type key of the filter state
     * @param {any} id id of the item under key filter to be reversed
     *
     * @param {any} data specific for "type === date" : applies the given data to date type filter
     */
    (type, id, data) => {
      setFilterState(filterStateHandler(filterState, type, id, data));
    },
    [filterState]
  );

  // apply filter button action
  const applyFilterHandler = useCallback(() => {
    onApplyFilter(filterState);
  }, [onApplyFilter, filterState]);

  // clear all button action
  // const clearAllHandler = useCallback(() => {
  //   onClearAll();
  // }, [onClearAll]);

  useEffect(() => {
    setFilterState(initFilterState);
  }, [initFilterState]);

  return (
    <Sheet isOpen={isOpen} onClose={onClose}>
      <Box
        p="2.4rem"
        minWidth="51.7rem"
        boxShadow="m"
        zIndex="100"
        position="absolute"
        bg="white"
        top="110%"
        right="0%"
      >
        {/* title */}
        <Text variant="subtitleMedium" fontWeight="600" color="neutral.800">
          Add Filter
        </Text>

        {/* category */}
        {/* <Flex aI="center" mt="2.4rem" flexWrap="wrap" gRG="2rem">
          <Text variant="bodyLarge" color="neutral.600">
            Category
          </Text>

          {filterState?.category?.map((item, index) => {
            return (
              <StatusChips
                key={index}
                title={item?.title}
                isActive={item?.isApplied}
                ml="2rem"
                onClick={() => handleFilter("category", item?.id)}
              />
            );
          })}
        </Flex> */}

        {/* serviPeriodces */}
        {/* <Flex aI="center" mt="2.4rem" flexWrap="wrap" gRG="2rem">
          <Text variant="bodyLarge" color="neutral.600">
            Period
          </Text>

          {filterState?.period?.map((item, index) => {
            return (
              <StatusChips
                key={index}
                title={item?.title}
                isActive={item?.isApplied}
                ml="2rem"
                onClick={() => handleFilter("period", item?.id)}
              />
            );
          })}
        </Flex> */}

        {/* Start Date */}
        <Formik
          initialValues={{
            statusType: filterState?.status || "",
            startDate: filterState?.date?.startDate || "",
            endDate: filterState?.date?.endDate || "",
            assignee: filterState?.assignee || "",
          }}
          enableReinitialize
        >
          {({ values, setFieldValue }) => (
            <Form>
              {/* Assignee */}
              <Flex aI="center" mt="2.4rem" gRG="2rem">
                <Text variant="bodyLarge" color="neutral.600">
                  Service Name
                </Text>

                <FormSelect
                  name="statusType"
                  label="Service Name"
                  onChange={(value) => {
                    handleFilter("serviceType", value?.value);
                  }}
                  options={filterState?.statusOptions?.map((item) => {
                    return {
                      label: (
                        <Flex key={item?.id} aI="center">
                          <Text
                            variant="bodyLarge"
                            // color={isActiveState ? "neutral.50" : "neutral.500"}
                          >
                            {item?.title}
                          </Text>
                        </Flex>
                      ),
                      value: item?.status,
                    };
                  })}
                />
              </Flex>
              {/* dates */}
              <Flex aI="center" mt="2.4rem">
                <Text variant="bodyLarge" color="neutral.600">
                  Start Date
                </Text>
                <FormDateInput
                  name="startDate"
                  label="Start Date"
                  containerProps={{ ml: "2rem" }}
                  onChange={(value) => {
                    if (
                      values?.endDate &&
                      compareAsc(value, new Date(values?.endDate)) === 1
                    ) {
                      cwToast(
                        "error",
                        "Invalid Start Date",
                        `Start date should be before ${format(
                          new Date(values?.endDate),
                          "dd-MM-yyyy"
                        )}`
                      );
                      setFieldValue("startDate", "");
                      handleFilter("date", "", {
                        ...values,
                        startDate: "",
                      });
                    } else {
                      handleFilter("date", "", {
                        ...values,
                        startDate: value?.toISOString(),
                      });
                    }
                  }}
                />
                <Text variant="bodyLarge" color="neutral.600" ml="3.7rem">
                  End Date
                </Text>
                <FormDateInput
                  name="endDate"
                  label="End Date"
                  containerProps={{ ml: "2rem" }}
                  onChange={(value) => {
                    if (
                      values?.startDate &&
                      compareAsc(new Date(values?.startDate), value) === 1
                    ) {
                      cwToast(
                        "error",
                        "Invalid End Date",
                        `End date should be after ${format(
                          new Date(values?.startDate),
                          "dd-MM-yyyy"
                        )}`
                      );
                      setFieldValue("endDate", "");
                      handleFilter("date", "", {
                        ...values,
                        endDate: "",
                      });
                    } else {
                      handleFilter("date", "", {
                        ...values,
                        endDate: value?.toISOString(),
                      });
                    }
                  }}
                />
              </Flex>

              {/* Assignee */}
              <Flex aI="center" mt="2.4rem" gRG="2rem">
                <Text variant="bodyLarge" color="neutral.600" mr="2.4rem">
                  {
                    { [USER_ROLE_CODE]: "Assignee", [OWNER_ROLE_CODE]: "User" }[
                      role
                    ]
                  }
                </Text>

                <FormSelect
                  name="assignee"
                  label={`Select ${
                    { [USER_ROLE_CODE]: "Assignee", [OWNER_ROLE_CODE]: "User" }[
                      role
                    ]
                  }`}
                  onChange={(value) => {
                    handleFilter("assignee", value?.value);
                  }}
                  options={filterState?.assigneeOptions?.map((item) => {
                    return {
                      label: (
                        <Flex key={item?.id} aI="center">
                          {item?.email && (
                            <ProfileAvatar
                              name={item?.email}
                              // avatar={avatar}
                              size="xs"
                              mr="0.8rem"
                              bg="secondary.50"
                              border="none"
                              textProps={{ color: "secondary.900" }}
                            />
                          )}
                          <Text
                            variant="bodyLarge"
                            // color={isActiveState ? "neutral.50" : "neutral.500"}
                          >
                            {item?.email}
                          </Text>
                        </Flex>
                      ),
                      value: item?.id,
                    };
                  })}
                />
              </Flex>
            </Form>
          )}
        </Formik>

        {/* Buttons */}
        <Flex aI="center" mt="4.2rem">
          {/* clear */}
          <Button variant="outline" w="22.6%" onClick={onClearAll}>
            Clear All
          </Button>
          {/* apply filter */}
          <Button ml="0.8rem" onClick={applyFilterHandler}>
            Apply filter
          </Button>
        </Flex>
      </Box>
    </Sheet>
  );
};
