import React, { useState } from "react";

import { Form } from "formik";
import { Box, Button, Error, Text } from "atoms";
import Table from "atoms/Table";
import FormLayout from "../FormLayout";
import FORM_NAMES from "templates/Incorporation/form.names";
import { Upload } from "organisms";
import { getDocumentById } from "utils/utilities";

const INITIAL_VALUES = {
  documents: [],
};

export const FormAddressProof = ({
  breadcrum,
  step,
  onNext,
  onPrevious,
  onSubmit,
  formInitValues,
  formUpload,
  ...props
}) => {
  const [submitting, setSubmitting] = useState(false);
  return (
    <>
      <FormLayout
        breadcrum={breadcrum}
        step={step}
        onPrevious={onPrevious}
        title="Upload Residential Proof"
        subtitle="Local or Residential Address is required to register a local entity."
        formikProps={{
          initialValues: {
            ...INITIAL_VALUES,
            ...formInitValues,
            documents: formInitValues?.localAddressProofDocuments,
          },
        }}
        {...props}
      >
        {({ values, setFieldValue, dirty, errors, setFieldError }) => {
          return (
            <Form>
              {/* Add Local Address Proof */}
              <Box mt="5rem">
                {/* inputs */}
                <Table
                  tableHeader={[
                    { name: "Document Name" },
                    { name: "Upload file" },
                  ]}
                >
                  <tbody>
                    <tr>
                      <td>
                        <Box>
                          <Text variant="bodyLarge" color="neutral.700">
                            Proof of Identification
                          </Text>
                          <Text variant="bodyLarge" color="neutral.700">
                            "(eg. Utilities Bills / Telecommunication Bills /
                            Bank Statements)"
                          </Text>
                          {errors?.localAddressProof && (
                            <Error text={errors?.localAddressProof} />
                          )}
                        </Box>
                      </td>
                      <td>
                        <Upload
                          url={
                            getDocumentById(
                              "localAddressProof",
                              values?.documents
                            )?.url
                          }
                          name={
                            getDocumentById(
                              "localAddressProof",
                              values?.documents
                            )?.name
                          }
                          time={
                            getDocumentById(
                              "localAddressProof",
                              values?.documents
                            )?.time
                          }
                          onClose={() => {
                            const delDocs =
                              JSON.parse(
                                JSON.stringify(values)
                              )?.documents?.filter(
                                (item) => item?.id !== "localAddressProof"
                              ) || [];

                            setFieldValue("documents", delDocs);
                          }}
                          loading={values?.[`formAddressPOI loading`]}
                          onChange={(e) => {
                            formUpload(
                              e,
                              "formAddressPOI",
                              setFieldValue,
                              (fileData) => {
                                const addDocs =
                                  JSON.parse(JSON.stringify(values))
                                    ?.documents || [];

                                addDocs?.push({
                                  id: "localAddressProof",
                                  name: fileData?.name,
                                  time: fileData?.uploadedAt,
                                  url: fileData?.url,
                                  type: "localAddressProof",
                                });

                                setFieldValue("documents", addDocs);
                              }
                            );
                          }}
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Box>

              <Button
                type="button"
                w="fit-content"
                px="4rem"
                mt="4rem"
                loading={submitting}
                onClick={() => {
                  if (
                    values?.documents?.find(
                      (doc) => doc?.id === "localAddressProof"
                    )?.url
                  ) {
                    if (dirty) {
                      setSubmitting(true);
                      onSubmit(values)
                        .then(() => {
                          onNext(FORM_NAMES.FormCompanyDetails, true);
                        })
                        .catch(console.error)
                        .finally(() => {
                          setSubmitting(false);
                        });
                    } else {
                      onNext(FORM_NAMES.FormCompanyDetails, true);
                    }
                  } else {
                    setFieldError("localAddressProof", "* required");
                  }
                }}
              >
                NEXT : Company details
              </Button>
            </Form>
          );
        }}
      </FormLayout>
    </>
  );
};
