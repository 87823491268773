import * as React from "react";

function TaxIcon(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 16 12" fill="none" {...props}>
      <path
        d="M7.494 9.863h1.125v-.844h.9a.56.56 0 00.46-.225.794.794 0 00.177-.507V6.131c0-.2-.06-.372-.178-.515a.569.569 0 00-.46-.216h-2.55V4.106h3.188V2.981H8.62v-.843H7.494v.843h-.919a.7.7 0 00-.506.225.72.72 0 00-.225.525V5.87c0 .2.075.36.225.478.15.119.319.178.506.178h2.456v1.369H5.844v1.125h1.65v.844zM1.625 12c-.3 0-.563-.113-.787-.338a1.08 1.08 0 01-.338-.787v-9.75c0-.3.113-.563.338-.787A1.08 1.08 0 011.625 0h12.75c.3 0 .563.113.787.338.225.224.338.487.338.787v9.75c0 .3-.113.563-.338.787a1.08 1.08 0 01-.787.338H1.625zm0-1.125h12.75v-9.75H1.625v9.75zm0 0v-9.75 9.75z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoTaxIcon = React.memo(TaxIcon);
export default MemoTaxIcon;
