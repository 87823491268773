import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box, Flex, Text, Button, Line, Grid, Loader } from "atoms";
import { DefaultChips, StepperTab } from "molecules";
import { ChatModal, FormDetails } from "../components";
import {
  DocumentVerifiedCard,
  FormOwnerACRA,
  FormOwnerCompanyVerification,
  FormOwnerDocumentCreation,
  FormOwnerDocumentSigning,
  FormOwnerPostIncorporation,
  FormOwnerPreIncorporation,
  FormOwnerUploadAcknowledgment,
} from "./components";
import useService from "contexts/Service";
import { useNavigate, useParams } from "react-router-dom";
import { CS_STEPS, INCORPORATION_STEPS } from "utils/service.steps";
import SERVICE_TYPE_CODE from "utils/services.type.constants";
import { getStatusChip } from "utils/utilities";
import { UserDetails } from "../types";
import { OWNER_ROLE_CODE, STATUS } from "utils/constants";
import MemoChatIcon from "assets/icons/Chat";

export const Owner = () => {
  const {
    actions: { getTasksById, updateTaskSubsteps, updateTask },
  } = useService();
  const navigate = useNavigate();
  const chatButtonRef = useRef();
  const [isChatModalVisible, setIsChatModalVisible] = useState(false);

  const { taskId } = useParams();

  // fetch task start
  const [task, setTask] = useState({});
  const [loading, setLoading] = useState(false);

  const fetchTask = useCallback(async () => {
    setLoading(true);
    getTasksById(taskId)
      .then((data) => {
        setTask(data?.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [getTasksById, taskId]);

  useEffect(() => {
    fetchTask();
  }, [fetchTask]);

  // fetch task ends

  // update master task starts
  const updateMasterTask = useCallback(
    async (status) => {
      const reqData = {
        taskId: task?._id,
        status,
      };

      await updateTask(reqData);
      fetchTask();
    },
    [updateTask, task, fetchTask]
  );
  // update master task ends

  // update task substep starts
  const updateTaskSubstep = useCallback(
    async (data) => {
      const reqData = {
        type: task?.serviceName,
        serviceId: task?.serviceId,
        ...data,
      };

      await updateTaskSubsteps(reqData);
      fetchTask();
    },
    [updateTaskSubsteps, task, fetchTask]
  );
  // update task substep ends

  const [activeStep, setActiveStep] = useState(0);
  const stepper = { cs: CS_STEPS, incorporation: INCORPORATION_STEPS }[
    task?.serviceName
  ];

  const activeStepHandler = useCallback(
    (newStep) => {
      if (newStep === 0) {
        setActiveStep(newStep);
      }

      if (
        task?.serviceDetails[stepper?.[newStep - 1]?.statusFlag] ===
          STATUS.completed ||
        stepper?.[newStep - 1]?.status === STATUS.completed
      ) {
        setActiveStep(newStep);
      }

      // if (newStep >= stepper?.length) {
      //   setActiveStep(0);
      // }
    },
    [stepper, task]
  );

  // status handlers
  const [pendingLoading, setPendingLoading] = useState(false);
  const [completedLoading, setCompletedLoading] = useState(false);

  const onPending = useCallback(() => {
    setPendingLoading(activeStep?.toString());
    updateMasterTask(STATUS.pending);
    updateTaskSubstep({
      [stepper?.[activeStep]?.statusFlag]: STATUS.pending,
    }).finally(() => {
      setPendingLoading(false);
    });
  }, [updateTaskSubstep, updateMasterTask, stepper, activeStep]);

  const onCompleted = useCallback(() => {
    setCompletedLoading(activeStep?.toString());
    updateTaskSubstep({
      [stepper?.[activeStep]?.statusFlag]: STATUS.completed,
      ...(activeStep < stepper?.length - 1 && {
        [stepper?.[activeStep + 1]?.statusFlag]: STATUS.inProgress,
      }),
    }).finally(() => {
      setCompletedLoading(false);
      activeStepHandler(activeStep + 1);
    });

    if (activeStep === stepper?.length - 1) {
      updateMasterTask(STATUS.completed);
    } else {
      task?.status?.name !== STATUS.inProgress &&
        updateMasterTask(STATUS.inProgress);
    }
  }, [
    updateTaskSubstep,
    updateMasterTask,
    stepper,
    activeStep,
    task,
    activeStepHandler,
  ]);
  // status handlers ends

  return (
    <Loader loading={loading}>
      <Box
        h="100%"
        bg="white"
        py="3.2rem"
        px="2.4rem"
        ml="3.2rem"
        mt="2.4rem"
        mr="3.2rem"
        bR=".8rem"
      >
        <Flex jC="space-between">
          <Box>
            <Flex>
              <Text
                variant="subtitleLarge"
                color="neutral.700"
                fontWeight="600"
              >
                {task?.serviceName?.toUpperCase()}
                {task?.serviceName === "cs" &&
                  ` (${
                    SERVICE_TYPE_CODE.CORPORATE_SECRETARIAL?.[
                      task?.serviceDetails?.serviceType
                    ]?.title
                  })`}
              </Text>
              <DefaultChips
                {...getStatusChip(task?.status?.name)}
                ml="2.4rem"
              />
            </Flex>
            {/* <Text color="neutral.600" variant="bodySmall" mt=".8rem">
              Description Text
            </Text> */}
          </Box>
          <Button
            w="fit-content"
            px="4rem"
            onClick={() =>
              // setActiveStep((pre) => (pre + 1 >= stepper?.length ? 0 : pre + 1))
              // activeStep >= stepper?.length
              //   ? navigate("/query-list/mytask")
              //   : activeStepHandler(activeStep + 1)
              activeStep + 1 < stepper?.length
                ? activeStepHandler(activeStep + 1)
                : navigate("/query-list/mytask")
            }
            disabled={
              stepper?.[activeStep]?.status !== STATUS.completed &&
              task?.serviceDetails?.[stepper?.[activeStep]?.statusFlag] !==
                STATUS.completed
            }
          >
            {activeStep + 1 < stepper?.length
              ? `Next : ${stepper[activeStep + 1]?.title}`
              : "Go To Tasks"}
          </Button>
        </Flex>
        <Line mt="2.4rem" mb="3.8rem" />
        {/* Owner Details */}
        <Grid gTC="16.25% 1fr" gCG="2.4rem" width="100%">
          <StepperTab
            steps={stepper}
            serviceDetails={task?.serviceDetails}
            activeStep={activeStep}
            setActiveStep={activeStepHandler}
          />
          {
            {
              cs: (
                <>
                  {
                    {
                      0: (
                        <Box w="100%">
                          <UserDetails
                            serviceTypeCode={task?.serviceDetails?.serviceType}
                            serviceDetails={task?.serviceDetails}
                          />
                          {task?.status?.name === STATUS.created && (
                            <Box mt="2.4rem">
                              <DocumentVerifiedCard
                                title="Begin Document Creation?"
                                subtitle="Continue with task or chat with user."
                                completed={{
                                  onCompleted: () => {
                                    updateMasterTask(STATUS.inProgress);
                                    updateTaskSubstep({
                                      [stepper?.[1]?.statusFlag]:
                                        STATUS.inProgress,
                                    });
                                  },
                                  onCompletedText: "Begin",
                                }}
                              />
                            </Box>
                          )}
                        </Box>
                      ),
                      1: (
                        <FormOwnerDocumentCreation
                          serviceDetails={task?.serviceDetails}
                          statusFlag={stepper?.[activeStep]?.statusFlag}
                          pending={{
                            onPending,
                            loading: pendingLoading === activeStep?.toString(),
                          }}
                          completed={{
                            onCompleted,
                            loading:
                              completedLoading === activeStep?.toString(),
                          }}
                        />
                      ),
                      2: (
                        <FormOwnerDocumentSigning
                          serviceDetails={task?.serviceDetails}
                          statusFlag={stepper?.[activeStep]?.statusFlag}
                          pending={{
                            onPending,
                            loading: pendingLoading === activeStep?.toString(),
                          }}
                          completed={{
                            onCompleted,
                            loading:
                              completedLoading === activeStep?.toString(),
                          }}
                        />
                      ),
                      3: (
                        <FormOwnerACRA
                          serviceDetails={task?.serviceDetails}
                          statusFlag={stepper?.[activeStep]?.statusFlag}
                          statusLinkFlag={"preCsAcraLink"}
                          updateTask={updateTaskSubstep}
                          pending={{
                            onPending,
                            loading: pendingLoading === activeStep?.toString(),
                          }}
                          completed={{
                            onCompleted,
                            loading:
                              completedLoading === activeStep?.toString(),
                          }}
                        />
                      ),
                    }[activeStep]
                  }
                </>
              ),
              incorporation: (
                <>
                  {
                    {
                      0: (
                        <Box w="100%">
                          <FormDetails
                            serviceDetails={task?.serviceDetails}
                            statusFlag={stepper?.[activeStep]?.statusFlag}
                          />
                          {task?.status?.name === STATUS.created && (
                            <Box mt="2.4rem">
                              <DocumentVerifiedCard
                                title="Begin Company Verification?"
                                subtitle="Continue with task or chat with user."
                                completed={{
                                  onCompleted: () => {
                                    updateMasterTask(STATUS.inProgress);
                                    updateTaskSubstep({
                                      [stepper?.[1]?.statusFlag]:
                                        STATUS.inProgress,
                                    });
                                  },
                                  onCompletedText: "Begin",
                                }}
                              />
                            </Box>
                          )}
                        </Box>
                      ),
                      1: (
                        <FormOwnerCompanyVerification
                          serviceDetails={task?.serviceDetails}
                          statusFlag={stepper?.[activeStep]?.statusFlag}
                          pending={{
                            onPending,
                            loading: pendingLoading === activeStep?.toString(),
                          }}
                          completed={{
                            onCompleted,
                            loading:
                              completedLoading === activeStep?.toString(),
                          }}
                        />
                      ),
                      2: (
                        <FormOwnerPreIncorporation
                          serviceDetails={task?.serviceDetails}
                          statusFlag={stepper?.[activeStep]?.statusFlag}
                          pending={{
                            onPending,
                            loading: pendingLoading === activeStep?.toString(),
                          }}
                          completed={{
                            onCompleted,
                            loading:
                              completedLoading === activeStep?.toString(),
                          }}
                        />
                      ),
                      3: (
                        <FormOwnerDocumentSigning
                          serviceDetails={task?.serviceDetails}
                          statusFlag={stepper?.[activeStep]?.statusFlag}
                          pending={{
                            onPending,
                            loading: pendingLoading === activeStep?.toString(),
                          }}
                          completed={{
                            onCompleted,
                            loading:
                              completedLoading === activeStep?.toString(),
                          }}
                        />
                      ),
                      // 4: <FormOwnerUploadAcknowledgment />,
                      4: (
                        <FormOwnerACRA
                          serviceDetails={task?.serviceDetails}
                          statusFlag={stepper?.[activeStep]?.statusFlag}
                          statusLinkFlag={"preIncorporationAcraLink"}
                          updateTask={updateTaskSubstep}
                          pending={{
                            onPending,
                            loading: pendingLoading === activeStep?.toString(),
                          }}
                          completed={{
                            onCompleted,
                            loading:
                              completedLoading === activeStep?.toString(),
                          }}
                        />
                      ),
                      5: (
                        <FormOwnerPostIncorporation
                          serviceDetails={task?.serviceDetails}
                          statusFlag={stepper?.[activeStep]?.statusFlag}
                          pending={{
                            onPending,
                            loading: pendingLoading === activeStep?.toString(),
                          }}
                          completed={{
                            onCompleted,
                            loading:
                              completedLoading === activeStep?.toString(),
                          }}
                        />
                      ),
                      6: (
                        <FormOwnerDocumentSigning
                          serviceDetails={task?.serviceDetails}
                          statusFlag={stepper?.[activeStep]?.statusFlag}
                          pending={{
                            onPending,
                            loading: pendingLoading === activeStep?.toString(),
                          }}
                          completed={{
                            onCompleted,
                            loading:
                              completedLoading === activeStep?.toString(),
                          }}
                        />
                      ),
                      7: (
                        <FormOwnerACRA
                          serviceDetails={task?.serviceDetails}
                          statusFlag={stepper?.[activeStep]?.statusFlag}
                          statusLinkFlag={"postIncorporationAcraLink"}
                          updateTask={updateTaskSubstep}
                          pending={{
                            onPending,
                            loading: pendingLoading === activeStep?.toString(),
                          }}
                          completed={{
                            onCompleted,
                            loading:
                              completedLoading === activeStep?.toString(),
                          }}
                        />
                      ),
                    }[activeStep]
                  }
                </>
              ),
            }[task?.serviceName]
          }
          {/* {
            {
              0: <FormDetails />,
              1: <FormOwnerCompanyVerification />,
              2: <FormOwnerPreIncorporation />,
              3: <FormOwnerDocumentSigning />,
              4: <FormOwnerUploadAcknowledgment />,
              // <FormOwnerACRA />,
              5: <FormOwnerPostIncorporation />,
              6: <FormOwnerDocumentSigning />,
              7: <FormOwnerACRA />,
            }[activeStep]
          } */}
        </Grid>
      </Box>
      <Box position="absolute" left="90%" top="calc(100vh - 22.8rem)">
        <Flex
          position="fixed"
          width="5.5rem"
          height="5.5rem"
          bg="primary.900"
          borderRadius="100%"
          aI="center"
          jC="center"
          onClick={() => setIsChatModalVisible(task?._id)}
          ref={chatButtonRef}
        >
          <MemoChatIcon width="5rem" height="5rem" />
        </Flex>
      </Box>
      {isChatModalVisible && (
        <ChatModal
          isOpen={isChatModalVisible}
          position={chatButtonRef?.current?.getBoundingClientRect()}
          onClose={() => setIsChatModalVisible(false)}
          isRole={OWNER_ROLE_CODE}
          taskId={isChatModalVisible}
        />
      )}
    </Loader>
  );
};
