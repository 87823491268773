import React, { useState } from "react";
import { Form, setNestedObjectValues } from "formik";
import { Button, Error, Flex, Line } from "atoms";
import { cwToast, FormToggle } from "molecules";
import FormLayout from "../FormLayout";
import MemoTick from "styles/icons/Tick";
import MemoCross from "styles/icons/Cross";
import FORM_NAMES from "templates/Incorporation/form.names";
import { cartFeatureHandler } from "utils/utilities";
import PRODUCT_PRICES from "utils/princing.constant";
import { incorporationDirectorServiceValidation } from "utils/validation";

const INITIAL_VALUES = {
  usingDirectorDetails: "",
};

export const FormLocalDirector = ({
  breadcrum,
  step,
  onNext,
  onPrevious,
  onSubmit,
  formInitValues,
  ...props
}) => {
  const [submitting, setSubmitting] = useState(false);
  return (
    <FormLayout
      breadcrum={breadcrum}
      step={step}
      onPrevious={onPrevious}
      title="Would you like a Nominee Director from Chartswood?"
      subtitle="Chartswood will help provide a Nominee Director to satisfy Singapore’s regulatory compliances and will take a non-executive role in your company"
      formikProps={{
        initialValues: {
          ...INITIAL_VALUES,
          ...formInitValues,
        },
        enableReinitialize: true,
        validationSchema: incorporationDirectorServiceValidation,
      }}
      {...props}
    >
      {({
        values,
        dirty,
        setFieldValue,
        validateForm,
        setTouched,
        setErrors,
        errors,
      }) => {
        return (
          <Form>
            <Flex mt="4rem">
              <FormToggle
                name="usingDirectorDetails"
                label="Yes"
                value={true}
                Icon={MemoTick}
              />
              <FormToggle
                name="usingDirectorDetails"
                label="No"
                value={false}
                ml="3.2rem"
                Icon={MemoCross}
                isWrong
              />
            </Flex>

            {/* {errors?.usingDirectorDetails && (
              <Error text={errors?.usingDirectorDetails} />
            )} */}

            <Line mt="4.8rem" />
            <Button
              w="fit-content"
              px="4rem"
              mt="4rem"
              type="button"
              loading={submitting}
              onClick={() => {
                validateForm(values)?.then((dataErrorBag) => {
                  const dataErrorBagKeys = Object?.keys(dataErrorBag || {});
                  if (dataErrorBagKeys?.length) {
                    setTouched(setNestedObjectValues(dataErrorBag, true));
                    setErrors(dataErrorBag);
                    dataErrorBag?.usingDirectorDetails &&
                      cwToast("error", dataErrorBag?.usingDirectorDetails);
                  } else {
                    if (dirty) {
                      setSubmitting(true);

                      const withCartValues = JSON.parse(JSON.stringify(values));
                      const newCartFeatures = cartFeatureHandler(
                        withCartValues?.cartSummary?.features,
                        PRODUCT_PRICES.director_services.name,
                        PRODUCT_PRICES.director_services.type,
                        withCartValues?.usingDirectorDetails
                      );
                      if (withCartValues?.cartSummary) {
                        withCartValues.cartSummary.features = newCartFeatures;
                      } else {
                        withCartValues.cartSummary = {
                          features: newCartFeatures,
                        };
                      }
                      setFieldValue("cartSummary.features", newCartFeatures);

                      onSubmit(withCartValues)
                        .then(() => {
                          onNext(FORM_NAMES.FormDirectorDetails, false);
                        })
                        .catch(console.error)
                        .finally(() => {
                          setSubmitting(false);
                        });
                    } else {
                      onNext(FORM_NAMES.FormDirectorDetails, false);
                    }
                  }
                });
              }}
            >
              Next: Director Details
            </Button>
          </Form>
        );
      }}
    </FormLayout>
  );
};
