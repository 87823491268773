import { ServerException } from "exceptions";
import axios from "axios";

import { hydrate } from "utils/persist";
import { RESPONSE } from "utils/constants";
import { loadCookie, saveCookie } from "utils/cookie";

import { AUTH_TOKEN_COOKIE, REMEMBER_ME } from "./getTokens";
import { makeRequestInitt } from "./makeRequestInit";

let loading = false;
const delay = (ms) => new Promise((res) => setTimeout(res, ms));

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
  timeout: 30000,
});
export const api = async (relativeUri, options = {}) => {
  const init = makeRequestInitt(options);
  try {
    const response = await axiosInstance.request({
      ...init,
      url: relativeUri,
    });
    if (
      response.data?.status === RESPONSE.success ||
      typeof response.data === "string"
    ) {
      return response;
    }
    // eslint-disable-next-line no-throw-literal
    throw { response };
  } catch (error) {
    if (error.response && error.response.status === 500) {
      throw new ServerException({
        message: "Something went wrong.",
      });
    }

    // wrong token stuff
    if (
      error.response &&
      error.response.data?.message
        ?.toLowerCase()
        ?.includes("wrong authentication token")
    ) {
      window.location.href = "/logout";
    }

    // refresh token stuff
    if (error.response && error.response.data?.status === RESPONSE.expired) {
      if (
        loadCookie(REMEMBER_ME) === "false" ||
        hydrate(AUTH_TOKEN_COOKIE, "cookies") === null
      ) {
        window.location.href = "/logout";
      } else {
        let waited = false;
        while (loading) {
          waited = true;
          // eslint-disable-next-line no-await-in-loop
          await delay(1000);
        }
        if (hydrate(AUTH_TOKEN_COOKIE, "cookies") !== null && !waited) {
          loading = true;
          const response = await axios("/auth/refresh", {
            method: "post",
            headers: {
              authorization: `Bearer ${hydrate(AUTH_TOKEN_COOKIE, "cookies")}`,
            },
            data: {
              rememberMe: true,
              // refresh_token: hydrate(REFRESH_TOKEN_COOKIE, "cookies"),
            },
          })
            .then(async ({ data }) => {
              saveCookie(AUTH_TOKEN_COOKIE, data.token);
              return api(relativeUri, options);
            })
            .catch(() => {
              window.location.href = "/logout";
            })
            .finally(() => {
              loading = false;
            });
          return response;
        }
        if (waited) {
          const response = await api(relativeUri, options);
          return response;
        }
      }
    }

    if (error.response && error.response.status === 400) {
      throw new ServerException({ message: error?.response?.data?.errors });
    }
    if (error.response && error.response.status === 404) {
      throw new ServerException({ message: error?.response?.data?.errors });
    }

    throw new ServerException({
      ...error?.response,
      message: error?.response?.data?.message,
    });
  }
};
