import React, { useState } from "react";
import { Box, Flex, Grid, Text } from "atoms";
import MemoMinus from "assets/icons/Minus";
import MemoNotificationFile from "assets/icons/NotificationFile";
import MemoPlus from "assets/icons/Plus";

export const MyCartCard = ({ name, description, money, ...props }) => {
  const [count, setCount] = useState(5);

  return (
    <>
      <Flex pt="1.2rem" pb="1.2rem" aI="center" jC="space-between" {...props}>
        <Flex aI="center">
          <Box
            w="3.2rem"
            h="3.2rem"
            bR="50%"
            bg="secondary.50"
            p="1rem"
            // mt="0.8rem"
            {...props}
          >
            <MemoNotificationFile />
          </Box>
          <Flex flexDirection="column" ml="0.8rem">
            <Text color="neutral.700" variant="bodyMedium" fontWeight="600">
              {name || ""}
            </Text>
            <Text variant="bodySmall" color="neutral.500">
              {description || ""}
            </Text>
          </Flex>
        </Flex>
        {/* Add oe remove Quntity */}
        {/* <Grid gridGap="1.6rem" gridAutoFlow="column" ml="9rem" mt="1.1rem">
          <Box
            mt="0.5rem"
            color="neutral.500"
            cursor="pointer"
            onClick={() => setCount((pre) => pre - 1)}
          >
            <MemoMinus />
          </Box>

          <Flex
            w="2rem"
            h="2rem"
            br="0.2rem"
            border="0.5px solid"
            borderColor="neutral.100"
            px="0.6rem"
            aI="center"
            jC="center"
          >
            <Text
              variant="bodyMedium"
              color="neutral.600"
              lineHeight="unset !important"
            >
              {count}
            </Text>
          </Flex>
          <Box
            mt="0.4rem"
            color="primary.900"
            cursor="pointer"
            onClick={() => setCount((pre) => pre + 1)}
          >
            <MemoPlus />
          </Box>
        </Grid> */}
        <Text
          mt="0.6rem"
          variant="bodyLarge"
          color="neutral.700"
          fontWeight="600"
          ml="9rem"
        >
          {money}
        </Text>
      </Flex>
    </>
  );
};
