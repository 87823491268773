import React from "react";
import { useNavigate } from "react-router-dom";

import { Box, Button, Error, Flex, Grid, Image, Text } from "atoms";
import { FormInput } from "molecules/FormInput";
import { Formik, Form } from "formik";
import { resetPasswordValidation } from "utils/validation";

export const CreateNewPassWord = ({ onSubmit, error, isLoading }) => {
  const navigate = useNavigate();

  return (
    <>
      <Formik
        initialValues={{
          password: "",
          confirmPassword: "",
        }}
        onSubmit={onSubmit}
        validationSchema={resetPasswordValidation}
      >
        {({ values }) => {
          return (
            <Form>
              <Box
                // w="80rem"
                // h="64.9rem"
                boxShadow="m"
                bR="0.8rem"
                // pl="8rem"
                // pt="8rem"
                py="2.4rem"
                px="3.9rem"
                minWidth="43rem"
                bg="white"
              >
                <Box>
                  <Box>
                    {/* <Box h="4.9rem" w="2.4rem">
                      <Image
                        src="images/logo.png"
                        alt="logo"
                        objectFit="cover"
                      />
                    </Box> */}
                    <Text
                      variant="subtitleLarge"
                      // mt="5rem"
                      color="neutral.800"
                      fontWeight="600"
                    >
                      Create new password
                    </Text>
                    <Text mt="4rem" variant="bodyMedium" color="neutral.600">
                      Your new password must be different from previous used
                      passwords.
                    </Text>
                    <Grid mt="1.6rem" gridGap="2.4rem">
                      <FormInput
                        name="password"
                        type="password"
                        label="New password"
                        isErrorRelative={values?.password}
                      />

                      <FormInput
                        name="confirmPassword"
                        type="password"
                        label="Confirm password"
                        // isErrorRelative
                      />
                    </Grid>
                    <Text pt="2rem" variant="bodyMedium" color="neutral.600">
                      Both password must be match.
                    </Text>
                    {error && <Error text={error} mt="0.8rem" />}
                    <Flex mt="4.8rem">
                      <Button
                        variant="outline"
                        w="11rem"
                        mr="1.6rem"
                        onClick={() => navigate(-1)}
                      >
                        Back
                      </Button>
                      <Button
                        type="submit"
                        variant="primary"
                        w="11rem"
                        loading={isLoading}
                      >
                        Send
                      </Button>
                    </Flex>
                  </Box>
                </Box>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
