export const CS_STEPS = [
  {
    title: "User Details",
    // description: "Description Text",
    status: "completed",
  },
  {
    title: "Document Creation",
    // description: "Description Text",
    statusFlag: "preCsDocumentCreationStatus",
  },
  {
    title: "Document Signing",
    // description: "Description Text",
    statusFlag: "preCsDocumentSigningStatus",
  },
  {
    title: "ACRA",
    // description: "Description Text",
    statusFlag: "preCsAcraStatus",
  },
];

export const INCORPORATION_STEPS = [
  {
    title: "User Details",
    // description: "Description Text",
    status: "completed",
  },
  {
    title: "Company Verification",
    // description: "Description Text",
    statusFlag: "incorporationCompanyVerificationStatus",
  },
  {
    title: "Pre Incorporation Document Creation",
    // description: "Description Text",
    statusFlag: "preIncorporationDocumentCreationStatus",
  },
  {
    title: "Document Signing",
    // description: "Description Text",
    statusFlag: "preIncorporationDocumentSigningStatus",
  },
  {
    title: "ACRA",
    // description: "Description Text",
    statusFlag: "preIncorporationAcraStatus",
  },
  {
    title: "Post Incorporation Document Creation",
    // description: "Description Text",
    statusFlag: "postIncorporationDocumentCreationStatus",
  },
  {
    title: "Document Signing",
    // description: "Description Text",
    statusFlag: "postIncorporationDocumentSigningStatus",
  },
  {
    title: "ACRA",
    // description: "Description Text",
    statusFlag: "postIncorporationAcraStatus",
  },
];
