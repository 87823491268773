import React, { useState } from "react";
import { Form, setNestedObjectValues } from "formik";
import { Box, Button, Error, Grid, Line, Text } from "atoms";
import FormLayout from "../FormLayout";
import { Accordian } from "organisms";
import {
  CorporateShareholder,
  Shareholder,
  ShareholderTypeModal,
} from "./components";
import FORM_NAMES from "templates/Incorporation/form.names";
import { incorporationShareholderValidation } from "utils/validation";

const DEFAULT_SHAREHOLDER_DETAILS = {
  category: "individual",
  createdAt: "",
  firstName: "",
  lastName: "",
  identificationType: "",
  identification: "",
  designation: "",
  email: "",
  dob: "",
  phone: "",
  address: {
    addressLine1: "",
    addressLine2: "",
    postalCode: "",
    country: "",
    countryCode: "",
  },
  numberOfCompanyShares: "",
  valuePerShare: "",
  // firstFinancialYearRevenue: "",
  currency: "",
  documents: [],
};
const DEFAULT_CORPORATE_SHAREHOLDER_DETAILS = {
  category: "corporate",
  createdAt: "",
  companyName: "",
  uen: "",
  countryOfIncorporation: "",
  doi: "",
  website: "",
  industry: "",
  email: "",
  address: {
    addressLine1: "",
    addressLine2: "",
    postalCode: "",
    country: "",
    countryCode: "",
  },
  numberOfCompanyShares: "",
  valuePerShare: "",
  // firstFinancialYearRevenue: "",
  currency: "",
  documents: [],
};

const INITIAL_VALUES = {
  shareholderCategory: "individual",
  shareholderDetails: [],
  corporateShareholderDetails: [],
};

const getDirectorTitle = (firstName, lastName) => {
  if (firstName || lastName) {
    let title = firstName || "";
    if (lastName) {
      title += `${firstName ? " " : ""}${lastName || ""}`;
    }
    return title;
  }
  return "";
};

export const FormNomineeShareholderDetails = ({
  breadcrum,
  step,
  onNext,
  onPrevious,
  onSubmit,
  formInitValues,
  formUpload,
  getTriggerIncoporationPayments,
  ...props
}) => {
  const [submitting, setSubmitting] = useState(false);

  const [isShareholderTypeModalVisible, setIsShareholderTypeModalVisible] =
    useState(false);

  return (
    <FormLayout
      breadcrum={breadcrum}
      step={step}
      onPrevious={onPrevious}
      title={
        formInitValues?.usingShareholderDetails
          ? "Add Other Shareholder's Detail"
          : "Add Shareholder's Detail"
      }
      subtitle="A shareholder is considered to be either an individual, company, or limited liability partnership. In the case of a private limited company, there must be at least 1 shareholder and a maximum of 50 shareholders."
      formikProps={{
        initialValues: {
          ...INITIAL_VALUES,
          ...formInitValues,
        },
        enableReinitialize: true,
        validationSchema: incorporationShareholderValidation,
      }}
      {...props}
    >
      {({
        values,
        setFieldValue,
        dirty,
        errors,
        validateForm,
        setTouched,
        setErrors,
        touched,
      }) => {
        return (
          <Form>
            {/* +ADD SHAREHOLDER */}
            {[
              ...values?.shareholderDetails,
              ...values?.corporateShareholderDetails,
            ]?.length <= 0 && (
              <Grid gTC="1fr" gCG="3.2rem" mt="4rem">
                <Button
                  w="30rem"
                  type="button"
                  onClick={() => {
                    setIsShareholderTypeModalVisible(true);
                  }}
                >
                  +ADD SHAREHOLDER
                </Button>
              </Grid>
            )}

            <Accordian
              accordians={[
                ...values?.shareholderDetails,
                ...values?.corporateShareholderDetails,
              ]?.map((shareholder, index) => ({
                title:
                  getDirectorTitle(
                    shareholder?.firstName,
                    shareholder?.lastName
                  ) ||
                  shareholder?.companyName ||
                  `New Shareholder`,
                children: {
                  individual: (
                    <Shareholder
                      key={index}
                      values={values}
                      index={values?.shareholderDetails?.findIndex(
                        (item) => item?.createdAt === shareholder?.createdAt
                      )}
                      setFieldValue={setFieldValue}
                      formUpload={formUpload}
                      errors={errors}
                      touched={touched}
                    />
                  ),
                  corporate: (
                    <CorporateShareholder
                      key={index}
                      values={values}
                      index={values?.corporateShareholderDetails?.findIndex(
                        (item) => item?.createdAt === shareholder?.createdAt
                      )}
                      setFieldValue={setFieldValue}
                      formUpload={formUpload}
                      errors={errors}
                      touched={touched}
                    />
                  ),
                }[shareholder?.category],
              }))}
              // mt="8rem"
              gap="3.6rem"
              isRemovable={
                (values?.shareholderDetails?.length || 0) +
                  (values?.corporateShareholderDetails?.length || 0) >
                1
              }
              onRemove={(index) => {
                const targetItem = [
                  ...values?.shareholderDetails,
                  ...values?.corporateShareholderDetails,
                ][index];

                switch (targetItem?.category) {
                  case "individual": {
                    let cloneShareholderDetails = JSON.parse(
                      JSON.stringify(values?.shareholderDetails)
                    );

                    const targetIndex = cloneShareholderDetails?.findIndex(
                      (item) => item?.createdAt === targetItem?.createdAt
                    );

                    cloneShareholderDetails = cloneShareholderDetails?.filter(
                      (_, cloneIndex) => cloneIndex !== targetIndex
                    );
                    setFieldValue(
                      "shareholderDetails",
                      cloneShareholderDetails
                    );
                    break;
                  }
                  case "corporate": {
                    let cloneCorporateShareholderDetails = JSON.parse(
                      JSON.stringify(values?.corporateShareholderDetails)
                    );

                    const targetIndex =
                      cloneCorporateShareholderDetails?.findIndex(
                        (item) => item?.createdAt === targetItem?.createdAt
                      );

                    cloneCorporateShareholderDetails =
                      cloneCorporateShareholderDetails?.filter(
                        (_, cloneIndex) => cloneIndex !== targetIndex
                      );
                    setFieldValue(
                      "corporateShareholderDetails",
                      cloneCorporateShareholderDetails
                    );
                    break;
                  }

                  default: {
                    break;
                  }
                }
              }}
            />

            {touched?.shareholderDetails &&
              errors?.shareholderDetails &&
              typeof errors?.shareholderDetails === "string" && (
                <Error text={errors?.shareholderDetails || ""} mt="2rem" />
              )}
            {!errors?.shareholderDetails &&
              touched?.corporateShareholderDetails &&
              errors?.corporateShareholderDetails &&
              typeof errors?.corporateShareholderDetails === "string" && (
                <Error
                  text={errors?.corporateShareholderDetails || ""}
                  mt="2rem"
                />
              )}

            {/* +ADD SHAREHOLDER */}
            {[
              ...values?.shareholderDetails,
              ...values?.corporateShareholderDetails,
            ]?.length > 0 && (
              <Box
                mt="5rem"
                cursor="pointer"
                onClick={() => {
                  setIsShareholderTypeModalVisible(true);
                }}
              >
                <Text
                  variant="bodyMedium"
                  fontWeight="600"
                  color="secondary.700"
                  cursor="pointer"
                >
                  +ADD SHAREHOLDER
                </Text>
              </Box>
            )}
            <Line mt="4rem" mb="4rem" />
            <Button
              w="fit-content"
              px="4rem"
              mt="4rem"
              type="submit"
              loading={submitting}
              onClick={() => {
                validateForm(values)?.then((dataErrorBag) => {
                  const dataErrorBagKeys = Object?.keys(dataErrorBag || {});
                  if (dataErrorBagKeys?.length) {
                    setTouched(setNestedObjectValues(dataErrorBag, true));
                    setErrors(dataErrorBag);
                  } else {
                    if (dirty) {
                      setSubmitting(true);
                      onSubmit(values)
                        .then(() => {
                          getTriggerIncoporationPayments()
                            .then(() => {
                              onNext(FORM_NAMES.Cart, true);
                            })
                            .finally(() => {
                              setSubmitting(false);
                            });
                          // onNext(FORM_NAMES.Cart, true);
                        })
                        .catch(console.error)
                        .finally(() => {
                          setSubmitting(false);
                        });
                    } else {
                      setSubmitting(true);
                      getTriggerIncoporationPayments()
                        .then(() => {
                          onNext(FORM_NAMES.Cart, true);
                        })
                        .finally(() => {
                          setSubmitting(false);
                        });
                      // onNext(FORM_NAMES.Cart, true);
                    }
                  }
                });
              }}
            >
              NEXT : Payment
            </Button>
            {isShareholderTypeModalVisible && (
              <ShareholderTypeModal
                isOpen={isShareholderTypeModalVisible}
                onSubmitModal={({ type }) => {
                  switch (type) {
                    case "individual": {
                      setFieldValue("shareholderDetails", [
                        ...values?.shareholderDetails,
                        {
                          ...JSON.parse(
                            JSON.stringify(DEFAULT_SHAREHOLDER_DETAILS)
                          ),
                          createdAt: new Date().toISOString(),
                        },
                      ]);
                      break;
                    }
                    case "corporate": {
                      setFieldValue("corporateShareholderDetails", [
                        ...values?.corporateShareholderDetails,
                        {
                          ...JSON.parse(
                            JSON.stringify(
                              DEFAULT_CORPORATE_SHAREHOLDER_DETAILS
                            )
                          ),
                          createdAt: new Date().toISOString(),
                        },
                      ]);
                      break;
                    }
                    default: {
                      break;
                    }
                  }
                }}
                onClose={() => {
                  setIsShareholderTypeModalVisible(false);
                }}
              />
            )}
          </Form>
        );
      }}
    </FormLayout>
  );
};
