import React from "react";
import { Accounting as TemplateAccounting } from "templates";

export const Accounting = () => {
  return (
    <>
      <TemplateAccounting />
    </>
  );
};
