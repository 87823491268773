import * as React from "react";

function VerifiedSign(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 27 32" fill="none" {...props}>
      <path
        d="M13.09 0l11.953 2.656a1.455 1.455 0 011.139 1.42v14.526a8.726 8.726 0 01-3.887 7.261L13.091 32l-9.204-6.137A8.724 8.724 0 010 18.603V4.077c0-.683.474-1.272 1.139-1.42L13.09 0zm6.477 10.505l-7.2 7.198-4.114-4.113-2.057 2.056 6.172 6.172 9.257-9.257-2.058-2.056z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoVerifiedSign = React.memo(VerifiedSign);
export default MemoVerifiedSign;
