import React, { useState } from "react";
import { Formik } from "formik";
// import { incorporationValidateSchema } from "utils/validation";
import { Box, Flex, Breadcrum, Text } from "atoms";
import MemoLineArrowIcon from "assets/icons/LineArrow";
import MemoBulbIcon from "assets/icons/Bulb";
import FAQ from "organisms/FAQ";

export const FormLayout = ({
  breadcrum,
  onPrevious,
  step,
  children,
  title,
  subtitle,
  formikProps,
  ...props
}) => {
  const [isFAQ, setIsFAQ] = useState(false);
  return (
    <Box
      w="100%"
      bg="white"
      boxShadow="m"
      my="1.6rem"
      // px="10.4rem"
      px="6.4rem"
      pb="6.2rem"
      pt="4.4rem"
      {...props}
    >
      {breadcrum && (
        <Flex
          mb="5.6rem"
          aI="center"
          position="absolute"
          left="3.8rem"
          // left="6.8rem"
          top="4.4rem"
        >
          <Box
            color="neutral.800"
            transform="rotate(-90deg)"
            // mr="0.4rem"
            cursor="pointer"
            onClick={onPrevious}
          >
            <MemoLineArrowIcon width="1.6rem" />
          </Box>
          {/* <Breadcrum {...breadcrum} /> */}
        </Flex>
      )}
      {step && (
        <Text variant="subtitleSmall" color="neutral.500">
          {step}
        </Text>
      )}
      <Text
        variant="headingLarge"
        fontWeight="700"
        color="neutral.700"
        mt="2.4rem"
      >
        {title}
      </Text>
      <Text variant="subtitleSmall" color="neutral.500" mt="1.6rem">
        {subtitle}
      </Text>
      <Formik {...formikProps}>{children}</Formik>

      {/* <Box
        position="absolute"
        left="90%"
        top="calc(100vh - 22.8rem)"
        cursor="pointer"
        onClick={() => setIsFAQ(true)}
      >
        <Flex
          position="fixed"
          width="5.5rem"
          height="5.5rem"
          bg="primary.900"
          borderRadius="100%"
          aI="center"
          jC="center"
        >
          <MemoBulbIcon height="2.4rem" />
        </Flex>
      </Box> */}
      <Flex
        position="absolute"
        right="2.4rem"
        bottom="2.4rem"
        w="5.5rem"
        h="5.5rem"
        bg="primary.900"
        bR="100%"
        aI="center"
        jC="center"
        cursor="pointer"
        onClick={() => setIsFAQ(true)}
      >
        <MemoBulbIcon height="2.4rem" />
      </Flex>
      <FAQ isOpen={isFAQ} onClose={() => setIsFAQ(false)} />
    </Box>
  );
};
