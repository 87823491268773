import React from "react";

import { Box, Grid, Text } from "atoms";
import { AuthLayoutCommonSection, SignUpForm } from "molecules";
import { Link } from "react-router-dom";
import useAuth from "contexts/Auth";

const SignUp = () => {
  const {
    actions: { signup },
  } = useAuth();
  return (
    <>
      <Grid gTC="60% 40%" h="100vh">
        <Grid
          jC="center"
          overflow="scroll"
          height="fit-content"
          py="4.8rem"
          maxHeight="100%"
        >
          <SignUpForm onSubmit={signup} />
        </Grid>

        <Grid bg="primary.900" h="100%" px="9.6rem" py="4.8rem">
          <AuthLayoutCommonSection />
        </Grid>
      </Grid>
    </>
  );
};

export default SignUp;
