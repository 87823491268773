import React, { useState } from "react";
import { Form, setNestedObjectValues } from "formik";
import { Box, Button, Text, Line, Grid, Error } from "atoms";
import FormLayout from "../FormLayout";
import { Director } from "./components";
import { Accordian } from "organisms";
import FORM_NAMES from "templates/Incorporation/form.names";
import { incorporationDirectorValidation } from "utils/validation";

const DEFAULT_DIRECTOR_VALUES = {
  firstName: "",
  lastName: "",
  identificationType: "",
  identification: "",
  designation: "",
  email: "",
  dob: "",
  mobile: "",
  address: {
    addressLine1: "",
    addressLine2: "",
    postalCode: "",
    country: "",
    countryCode: "",
  },
  documents: [],
  // directorPOI: "",
  // directorLocalAddressProof: "",
};

const INITIAL_VALUES = {
  directorDetails: [],
};

const getDirectorTitle = (firstName, lastName) => {
  if (firstName || lastName) {
    let title = firstName || "";
    if (lastName) {
      title += `${firstName ? " " : ""}${lastName || ""}`;
    }
    return title;
  }
  return "";
};

export const FormDirectorDetails = ({
  breadcrum,
  step,
  onNext,
  onPrevious,
  onSubmit,
  formInitValues,
  formUpload,
  ...props
}) => {
  const [submitting, setSubmitting] = useState(false);

  return (
    <FormLayout
      breadcrum={breadcrum}
      step={step}
      onPrevious={onPrevious}
      title={
        formInitValues?.usingDirectorDetails
          ? "Add Other Director's Detail"
          : "Add Director's Detail"
      }
      subtitle="According to Singapore’s Companies Act, all Singaporean incorporated companies must have a director on the companies board who is a resident of Singapore at all times, including registration and incorporation of the company."
      formikProps={{
        initialValues: {
          ...INITIAL_VALUES,
          ...formInitValues,
        },
        enableReinitialize: true,
        validationSchema: incorporationDirectorValidation,
      }}
      {...props}
    >
      {({
        values,
        setFieldValue,
        dirty,
        validateForm,
        setTouched,
        setErrors,
        errors,
        touched,
      }) => {
        return (
          <Form>
            {/* +ADD Director */}
            {values?.directorDetails?.length <= 0 && (
              <Grid gTC="1fr" gCG="3.2rem" mt="8rem">
                <Button
                  w="30rem"
                  onClick={() => {
                    setFieldValue("directorDetails", [
                      ...values?.directorDetails,
                      { ...DEFAULT_DIRECTOR_VALUES },
                    ]);
                  }}
                >
                  +Add Director
                </Button>
              </Grid>
            )}

            <Accordian
              accordians={values?.directorDetails?.map((director, index) => ({
                title:
                  getDirectorTitle(director?.firstName, director?.lastName) ||
                  `New Director`,
                children: (
                  <Director
                    key={index}
                    values={values}
                    index={index}
                    formUpload={formUpload}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    touched={touched}
                  />
                ),
              }))}
              // mt="8rem"
              gap="3.6rem"
              isRemovable={values?.directorDetails?.length > 1}
              onRemove={(index) => {
                let cloneDirectorDetails = JSON.parse(
                  JSON.stringify(values?.directorDetails)
                );
                cloneDirectorDetails = cloneDirectorDetails?.filter(
                  (_, cloneIndex) => cloneIndex !== index
                );
                setFieldValue("directorDetails", cloneDirectorDetails);
              }}
            />

            {touched?.directorDetails &&
              errors?.directorDetails &&
              typeof errors?.directorDetails === "string" && (
                <Error text={errors?.directorDetails || ""} mt="2rem" />
              )}

            {values?.directorDetails?.length > 0 && (
              <Box
                mt="5.6rem"
                cursor="pointer"
                onClick={() => {
                  setFieldValue("directorDetails", [
                    ...values?.directorDetails,
                    { ...DEFAULT_DIRECTOR_VALUES },
                  ]);
                }}
              >
                <Text
                  variant="bodyMedium"
                  fontWeight="600"
                  color="secondary.700"
                >
                  +Add Director
                </Text>
              </Box>
            )}
            <Line mt="4.8rem" />
            <Button
              w="fit-content"
              px="4rem"
              mt="4rem"
              type="submit"
              loading={submitting}
              onClick={() => {
                validateForm(values)?.then((dataErrorBag) => {
                  const dataErrorBagKeys = Object?.keys(dataErrorBag || {});
                  if (dataErrorBagKeys?.length) {
                    setTouched(setNestedObjectValues(dataErrorBag, true));
                    setErrors(dataErrorBag);
                  } else {
                    if (dirty) {
                      setSubmitting(true);
                      onSubmit(values)
                        .then(() => {
                          onNext(FORM_NAMES.FormNomineeServices, true);
                        })
                        .catch(console.error)
                        .finally(() => {
                          setSubmitting(false);
                        });
                    } else {
                      onNext(FORM_NAMES.FormNomineeServices, true);
                    }
                  }
                });
              }}
            >
              Next: Shareholder Services
            </Button>
          </Form>
        );
      }}
    </FormLayout>
  );
};
