import * as React from "react";

function EyeHide(props) {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.0174 13.5035C11.7168 14.3449 10.208 14.7905 8.66797 14.788C4.68091 14.788 1.36378 11.8616 0.667969 8C0.98595 6.24321 1.85228 4.63837 3.1377 3.42491L0.823991 1.06722L1.8703 0L16.5105 14.9335L15.4642 16L13.0166 13.5035H13.0174ZM4.18327 4.49288C3.17837 5.42479 2.47835 6.64903 2.1779 8C2.40883 9.03061 2.87243 9.992 3.53182 10.8076C4.19121 11.6233 5.02817 12.2707 5.97608 12.6983C6.92398 13.1258 7.95666 13.3218 8.99191 13.2706C10.0272 13.2193 11.0364 12.9223 11.9392 12.4031L10.4397 10.8736C9.8013 11.2837 9.04523 11.4604 8.29547 11.3747C7.5457 11.289 6.84676 10.9459 6.31327 10.4018C5.77979 9.85761 5.44344 9.1447 5.35939 8.37995C5.27534 7.6152 5.44858 6.84402 5.8507 6.19289L4.18327 4.49288ZM9.34382 9.75582L6.94655 7.31064C6.81498 7.65226 6.78401 8.02561 6.85745 8.38491C6.93089 8.74421 7.10555 9.07384 7.35999 9.33337C7.61443 9.5929 7.9376 9.77104 8.28986 9.84595C8.64212 9.92085 9.00816 9.88927 9.34308 9.75507L9.34382 9.75582ZM15.1802 11.4634L14.1221 10.3848C14.6164 9.6663 14.9683 8.85618 15.158 8C14.9571 7.10227 14.5792 6.2556 14.0475 5.51145C13.5158 4.7673 12.8412 4.14121 12.0649 3.67124C11.2885 3.20127 10.4266 2.89723 9.53144 2.77761C8.6363 2.65799 7.72667 2.72528 6.85782 2.97539L5.69098 1.78524C6.61307 1.41567 7.61796 1.21203 8.66797 1.21203C12.655 1.21203 15.9722 4.1384 16.668 8C16.4414 9.2563 15.9321 10.4418 15.1802 11.4634ZM8.46314 4.61205C8.9338 4.58238 9.40527 4.65509 9.8463 4.82535C10.2873 4.99562 10.6878 5.25955 11.0213 5.59966C11.3547 5.93977 11.6135 6.34828 11.7804 6.79812C11.9473 7.24796 12.0186 7.72885 11.9895 8.20892L8.4624 4.61205H8.46314Z"
        fill="#262626"
      />
    </svg>
  );
}

const MemoEyeHideIcon = React.memo(EyeHide);
export default MemoEyeHideIcon;
