// import MemoItineraryModalHeader from "assets/icons/ItineraryModalHeader";
import React from "react";

import RModal from "react-modal";

import "./Modal.css";

const customStyles = {
  content: {
    inset: "initial",
    overflow: "visible",
    border: 0,
    // boxShadow: "0px 0px 15px rgba(196, 196, 196, 0.3)",
    height: "max-content",
    padding: 0,
  },
  overlay: {
    background: "rgba(0, 0, 0, 0.4)",
    zIndex: 1600,
    overflow: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100vw",
    height: "100vh",
  },
};

RModal.setAppElement("#root");

export const Modal = ({ isOpen, onClose, style, ...rest }) => {
  return (
    <RModal
      isOpen={isOpen}
      onRequestClose={onClose}
      shouldCloseOnOverlayClick={false}
      style={{
        overlay: { ...customStyles.overlay, ...style?.overlay },
        content: { ...customStyles.content, ...style?.content },
      }}
      {...rest}
    />
  );
};
