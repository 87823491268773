import React, { useCallback, useEffect, useRef, useState } from "react";
import { CSSTransition } from "react-transition-group";

import MemoSelectArrowUpIcon from "assets/icons/SelectArrowUp";
import { Box, Flex, Text } from "atoms";
import MemoDeleteIcon from "assets/icons/Delete";

let lastIndex = -1;
export const Accordian = ({
  accordians,
  gap,
  isRemovable = true,
  onRemove,
  isAutoOpen = true,
  ...props
}) => {
  const childRef = useRef(null);
  const [openPins, setOpenPins] = useState(
    new Array(accordians?.length).fill(undefined)
  );
  // const [lastIndex, setlastIndex] = useState(0);

  const accordianOpenStateHandler = useCallback(
    (index) => {
      const newPinning = new Array(accordians?.length).fill(undefined);
      newPinning[index] = openPins[index] ? undefined : true;
      setOpenPins(newPinning);
    },
    [accordians?.length, openPins]
  );

  useEffect(() => {
    if (isAutoOpen) {
      accordians?.length - 1 > lastIndex &&
        accordianOpenStateHandler(accordians?.length - 1);
      lastIndex = accordians?.length - 1;
      // setlastIndex(accordians?.length - 1);
    }
  }, [accordians]);

  useEffect(() => {
    lastIndex = -1;
  }, []);

  useEffect(() => {
    if (childRef.current) {
      setTimeout(() => {
        // console.log(childRef.current);
        childRef.current.focus();
      }, 1500);
    }
  }, [childRef, accordians]);
  return (
    <Box {...props}>
      {accordians?.map((accordian, index) => (
        <Box mt={gap} key={index}>
          {/* Accordian box */}
          <Flex
            w="100%"
            bg="neutral.50"
            bR="0.4rem"
            aI="center"
            jC="space-between"
            pl="1.6rem"
            pr="2.4rem"
            py="1.4rem"
            cursor="pointer"
            onClick={() => accordianOpenStateHandler(index)}
            css={{
              "& delete": {
                display: "none",
              },
              "&:hover delete": {
                display: "flex",
              },
            }}
          >
            <Flex aI="center" jC="center" w="fit-content">
              {/* index */}
              <Flex
                bR="100%"
                bg="secondary.700"
                aI="center"
                jC="center"
                w="fit-content"
                minWidth="2.4rem"
                minHeight="2.4rem"
              >
                <Text variant="bodyLarge" fontWeight="600" color="white">
                  {index + 1}
                </Text>
              </Flex>
              {/* title */}
              <Text variant="subtitleSmall" fontWeight="600" ml="1.6rem">
                {accordian?.title}
              </Text>
            </Flex>
            <Flex aI="center" jC="center" w="fit-content">
              {/* delete */}
              {isRemovable && (
                <Flex
                  bR="100%"
                  bg="white"
                  color="neutral.800"
                  aI="center"
                  jC="center"
                  w="fit-content"
                  minWidth="2.4rem"
                  minHeight="2.4rem"
                  transition="all .3sec"
                  css={{
                    "&:hover": {
                      bg: "error.50",
                      color: "error.900",
                    },
                  }}
                  cursor="pointer"
                  onClick={(e) => {
                    e?.stopPropagation();
                    onRemove(index);
                  }}
                  mr="2.4rem"
                >
                  <MemoDeleteIcon />
                </Flex>
              )}

              {/* arrow */}
              <Flex
                bR="100%"
                bg="white"
                aI="center"
                jC="center"
                w="fit-content"
                color="neutral.800"
                minWidth="2.4rem"
                minHeight="2.4rem"
                transition="all .3sec"
                cursor="pointer"
                css={{
                  "transform-origin": "center",
                }}
                transform={openPins[index] ? "rotate(-180deg)" : "rotate(0deg)"}
              >
                <MemoSelectArrowUpIcon />
              </Flex>
            </Flex>

            {/* delete */}
            {/* {isRemovable && (
              <Flex
                as="delete"
                bR="100%"
                bg="error.50"
                aI="center"
                jC="center"
                w="fit-content"
                color="error.900"
                minWidth="2.4rem"
                minHeight="2.4rem"
                position="absolute"
                top="-1.3rem"
                right="-1.2rem"
                // top="-24%"
                // right="-0.75%"
                cursor="pointer"
                onClick={(e) => {
                  e?.stopPropagation();
                  onRemove(index);
                }}
              >
                <MemoDeleteIcon />
              </Flex>
            )} */}
          </Flex>

          {/* Accordian Child */}
          <CSSTransition
            classNames="collapse"
            in={openPins[index]}
            timeout={300}
            unmountOnExit
          >
            <Box {...(openPins[index] && { ref: { childRef } })}>
              {accordian?.children}
            </Box>
          </CSSTransition>
        </Box>
      ))}
    </Box>
  );
};
