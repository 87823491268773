import React, { useState } from "react";
import { Formik, Form } from "formik";

import { Box, Text, Grid, Image, Button, Flex, Error } from "atoms";
import {
  SocialMediaIcon,
  FormInput,
  FormPhoneInput,
  FormCheckbox,
} from "molecules";
import { signupValidationSchema } from "utils/validation";
import MemoGoogleColorLogo from "assets/icons/GoogleColorLogo";
import MemoGoogleWhiteLogo from "assets/icons/GoogleWhiteLogo";
import MemoFb from "assets/icons/Fb";
import MemoLinkedin from "assets/icons/Linkedin";
import { Link } from "react-router-dom";

const iconData = [
  {
    id: "0",
    Icon: MemoGoogleColorLogo,
    Hovericon: MemoGoogleWhiteLogo,
    text: "Sign up with Google",
    onClick: () =>
      window.open(`${process.env.REACT_APP_API_HOST}/auth/google`, "_self"),
  },
  // {
  //   id: "1",
  //   Icon: MemoFb,
  //   Hovericon: MemoFb,
  //   hoverColor: "white",
  //   color: "#395185",
  // },
  // {
  //   id: "2",
  //   Icon: MemoLinkedin,
  //   Hovericon: MemoLinkedin,
  //   hoverColor: "white",
  //   color: "#0A66C2",
  // },
];

export const SignUpForm = ({ onSubmit }) => {
  const [apiError, setApiError] = useState("");
  const onSubmitHandler = (values) => {
    onSubmit(values)
      .then()
      .catch((e) => {
        setApiError(e?.message);
      });
  };
  return (
    <>
      <Box w="fit-content" mx="auto">
        <Formik
          initialValues={{
            firstName: "",
            lastName: "",
            email: "",
            password: "",
            phoneNumber: "",
            loginWithOtp: true,
            allowProductUpdates: false,
          }}
          onSubmit={onSubmitHandler}
          validationSchema={signupValidationSchema}
        >
          {({ values }) => {
            return (
              <Form>
                <Grid>
                  <Box w="6.75rem" h="13.8rem" mx="auto" mb="3rem">
                    {/* <Box w="4.5rem" h="9.2rem" mb="2rem" mx="auto"> */}
                    <Image
                      src="/images/tree-icon.png"
                      alt="logo"
                      objectFit="cover"
                    />
                  </Box>
                  <Grid
                    p="2.4rem 4rem 0rem 4rem"
                    w="43rem"
                    boxShadow="m"
                    mb="4rem"
                  >
                    <Text
                      variant="subtitleLarge"
                      fontWeight="600"
                      color="neutral.800"
                      textAlign="center"
                    >
                      Create account
                    </Text>
                    <Grid
                      mt="2.4rem"
                      gTC={`repeat(${iconData?.length},1fr)`}
                      gridGap="1.6rem"
                      justifyItems="center"
                    >
                      {iconData.map((item) => {
                        return (
                          <Box onClick={item?.onClick}>
                            <SocialMediaIcon
                              key={item?.id}
                              Icon={item?.Icon}
                              Hovericon={item?.Hovericon}
                              color={item?.color}
                              hoverColor={item?.hoverColor}
                              text={item?.text}
                              w="100%"
                              aI="center"
                              jC="center"
                            />
                          </Box>
                        );
                      })}
                    </Grid>
                    <Grid
                      mt="3.2rem"
                      gTC="repeat(3,1fr)"
                      aI="center"
                      gridGap="2rem"
                    >
                      <Box w="10.7" h="1px" bg="neutral.100" />
                      <Text
                        variant="bodyMedium"
                        color="neutral.500"
                        textAlign="center"
                      >
                        OR SIGN UP WITH
                      </Text>
                      <Box w="10.7" h="1px" bg="neutral.100" />
                    </Grid>
                    <Box mt="3.2rem">
                      <FormInput
                        name="firstName"
                        type="text"
                        label="First Name*"
                      />
                      <Box mt="2.4rem" />
                      <FormInput name="lastName" label="Last Name*" />
                      <Box mt="2.4rem" />
                      <FormInput name="email" type="email" label="Email*" />
                      <Box mt="2.4rem" />
                      <FormInput
                        name="password"
                        type="password"
                        label="Password*"
                        isErrorRelative={values?.password}
                      />
                      <Box mt="2.4rem" />
                      <FormInput
                        name="confirm_password"
                        type="password"
                        label="Repeat Password*"
                        // isErrorRelative
                      />
                    </Box>
                    <Box mt="2.4rem">
                      <FormPhoneInput name="phoneNumber" />
                    </Box>
                    {/* <Flex mt="3.2rem" aI="center">
                      <FormCheckbox name="loginWithOtp" />
                      <Text
                        variant="bodyMedium"
                        ml="0.8rem"
                        color="neutral.500"
                      >
                        Login with&nbsp;
                        <Text
                          as="span"
                          variant="bodyMedium"
                          color="neutral.600"
                        >
                          OTP&nbsp;
                        </Text>
                        every time
                      </Text>
                    </Flex> */}
                    <Flex mt="2.4rem" aI="flex-start">
                      <FormCheckbox name="allowProductUpdates" />
                      <Text
                        variant="bodyMedium"
                        ml="0.8rem"
                        color="neutral.500"
                      >
                        Allow Chartswood to stay in touch regarding new products
                        and updates. You can unsubscribe any time.
                      </Text>
                    </Flex>
                    {apiError && <Error text={apiError} mt="0.8rem" />}
                    <Button
                      mt="3.2rem"
                      variant="primary"
                      type="submit"
                      h="5rem"
                    >
                      Sign Up
                    </Button>

                    <Box my="2rem">
                      <Text
                        color="neutral.800"
                        variant="bodyLarge"
                        textAlign="center"
                      >
                        Already have an account?&nbsp;
                        <Link to="/login">
                          <Text
                            as="span"
                            color="secondary.700"
                            variant="bodyLarge"
                            cursor="pointer"
                          >
                            Login
                          </Text>
                        </Link>
                      </Text>
                    </Box>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </Box>
    </>
  );
};
