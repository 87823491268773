import React, { useEffect, useState } from "react";
import { Box, Flex, Grid, Loader, Text } from "atoms";
import { ChooseService, PricingCard } from "organisms";
import { TabHeader } from "organisms";
import MemoBulbIcon from "assets/icons/Bulb";
import useService from "contexts/Service";
import { formatCurrency } from "utils/utilities";
import { createEvent } from "@testing-library/react";
import FAQ from "organisms/FAQ";

const ChooseServiceCards = [
  {
    title: "Employment pass application",
    body: "Nulla aliquam eget mauris quis fringilla. Sed velit augue,mauris quis fringilla.",
    button: "Get Started",
    link: "employment-pass-application",
  },
  {
    title: "Visas application",
    body: "Nulla aliquam eget mauris quis fringilla. Sed velit augue,mauris quis fringilla.",
    button: "Get Started",
    link: "visas-application",
  },
];

const PricingMOM = [
  {
    title: "Dependent Pass",
    subtitle: "Description Text",
    price: "from $349",
    duration: "one-time",
    bulletData: [
      "Description Text",
      "Description Text",
      "Description Text",
      "Description Text",
      "Description Text",
      "Description Text",
      "Description Text",
    ],
  },
  {
    title: "Employment Pass",
    subtitle: "Description Text",
    price: "from $499",
    duration: "/monthly",
    bulletData: [
      "Description Text",
      "Description Text",
      "Description Text",
      "Description Text",
      "Description Text",
      "Description Text",
    ],
  },
  {
    title: "Buisness Visas",
    subtitle: "Description Text",
    price: "from $1599",
    duration: "/annual",
    bulletData: [
      "Description Text",
      "Description Text",
      "Description Text",
      "Description Text",
      "Description Text",
    ],
  },
  {
    title: "LTVP Form",
    subtitle: "Description Text",
    price: "from $1599",
    duration: "for life time",
    bulletData: [
      "Description Text",
      "Description Text",
      "Description Text",
      "Description Text",
      "Description Text",
    ],
  },
];

const DESCRIPTORS = [
  {
    displayName: "1 Free appeal (new application or renewal)",
    dependentpass: true,
    employmentpass: true,
    businessvisas: true,
    ltvpform: true,
    spass: true,
  },
  {
    displayName: "1 Free Consultation (new application or renewal)",
    dependentpass: true,
    employmentpass: true,
    businessvisas: true,
    ltvpform: true,
    spass: true,
  },
  {
    displayName: "Renewal application: $425 (Excludes MOM fee of $225)",
    dependentpass: true,
    employmentpass: false,
    businessvisas: false,
    ltvpform: false,
    spass: false,
  },
  {
    displayName: "Renewal application: $425 (Excludes MOM fee of $100)",
    dependentpass: false,
    employmentpass: true,
    businessvisas: false,
    ltvpform: false,
    spass: false,
  },
  {
    displayName: "Renewal application: $325 (Excludes MOM fee of $225)",
    dependentpass: false,
    employmentpass: false,
    businessvisas: true,
    ltvpform: false,
    spass: false,
  },
  {
    displayName: (
      <Box as="span">
        *Personalised Employment Pass can not be renewed. It is only issued
        once.
      </Box>
    ),
    dependentpass: false,
    employmentpass: false,
    businessvisas: false,
    ltvpform: true,
    spass: false,
  },
  {
    displayName: "Renewal application: $325",
    dependentpass: false,
    employmentpass: false,
    businessvisas: false,
    ltvpform: false,
    spass: true,
  },
  // downloads
  {
    displayName: (
      <Flex>
        Download Related Documents &nbsp;
        <a
          href="https://chartswood-dev.s3.ap-southeast-1.amazonaws.com/DPassLtvp.zip"
          download
          rel="noreferrer"
          target="_blank"
        >
          <Box color="primary.500" textDecoration="underline">
            here
          </Box>
        </a>
      </Flex>
    ),
    dependentpass: true,
    employmentpass: false,
    businessvisas: false,
    ltvpform: false,
    spass: false,
  },
  {
    displayName: (
      <Flex>
        Download Related Documents &nbsp;
        <a
          href="https://chartswood-dev.s3.ap-southeast-1.amazonaws.com/Employment+Pass+Form+-+Final.pdf"
          download
          rel="noreferrer"
          target="_blank"
        >
          <Box color="primary.500" textDecoration="underline">
            here
          </Box>
        </a>
      </Flex>
    ),
    dependentpass: false,
    employmentpass: true,
    businessvisas: false,
    ltvpform: false,
    spass: false,
  },
  {
    displayName: (
      <Flex>
        Download Related Documents &nbsp;
        <a
          href="https://chartswood-dev.s3.ap-southeast-1.amazonaws.com/CWA+-+Letter+Of+Consent.pdf"
          download
          rel="noreferrer"
          target="_blank"
        >
          <Box color="primary.500" textDecoration="underline">
            here
          </Box>
        </a>
      </Flex>
    ),
    dependentpass: false,
    employmentpass: false,
    businessvisas: true,
    ltvpform: false,
    spass: false,
  },
  {
    displayName: (
      <Flex>
        Download Related Documents &nbsp;
        <a
          href="https://chartswood-dev.s3.ap-southeast-1.amazonaws.com/PEP.pdf"
          download
          rel="noreferrer"
          target="_blank"
        >
          <Box color="primary.500" textDecoration="underline">
            here
          </Box>
        </a>
      </Flex>
    ),
    dependentpass: false,
    employmentpass: false,
    businessvisas: false,
    ltvpform: true,
    spass: false,
  },
  {
    displayName: (
      <Flex>
        Download Related Documents &nbsp;
        <a
          href="https://chartswood-dev.s3.ap-southeast-1.amazonaws.com/ep_spass_form8+-+Final.pdf"
          download
          rel="noreferrer"
          target="_blank"
        >
          <Box color="primary.500" textDecoration="underline">
            here
          </Box>
        </a>
      </Flex>
    ),
    dependentpass: false,
    employmentpass: false,
    businessvisas: false,
    ltvpform: false,
    spass: true,
  },
];

export const MOM = () => {
  const {
    actions: { getSubscriptionsByServiceType, createEV },
  } = useService();

  const [loading, setLoading] = useState([]);
  const [pricingLoading, setPricingLoading] = useState(false);
  const [pricingPlans, setPricingPlans] = useState([]);
  const [isFAQ, setIsFAQ] = useState(false);
  useEffect(() => {
    setPricingLoading(true);
    getSubscriptionsByServiceType("ev")
      .then((data) => {
        setPricingPlans(data);
        setLoading(new Array(data?.length).fill(false));
      })
      .finally(() => {
        setPricingLoading(false);
      });
  }, [getSubscriptionsByServiceType]);

  return (
    <Flex flexDirection="column" w="100%" h="100%" px="3.2rem">
      <TabHeader
        title="Employment Pass & Visa Application"
        breadcrum={{
          firstText: "Home",
          firstUrl: "/dashboard",
          secondText: "Employment Pass & Visa Application",
        }}
      />

      <Loader loading={pricingLoading}>
        <Box bg="white" mt="2.4rem" p="3.2rem" mb="2.4rem" boxShadow="m">
          <Text
            variant="headingSmall"
            fontWeight="600"
            // mt="2.4rem"
            color="neutral.800"
          >
            Employment Pass & Visa Application Packages
          </Text>
          <Text variant="subtitleSmall" color="neutral.500" mt="1.6rem">
            Our professionals will assist you with your pass applications from
            the first stage of your application to the final approval.
          </Text>
          <Box
            h=".1rem"
            w="100%"
            border="1px solid"
            borderColor="neutral.300"
            mt="1.6rem"
          />

          <Grid
            my="2.4rem"
            // gridAutoFlow="column"
            gTC={`repeat(3, 1fr)`}
            jC="center"
            // w="100%"
            // gCG="2.4rem"
          >
            {pricingPlans?.map((plan, index) => (
              <PricingCard
                key={index}
                id={plan?._id}
                title={plan?.displayName}
                titleMaxLines="3"
                price={formatCurrency(
                  plan?.prices?.[0]?.amount,
                  plan?.prices?.[0]?.currency
                )}
                // duration={plan?.prices?.[0]?.interval}
                bulletData={DESCRIPTORS?.filter(
                  (item) => item?.[plan?.name]
                )?.map((item) => item?.displayName)}
                onChooseLoading={loading?.[index]}
                onChoosePlan={(id) => {
                  setLoading((prev) => {
                    const newLoader = JSON.parse(JSON.stringify(prev));
                    newLoader[index] = true;
                    return newLoader;
                  });
                  id &&
                    createEV({ subscription: id }, { plan })
                      .then((data) => {
                        // data && window.open(data, "_self");
                      })
                      .finally(() => {
                        setLoading((prev) => {
                          const newLoader = JSON.parse(JSON.stringify(prev));
                          newLoader[index] = false;
                          return newLoader;
                        });
                      });
                }}
              />
            ))}
          </Grid>
        </Box>
      </Loader>

      {/* service cards */}
      {/* <Box mt="2.4rem" mb="13rem">
        <ChooseService cards={ChooseServiceCards} />
      </Box> */}
      <Box
        position="absolute"
        left="90%"
        top="calc(100vh - 22.8rem)"
        cursor="pointer"
        onClick={() => setIsFAQ(true)}
      >
        <Flex
          position="fixed"
          width="5.5rem"
          height="5.5rem"
          bg="primary.900"
          borderRadius="100%"
          aI="center"
          jC="center"
        >
          <MemoBulbIcon height="2.4rem" />
        </Flex>
      </Box>
      <FAQ isOpen={isFAQ} onClose={() => setIsFAQ(false)} />
    </Flex>
  );
};
