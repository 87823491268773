import * as React from "react";

function Invoicedetails(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 14 16" fill="none" {...props}>
      <path
        d="M2.164 15.5a1.9 1.9 0 01-1.397-.572 1.9 1.9 0 01-.572-1.397v-2.344h2.382V.5l1.125 1.125L4.827.5l1.106 1.125L7.058.5l1.125 1.125L9.308.5l1.125 1.125L11.558.5l1.125 1.125L13.808.5v13.031a1.9 1.9 0 01-.572 1.397 1.9 1.9 0 01-1.397.572H2.164zm9.675-1.125c.25 0 .453-.078.61-.234a.824.824 0 00.234-.61V2.375H3.702v8.813h7.293v2.343c0 .25.078.453.235.61.156.156.36.234.61.234zM4.695 5.338V4.213h4.5v1.125h-4.5zm0 2.512V6.725h4.5V7.85h-4.5zm6.244-2.512a.54.54 0 01-.394-.17.54.54 0 01-.168-.393.54.54 0 01.168-.394.54.54 0 01.788 0 .54.54 0 010 .788.54.54 0 01-.394.169zm0 2.418a.54.54 0 01-.394-.168.54.54 0 01-.168-.394.54.54 0 01.168-.394.54.54 0 01.788 0 .54.54 0 010 .788.54.54 0 01-.394.168zm-8.794 6.619H9.87v-2.063H1.32v1.22a.8.8 0 00.825.844zm-.825 0v-2.063 2.063z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoInvoicedetails = React.memo(Invoicedetails);
export default MemoInvoicedetails;
