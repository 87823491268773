import React from "react";

import { Box, Flex, Grid, Line, Text } from "atoms";
// import MemoPdficon from "assets/icons/Pdficon";
import { format, parseISO } from "date-fns";
import MemoFileIcon from "assets/icons/File";

export const UserDetails = ({ serviceDetails }) => {
  const bizFile = serviceDetails?.details?.documents?.find(
    (doc) => doc?.type === "bizfile"
  );

  return (
    <Box w="100%">
      <Text variant="subtitleMedium" fontWeight="600">
        Documents
      </Text>

      <Line mt="1.6rem" />

      <Grid gTC="auto 1fr" gCG="2.4rem" aI="center" mt="2.4rem">
        <Text variant="bodyLarge" color="neutral.700">
          Bizfile
        </Text>

        <Flex
          cursor="pointer"
          onClick={() => window.open(bizFile?.url, "_blank")}
        >
          <Box mt="1.2rem" color="primary.900">
            <MemoFileIcon height="2.4rem" width="2.4rem" />
          </Box>
          <Box pl="1.2rem">
            <Text variant="bodyLarge" fontWeight="600" color="neutral.700">
              {bizFile?.name}
            </Text>
            <Text variant="bodySmall" color="neutral.500">
              {bizFile?.time &&
                format(parseISO(bizFile?.time), "dd MMM, yyyy hh:mm a")}
            </Text>
          </Box>
        </Flex>
      </Grid>
    </Box>
  );
};
