import * as React from "react";

function Linkedin(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 25 25" fill="none" {...props}>
      <path
        d="M12.5.672c-6.628 0-12 5.373-12 12 0 6.628 5.372 12 12 12s12-5.372 12-12c0-6.627-5.372-12-12-12zM9.562 17.646h-2.43v-7.82h2.43v7.82zm-1.23-8.78c-.767 0-1.263-.544-1.263-1.216 0-.686.511-1.214 1.295-1.214.784 0 1.263.528 1.278 1.214 0 .672-.494 1.216-1.31 1.216zm10.105 8.78h-2.43v-4.334c0-1.008-.352-1.693-1.23-1.693-.672 0-1.07.463-1.247.91-.065.158-.081.383-.081.607v4.509h-2.431V12.32c0-.976-.032-1.793-.064-2.495h2.111l.111 1.086h.049c.32-.51 1.104-1.262 2.415-1.262 1.599 0 2.797 1.07 2.797 3.373v4.624z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoLinkedin = React.memo(Linkedin);
export default MemoLinkedin;
