import * as React from "react";

function Plus(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M6.857 6.857V0h2.286v6.857H16v2.286H9.143V16H6.857V9.143H0V6.857h6.857z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoPlus = React.memo(Plus);
export default MemoPlus;
