import * as React from "react";

function Link(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 18 18" fill="none" {...props}>
      <path
        d="M14.534 3.024a3.103 3.103 0 01.196 4.38l-2.7 2.952 1.403 1.282 2.699-2.952a5.002 5.002 0 00-.316-7.064 5.002 5.002 0 00-7.064.316l-2.7 2.952 1.403 1.283 2.699-2.953a3.103 3.103 0 014.38-.196zm-3.505 2.352L5.631 11.28l1.476 1.35 5.398-5.905-1.476-1.35zm-1.645 10.69l2.7-2.952-1.403-1.282-2.7 2.952a3.103 3.103 0 01-4.379.196 3.103 3.103 0 01-.196-4.38l2.7-2.951-1.403-1.283L2.004 9.32a5.002 5.002 0 00.316 7.063 5.002 5.002 0 007.064-.316z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoLink = React.memo(Link);
export default MemoLink;
