import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Breadcrum,
  Flex,
  Text,
  Button,
  Grid,
  Checkbox,
  DatePickerCard,
} from "atoms";
import MemoLineArrowIcon from "assets/icons/LineArrow";
import { CartSummary } from "organisms";
import { TabHeader } from "organisms";
import { Formik } from "formik";
import SERVICE_TYPE_CODE from "utils/services.type.constants";
import { FormInput, FormRadio, cwToast } from "molecules";

export default function KYC() {
  const navigate = useNavigate();
  const [step, setStep] = useState("Step 1/4");
  const [children, setChildren] = useState(
    "Customer KYC Process for children testing."
  );

  const breadcrum = {
    firstText: "Home",
    firstUrl: "/dashboard",
    secondText: "Corporate Secretarial Services",
    secondUrl: "/service/corporate-secretarial",
    thirdText: "KYC",
  };

  const [tabHeader, setTabHeader] = useState({
    title: "KYC VERIFICATION PROCESS",
    breadcrum: breadcrum,
    onPrevious: () => {
      navigate("/service/corporate-secretarial");
    },
  });

  const [formikProps, setFormikProps] = useState({
    initialValues: {
      activityType: "primary",
      primaryActivityCode: "",
      primaryBusinessDescription: "",
      secondaryActivityCode: "",
      secondaryBusinessDescription: "",
      cartSummary: "",
      cartDetails: "",
    },
    enableReinitialize: true,
    validationSchema: true,
  });

  const [form, setForm] = useState(1);
  const [date, setDate] = useState(new Date().toISOString().split("T")[0]);
  return (
    <>
      <Box w="100%" px="3rem" mb="2.4rem">
        {tabHeader && <TabHeader {...tabHeader} />}

        {/* <CartSummary position="absolute" top="0rem" right="3.2rem" zIndex="100" /> */}

        <Box mt="2.4rem">
          {/* input */}
          <Formik {...formikProps}>
            <>
              {" "}
              <CartSummary
                position="absolute"
                top="-11.6rem"
                right="0rem"
                zIndex="100"
                features={[] || []}
              />
              <Box bg="white" w="100%" py="4rem" px="6.4rem">
                {/* step */}
                {form && form === 1 ? (
                  <>
                    <Text variant="subtitleSmall" color="neutral.500">
                      Steps 1/8
                    </Text>
                    {/* title */}
                    <Text
                      variant="headingLarge"
                      fontWeight="700"
                      color="neutral.700"
                      mt="2.4rem"
                    >
                      Is the Singapore Entity owned by an Overseas Entity?
                    </Text>
                    {/* input */}
                    {/* <Formik {...formikProps}>{children}</Formik> */}
                    <Grid
                      gTC="auto auto auto"
                      gCG="3.5rem"
                      jC="start"
                      mt="4.8rem"
                    >
                      <FormRadio
                        name="activityType"
                        onClick={() => {
                          setForm(2);
                        }}
                        label="Yes"
                        value="Yes"
                      />
                      <FormRadio
                        name="activityType"
                        onClick={() => {
                          setForm(4);
                        }}
                        label="No"
                        value="No"
                      />
                    </Grid>
                  </>
                ) : (
                  <></>
                )}
                {form && form === 2 ? (
                  <>
                    <Text variant="subtitleSmall" color="neutral.500">
                      Steps 2/8
                    </Text>
                    {/* title */}
                    <Text
                      variant="headingLarge"
                      fontWeight="700"
                      color="neutral.700"
                      mt="2.4rem"
                    >
                      Please provide details of Overseas Entity
                    </Text>
                    <Flex mt="4rem">
                      {/* company name */}
                      <FormInput
                        name="NameOfEntity"
                        label="Name of Entity"
                        // hintText="* Company name availability subject to final confirmation from regulating authority."
                      />
                    </Flex>
                    <br></br>
                    <Flex>
                      <FormInput
                        name="UniqueID#1"
                        label="Unique ID #1 (Registration ID)"
                        // hintText="* Company name availability subject to final confirmation from regulating authority."
                      />
                    </Flex>
                    <br></br>
                    <Flex>
                      <FormInput
                        name="UniqueID#2"
                        label="Unique ID #2 (Registration ID)"
                        // hintText="* Company name availability subject to final confirmation from regulating authority."
                      />
                    </Flex>
                    <br></br>
                    <br></br>
                    <Flex>
                      <FormInput
                        name="fileUpload"
                        label="Please upload scanned copy of ID’s"
                        type="file"
                        // hintText="* Company name availability subject to final confirmation from regulating authority."
                      />
                      {/* Additional logic for handling the file upload on form submission */}
                    </Flex>
                  </>
                ) : (
                  <></>
                )}
                {form && form === 3 ? (
                  <>
                    <Text variant="subtitleSmall" color="neutral.500">
                      Steps 3/8
                    </Text>
                    <Text
                      variant="headingLarge"
                      fontWeight="700"
                      color="neutral.700"
                      mt="2.4rem"
                    >
                      Who is / are the Beneficial Owners (Ultimate Shareholders)
                      of the Overseas Entity?
                    </Text>
                    <br></br>
                    <br></br>
                    <br></br>
                    <Flex>
                      <FormInput
                        name="NameOfShareholder "
                        label="Name of Shareholder "
                        // hintText="* Company name availability subject to final confirmation from regulating authority."
                      />
                    </Flex>
                    <br></br>
                    <Flex>
                      <FormInput
                        name="PassportNo"
                        label="Passport No"
                        // hintText="* Company name availability subject to final confirmation from regulating authority."
                      />
                    </Flex>
                    <br></br>
                    <br></br>
                    <Flex>
                      <FormInput
                        name="Please upload scanned copy of Passport"
                        label="Please upload scanned copy of Passport"
                        type="file"
                        // hintText="* Company name availability subject to final confirmation from regulating authority."
                      />
                    </Flex>
                  </>
                ) : (
                  <></>
                )}
                {form && form === 4 ? (
                  <>
                    <Text variant="subtitleSmall" color="neutral.500">
                      Steps 4/8
                    </Text>
                    <Text
                      variant="headingLarge"
                      fontWeight="700"
                      color="neutral.700"
                      mt="2.4rem"
                    >
                      Who is / are the Shareholders of the Singapore Entity?
                    </Text>
                    <br></br>
                    <br></br>
                    <br></br>
                    <Flex>
                      <FormInput
                        name="NameOfShareholder "
                        label="Name of Shareholder "
                        // hintText="* Company name availability subject to final confirmation from regulating authority."
                      />
                    </Flex>
                    <br></br>
                    <Flex>
                      <FormInput
                        name="PassportNo"
                        label="Passport No"
                        // hintText="* Company name availability subject to final confirmation from regulating authority."
                      />
                    </Flex>
                    <br></br>
                    <br></br>
                    <Flex>
                      <FormInput
                        name="Please upload scanned copy of Passport"
                        label="Please upload scanned copy of Passport"
                        type="file"
                        // hintText="* Company name availability subject to final confirmation from regulating authority."
                      />
                    </Flex>
                  </>
                ) : (
                  <></>
                )}
                {form && form === 5 ? (
                  <>
                    <Text variant="subtitleSmall" color="neutral.500">
                      Steps 5/8
                    </Text>
                    <Text
                      variant="headingLarge"
                      fontWeight="700"
                      color="neutral.700"
                      mt="2.4rem"
                    >
                      Who is / are the Directors of the Singapore Entity?
                    </Text>
                    <br></br>
                    <br></br>
                    <br></br>
                    <Flex>
                      <FormInput
                        name="NameOfDirector "
                        label="Name of Director "
                        // hintText="* Company name availability subject to final confirmation from regulating authority."
                      />
                    </Flex>
                    <br></br>
                    <Flex>
                      <FormInput
                        name="PassportNo"
                        label="Passport No"
                        // hintText="* Company name availability subject to final confirmation from regulating authority."
                      />
                    </Flex>
                    <br></br>
                    <Flex>
                      <FormInput
                        name="DesignationOfDirector"
                        label="Designation of Director:"
                        // hintText="* Company name availability subject to final confirmation from regulating authority."
                      />
                    </Flex>
                    <br></br>
                    <br></br>
                    <Flex>
                      <FormInput
                        name="Please upload scanned copy of Passport"
                        label="Please upload scanned copy of Passport"
                        type="file"
                        // hintText="* Company name availability subject to final confirmation from regulating authority."
                      />
                    </Flex>
                  </>
                ) : (
                  <></>
                )}
                {form && form === 6 ? (
                  <>
                    <Text variant="subtitleSmall" color="neutral.500">
                      Steps 6/8
                    </Text>
                    {/* title */}
                    <Text
                      variant="headingLarge"
                      fontWeight="700"
                      color="neutral.700"
                      mt="2.4rem"
                    >
                      OFAC / PEP Check
                    </Text>
                    {/* input */}
                    {/* <Formik {...formikProps}>{children}</Formik> */}
                    <Grid
                      gTC="auto auto auto"
                      gCG="3.5rem"
                      jC="start"
                      mt="4.8rem"
                    >
                      <Text variant="subtitleSmall" color="neutral.500">
                        Declaration
                      </Text>
                      <FormRadio
                        name="activityType"
                        onClick={() => {
                          setForm(2);
                        }}
                        label="Yes"
                        value="Yes"
                      />
                      <FormRadio name="activityType" label="No" value="No" />
                    </Grid>
                    <br></br>
                    <br></br>
                    <br></br>
                    <Flex>
                      <FormInput
                        name="Details"
                        label="Details"
                        // hintText="* Company name availability subject to final confirmation from regulating authority."
                      />
                    </Flex>
                    <br></br>
                    <br></br>
                    <Grid
                      gTC="auto auto auto"
                      gCG="3.5rem"
                      jC="start"
                      mt="4.8rem"
                    >
                      <Text variant="subtitleSmall" color="neutral.500">
                        Independent Database Check
                      </Text>
                      <Checkbox
                        name="activityType"
                        onClick={() => {
                          setForm(1);
                        }}
                        label="Yes"
                        value="Yes"
                      />
                    </Grid>
                  </>
                ) : (
                  <></>
                )}
                {form && form === 7 ? (
                  <>
                    <Text variant="subtitleSmall" color="neutral.500">
                      Steps 7/8
                    </Text>
                    {/* title */}
                    <Text
                      variant="headingLarge"
                      fontWeight="700"
                      color="neutral.700"
                      mt="2.4rem"
                    >
                      Litigation / Bankruptcy Check
                    </Text>
                    {/* input */}
                    {/* <Formik {...formikProps}>{children}</Formik> */}
                    <Grid
                      gTC="auto auto auto"
                      gCG="3.5rem"
                      jC="start"
                      mt="4.8rem"
                    >
                      <Text variant="subtitleSmall" color="neutral.500">
                        Declaration
                      </Text>
                      <FormRadio
                        name="activityType"
                        onClick={() => {
                          setForm(2);
                        }}
                        label="Yes"
                        value="Yes"
                      />
                      <FormRadio name="activityType" label="No" value="No" />
                    </Grid>
                    <br></br>
                    <br></br>
                    <br></br>
                    <Flex>
                      <FormInput
                        name="Details"
                        label="Details"
                        // hintText="* Company name availability subject to final confirmation from regulating authority."
                      />
                    </Flex>
                  </>
                ) : (
                  <></>
                )}
                {form && form === 8 ? (
                  <>
                    <Text variant="subtitleSmall" color="neutral.500">
                      Steps 8/8
                    </Text>
                    {/* title */}
                    <Text
                      variant="headingLarge"
                      fontWeight="700"
                      color="neutral.700"
                      mt="2.4rem"
                    >
                      Desktop Media Check
                    </Text>
                    {/* input */}
                    {/* <Formik {...formikProps}>{children}</Formik> */}
                    <Grid
                      gTC="auto auto auto"
                      gCG="3.5rem"
                      jC="start"
                      mt="4.8rem"
                    >
                      <Text variant="subtitleSmall" color="neutral.500">
                        Declaration
                      </Text>
                      <DatePickerCard
                        value={date}
                        onChange={(e) => {
                          const localDate = new Date(
                            e.getTime() - e.getTimezoneOffset() * 60000
                          );
                          const formattedDate = localDate
                            .toISOString()
                            .split("T")[0];
                          setDate(formattedDate);
                          console.log(date, ">>>>>>>>>>>");
                        }}
                      />
                    </Grid>
                    <br></br>
                    <br></br>
                    <br></br>
                    <Flex>
                      <FormInput
                        name="Result"
                        label="Result"
                        // hintText="* Company name availability subject to final confirmation from regulating authority."
                      />
                    </Flex>
                    <br></br>
                    <br></br>
                    <br></br>
                    <Flex>
                      <FormInput
                        name="URL"
                        label="Please provide URL for matches (if any) "
                        // hintText="* Company name availability subject to final confirmation from regulating authority."
                      />
                    </Flex>
                    <br></br>
                    <br></br>
                    <br></br>
                    <Flex>
                      <FormInput
                        name="upload"
                        label="upload"
                        type="file"
                        // hintText="* Company name availability subject to final confirmation from regulating authority."
                      />
                    </Flex>
                  </>
                ) : (
                  <></>
                )}
                <Box w="100%" h="0.5px" bg="neutral.200" mt="4.8rem" />
                <Button
                  width="fit-content"
                  px="4rem"
                  mt="4rem"
                  type="button"
                  onClick={() => {
                    if (form !== 8) {
                      setForm(form + 1);
                    } else {
                      cwToast("success", "Request Submitted");
                      navigate("/dashboard");
                    }
                  }}
                >
                  {form !== 8 ? "NEXT" : "SUBMIT"}
                  {/* NEXT : Upload Documents */}
                </Button>
              </Box>
            </>
          </Formik>
        </Box>
      </Box>
    </>
  );
}
