import React, { useEffect } from "react";
import PhoneInput from "react-phone-input-2";

import Box from "atoms/Box";
// import "react-phone-input-2/lib/material.css";
import "react-phone-input-2/lib/style.css";
import "styles/phoneInput.css";
import Text from "atoms/Text";

export const PhoneField = ({ value, onChange, name, error, ...props }) => {
  useEffect(() => {
    document.getElementsByClassName("special-label")[0].innerHTML =
      "Phone number";
    // console.log(document.getElementsByClassName("special-label"));
  }, []);

  return (
    <>
      <Box
        css={{
          ".react-tel-input:hover .form-control": {
            border: "1px solid #000 !important",
          },
          ".react-tel-input:hover .flag-dropdown": {
            borderLeft: "1px solid #000 !important",
            borderTop: "1px solid #000 !important",
            borderBottom: "1px solid #000 !important",
          },
          ".react-tel-input:focus-within .form-control": {
            border: "1px solid #006C11 !important",
          },
          ".react-tel-input:focus-within .flag-dropdown": {
            borderLeft: "1px solid #006C11 !important",
            borderTop: "1px solid #006C11 !important",
            borderBottom: "1px solid #006C11 !important",
          },
          ".country:hover": {
            backgroundColor: "rgba(0, 108, 17, 0.16) !important",
            color: "#006C11 !important",
          },
          ".country.highlight": {
            backgroundColor: "rgba(0, 108, 17, 0.16) !important",
            color: "#006C11 !important",
          },
          ...((error || value?.errorFlag) && {
            ".react-tel-input:not(:focus-within) .form-control": {
              border: "1px solid #FF0800 !important",
            },
            ".react-tel-input:not(:focus-within) .flag-dropdown": {
              borderLeft: "1px solid #FF0800 !important",
              borderTop: "1px solid #FF0800 !important",
              borderBottom: "1px solid #FF0800 !important",
            },
          }),
        }}
      >
        <PhoneInput
          country={value?.countryCode ? value?.countryCode : "sg"}
          value={value || ""}
          placeholder={props?.placeholder}
          countryCodeEditable={false}
          alwaysDefaultMask={false}
          onChange={onChange}
          inputStyle={{
            color: "#7B7B7B",
            width: "100%",
            border: "1px solid #D9D9D9",
            borderRadius: "8px",
          }}
          dropdownStyle={{
            color: "#555555",
            background: "",
            fontSize: "1.6rem",
          }}
          buttonStyle={{
            backgroundColor: "#F5F5F5",
            borderRadius: "8px 0 0 8px",
          }}
          inputProps={{ name: `${name}` }}
          {...props}
        />
        {(error || value?.errorFlag) && (
          <Box>
            <Text fontSize="1.2rem" fontWeight="meduim" color="red">
              {error || value?.errorFlag}
            </Text>
          </Box>
        )}
      </Box>
    </>
  );
};
