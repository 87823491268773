import React, { useCallback, useEffect, useState } from "react";

import { Flex, Text } from "atoms";
import { ProfileAvatar } from "molecules";

export const StatusChips = ({ title, avatar, isActive, onClick, ...props }) => {
  // const [isActiveState, setIsActiveState] = useState(false);

  // const handleActiveState = useCallback(() => {
  //   // setIsActiveState((activeState) => !activeState);
  // }, []);

  // useEffect(() => {
  //   isActive && setIsActiveState(isActive);
  // }, [isActive]);

  return (
    <Flex
      p="0.7rem 1.4rem"
      border="0.5px solid"
      borderColor={isActive ? "green.700" : "neutral.200"}
      borderRadius="4px"
      aI="center"
      cursor="pointer"
      css={{
        "&:hover": {
          borderColor: "green.700",
        },
      }}
      onClick={() => {
        // handleActiveState();
        onClick?.();
      }}
      {...(isActive && { bg: "green.700" })}
      {...props}
    >
      {avatar && (
        <ProfileAvatar
          name="Allie Grator"
          avatar={avatar}
          size="a"
          mr="0.8rem"
        />
      )}
      <Text variant="bodyLarge" color={isActive ? "neutral.50" : "neutral.500"}>
        {title}
      </Text>
    </Flex>
  );
};
