import React from "react";

import { Box, Flex, Grid, Line, Text } from "atoms";
// import MemoPdficon from "assets/icons/Pdficon";
import { format, parseISO } from "date-fns";
import { getFormattedAddress } from "utils/utilities";
import MemoFileIcon from "assets/icons/File";

export const CorporateShareholder = ({ shareholder, ...props }) => {
  const bizFile = shareholder?.documents?.find(
    (doc) => doc?.type === "bizFile"
  );

  const incumbencyCertificate = shareholder?.documents?.find(
    (doc) => doc?.type === "incumbencyCertificate"
  );

  const corporateAddressProof = shareholder?.documents?.find(
    (doc) => doc?.type === "corporateAddressProof"
  );

  return (
    <Box {...props}>
      <Grid gTC="repeat(4,1fr)" gCG="2rem" mt="2.4rem">
        {/*  */}
        <Text variant="bodyLarge" color="neutral.600">
          Company Name
        </Text>
        <Text variant="bodyLarge" color="neutral.600">
          UEN/other company registration number
        </Text>
        <Text variant="bodyLarge" color="neutral.600">
          Country of Incorporation
        </Text>
        <Text variant="bodyLarge" color="neutral.600">
          Date of Incorporation
        </Text>

        {/*  */}
        <Text variant="subtitleSmall" fontWeight="600">
          {shareholder?.companyName}
        </Text>
        <Text variant="subtitleSmall" fontWeight="600">
          {shareholder?.uen}
        </Text>
        <Text variant="subtitleSmall" fontWeight="600">
          {shareholder?.countryOfIncorporation}
        </Text>
        <Text variant="subtitleSmall" fontWeight="600">
          {shareholder?.doi && format(new Date(shareholder?.doi), "dd-MM-yyyy")}
        </Text>

        {/*  */}
        <Text variant="bodyLarge" color="neutral.600" mt="2.4rem">
          Website
        </Text>
        <Text variant="bodyLarge" color="neutral.600" mt="2.4rem">
          Industry
        </Text>
        <Text variant="bodyLarge" color="neutral.600" mt="2.4rem">
          Email
        </Text>
        <Text variant="bodyLarge" color="neutral.600" mt="2.4rem">
          Address
        </Text>

        {/*  */}
        <Text variant="subtitleSmall" fontWeight="600">
          {shareholder?.website}
        </Text>
        <Text variant="subtitleSmall" fontWeight="600">
          {shareholder?.industry}
        </Text>
        <Text variant="subtitleSmall" fontWeight="600">
          {shareholder?.email}
        </Text>
        <Text variant="subtitleSmall" fontWeight="600">
          {getFormattedAddress(shareholder?.address)}
        </Text>

        {/*  */}
        <Text variant="bodyLarge" color="neutral.600" mt="2.4rem">
          Company Shares
        </Text>
        <Text variant="bodyLarge" color="neutral.600" mt="2.4rem">
          Value per Share
        </Text>
        {/* <Text variant="bodyLarge" color="neutral.600" mt="2.4rem">
          First Financial Review
        </Text> */}
        <Text variant="bodyLarge" color="neutral.600" mt="2.4rem">
          Currency
        </Text>
        <Box />

        {/*  */}
        <Text variant="subtitleSmall" fontWeight="600">
          {shareholder?.numberOfCompanyShares}
        </Text>
        <Text variant="subtitleSmall" fontWeight="600">
          {shareholder?.valuePerShare}
        </Text>
        {/* <Text variant="subtitleSmall" fontWeight="600">
          {shareholder?.firstFinancialYearRevenue &&
            format(
              new Date(shareholder?.firstFinancialYearRevenue),
              "dd-MM-yyyy"
            )}
        </Text> */}
        <Text variant="subtitleSmall" fontWeight="600">
          {shareholder?.currency}
        </Text>
        <Box />
      </Grid>

      <Line mt="2.4rem" />

      <Text variant="subtitleMedium" fontWeight="600" mt="4rem">
        Documents
      </Text>

      <Line mt="1.6rem" />

      <Grid gTC="auto 1fr" gCG="2.4rem" gRG="2.4rem" aI="center" mt="2.4rem">
        {/* Biz File  */}
        <Text variant="bodyLarge" color="neutral.700">
          Biz File
        </Text>

        <Flex
          cursor="pointer"
          onClick={() => window.open(bizFile?.url, "_blank")}
        >
          <Box mt="1.2rem" color="primary.900">
            <MemoFileIcon height="2.4rem" width="2.4rem" />
          </Box>
          <Box pl="1.2rem">
            <Text variant="bodyLarge" fontWeight="600" color="neutral.700">
              {bizFile?.name}
            </Text>
            <Text variant="bodySmall" color="neutral.500">
              {bizFile?.time &&
                format(parseISO(bizFile?.time), "dd MMM, yyyy hh:mm a")}
            </Text>
          </Box>
        </Flex>

        {/* Certificate of Incumbency */}

        <Text variant="bodyLarge" color="neutral.700">
          Certificate of Incumbency
        </Text>

        <Flex
          cursor="pointer"
          onClick={() => window.open(incumbencyCertificate?.url, "_blank")}
        >
          <Box mt="1.2rem" color="primary.900">
            <MemoFileIcon height="2.4rem" width="2.4rem" />
          </Box>
          <Box pl="1.2rem">
            <Text variant="bodyLarge" fontWeight="600" color="neutral.700">
              {incumbencyCertificate?.name}
            </Text>
            <Text variant="bodySmall" color="neutral.500">
              {incumbencyCertificate?.time &&
                format(
                  parseISO(incumbencyCertificate?.time),
                  "dd MMM, yyyy hh:mm a"
                )}
            </Text>
          </Box>
        </Flex>

        {/* Corporate Address Proof */}

        <Text variant="bodyLarge" color="neutral.700">
          Corporate Address Proof
        </Text>

        <Flex
          cursor="pointer"
          onClick={() => window.open(corporateAddressProof?.url, "_blank")}
        >
          <Box mt="1.2rem" color="primary.900">
            <MemoFileIcon height="2.4rem" width="2.4rem" />
          </Box>
          <Box pl="1.2rem">
            <Text variant="bodyLarge" fontWeight="600" color="neutral.700">
              {corporateAddressProof?.name}
            </Text>
            <Text variant="bodySmall" color="neutral.500">
              {corporateAddressProof?.time &&
                format(
                  parseISO(corporateAddressProof?.time),
                  "dd MMM, yyyy hh:mm a"
                )}
            </Text>
          </Box>
        </Flex>
      </Grid>
    </Box>
  );
};
