import React from "react";

import MemoUploadCloudIcon from "assets/icons/UploadCloudIcon";
import { Box, Button, Flex, Grid, Text } from "atoms";
import { FILE_TYPES } from "utils/constants";

export const UploadCart = ({ onChange, title, ...props }) => {
  return (
    <>
      <Flex
        w="100%"
        bg="neutral.50"
        flexDirection="column"
        // border="1px dashed"
        // borderColor="green.700"
        // bR="4px"
        backgroundImage={`url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23FF8800FF' stroke-width='1' stroke-dasharray='7%2c 11' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e")`}
        {...props}
      >
        <Text mt="2.4rem" ml="1.6rem" color="neutral.400" variant="bodySmall">
          {title || "Upload multiple files"}
        </Text>
        <Flex jC="center" aI="center" flexDirection="column">
          <Box w="3.2rem" h="3.2rem" color="secondary.700">
            <MemoUploadCloudIcon width="3.2rem" height="3.2rem" />
          </Box>
          <Text mt="2.4rem" color="neutral.800" variant="subtitleMedium">
            Select a file to upload
          </Text>
          {/* <Button variant="outlineSuccess" mt="4rem" w="fit-content" px="4rem">
            Browse computer
          </Button> */}
          <Button
            as="label"
            type="button"
            variant="outline"
            mt="4rem"
            w="fit-content"
            px="4rem"
          >
            <input
              name="logo"
              type="file"
              value={null}
              accept={FILE_TYPES}
              onChange={onChange}
              style={{ display: "none" }}
            />
            Browse computer
          </Button>
          <Grid gridAutoFlow="column" gridGap="0.8rem">
            <Box w="0.4rem" h="0.4rem" bg="neutral.500" bR="50%" mt="2.6rem" />
            <Text
              color="neutral.700"
              variant="bodySmall"
              mb="2.4rem"
              mt="1.6rem"
            >
              Format: PDF, Doc
            </Text>
          </Grid>
        </Flex>
      </Flex>
    </>
  );
};
