import * as React from "react";

function PersonalDetails(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 16 13" fill="none" {...props}>
      <path
        d="M6.5 5.906c-.825 0-1.5-.262-2.025-.787-.525-.525-.787-1.2-.787-2.025s.262-1.5.787-2.025C5 .544 5.675.28 6.5.28s1.5.263 2.025.788c.525.525.787 1.2.787 2.025s-.262 1.5-.787 2.025c-.525.525-1.2.787-2.025.787zm-6 6.019v-1.763c0-.437.11-.83.328-1.18.219-.35.535-.62.947-.807.9-.4 1.734-.688 2.503-.863.769-.175 1.51-.262 2.222-.262h.431a2.916 2.916 0 00-.168.516 5.524 5.524 0 00-.094.609H6.5c-.725 0-1.434.078-2.128.234a9.731 9.731 0 00-2.147.778c-.2.1-.35.241-.45.422-.1.182-.15.366-.15.553v.638h5.044c.062.225.137.428.225.61.087.18.194.352.319.515H.5zm11.006.825l-.187-1.238a3.322 3.322 0 01-.647-.271 2.384 2.384 0 01-.553-.403l-1.031.225-.47-.788.882-.825a2.274 2.274 0 01-.037-.469c0-.2.012-.356.037-.469l-.881-.825.469-.787 1.03.225c.15-.15.335-.284.554-.403.219-.119.434-.21.647-.272l.187-1.237h1.013l.187 1.237c.213.063.428.153.647.272.219.119.403.253.553.403l1.031-.225.47.787-.882.825c.025.113.037.27.037.47 0 .2-.012.356-.037.468l.881.825-.469.787-1.03-.224c-.15.15-.335.284-.554.403-.219.118-.434.209-.647.271l-.187 1.238h-1.013zm.506-2.269c.45 0 .813-.137 1.088-.412.275-.275.412-.638.412-1.088 0-.45-.137-.812-.412-1.087-.275-.275-.637-.413-1.088-.413-.45 0-.812.138-1.087.413-.275.275-.413.637-.413 1.087 0 .45.138.813.413 1.088.275.275.637.412 1.087.412zM6.5 4.781c.487 0 .89-.16 1.21-.478.318-.319.478-.722.478-1.21 0-.487-.16-.89-.479-1.209-.318-.318-.721-.478-1.209-.478-.487 0-.89.16-1.21.478-.318.32-.478.722-.478 1.21 0 .487.16.89.479 1.21.318.318.721.477 1.209.477z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoPersonalDetails = React.memo(PersonalDetails);
export default MemoPersonalDetails;
