import { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

export const useFormNavigation = (
  currentFormCodeInit,
  FORM_NAMES,
  getFormElement,
  pathName,
  isRefreshable = true
) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [stepCount, setStepCount] = useState("1");
  const [currentFormCode, setCurrentFormCode] = useState(currentFormCodeInit);

  const initBeforeUnLoad = () => {
    window.onbeforeunload = (event) => {
      const e = event || window.event;
      if (e?.currentTarget?.location?.pathname?.includes(pathName)) {
        e.preventDefault();
        if (e) {
          e.returnValue = "";
        }
        return "";
      }
    };

    window.onload = (event) => {
      const e = event || window.event;
      if (e?.currentTarget?.location?.pathname?.includes(pathName)) {
        e.preventDefault();
        navigate({
          pathname: pathName,
          search: `?${new URLSearchParams({
            step: 1,
            formCode: currentFormCodeInit,
          }).toString()}`,
        });
      }
    };
  };

  const formNavigationHandler = useCallback(
    (next, isStepIncrement, cb) => {
      if (stepCount && next) {
        next !== FORM_NAMES?.Cart &&
          navigate({
            pathname: "",
            search: `?${new URLSearchParams({
              step: isStepIncrement
                ? (parseInt(stepCount) || 0) + 1
                : stepCount,
              formCode: next,
            }).toString()}`,
          });
        // next === FORM_NAMES?.Cart
        //   ? navigate("/cart")
        //   : navigate({
        //       pathname: "",
        //       search: `?${new URLSearchParams({
        //         step: isStepIncrement
        //           ? (parseInt(stepCount) || 0) + 1
        //           : stepCount,
        //         formCode: next,
        //       }).toString()}`,
        //     });
        cb?.();
      }
    },
    [stepCount, navigate, FORM_NAMES?.Cart]
  );

  useEffect(() => {
    isRefreshable && initBeforeUnLoad();
    const newStepCount = searchParams.get("step");
    const newFormType = searchParams.get("formCode");

    setStepCount(newStepCount || "1");
    setCurrentFormCode(newFormType || currentFormCodeInit);

    if (!newFormType) {
      navigate({
        pathname: pathName,
        search: `?${new URLSearchParams({
          step: 1,
          formCode: currentFormCodeInit,
        }).toString()}`,
      });
    }
  }, [searchParams, navigate]);

  return [stepCount, currentFormCode, { formNavigationHandler }];
};
