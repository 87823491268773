import React from "react";
import styled from "styled-components";

import { Box, Flex, Text, Input, Error, Warning } from "atoms";

export const RadioFieldBase = styled(Box)`
  position: relative;
  display: grid;
  align-items: center;
  margin: 0 0 0 0;
  gap: 10px;
  label {
    font-size: 1.4rem;
  }

  input[type="radio"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
    position: relative;
    top: 1px;
    right: 0;
    bottom: 0;
    left: 0;
    height: 19px;
    width: 19px;
    padding: 5px;
    transition: all 0.15s ease-out 0s;
    border: 1px solid
      ${(props) =>
        props?.error
          ? props.theme.colors.error[900]
          : props.autoFocus
          ? props.theme.colors.success[900]
          : props.theme.colors.neutral[700]};
    cursor: pointer;
    display: inline-block;
    margin-right: 14px;
    outline: none;
    border-radius: 50%;
  }

  input[type="radio"]:checked {
    border: 5px solid #006c11;
    padding: 2px;
    content: "";
  }
  input[type="radio"]::after {
    border-radius: 50%;
  }
`;

export const RadioField = ({ label, error, warning, autoFocus, ...props }) => (
  <RadioFieldBase autoFocus={autoFocus} error={error}>
    <Flex aI="center" as="label">
      <Input
        type="radio"
        width="auto"
        mt={0}
        ml={0}
        mr={2}
        // autoFocus
        {...props}
        status={null}
      />
      <Text
        color="neutral.700"
        variant="bodyMedium"
        lineHeight="unset"
        alignSelf="end"
      >
        {label}
      </Text>
    </Flex>
    {/* {error && <Error text={error} />} */}
    {warning && <Warning text={warning} />}
  </RadioFieldBase>
);
