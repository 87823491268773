import React from "react";

import { Box, Grid, Line, Text } from "atoms";
import { format } from "date-fns";
import { getFormattedAddress } from "utils/utilities";

export const UserDetails = ({ serviceDetails }) => {
  return (
    <Box w="100%">
      <Grid gTC="repeat(2,1fr)">
        <Text
          variant="subtitleMedium"
          fontWeight="600"
          display="flex"
          aI="center"
        >
          Chartswood Address Services:
          <Text
            variant="subtitleMedium"
            fontWeight="600"
            color={
              serviceDetails?.details?.useCWaddress
                ? "success.700"
                : "secondary.900"
            }
            ml="1rem"
          >
            {serviceDetails?.details?.useCWaddress ? "Yes" : "No"}
          </Text>
        </Text>

        <Text
          variant="subtitleMedium"
          fontWeight="600"
          display="flex"
          aI="center"
          justifySelf="end"
          color="neutral.700"
        >
          Effective Date:
          <Text variant="subtitleMedium" fontWeight="400" ml="1rem">
            {serviceDetails?.details?.effectiveDate &&
              format(
                new Date(serviceDetails?.details?.effectiveDate),
                "dd-MM-yyyy"
              )}
          </Text>
        </Text>
      </Grid>

      <Line mt="1.6rem" />

      {serviceDetails?.details?.address && (
        <>
          <Text
            variant="bodyLarge"
            fontWeight="600"
            color="neutral.700"
            mt="4rem"
          >
            New Registered Address
          </Text>
          <Line mt="1.6rem" />
          <Grid gTC="repeat(1,max-content)" gCG="15rem" mt="2.4rem">
            <Text variant="bodyLarge" color="neutral.600">
              Address
            </Text>

            <Text variant="subtitleSmall" fontWeight="600">
              {getFormattedAddress(serviceDetails?.details?.address)}
            </Text>
          </Grid>
        </>
      )}
    </Box>
  );
};
