import React, { forwardRef } from "react";
import styled, { css } from "styled-components";
import { variant, system } from "styled-system";
import { FormattedMessage } from "react-intl";
import { Box } from "atoms";

export const TextBase = styled(Box)`
  ${variant({
    variants: {
      // new variants

      // Quire Sans
      headingSmall: {
        fontSize: "2.8rem",
        fontFamily: "primaryRegular",
        lineHeight: "3.4rem",
      },

      headingLarge: {
        fontSize: "3.2rem",
        fontFamily: "primaryRegular",
        lineHeight: "3.9rem",
      },

      // Montserrat
      subtitleSmall: {
        fontSize: "1.8rem",
        fontFamily: "secondary",
        lineHeight: "2.1rem",
      },
      subtitleMedium: {
        fontSize: "2rem",
        fontFamily: "secondary",
        lineHeight: "2.4rem",
      },
      subtitleLarge: {
        fontSize: "2.4rem",
        fontFamily: "secondary",
        lineHeight: "2.9rem",
      },

      // Myriad Pro
      bodySmall: {
        fontSize: "1.3rem",
        fontFamily: "smallRegular",
        lineHeight: "2.4rem",
      },
      bodyMedium: {
        fontSize: "1.4rem",
        fontFamily: "smallRegular",
        lineHeight: "2.4rem",
      },
      bodyLarge: {
        fontSize: "1.6rem",
        fontFamily: "smallRegular",
        lineHeight: "2.4rem",
      },
    },
  })}
  ${system({
    lineHeight: {
      property: "lineHeight",
      scale: "lineHeights",
    },
  })}
  ${system({
    fontWeight: {
      property: "fontWeight",
      scale: "fontWeights",
    },
  })}
  ${({ textTransform }) =>
    textTransform &&
    css`
      text-transform: ${textTransform};
    `}
  ${({ textDecoration }) =>
    textDecoration &&
    css`
      text-decoration: ${textDecoration};
    `}
	${({ wordBreak }) =>
    wordBreak &&
    css`
      word-break: ${wordBreak};
    `}
`;

export const Text = forwardRef(({ children, id, values, ...props }, textRef) =>
  id ? (
    <FormattedMessage id={id} values={values}>
      {(text) => (
        <TextBase
          variant="bodyLarge"
          color="neutral.800"
          {...props}
          ref={textRef}
        >
          {text}
        </TextBase>
      )}
    </FormattedMessage>
  ) : (
    <TextBase variant="bodyLarge" color="neutral.800" {...props} ref={textRef}>
      {children || null}
    </TextBase>
  )
);
