import React from "react";

import { CorporateSecretarial as TemplateCorporateSecretarial } from "templates";
export const CorporateSecretarial = () => {
  return (
    <>
      <TemplateCorporateSecretarial />
    </>
  );
};
