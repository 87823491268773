import React from "react";
import { useState } from "react";
import { Box, Flex, Text, Grid } from "atoms";
import MemoTick from "styles/icons/Tick";

export const RightTogglebutton = ({
  label,
  checked,
  error,
  warning,
  Icon,
  ...props
}) => {
  const [isHover, setIsHover] = useState(false);
  const handleMouseEnter = () => {
    setIsHover(true);
  };
  const handleMouseLeave = () => {
    setIsHover(false);
  };
  return (
    <>
      <Box
        p="0.8rem"
        w="14.4rem"
        h="12rem"
        border="1px solid"
        bR="0.8rem"
        cursor="pointer"
        borderColor={checked || isHover ? "success.900" : "neutral.300"}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        {...props}
      >
        <Flex jC="right">
          <Grid
            h="1.6rem"
            w="1.6rem"
            border="0.5px solid"
            bR="50%"
            pI="center"
            borderColor={checked || isHover ? "success.900" : "neutral.300"}
            bg={checked || isHover ? "success.900" : "white"}
          >
            <Box color="white">
              <MemoTick width="0.7rem" height="0.7em" />
            </Box>
          </Grid>
        </Flex>
        <Box
          h="4rem"
          w="4rem"
          bR="50%"
          bg={checked || isHover ? "success.50" : "neutral.50"}
          mx="auto"
        >
          <Grid pI="center" h="4rem" w="4rem" bR="50%">
            <Box color={checked || isHover ? "success.900" : "neutral.500"}>
              {Icon && <Icon width="1.4rem" height="1.2rem" />}
            </Box>
          </Grid>
        </Box>
        <Text
          mt="0.8rem"
          variant="subtitleSmall"
          fontWeight="600"
          color={checked || isHover ? "success.900" : "neutral.500"}
          textAlign="center"
        >
          {label}
        </Text>
      </Box>
    </>
  );
};
