import React, { useEffect } from "react";

import { Box, Button, Error, Grid, Line, Text } from "atoms";
import { Accordian } from "organisms";
import FormLayout from "../FormLayout";
import { FormInput } from "molecules";
import FORM_NAMES from "../form.names";
import PRODUCT_PRICES from "utils/princing.constant";
import { setNestedObjectValues } from "formik";

export const FormTransferShares = ({
  step,
  onAdd,
  onNext,
  values,
  setFieldValue,
  onSubmit,
  isLastStep,
  validateForm,
  setTouched,
  setErrors,
  errors,
  touched,
  ...props
}) => {
  useEffect(() => {
    if (
      Array.isArray(values?.transferShares) &&
      values?.transferShares?.length > 0
    ) {
      const newCartFeatures = [
        ...new Array(values?.transferShares?.length).fill({
          name: PRODUCT_PRICES.reallocate_shares.name,
          key: PRODUCT_PRICES.reallocate_shares.type,
        }),
      ];

      const newCartDetails = [
        ...new Array(values?.transferShares?.length).fill(
          PRODUCT_PRICES.reallocate_shares
        ),
      ];

      setFieldValue("cartSummary.features", newCartFeatures);
      setFieldValue("cartDetails", newCartDetails);
    } else {
      setFieldValue("cartSummary.features", []);
      setFieldValue("cartDetails", []);
    }
  }, [values?.transferShares?.length]);

  return (
    <FormLayout
      step={step}
      title="Reallocate Shares"
      // subtitle="Description Text"
      {...props}
    >
      {/* {({ values, setFieldValue }) => {
        return (
          <Form> */}
      {/* input */}
      {/* +ADD Director */}
      {(values?.transferShares || [])?.length <= 0 && (
        <Grid gTC="1fr" gCG="3.2rem" mt="4.8rem">
          <Button
            w="30rem"
            type="button"
            onClick={() => {
              setFieldValue("transferShares", [
                ...(values?.transferShares || []),
                {
                  from: { name: "", email: "" },
                  to: { name: "", email: "" },
                  numberOfShares: "",
                  valuePerShare: "",
                },
              ]);
            }}
          >
            +ADD MORE RECORDS
          </Button>
        </Grid>
      )}

      {/* <Grid gTC="1fr" gCG="3.2rem" mt="4.8rem">
        <Box
          justifySelf="end"
          alignSelf="center"
          cursor="pointer"
          onClick={() => {
            setFieldValue("transferShares", [
              ...(values?.transferShares || []),
              {
                from: { name: "", email: "" },
                to: { name: "", email: "" },
                numberOfShares: "",
                valuePerShare: "",
              },
            ]);
          }}
        >
          <Text
            variant="bodyMedium"
            fontWeight="600"
            color="secondary.700"
            cursor="pointer"
          >
            +ADD SHAREHOLDER
          </Text>
        </Box>
      </Grid> */}
      <Accordian
        accordians={(values?.transferShares || [])?.map((member, index) => {
          return {
            title:
              // `${member?.from?.name || ""} - ${member?.to?.name || ""}` ||
              `Transfer Shares From ${member?.from?.name || "-"} To ${
                member?.to?.name || ""
              }`,
            children: (
              <>
                <Box mt="5rem">
                  <Text
                    variant="subtitleSmall"
                    fontWeight="600"
                    color="neutral.700"
                  >
                    Transfer Shares From
                  </Text>
                  <Line mt="1.6rem" mb="2.4rem" />
                  {/* inputs */}
                  <Grid gTC="repeat(2,1fr)" gridGap="2.4rem">
                    <FormInput
                      name={`transferShares[${index}].from.name`}
                      label="Enter Name"
                    />
                    <FormInput
                      name={`transferShares[${index}].from.email`}
                      label="Enter Email"
                      type="email"
                    />
                  </Grid>
                </Box>

                <Box mt="5rem">
                  <Text
                    variant="subtitleSmall"
                    fontWeight="600"
                    color="neutral.700"
                  >
                    Transfer Shares To
                  </Text>
                  <Line mt="1.6rem" mb="2.4rem" />
                  {/* inputs */}
                  <Grid gTC="repeat(2,1fr)" gridGap="2.4rem">
                    <FormInput
                      name={`transferShares[${index}].to.name`}
                      label="Enter Name"
                    />
                    <FormInput
                      name={`transferShares[${index}].to.email`}
                      label="Enter Email"
                      type="email"
                    />
                  </Grid>
                </Box>

                <Grid
                  gTC="1fr 1fr"
                  gCG="2.4rem"
                  gRG="2.4rem"
                  mt="4.8rem"
                  w="fit-content"
                  aI="center"
                >
                  {/* company shares */}
                  <Text variant="subtitleSmall" fontWeight="600">
                    Add number of shares:
                  </Text>
                  <Box w="20rem" ml="2.4rem">
                    <FormInput
                      name={`transferShares[${index}].numberOfShares`}
                      label="Company Shares*"
                      type="number"
                    />
                  </Box>

                  {/* company shares */}
                  <Text variant="subtitleSmall" fontWeight="600">
                    Add Value per Share:
                  </Text>
                  <Box width="20rem" ml="2.4rem">
                    <FormInput
                      name={`transferShares[${index}].valuePerShare`}
                      label="Value per Share*"
                      type="number"
                    />
                  </Box>
                </Grid>
              </>
            ),
          };
        })}
        // mt="4.8rem"
        gap="2.4rem"
        onRemove={(index) => {
          let cloneDetails = JSON.parse(JSON.stringify(values?.transferShares));
          cloneDetails = cloneDetails?.filter(
            (_, cloneIndex) => cloneIndex !== index
          );
          setFieldValue("transferShares", cloneDetails);
        }}
      />

      {touched?.transferShares &&
        errors?.transferShares &&
        typeof errors?.transferShares === "string" && (
          <Error text={errors?.transferShares || ""} mt="2rem" />
        )}

      {/* line */}
      {(values?.transferShares || [])?.length > 0 && (
        <Box
          mt="5rem"
          cursor="pointer"
          onClick={() => {
            setFieldValue("transferShares", [
              ...(values?.transferShares || []),
              {
                from: { name: "", email: "" },
                to: { name: "", email: "" },
                numberOfShares: "",
                valuePerShare: "",
              },
            ]);
          }}
        >
          <Text
            variant="bodyMedium"
            fontWeight="600"
            color="secondary.700"
            cursor="pointer"
          >
            +ADD MORE RECORDS
          </Text>
        </Box>
      )}
      <Box w="100%" h="0.5px" bg="neutral.200" mt="4.8rem" />
      <Button
        type="button"
        width="fit-content"
        px="4rem"
        mt="4rem"
        onClick={() => {
          validateForm({
            actionType: values?.actionType,
            transferShares: values?.transferShares,
          })?.then((dataErrorBag) => {
            if (dataErrorBag?.transferShares) {
              setTouched(
                setNestedObjectValues(
                  { transferShares: dataErrorBag?.transferShares },
                  true
                )
              );
              setErrors(dataErrorBag);
            } else {
              if (isLastStep) {
                onSubmit(values);
              }
              onNext(FORM_NAMES.Cart, true);
            }
          });
        }}
      >
        NEXT : Payment
      </Button>
      {/* </Form>
        );
      }} */}
    </FormLayout>
  );
};
