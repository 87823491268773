import * as React from "react";

function Delete(props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4 3.2V0.8C4 0.587827 4.08429 0.384344 4.23431 0.234315C4.38434 0.0842854 4.58783 0 4.8 0H11.2C11.4122 0 11.6157 0.0842854 11.7657 0.234315C11.9157 0.384344 12 0.587827 12 0.8V3.2H16V4.8H14.4V15.2C14.4 15.4122 14.3157 15.6157 14.1657 15.7657C14.0157 15.9157 13.8122 16 13.6 16H2.4C2.18783 16 1.98434 15.9157 1.83431 15.7657C1.68429 15.6157 1.6 15.4122 1.6 15.2V4.8H0V3.2H4ZM9.1312 9.6L10.5456 8.1856L9.4144 7.0544L8 8.4688L6.5856 7.0544L5.4544 8.1856L6.8688 9.6L5.4544 11.0144L6.5856 12.1456L8 10.7312L9.4144 12.1456L10.5456 11.0144L9.1312 9.6ZM5.6 1.6V3.2H10.4V1.6H5.6Z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoDeleteIcon = React.memo(Delete);
export default MemoDeleteIcon;
