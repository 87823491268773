import React from "react";
import { Owner as TemplateOwner } from "templates";

export const Owner = () => {
  return (
    <>
      <TemplateOwner />
    </>
  );
};
