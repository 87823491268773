import React from "react";
import { Box, Button, Flex, Grid, Text, Line, Table } from "atoms";
import { FormInput, FormSelect } from "molecules";
import { Form } from "formik";
import MemoUploadIcon from "assets/icons/Upload";
import FormLayout from "../FormLayout";
import useService from "contexts/Service";

/**
 * reserved formik values
 *
 * @value {"local", "foriegn"} directorRole
 *
 */

export const FormShareholderDetails = ({
  breadcrum,
  step,
  onNext,
  onPrevious,
  ...props
}) => {
  const {
    actions: { switchLayoutST },
  } = useService();

  return (
    <FormLayout
      breadcrum={breadcrum}
      step={step}
      onPrevious={onPrevious}
      title="Shareholder details:"
      // subtitle="Description Text"
      {...props}
    >
      {() => {
        return (
          <Form>
            {/* Shareholder details */}
            <Box mt="8rem">
              <Text
                variant="subtitleSmall"
                fontWeight="600"
                color="neutral.700"
              >
                Shareholder Name
              </Text>
              <Line mt="1.6rem" />
              {/* Firstname and lastname */}
              <Grid mt="2rem" gridAutoFlow="column" gridGap="2.4rem">
                <FormInput
                  name="firstName"
                  placeholder="firstName"
                  label="First Name*"
                />
                <FormInput
                  name="lastName"
                  placeholder="lastName"
                  label="Last Name*"
                />
              </Grid>
            </Box>
            {/* Details Regarding UEN Number and Country */}
            <Grid mt="6.1rem" gridAutoFlow="column" gridGap="13rem">
              <Text
                variant="subtitleSmall"
                fontWeight="600"
                color="neutral.700"
              >
                UEN or Other Company Registration Number
              </Text>
              <Text
                variant="subtitleSmall"
                fontWeight="600"
                color="neutral.700"
              >
                Country of Incorporation
              </Text>
            </Grid>
            <Line mt="1.6rem" />
            <Flex mt="2rem">
              <Box flex="1">
                <FormInput name="UENnumber" label="Enter" />
              </Box>
              <Box flex="1" ml="2.4rem">
                <FormSelect name="ShareHolderDetailsCountry" label="Country" />
              </Box>
            </Flex>

            {/* Details regarding DOB and Website */}
            <Grid mt="6.1rem" gridAutoFlow="column">
              <Text
                variant="subtitleSmall"
                fontWeight="600"
                color="neutral.700"
              >
                Date of birth
              </Text>
              <Text
                variant="subtitleSmall"
                fontWeight="600"
                color="neutral.700"
              >
                Website
              </Text>
            </Grid>
            <Line mt="1.6rem" />
            <Grid mt="2rem" gridAutoFlow="column" gridGap="2.4rem">
              <FormInput name="ShareHolderDetailsDOB" label="Enter" />
              <FormInput name="ShareHolderDetailsWebsite" label="Enter" />
            </Grid>

            {/* Details of Industry and Email */}
            <Grid mt="6.1rem" gridAutoFlow="column">
              <Text
                variant="subtitleSmall"
                fontWeight="600"
                color="neutral.700"
              >
                Industry
              </Text>
              <Text
                variant="subtitleSmall"
                fontWeight="600"
                color="neutral.700"
              >
                Email
              </Text>
            </Grid>
            <Line mt="1.6rem" />
            <Grid mt="2rem" gridAutoFlow="column" gridGap="2.4rem">
              <FormInput name="ShareHolderDetailsIndustry" label="Enter" />
              <FormInput name="email" placeholder="email" label="Enter" />
            </Grid>

            {/* Address Details */}
            <Box mt="6.2rem">
              <Text
                variant="subtitleSmall"
                fontWeight="600"
                color="neutral.700"
              >
                Residential Address
              </Text>
            </Box>
            <Line mt="1.6rem" />
            <Grid
              gridTemplateColumns="repeat(2,1fr)"
              gridGap="2.4rem"
              mt="2rem"
            >
              <FormInput
                name="address"
                placeholder="address"
                label="Address Line 1"
              />
              <FormInput
                name="address"
                placeholder="address"
                label="Address Line 2"
              />
              <FormInput
                name="postalCode"
                placeholder="postalCode"
                label="Postal Code"
              />
              <FormInput
                name="ShareHolderDetailsPostalCountry"
                label="Country"
              />
            </Grid>

            {/* Upload Doc for identification */}
            <Box mt="6.2rem">
              <Text
                variant="subtitleSmall"
                fontWeight="600"
                color="neutral.700"
              >
                Upload Choosen Document
              </Text>
            </Box>
            <Line mt="1.6rem" mb="2.4rem" />
            <Table
              tableHeader={[
                { name: "Document Name" },
                { name: "Document Type" },
                { name: "Upload file" },
                { name: "Period" },
              ]}
            >
              <tbody>
                <tr>
                  <td>
                    <Text variant="bodyLarge" color="neutral.700">
                      Proof of Identification
                    </Text>
                  </td>
                  <td>
                    <FormSelect
                      name="ShareHolderDetailsPassport"
                      label="Passport"
                    />
                  </td>
                  <td>
                    <Button IconProps={{ Icon: MemoUploadIcon }}>Upload</Button>
                  </td>
                  <td>
                    <Text
                      variant="bodyLarge"
                      fontWeight="600"
                      color="neuteal.800"
                    >
                      One-Time
                    </Text>
                  </td>
                </tr>
              </tbody>
            </Table>

            {/* Upload Doc for address */}
            <Box mt="4.8rem">
              <Text
                variant="subtitleSmall"
                fontWeight="600"
                color="neutral.700"
              >
                Add Local Address Proof
              </Text>
            </Box>
            <Line mt="1.6rem" mb="2.4rem" />
            <Table
              tableHeader={[
                { name: "Document Name" },
                { name: "Document Type" },
                { name: "Upload file" },
                { name: "Period" },
              ]}
            >
              <tbody>
                <tr>
                  <td>
                    <Text variant="bodyLarge" color="neutral.700">
                      Proof of Identification
                    </Text>
                  </td>
                  <td>
                    <FormSelect
                      name="ShareHolderDetailsPassport"
                      label="Passport"
                    />
                  </td>
                  <td>
                    <Button IconProps={{ Icon: MemoUploadIcon }}>Upload</Button>
                  </td>
                  <td>
                    <Text
                      variant="bodyLarge"
                      fontWeight="600"
                      color="neuteal.800"
                    >
                      One-Time
                    </Text>
                  </td>
                </tr>
              </tbody>
            </Table>

            {/* Enter number of shares  */}
            <Box mt="4.9rem">
              <Text
                variant="subtitleSmall"
                fontWeight="600"
                color="neutral.700"
              >
                Number of shares alloted
              </Text>
            </Box>
            <Line mt="1.6rem" mb="2.4rem" />
            <Box w="48.4rem">
              <FormInput name="ShareHolderDetailsNumberOfShare" label="Enter" />
            </Box>

            <Box mt="5.6rem">
              <Text variant="bodyMedium" fontWeight="600" color="secondary.700">
                +ADD SHAREHOLDER
              </Text>
            </Box>
            <Line mt="4rem" />
            <Button
              w="fit-content"
              px="4rem"
              mt="4rem"
              type="submit"
              onClick={() => {
                onNext("Cart", true);
                switchLayoutST();
              }}
            >
              NEXT : Checkout
            </Button>
          </Form>
        );
      }}
    </FormLayout>
  );
};
