import * as React from "react";

function Minus(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 17 3" fill="none" {...props}>
      <path d="M.96 0h16v2.286h-16V0z" fill="currentColor" />
    </svg>
  );
}

const MemoMinus = React.memo(Minus);
export default MemoMinus;
