import * as React from "react";

function Upload(props) {
  return (
    <svg
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.5 12.25H13.5V7H15V13C15 13.1989 14.921 13.3897 14.7803 13.5303C14.6397 13.671 14.4489 13.75 14.25 13.75H0.75C0.551088 13.75 0.360322 13.671 0.21967 13.5303C0.0790176 13.3897 0 13.1989 0 13V7H1.5V12.25ZM8.25 4.75V10H6.75V4.75H3L7.5 0.25L12 4.75H8.25Z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoUploadIcon = React.memo(Upload);
export default MemoUploadIcon;
