import React from "react";
import { PricingAndPlan as TemplatePricingAndPlan } from "templates";

export const PricingAndPlan = () => {
  return (
    <>
      <TemplatePricingAndPlan />
    </>
  );
};
