import React from "react";

import { Box, Text, Button, Grid } from "atoms";
import MemoEmptyCartIcon from "assets/icons/EmptyCartIcon";
import { useNavigate } from "react-router-dom";

export const EmptyCart = () => {
  const navigate = useNavigate();
  return (
    <>
      <Grid
        w="112rem"
        h="43.6rem"
        bg="white"
        py="5.6rem"
        mt="7rem"
        mx="auto"
        pI="center"
        bR="0.8rem"
      >
        <Box w="13.8rem" h="10.4rem">
          <MemoEmptyCartIcon width="13.8rem" height="10.4rem" />
        </Box>
        <Text
          mt="3.2rem"
          color="green.900"
          variant="headingLarge"
          fontWeight="700"
          maxWidth="64rem"
          textAlign="center"
        >
          Your cart is empty
        </Text>
        <Text
          mt="0.8rem"
          color="neutral.700"
          variant="bodyLarge"
          maxWidth="64rem"
          textAlign="center"
        >
          Looks like you have’t added anything in your card. Go ahead and
          explore Services
        </Text>
        <Button w="19.7rem" mt="4.8rem" onClick={() => navigate("/dashboard")}>
          <Text variant="bodyLarge" fontWeight="700">
            Explore Services
          </Text>
        </Button>
      </Grid>
    </>
  );
};
