import React, { useState } from "react";
import { Box, Button, Flex, Text } from "atoms";
import { theme } from "styles";
import MemoTickIcon from "assets/icons/Tick";

export const PricingCard = ({
  title,
  subtitle,
  price,
  duration,
  bulletData,
  ...props
}) => {
  const [isHover, setIsHover] = useState(false);

  const handleMouseEnter = () => {
    setIsHover(true);
  };
  const handleMouseLeave = () => {
    setIsHover(false);
  };

  return (
    <>
      <Box
        mt="4.6rem"
        px="3.6rem"
        py="4rem"
        bR="2rem"
        w="34.9rem"
        h="fit-content"
        boxShadow={isHover ? "m" : "none"}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        background={
          isHover
            ? `linear-gradient(90deg, ${theme.colors.primary[700]} 0%, ${theme.colors.primary[900]} 92.21%)`
            : "none"
        }
        {...props}
      >
        <Text
          color={isHover ? "neutral.50" : "neutral.800"}
          variant="subtitleSmall"
          fontWeight="600"
        >
          {title}
        </Text>
        <Text
          color={isHover ? "neutral.100" : "neutral.500"}
          variant="bodyMedium"
        >
          {subtitle}
        </Text>
        <Text
          mt="3.2rem"
          color={isHover ? "neutral.50" : "neutral.800"}
          fontSize="3.2rem ! important"
          variant="subtitleLarge"
          fontWeight="700"
        >
          {price}
        </Text>
        <Text
          color={isHover ? "neutral.50" : "neutral.500"}
          variant="bodyMedium"
        >
          {duration}
        </Text>
        <Box mt="3.2rem" mb="3.2rem">
          <Button px="10rem">Select Plan</Button>
        </Box>

        {bulletData &&
          bulletData.map((index) => {
            return (
              <Flex mt="1.6rem" key={index}>
                <Box
                  color={isHover ? "neutral.50" : "success.700"}
                  mr="1.6rem"
                  py=".6rem"
                >
                  <MemoTickIcon width="1.6rem" height="1.2rem" />
                </Box>
                <Text
                  color={isHover ? "neutral.50" : "neutral.600"}
                  variant="bodyMedium"
                  fontWeight="600"
                >
                  {index?.title}
                </Text>
              </Flex>
            );
          })}
      </Box>
    </>
  );
};
