import React from "react";
import { useState } from "react";
import { theme } from "styles";
import { Box, Flex, Text } from "atoms";
import MemoArrow from "styles/icons/Arrow";
import MemoShapeBox from "assets/icons/ShapeBox";

export const Card = ({
  title,
  subtitle,
  body,
  button,
  onClick,
  Icon,
  iconColor,
  titleMaxLines,
  subTitleMaxLines,
  bodyMaxLines,
}) => {
  const [isHover, setIsHover] = useState(false);

  const handleMouseEnter = () => {
    setIsHover(true);
  };
  const handleMouseLeave = () => {
    setIsHover(false);
  };

  return (
    <>
      <Flex
        flexDirection="column"
        // jC="space-between"
        p="3.2rem"
        w="35.8rem"
        // h="28.1rem"
        h="100%"
        minHeight="28.1rem"
        bR="0.8rem"
        boxShadow="m"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        background={
          isHover
            ? `linear-gradient(90deg, ${theme.colors.primary[700]} 0%, ${theme.colors.primary[900]} 92.21%)`
            : "none"
        }
        onClick={onClick}
      >
        <Flex jC="right" color={iconColor || "primary.700"}>
          {Icon ? (
            <Icon height="2.6rem" width="2.6rem" />
          ) : (
            <MemoShapeBox height="19.16" width="30.12" />
          )}
        </Flex>

        <Text
          mt="1.2rem"
          variant="subtitleMedium"
          fontWeight="600"
          color={isHover ? "neutral.50" : "neutral.800"}
          {...(titleMaxLines && {
            css: {
              display: "-webkit-box",
              "-webkit-line-clamp": titleMaxLines,
              "-webkit-box-orient": "vertical",
              overflow: "hidden",
              "text-overflow": "ellipsis",
              height: `${(parseInt(titleMaxLines) || 0) * 2.5}rem`,
            },
          })}
        >
          {title}
        </Text>

        <Text
          mt="1.2rem"
          variant="bodyLarge"
          fontWeight="600"
          color={isHover ? "neutral.50" : "neutral.800"}
          {...(subTitleMaxLines && {
            css: {
              display: "-webkit-box",
              "-webkit-line-clamp": subTitleMaxLines,
              "-webkit-box-orient": "vertical",
              overflow: "hidden",
              "text-overflow": "ellipsis",
              // height: `${(parseInt(subTitleMaxLines) || 0) * 2.25}rem`,
            },
          })}
        >
          {subtitle}
        </Text>

        <Text
          mt="2.4rem"
          variant="bodyMedium"
          color={isHover ? "white" : "neutral.500"}
          {...(bodyMaxLines && {
            css: {
              display: "-webkit-box",
              "-webkit-line-clamp": bodyMaxLines,
              "-webkit-box-orient": "vertical",
              overflow: "hidden",
              "text-overflow": "ellipsis",
              // height: `${(parseInt(bodyMaxLines) || 0) * 2}rem`,
            },
          })}
        >
          {body}
        </Text>

        <Flex mt="3.3rem" jC="flex-start" aI="end" flex={1}>
          <Text
            variant="bodyMedium"
            fontWeight="600"
            color={isHover ? "neutral.100" : "neutral.600"}
            lineHeight="2rem"
          >
            {button}
          </Text>
          <Box
            ml="0.8rem"
            color={isHover ? "white" : "neutral.600"}
            width="3rem"
            height="2rem"
          >
            <MemoArrow width="3rem" height="2rem" />
          </Box>
        </Flex>
      </Flex>
    </>
  );
};
