import React from "react";

import { Box, Grid, Line, Text } from "atoms";

export const ShareholderTransfer = ({ shareholder, ...props }) => {
  return (
    <Box {...props}>
      <Text variant="bodyLarge" fontWeight="600" color="neutral.700" mt="4rem">
        Transfer Shares from
      </Text>

      <Line mt="1.6rem" />

      <Grid gTC="repeat(2,1fr)" gCG="15rem" mt="2.4rem">
        {/*  */}
        <Text variant="bodyLarge" color="neutral.600">
          Name
        </Text>
        <Text variant="bodyLarge" color="neutral.600">
          Email
        </Text>

        {/*  */}
        <Text variant="subtitleSmall" fontWeight="600">
          {shareholder?.from?.name}
        </Text>
        <Text variant="subtitleSmall" fontWeight="600">
          {shareholder?.from?.email}
        </Text>
      </Grid>

      <Text variant="bodyLarge" fontWeight="600" color="neutral.700" mt="4rem">
        Transfer Shares to
      </Text>

      <Line mt="1.6rem" />

      <Grid gTC="repeat(2,1fr)" gCG="15rem" mt="2.4rem">
        {/*  */}
        <Text variant="bodyLarge" color="neutral.600">
          Name
        </Text>
        <Text variant="bodyLarge" color="neutral.600">
          Email
        </Text>

        {/*  */}
        <Text variant="subtitleSmall" fontWeight="600">
          {shareholder?.to?.name}
        </Text>
        <Text variant="subtitleSmall" fontWeight="600">
          {shareholder?.to?.email}
        </Text>
      </Grid>

      <Line mt="4rem" />

      <Grid gTC="repeat(2,1fr)" gCG="15rem" mt="2.4rem">
        {/*  */}
        <Text variant="bodyLarge" color="neutral.600">
          Number of shares
        </Text>
        <Text variant="bodyLarge" color="neutral.600">
          Value per share
        </Text>

        {/*  */}
        <Text variant="subtitleSmall" fontWeight="600">
          {shareholder?.numberOfShares}
        </Text>
        <Text variant="subtitleSmall" fontWeight="600">
          {shareholder?.valuePerShare}
        </Text>
      </Grid>
    </Box>
  );
};
