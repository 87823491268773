import React, { useCallback, useEffect, useState } from "react";
import { format } from "date-fns";
import { Form, Formik } from "formik";

import { Box, Flex, Grid, Pagination, Table, Text } from "atoms";
import { FilterChips, TabHeader } from "organisms";
import { DefaultChips, FormInput, Tab } from "molecules";
import MemoFilterIcon from "assets/icons/Filter";
import { FilterModal } from "./components";
import { useSort } from "hooks/useSort";
import { usePageNumber, usePageSize } from "hooks/usePageNumber";
import useService from "contexts/Service";
import { PAGE_SIZE } from "utils/constants";
import { useNavigate } from "react-router-dom";
import { formatCurrency } from "utils/utilities";

const tabData = [
  {
    title: "All",
    count: 20,
  },
  {
    title: "Onetime payment",
    count: 2,
  },
  {
    title: "Recurring payment",
    count: 2,
  },
];

const allFilters = {
  paymentMethod: [
    {
      id: 1,
      title: "Certificate",
      isApplied: false,
    },
    {
      id: 2,
      title: "Statements",
      isApplied: false,
    },
    {
      id: 3,
      title: "KYC",
      isApplied: false,
    },
  ],
  date: { startDate: "", endDate: "", isApplied: false },
  services: [
    {
      id: 1,
      title: "Corporate secretarial",
      isApplied: false,
    },
    {
      id: 2,
      title: "Employment Pass & Visa Application",
      isApplied: false,
    },
    {
      id: 3,
      title: "Accounting",
      isApplied: false,
    },
    {
      id: 4,
      title: "Tax",
      isApplied: false,
    },
  ],
};

export const Payments = () => {
  const navigate = useNavigate();

  const {
    actions: { getPayments },
  } = useService();
  // filter
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [filterState, setFilterState] = useState(
    JSON.parse(JSON.stringify(allFilters))
  );

  // tabs
  const [activeTab, setActiveTab] = useState(0);

  const [total, setTotal] = useState(0);
  const sort = useSort();
  const page = Number(usePageNumber());
  const pageSize = Number(usePageSize());

  const [payments, setPayments] = useState([]);

  const fetchDocuments = useCallback(async () => {
    getPayments({
      sortBy: sort || "createdAt:desc",
      limit: pageSize,
      skip: (page - 1) * pageSize,
    }).then((data) => {
      setPayments(data?.data);
      setTotal(data?.total || 0);
    });
  }, [getPayments, sort, pageSize, page]);

  useEffect(() => {
    fetchDocuments();
  }, [fetchDocuments]);

  return (
    <Box px="3.2rem">
      {/* header */}
      <TabHeader
        title="Payment Detail"
        breadcrum={{
          firstText: "Home",
          firstUrl: "/dashboard",
          secondText: "Payment Detail",
        }}
        onPrevious={() => navigate("/dashboard")}
      />

      {/* body */}
      <Box bg="#fff" mt="2.6rem">
        <Flex width="100%" aI="center" jC="space-between" px="2.4rem">
          <Box>
            {/* title */}
            <Text
              variant="subtitleMedium"
              fontWeight="600"
              color="neutral.800"
              pt="4rem"
            >
              Payment History
            </Text>

            {/* filter chips */}
            <Grid mt="1.6rem" gCG="1.7rem" gRG="1.7rem" gTC="repeat(4, auto)">
              {/* paymentMethod chips */}
              {filterState?.paymentMethod
                ?.filter((item) => item?.isApplied)
                ?.map((item, index) => (
                  <FilterChips key={index} title={item?.title} />
                ))}

              {/* services chips */}
              {filterState?.services
                ?.filter((item) => item?.isApplied)
                ?.map((item, index) => (
                  <FilterChips key={index} title={item?.title} />
                ))}

              {/* date chips */}
              {filterState?.date?.isApplied && (
                <FilterChips
                  title={`${
                    filterState?.date?.startDate &&
                    format(filterState?.date?.startDate, "dd MMM")
                  } - ${
                    filterState?.date?.endDate &&
                    format(filterState?.date?.endDate, "dd MMM")
                  }`}
                />
              )}
            </Grid>
          </Box>

          {/* <Flex aI="center">
            <Box>
              <Formik initialValues={{ search: "" }}>
                {() => (
                  <Form>
                    <Box w="25.8rem" h="5rem">
                      <FormInput
                        name="search"
                        label="Search"
                        isSearchable
                        isValidationBehaviour={false}
                      />
                    </Box>
                  </Form>
                )}
              </Formik>
            </Box>

            <Box
              color="neutral.500"
              ml="2rem"
              cursor="pointer"
              onClick={() => {
                setIsFilterModalOpen(true);
              }}
            >
              <MemoFilterIcon height="2.4rem" />
            </Box>

            {isFilterModalOpen && (
              <FilterModal
                isOpen={isFilterModalOpen}
                initFilterState={filterState}
                onClose={() => {
                  setIsFilterModalOpen(false);
                }}
                onApplyFilter={(data) => {
                  setFilterState(data);
                  setIsFilterModalOpen(false);
                }}
                onClearAll={() => {
                  setFilterState(JSON.parse(JSON.stringify(allFilters)));
                  setIsFilterModalOpen(false);
                }}
              />
            )}
          </Flex> */}
        </Flex>

        {/* tabs */}
        {/* <Tab
          tabs={tabData}
          activeTab={activeTab}
          setActiveTab={(index) => setActiveTab(index)}
          mt="4rem"
        /> */}

        {/* tabular data */}
        <Table
          variant="secondary"
          tableHeader={[
            { name: "Service name", sortKey: "serviceName" },
            { name: "Payment method" },
            { name: "Pricing", sortKey: "amount" },
            { name: "Created At", sortKey: "createdAt" },
            { name: "Status", sortKey: "status" },
          ]}
        >
          <tbody>
            {payments?.map((ticket, index) => {
              return (
                <tr key={index}>
                  {/* column:  Service name*/}
                  <td>
                    <Text
                      variant="bodyMedium"
                      color="neutral.700"
                      textTransform="uppercase"
                    >
                      {ticket?.services?.[0]?.serviceType}
                    </Text>
                  </td>

                  {/* column:  Subservices*/}
                  {/* <td>
                    <Text
                      variant="bodyMedium"
                      color="neutral.700"
                      truncate
                      maxWidth="10rem"
                      overflow="hidden"
                      css={{
                        "white-space": "nowrap",
                        "text-overflow": "ellipsis",
                      }}
                    >
                      Change in bussiness code
                    </Text>
                  </td> */}

                  {/* column:  Payment method*/}
                  <td>
                    <Text variant="bodyMedium" color="neutral.700">
                      Visa 2342
                    </Text>
                  </td>

                  {/* column:  Pricing*/}
                  <td>
                    <Text variant="bodyMedium" color="neutral.700">
                      {formatCurrency(ticket?.amount / 100)}
                    </Text>
                  </td>

                  {/* column:  Service ends on*/}
                  <td>
                    <Text variant="bodyMedium" color="neutral.700">
                      {format(new Date(ticket?.createdAt), "dd-MM-yyy")}
                    </Text>
                  </td>

                  {/* column:  Status*/}
                  <td>
                    {
                      {
                        // created: (
                        //   <DefaultChips title="Created" variant="statement" />
                        // ),
                        // "in-progress": (
                        //   <DefaultChips
                        //     title="In Progress"
                        //     variant="progress"
                        //   />
                        // ),
                        pending: (
                          <DefaultChips
                            title="Pending"
                            variant="pending"
                            w="100%"
                          />
                        ),
                        failed: (
                          <DefaultChips
                            title="Failed"
                            variant="delayed"
                            w="100%"
                          />
                        ),
                        paid: (
                          <DefaultChips
                            title="Paid"
                            variant="completed"
                            w="100%"
                          />
                        ),
                      }[ticket?.status]
                    }
                    {/* <DefaultChips title="Paid" variant="completed" w="100%" /> */}
                  </td>
                </tr>
              );
            })}
            {total === 0 && (
              <tr>
                <td colSpan="10">
                  <Text textAlign="center">No Payment Record Found</Text>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        {total > PAGE_SIZE && (
          // <Box mt="2rem">
          <Pagination totalRecords={total} />
          // </Box>
        )}
      </Box>
    </Box>
  );
};
