import * as React from "react";
function Arrow(props) {
  return (
    <>
      <svg
        className="icon"
        width="1em"
        height="1em"
        viewBox="0 0 27 24"
        fill="#555555"
        {...props}
      >
        <path
          d="M26.06 13.06a1.5 1.5 0 000-2.12l-9.545-9.547a1.5 1.5 0 10-2.122 2.122L22.88 12l-8.486 8.485a1.5 1.5 0 102.122 2.122l9.546-9.546zM0 13.5h25v-3H0v3z"
          fill="currentColor"
        />
      </svg>
    </>
  );
}

const MemoArrow = React.memo(Arrow);
export default MemoArrow;
