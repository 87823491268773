import React from "react";
import { Box, Button, Line, Text } from "atoms";
import { FormLayout } from "templates/CorporateSecretarial/components";
import UploadCart from "organisms/UploadCart";

export const FormUploadCart = ({ step, onNext, ...props }) => {
  return (
    <>
      <FormLayout step={step} title="Kindly provide Details:" {...props}>
        <Text color="neutral.500" variant="subtitleSmall">
          Please upload
          <Text
            as="span"
            variant="subtitleSmall"
            color="neutral.800"
            ml="0.5rem"
          >
            passport, Visa application form
          </Text>
        </Text>
        <Box mt="4.8rem">
          <UploadCart />
        </Box>
        <Line mt="4rem" />
        <Button w="fit-content" px="4rem" mt="4rem" onClick={onNext}>
          Next : Payment
        </Button>
      </FormLayout>
    </>
  );
};
