import React, { useEffect, useState } from "react";
import { Box, Flex, Grid, Loader, Text } from "atoms";
import { PricingCard } from "organisms";
import { TabHeader } from "organisms";
import MemoBulbIcon from "assets/icons/Bulb";
import useService from "contexts/Service";
import { formatCurrency } from "utils/utilities";
import FAQ from "organisms/FAQ";

// const ChooseServiceCards = [
//   {
//     title: "Xero accounting sofware implementation",
//     body: "Nulla aliquam eget mauris quis fringilla. Sed velit augue,mauris quis fringilla.",
//     button: "Get Started",
//     link: "xero-accounting-sofware-implementation",
//   },
//   {
//     title: "Monthly book Keeping",
//     body: "Nulla aliquam eget mauris quis fringilla. Sed velit augue,mauris quis fringilla.",
//     button: "Get Started",
//     link: "monthly-book-keeping",
//   },
//   {
//     title: "Yearly book keeping",
//     body: "Nulla aliquam eget mauris quis fringilla. Sed velit augue,mauris quis fringilla.",
//     button: "Get Started",
//     link: "yearly-book-keeping",
//   },
//   {
//     title: "Outsource Financial Service",
//     body: "Nulla aliquam eget mauris quis fringilla. Sed velit augue,mauris quis fringilla.",
//     button: "Get Started",
//     link: "outsource-financial-service",
//   },
// ];

const DESCRIPTORS = [
  {
    displayName: "Filling with ACRA",
    monthly: true,
    quarterly: true,
    yearly: true,
  },
  {
    displayName: "Preparation of Unaudited Financial Statements",
    monthly: true,
    quarterly: true,
    yearly: true,
  },
  {
    displayName: "Review Financial Position",
    monthly: true,
    quarterly: true,
    yearly: true,
  },
  {
    displayName: "Business Advisory",
    monthly: true,
    quarterly: true,
    yearly: true,
  },
  {
    displayName: "Filling of ECI and Corporate Taxes",
    monthly: true,
    quarterly: true,
    yearly: true,
  },
  {
    displayName: "Provide Monthly Reports",
    monthly: true,
    quarterly: false,
    yearly: false,
  },

  {
    displayName: "Provide Quarterly Reports",
    monthly: false,
    quarterly: true,
    yearly: false,
  },
];

export const Accounting = () => {
  const {
    actions: { getSubscriptionsByServiceType, createAccounting },
  } = useService();

  const [loading, setLoading] = useState([]);
  const [pricingLoading, setPricingLoading] = useState(false);
  const [pricingPlans, setPricingPlans] = useState([]);
  const [isFAQ, setIsFAQ] = useState(false);
  useEffect(() => {
    setPricingLoading(true);
    getSubscriptionsByServiceType("accounting")
      .then((data) => {
        setPricingPlans(data);
        setLoading(new Array(data?.length).fill(false));
      })
      .finally(() => {
        setPricingLoading(false);
      });
  }, [getSubscriptionsByServiceType]);

  return (
    <>
      <Flex flexDirection="column" w="100%" h="100%" px="3.2rem">
        <TabHeader
          title="Set up Accounting"
          breadcrum={{
            firstText: "Home",
            firstUrl: "/dashboard",
            secondText: "Set up Accounting",
          }}
        />
        <Loader loading={pricingLoading}>
          {/* service cards */}
          <Box bg="white" mt="2.4rem" p="3.2rem" mb="2.4rem" boxShadow="m">
            <Text
              variant="headingSmall"
              fontWeight="600"
              // mt="2.4rem"
              color="neutral.800"
            >
              Accounting Packages
            </Text>
            <Text variant="subtitleSmall" color="neutral.500" mt="1.6rem">
              Our outsourcing accounting services enables you to keep managing
              your core business operations without the inconvenience of
              managing an extra in-house accounting team.
            </Text>
            <Box
              h=".1rem"
              w="100%"
              border="1px solid"
              borderColor="neutral.300"
              mt="1.6rem"
            />

            <Grid
              my="2.4rem"
              // gridAutoFlow="column"
              gTC={`repeat(${pricingPlans?.length || 0}, 1fr)`}
              jC="center"
              // gCG="2.4rem"
            >
              {pricingPlans?.map((plan, index) => (
                <PricingCard
                  key={index}
                  id={plan?._id}
                  title={plan?.displayName}
                  price={`from ${formatCurrency(
                    plan?.prices?.[0]?.amount,
                    plan?.prices?.[0]?.currency
                  )}`}
                  // duration={plan?.prices?.[0]?.interval}
                  bulletData={DESCRIPTORS?.filter(
                    (item) => item?.[plan?.name]
                  )?.map((item) => item?.displayName)}
                  onChooseLoading={loading?.[index]}
                  onChoosePlan={(id) => {
                    setLoading((prev) => {
                      const newLoader = JSON.parse(JSON.stringify(prev));
                      newLoader[index] = true;
                      return newLoader;
                    });

                    id &&
                      createAccounting({ subscription: id }, { plan }).finally(
                        () => {
                          setLoading((prev) => {
                            const newLoader = JSON.parse(JSON.stringify(prev));
                            newLoader[index] = false;
                            return newLoader;
                          });
                        }
                      );
                  }}
                  templateTitle={"Accounting "}
                />
              ))}
            </Grid>
          </Box>
        </Loader>
        <Box
          position="absolute"
          left="90%"
          top="calc(100vh - 22.8rem)"
          cursor="pointer"
          onClick={() => setIsFAQ(true)}
        >
          <Flex
            position="fixed"
            width="5.5rem"
            height="5.5rem"
            bg="primary.900"
            borderRadius="100%"
            aI="center"
            jC="center"
          >
            <MemoBulbIcon height="2.4rem" />
          </Flex>
        </Box>
        <FAQ isOpen={isFAQ} onClose={() => setIsFAQ(false)} />
      </Flex>
    </>
  );
};
