import React from "react";

import { Box, Flex, Grid, Line, Text } from "atoms";
// import MemoPdficon from "assets/icons/Pdficon";
import { format, parseISO } from "date-fns";
import { getFormattedAddress } from "utils/utilities";
import MemoFileIcon from "assets/icons/File";

const getDirectorTitle = (firstName, lastName) => {
  if (firstName || lastName) {
    let title = firstName || "";
    if (lastName) {
      title += `${firstName ? " " : ""}${lastName || ""}`;
    }
    return title;
  }
  return "";
};

export const Director = ({ director, ...props }) => {
  const proofOfDetails = director?.documents?.find(
    (doc) => doc?.type === "directorPOI"
  );

  const localAddressProof = director?.documents?.find(
    (doc) => doc?.type === "directorLocalAddressProof"
  );
  return (
    <Box {...props}>
      <Grid
        gTC="repeat(4,1fr)"
        gCG="2rem"
        // gCG="15rem"
        mt="2.4rem"
      >
        {/*  */}
        <Text variant="bodyLarge" color="neutral.600">
          Name
        </Text>
        <Text variant="bodyLarge" color="neutral.600">
          Identification ({director?.identificationType})
        </Text>
        <Text variant="bodyLarge" color="neutral.600">
          Designation
        </Text>
        <Text variant="bodyLarge" color="neutral.600">
          Email
        </Text>

        {/*  */}
        <Text variant="subtitleSmall" fontWeight="600">
          {getDirectorTitle(director?.firstName, director?.lastName)}
        </Text>
        <Text variant="subtitleSmall" fontWeight="600">
          {director?.identification}
        </Text>
        <Text variant="subtitleSmall" fontWeight="600">
          {director?.designation}
        </Text>
        <Text variant="subtitleSmall" fontWeight="600">
          {director?.email}
        </Text>

        {/*  */}
        <Text variant="bodyLarge" color="neutral.600" mt="2.4rem">
          Date of Birth
        </Text>
        <Text variant="bodyLarge" color="neutral.600" mt="2.4rem">
          Mobile
        </Text>
        <Text variant="bodyLarge" color="neutral.600" mt="2.4rem">
          Address
        </Text>
        <Box />

        {/*  */}
        <Text variant="subtitleSmall" fontWeight="600">
          {director?.dob && format(new Date(director?.dob), "dd-MM-yyyy")}
        </Text>
        <Text variant="subtitleSmall" fontWeight="600">
          {director?.mobile}
        </Text>
        <Text variant="subtitleSmall" fontWeight="600">
          {getFormattedAddress(director?.address)}
        </Text>
        <Box />
      </Grid>

      <Line mt="2.4rem" />

      <Text variant="subtitleMedium" fontWeight="600" mt="4rem">
        Documents
      </Text>

      <Line mt="1.6rem" />

      <Grid gTC="auto 1fr" gCG="2.4rem" gRG="2.4rem" aI="center" mt="2.4rem">
        {/* proof of details */}
        <Text variant="bodyLarge" color="neutral.700">
          Proof of Details
        </Text>

        <Flex
          cursor="pointer"
          onClick={() => window.open(proofOfDetails?.url, "_blank")}
        >
          <Box mt="1.2rem" color="primary.900">
            <MemoFileIcon height="2.4rem" width="2.4rem" />
          </Box>
          <Box pl="1.2rem">
            <Text variant="bodyLarge" fontWeight="600" color="neutral.700">
              {proofOfDetails?.name}
            </Text>
            <Text variant="bodySmall" color="neutral.500">
              {proofOfDetails?.time &&
                format(parseISO(proofOfDetails?.time), "dd MMM, yyyy hh:mm a")}
            </Text>
          </Box>
        </Flex>

        {/* local address proof */}

        <Text variant="bodyLarge" color="neutral.700">
          Local Address Proof
        </Text>

        <Flex
          cursor="pointer"
          onClick={() => window.open(localAddressProof?.url, "_blank")}
        >
          <Box mt="1.2rem" color="primary.900">
            <MemoFileIcon height="2.4rem" width="2.4rem" />
          </Box>
          <Box pl="1.2rem">
            <Text variant="bodyLarge" fontWeight="600" color="neutral.700">
              {localAddressProof?.name}
            </Text>
            <Text variant="bodySmall" color="neutral.500">
              {localAddressProof?.time &&
                format(
                  parseISO(localAddressProof?.time),
                  "dd MMM, yyyy hh:mm a"
                )}
            </Text>
          </Box>
        </Flex>
      </Grid>
    </Box>
  );
};
