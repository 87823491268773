import React from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "atoms";
import { Form } from "formik";
import { FormManagerLayout } from "templates/CorporateSecretarial/FormManager";
import { FormUploadCart } from "../components";

const breadcrum = {
  firstText: "Tax",
  secondText: "Step 1",
};
export const CorporateTaxAndECIFilling = () => {
  const navigate = useNavigate();
  return (
    <FormManagerLayout
      tabHeader={{
        title: "Corporate Tax Filing / ECI Filing",
        breadcrum: breadcrum,
      }}
      formikProps={{
        initialValues: {
          step: 1,
        },
      }}
    >
      {({ values, setFieldValue }) => {
        return (
          <Form>
            <Box w="100%">
              <FormUploadCart
                step={`Step ${values.step}`}
                onNext={() => navigate("/cart")}
              />
            </Box>
          </Form>
        );
      }}
    </FormManagerLayout>
  );
};
