import * as React from "react";

function File(props) {
  return (
    <svg
      width="64"
      height="80"
      viewBox="0 0 64 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8 0C3.6 0 0.0400009 3.6 0.0400009 8L0 72C0 76.4 3.56 80 7.96 80H56C60.4 80 64 76.4 64 72V24L40 0H8ZM36 28V6L58 28H36Z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoFileIcon = React.memo(File);
export default MemoFileIcon;
