import React from "react";
import { Box, Button, Grid } from "atoms";
import { FormInput } from "molecules";
import FormLayout from "../FormLayout";
import { setNestedObjectValues } from "formik";

export const FormNewCorporateSecretaryDetails = ({
  step,
  onNext,
  values,
  validateForm,
  setTouched,
  setErrors,
  errors,
  touched,
  ...props
}) => {
  return (
    <FormLayout
      step={step}
      onNext={onNext}
      title="Kindly provide New Corporate Secretarial Details"
      subtitle="Please provide the details of your new Corporate Secretary that best represents your company’s interests."
      {...props}
    >
      {/* {({ values }) => {
        return (
          <Form> */}
      {/* input */}
      <Grid gTC="1fr 1fr" gridGap="1.6rem" mt="4.8rem">
        <FormInput
          name="newCorporateSecretaryName"
          label="Corporate Secretary Full Name"
        />
        <FormInput
          name="newCorporateSecretaryEmail"
          label="Corporate Secretary Email"
        />
      </Grid>
      {/* line */}
      <Box w="100%" h="0.5px" bg="neutral.200" mt="4.8rem" />
      <Button
        type="button"
        width="fit-content"
        px="4rem"
        mt="4rem"
        onClick={() => {
          validateForm({
            newCorporateSecretaryName: values?.newCorporateSecretaryName,
            newCorporateSecretaryEmail: values?.newCorporateSecretaryEmail,
          })?.then((dataErrorBag) => {
            if (
              dataErrorBag?.newCorporateSecretaryName ||
              dataErrorBag?.newCorporateSecretaryEmail
            ) {
              setTouched(
                setNestedObjectValues(
                  {
                    newCorporateSecretaryName:
                      dataErrorBag?.newCorporateSecretaryName,
                    newCorporateSecretaryEmail:
                      dataErrorBag?.newCorporateSecretaryEmail,
                  },
                  true
                )
              );
              setErrors(dataErrorBag);
            } else {
              onNext();
            }
          });
        }}
      >
        NEXT : Upload Documents
      </Button>
      {/* </Form>
        );
      }} */}
    </FormLayout>
  );
};
