import React from "react";

import { Box } from "atoms";

export const Sheet = ({ isOpen, onClose, children, ...props }) => {
  return (
    <>
      {isOpen && (
        /**
         * when modal is open for profile switch or user dropdown,
         * this will create a wrapper behind that dropdown so when
         * user clicks outside of it, it will close
         */
        <Box
          top="0"
          left="0"
          right="0"
          bottom="0"
          zIndex="90"
          position="fixed"
          onClick={onClose}
          {...props}
        />
      )}
      {isOpen && children}
    </>
  );
};
