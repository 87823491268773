import { useCallback, useEffect, useState } from "react";
import { PAGE_SIZE } from "utils/constants";
import { getComanyActivityCodes } from "utils/utilities";

const newDeepCopyCodes = (activityCodes, newCodes) => {
  const deepActivityCodes = JSON.parse(JSON.stringify(activityCodes));

  const uniqueActivityCodes = [...deepActivityCodes, ...newCodes]?.filter(
    (task, index, self) =>
      self?.findIndex((o) => o?.value === task?.value) === index
  );

  return uniqueActivityCodes;
};

export const useActivityCode = (defaultValue) => {
  const [activityCodes, setActivityCodes] = useState([]);

  const loadNextActivtityCodes = useCallback(() => {
    getComanyActivityCodes({
      pageSize: PAGE_SIZE,
      pageNo: Math.floor(activityCodes?.length / PAGE_SIZE) + 1,
    })?.then((data) =>
      setActivityCodes((prev) => newDeepCopyCodes(prev, data))
    );
  }, [getComanyActivityCodes, activityCodes?.length]);

  const searchActivityCodes = useCallback(
    (searchKeyword) => {
      const preExisting = activityCodes?.filter((activity) =>
        activity?.label?.toLowerCase()?.includes(searchKeyword?.toLowerCase())
      );
      if (preExisting && preExisting?.length <= PAGE_SIZE) {
        const keywords = searchKeyword?.split("-");
        const codeKeyword = (
          isNaN(parseInt(keywords?.[0]))
            ? ""
            : parseInt(keywords?.[0])?.toString()
        )?.replace(" ", "");
        const nameKeyword = (
          keywords?.[1] || (isNaN(parseInt(keywords?.[0])) ? keywords?.[0] : "")
        )?.trim();
        getComanyActivityCodes({
          pageSize: PAGE_SIZE,
          pageNo: 1,
          name: nameKeyword,
          code: codeKeyword,
        })?.then((data) =>
          setActivityCodes((prev) => newDeepCopyCodes(prev, data))
        );
      }
    },
    [getComanyActivityCodes, activityCodes]
  );

  const fetchActivityCodes = useCallback(() => {
    getComanyActivityCodes({
      pageSize: PAGE_SIZE,
      pageNo: 1,
    })?.then((data) =>
      setActivityCodes((prev) => newDeepCopyCodes(prev, data))
    );
  }, [getComanyActivityCodes, defaultValue]);

  useEffect(() => {
    fetchActivityCodes();
    defaultValue && searchActivityCodes(defaultValue);
  }, []);

  return [activityCodes, loadNextActivtityCodes, searchActivityCodes];
};
