import React from "react";
import { Link } from "react-router-dom";

import { Flex, Grid, Text } from "atoms";
import { Card } from "molecules";

export const ChooseService = ({
  cards,
  title,
  titleMaxLines,
  subTitleMaxLines,
  bodyMaxLines,
  ...props
}) => {
  return (
    <Flex jC="flex-start" aI="center" flexDirection="column" {...props}>
      {/* title */}
      {title && (
        <Text
          variant="subtitleLarge"
          fontWeight="600"
          my="4rem"
          textAlign="center"
        >
          {title}
        </Text>
      )}

      {/* cards */}
      <Grid gTC="1fr 1fr 1fr" gRG="2.4rem" gCG="2.4rem">
        {cards.map((cardItem, index) =>
          cardItem?.link ? (
            // eslint-disable-next-line no-undef
            <Link to={cardItem?.link} key={index}>
              <Card
                title={cardItem?.title}
                subtitle={cardItem?.subtitle}
                body={cardItem?.body}
                button={cardItem?.button}
                Icon={cardItem?.icon}
                iconColor={cardItem?.iconColor}
                titleMaxLines={titleMaxLines}
                bodyMaxLines={bodyMaxLines}
                subTitleMaxLines={subTitleMaxLines}
              />
            </Link>
          ) : (
            <Card
              // eslint-disable-next-line no-undef
              key={index}
              title={cardItem?.title}
              subtitle={cardItem?.subtitle}
              body={cardItem?.body}
              button={cardItem?.button}
              Icon={cardItem?.icon}
              iconColor={cardItem?.iconColor}
            />
          )
        )}
      </Grid>
    </Flex>
  );
};
