import React, { useCallback, useState } from "react";
import { Box, Flex, Line, Text, ToggleButton, Grid, Button } from "atoms";
import { ProfileDetails } from "./components";
import { Form, Formik } from "formik";
import { personaldetailValidation } from "utils/validation";
import useAuth from "contexts/Auth";
import {
  getCountries,
  uploadFile,
  verifyUploadFileImageExtension,
} from "utils/utilities";
import { cwToast, FormInput, FormSelect } from "molecules";
import { postcodeValidatorExistsForCountry } from "postcode-validator";

export const PersonalDetails = () => {
  const {
    state: { userData, isGoogleSSO },
    actions: { updateUser, changePassword },
  } = useAuth();

  const [imageUploading, setImageUploading] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const setProfilePicture = useCallback(
    (e) => {
      const file = e.target.files[0];
      if (verifyUploadFileImageExtension(file)) {
        setImageUploading(true);
        uploadFile(file)
          .then(({ location }) => {
            updateUser({ avatar: location });
            cwToast("success", "Profile Image Updated");
            // setValue(location);
          })
          .catch(console.error)
          .finally(() => setImageUploading(false));
      } else {
        cwToast(
          "error",
          "Invalid extension found. Valid extensions are .png, .jpg, .jpeg"
        );
      }
    },
    [updateUser]
  );

  const handleLoginWithOtp = useCallback(
    (value) => {
      updateUser({ loginWithOtp: !!value });
      !!value
        ? cwToast("success", "2FA Enabled")
        : cwToast("warning", "2FA Disabled");
    },
    [updateUser]
  );

  const handleFormSubmit = useCallback(
    ({ email, password, phoneNumber, ...values }) => {
      setSubmitting(true);
      const formData = {
        // phoneNumber: phoneNumber?.dialCode
        //   ? `${phoneNumber?.dialCode} ${phoneNumber?.phoneNumber}`
        //   : "",
        phoneNumber,
        ...values,
      };

      updateUser(formData)
        .then(() => {
          cwToast("success", "Profile Updated");
        })
        .catch(console.error)
        .finally(() => {
          setSubmitting(false);
        });
    },
    [updateUser]
  );

  return (
    <>
      <Box pl="3.2rem" pr="3.2rem" mt="2.4rem">
        {/* Account Profile Section */}
        <Grid mt="1.6rem" gridAutoFlow="column">
          <Text variant="subtitleLarge" fontWeight="600" color="neutral.800">
            Account Profile
          </Text>
          {!isGoogleSSO && (
            <Flex jC="flex-end" aI="center">
              <Text variant="bodyMedium" color="neutral.800">
                Login with OTP every time
              </Text>
              <Box ml="1.6rem">
                <ToggleButton
                  value={userData?.loginWithOtp}
                  onChange={handleLoginWithOtp}
                />
              </Box>
            </Flex>
          )}
        </Grid>
        <Line mt="1.6rem" />
        <Box mt="2.4rem">
          <Formik
            initialValues={{
              password: "password",
              firstName: userData?.firstName || "",
              lastName: userData?.lastName || "",
              email: userData?.email || "",
              phoneNumber: userData?.phoneNumber,
              postalCode: userData?.postalCode || "",
              addressOne: userData?.addressOne || "",
              addressTwo: userData?.addressTwo || "",
              country: userData?.country || "",
              countryCode: userData?.countryCode || "",
              state: userData?.state || "",
            }}
            onSubmit={handleFormSubmit}
            validationSchema={personaldetailValidation}
          >
            {({ values, setFieldValue,isSubmitting }) => {
              return (
                <Form>
                  {/* Profile Details Section */}
                  <ProfileDetails
                    firstName={userData?.firstName || userData?.email}
                    lastName={userData?.lastName}
                    email={userData?.email}
                    avatar={userData?.avatar}
                    setProfilePicture={setProfilePicture}
                    isImageUploading={imageUploading}
                    onSubmitResetPassword={changePassword}
                  />
                  {/* User Address Details */}
                  <Box
                    w="100%"
                    p="2.4rem 2.2rem"
                    bg="white"
                    bR="0.8rem"
                    mt="2.4rem"
                  >
                    <Text variant="subtitleLarge" fontWeight="600">
                      Address
                    </Text>
                    <Line mt="1.6rem" />
                    {/* user address, state, country, postal-code*/}
                    <Grid
                      gridTemplateColumns="repeat(2,1fr)"
                      gridGap="2.4rem"
                      mt="2rem"
                    >
                      <FormInput
                        name="addressOne"
                        label="Address Line 1"
                        isValidationBehaviour={false}
                      />
                      <FormInput
                        name="addressTwo"
                        label="Address Line 2"
                        isValidationBehaviour={false}
                      />
                      <FormSelect
                        name="country"
                        label="Country"
                        options={getCountries()}
                        onChange={(country) =>
                          setFieldValue("countryCode", country?.countryCode)
                        }
                      />
                      <FormInput
                        name="postalCode"
                        label="Postal Code"
                        // isValidationBehaviour={false}
                        disabled={
                          !postcodeValidatorExistsForCountry(
                            values?.countryCode
                          )
                        }
                      />

                      {/* <FormSelect
                        name="state"
                        label="State"
                        options={getStates(values?.countryCode)}
                      /> */}
                    </Grid>
                  </Box>
                  <Flex mt="4rem" aI="center" jC="flex-end">
                    <Button type="submit" w="11.4rem" loading={submitting}>
                      Save
                    </Button>
                  </Flex>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </Box>
    </>
  );
};
