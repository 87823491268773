import React from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "atoms";
import { Form } from "formik";
import { FormDocUploadTable, FormUploadCart } from "../components";
import { FormManagerLayout } from "templates/CorporateSecretarial/FormManager";
const breadcrum = {
  firstText: "Accounting",
  secondText: "Step 1",
};

export const OutsourceFinancialService = () => {
  const navigate = useNavigate();
  return (
    <FormManagerLayout
      tabHeader={{
        title: "Out Source Financial Service",
        breadcrum: breadcrum,
      }}
      formikProps={{
        initialValues: {
          step: 1,
        },
      }}
    >
      {({ values, setFieldValue }) => {
        return (
          <Form>
            <Box w="100%">
              {
                {
                  1: (
                    <FormDocUploadTable
                      step={`Step ${values.step}`}
                      onNext={() => setFieldValue("step", values.step + 1)}
                    />
                  ),
                  2: (
                    <FormUploadCart
                      step={`Step ${values.step}`}
                      onNext={() => navigate("/cart")}
                    />
                  ),
                }[values?.step]
              }
            </Box>
          </Form>
        );
      }}
    </FormManagerLayout>
  );
};
