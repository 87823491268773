import React, {
  forwardRef,
  useRef,
  useMemo,
  useState,
  useCallback,
} from "react";
import { useEffect } from "react";
import styled from "styled-components";
import mergeRefs from "utils/mergeRefs";
import {
  space,
  layout,
  color,
  position,
  border,
  shadow,
  typography,
  variant,
} from "styled-system";

import { Flex, Box } from "atoms";
import MemoEyeHideIcon from "assets/icons/EyeHide";
import MemoEyeShowIcon from "assets/icons/EyeShow";
import MemoTickWithCircleIcon from "assets/icons/TickWithCircle";
import MemoCrossWithCircleIcon from "assets/icons/CrossWithCircle";
import MemoSearchIcon from "assets/icons/Search";
import MemoCalendarIcon from "assets/icons/Calendar";
import MemoInfoIcon from "assets/icons/Info";
import { Tooltip } from "molecules";

export const InputBase = styled.input`
  width: 100%;
  font-weight: 600;

  ${variant({
    variants: {
      primary: {
        p: "1.3rem 1.6rem",
        border: "1px solid",
        borderColor: "neutral.300",
        borderRadius: "8px",
        color: "neutral.800",
        outline: "none",
        fontWeight: "400",
        fontFamily: "smallRegular",
        fontSize: "1.6rem",
        height: "50px",
        "&:hover": {
          borderColor: "neutral.900",
        },
        "&:focus, &:active": {
          borderColor: "neutral.400",
        },
        "&::placeholder": {
          color: "neutral.500",
        },
      },

      error: {
        display: "block",
        color: "error.900",
        border: "none",
        borderBottom: "1px solid",
        borderColor: "error.900",
      },
    },
  })};

  ${space}
  ${layout}
  ${color}
  ${position}
  ${border}
  ${shadow}
  ${typography}
`;

export const InputBaseTextArea = styled.textarea`
  width: 100%;
  font-weight: 600;

  ${variant({
    variants: {
      primary: {
        p: "1.3rem 1.6rem",
        border: "1px solid",
        borderColor: "neutral.300",
        borderRadius: "8px",
        color: "neutral.800",
        outline: "none",
        fontWeight: "400",
        fontFamily: "smallRegular",
        fontSize: "1.6rem",
        height: "50px",
        "&:hover": {
          borderColor: "neutral.900",
        },
        "&:focus, &:active": {
          borderColor: "neutral.400",
        },
        "&::placeholder": {
          color: "neutral.500",
        },
      },

      error: {
        display: "block",
        color: "error.900",
        border: "none",
        borderBottom: "1px solid",
        borderColor: "error.900",
      },
    },
  })};

  ${space}
  ${layout}
  ${color}
  ${position}
  ${border}
  ${shadow}
  ${typography}
`;

export const Input = forwardRef(
  (
    {
      autoFocus,
      variant: v = "primary",
      type,
      onChange,
      status,
      isSearchable,
      isDate,
      tooltipProps,
      ...props
    },
    ref
  ) => {
    const inputRef = useRef(null);

    const [isOpen, setOpen] = useState(false);
    const [toggleVisible, setToggleVisible] = useState(false);

    const toggle = useCallback(() => setOpen((prev) => !prev), []);

    const isPassword = useMemo(() => type === "password", [type]);

    const onChangeHandler = useCallback(
      (e) => {
        if (typeof onChange === "function") {
          onChange(e);
        }
        setToggleVisible(e?.currentTarget?.value?.length > 0);
      },
      [onChange]
    );
    useEffect(() => {
      if (autoFocus) {
        inputRef?.current?.focus?.();
      }
      // eslint-disable-next-line
    }, []);

    const condition =
      !props?.disabled &&
      (status !== null || isPassword || isDate || isSearchable);

    return (
      <>
        {condition && (
          <Flex
            position="absolute"
            top={0}
            right={0}
            cursor="pointer"
            alignItems="center"
            height="5rem"
            // width="4.5rem"
            justifyContent="center"
            mr="1.7rem"
          >
            {/* <Text textDecoration="underline" firstLetterCapital> */}
            {/* {status !== null && (
              <Box color={status && "success.700"}>
                {status ? (
                  <MemoTickWithCircleIcon width="1.6rem" height="1.6rem" />
                ) : (
                  <MemoCrossWithCircleIcon width="1.6rem" height="1.6rem" />
                )}
              </Box>
            )} */}
            {isPassword && (
              <Box
                ml="1.5rem"
                onClick={props?.disabled ? undefined : toggle}
                width="1.6rem"
                height="1.6rem"
              >
                {isOpen ? (
                  <Box color="neutral.800" width="1.6rem" height="1.6rem">
                    <MemoEyeShowIcon width="1.6rem" height="1.6rem" />
                  </Box>
                ) : (
                  <MemoEyeHideIcon width="1.6rem" height="1.6rem" />
                )}
              </Box>
            )}
            {isDate && (
              <Box
                color="neutral.600"
                ml="1.5rem"
                width="1.6rem"
                height="1.6rem"
              >
                <MemoCalendarIcon width="1.6rem" height="1.6rem" />
              </Box>
            )}
            {isSearchable && (
              <Box
                color="neutral.400"
                ml="1.5rem"
                width="1.6rem"
                height="1.6rem"
                // position="absolute"
                // right="1.6rem"
                // top="1.7rem"
              >
                <MemoSearchIcon width="1.6rem" height="1.6rem" />
              </Box>
            )}
            {/* {errorTooltip && error && (
              <Tooltip
                render={error}
                maxWidth="none !important"
                isTooltipArrow
                {...tooltipProps}
              >
                <Box color="error.900" ml="1.5rem">
                  <MemoInfoIcon width="1.6rem" height="1.6rem" />
                </Box>
              </Tooltip>
            )} */}
            {/* </Text> */}
          </Flex>
        )}
        {type === "textarea" ? (
          <InputBaseTextArea
            type={isPassword && isOpen ? "text" : type}
            variant={v}
            ref={mergeRefs(ref, inputRef)}
            onChange={onChangeHandler}
            {...props}
            pr={isPassword ? "4.5rem" : undefined}
          />
        ) : (
          <InputBase
            type={isPassword && isOpen ? "text" : type}
            variant={v}
            ref={mergeRefs(ref, inputRef)}
            onChange={onChangeHandler}
            {...props}
            pr={isPassword ? "4.5rem" : undefined}
          />
        )}
      </>
    );
  }
);
