import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Box, Flex, Image, Text } from "atoms";
import { TOPBAR_HEIGHT } from "./utilities/constants";
import { ProfileAvatar } from "molecules";
import MemoCartIcon from "assets/icons/Cart";
import MemoNotificationBellIcon from "assets/icons/NotificationBell";
// import LogoCW from "assets/images/logo.png";
import LogoCW from "assets/images/sidebarLogo.png";
import { UserDropdown } from "./components/UserDropdown";
import { NotificationDropdown } from "./components/NotificationDropdown";
import { CartDropDown } from "./components/CartDropDown";
import useAuth from "contexts/Auth";
import { OWNER_ROLE_CODE, USER_ROLE_CODE } from "utils/constants";
import useService from "contexts/Service";
import { formatCurrency } from "utils/utilities";
import useNotifications from "contexts/notification.context";

export const DefaultHeader = ({ isHeaderLogo }) => {
  const {
    state: { userData },
  } = useAuth();
  const {
    state: { cart },
  } = useService();
  const {
    state: { notifications, unreadCount, loading: notificationLoading },
    actions: { getAllNotifications, onRead, onReadAll },
  } = useNotifications();

  useEffect(() => {
    getAllNotifications();
  }, [getAllNotifications]);

  const navigate = useNavigate();

  const [isUserDropdownOpen, setIsUserDropdownOpen] = useState(false);
  const [isNotificationDropdownOpen, setIsNotificationDropdownOpen] =
    useState(false);
  const [isCartDropdownOpen, setIsCartDropdownOpen] = useState(false);

  return (
    <Flex
      bg="white"
      as="header"
      aI="center"
      h={TOPBAR_HEIGHT}
      w="100%"
      jC="space-between"
      bR="8px"
      px="2.4rem"
      py="1.2rem"
    >
      {/* left start */}
      <Box cursor="pointer" onClick={() => navigate("/dashboard")}>
        {isHeaderLogo && <Image src={LogoCW} alt="Logo" height="2.8rem" />}
      </Box>

      {/* right start */}
      <Flex aI="center">
        {/* cart */}
        <Box>
          <Flex
            aI="center"
            cursor="pointer"
            onClick={() => setIsCartDropdownOpen(true)}
          >
            <Box mr=".8rem" color="primary.900" width="2rem" height="2rem">
              <MemoCartIcon width="2rem" height="2rem" />
            </Box>
            <Text
              color="primary.900"
              variant="bodyLarge"
              fontWeight="600"
              lineHeight="2rem"
            >
              {formatCurrency(cart?.eval)}
            </Text>
          </Flex>
          {/* Cart DropDown */}
          <CartDropDown
            isOpen={isCartDropdownOpen}
            onClose={() => setIsCartDropdownOpen(false)}
          />
        </Box>

        {/* notification bell */}
        <Box ml="2.4rem">
          <Flex
            cursor="pointer"
            onClick={() => setIsNotificationDropdownOpen(true)}
          >
            <Box color="neutral.500">
              <MemoNotificationBellIcon width="1.6rem" height="1.8rem" />
            </Box>
            {unreadCount > 0 && (
              <Flex
                bg="primary.900"
                position="absolute"
                bR="100%"
                w="1.8rem"
                h="1.8rem"
                left="47.33%"
                bottom="49%"
                aI="center"
                jC="center"
              >
                <Text color="white" variant="bodyMedium" fontWeight="600">
                  {unreadCount}
                </Text>
              </Flex>
            )}
          </Flex>

          {/* Notification Dropdown */}
          <NotificationDropdown
            isOpen={isNotificationDropdownOpen}
            onClose={() => setIsNotificationDropdownOpen(false)}
            notifications={notifications}
            unreadCount={unreadCount}
            loading={notificationLoading}
            onRead={onRead}
            onReadAll={onReadAll}
            role={userData?.role}
          />
        </Box>

        {/* profile */}
        <Box ml="2.4rem">
          <ProfileAvatar
            // name={`${userData?.firstName || ""} ${userData?.lastName || ""}`}
            name={
              {
                [USER_ROLE_CODE]: `${userData?.firstName || ""} ${
                  userData?.lastName || ""
                }`,
                [OWNER_ROLE_CODE]: userData?.email,
              }[userData?.role]
            }
            avatar={userData?.avatar}
            onClick={() => setIsUserDropdownOpen(true)}
            cursor="pointer"
          />
          {/* user dropdown */}
          <UserDropdown
            isOpen={isUserDropdownOpen}
            onClose={() => setIsUserDropdownOpen(false)}
            userName={`${userData?.firstName || ""} ${
              userData?.lastName || ""
            }`}
            email={userData?.email}
          />
        </Box>
      </Flex>
    </Flex>
  );
};
