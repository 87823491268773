import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { FormDetails } from "../components";
import { FormManagerLayout } from "./FormManagerLayout";
import FORM_NAMES from "../components/form.names";
import { useFormNavigation } from "hooks/useFormNavigation";
import { onChangeFormUploadFile } from "utils/utilities";
import useService from "contexts/Service";
import SERVICE_TYPE_CODE from "utils/services.type.constants";
import { Form } from "formik";
import PRODUCT_PRICES from "utils/princing.constant";
import { csToActShareholderValidation } from "utils/validation";

const FORM_COMPONENT_NAMED_NAVIGATION = [
  {
    name: FORM_NAMES.FormDetails,
    Component: FormDetails,
    onNext: {
      next: FORM_NAMES.Cart,
      isStepIncrement: false,
    },
    attributes: {
      detailSubtitle:
        "We provide nominee shareholder service to match your individual needs for shareholder",
      docs: [
        {
          id: "bizfile",
          name: "Bizfile",
          description: "(jpeg, jpg, png, pdf)",
        },
      ],
      isLastStep: true,
    },
  },
];

const getFormElement = (name) => {
  return (
    FORM_COMPONENT_NAMED_NAVIGATION?.find((item) => item?.name === name) ||
    FORM_COMPONENT_NAMED_NAVIGATION?.find(
      (item) => item?.name === FORM_NAMES.FormDetails
    )
  );
};

const breadcrum = {
  firstText: "Home",
  firstUrl: "/dashboard",
  secondText: "Corporate Secretarial Services",
  secondUrl: "/service/corporate-secretarial",
  thirdText: "To Act as Nominee Shareholder Service",
};

export const ToActNomineeShareholder = () => {
  const navigate = useNavigate();
  const [FormElement, setFormElement] = useState(getFormElement());
  const {
    actions: { createCS },
  } = useService();

  const [stepCount, currentFormCode, { formNavigationHandler }] =
    useFormNavigation(
      FORM_NAMES.FormDetails,
      FORM_NAMES,
      getFormElement,
      "/service/corporate-secretarial/to-act-nominee-shareholder"
    );

  useEffect(() => {
    setFormElement(getFormElement(currentFormCode));
  }, [currentFormCode]);

  const onSubmit = useCallback(
    ({ cartSummary, cartDetails, ...values }) => {
      const formattedKeys = Object.keys(values || {})?.filter((item) =>
        item?.includes("loading")
      );
      const formattedValues = JSON.parse(JSON.stringify(values));

      formattedKeys?.forEach((item) => {
        delete formattedValues[item];
      });

      createCS(
        formattedValues,
        SERVICE_TYPE_CODE.CORPORATE_SECRETARIAL.toActNomineeShareholder.code,
        cartSummary
      ).then(() => {
        navigate("/cart");
      });
    },
    [createCS]
  );

  return (
    <FormManagerLayout
      tabHeader={{
        title:
          SERVICE_TYPE_CODE.CORPORATE_SECRETARIAL.toActNomineeShareholder.title,
        breadcrum: breadcrum,
        onPrevious: () => {
          navigate("/service/corporate-secretarial");
        },
      }}
      formikProps={{
        initialValues: {
          documents: [],
          cartSummary: {
            features: [
              {
                name: PRODUCT_PRICES.nominee_shareholder_service.name,
                key: PRODUCT_PRICES.nominee_shareholder_service.type,
              },
            ],
          },
          cartDetails: [PRODUCT_PRICES.nominee_shareholder_service],
        },
        enableReinitialize: true,
        validationSchema: csToActShareholderValidation,
      }}
    >
      {({
        values,
        setFieldValue,
        validateForm,
        setTouched,
        setErrors,
        errors,
        touched,
      }) => {
        return (
          <Form>
            <FormElement.Component
              step={`Step ${stepCount || ""}/1`}
              onNext={(next, isStepIncrement) => {
                FormElement?.onNext
                  ? formNavigationHandler(
                      FormElement?.onNext?.next,
                      FormElement?.onNext?.isStepIncrement
                    )
                  : formNavigationHandler(next, isStepIncrement);
              }}
              breadcrum={
                (parseInt(stepCount) || 0) > 1 && { firstText: "Go Back" }
              }
              onPrevious={() => {
                (parseInt(stepCount) || 0) > 1 && navigate(-1);
              }}
              formUpload={onChangeFormUploadFile}
              values={values}
              setFieldValue={setFieldValue}
              {...FormElement?.attributes}
              onSubmit={onSubmit}
              cartDetails={values?.cartDetails || []}
              validateForm={validateForm}
              setTouched={setTouched}
              setErrors={setErrors}
              errors={errors}
              touched={touched}
            />
          </Form>
        );
      }}
    </FormManagerLayout>
  );
};
