import React, { useCallback } from "react";
import { Link } from "react-router-dom";

import { Box, Grid, Text } from "atoms";
import { AuthLayoutCommonSection, OTP } from "molecules";
import { LoginForm } from "molecules/LoginForm/LoginForm";
import useAuth from "contexts/Auth";

const Login = () => {
  const {
    state: {
      userData,
      // login states
      loginLoading,
      loginError,
      // login otp states
      // otpSuccess,
      otpLoading,
      otpError,
      // forgot password otp resend states
      resendSuccess,
      resendLoading,
      resendError,
    },
    actions: { login, loginOTPResend, loginOTPVerify },
  } = useAuth();

  // login 2step otp section handlers
  const loginOTPOnResendHandler = useCallback(() => {
    loginOTPResend(userData?.email);
  }, [loginOTPResend, userData]);

  const loginOnSubmitOTPHandler = useCallback(
    ({ otp }) => {
      loginOTPVerify(userData?.email, otp);
    },
    [loginOTPVerify, userData]
  );

  return userData?.is2StepVerification ? (
    <Box>
      <Grid gTC="0.4fr 1fr" w="100%" h="100vh">
        <Box bg="primary.900" />
        <Grid>
          <Box top="15%" left="-7.5%" w="fit-content" position="absolute">
            <OTP
              onSubmit={loginOnSubmitOTPHandler}
              error={otpError || resendError}
              isLoading={otpLoading}
              userEmail={userData?.email}
              onResend={loginOTPOnResendHandler}
              onResendLoading={resendLoading}
              onResendSuccess={resendSuccess}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  ) : (
    <>
      <Grid gTC="60% 40%" h="100%" w="100%">
        <LoginForm
          onSubmit={login}
          error={loginError}
          isLoading={loginLoading}
          // alignSelf="center"
          height="fit-content"
          py="4.8rem"
        />

        <Grid bg="primary.900" h="100%" px="9.6rem" py="4.8rem">
          <AuthLayoutCommonSection />
        </Grid>
      </Grid>
    </>
  );
};

export default Login;
