import React from "react";

import { Flex } from "atoms";
import { PaymentFailed } from "organisms";

export const PaymentFailurePage = () => {
  return (
    <Flex w="100%" p="3.2rem" aI="center" jC="center">
      <PaymentFailed />
    </Flex>
  );
};
