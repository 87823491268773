import * as React from "react";

function QuestionMarkWithCircle(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 47 47" fill="none" {...props}>
      <path
        d="M23.5.7a22.8 22.8 0 110 45.6 22.8 22.8 0 010-45.6zm0 32.774a2.137 2.137 0 100 4.275 2.137 2.137 0 000-4.275zm0-22.8a7.125 7.125 0 00-7.126 7.125 1.425 1.425 0 002.85 0 4.275 4.275 0 117.501 2.805l-.302.313-.336.285-.704.527c-.361.28-.7.588-1.014.92-1.46 1.557-2.295 3.666-2.295 6.55a1.425 1.425 0 102.85 0c0-2.16.559-3.574 1.525-4.6l.214-.216.228-.208.25-.206.625-.464.439-.356a7.126 7.126 0 00-4.706-12.475z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoQuestionMarkWithCircle = React.memo(QuestionMarkWithCircle);
export default MemoQuestionMarkWithCircle;
