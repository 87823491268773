import React from "react";
import { Box, Button, Flex } from "atoms";
import { cwToast, FormToggle } from "molecules";
import FormLayout from "../FormLayout";
import MemoTickIcon from "assets/icons/Tick";
import MemoCross from "styles/icons/Cross";
import { replaceInArrayOfObjects } from "utils/utilities";
import PRODUCT_PRICES from "utils/princing.constant";
import { setNestedObjectValues } from "formik";

export const FormCWCorporateSectretarialServices = ({
  step,
  onNext,
  values,
  setFieldValue,
  validateForm,
  setTouched,
  setErrors,
  errors,
  touched,
  ...props
}) => {
  return (
    <FormLayout
      step={step}
      title="Would you like to use Chartswood Corporate Secretarial Services?"
      subtitle="Chartswood Corporate Secretarial Services helps your company to concentrate on its core business goals."
      {...props}
    >
      {/* {({ values }) => {
        return (
          <Form> */}
      {/* input */}
      <Flex mt="4.8rem" w="35rem">
        <FormToggle
          name="usingCWCorporateSecretarialServices"
          label="Yes"
          value={true}
          Icon={MemoTickIcon}
        />
        <FormToggle
          name="usingCWCorporateSecretarialServices"
          label="No"
          value={false}
          ml="3.2rem"
          Icon={MemoCross}
          isWrong
        />
      </Flex>
      {/* line */}
      <Box w="100%" h="0.5px" bg="neutral.200" mt="4.8rem" />
      <Button
        type="button"
        width="fit-content"
        px="4rem"
        mt="4rem"
        onClick={() => {
          validateForm({
            usingCWCorporateSecretarialServices:
              values?.usingCWCorporateSecretarialServices,
          })?.then((dataErrorBag) => {
            if (dataErrorBag?.usingCWCorporateSecretarialServices) {
              setTouched(
                setNestedObjectValues(
                  {
                    usingCWCorporateSecretarialServices:
                      dataErrorBag?.usingCWCorporateSecretarialServices,
                  },
                  true
                )
              );
              setErrors(dataErrorBag);
              dataErrorBag?.usingCWCorporateSecretarialServices &&
                cwToast(
                  "error",
                  dataErrorBag?.usingCWCorporateSecretarialServices
                );
            } else {
              const withCartValues = JSON.parse(JSON.stringify(values));

              let newCartFeatures = withCartValues?.cartSummary?.features || [];
              newCartFeatures = replaceInArrayOfObjects(
                {
                  finder: "key",
                  finderValue:
                    withCartValues?.usingCWCorporateSecretarialServices
                      ? PRODUCT_PRICES.transfer_company_secretary_no.type
                      : PRODUCT_PRICES.transfer_company_secretary_yes.type,
                },
                {
                  name: withCartValues?.usingCWCorporateSecretarialServices
                    ? PRODUCT_PRICES.transfer_company_secretary_yes.name
                    : PRODUCT_PRICES.transfer_company_secretary_no.name,
                  key: withCartValues?.usingCWCorporateSecretarialServices
                    ? PRODUCT_PRICES.transfer_company_secretary_yes.type
                    : PRODUCT_PRICES.transfer_company_secretary_no.type,
                },
                newCartFeatures
              );

              let newCartDetails = withCartValues?.cartDetails || [];
              newCartDetails = replaceInArrayOfObjects(
                {
                  finder: "type",
                  finderValue:
                    withCartValues?.usingCWCorporateSecretarialServices
                      ? PRODUCT_PRICES.transfer_company_secretary_no.type
                      : PRODUCT_PRICES.transfer_company_secretary_yes.type,
                },
                withCartValues?.usingCWCorporateSecretarialServices
                  ? PRODUCT_PRICES.transfer_company_secretary_yes
                  : PRODUCT_PRICES.transfer_company_secretary_no,
                newCartDetails
              );

              setFieldValue("cartSummary.features", newCartFeatures);
              setFieldValue("cartDetails", newCartDetails);
              onNext();
            }
          });
        }}
      >
        Next : Corporate secretarial details
      </Button>
      {/* </Form>
        );
      }} */}
    </FormLayout>
  );
};
