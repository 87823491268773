import * as React from "react";

function RoundCross(props) {
  return (
    <svg width={12} height={12} fill="none" {...props}>
      <path
        d="M6 12A6 6 0 116 0a6 6 0 010 12zm0-6.848L4.303 3.454l-.849.85L5.152 6 3.454 7.697l.85.849L6 6.848l1.697 1.698.849-.85L6.848 6l1.698-1.697-.85-.849L6 5.152z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoRoundCross = React.memo(RoundCross);
export default MemoRoundCross;
