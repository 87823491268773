import React, { useCallback, useEffect, useRef } from "react";
import { useField } from "formik";

import {
  Box,
  Checkbox,
  DatePickerCard,
  Error,
  Grid,
  InputField,
  PhoneField,
  RadioField,
  SelectField,
} from "atoms";
import { RightTogglebutton, WrongTogglebutton } from "molecules/ToggleButton";

export const FormInput = ({ name, validate, error: propError, ...props }) => {
  const [field, meta] = useField({ name, validate });
  const error = (meta.touched && meta.error) || propError || "";
  return (
    <InputField
      {...field}
      isValidationBehaviour
      {...props}
      error={error || props?.error}
      touched={meta.touched}
      value={meta.value || field.value}
    />
  );
};

export const FormCheckbox = ({
  name,
  onChange: onChangeFromProps,
  ...props
}) => {
  const [field, , { setValue }] = useField(name);

  const onChange = useCallback(
    (val) => {
      setValue(val);
      if (typeof onChangeFromProps === "function") {
        onChangeFromProps(val);
      }
    },
    [setValue, onChangeFromProps]
  );

  return <Checkbox {...props} {...field} onChange={onChange} />;
};

export const FormSelect = ({
  name,
  options,
  validate,
  error: propError,
  customDefaultValue,
  ...props
}) => {
  const [field, meta, { setValue, setTouched }] = useField({ name, validate });
  const error = (meta.touched && meta.error) || propError || "";

  return (
    <SelectField
      {...field}
      value={
        Array.isArray(field.value)
          ? options?.filter((o) => field.value.includes(o.value))
          : // eslint-disable-next-line eqeqeq
            options?.find((o) => o.value == field.value) ||
            field.value ||
            customDefaultValue
      }
      {...props}
      onChange={(option) => {
        setValue(
          Array.isArray(option) ? option?.map((o) => o?.value) : option?.value
        );
        if (props.onChange) {
          props.onChange(
            Array.isArray(option) ? option?.map((o) => o) : option
          );
        }
      }}
      onBlur={(e) => {
        setTouched(true);
        if (props.onBlur) {
          props.onBlur(e);
        }
      }}
      options={options}
      error={error}
    />
  );
};

export const FormRadio = ({
  name,
  onChange: onChangeFromProps,
  value,
  error: propError,
  ...props
}) => {
  const [field, meta, { setValue }] = useField(name);
  const error = (meta.touched && meta.error) || propError || "";

  const onChange = useCallback(
    (e) => {
      setValue(e.target.value);
      if (typeof onChangeFromProps === "function") {
        onChangeFromProps(e.target.value);
      }
    },
    [setValue, onChangeFromProps]
  );

  return (
    <RadioField
      {...field}
      name={name}
      value={value}
      checked={field.value === value}
      {...props}
      onChange={onChange}
      error={error}
    />
  );
};

export const FormToggle = ({
  name,
  onChange: onChangeFromProps,
  value,
  isWrong,
  ...props
}) => {
  const [field, , { setValue }] = useField(name);

  const onChange = useCallback(() => {
    setValue(value);
    if (typeof onChangeFromProps === "function") {
      onChangeFromProps(value);
    }
  }, [setValue, onChangeFromProps, value]);

  return isWrong ? (
    <WrongTogglebutton
      {...field}
      checked={field.value === value}
      {...props}
      onClick={onChange}
    />
  ) : (
    <RightTogglebutton
      {...field}
      checked={field.value === value}
      {...props}
      onClick={onChange}
    />
  );
};

export const FormDateInput = ({
  name,
  validate,
  error: propError,
  onChange: onChangeFromProps,
  ...props
}) => {
  const [field, meta, { setValue }] = useField({ name, validate });

  const error = (meta.touched && meta.error) || propError || "";

  const onChange = useCallback(
    (val, e) => {
      setValue(val.toISOString());
      if (typeof onChangeFromProps === "function") {
        onChangeFromProps(val, e);
      }
    },
    [setValue, onChangeFromProps]
  );

  return (
    <DatePickerCard
      {...field}
      isValidationBehaviour
      {...props}
      error={error}
      touched={meta.touched}
      value={meta.value || field.value || meta.initialValue}
      onChange={onChange}
    />
  );
};

export const FormPhoneInput = ({
  name,
  validate,
  error: propError,
  onChange: onChangeFromProps,
  ...props
}) => {
  const [field, meta, { setValue }] = useField({ name, validate });
  const error = (meta.touched && meta?.error) || propError || "";

  const onChange = useCallback(
    (value, data, event, formattedValue) => {
      // const totalDigits = data?.format?.split(".")?.length - 1;
      // const errorFlag = value?.length !== totalDigits && "Invalid Phone";
      // const customObject = {
      //   dialCode: data.dialCode,
      //   ...data,
      //   phoneNumber: value,
      //   // phoneNumber: value.slice(data.dialCode.length),
      //   errorFlag,
      // };

      setValue(value);

      if (typeof onChangeFromProps === "function") {
        onChangeFromProps(value, event);
      }
    },
    [setValue, onChangeFromProps]
  );

  return (
    <PhoneField
      {...field}
      isValidationBehaviour
      {...props}
      error={error}
      touched={meta.touched}
      value={meta.value || field.value || meta.initialValue}
      onChange={onChange}
    />
  );
};

export const FormOTP = ({
  name,
  validate,
  error: propError,
  label,
  quantifier = 6,
  onChange: customOnChange,
  ...props
}) => {
  const [field, meta, { setValue }] = useField({ name, validate });
  const error = (meta.touched && meta.error) || propError || "";

  const inputBoxRef = useRef([]);

  const onChange = useCallback(
    (event, index) => {
      const replacement =
        event?.nativeEvent?.data || (typeof event === "string" && event) || "";

      let value = meta?.value || field?.value || "";

      if (replacement?.length === 1) {
        value =
          value.substring(0, index) +
          replacement +
          value.substring(index + replacement?.length);
        inputBoxRef?.current[index + 1]?.focus();
      }

      if (replacement?.length > 1) {
        value = value.substring(0, index) + replacement;
        inputBoxRef?.current[quantifier]?.focus();
      }
      value = value?.substring(0, quantifier);

      setValue(value);

      customOnChange(value);
    },
    [field, meta, setValue, inputBoxRef, quantifier]
  );

  // useEffect(() => {
  //   inputBoxRef?.current[0]?.focus();
  // }, [quantifier]);

  return (
    <Box>
      <Grid gTC={`repeat(${quantifier},6.4rem)`} gCG="1.2rem">
        {[...new Array(quantifier)]?.map((item, index) => (
          <InputField
            key={index}
            {...(!index && { ...field })}
            isValidationBehaviour
            touched={meta.touched}
            error={!!error}
            value={(meta.value || field.value || "").charAt(index)}
            height="6.4rem"
            bg="neutral.50"
            fontSize="4rem"
            fontWeight="700"
            lineHeight="unset"
            textAlign="center"
            onChange={(event) => onChange(event, index)}
            onPaste={(event) => {
              // console.log("paste:: ", event);
              // console.log("paste:: ", event?.clipboardData?.getData("text"));
              onChange(event?.clipboardData?.getData("text"), index);
            }}
            onKeyDown={(event) => {
              switch (event?.keyCode) {
                // delete
                case 8: {
                  onChange(" ", index);
                  inputBoxRef?.current[index]?.focus();
                  break;
                }
                // arrow left
                case 37: {
                  inputBoxRef?.current[index - 1]?.focus();
                  break;
                }
                // arrow right
                case 39: {
                  inputBoxRef?.current[index + 1]?.focus();
                  break;
                }

                default:
                  break;
              }
            }}
            status={null}
            forwardRef={(element) => {
              inputBoxRef.current[index] = element;
            }}
            {...props}
          />
        ))}
        {error && (
          <Error text={error} mt="0.8rem" w={`calc(6.4rem * ${quantifier})`} />
        )}
      </Grid>
    </Box>
  );
};
