import React from "react";
import { Line, Text, Table, Button } from "atoms";
import { FormSelect } from "molecules";
import { FormLayout } from "templates/CorporateSecretarial/components";
import MemoUploadIcon from "assets/icons/Upload";

export const FormDocUploadTable = ({ step, onNext, ...props }) => {
  return (
    <>
      <FormLayout
        //step={step}
        title="Kindly provide Details:"
        // subtitle="Description Text"
        {...props}
      >
        <Text
          mt="4.8rem"
          variant="subtitleSmall"
          color="neutral.700"
          fontWeight="600"
        >
          Upload Choosen Document
        </Text>
        <Line mt="1.6rem" mb="2.4rem" />
        <Table
          tableHeader={[
            { name: "Document Name" },
            { name: "Document Type" },
            { name: "Period" },
            { name: "Upload file" },
          ]}
        >
          <tbody>
            <tr>
              <td>
                <Text variant="bodyLarge" color="neutral.700">
                  Bank statement
                </Text>
              </td>
              <td>
                <FormSelect name="passport" label="Passport" />
              </td>
              <td>
                <Text variant="bodyLarge" fontWeight="600" color="neuteal.800">
                  One-Time
                </Text>
              </td>
              <td>
                <Button IconProps={{ Icon: MemoUploadIcon }}>Upload</Button>
              </td>
            </tr>

            <tr>
              <td>
                <Text variant="bodyLarge" color="neutral.700">
                  Invoice for vendors
                </Text>
              </td>
              <td>
                <FormSelect name="passport" label="Passport" />
              </td>
              <td>
                <Text variant="bodyLarge" fontWeight="600" color="neuteal.800">
                  One-Time
                </Text>
              </td>
              <td>
                <Button IconProps={{ Icon: MemoUploadIcon }}>Upload</Button>
              </td>
            </tr>

            <tr>
              <td>
                <Text variant="bodyLarge" color="neutral.700">
                  Receipts
                </Text>
              </td>
              <td>
                <FormSelect name="passport" label="Passport" />
              </td>
              <td>
                <Text variant="bodyLarge" fontWeight="600" color="neuteal.800">
                  One-Time
                </Text>
              </td>
              <td>
                <Button IconProps={{ Icon: MemoUploadIcon }}>Upload</Button>
              </td>
            </tr>
            <tr>
              <td>
                <Text variant="bodyLarge" color="neutral.700">
                  CPF statement
                </Text>
              </td>
              <td>
                <FormSelect name="passport" label="Passport" />
              </td>
              <td>
                <Text variant="bodyLarge" fontWeight="600" color="neuteal.800">
                  One-Time
                </Text>
              </td>
              <td>
                <Button IconProps={{ Icon: MemoUploadIcon }}>Upload</Button>
              </td>
            </tr>
            <tr>
              <td>
                <Text variant="bodyLarge" color="neutral.700">
                  Bizfile
                </Text>
              </td>
              <td>
                <FormSelect name="passport" label="Passport" />
              </td>
              <td>
                <Text variant="bodyLarge" fontWeight="600" color="neuteal.800">
                  One-Time
                </Text>
              </td>
              <td>
                <Button IconProps={{ Icon: MemoUploadIcon }}>Upload</Button>
              </td>
            </tr>
          </tbody>
        </Table>
        <Line mt="5rem" />
        <Button
          w="fit-content"
          px="4rem"
          mt="4rem"
          type="submit"
          onClick={onNext}
        >
          Checkout
        </Button>
      </FormLayout>
    </>
  );
};
