import * as React from "react";

function ShapeBox(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 32 20" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.234.418h7.53v7.666h-7.53V.418zm11.293 0h7.53v7.666h-7.53V.418zM.941.418h7.53v7.666H.94V.418zm11.293 11.498h7.53v7.666h-7.53v-7.666zm11.293 0h7.53v7.666h-7.53v-7.666zm-22.586 0h7.53v7.666H.94v-7.666z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoShapeBox = React.memo(ShapeBox);
export default MemoShapeBox;
