import React, { useCallback } from "react";
import { Box, Grid, Image } from "atoms";
import {
  AuthLayoutCommonSection,
  CreateNewPassWord,
  ForgotPassword,
  OTP,
} from "molecules";
import useAuth from "contexts/Auth";

const InnerLogin = ({ classifier }) => {
  const {
    state: {
      userData,
      // forgot password states
      forgotPasswordSuccess,
      forgotPasswordLoading,
      forgotPasswordError,
      // forgot password otp states
      otpSuccess,
      otpLoading,
      otpError,
      // forgot password otp resend states
      resendSuccess,
      resendLoading,
      resendError,
      // reset password states
      // resetPasswordSuccess,
      resetPasswordLoading,
      resetPasswordError,
    },
    actions: {
      forgotPassword,
      forgotPasswordErrorStateReset,
      forgotPasswordOTPVerify,
      forgotPasswordOTPVerifyErrorStateReset,
      forgotPasswordOTPResend,
      resetPassword,
    },
  } = useAuth();

  // forgot-password section handlers
  const forgotPasswordOnResendHandler = useCallback(() => {
    forgotPasswordOTPResend(userData?.email);
  }, [forgotPasswordOTPResend, userData]);

  const forgotPasswordOnSubmitOTPHandler = useCallback(
    ({ otp }) => {
      forgotPasswordOTPVerify(userData?.email, otp);
    },
    [forgotPasswordOTPVerify, userData]
  );

  // reset password section handler
  const resetPasswordHandler = useCallback(
    ({ password }) => {
      resetPassword(userData?.email, password);
    },
    [resetPassword, userData]
  );
  return (
    <>
      <Grid gTC="60% 40%" h="100%" w="100%">
        <Grid
          height="fit-content"
          py="4.8rem"
          // boxShadow="m"
          justifySelf="center"
        >
          <Box w="6.75rem" h="13.8rem" mx="auto" mb="3rem">
            {/* <Box w="4.5rem" h="9.2rem" mx="auto" mb="2rem"> */}
            <Image src="/images/tree-icon.png" alt="logo" />
          </Box>
          {
            {
              "forgot-password": forgotPasswordSuccess ? (
                otpSuccess ? (
                  <CreateNewPassWord
                    onSubmit={resetPasswordHandler}
                    error={resetPasswordError}
                    isLoading={resetPasswordLoading}
                  />
                ) : (
                  <OTP
                    onSubmit={forgotPasswordOnSubmitOTPHandler}
                    error={otpError || resendError}
                    isLoading={otpLoading}
                    userEmail={userData?.email}
                    onResend={forgotPasswordOnResendHandler}
                    onResendLoading={resendLoading}
                    onResendSuccess={resendSuccess}
                    forgotPasswordOTPVerifyErrorStateReset={
                      forgotPasswordOTPVerifyErrorStateReset
                    }
                  />
                )
              ) : (
                <ForgotPassword
                  onSubmit={forgotPassword}
                  error={forgotPasswordError}
                  isLoading={forgotPasswordLoading}
                  forgotPasswordErrorStateReset={forgotPasswordErrorStateReset}
                />
              ),
              // "resend-email": <ResendEmail />,
              // "security-check": <SecurityCheck />,
              // "change-email": <ChangeEmail />,
              // "create-new-password": <CreateNewPassWord />,
            }[classifier]
          }
        </Grid>

        <Grid bg="primary.900" h="100%" px="9.6rem" py="4.8rem">
          <AuthLayoutCommonSection />
        </Grid>
      </Grid>
    </>
  );
};

// const InnerLogin = ({ classifier }) => {
//   const {
//     state: {
//       userData,
//       // forgot password states
//       forgotPasswordSuccess,
//       forgotPasswordLoading,
//       forgotPasswordError,
//       // forgot password otp states
//       otpSuccess,
//       otpLoading,
//       otpError,
//       // forgot password otp resend states
//       resendSuccess,
//       resendLoading,
//       resendError,
//       // reset password states
//       // resetPasswordSuccess,
//       resetPasswordLoading,
//       resetPasswordError,
//     },
//     actions: {
//       forgotPassword,
//       forgotPasswordOTPVerify,
//       forgotPasswordOTPResend,
//       resetPassword,
//     },
//   } = useAuth();

//   // forgot-password section handlers
//   const forgotPasswordOnResendHandler = useCallback(() => {
//     forgotPasswordOTPResend(userData?.email);
//   }, [forgotPasswordOTPResend, userData]);

//   const forgotPasswordOnSubmitOTPHandler = useCallback(
//     ({ otp }) => {
//       forgotPasswordOTPVerify(userData?.email, otp);
//     },
//     [forgotPasswordOTPVerify, userData]
//   );

//   // reset password section handler
//   const resetPasswordHandler = useCallback(
//     ({ password }) => {
//       resetPassword(userData?.email, password);
//     },
//     [resetPassword, userData]
//   );
//   return (
//     <Box>
//       <Grid gTC="0.4fr 1fr" w="100%" h="100vh">
//         <Box bg="primary.900" />
//         <Grid>
//           <Box top="15%" left="-7.5%" w="fit-content" position="absolute">
//             {
//               {
//                 "forgot-password": forgotPasswordSuccess ? (
//                   otpSuccess ? (
//                     <CreateNewPassWord
//                       onSubmit={resetPasswordHandler}
//                       error={resetPasswordError}
//                       isLoading={resetPasswordLoading}
//                     />
//                   ) : (
//                     <OTP
//                       onSubmit={forgotPasswordOnSubmitOTPHandler}
//                       error={otpError || resendError}
//                       isLoading={otpLoading}
//                       userEmail={userData?.email}
//                       onResend={forgotPasswordOnResendHandler}
//                       onResendLoading={resendLoading}
//                       onResendSuccess={resendSuccess}
//                     />
//                   )
//                 ) : (
//                   <ForgotPassword
//                     onSubmit={forgotPassword}
//                     error={forgotPasswordError}
//                     isLoading={forgotPasswordLoading}
//                   />
//                 ),
//                 // "resend-email": <ResendEmail />,
//                 // "security-check": <SecurityCheck />,
//                 // "change-email": <ChangeEmail />,
//                 // "create-new-password": <CreateNewPassWord />,
//               }[classifier]
//             }
//           </Box>
//         </Grid>
//       </Grid>
//     </Box>
//   );
// };

export default InnerLogin;
