import React, { Suspense } from "react";
import { Routes, Route, Navigate, Outlet } from "react-router-dom";

import { Loader } from "atoms";

import Login from "Pages/Auth/Login";
import SignUp from "Pages/Auth/SignUp";
import InnerLogin from "Pages/Auth/InnerLogin";

import AuthLayout from "Layout/AuthLayout";
import DefaultLayout from "Layout/DefaultLayout";

import Incorporation from "Pages/Incorporation";
import Dashboard from "Pages/Dashboard";
import Cart from "Pages/Cart";
import CorporateSecretarial from "Pages/CorporateSecretarial";
import {
  ChangeBuisnessCode,
  Shareholder,
  TransferCompanySecretary,
  Director,
  ChangeCompanyAddress,
  AgmAnnualFiling,
  CertfiyTrueCopy,
  ToActNomineeShareholder,
} from "templates/CorporateSecretarial/FormManager";
import Accounting from "Pages/Accounting";

import {
  OutsourceFinancialService,
  MonthlyBookKeeping,
  YearlyBookKeeping,
  XeroAccounting,
} from "templates/Accounting/FormManager";
import MyTask from "Pages/MyTask";
import MOM from "Pages/MOM";
import { EmploymentPass, VisasApplication } from "templates/MOM/FormManager";

import PersonalDetails from "Pages/PersonalDetails";
import { Payments } from "Pages/Payments";
import Library from "Pages/Library";
import Tax from "Pages/Tax";
import { GSTRegistration } from "templates/Tax/FormManager";
import { GSTReviewAndSubmission } from "templates/Tax/FormManager/GSTReviewAndSubmission";
import { MonthlyPayrollService } from "templates/Tax/FormManager/MonthlyPayrollService";
import { CorporateTaxAndECIFilling } from "templates/Tax/FormManager/CorporateTaxAndECIFilling";
import { PersonalIncomeReviewAndSubmission } from "templates/Tax/FormManager/PersonalIncomeReviewAndSubmission";
import { Owner, User } from "Pages/Task";
import PricingAndPlan from "Pages/PricingAndPlan";
import { ServiceProvider } from "contexts/Service";
import { ToActNomineeDirector } from "templates/CorporateSecretarial/FormManager/ToActNomineeDirector";
import { OWNER_ROLE_CODE, USER_ROLE_CODE } from "utils/constants";
import { PaymentSuccessPage } from "Pages/Payments/PaymentSucess";
import { PaymentFailurePage } from "Pages/Payments/PaymentFailure";
import LoginWithSSO from "Pages/LoginWithSSO";
import { NotificationProvider } from "contexts/notification.context";
import Logout from "Pages/Logout";
import KYC from "Pages/KYC/KYC";

export const UnauthenticatedRoutes = React.memo(() => {
  return (
    <AuthLayout>
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path="login" element={<Login />} />
          <Route path="dashboard" element={<LoginWithSSO />} />
          <Route
            path="forgot-password"
            element={<InnerLogin classifier="forgot-password" />}
          />
          {/* <Route
            path="resend-email"
            element={<InnerLogin classifier="resend-email" />}
          /> */}
          {/* <Route
            path="security-check"
            element={<InnerLogin classifier="security-check" />}
          /> */}
          {/* <Route
            path="change-email"
            element={<InnerLogin classifier="change-email" />}
          /> */}
          {/* <Route
            path="create-new-password"
            element={<InnerLogin classifier="create-new-password" />}
          /> */}
          <Route path="signup" element={<SignUp />} />
          <Route path="*" element={<Navigate to="login" />} />
        </Routes>
      </Suspense>
    </AuthLayout>
  );
});

export const AuthenticatedRoutes = React.memo(({ role }) => {
  return (
    <ServiceProvider>
      <NotificationProvider>
        <DefaultLayout>
          <Suspense fallback={<Loader />}>
            <Routes>
              {/* services */}
              <Route path="service">
                <Route index element={<Outlet />} />

                {/* Incorporation */}
                <Route path="incorporation" element={<Incorporation />} />

                {/* Corporate-Secretarial */}
                <Route path="corporate-secretarial">
                  <Route index element={<CorporateSecretarial />} />
                  <Route
                    path="transfer-company-secretary"
                    element={<TransferCompanySecretary />}
                  />
                  <Route
                    path="change-buisness-code"
                    element={<ChangeBuisnessCode />}
                  />
                  <Route path="update-shareholder" element={<Shareholder />} />
                  <Route path="update-director" element={<Director />} />
                  <Route
                    path="change-company-address"
                    element={<ChangeCompanyAddress />}
                  />
                  <Route
                    path="certify-true-copy"
                    element={<CertfiyTrueCopy />}
                  />
                  <Route
                    path="to-act-nominee-shareholder"
                    element={<ToActNomineeShareholder />}
                  />
                  <Route
                    path="to-act-nominee-director"
                    element={<ToActNomineeDirector />}
                  />
                  <Route path="xblr" element={<Navigate to="/cart" />} />
                </Route>

                {/* MOM */}
                <Route path="mom">
                  <Route index element={<MOM />} />
                  <Route
                    path="employment-pass-application"
                    element={<EmploymentPass />}
                  />
                  <Route
                    path="visas-application"
                    element={<VisasApplication />}
                  />
                </Route>

                {/* KYC */}
                <Route path="kyc">
                  <Route index element={<KYC />} />
                  <Route
                    path="employment-pass-application"
                    element={<EmploymentPass />}
                  />
                  <Route
                    path="visas-application"
                    element={<VisasApplication />}
                  />
                </Route>

                {/* Accounting */}
                <Route path="accounting">
                  <Route index element={<Accounting />} />
                  <Route
                    path="xero-accounting-sofware-implementation"
                    element={<XeroAccounting />}
                  />
                  <Route
                    path="monthly-book-keeping"
                    element={<MonthlyBookKeeping />}
                  />
                  <Route
                    path="yearly-book-keeping"
                    element={<YearlyBookKeeping />}
                  />
                  <Route
                    path="outsource-financial-service"
                    element={<OutsourceFinancialService />}
                  />
                </Route>

                {/* Tax */}
                <Route path="tax">
                  <Route index element={<Tax />} />
                  <Route
                    path="gst-registration"
                    element={<GSTRegistration />}
                  />
                  <Route
                    path="gst-review-and-submission"
                    element={<GSTReviewAndSubmission />}
                  />
                  <Route
                    path="monthly-payroll-service"
                    element={<MonthlyPayrollService />}
                  />
                  <Route
                    path="corporate-tax-and-eci-filling"
                    element={<CorporateTaxAndECIFilling />}
                  />
                  <Route
                    path="personal-income-review-and-submission"
                    element={<PersonalIncomeReviewAndSubmission />}
                  />
                </Route>
              </Route>

              {/* Documents */}
              <Route path="documents">
                <Route index element={<Outlet />} />
                <Route path="library" element={<Library />} />
              </Route>
              {/* Pricing And Plan */}
              <Route path="subscription">
                <Route index element={<Outlet />} />
                <Route path="pricing-and-plan" element={<PricingAndPlan />} />
              </Route>
              {/* Profile */}
              <Route path="profile">
                <Route index element={<Outlet />} />
                <Route path="personal-details" element={<PersonalDetails />} />
                <Route path="payment-details" element={<Payments />} />
              </Route>

              {/* Query list */}
              <Route path="query-list">
                <Route index element={<Outlet />} />
                <Route path="mytask">
                  <Route index element={<MyTask />} />
                  {
                    {
                      [USER_ROLE_CODE]: (
                        <>
                          <Route
                            path="user/details/:taskId"
                            element={<User />}
                          />
                        </>
                      ),
                      [OWNER_ROLE_CODE]: (
                        <>
                          <Route
                            path="owner/details/:taskId"
                            element={<Owner />}
                          />
                        </>
                      ),
                    }[role]
                  }
                </Route>
              </Route>

              {/* Cart */}
              <Route path="cart">
                <Route index element={<Cart />} />
              </Route>

              {/* Payment */}
              <Route path="payment">
                <Route index element={<Outlet />} />
                <Route path="success" element={<PaymentSuccessPage />} />
                <Route path="failure" element={<PaymentFailurePage />} />
              </Route>

              {/* Dashboard */}
              {/* <Route path="dashboard" element={<Dashboard />} /> */}
              <Route
                path="dashboard"
                element={
                  role === USER_ROLE_CODE ? (
                    <Dashboard />
                  ) : role === OWNER_ROLE_CODE ? (
                    <Navigate to="query-list/mytask" />
                  ) : (
                    <Dashboard />
                  )
                }
              />
              <Route path="logout" element={<Logout />} />
              <Route
                path="*"
                element={
                  role === USER_ROLE_CODE ? (
                    <Navigate to="dashboard" />
                  ) : role === OWNER_ROLE_CODE ? (
                    <Navigate to="query-list/mytask" />
                  ) : (
                    <Navigate to="dashboard" />
                  )
                }
              />
            </Routes>
          </Suspense>
        </DefaultLayout>
      </NotificationProvider>
    </ServiceProvider>
  );
});
