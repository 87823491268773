import React from "react";

import { Box, Line, Text } from "atoms";
import {
  CorporateShareholder,
  Shareholder,
  ShareholderRemove,
  ShareholderTransfer,
} from "./components";
import { Accordian } from "organisms";

const getDirectorTitle = (firstName, lastName) => {
  if (firstName || lastName) {
    let title = firstName || "";
    if (lastName) {
      title += `${firstName ? " " : ""}${lastName || ""}`;
    }
    return title;
  }
  return "";
};

export const UserDetails = ({ serviceDetails }) => {
  return (
    <Box w="100%">
      <Text variant="subtitleMedium" fontWeight="600">
        Shareholder Details
      </Text>

      <Line mt="1.6rem" />

      {serviceDetails?.details?.actionType === "add" && (
        <Accordian
          accordians={[
            ...(serviceDetails?.details?.shareholderDetails || []),
            ...(serviceDetails?.details?.corporateShareholderDetails || []),
          ]?.map((shareholder) => ({
            title:
              getDirectorTitle(shareholder?.firstName, shareholder?.lastName) ||
              shareholder?.companyName ||
              `New Shareholder`,
            children: {
              individual: <Shareholder shareholder={shareholder} />,
              corporate: <CorporateShareholder shareholder={shareholder} />,
            }[shareholder?.category],
          }))}
          mt="4rem"
          gap="2.4rem"
          isRemovable={false}
          isAutoOpen={false}
        />
      )}

      {serviceDetails?.details?.actionType === "remove" && (
        <Accordian
          accordians={serviceDetails?.details?.removeMembers?.map(
            (shareholder) => ({
              title: shareholder?.name,
              children: <ShareholderRemove shareholder={shareholder} />,
            })
          )}
          mt="4rem"
          gap="2.4rem"
          isRemovable={false}
          isAutoOpen={false}
        />
      )}

      {serviceDetails?.details?.actionType === "update" && (
        <Accordian
          accordians={serviceDetails?.details?.transferShares?.map(
            (shareholder) => ({
              title: `${shareholder?.from?.name || ""} - ${
                shareholder?.to?.name || ""
              }`,
              children: <ShareholderTransfer shareholder={shareholder} />,
            })
          )}
          mt="4rem"
          gap="2.4rem"
          isRemovable={false}
          isAutoOpen={false}
        />
      )}
    </Box>
  );
};
