import React, { useEffect, useState } from "react";

import { Box, Button, Error, Grid, Line, Text } from "atoms";
import FormLayout from "../FormLayout";
import { Accordian } from "organisms";
import {
  CorporateShareholder,
  Shareholder,
  ShareholderTypeModal,
} from "./components";
import FORM_NAMES from "../form.names";
import PRODUCT_PRICES from "utils/princing.constant";
import { setNestedObjectValues } from "formik";

const DEFAULT_SHAREHOLDER_DETAILS = {
  category: "individual",
  createdAt: "",
  firstName: "",
  lastName: "",
  // something
  identification: "",
  designation: "",
  email: "",
  dob: "",
  phone: "",
  address: {
    addressLine1: "",
    addressLine2: "",
    postalCode: "",
    country: "",
    countryCode: "",
  },
  numberOfCompanyShares: "",
  valuePerShare: "",
  // firstFinancialYearRevenue: "",
  currency: "",
  documents: [],
};
const DEFAULT_CORPORATE_SHAREHOLDER_DETAILS = {
  category: "corporate",
  createdAt: "",
  companyName: "",
  uen: "",
  countryOfIncorporation: "",
  doi: "",
  website: "",
  industry: "",
  email: "",
  address: {
    addressLine1: "",
    addressLine2: "",
    postalCode: "",
    country: "",
    countryCode: "",
  },
  numberOfCompanyShares: "",
  valuePerShare: "",
  // firstFinancialYearRevenue: "",
  currency: "",
  documents: [],
};

const getDirectorTitle = (firstName, lastName) => {
  if (firstName || lastName) {
    let title = firstName || "";
    if (lastName) {
      title += `${firstName ? " " : ""}${lastName || ""}`;
    }
    return title;
  }
  return "";
};

export const FormNomineeShareholderDetails = ({
  step,
  onNext,
  formUpload,
  formikProps,
  values,
  setFieldValue,
  onSubmit,
  isLastStep,
  validateForm,
  setTouched,
  setErrors,
  errors,
  touched,
  ...props
}) => {
  const [isShareholderTypeModalVisible, setIsShareholderTypeModalVisible] =
    useState(false);

  useEffect(() => {
    if (
      [
        ...(values?.shareholderDetails || []),
        ...(values?.corporateShareholderDetails || []),
      ]?.length
    ) {
      const newCartFeatures = [
        ...new Array(
          [
            ...(values?.shareholderDetails || []),
            ...(values?.corporateShareholderDetails || []),
          ]?.length
        ).fill({
          name: PRODUCT_PRICES.add_shareholder.name,
          key: PRODUCT_PRICES.add_shareholder.type,
        }),
      ];

      const newCartDetails = [
        ...new Array(
          [
            ...(values?.shareholderDetails || []),
            ...(values?.corporateShareholderDetails || []),
          ]?.length
        ).fill(PRODUCT_PRICES.add_shareholder),
      ];

      setFieldValue("cartSummary.features", newCartFeatures);
      setFieldValue("cartDetails", newCartDetails);
    } else {
      setFieldValue("cartSummary.features", []);
      setFieldValue("cartDetails", []);
    }
  }, [
    values?.shareholderDetails?.length,
    values?.corporateShareholderDetails?.length,
  ]);

  return (
    <FormLayout
      step={step}
      title={"Add Shareholder's Detail"}
      subtitle="A shareholder considers to be either an individual, company, or limited liability partnership. In the case of a private limited company, there must be at least 1 shareholder and a maximum of 50 shareholders. Please provide all relevant documentation of the new shareholder."
      {...props}
    >
      {/* {({ values, setFieldValue }) => {
        return (
          <Form> */}

      {/* +ADD SHAREHOLDER */}
      {[
        ...(values?.shareholderDetails || []),
        ...(values?.corporateShareholderDetails || []),
      ]?.length <= 0 && (
        <Grid gTC="1fr" gCG="3.2rem" mt="4rem">
          <Button
            w="30rem"
            type="button"
            onClick={() => {
              setIsShareholderTypeModalVisible(true);
            }}
          >
            +ADD SHAREHOLDER
          </Button>
        </Grid>
      )}

      <Accordian
        accordians={[
          ...(values?.shareholderDetails || []),
          ...(values?.corporateShareholderDetails || []),
        ]?.map((shareholder, index) => ({
          title:
            getDirectorTitle(shareholder?.firstName, shareholder?.lastName) ||
            shareholder?.companyName ||
            `New Shareholder`,
          children: {
            individual: (
              <Shareholder
                key={index}
                values={values}
                index={(values?.shareholderDetails || [])?.findIndex(
                  (item) => item?.createdAt === shareholder?.createdAt
                )}
                setFieldValue={setFieldValue}
                formUpload={formUpload}
                errors={errors}
                touched={touched}
              />
            ),
            corporate: (
              <CorporateShareholder
                key={index}
                values={values}
                index={(values?.corporateShareholderDetails || [])?.findIndex(
                  (item) => item?.createdAt === shareholder?.createdAt
                )}
                setFieldValue={setFieldValue}
                formUpload={formUpload}
                errors={errors}
                touched={touched}
              />
            ),
          }[shareholder?.category],
        }))}
        // mt="2rem"
        gap="3.6rem"
        isRemovable={
          ((values?.shareholderDetails || [])?.length || 0) +
            ((values?.corporateShareholderDetails || [])?.length || 0) >
          1
        }
        onRemove={(index) => {
          const targetItem = [
            ...(values?.shareholderDetails || []),
            ...(values?.corporateShareholderDetails || []),
          ][index];

          switch (targetItem?.category) {
            case "individual": {
              let cloneShareholderDetails = JSON.parse(
                JSON.stringify(values?.shareholderDetails || [])
              );

              const targetIndex = cloneShareholderDetails?.findIndex(
                (item) => item?.createdAt === targetItem?.createdAt
              );

              cloneShareholderDetails = cloneShareholderDetails?.filter(
                (_, cloneIndex) => cloneIndex !== targetIndex
              );
              setFieldValue("shareholderDetails", cloneShareholderDetails);
              break;
            }
            case "corporate": {
              let cloneCorporateShareholderDetails = JSON.parse(
                JSON.stringify(values?.corporateShareholderDetails || [])
              );

              const targetIndex = cloneCorporateShareholderDetails?.findIndex(
                (item) => item?.createdAt === targetItem?.createdAt
              );

              cloneCorporateShareholderDetails =
                cloneCorporateShareholderDetails?.filter(
                  (_, cloneIndex) => cloneIndex !== targetIndex
                );
              setFieldValue(
                "corporateShareholderDetails",
                cloneCorporateShareholderDetails
              );
              break;
            }

            default: {
              break;
            }
          }
        }}
      />

      {touched?.shareholderDetails &&
        errors?.shareholderDetails &&
        typeof errors?.shareholderDetails === "string" && (
          <Error text={errors?.shareholderDetails || ""} mt="2rem" />
        )}
      {!errors?.shareholderDetails &&
        touched?.corporateShareholderDetails &&
        errors?.corporateShareholderDetails &&
        typeof errors?.corporateShareholderDetails === "string" && (
          <Error text={errors?.corporateShareholderDetails || ""} mt="2rem" />
        )}

      {/* +ADD SHAREHOLDER */}
      {[
        ...(values?.shareholderDetails || []),
        ...(values?.corporateShareholderDetails || []),
      ]?.length > 0 && (
        <Box
          mt="5rem"
          cursor="pointer"
          onClick={() => {
            setIsShareholderTypeModalVisible(true);
          }}
        >
          <Text
            variant="bodyMedium"
            fontWeight="600"
            color="secondary.700"
            cursor="pointer"
          >
            +ADD SHAREHOLDER
          </Text>
        </Box>
      )}

      <Line mt="4rem" mb="4rem" />
      <Button
        w="fit-content"
        px="4rem"
        mt="4rem"
        type="button"
        onClick={() => {
          validateForm({
            actionType: values?.actionType,
            shareholderDetails: values?.shareholderDetails,
            corporateShareholderDetails: values?.corporateShareholderDetails,
          })?.then((dataErrorBag) => {
            if (
              dataErrorBag?.shareholderDetails ||
              dataErrorBag?.corporateShareholderDetails
            ) {
              setTouched(
                setNestedObjectValues(
                  {
                    shareholderDetails: dataErrorBag?.shareholderDetails,
                    corporateShareholderDetails:
                      dataErrorBag?.corporateShareholderDetails,
                  },
                  true
                )
              );
              setErrors(dataErrorBag);
            } else {
              if (isLastStep) {
                onSubmit(values);
              }
              onNext(FORM_NAMES.Cart, true);
            }
          });
        }}
      >
        NEXT : Payment
      </Button>
      {/* </Form>
        );
      }} */}
      {isShareholderTypeModalVisible && (
        <ShareholderTypeModal
          isOpen={isShareholderTypeModalVisible}
          onSubmitModal={({ type }) => {
            switch (type) {
              case "individual": {
                setFieldValue("shareholderDetails", [
                  ...(values?.shareholderDetails || []),
                  {
                    ...JSON.parse(JSON.stringify(DEFAULT_SHAREHOLDER_DETAILS)),
                    createdAt: new Date().toISOString(),
                  },
                ]);
                break;
              }
              case "corporate": {
                setFieldValue("corporateShareholderDetails", [
                  ...(values?.corporateShareholderDetails || []),
                  {
                    ...JSON.parse(
                      JSON.stringify(DEFAULT_CORPORATE_SHAREHOLDER_DETAILS)
                    ),
                    createdAt: new Date().toISOString(),
                  },
                ]);
                break;
              }
              default: {
                break;
              }
            }
          }}
          onClose={() => {
            setIsShareholderTypeModalVisible(false);
          }}
        />
      )}
    </FormLayout>
  );
};
