import * as React from "react";

function PricingAndPlan(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M15.057 7.558L8.435.934A1.498 1.498 0 007.377.5H2C1.175.5.5 1.175.5 2v5.378c0 .397.157.78.443 1.057l6.622 6.622a1.503 1.503 0 002.122 0l5.378-5.377a1.496 1.496 0 00-.008-2.122zM8.623 14L2 7.378V2h5.378L14 8.623 8.623 14z"
        fill="currentColor"
      />
      <path
        d="M3.875 5a1.125 1.125 0 100-2.25 1.125 1.125 0 000 2.25z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoPricingAndPlan = React.memo(PricingAndPlan);
export default MemoPricingAndPlan;
