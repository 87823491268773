import * as React from "react";

function PaymentDetails(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 16 12" fill="none" {...props}>
      <path
        d="M15.5 1.125v9.75c0 .3-.113.563-.338.787a1.08 1.08 0 01-.787.338H1.625c-.3 0-.563-.113-.787-.338a1.08 1.08 0 01-.338-.787v-9.75c0-.3.113-.563.338-.787A1.08 1.08 0 011.625 0h12.75c.3 0 .563.113.787.338.225.224.338.487.338.787zM1.625 3.169h12.75V1.125H1.625v2.044zm0 2.419v5.287h12.75V5.588H1.625zm0 5.287v-9.75 9.75z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoPaymentDetails = React.memo(PaymentDetails);
export default MemoPaymentDetails;
