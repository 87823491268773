import React from "react";
import { Box, Flex, Text } from "atoms";
import { DefaultChips } from "molecules";
import { RedirectModal } from "templates/Task/components";
import DocumentVerifiedCard from "../DocumentVerifiedCard";
import { getStatusChip } from "utils/utilities";
import { STATUS } from "utils/constants";

export const FormOwnerPostIncorporation = ({
  serviceDetails,
  statusFlag,
  pending,
  completed,
}) => {
  return (
    <>
      <Box w="100%">
        <Flex>
          <Text variant="subtitleMedium" color="neutral.700" fontWeight="600">
            Post incorporation
          </Text>
          <DefaultChips
            {...getStatusChip(serviceDetails?.[statusFlag])}
            ml="2.4rem"
          />
        </Flex>
        {/* <Text color="neutral.600" variant="bodySmall" mt=".8rem">
          Description Text
        </Text> */}
        {/* Redirect Modal */}
        <Box mt="3.2rem">
          <RedirectModal
            title="Prokorp"
            description="Prokorp is a digital corporate compliance platform that assists with corporate secretarial tasks."
            link="https://portal.prokorp.sg/ChartswoodAssociates/CompanyModule/frmCmpCompanyDirectory.aspx"
          />
        </Box>
        {/* document verified card */}
        {serviceDetails?.[statusFlag] !== STATUS.completed && (
          <Box mt="2.4rem">
            <DocumentVerifiedCard
              title="Are documents created?"
              subtitle="Create post incorporation documents in prokorp or chat with user."
              {...(serviceDetails?.[statusFlag] !== STATUS.pending && {
                pending,
              })}
              completed={completed}
            />
          </Box>
        )}
      </Box>
    </>
  );
};
