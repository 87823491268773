import React, { useCallback, useEffect, useState } from "react";

import { Box, Button, Error, Flex, Image, Text } from "atoms";
import { FormOTP } from "molecules/FormInput";
import { Formik, Form } from "formik";
import { otpValidationSchema } from "utils/validation";

export const OTP = ({
  onSubmit,
  error,
  isLoading,
  userEmail,
  onResend,
  onResendLoading,
  onResendSuccess,
  forgotPasswordOTPVerifyErrorStateReset,
}) => {
  const [timerValue, setTimerValue] = useState(30);

  useEffect(() => {
    const timer =
      timerValue > 0 && setInterval(() => setTimerValue(timerValue - 1), 1000);
    return () => clearInterval(timer);
  }, [timerValue]);

  const onResendHandler = useCallback(() => {
    onResend();
    setTimerValue(30);
  }, [onResend]);

  return (
    <>
      <Formik
        initialValues={{
          otp: "",
        }}
        onSubmit={onSubmit}
        validationSchema={otpValidationSchema}
      >
        {({ setFieldValue }) => {
          return (
            <Form>
              <Flex
                // w="80rem"
                // h="58rem"
                boxShadow="m"
                bR="0.8rem"
                // pl="8rem"
                // pt="8rem"
                py="2.4rem"
                px="3.9rem"
                minWidth="43rem"
                bg="white"
                flexDirection="column"
              >
                {/* <Box h="4.9rem" w="2.4rem">
                  <Image src="images/logo.png" alt="logo" objectFit="cover" />
                </Box> */}
                <Text
                  variant="subtitleLarge"
                  fontWeight="600"
                  // mt="5rem"
                  color="neutral.800"
                >
                  Verify the authorisation code
                </Text>
                <Text
                  mt="4rem"
                  variant="bodyMedium"
                  color="neutral.600"
                  display="flex"
                >
                  One time password has been{" "}
                  {onResendSuccess ? "resent" : "sent"} to
                  <Text
                    variant="bodyMedium"
                    color="neutral.800"
                    fontWeight="600"
                    ml="0.5rem"
                  >
                    {userEmail || ""}
                  </Text>
                </Text>

                <Box w="44.4rem" mt="1.6rem">
                  <FormOTP
                    name="otp"
                    error={error}
                    onChange={(value) => {
                      setFieldValue("otp", value);
                      if (error) {
                        forgotPasswordOTPVerifyErrorStateReset();
                      }
                    }}
                  />
                </Box>
                {/* {error && <Error text={error} mt="0.8rem" />} */}
                <Button
                  type="submit"
                  variant="primary"
                  mt="4rem"
                  w="11rem"
                  loading={isLoading}
                >
                  Verify
                </Button>
                {timerValue ? (
                  <Text
                    mt="1.6rem"
                    variant="bodyMedium"
                    color="neutral.800"
                    display="flex"
                  >
                    Resend OTP in
                    <Text
                      variant="bodyMedium"
                      color="primary.900"
                      fontWeight="700"
                      ml="0.5rem"
                    >
                      00:{timerValue < 10 && "0"}
                      {timerValue}
                    </Text>
                  </Text>
                ) : onResendLoading ? (
                  <Text
                    mt="1.6rem"
                    variant="bodyMedium"
                    color="neutral.800"
                    display="flex"
                  >
                    Re-sending new OTP ....
                  </Text>
                ) : (
                  <Text
                    mt="1.6rem"
                    variant="bodyMedium"
                    color="neutral.800"
                    display="flex"
                  >
                    Didn’t received OTP?
                    <Text
                      variant="bodyMedium"
                      color="primary.900"
                      fontWeight="700"
                      ml="0.5rem"
                      cursor="pointer"
                      onClick={onResendHandler}
                    >
                      Resend OTP
                    </Text>
                  </Text>
                )}
              </Flex>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
