import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  FormDetails,
  FormRemoveDirector,
  FormUpdateDirector,
  FormDirectorDetails,
} from "../components";

import { FormManagerLayout } from "./FormManagerLayout";
import FORM_NAMES from "../components/form.names";
import { useFormNavigation } from "hooks/useFormNavigation";
import { onChangeFormUploadFile } from "utils/utilities";
import useService from "contexts/Service";
import SERVICE_TYPE_CODE from "utils/services.type.constants";
import { Form } from "formik";
import { csDirectorValidation } from "utils/validation";

const FORM_COMPONENT_NAMED_NAVIGATION = [
  {
    name: FORM_NAMES.FormUpdateDirector,
    Component: FormUpdateDirector,
  },
  {
    name: FORM_NAMES.FormDirectorDetails,
    Component: FormDirectorDetails,
  },
  {
    name: FORM_NAMES.FormRemoveDirector,
    Component: FormRemoveDirector,
  },
  {
    name: FORM_NAMES.FormDetails,
    Component: FormDetails,
    onNext: {
      next: FORM_NAMES.Cart,
    },
    attributes: {
      isLastStep: true,
    },
  },
];

const getFormElement = (name) => {
  return (
    FORM_COMPONENT_NAMED_NAVIGATION?.find((item) => item?.name === name) ||
    FORM_COMPONENT_NAMED_NAVIGATION?.find(
      (item) => item?.name === FORM_NAMES.FormUpdateDirector
    )
  );
};

const breadcrum = {
  firstText: "Home",
  firstUrl: "/dashboard",
  secondText: "Corporate Secretarial Services",
  secondUrl: "/service/corporate-secretarial",
  thirdText: "Add or Remove Director",
};

export const Director = () => {
  const navigate = useNavigate();
  const [FormElement, setFormElement] = useState(getFormElement());
  const {
    actions: { createCS },
  } = useService();

  const [stepCount, currentFormCode, { formNavigationHandler }] =
    useFormNavigation(
      FORM_NAMES.FormUpdateDirector,
      FORM_NAMES,
      getFormElement,
      "/service/corporate-secretarial/update-director"
    );

  useEffect(() => {
    setFormElement(getFormElement(currentFormCode));
  }, [currentFormCode]);

  const onSubmit = useCallback(
    ({ cartSummary, cartDetails, ...values }) => {
      const formattedValues = {};
      formattedValues.actionType = values?.actionType;
      formattedValues.documents = values?.documents;

      switch (formattedValues.actionType) {
        case "add": {
          formattedValues.directorDetails = values?.directorDetails;
          break;
        }
        case "remove": {
          formattedValues.removeMembers = values?.removeMembers;
          break;
        }
        default: {
          break;
        }
      }

      formattedValues.actionType = values?.actionType;
      // const formattedKeys = Object.keys(values || {})?.filter((item) =>
      //   item?.includes("loading")
      // );
      // const formattedValues = JSON.parse(JSON.stringify(values));

      // formattedKeys?.forEach((item) => {
      //   delete formattedValues[item];
      // });
      createCS(
        formattedValues,
        SERVICE_TYPE_CODE.CORPORATE_SECRETARIAL.additionAndRemovalOfDirector
          .code,
        cartSummary
      ).then(() => {
        navigate("/cart");
      });
    },
    [createCS]
  );

  return (
    <FormManagerLayout
      tabHeader={{
        title:
          SERVICE_TYPE_CODE.CORPORATE_SECRETARIAL.additionAndRemovalOfDirector
            .title,
        breadcrum: breadcrum,
        onPrevious: () => {
          navigate("/service/corporate-secretarial");
        },
      }}
      formikProps={{
        initialValues: {
          actionType: "",
          directorDetails: [],
          removeMembers: [],
          documents: [],
          cartSummary: "",
          cartDetails: "",
        },
        enableReinitialize: true,
        validationSchema: csDirectorValidation,
      }}
    >
      {({
        values,
        setFieldValue,
        validateForm,
        setTouched,
        setErrors,
        errors,
        touched,
      }) => {
        return (
          <Form>
            <FormElement.Component
              step={`Step ${stepCount || ""}/3`}
              onNext={(next, isStepIncrement) => {
                FormElement?.onNext
                  ? formNavigationHandler(
                      FormElement?.onNext?.next,
                      FormElement?.onNext?.isStepIncrement
                    )
                  : formNavigationHandler(next, isStepIncrement);
              }}
              breadcrum={
                (parseInt(stepCount) || 0) > 1 && { firstText: "Go Back" }
              }
              onPrevious={() => {
                (parseInt(stepCount) || 0) > 1 && navigate(-1);
              }}
              formUpload={onChangeFormUploadFile}
              values={values}
              setFieldValue={setFieldValue}
              {...FormElement?.attributes}
              {...{
                docs: [
                  ...(values?.actionType === "remove"
                    ? [
                        {
                          id: "directorResignationLetter",
                          name: "Director resignation letter",
                        },
                      ]
                    : []),
                  {
                    id: "directorResolutionOrAppointment",
                    name: "Director resolution for Resignation/ Appointment of Director",
                  },
                  {
                    id: "form45",
                    name: "Form 45 for New Director signed by Directors",
                  },
                ],
                detailTitle: {
                  remove:
                    "Kindly provide the details for the removal of a director",
                  add: "",
                }[values?.actionType],
                detailSubtitle: {
                  remove:
                    "Please provide all relevant documentation to verify the termination/resignation of your previous director.",
                  add: "Please provide all relevant documentation of the new director. Document verification is mandatory for new directors",
                }[values?.actionType],
              }}
              onSubmit={onSubmit}
              cartDetails={values?.cartDetails || []}
              validateForm={validateForm}
              setTouched={setTouched}
              setErrors={setErrors}
              errors={errors}
              touched={touched}
            />
          </Form>
        );
      }}
    </FormManagerLayout>
  );
};
