import * as React from "react";

function Cross(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 12 12" fill="none" {...props}>
      <path
        d="M6 4.667L10.667 0 12 1.333 7.333 6 12 10.667 10.667 12 6 7.333 1.333 12 0 10.667 4.667 6 0 1.333 1.333 0 6 4.667z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoCross = React.memo(Cross);
export default MemoCross;
