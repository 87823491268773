import React from "react";

import { Box, Flex, Text } from "atoms";

export const LineLoader = ({ filled, total, totalWidth, ...props }) => {
  return (
    <Flex aI="center" {...props}>
      <Box
        bg="neutral.100"
        w={totalWidth || "100%"}
        h="1.6rem"
        borderRadius="0.2rem"
      >
        <Box
          bg="green.700"
          w={`${(filled * 100) / total}%`}
          h="100%"
          borderRadius="0.2rem"
        />
      </Box>
      <Text variant="bodyLarge" ml="0.9rem">
        Steps {`${filled}/${total}`}
      </Text>
    </Flex>
  );
};
