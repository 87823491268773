import React from "react";
import { useState } from "react";
import { Box, Flex, Text } from "atoms";

export const SocialMediaIcon = ({
  Icon,
  color,
  Hovericon,
  hoverColor,
  text,
  ...props
}) => {
  const [isHover, setIsHovered] = useState(false);
  const handleHover = (_isHover) => setIsHovered(_isHover);

  return (
    <>
      <Flex
        // w="10.7rem"
        // w="fit-content"
        p="1.3rem"
        h="5rem"
        bR="0.8rem"
        border="0.5px solid"
        borderColor="primary.900"
        cursor="pointer"
        bg={isHover === true ? "primary.900" : "white"}
        onMouseOver={() => handleHover(true)}
        onMouseLeave={() => handleHover(false)}
        {...props}
      >
        <Box
          w="2.4rem"
          h="2.4rem"
          // mx="auto"
          {...(isHover ? { color: hoverColor } : { color: color })}
        >
          {isHover ? (
            <Hovericon width="2.4rem" height="2.4rem" />
          ) : (
            <Icon width="2.4rem" height="2.4rem" />
          )}
        </Box>

        {text && (
          <Text
            variant="bodyLarge"
            width="max-content"
            ml="2.4rem"
            color={isHover ? "white" : "primary.900"}
            fontWeight="600"
          >
            {text}
          </Text>
        )}
      </Flex>
    </>
  );
};
