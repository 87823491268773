import React from "react";

import { Grid, Image, Loader, Text } from "atoms";
import { getInitials } from "utils/utilities";

const SIZE = {
  a: "1.8rem", //atomic
  xs: "3.2rem",
  s: "3.8rem",
  m: "4.8rem",
  l: "9.4rem",
};

export const ProfileAvatar = ({
  name,
  avatar,
  size = "s",
  textProps,
  avatarProps,
  isLoading,
  ...props
}) => {
  return (
    <Grid
      minWidth={SIZE[size]}
      maxWidth={SIZE[size]}
      minHeight={SIZE[size]}
      placeItems="center"
      borderRadius={SIZE[size]}
      border={avatar ? 0 : "1px solid"}
      bg={avatar ? undefined : "blue.50"}
      borderColor={avatar ? undefined : "blue.400"}
      {...(!avatar && { alignItems: "center", justifyContent: "center" })}
      {...props}
    >
      {isLoading ? (
        <Loader loading={isLoading} />
      ) : avatar ? (
        <Image
          src={avatar}
          alt="dashboard.alt"
          height={{
            xs: "3rem",
            md: SIZE[size],
          }}
          overflow="hidden"
          objectfit="cover"
          borderRadius="10rem"
          width={{ xs: "3rem", md: SIZE[size] }}
          maxWidth={{ xs: "3rem", md: SIZE[size] }}
          {...avatarProps}
        />
      ) : (
        <Text
          variant="bodySmall"
          fontWeight="600"
          textTransform="capitalize"
          {...textProps}
        >
          {getInitials(name)}
        </Text>
      )}
    </Grid>
  );
};
