import * as React from "react";

function Chat(props) {
  return (
    <svg
      width="41"
      height="41"
      viewBox="0 0 41 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="41" height="41" rx="20.5" fill="white" fill-opacity="0.34" />
      <path
        d="M18.2 10H22.8C25.24 10 27.58 10.9693 29.3054 12.6946C31.0307 14.42 32 16.76 32 19.2C32 21.64 31.0307 23.98 29.3054 25.7054C27.58 27.4307 25.24 28.4 22.8 28.4V32.425C17.05 30.125 9 26.675 9 19.2C9 16.76 9.96928 14.42 11.6946 12.6946C13.42 10.9693 15.76 10 18.2 10Z"
        fill="white"
      />
    </svg>
  );
}

const MemoChatIcon = React.memo(Chat);
export default MemoChatIcon;
