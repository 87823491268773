import React from "react";
import { Box, Text, Button, Table, Error } from "atoms";
import FormLayout from "../FormLayout";
import { Upload } from "organisms";
import { getDocumentById } from "utils/utilities";
import { setNestedObjectValues } from "formik";

export const FormDetails = ({
  step,
  onNext,
  docs,
  formUpload,
  values,
  setFieldValue,
  isLastStep,
  onSubmit,
  detailTitle,
  detailSubtitle,
  validateForm,
  setTouched,
  setErrors,
  errors,
  touched,
  ...props
}) => {
  return (
    <FormLayout
      step={step}
      title={detailTitle || "Kindly provide details"}
      subtitle={detailSubtitle || ""}
      {...props}
    >
      {/* {({ values, setFieldValue }) => {
        return (
          <Form> */}
      {docs && (
        <Box mt="4.8rem">
          <Text variant="subtitleSmall" fontWeight="600" color="neutral.700">
            Documents list
          </Text>
          <Box mt="3.2rem">
            <Table
              tableHeader={[{ name: "Document Name" }, { name: "Upload file" }]}
            >
              <tbody>
                {docs?.map((doc, index) => (
                  <tr key={index}>
                    <td>
                      <Box>
                        <Text variant="bodyLarge" color="neutral.700">
                          {doc?.name}
                        </Text>
                        <Text variant="bodyLarge" color="neutral.700">
                          {doc?.description }
                        </Text>
                        {touched?.documents &&
                          errors?.documents &&
                          !getDocumentById(doc?.id, values?.documents)?.url && (
                            <Error text={"* required"} />
                          )}
                      </Box>
                    </td>
                    <td>
                      <Upload
                        url={getDocumentById(doc?.id, values?.documents)?.url}
                        name={getDocumentById(doc?.id, values?.documents)?.name}
                        time={getDocumentById(doc?.id, values?.documents)?.time}
                        onClose={() => {
                          // setFieldValue(doc?.id, "");
                          const delDocs =
                            JSON.parse(
                              JSON.stringify(values)
                            )?.documents?.filter(
                              (item) => item?.id !== doc?.id
                            ) || [];

                          setFieldValue("documents", delDocs);
                        }}
                        loading={values?.[`${doc?.id} loading`]}
                        onChange={(e) => {
                          // formUpload(e, doc?.id, setFieldValue, (fieldData) => {
                          //   setFieldValue(doc?.id, {
                          //     type: "",
                          //     name: fieldData?.name,
                          //     url: fieldData?.url,
                          //     time: fieldData?.uploadedAt,
                          //   });
                          // });
                          formUpload(e, doc?.id, setFieldValue, (fileData) => {
                            const addDocs =
                              JSON.parse(JSON.stringify(values))?.documents ||
                              [];

                            addDocs?.push({
                              id: doc?.id,
                              name: fileData?.name,
                              time: fileData?.uploadedAt,
                              url: fileData?.url,
                              type: doc?.id,
                            });

                            setFieldValue("documents", addDocs);
                          });
                        }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Box>
          <Box mt="4rem">
            <Button
              type="button"
              width="18.8rem"
              height="5rem"
              onClick={() => {
                validateForm({
                  ...values,
                })?.then((dataErrorBag) => {
                  const dataErrorBagKeys = Object?.keys(
                    {
                      ...(dataErrorBag?.documents && {
                        documents: dataErrorBag?.documents,
                      }),
                    } || {}
                  );
                  if (dataErrorBag?.documents) {
                    setTouched(
                      setNestedObjectValues(
                        {
                          ...(dataErrorBag?.documents && {
                            documents: dataErrorBag?.documents,
                          }),
                        },
                        true
                      )
                    );
                    setErrors({
                      ...(dataErrorBag?.documents && {
                        documents: dataErrorBag?.documents,
                      }),
                    });
                  } else {
                    if (isLastStep) {
                      onSubmit(values);
                    }
                    onNext();
                  }
                });
              }}
            >
              Next : Payment
            </Button>
          </Box>
        </Box>
      )}
      {/* </Form>
        );
      }} */}
    </FormLayout>
  );
};
