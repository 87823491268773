import React, { useMemo, useCallback } from "react";
import css from "@styled-system/css";
import styled from "styled-components";

import Text from "atoms/Text";
import { Box } from "atoms/Box";
import { Flex } from "atoms/Flex";
import { PAGE_SIZE } from "utils/constants";
import { SelectField } from "atoms/SelectField";
import { usePageNumber, usePageSize } from "hooks/usePageNumber";
// import MemoChevronLeft from "assets/icons/ChevronLeftIcon";
// import MemoChevronRight from "assets/icons/ChevronRightIcon";
import MemoArrowIcon from "assets/icons/Arrow";
import {
  useLocationQuery,
  useLocationQueryAdd,
  useLocationQuerySet,
} from "hooks/useLocationQuery";

import { RcPagination } from "./Pagination.style";

const variants = {
  default: css({
    "&.rc-pagination": { flexWrap: "wrap", display: "flex" },
    "li.rc-pagination-prev, li.rc-pagination-next": {
      border: "none",
      boxShadow: "none",
    },
    "li.rc-pagination-item": {
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: "1.4rem",
      fontFamily: "smallRegular",
      lineHeight: "2.4rem",
      color: "neutral.400",
      borderRadius: "100%",
      border: "none",
      boxShadow: "none",
      "&:hover, &:focus": {
        border: "none",
        // borderColor: "blue.400",
        outline: "0",
      },
      "&.rc-pagination-item-active": {
        bg: "secondary.700",
        a: {
          color: "white",
        },
      },
    },
    ".rc-pagination-jump-next, .rc-pagination-jump-prev": {
      border: "none",
      boxShadow: "none",
      button: {
        display: "none",
      },
    },
    ".rc-pagination-options": {
      display: "none",
    },
  }),
};

const StyledPagination = styled(RcPagination)`
  ${({ variant }) =>
    variants[
      variant && Object.keys(variants).includes(variant) ? variant : "default"
    ]}
`;

const getInt = (arg) => {
  if (arg) {
    if (typeof arg === "string") {
      return parseInt(arg, 10);
    }
    return arg;
  }
  return 0;
};

const prevIcon = (disabled) => (
  <Box
    transform="rotate(-90deg)"
    color={disabled ? "neutral.200" : "neutral.600"}
  >
    <MemoArrowIcon width="20px" height="20px" />
  </Box>
);

const nextIcon = (disabled) => (
  <Box
    transform="rotate(90deg)"
    color={disabled ? "neutral.200" : "neutral.600"}
  >
    <MemoArrowIcon
      width="20px"
      height="20px"
      color={disabled ? "gray.200" : "gray.600"}
    />
  </Box>
);

export const Pagination = ({
  variant,
  totalRecords,
  showPageSize,
  pageSize: propPageSize,
  pageSizeOptions = [1, 2, 3, 4].map((page) => page * PAGE_SIZE),
}) => {
  const setQuery = useLocationQuerySet();
  const currentPage = usePageNumber();
  const pageSize = usePageSize();
  const total = useMemo(() => getInt(totalRecords), [totalRecords]);
  const pageSizeInt = useMemo(
    () => getInt(propPageSize || pageSize),
    [propPageSize, pageSize]
  );
  const currentPageInt = useMemo(() => getInt(currentPage), [currentPage]);
  const searchParams = useLocationQuery();
  const setToUrl = useLocationQueryAdd();

  const onPageChangeHandler = useCallback(
    (page, take) => {
      if (page && take) {
        searchParams.page = `${page}`;
      } else {
        delete searchParams.page;
      }
      setQuery(searchParams);
    },
    [setQuery, searchParams]
  );

  const onChange = useCallback(
    (current, pageListLength) => {
      onPageChangeHandler(current, pageListLength);
    },
    [onPageChangeHandler]
  );

  const onChangePageSize = useCallback(
    ({ value }) => {
      setToUrl("page", undefined);
      setToUrl("take", value);
    },
    [setToUrl]
  );

  return (
    <Flex
      bg="white"
      // height={{ xs: "9rem", md: "5rem" }}
      alignItems="center"
      justifyContent={showPageSize ? "space-between" : "center"}
      flexWrap="wrap"
      py="2.2rem"
      px="2.4rem"
    >
      {showPageSize && (
        <Flex alignItems="center" flex="1">
          <Text color="neutral.600" truncate>
            Showing{" "}
            <Text color="primary.500" as="span">
              {(currentPageInt - 1) * pageSizeInt + 1}-
              {Math.min(currentPageInt * pageSizeInt, total)}
            </Text>{" "}
            of {total} results
          </Text>
          <Box width="10rem" ml="1.5rem">
            <SelectField
              noMargin
              labelHide
              onChange={onChangePageSize}
              options={pageSizeOptions.map((size) => ({
                label: size,
                value: Number.parseInt(size, 10),
              }))}
              value={{ label: pageSizeInt, value: pageSizeInt }}
            />
          </Box>
        </Flex>
      )}
      <StyledPagination
        total={total}
        onChange={onChange}
        pageSize={pageSizeInt}
        current={currentPageInt}
        pageSizeOptions={pageSizeOptions}
        prevIcon={prevIcon(currentPageInt <= 1)}
        nextIcon={nextIcon(currentPageInt * pageSizeInt >= total)}
        showTitle={false}
        variant={variant}
        jumpNextIcon={
          <Box position="absolute" fontWeight="600">
            ...
          </Box>
        }
        jumpPrevIcon={
          <Box position="absolute" fontWeight="600">
            ...
          </Box>
        }
        totalBoundaryShowSizeChanger={5}
      />
    </Flex>
  );
};
