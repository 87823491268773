import React, { useRef } from "react";

import { Box, Flex, Text } from "atoms";
import MemoTickWithCircleIcon from "assets/icons/TickWithCircle";

const getTop = (barHeight, refTop, refBottom, parentTop) => {
  const middleDifference = (refBottom - refTop) / 2;
  const middleRef = refTop + middleDifference;
  const barTop = middleRef - barHeight / 2 - parentTop;

  return barTop;
};

export const StepperTab = ({
  steps = [],
  serviceDetails,
  activeStep,
  setActiveStep,
  ...props
}) => {
  const tabRefs = useRef([]);

  return (
    <Flex
      px="2.4rem"
      w="100%"
      h="fit-content"
      flexDirection="column"
      pb="0.5rem"
      {...props}
    >
      {steps?.map((item, index) => {
        return (
          <Flex
            key={index}
            {...(index && { mt: "7.5rem" })}
            aI="center"
            cursor="pointer"
            onClick={(e) => setActiveStep?.(index)}
            ref={(el) => (tabRefs.current[index] = el)}
          >
            {/* {activeStep === index && (
              <Box
                w="1.1rem"
                h="350%"
                bg="primary.700"
                borderRadius="0.5rem"
                position="absolute"
                top="-3rem"
                right="-2.4rem"
                zIndex="1"
              />
            )} */}
            <Text
              variant="bodyLarge"
              color={activeStep === index ? "neutral.800" : "neutral.500"}
            >
              {item?.title}
            </Text>

            {/* completion icon */}
            {(item?.status === "completed" ||
              serviceDetails?.[item?.statusFlag] === "completed") && (
              <Box color="primary.900" ml="1.6rem">
                <MemoTickWithCircleIcon width="1.6rem" height="1.6rem" />
              </Box>
            )}
          </Flex>
        );
      })}

      <Box
        position="absolute"
        top="0"
        right="0"
        w="1.1rem"
        h="100%"
        bR="0.5rem"
        bg="neutral.50"
        ref={(el) => (tabRefs.current[steps?.length] = el)}
      >
        <Box
          w="1.1rem"
          h="8.7rem"
          bg="primary.700"
          borderRadius="0.5rem"
          position="absolute"
          {...(activeStep && {
            top: `${getTop(
              87,
              tabRefs?.current?.[activeStep]?.getBoundingClientRect()?.top,
              tabRefs?.current?.[activeStep]?.getBoundingClientRect()?.bottom,
              tabRefs?.current?.[steps?.length]?.getBoundingClientRect()?.top
            )}px`,
          })}
          // top={`${activeStep * 7.5 + (activeStep - 1) * 2.4}rem`}
          // right="0rem"
          zIndex="1"
          transition="all 0.5s"
        />
      </Box>
    </Flex>
  );
};
