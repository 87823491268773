import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { FormActivityCode } from "../components";
import { FormManagerLayout } from "./FormManagerLayout";
import FORM_NAMES from "../components/form.names";
import { useFormNavigation } from "hooks/useFormNavigation";
import { onChangeFormUploadFile } from "utils/utilities";
import useService from "contexts/Service";
import SERVICE_TYPE_CODE from "utils/services.type.constants";
import { Form } from "formik";
import { csChangeInBuisnessValidation } from "utils/validation";

const FORM_COMPONENT_NAMED_NAVIGATION = [
  {
    name: FORM_NAMES.FormActivityCode,
    Component: FormActivityCode,
    onNext: {
      next: FORM_NAMES.Cart,
      isStepIncrement: false,
    },
    attributes: {
      isLastStep: true,
    },
  },
  // {
  //   name: FORM_NAMES.FormDetails,
  //   Component: FormDetails,
  //   onNext: {
  //     next: FORM_NAMES.Cart,
  //   },
  //   attributes: {
  //     docs: [
  //       {
  //         id: "previousSecretaryResignationLetter",
  //         name: "Previous Secretary Resignation Letter",
  //       },
  //     ],
  //   },
  // },
];

const getFormElement = (name) => {
  return (
    FORM_COMPONENT_NAMED_NAVIGATION?.find((item) => item?.name === name) ||
    FORM_COMPONENT_NAMED_NAVIGATION?.find(
      (item) => item?.name === FORM_NAMES.FormActivityCode
    )
  );
};

const breadcrum = {
  firstText: "Home",
  firstUrl: "/dashboard",
  secondText: "Corporate Secretarial Services",
  secondUrl: "/service/corporate-secretarial",
  thirdText: "Change Buisness Code",
};

export const ChangeBuisnessCode = () => {
  const navigate = useNavigate();
  const [FormElement, setFormElement] = useState(getFormElement());
  const {
    actions: { createCS },
  } = useService();

  const [stepCount, currentFormCode, { formNavigationHandler }] =
    useFormNavigation(
      FORM_NAMES.FormActivityCode,
      FORM_NAMES,
      getFormElement,
      "/service/corporate-secretarial/change-buisness-code"
    );

  useEffect(() => {
    setFormElement(getFormElement(currentFormCode));
  }, [currentFormCode]);

  const onSubmit = useCallback(
    ({ cartSummary, cartDetails, ...values }) => {
      const formattedKeys = Object.keys(values || {})?.filter((item) =>
        item?.includes("loading")
      );
      const formattedValues = JSON.parse(JSON.stringify(values));

      formattedKeys?.forEach((item) => {
        delete formattedValues[item];
      });

      createCS(
        formattedValues,
        SERVICE_TYPE_CODE.CORPORATE_SECRETARIAL.changeInBuisnessCode.code,
        cartSummary
      ).then(() => {
        navigate("/cart");
      });
    },
    [createCS]
  );

  return (
    <FormManagerLayout
      tabHeader={{
        title:
          SERVICE_TYPE_CODE.CORPORATE_SECRETARIAL.changeInBuisnessCode.title,
        breadcrum: breadcrum,
        onPrevious: () => {
          navigate("/service/corporate-secretarial");
        },
      }}
      formikProps={{
        initialValues: {
          activityType: "primary",
          primaryActivityCode: "",
          primaryBusinessDescription: "",
          secondaryActivityCode: "",
          secondaryBusinessDescription: "",
          cartSummary: "",
          cartDetails: "",
        },
        enableReinitialize: true,
        validationSchema: csChangeInBuisnessValidation,
      }}
    >
      {({
        values,
        setFieldValue,
        validateForm,
        setTouched,
        setErrors,
        errors,
        touched,
        isSubmitting,
      }) => {
        return (
          <Form>
            <FormElement.Component
              step={`Step ${stepCount || ""}/1`}
              onNext={() => {
                formNavigationHandler(
                  FormElement?.onNext?.next,
                  FormElement?.onNext?.isStepIncrement
                );
              }}
              breadcrum={
                (parseInt(stepCount) || 0) > 1 && { firstText: "Go Back" }
              }
              onPrevious={() => {
                (parseInt(stepCount) || 0) > 1 && navigate(-1);
              }}
              formUpload={onChangeFormUploadFile}
              values={values}
              setFieldValue={setFieldValue}
              {...FormElement?.attributes}
              onSubmit={onSubmit}
              cartDetails={values?.cartDetails || []}
              validateForm={validateForm}
              setTouched={setTouched}
              setErrors={setErrors}
              errors={errors}
              touched={touched}
              isSubmitting={isSubmitting}
            />
          </Form>
        );
      }}
    </FormManagerLayout>
  );
};
