import React from "react";

import { Box, Flex, Grid, Line, Text } from "atoms";
// import MemoPdficon from "assets/icons/Pdficon";
import { format, parseISO } from "date-fns";
import MemoFileIcon from "assets/icons/File";

export const UserDetails = ({ serviceDetails }) => {
  return (
    <Box w="100%">
      <Text variant="subtitleMedium" fontWeight="600">
        Documents
      </Text>

      <Line mt="1.6rem" />

      {serviceDetails?.details?.documents?.map((doc, index) => (
        <Grid key={index} gTC="auto 1fr" gCG="2.4rem" aI="center" mt="2.4rem">
          <Text variant="bodyLarge" color="neutral.700">
            Certify True Copy Document {index + 1}
          </Text>

          <Flex
            cursor="pointer"
            onClick={() => window.open(doc?.url, "_blank")}
          >
            <Box mt="1.2rem" color="primary.900">
              <MemoFileIcon height="2.4rem" width="2.4rem" />
            </Box>
            <Box pl="1.2rem">
              <Text variant="bodyLarge" fontWeight="600" color="neutral.700">
                {doc?.name}
              </Text>
              <Text variant="bodySmall" color="neutral.500">
                {doc?.time &&
                  format(parseISO(doc?.time), "dd MMM, yyyy hh:mm a")}
              </Text>
            </Box>
          </Flex>
        </Grid>
      ))}
    </Box>
  );
};
