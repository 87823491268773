import * as React from "react";

function Cart(props) {
  return (
    <svg
      width="19"
      height="20"
      viewBox="0 0 19 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.61905 6.43099H16.2296L16.682 4.62147H5.42857V2.81194H17.8419C17.9794 2.81195 18.1152 2.8433 18.2387 2.90363C18.3623 2.96395 18.4706 3.05166 18.5552 3.16008C18.6398 3.2685 18.6985 3.39479 18.727 3.52934C18.7555 3.66388 18.7529 3.80315 18.7195 3.93656L16.4576 12.9842C16.4086 13.1798 16.2956 13.3535 16.1366 13.4776C15.9776 13.6017 15.7817 13.6691 15.58 13.6691H2.71429C2.47433 13.6691 2.2442 13.5738 2.07452 13.4041C1.90485 13.2344 1.80952 13.0043 1.80952 12.7643V1.90718H0V0.0976562H2.71429C2.95424 0.0976563 3.18437 0.192979 3.35405 0.362655C3.52372 0.532331 3.61905 0.76246 3.61905 1.00242V6.43099ZM3.61905 19.0977C3.13913 19.0977 2.67887 18.907 2.33952 18.5677C2.00017 18.2283 1.80952 17.768 1.80952 17.2881C1.80952 16.8082 2.00017 16.348 2.33952 16.0086C2.67887 15.6693 3.13913 15.4786 3.61905 15.4786C4.09896 15.4786 4.55922 15.6693 4.89857 16.0086C5.23793 16.348 5.42857 16.8082 5.42857 17.2881C5.42857 17.768 5.23793 18.2283 4.89857 18.5677C4.55922 18.907 4.09896 19.0977 3.61905 19.0977ZM14.4762 19.0977C13.9963 19.0977 13.536 18.907 13.1967 18.5677C12.8573 18.2283 12.6667 17.768 12.6667 17.2881C12.6667 16.8082 12.8573 16.348 13.1967 16.0086C13.536 15.6693 13.9963 15.4786 14.4762 15.4786C14.9561 15.4786 15.4164 15.6693 15.7557 16.0086C16.0951 16.348 16.2857 16.8082 16.2857 17.2881C16.2857 17.768 16.0951 18.2283 15.7557 18.5677C15.4164 18.907 14.9561 19.0977 14.4762 19.0977Z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoCartIcon = React.memo(Cart);
export default MemoCartIcon;
