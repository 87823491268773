const FORM_NAMES = {
  FormAddressProof: "Form Address Proof",
  FormCompanyDetails: "Form Company Details",
  FormCorporateAddressDetails: "Form Corporate Address Details",
  FormCorporateAddressService: "Form Corporate Address Service",
  FormDirector: "Form Director",
  FormDirectorDetails: "Form Director Details",
  FormLocalDirector: "Form Local Director",
  FormNomineeServices: "Form Nominee Services",
  FormNomineeShareholderDetails: "Form Nominee Shareholder Details",
  FormShareholderDetails: "Form Shareholder Details",
  FormShareholderServices: "Form Shareholder Services",
  Cart: "Cart",
  FormDirectorPlan: "Form Director Plan",
};

export default FORM_NAMES;
