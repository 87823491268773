import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";

export const ToggleButton = ({ value, onChange }) => {
  const [toggleState, setToggleState] = useState(false);

  const toggleStateHandler = useCallback(() => {
    let stateValue = null;
    setToggleState((prev) => {
      stateValue = !prev;
      return !prev;
    });
    onChange(stateValue);
  }, [onChange]);

  useEffect(() => {
    setToggleState(!!value);
  }, [value]);

  return (
    <>
      <Container onClick={toggleStateHandler} value={toggleState}>
        <Circle value={toggleState} />
      </Container>
    </>
  );
};

const Container = styled.div`
  margin: 0 5px;
  width: 4.4rem;
  height: 1.6rem;
  border-radius: 0.9rem;
  background-color: ${({ value }) => (value ? "#5A9299" : "#9D9D9D")};
  display: flex;
  align-items: center;
`;

const Circle = styled.div`
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 50%;
  cursor: pointer;
  background-color: ${({ value }) => (value ? "#275F66" : "#DDD9CE")};
  margin: 0 3px;
  transform: ${({ value }) =>
    value ? "translateX(1.7rem)" : "translateX(-0.4rem)"};
  transition: all 0.2s ease-in-out;
`;
