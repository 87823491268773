import React from "react";
import { useNavigate } from "react-router-dom";

import { Box, Flex, Grid, Line, Loader, Text } from "atoms";
import { Sheet } from "organisms";
import { NotificationCard } from "./NotificationCard";
import MemoNotificationFile from "assets/icons/NotificationFile";
import { OWNER_ROLE_CODE, USER_ROLE_CODE } from "utils/constants";
import MemoEmptyCartIcon from "assets/icons/EmptyCartIcon";
import MemoNotificationBellIcon from "assets/icons/NotificationBell";
import MemoNotificationCenterI from "assets/icons/NotificationCenterI";
import MemoNotificationHalfCircle from "assets/icons/NotificationHalfCircle";

const scrollbar = {
  width: "100%",
  height: "50rem",
  overflowY: "scroll",

  direction: "ltr",

  "::-webkit-scrollbar": {
    width: "8px",
  },
  "::-webkit-scrollbar-track": {
    borderRadius: "10px",
    background: "#F5F5F5",
  },
  "::-webkit-scrollbar-thumb": {
    background: "#9D9D9D",
    borderRadius: "8px",
  },
};

export const NotificationDropdown = ({
  isOpen,
  onClose,
  notifications,
  role,
  loading,
  onRead,
  onReadAll,
}) => {
  const navigate = useNavigate();

  return (
    <Sheet isOpen={isOpen} onClose={onClose} bg="rgba(0,0,0,0.1)">
      <Box
        // as="span"
        minWidth="46.5rem"
        //minHeight="60rem"
        position="absolute"
        bg="white"
        right="-310%"
        top="280%"
        bR="1rem"
        boxShadow="m"
        zIndex="1000"
        pr="1.6rem"
        pl="1.6rem"
        pb="1.7rem"
      >
        <Loader loading={loading}>
          <Box
            top="-8px"
            w="3.8rem"
            h="3.8rem"
            position="absolute"
            left="calc(100% - 80px)"
            transform="rotate(45deg)"
            bg="white"
          />

          <Flex jC="space-between" mt="3rem">
            <Text color="neutral.800" variant="bodyLarge" fontWeight="600">
              Notifications
            </Text>
            <Text
              color="primary.900"
              variant="bodyLarge"
              fontWeight="600"
              cursor="pointer"
              onClick={() => {
                onReadAll();
                onClose();
              }}
            >
              Mark all as read
            </Text>
          </Flex>
          <Line mt="1.3rem" />
          {/* <Tab
          tabs={tabData}
          activeTab={activeTab}
          setActiveTab={(index) => setActiveTab(index)}
        /> */}
          {/* <Text color="neutral.400" variant="bodySmall" mt="2.5rem">
          Today
        </Text> */}
          <Box css={scrollbar}>
            {/* Notification Card */}
            <Grid gridAutoFlow="row" mt="0.8rem">
              {!notifications?.length && (
                <Flex aI="center" jC="center" py="2.4rem" color="primary.500">
                  <MemoNotificationBellIcon width="2rem" height="2rem" />
                  <Text
                    variant="bodyLarge"
                    color="neutral.700"
                    justifySelf="center"
                    ml="2.4rem"
                  >
                    No Notifications
                  </Text>
                </Flex>
              )}
              {notifications?.map((item, index) => (
                <NotificationCard
                  key={index}
                  Icon={MemoNotificationFile}
                  description={item?.message}
                  time={item?.createdAt}
                  isRead={item?.isRead}
                  onClick={() => {
                    if (item?.taskId) {
                      if (role === USER_ROLE_CODE) {
                        navigate(
                          `/query-list/mytask/user/details/${item?.taskId}`
                        );
                      }
                      if (role === OWNER_ROLE_CODE) {
                        navigate(
                          `/query-list/mytask/owner/details/${item?.taskId}`
                        );
                      }
                    }
                    if (!item?.isRead) {
                      onRead(item?._id);
                    }
                    onClose();
                  }}
                  cursor="pointer"
                />
              ))}
            </Grid>
          </Box>
        </Loader>
      </Box>
    </Sheet>
  );
};
