import React, { useState } from "react";
import { Form, setNestedObjectValues } from "formik";

import { Button, Flex, Line } from "atoms";
import { cwToast, FormToggle } from "molecules";
import FormLayout from "../FormLayout";
import MemoTick from "styles/icons/Tick";
import MemoCross from "styles/icons/Cross";
import FORM_NAMES from "templates/Incorporation/form.names";
import { cartFeatureHandler } from "utils/utilities";
import PRODUCT_PRICES from "utils/princing.constant";
import { incorporationCorporateAddressServiceValidation } from "utils/validation";

const INITIAL_VALUES = {
  usingCorporateAddressDetails: "",
};

export const FormCorporateAddressService = ({
  breadcrum,
  step,
  onNext,
  onPrevious,
  onSubmit,
  formInitValues,
  ...props
}) => {
  const [submitting, setSubmitting] = useState(false);
  return (
    <FormLayout
      breadcrum={breadcrum}
      step={step}
      onPrevious={onPrevious}
      title="Would you like to use Chartswood’s corporate address services?"
      subtitle="Chartswood provides registered address and mailing services"
      formikProps={{
        initialValues: {
          ...INITIAL_VALUES,
          ...formInitValues,
        },
        enableReinitialize: true,
        validationSchema: incorporationCorporateAddressServiceValidation,
      }}
      {...props}
    >
      {({
        values,
        dirty,
        setFieldValue,
        validateForm,
        setTouched,
        setErrors,
        errors,
      }) => {
        return (
          <>
            <Form>
              <Flex mt="4rem">
                <FormToggle
                  name="usingCorporateAddressDetails"
                  label="Yes"
                  value={true}
                  Icon={MemoTick}
                />
                <FormToggle
                  name="usingCorporateAddressDetails"
                  label="No"
                  value={false}
                  ml="3.2rem"
                  Icon={MemoCross}
                  isWrong
                />
              </Flex>

              {/* {errors?.usingCorporateAddressDetails && (
                <Error text={errors?.usingCorporateAddressDetails} />
              )} */}

              <Line mt="4.8rem" />
              <Button
                type="button"
                w="fit-content"
                px="4rem"
                mt="4rem"
                loading={submitting}
                onClick={() => {
                  validateForm(values)?.then((dataErrorBag) => {
                    const dataErrorBagKeys = Object?.keys(dataErrorBag || {});
                    if (dataErrorBagKeys?.length) {
                      setTouched(setNestedObjectValues(dataErrorBag, true));
                      setErrors(dataErrorBag);
                      dataErrorBag?.usingCorporateAddressDetails &&
                        cwToast(
                          "error",
                          dataErrorBag?.usingCorporateAddressDetails
                        );
                    } else {
                      if (dirty) {
                        setSubmitting(true);

                        const withCartValues = JSON.parse(
                          JSON.stringify(values)
                        );
                        const newCartFeatures = cartFeatureHandler(
                          withCartValues?.cartSummary?.features,
                          PRODUCT_PRICES.corporate_address_services.name,
                          PRODUCT_PRICES.corporate_address_services.type,
                          withCartValues?.usingCorporateAddressDetails
                        );
                        if (withCartValues?.cartSummary) {
                          withCartValues.cartSummary.features = newCartFeatures;
                        } else {
                          withCartValues.cartSummary = {
                            features: newCartFeatures,
                          };
                        }
                        setFieldValue("cartSummary.features", newCartFeatures);
                        onSubmit(withCartValues)
                          .then(() => {
                            values?.usingCorporateAddressDetails
                              ? onNext(FORM_NAMES.FormLocalDirector, true)
                              : onNext(
                                  FORM_NAMES.FormCorporateAddressDetails,
                                  false
                                );
                          })
                          .catch(console.error)
                          .finally(() => {
                            setSubmitting(false);
                          });
                      } else {
                        values?.usingCorporateAddressDetails
                          ? onNext(FORM_NAMES.FormLocalDirector, true)
                          : onNext(
                              FORM_NAMES.FormCorporateAddressDetails,
                              false
                            );
                      }
                    }
                  });
                }}
              >
                {values?.usingCorporateAddressDetails
                  ? "NEXT : Nominee Director"
                  : "NEXT : Corporate Address Details"}
              </Button>
            </Form>
          </>
        );
      }}
    </FormLayout>
  );
};
