import React, { useCallback, useEffect, useState } from "react";
import { format } from "date-fns";
import { Form, Formik } from "formik";

import { Box, Flex, Grid, Pagination, Table, Text } from "atoms";
import { FilterChips, TabHeader } from "organisms";
import { cwToast, FormInput, ProfileAvatar } from "molecules";
import MemoFilterIcon from "assets/icons/Filter";
import { FilterModal } from "./components";
import MemoEyeShowIcon from "assets/icons/EyeShow";
import useService from "contexts/Service";
import { useSort } from "hooks/useSort";
import { usePageNumber, usePageSize } from "hooks/usePageNumber";
import { FILE_TYPES, OWNER_ROLE_CODE, PAGE_SIZE, USER_ROLE_CODE } from "utils/constants";
import MemoDownload from "assets/icons/Download";
import { useNavigate, useSearchParams } from "react-router-dom";
import useAuth from "contexts/Auth";
import MemoRefreshIcon from "assets/icons/Refresh";
import {
  filterStateHandler,
  uploadFile,
  verifyUploadFileDocumentExtension,
} from "utils/utilities";
import { useLocationQueryAdd } from "hooks/useLocationQuery";

export const Library = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const {
    state: { userData },
  } = useAuth();

  const {
    actions: { getDocuments, updateDocument },
  } = useService();

  // filter
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [filterState, setFilterState] = useState({});
  const serviceTypeFilter = searchParams.get("serviceType");
  const fromDateFilter = searchParams.get("fromDate");
  const toDateFilter = searchParams.get("toDate");
  const assigneeFilter = searchParams.get("assignee");
  const documentNameFilter = searchParams.get("documentName");
  const setParam = useLocationQueryAdd();

  // table
  const [total, setTotal] = useState(0);
  const sort = useSort();
  const page = Number(usePageNumber());
  const pageSize = Number(usePageSize());

  // filterParamHandler starts
  const filterParamHandler = useCallback(() => {
    const params = {
      serviceType: serviceTypeFilter,
      fromDate: fromDateFilter,
      toDate: toDateFilter,
      documentName: documentNameFilter,
      // assignedTo: assigneeFilter,
    };

    return params;
  }, [
    serviceTypeFilter,
    fromDateFilter,
    toDateFilter,
    assigneeFilter,
    documentNameFilter,
  ]);

  // document fetch handling starts
  const [documents, setDocuments] = useState([]);

  const fetchDocuments = useCallback(async () => {
    getDocuments({
      sortBy: sort || "createdAt:desc",
      limit: pageSize,
      skip: (page - 1) * pageSize,
      ...filterParamHandler(),
      // ...filterParams,
    }).then((data) => {
      setDocuments(data?.data?.docs);
      setTotal(data?.data?.total);
    });
  }, [getDocuments, sort, pageSize, page, filterParamHandler]);

  useEffect(() => {
    fetchDocuments();
  }, [fetchDocuments]);
  // document fetch handling ends

  // search handler starts
  // const searchHandler = useCallback(
  //   (searchKey) => {
  //     let filteredDocs = documents?.filter((doc) => {
  //       return (
  //         doc?.serviceType?.toLowerCase()?.includes(searchKey?.toLowerCase()) ||
  //         doc?.documentName?.toLowerCase()?.includes(searchKey?.toLowerCase())
  //       );
  //     });
  //     setActiveDocuments(filteredDocs);
  //   },
  //   [documents]
  // );
  // search handler ends

  // document re-upload handler starts
  const [loadingDocumentUpload, setLoadingDocumentUpload] = useState(false);

  const reuploadDocumentHandler = useCallback(
    (e, currentDocument) => {
      // staring upload
      setLoadingDocumentUpload(currentDocument?._id);

      const file = e.target.files[0];
      if (verifyUploadFileDocumentExtension(file)) {
        // uploadin to s3
        uploadFile(file)
          .then(({ location }) => {
            cwToast("success", "Document Uploaded");

            // starting update
            setLoadingDocumentUpload(`${currentDocument?._id} updating`);
            updateDocument({
              serviceId: currentDocument?.serviceId,
              documentLink: location,
              documentName: file?.name,
              documentTime: new Date().toISOString(),
              documentType: currentDocument?.documentType,
            })
              .then(() => {
                cwToast("success", "Document Updated");
              })
              .catch((e) => {
                cwToast("error", "Failed to Upload");
                console.error(e);
              });
            // .finally(() => setLoadingDocumentUpload(false));
          })
          .catch((e) => {
            cwToast("error", "Failed to Upload");
            console.error(e);
          });
      } else {
        setLoadingDocumentUpload(false);
        cwToast(
          "error",
          "Invalid extension found.",
          "Valid extension is .pdf, .doc/.docx, .png, .jpg/.jpeg, .webp"
        );
      }
    },
    [updateDocument]
  );
  // document re-upload handler ends

  // hydrating filter starts
  const hydrateFilter = useCallback(() => {
    let chosenRoleBasedAssignee = [];

    if (userData?.role === USER_ROLE_CODE) {
      chosenRoleBasedAssignee = documents
        ?.map((doc) => ({
          id: doc?.taskOwner?._id,
          email: doc?.taskOwner?.email,
        }))
        ?.filter(
          (task, index, self) =>
            self?.findIndex((o) => o?.id === task?.id) === index
        );
    }

    if (userData?.role === OWNER_ROLE_CODE) {
      chosenRoleBasedAssignee = documents
        ?.map((doc) => ({
          id: doc?.user?._id,
          email: `${doc?.user?.firstName} ${doc?.user?.lastName}`,
        }))
        ?.filter(
          (task, index, self) =>
            self?.findIndex((o) => o?.id === task?.id) === index
        );
    }

    chosenRoleBasedAssignee = chosenRoleBasedAssignee?.filter(
      (item) => item?.id && item?.email
    );

    const allFilters = {
      statusOptions: [
        {
          id: 1,
          title: "Incorporation",
          status: "incorporation",
        },
        {
          id: 2,
          title: "Corporate Secretorial",
          status: "cs",
        },
        {
          id: 3,
          title: "Accounting",
          status: "accounting",
        },
        {
          id: 4,
          title: "Tax",
          status: "tax",
        },
        {
          id: 5,
          title: "Employment Pass & Visa Application",
          status: "ev",
        },
      ],
      date: { startDate: "", endDate: "", isApplied: false },
      assigneeOptions: chosenRoleBasedAssignee,
    };

    // prefabing filter starts
    if (serviceTypeFilter) {
      allFilters.serviceType = serviceTypeFilter;
    }

    if (fromDateFilter) {
      allFilters.date.startDate = fromDateFilter;
      allFilters.date.isApplied = true;
    }

    if (toDateFilter) {
      allFilters.date.endDate = toDateFilter;
      allFilters.date.isApplied = true;
    }

    if (assigneeFilter) {
      allFilters.assignee = assigneeFilter;
    }
    // prefabing filter ends

    setFilterState(allFilters);
  }, [
    documents,
    serviceTypeFilter,
    fromDateFilter,
    toDateFilter,
    assigneeFilter,
  ]);

  useEffect(() => {
    hydrateFilter();
  }, [hydrateFilter]);
  // hydrating filter ends

  // handle Filter removal starts
  const handleFilter = useCallback(
    /**
     * @param {any} type key of the filter state
     * @param {any} id id of the item under key filter to be reversed
     *
     * @param {any} data specific for "type === date" : applies the given data to date type filter
     */
    (type, id, data) => {
      setFilterState(filterStateHandler(filterState, type, id, data));
    },
    [filterState]
  );
  // handle Filter removal ends

  // handle filter query starts
  useEffect(() => {
    setParam("serviceType", filterState?.serviceType);

    setParam(
      "fromDate",
      filterState?.date?.startDate &&
        new Date(filterState?.date?.startDate).toISOString()
    );

    setParam(
      "toDate",
      filterState?.date?.endDate &&
        new Date(filterState?.date?.endDate).toISOString()
    );

    setParam("assignee", filterState?.assignee);
  }, [filterState]);
  // handle filter query ends

  return (
    <Box px="3.2rem">
      {/* header */}
      <TabHeader
        title="Document Library"
        breadcrum={{
          firstText: "Home",
          firstUrl: "/dashboard",
          secondText: "Document Library",
        }}
        onPrevious={() => navigate("/dashboard")}
      />

      {/* body */}
      <Box bg="#fff" mt="2.6rem">
        <Flex width="100%" aI="center" jC="space-between" px="2.4rem" pt="4rem">
          <Box>
            {/* title */}
            <Text
              variant="subtitleMedium"
              fontWeight="600"
              color="neutral.800"
              // pt="4rem"
            >
              Document list
            </Text>

            {/* filter chips */}
            <Grid
              // pt="1.6rem"
              css={{
                "& > :first-child": {
                  mt: "1.6rem",
                },
              }}
              // gridAutoFlow="column"
              gCG="1.7rem"
              gRG="1.7rem"
              gTC="repeat(4, auto)"
            >
              {/* status chips */}
              {filterState?.serviceType &&
                [
                  filterState?.statusOptions?.find(
                    (item) => item?.status === filterState?.serviceType
                  ),
                ]?.map((item, index) => (
                  <FilterChips
                    key={index}
                    title={item?.title}
                    onClickCross={() => {
                      handleFilter("serviceType", "");
                    }}
                  />
                ))}

              {/* date chips */}
              {filterState?.date?.isApplied && (
                <FilterChips
                  title={`${
                    filterState?.date?.startDate &&
                    format(new Date(filterState?.date?.startDate), "dd MMM")
                  } - ${
                    filterState?.date?.endDate &&
                    format(new Date(filterState?.date?.endDate), "dd MMM")
                  }`}
                  onClickCross={() =>
                    handleFilter("date", "", {
                      isApplied: false,
                      startDate: "",
                      endDate: "",
                    })
                  }
                />
              )}
              {/* assignee chips */}
              {filterState?.assignee &&
                [
                  documents?.find(
                    (doc) =>
                      ({
                        [USER_ROLE_CODE]: doc?.taskOwner?._id,
                        [OWNER_ROLE_CODE]: doc?.user?._id,
                      }[userData?.role] === filterState?.assignee)
                  ),
                ]?.map((doc, index) => (
                  <FilterChips
                    key={index}
                    title={
                      {
                        [USER_ROLE_CODE]: doc?.taskOwner?.email,
                        [OWNER_ROLE_CODE]: `${doc?.user?.firstName} ${doc?.user?.lastName}`,
                      }[userData?.role]
                    }
                    onClickCross={() => {
                      handleFilter("assignee", "");
                    }}
                  />
                ))}
            </Grid>
          </Box>

          <Flex aI="center">
            {/* Search Input */}
            <Box>
              <Formik initialValues={{ search: "" }}>
                {({ setFieldValue }) => (
                  <Form>
                    <Box w="25.8rem" h="5rem">
                      <FormInput
                        name="search"
                        label="Search"
                        isSearchable
                        isValidationBehaviour={false}
                        onChange={(e) => {
                          setFieldValue("search", e?.currentTarget?.value);
                          // searchHandler(e?.currentTarget?.value);
                          setParam("documentName", e?.currentTarget?.value);
                        }}
                      />
                    </Box>
                  </Form>
                )}
              </Formik>
            </Box>

            {/* Filter */}
            <Box
              color="neutral.500"
              ml="2rem"
              cursor="pointer"
              onClick={() => {
                setIsFilterModalOpen(true);
              }}
            >
              <MemoFilterIcon height="2.4rem" />
            </Box>

            {/* filterModal */}
            {isFilterModalOpen && (
              <FilterModal
                isOpen={isFilterModalOpen}
                initFilterState={filterState}
                onClose={() => {
                  setIsFilterModalOpen(false);
                }}
                onApplyFilter={(data) => {
                  setFilterState(data);
                  setIsFilterModalOpen(false);
                }}
                onClearAll={() => {
                  setParam("serviceType", undefined);
                  setParam("fromDate", undefined);
                  setParam("toDate", undefined);
                  setParam("assignee", undefined);
                  setIsFilterModalOpen(false);
                }}
                role={userData?.role}
              />
            )}
          </Flex>
        </Flex>

        {/* tabular data */}
        <Table
          variant="secondary"
          tableHeader={[
            { name: "Documents", sortKey: "documentName" },
            { name: "Service Name", sortKey: "serviceType" },
            { name: "Last Update Date", sortKey: "updatedAt" },
            // { name: "Assignee" },

            ...[
              {
                [USER_ROLE_CODE]: { name: "Assignee" },
                [OWNER_ROLE_CODE]: { name: "User" },
              }[userData?.role],
            ],

            { name: "Actions" },
          ]}
        >
          <tbody>
            {documents?.map((doc, index) => {
              return (
                <tr key={index}>
                  {/* column:  Document name*/}
                  <td>
                    <Text variant="bodyMedium" color="neutral.700">
                      {doc?.documentName}
                    </Text>
                  </td>

                  {/* column:  Service name*/}
                  <td>
                    <Text
                      variant="bodyMedium"
                      color="neutral.700"
                      textTransform="uppercase"
                    >
                      {doc?.serviceType}
                    </Text>
                  </td>

                  {/* column:  Last update date*/}
                  <td>
                    <Text
                      variant="bodyMedium"
                      color="neutral.700"
                      textTransform="uppercase"
                    >
                      {format(new Date(doc?.updatedAt), "dd-MM-yyyy")}
                    </Text>
                  </td>

                  {/* column:  Subservices*/}
                  <td>
                    <Flex aI="center">
                      <ProfileAvatar
                        // name={`${doc?.taskOwner?.email}`}
                        name={
                          {
                            [USER_ROLE_CODE]: `${
                              doc?.taskOwner?.email || "N A"
                            }`,
                            [OWNER_ROLE_CODE]: `${
                              doc?.user?.firstName || "N A"
                            } ${doc?.user?.lastName}`,
                          }[userData?.role]
                        }
                        size="xs"
                        bg="secondary.50"
                        border="none"
                        textProps={{ color: "secondary.900" }}
                      />
                      <Text
                        variant="bodyLarge"
                        fontWeight="600"
                        color="neutral.600"
                        ml="0.6rem"
                      >
                        {
                          {
                            [USER_ROLE_CODE]: `${
                              doc?.taskOwner?.email || "NA"
                            }`,
                            [OWNER_ROLE_CODE]: `${
                              doc?.user?.firstName || "NA"
                            } ${
                              doc?.user?.firstName ? doc?.user?.lastName : ""
                            }`,
                          }[userData?.role]
                        }
                      </Text>
                    </Flex>
                  </td>

                  {/* column:  Service ends on*/}
                  <td>
                    <Flex aI="center">
                      {/* eslint-disable-next-line react/jsx-no-target-blank */}
                      <a href={doc?.documentLink} target={"_blank"}>
                        <Box color="neutral.300" cursor="pointer">
                          <MemoEyeShowIcon width="1.9rem" />
                        </Box>
                      </a>
                      <a href={doc?.documentLink} download>
                        <Box color="neutral.300" cursor="pointer" ml="2.4rem">
                          <MemoDownload width="1.5rem" />
                        </Box>
                      </a>

                      {userData?.role === USER_ROLE_CODE && (
                        <Box
                          // color="neutral.300"
                          color={
                            loadingDocumentUpload === doc?._id
                              ? loadingDocumentUpload?.includes("updating")
                                ? "primary.500"
                                : "secondary.500"
                              : "neutral.300"
                          }
                          cursor="pointer"
                          ml="2.4rem"
                          as="label"
                          type="button"
                          className={
                            loadingDocumentUpload === doc?._id
                              ? "rotate-circular"
                              : ""
                          }
                        >
                          <input
                            name="logo"
                            type="file"
                            value={null}
                            accept={FILE_TYPES}
                            onChange={(e) => {
                              reuploadDocumentHandler(e, doc);
                            }}
                            style={{ display: "none" }}
                          />
                          <MemoRefreshIcon width="1.6rem" />
                        </Box>
                      )}
                    </Flex>
                  </td>
                </tr>
              );
            })}
            {total === 0 && (
              <tr>
                <td colSpan="10">
                  <Text textAlign="center">No Documents Uploaded</Text>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        {total > PAGE_SIZE && (
          // <Box mt="2rem">
          <Pagination totalRecords={total} />
          // </Box>
        )}
      </Box>
    </Box>
  );
};
