import { Loader } from "atoms";
import useAuth from "contexts/Auth";
import React, { useEffect } from "react";

export const Logout = () => {
  const {
    actions: { logout },
  } = useAuth();

  useEffect(() => {
    logout();
  }, [logout]);

  return <Loader loading />;
};
