import { Box, Button, Line } from "atoms";
import UploadCart from "organisms/UploadCart";
import React from "react";
import { FormLayout } from "templates/CorporateSecretarial/components";

export const FormUploadCart = ({ step, onNext, ...props }) => {
  return (
    <>
      <FormLayout
        //step={step}
        title="Kindly provide Details:"
        subtitle="Please upload payslip, previous years notice of assessment"
        {...props}
      >
        <Box mt="4.8rem">
          <UploadCart />
        </Box>
        <Line mt="4rem" />
        <Button w="fit-content" px="4rem" mt="4rem" onClick={onNext}>
          Next : Payment
        </Button>
      </FormLayout>
    </>
  );
};
