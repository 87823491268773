import React from "react";
import { Box, Button, Flex } from "atoms";
import { cwToast, FormDateInput, FormToggle } from "molecules";
import FormLayout from "../FormLayout";
import MemoTick from "styles/icons/Tick";
import MemoCross from "styles/icons/Cross";
import FORM_NAMES from "../form.names";
import { replaceInArrayOfObjects } from "utils/utilities";
import PRODUCT_PRICES from "utils/princing.constant";
import { setNestedObjectValues } from "formik";
/**
 * reserved formik values
 * @value {"yes" ,"no"} formUpdateAddress
 */
export const FormUpdateAddress = ({
  step,
  onNext,
  values,
  onSubmit,
  isLastStep,
  setFieldValue,
  validateForm,
  setTouched,
  setErrors,
  errors,
  touched,
  ...props
}) => {
  return (
    <FormLayout
      step={step}
      title="Would you like to use Chartswood’s corporate address services?"
      subtitle="Chartswood provides registered address and mailing services"
      {...props}
    >
      {/* {({ values }) => {
        return (
          <Form> */}
      {/* <Box
              mt="4.8rem"
              bg="green.50"
              w="48.8rem"
              h="11.2rem"
              pt="2.4rem"
              pl="3.2rem"
            >
              <Text
                variant="subtitleSmall"
                fontWeight="600"
                color="neutral.800"
              >
                Chartswood Address
              </Text>
              <Text variant="bodyMedium" color="neutral.800" mt="1.6rem">
                137 Market St Singapore 048943
              </Text>
            </Box> */}
      {/* input */}
      <Flex mt="4.8rem">
        <FormToggle
          name="useCWaddress"
          label="Yes"
          value={true}
          Icon={MemoTick}
        />
        <FormToggle
          name="useCWaddress"
          label="No"
          value={false}
          ml="3.2rem"
          Icon={MemoCross}
          isWrong
        />
      </Flex>
      {values?.useCWaddress && (
        <Box w="48.8rem" mt="4.8rem">
          <FormDateInput name="effectiveDate" label="Effective Date" />
        </Box>
      )}
      {/* line */}
      <Box w="100%" h="0.5px" bg="neutral.200" mt="4.8rem" />

      <Button
        width="fit-content"
        px="4rem"
        mt="4rem"
        type="button"
        onClick={() => {
          validateForm({
            useCWaddress: values?.useCWaddress,
            ...(values?.useCWaddress && {
              effectiveDate: values?.effectiveDate,
            }),
          })?.then((dataErrorBag) => {
            if (
              dataErrorBag?.useCWaddress ||
              (values?.useCWaddress && dataErrorBag?.effectiveDate)
            ) {
              setTouched(
                setNestedObjectValues(
                  {
                    useCWaddress: dataErrorBag?.useCWaddress,
                    ...(values?.useCWaddress && {
                      effectiveDate: dataErrorBag?.effectiveDate,
                    }),
                  },
                  true
                )
              );
              setErrors(dataErrorBag);
              dataErrorBag?.useCWaddress &&
                cwToast("error", dataErrorBag?.useCWaddress);
            } else {
              let newCartFeatures = values?.cartSummary?.features || [];
              newCartFeatures = replaceInArrayOfObjects(
                {
                  finder: "key",
                  finderValue: values?.useCWaddress
                    ? PRODUCT_PRICES.change_address_no.type
                    : PRODUCT_PRICES.change_address_yes.type,
                },
                {
                  name: values?.useCWaddress
                    ? PRODUCT_PRICES.change_address_yes.name
                    : PRODUCT_PRICES.change_address_no.name,
                  key: values?.useCWaddress
                    ? PRODUCT_PRICES.change_address_yes.type
                    : PRODUCT_PRICES.change_address_no.type,
                },
                newCartFeatures
              );

              let newCartDetails = values?.cartDetails || [];
              newCartDetails = replaceInArrayOfObjects(
                {
                  finder: "type",
                  finderValue: values?.useCWaddress
                    ? PRODUCT_PRICES.change_address_no.type
                    : PRODUCT_PRICES.change_address_yes.type,
                },
                values?.useCWaddress
                  ? PRODUCT_PRICES.change_address_yes
                  : PRODUCT_PRICES.change_address_no,
                newCartDetails
              );

              setFieldValue("cartSummary.features", newCartFeatures);
              setFieldValue("cartDetails", newCartDetails);
              if (isLastStep && values?.useCWaddress) {
                onSubmit({
                  ...values,
                  cartSummary: { features: newCartFeatures },
                  cartDetails: newCartDetails,
                });
              }
              values?.useCWaddress
                ? onNext(FORM_NAMES.Cart)
                : onNext(FORM_NAMES.FormChangeCompanyAddress, true);
            }
          });
        }}
      >
        {values?.useCWaddress ? "NEXT: Payment" : "NEXT: Change Address"}
      </Button>
      {/* </Form>
        );
      }} */}
    </FormLayout>
  );
};
