import React, { useCallback, useEffect, useState } from "react";

import { Box, Loader } from "atoms";
import { useNavigate } from "react-router-dom";
import { Checkout } from "./components";
import useService from "contexts/Service";
import { EmptyCart, TabHeader } from "organisms";

export const Cart = () => {
  const navigate = useNavigate();

  const {
    state: { cart },
    actions: { getCart, removeItemFromCart, getTriggerIncoporationPayments },
  } = useService();

  const [loading, setLoading] = useState(false);

  const fetchCart = useCallback(async () => {
    setLoading(true);
    getCart().finally(() => {
      setLoading(false);
    });
  }, [getCart]);

  useEffect(() => {
    fetchCart();
  }, [fetchCart]);

  return (
    <Box w="100%" px="3.2rem">
      <TabHeader
        title="My Cart"
        breadcrum={{
          firstText: "Home",
          firstUrl: "/dashboard",
          secondText: "My Cart",
        }}
        onPrevious={() => navigate("/dashboard")}
      />

      <Loader loading={loading}>
        {cart?.cartDetails?.length ? (
          <Checkout
            cart={cart?.cartDetails}
            removeItemFromCart={removeItemFromCart}
            refreshList={fetchCart}
            total={cart?.eval}
            onPay={getTriggerIncoporationPayments}
          />
        ) : (
          <EmptyCart />
        )}
      </Loader>
    </Box>
  );
};
