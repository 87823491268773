import { createReducer } from "utils/context";
import { AUTH_ACTIONS } from "./actions";

export const initialState = {
  // global identifier for auth
  isLoggedIn: false,
  isGoogleSSO: false,

  // login
  loginLoading: false,
  loginError: null,

  // forgot password
  forgotPasswordLoading: false,
  forgotPasswordError: null,
  forgotPasswordSuccess: false,

  // forgot password otp
  otpLoading: false,
  otpError: null,
  otpSuccess: false,

  // resend
  resendLoading: false,
  resendError: null,
  resendSuccess: false,

  // reset password
  resetPasswordLoading: false,
  resetPasswordError: null,
  resetPasswordSuccess: false,

  // userData
  userData: null,

  // layout identifier
  isLayoutFT: false,
};

export const authReducer = createReducer({
  // --- login state management
  [AUTH_ACTIONS.login.loading]: (state) => ({
    ...state,
    loginLoading: true,
    loginError: null,
  }),
  [AUTH_ACTIONS.login.success]: (
    state,
    { payload: { isLoggedIn, ...payload } }
  ) => ({
    ...state,
    userData: payload,
    isLoggedIn: isLoggedIn,
    loginLoading: false,
    loginError: null,
  }),
  [AUTH_ACTIONS.login.failure]: (state, { payload }) => ({
    ...state,
    isLoggedIn: false,
    loginLoading: false,
    loginError: payload,
  }),

  // --- forgot password state management
  [AUTH_ACTIONS.forgotPassword.loading]: (state) => ({
    ...state,
    forgotPasswordLoading: true,
    forgotPasswordError: null,
    forgotPasswordSuccess: false,
  }),
  [AUTH_ACTIONS.forgotPassword.success]: (state, { payload }) => ({
    ...state,
    userData: payload,
    forgotPasswordLoading: false,
    forgotPasswordError: null,
    forgotPasswordSuccess: true,
  }),
  [AUTH_ACTIONS.forgotPassword.failure]: (state, { payload }) => ({
    ...state,
    forgotPasswordLoading: false,
    forgotPasswordError: payload,
    forgotPasswordSuccess: false,
  }),

  // --- otp module state management
  [AUTH_ACTIONS.otp.loading]: (state) => ({
    ...state,
    otpLoading: true,
    otpError: null,
    otpSuccess: false,
  }),
  [AUTH_ACTIONS.otp.success]: (state, { payload }) => ({
    ...state,
    otpLoading: false,
    otpError: null,
    otpSuccess: true,
  }),
  [AUTH_ACTIONS.otp.failure]: (state, { payload }) => ({
    ...state,
    otpLoading: false,
    otpError: payload,
    otpSuccess: false,
  }),

  // --- resend module state management
  [AUTH_ACTIONS.resend.loading]: (state) => ({
    ...state,
    resendLoading: true,
    resendError: null,
    resendSuccess: false,
  }),
  [AUTH_ACTIONS.resend.success]: (state, { payload }) => ({
    ...state,
    resendLoading: false,
    resendError: null,
    resendSuccess: true,
  }),
  [AUTH_ACTIONS.resend.failure]: (state, { payload }) => ({
    ...state,
    resendLoading: false,
    resendError: payload,
    resendSuccess: false,
  }),

  // --- reset password module state management
  [AUTH_ACTIONS.resetPassword.loading]: (state) => ({
    ...state,
    resetPasswordLoading: true,
    resetPasswordError: null,
    resetPasswordSuccess: false,
  }),
  [AUTH_ACTIONS.resetPassword.success]: (state, { payload }) => ({
    ...state,
    resetPasswordLoading: false,
    resetPasswordError: null,
    resetPasswordSuccess: true,
    userData: payload,
    isLoggedIn: true,
  }),
  [AUTH_ACTIONS.resetPassword.failure]: (state, { payload }) => ({
    ...state,
    resetPasswordLoading: false,
    resetPasswordError: payload,
    resetPasswordSuccess: false,
  }),

  // --- signup state management
  [AUTH_ACTIONS.signup]: (state, { payload }) => ({
    ...state,
    userData: payload,
  }),

  // --- logout state management
  [AUTH_ACTIONS.logout]: (state) => ({
    ...state,
    isLoggedIn: false,
    userData: null,
  }),

  // --- layout state management
  [AUTH_ACTIONS.switchLayout]: (state, { payload }) => ({
    ...state,
    isLayoutFT: !!payload,
  }),

  // --- update user state management
  [AUTH_ACTIONS.updateUser]: (state, { payload }) => ({
    ...state,
    userData: payload,
  }),

  [AUTH_ACTIONS.setLoggedIn]: (
    state,
    { payload: { isLoggedIn, isGoogleSSO } = {} }
  ) => ({
    ...state,
    isLoggedIn,
    isGoogleSSO,
  }),
});
