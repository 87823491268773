import React from "react";

import { Box, Flex, Text } from "atoms";

export const Tab = ({ tabs = [], activeTab, setActiveTab, ...props }) => {
  return (
    <Flex
      px="2.4rem"
      w="100%"
      borderBottom="0.5px solid"
      borderColor="neutral.200"
      pb="0.5rem"
      {...props}
    >
      {tabs?.map((item, index) => {
        return (
          <Flex
            key={index}
            {...(index && { ml: "3.4rem" })}
            aI="center"
            cursor="pointer"
            onClick={(e) => setActiveTab?.(index)}
          >
            {activeTab === index && (
              <Box
                w="110%"
                h="0.2rem"
                bg="green.900"
                borderRadius="0.4rem"
                position="absolute"
                bottom="-25%"
              />
            )}
            <Text
              variant="bodyLarge"
              color={activeTab === index ? "green.900" : "neutral.600"}
            >
              {item?.title}
            </Text>
            {item?.count > 0 && (
              <Box
                ml="0.8rem"
                bg={activeTab === index ? "green.900" : "neutral.100"}
                p="0.1rem 0.7rem"
                borderRadius="0.4rem"
                maxHeight="1.6rem"
              >
                <Text
                  variant="bodySmall"
                  fontWeight="600"
                  color={activeTab === index ? "neutral.50" : "neutral.400"}
                  lineHeight="unset"
                >
                  {item?.count}
                </Text>
              </Box>
            )}
          </Flex>
        );
      })}
    </Flex>
  );
};
