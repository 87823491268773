import React from "react";
import css from "@styled-system/css";
import styled from "styled-components";
import ReactSelect, { components } from "react-select";
import { Box, Text, Error, Warning, Flex } from "atoms";
import { theme } from "styles";
import MemoSelectArrowUpIcon from "assets/icons/SelectArrowUp";
import MemoPointerIcon from "assets/icons/Pointer";

export const SelectFieldBase = styled(Box)`
  position: relative;
  width: 100%;
  display: grid;
  align-items: center;
  gap: 10px;
  cursor: pointer;

  label {
    background-color: ${(props) => props.theme.colors.white};
    font-size: 14px;
    ${({ labelHide }) =>
      css({
        borderRadius: "8px",
        fontFamily: "smallRegular",
        span: {
          position: "absolute",
          top: "50%",
          left: "0px",
          transform: "translateY(-50%)",
          transition: "0.2s all",
        },

        ...(!labelHide && {
          input: {
            "::placeholder": {
              opacity: 0,
              visibility: "hidden",
            },
            "&:focus + span, &:not(:placeholder-shown) + span": {
              top: "0px",
              fontSize: "14px",
            },
          },
        }),
      })}
  }
`;

const { ValueContainer, Placeholder, Option } = components;

const CustomValueContainer = ({
  children,
  isFocused,
  selectProps,
  ...props
}) => (
  <ValueContainer isFocused={isFocused} {...selectProps} {...props}>
    <Placeholder isFocused={isFocused} {...selectProps} {...props}>
      {selectProps?.placeholder && !selectProps?.inputValue && (
        <Text color="neutral.500" fontSize="1.6rem">
          {selectProps?.placeholder}
        </Text>
      )}
    </Placeholder>
    {React.Children.map(children, (child) =>
      child && child?.type !== Placeholder ? child : null
    )}
  </ValueContainer>
);

const DropdownIndicator = ({ ...props }) => {
  return (
    <components.DropdownIndicator {...props}>
      <Box transform="rotate(180deg)" color="neutral.500">
        <MemoSelectArrowUpIcon />
      </Box>
    </components.DropdownIndicator>
  );
};

const IconOption = (props) => (
  <Option {...props}>
    <Flex aI="center" jC="space-between">
      {props.data.label}
      <Box mr="3.2rem">
        {/* <Box as="custom-select-icon">
          <MemoPointerIcon />
        </Box> */}
      </Box>
    </Flex>
  </Option>
);

export const SelectField = ({
  label,
  error,
  width,
  warning,
  isMulti,
  noMargin,
  labelHide,
  searchable,
  searchableblue,
  Icon,
  borderRadius,
  styles,
  ...props
}) => (
  <SelectFieldBase
    labelHide={labelHide}
    margin={noMargin ? "0 !important" : undefined}
  >
    <Text as="label" color="gray.700" fontSize={7}>
      <ReactSelect
        placeholder={label}
        components={{
          // ValueContainer: CustomValueContainer,
          DropdownIndicator,
          Option: IconOption,
        }}
        styles={{
          control: (provided, state) => ({
            ...provided,
            borderColor: error
              ? theme.colors.error[900]
              : theme.colors?.neutral[300],
            boxShadow: "none",
            flexWrap: "nowrap",
            cursor: "pointer",
            padding: "13px 16px",
            borderRadius: "8px",
            maxHeight: "50px",
            ...(Icon && {
              paddingLeft: "2rem",
            }),
            backgroundColor: state?.isDisabled ? "#fafafa" : "inherit",
            "&:hover": {
              borderColor: "#000000",
            },
            "&:focus, &:active, &:focus-within": {
              borderColor: "#006C11",
            },
          }),
          valueContainer: (provided, state) => ({
            ...provided,
            padding: "0px",
            width: width || provided?.width,
          }),
          placeholder: (provided, state) => ({
            ...provided,
            position: "absolute",
            top:
              state?.hasValue || state?.selectProps?.inputValue
                ? "-8px"
                : "50%",
            transition: "top 0.1s, font-size 0.1s",
            fontSize: 16,
            lineHeight: "4px",
            backgroundColor:
              (state?.hasValue || state?.selectProps?.inputValue) && "white",
            color: error ? theme.colors.error[900] : provided?.color,
            whiteSpace: "nowrap",
            display: state?.hasValue && labelHide ? "none" : provided?.display,
          }),
          loadingIndicator: (provided) => ({
            ...provided,
            position: "relative",
          }),
          dropdownIndicator: (provided, state) => ({
            ...provided,
            padding: 0,
          }),
          singleValue: (provided, state) => ({
            ...provided,
            color: state?.isDisabled ? "#616161" : "inherit",
          }),
          multiValue: (provided) => ({
            ...provided,
            backgroundColor: theme.colors.blue[400],
            alignItems: "center",
            height: "26px",
            paddingRight: "2px",
            marginTop: "1px",
          }),
          multiValueLabel: (provided) => ({
            ...provided,
            color: "white",
            paddingBottom: "1px",
          }),
          option: (provided, state) => ({
            ...provided,
            backgroundColor: "",
            // backgroundColor:
            //   state?.isSelected || state?.isFocused
            //     ? "rgba(0, 108, 17, 0.16)"
            //     : "",
            fontSize: "1.6rem",
            fontWeight: "400",
            borderRadius: "4px",
            color: theme.colors?.neutral[600],
            // color: state?.isSelected
            //   ? theme.colors?.success[900]
            //   : theme.colors?.neutral[600],
            "&:hover": {
              backgroundColor: `rgba(0, 108, 17, 0.16)`,
              color: theme.colors?.success[900],
            },
            "& custom-select-icon": {
              display: "none",
            },
            "&:hover custom-select-icon": {
              display: "block",
            },
          }),
          multiValueRemove: (base, state) =>
            state?.data.isFixed
              ? { ...base, display: "none" }
              : {
                  ...base,
                  color: "white",
                  backgroundColor: theme.colors.error[900],
                  borderRadius: "10px",
                  width: "20px",
                  height: "20px",
                },
          menu: (provided) => ({
            ...provided,
            ...styles?.menu,
            animation: "fadeIn 0.4s ease-in-out",
            borderRadius: "8px",
            boxShadow: "0px 10px 40px rgba(0, 0, 0, 0.08)",
            paddingLeft: "8px",
            paddingRight: "16px",
            zIndex: 7000,
          }),
          menuList: (provided) => ({
            ...provided,

            "::-webkit-scrollbar": {
              width: "3px",
              height: "0px",
            },
            "::-webkit-scrollbar-track": {
              background: "#fff",
            },
            "::-webkit-scrollbar-thumb": {
              background: "#D9D9D9",
              borderRadius: "7px",
            },
          }),
        }}
        closeMenuOnSelect={!isMulti}
        tabSelectsValue={false}
        isMulti={isMulti}
        {...props}
        onMenuScrollToBottom={props?.onScroll}
      />

      {Icon && (
        <Box position="absolute" top="12px" left="11px" color="blue.400">
          <Icon />
        </Box>
      )}
    </Text>
    {error && (
      <Box position="absolute" bottom="-24px">
        <Error text={error} />
      </Box>
    )}

    {warning && <Warning text={warning} />}
  </SelectFieldBase>
);
