import * as React from "react";

function Refresh(props) {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.55165 1.94641C5.00329 0.688536 6.86043 -0.00267189 8.78124 7.76212e-06C13.1996 7.76212e-06 16.7812 3.5816 16.7812 8C16.7812 9.7088 16.2452 11.2928 15.3332 12.592L12.7812 8H15.1812C15.1813 6.7453 14.8126 5.51825 14.121 4.47141C13.4293 3.42457 12.4452 2.60413 11.291 2.1121C10.1368 1.62008 8.86341 1.47817 7.62921 1.70402C6.395 1.92987 5.25441 2.51353 4.34925 3.3824L3.55165 1.94641ZM14.0108 14.0536C12.5592 15.3115 10.7021 16.0027 8.78124 16C4.36285 16 0.78125 12.4184 0.78125 8C0.78125 6.2912 1.31725 4.7072 2.22925 3.408L4.78125 8H2.38125C2.38115 9.2547 2.74985 10.4818 3.44152 11.5286C4.13318 12.5754 5.1173 13.3959 6.2715 13.8879C7.4257 14.3799 8.69907 14.5218 9.93328 14.296C11.1675 14.0701 12.3081 13.4865 13.2132 12.6176L14.0108 14.0536Z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoRefreshIcon = React.memo(Refresh);
export default MemoRefreshIcon;
