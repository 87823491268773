import React from "react";
import { useNavigate } from "react-router-dom";

import { Box, Flex, Line, Modal, Text } from "atoms";
import { Sheet } from "organisms";
import MemoEmptyCartIcon from "assets/icons/EmptyCartIcon";
import MemoWhatsappIcon from "assets/icons/Whatsapp";
import MemoCross from "styles/icons/Cross";

export const FAQ = ({ isOpen, onClose, ...props }) => {
  const navigate = useNavigate();

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      shouldCloseOnOverlayClick={true}
    >
      <Box
        minWidth="86.5rem"
        maxWidth="86.5rem"
        maxHeight="50rem"
        overflowX="hidden"
        overflowY="scroll"
        // position="absolute"
        bg="white"
        // right="56%"
        // top="174%"
        bR="1rem"
        boxShadow="m"
        zIndex="1000"
        p="2.4rem"
      >
        <Flex alignItems="center" justifyContent="space-between" mb="2rem">
          <Text
            variant="subtitleLarge"
            fontWeight="600"

            // textAlign="center"
          >
            FAQs
          </Text>
          <Box cursor="pointer" onClick={onClose}>
            <MemoCross width="1.6rem" height="1.6rem" />
          </Box>
        </Flex>
        <Text variant="subtitleLarge" fontWeight="600">
          What is required to Set-Up a company in Singapore?
        </Text>
        <Text
          variant="bodyLarge"
          color="neutral.700"
          justifySelf="center"
          mb="2rem"
          // textAlign="center"
        >
          For locals and PRs, you need to have a local registered address, a
          Corporate Secretary and a resident director including a minimum
          paid-up capital of SGD$1.
        </Text>
        <Text
          variant="bodyLarge"
          color="neutral.700"
          justifySelf="center"
          mb="2rem"
          // textAlign="center"
        >
          For foreigners, you can use the various services provided by
          Chartswood Associates.
        </Text>
        <Text
          variant="bodyLarge"
          color="neutral.700"
          justifySelf="center"
          mb="2rem"
          // textAlign="center"
        >
          Our registered address service allows you to use our address for
          registration. We will scan the letters and forward them to you upon
          request.
        </Text>
        <Text
          variant="bodyLarge"
          color="neutral.700"
          justifySelf="center"
          mb="2rem"
          // textAlign="center"
        >
          Your company can have a resident director by hiring our nominee
          director. The nominee director will not hold the actual authority to
          run the company.
        </Text>
        <Text
          variant="bodyLarge"
          color="neutral.700"
          justifySelf="center"
          mb="2rem"
          // textAlign="center"
        >
          Once you have an Employment Pass and a Letter of Consent from the
          Ministry of Manpower. Thereafter, you can become a resident director.
        </Text>
        <Text variant="subtitleLarge" fontWeight="600">
          What’s the Incorporation Process like?
        </Text>
        <Text
          variant="bodyLarge"
          color="neutral.700"
          justifySelf="center"

          // textAlign="center"
        >
          1. Check Preferred Name Availability
        </Text>
        <Text
          variant="bodyLarge"
          color="neutral.700"
          justifySelf="center"

          // textAlign="center"
        >
          2. IC / Passport Copy
        </Text>
        <Text
          variant="bodyLarge"
          color="neutral.700"
          justifySelf="center"

          // textAlign="center"
        >
          3. Provide Director(s) & Shareholder(s) details
        </Text>
        <Text
          variant="bodyLarge"
          color="neutral.700"
          justifySelf="center"

          // textAlign="center"
        >
          4. CWA will prepare the documents for your electronic signature
        </Text>
        <Text
          variant="bodyLarge"
          color="neutral.700"
          justifySelf="center"

          // textAlign="center"
        >
          5. Documents will be submitted to ACRA
        </Text>
        <Text
          variant="bodyLarge"
          color="neutral.700"
          justifySelf="center"
          mb="2rem"
          // textAlign="center"
        >
          6. Company is Set-Up once ACRA issues a UEN
        </Text>
        <Text variant="subtitleLarge" fontWeight="600">
          How long will it take to Incorporate a company?
        </Text>
        <Text
          variant="bodyLarge"
          color="neutral.700"
          justifySelf="center"
          mb="2rem"
          // textAlign="center"
        >
          Takes less than an hour to complete the process once the documents are
          ready.
        </Text>
        <Text variant="subtitleLarge" fontWeight="600">
          What’s a Corporate Secretary?
        </Text>
        <Text
          variant="bodyLarge"
          color="neutral.700"
          justifySelf="center"
          mb="2rem"
          // textAlign="center"
        >
          The Corporate Secretary ensures the company meets all regulatory
          obligations. As he/she needs to prepare documents for the company’s
          resolutions, books are kept and filed regularly. Only a certified
          person can do this.
        </Text>
        <Text variant="subtitleLarge" fontWeight="600">
          Do I need a company Director?
        </Text>
        <Text
          variant="bodyLarge"
          color="neutral.700"
          justifySelf="center"
          mb="2rem"
          // textAlign="center"
        >
          Every company must have at least one director who is a local resident
          in Singapore. 
        </Text>
        <Text variant="subtitleLarge" fontWeight="600">
          Have more questions?
        </Text>
        <Flex
          flexDirection="column"
          aI="center"
          w="fit-content"
          cursor="pointer"
          onClick={() => {
            window.open(
              `https://wa.me/+6587703896?${new URLSearchParams({}).toString()}`
            );
          }}
        >
          <Box height="4.5rem">
            <MemoWhatsappIcon height="4.5rem" />
          </Box>
          <Text color="neutral.600" variant="bodyMedium">
            Via Whatsapp
          </Text>
        </Flex>
      </Box>
    </Modal>
  );
};
