import React from "react";

import { Box, Error, Grid, Line, Table, Text } from "atoms";
import {
  FormDateInput,
  FormInput,
  FormPhoneInput,
  FormRadio,
  FormSelect,
} from "molecules";
import {
  getCountries,
  getCurrencies,
  getDocumentById,
  getIdentificationLabel,
} from "utils/utilities";
import { Upload } from "organisms";
import { postcodeValidatorExistsForCountry } from "postcode-validator";

const customIdGenerator = (index, id) => {
  return `shareholderDetails_${index}_${id}`;
};

// {`shareholderDetails[${index}].`}
export const Shareholder = ({
  values,
  index,
  setFieldValue,
  formUpload,
  errors,
  touched,
}) => {
  return (
    <>
      {/* Shareholder Name */}
      <Box mt="4rem">
        {/* title */}
        <Text variant="subtitleSmall" fontWeight="600" color="neutral.700">
          Shareholder Name
        </Text>
        <Line mt="1.6rem" />
        {/* inputs */}
        <Grid gTC="repeat(2,1fr)" gridGap="2.4rem" mt="2.4rem">
          <FormInput
            name={`shareholderDetails[${index}].firstName`}
            label="First Name*"
          />
          <FormInput
            name={`shareholderDetails[${index}].lastName`}
            label="Last Name*"
          />
        </Grid>
      </Box>

      {/* choose identification */}
      <Box mt="5rem">
        <Grid gTC="repeat(4,auto)" gCG="3.5rem" jC="start">
          {/* title */}
          <Text variant="subtitleSmall" fontWeight="600" color="neutral.700">
            Choose Identification
          </Text>
          <FormRadio
            name={`shareholderDetails[${index}].identificationType`}
            label="Passport"
            value="passport"
          />
          <FormRadio
            name={`shareholderDetails[${index}].identificationType`}
            label="NRIC"
            value="NRIC"
          />
          <FormRadio
            name={`shareholderDetails[${index}].identificationType`}
            label="EP Number"
            value="EP_Number"
          />
        </Grid>
        <Line mt="1.6rem" mb="2.4rem" />
        {/* input */}
        <FormInput
          name={`shareholderDetails[${index}].identification`}
          label={`Enter ${getIdentificationLabel(
            values?.shareholderDetails?.[index]?.identificationType
          )}`}
        />
      </Box>

      {/* Upload Choosen Document */}
      <Box mt="5rem">
        {/* title */}
        <Text variant="subtitleSmall" fontWeight="600" color="neutral.700">
          Upload Choosen Document
        </Text>
        <Line mt="1.6rem" mb="2.4rem" />
        {/* inputs */}
        <Table
          tableHeader={[{ name: "Document Name" }, { name: "Upload file" }]}
        >
          <tbody>
            <tr>
              <td>
                <Box>
                  <Text variant="bodyLarge" color="neutral.700">
                    Proof of{" "}
                    {getIdentificationLabel(
                      values?.shareholderDetails?.[index]?.identificationType
                    ) || "Details"}
                  </Text>
                  {/* <Text variant="bodyLarge" color="neutral.700">
                    (eg. Utilities Bills / Telecommunication Bills / Bank
                    Statements)
                  </Text> */}
                  {touched?.shareholderDetails?.[index]?.documents &&
                    errors?.shareholderDetails?.[index]?.documents &&
                    !getDocumentById(
                      "shareholderPOI",
                      values?.[`shareholderDetails`]?.[index]?.documents
                    )?.url && <Error text={"* required"} />}
                </Box>
              </td>
              <td>
                <Upload
                  url={
                    getDocumentById(
                      "shareholderPOI",
                      values?.[`shareholderDetails`]?.[index]?.documents
                    )?.url
                  }
                  name={
                    getDocumentById(
                      "shareholderPOI",
                      values?.[`shareholderDetails`]?.[index]?.documents
                    )?.name
                  }
                  time={
                    getDocumentById(
                      "shareholderPOI",
                      values?.[`shareholderDetails`]?.[index]?.documents
                    )?.time
                  }
                  onClose={() => {
                    const delDocs = JSON.parse(JSON.stringify(values))?.[
                      `shareholderDetails`
                    ]?.[index]?.documents?.filter(
                      (item) => item?.id !== "shareholderPOI"
                    );

                    setFieldValue(
                      `shareholderDetails[${index}].documents`,
                      delDocs
                    );
                  }}
                  loading={
                    values?.[
                      `${customIdGenerator(index, "shareholderPOI")} loading`
                    ]
                  }
                  onChange={(e) => {
                    formUpload(
                      e,
                      customIdGenerator(index, "shareholderPOI"),
                      setFieldValue,
                      (fieldData) => {
                        const addDocs =
                          JSON.parse(JSON.stringify(values))?.[
                            `shareholderDetails`
                          ]?.[index]?.documents || [];

                        addDocs?.push({
                          id: "shareholderPOI",
                          name: fieldData?.name,
                          time: fieldData?.uploadedAt,
                          url: fieldData?.url,
                          type: "shareholderPOI",
                        });

                        setFieldValue(
                          `shareholderDetails[${index}].documents`,
                          addDocs
                        );
                      }
                    );
                  }}
                />
              </td>
            </tr>
          </tbody>
        </Table>
      </Box>

      {/* designation, dob,email, mobile section */}
      <Grid gTC="repeat(2,1fr)" gridGap="2.4rem">
        {/* Designation */}
        <Box mt="5rem">
          <Text variant="subtitleSmall" fontWeight="600" color="neutral.700">
            Designation
          </Text>
          <Line mt="1.6rem" mb="2.4rem" />
          {/* input */}
          <FormInput
            name={`shareholderDetails[${index}].designation`}
            label="Enter Designation"
          />
        </Box>
        {/* Email */}
        <Box mt="5rem">
          <Text variant="subtitleSmall" fontWeight="600" color="neutral.700">
            Email
          </Text>
          <Line mt="1.6rem" mb="2.4rem" />
          <FormInput
            name={`shareholderDetails[${index}].email`}
            label="Enter Email"
            type="email"
          />
        </Box>
      </Grid>

      {/* Residential Address */}
      <Box mt="5rem">
        <Text variant="subtitleSmall" fontWeight="600" color="neutral.700">
          Residential Address
        </Text>
        <Line mt="1.6rem" mb="2.4rem" />
        {/* inputs */}
        <Grid gTC="repeat(2,1fr)" gridGap="2.4rem">
          <FormInput
            name={`shareholderDetails[${index}].address.addressLine1`}
            label="Address Line 1"
          />
          <FormInput
            name={`shareholderDetails[${index}].address.addressLine2`}
            label="Address Line 2"
          />
          <FormSelect
            name={`shareholderDetails[${index}].address.country`}
            label="Country"
            options={getCountries()}
            onChange={(country) => {
              setFieldValue(
                `shareholderDetails[${index}].address.countryCode`,
                country?.countryCode
              );
              setFieldValue(
                `shareholderDetails[${index}].address.postalCode`,
                ""
              );
              setFieldValue(`shareholderDetails[${index}].mobile`, "");
            }}
          />
          <FormInput
            name={`shareholderDetails[${index}].address.postalCode`}
            label="Postal Code"
            disabled={
              !postcodeValidatorExistsForCountry(
                values?.shareholderDetails?.[index]?.address?.countryCode
              )
            }
          />
        </Grid>
      </Box>

      <Grid gTC="repeat(2,1fr)" gridGap="2.4rem">
        {/* Date of Birth */}
        <Box mt="5rem">
          <Text variant="subtitleSmall" fontWeight="600" color="neutral.700">
            Date of Birth
          </Text>
          <Line mt="1.6rem" mb="2.4rem" />
          <FormDateInput
            name={`shareholderDetails[${index}].dob`}
            label="Enter Date of Birth"
            datePickerProps={{ maxDate: new Date() }}
          />
        </Box>
        {/* mobile */}
        <Box mt="5rem">
          <Text variant="subtitleSmall" fontWeight="600" color="neutral.700">
            Mobile
          </Text>
          <Line mt="1.6rem" mb="2.4rem" />
          <FormPhoneInput
            name={`shareholderDetails[${index}].phone`}
            {...(values?.shareholderDetails?.[index]?.address?.countryCode && {
              country:
                values?.shareholderDetails?.[
                  index
                ]?.address?.countryCode?.toLowerCase(),
            })}
          />
        </Box>
      </Grid>

      {/* local address proof */}
      <Box mt="5rem">
        {/* title */}
        <Text variant="subtitleSmall" fontWeight="600" color="neutral.700">
          Add Local Address Proof
        </Text>
        <Line mt="1.6rem" mb="2.4rem" />
        {/* inputs */}
        <Table
          tableHeader={[{ name: "Document Name" }, { name: "Upload file" }]}
        >
          <tbody>
            <tr>
              <td>
                <Box>
                  <Text variant="bodyLarge" color="neutral.700">
                    Local Address Proof
                  </Text>
                  <Text variant="bodyLarge" color="neutral.700">
                  (eg. Utilities Bills / Telecommunication Bills / Bank
                    Statements)
                  </Text>
                  {touched?.shareholderDetails?.[index]?.documents &&
                    errors?.shareholderDetails?.[index]?.documents &&
                    !getDocumentById(
                      "shareholderLocalAddressProof",
                      values?.[`shareholderDetails`]?.[index]?.documents
                    )?.url && <Error text={"* required"} />}
                </Box>
              </td>
              <td>
                <Upload
                  url={
                    getDocumentById(
                      "shareholderLocalAddressProof",
                      values?.[`shareholderDetails`]?.[index]?.documents
                    )?.url
                  }
                  name={
                    getDocumentById(
                      "shareholderLocalAddressProof",
                      values?.[`shareholderDetails`]?.[index]?.documents
                    )?.name
                  }
                  time={
                    getDocumentById(
                      "shareholderLocalAddressProof",
                      values?.[`shareholderDetails`]?.[index]?.documents
                    )?.time
                  }
                  onClose={() => {
                    const delDocs = JSON.parse(JSON.stringify(values))?.[
                      `shareholderDetails`
                    ]?.[index]?.documents?.filter(
                      (item) => item?.id !== "shareholderLocalAddressProof"
                    );

                    setFieldValue(
                      `shareholderDetails[${index}].documents`,
                      delDocs
                    );
                  }}
                  loading={
                    values?.[
                      `${customIdGenerator(
                        index,
                        "shareholderLocalAddressProof"
                      )} loading`
                    ]
                  }
                  onChange={(e) => {
                    formUpload(
                      e,
                      customIdGenerator(index, "shareholderLocalAddressProof"),
                      setFieldValue,
                      (fieldData) => {
                        const addDocs =
                          JSON.parse(JSON.stringify(values))?.[
                            `shareholderDetails`
                          ]?.[index]?.documents || [];

                        addDocs?.push({
                          id: "shareholderLocalAddressProof",
                          name: fieldData?.name,
                          time: fieldData?.uploadedAt,
                          url: fieldData?.url,
                          type: "shareholderLocalAddressProof",
                        });

                        setFieldValue(
                          `shareholderDetails[${index}].documents`,
                          addDocs
                        );
                      }
                    );
                  }}
                />
              </td>
            </tr>
          </tbody>
        </Table>
      </Box>

      <Grid
        gTC="1fr 1fr"
        gCG="2.4rem"
        gRG="2.4rem"
        mt="4.8rem"
        w="fit-content"
        aI="center"
      >
        {/* company shares */}
        <Text variant="subtitleSmall" fontWeight="600">
          Add Number of Company Shares:
        </Text>
        <Box w="20rem" ml="2.4rem">
          <FormInput
            name={`shareholderDetails[${index}].numberOfCompanyShares`}
            label="Company Shares*"
            type="number"
          />
        </Box>

        {/* company shares */}
        <Text variant="subtitleSmall" fontWeight="600">
          Add Value per Share:
        </Text>
        <Box width="20rem" ml="2.4rem">
          <FormInput
            name={`shareholderDetails[${index}].valuePerShare`}
            label="Value per Share*"
            type="number"
          />
        </Box>

        {/* Financial revenue */}
        {/* <Text variant="subtitleSmall" fontWeight="600">
          Choose First Financial Year:
        </Text>
        <Box w="20rem" ml="2.4rem">
          <FormDateInput
            name={`shareholderDetails[${index}].firstFinancialYearRevenue`}
            label="Year*"
            datePickerProps={{
              dateFormat: "MM/yyyy",
              showMonthYearPicker: true,
              showFullMonthYearPicker: true,
            }}
          />
        </Box> */}

        {/* Financial revenue */}
        <Text variant="subtitleSmall" fontWeight="600">
          Choose Currency:
        </Text>
        <Box w="20rem" ml="2.4rem">
          <FormSelect
            name={`shareholderDetails[${index}].currency`}
            label="Currency*"
            options={[...getCurrencies()]}
          />
        </Box>
      </Grid>
    </>
  );
};
