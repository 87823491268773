import React, { useEffect, useState } from "react";
import { Form } from "formik";
import { Box, Button, Line } from "atoms";
import FormLayout from "../FormLayout";
import FORM_NAMES from "templates/Incorporation/form.names";
import { PlanCard } from "organisms";
import useService from "contexts/Service";
import { formatCurrency } from "utils/utilities";
import { cwToast } from "molecules";

const INITIAL_VALUES = {
  directorType: "local",
};

const DESCRIPTORS = [
  { key: "incorporationApplication", displayName: "Incorporation Application" },
  { key: "purchaseOfCompanyName", displayName: "Purchase of company name" },
  { key: "acraBusinessProfile", displayName: "ACRA business profile" },
  { key: "companyConstitution", displayName: "Company constitution" },
  { key: "govFeesIncluded", displayName: "$315 government fees included" },
  {
    key: "annualFilingFee",
    displayName: "$60 Annual Filing government fee included",
  },
  {
    key: "nomineeDirectorFees",
    displayName: "Excludes: Nominee Director Fee $1,600 / annum",
  },
  { key: "bankAccountOpening", displayName: "Bank account opening" },
  {
    key: "corporateSecretaryfiling",
    displayName: "Corporate Secretary routine Annual Filing & AGM Preparation",
  },
];

const PLANS_CLASSIFICATION = {
  LOCAL: ["regular", "ics"],
  FOREIGN: ["cy"],
};

const getClassifiedPlans = (directorType, plans) => {
  const choosenPlan = {
    local: PLANS_CLASSIFICATION.LOCAL,
    foreign: PLANS_CLASSIFICATION.FOREIGN,
  }[directorType];

  const filteredPlans = [];

  choosenPlan?.forEach((item) => {
    filteredPlans?.push(
      plans?.find((plan) =>
        plan?.name?.toLowerCase()?.includes(item?.toLowerCase())
      )
    );
  });
  return filteredPlans;
};

export const FormDirectorPlan = ({
  breadcrum,
  step,
  onNext,
  onPrevious,
  onSubmit,
  formInitValues,
  ...props
}) => {
  const {
    actions: { getSubscriptionsByServiceType },
  } = useService();

  const [submitting, setSubmitting] = useState(false);

  const [formControlledTitle, setFormControlledTitle] = useState("");

  const [pricingPlans, setPricingPlans] = useState([]);

  useEffect(() => {
    getSubscriptionsByServiceType("incorporation").then((data) => {
      setPricingPlans(data);
    });
  }, [getSubscriptionsByServiceType]);

  return (
    <FormLayout
      breadcrum={breadcrum}
      step={step}
      onPrevious={onPrevious}
      title={formControlledTitle}
      subtitle="Get access to our top-notch incorporation and secretarial services below"
      formikProps={{
        initialValues: {
          ...INITIAL_VALUES,
          ...formInitValues,
        },
        enableReinitialize: true,
      }}
      {...props}
    >
      {({ values, setFieldValue, dirty, errors, setFieldError }) => {
        setFormControlledTitle(
          values?.directorType === "local"
            ? "Local Incorporation Packages"
            : "Incorporation Pricing Packages for Foreigners"
        );
        return (
          <>
            <Form>
              <Box mt="4rem">
                {getClassifiedPlans(values?.directorType, pricingPlans)?.map(
                  (plan, index) => (
                    <PlanCard
                      key={index}
                      id={plan?._id}
                      name={plan?.displayName}
                      price={formatCurrency(
                        plan?.prices?.[0]?.amount,
                        plan?.prices?.[0]?.currency
                      )}
                      planTenure={plan?.prices?.[0]?.interval}
                      descriptors={DESCRIPTORS?.filter(
                        (item) => plan?.[item?.key]
                      )?.map((item) => item?.displayName)}
                      isSelected={
                        values?.cartSummary?.subscription === plan?._id
                      }
                      onClick={(id) => {
                        setFieldValue("cartSummary.subscription", id);
                      }}
                      {...(index && { mt: "4rem" })}
                    />
                  )
                )}
                {/* {errors?.subscription && <Error text={errors?.subscription} />} */}
              </Box>
              <Line mt="4.8rem" />
              <Button
                w="fit-content"
                type="button"
                px="4rem"
                mt="4rem"
                loading={submitting}
                onClick={() => {
                  if (values?.cartSummary?.subscription) {
                    if (dirty) {
                      setSubmitting(true);
                      onSubmit(values)
                        .then(() => {
                          // values?.directorType === "local"
                          //   ? onNext(FORM_NAMES.FormCompanyDetails, true)
                          //   : onNext(FORM_NAMES.FormAddressProof, false);
                          onNext(FORM_NAMES.FormCompanyDetails, true);
                        })
                        .catch(console.error)
                        .finally(() => {
                          setSubmitting(false);
                        });
                    } else {
                      // values?.directorType === "local"
                      //   ? onNext(FORM_NAMES.FormCompanyDetails, true)
                      //   : onNext(FORM_NAMES.FormAddressProof, false);
                      onNext(FORM_NAMES.FormCompanyDetails, true);
                    }
                  } else {
                    setFieldError("subscription", "* required");
                    cwToast("error", "Choose a plan");
                  }
                }}
              >
                {/* {values?.directorType === "local"
                  ? "NEXT : Company Details"
                  : "NEXT : Local Address Proof"} */}
                NEXT : Company Details
              </Button>
            </Form>
          </>
        );
      }}
    </FormLayout>
  );
};
