import * as React from "react";

function BxNews(props) {
  return (
    <svg
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.8889 0H1.88889C0.847111 0 0 0.797333 0 1.77778V14.2222C0 15.2027 0.847111 16 1.88889 16H15.8889C16.9307 16 17.7778 15.2027 17.7778 14.2222V1.77778C17.7778 0.797333 16.9307 0 15.8889 0ZM15.8874 14.2218H1.8874C1.85463 14.2218 1.82856 14.2158 1.81013 14.2116C1.80007 14.2093 1.7923 14.2076 1.78696 14.2076C1.78074 14.2076 1.77718 14.2093 1.77629 14.2147L1.76562 1.81823C1.77185 1.80934 1.81185 1.77734 1.8874 1.77734H15.8874C15.9201 1.77776 15.9458 1.78317 15.9643 1.78706C15.9855 1.79152 15.9971 1.79396 15.9985 1.78445L16.0092 14.1809C16.003 14.1898 15.963 14.2218 15.8874 14.2218ZM3.55469 3.55859H8.88802V8.89193H3.55469V3.55859ZM9.77691 10.6719H3.55469V12.4497H9.77691H10.6658H14.2214V10.6719H10.6658H9.77691ZM14.2196 7.11328H10.6641V8.89106H14.2196V7.11328ZM10.6641 3.55859H14.2196V5.33637H10.6641V3.55859Z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoBxNewsIcon = React.memo(BxNews);
export default MemoBxNewsIcon;
