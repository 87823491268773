import React from "react";

import { Box, Flex, Grid, Text } from "atoms";
import { DefaultChips } from "molecules";

export const Step = ({
  serialNumber,
  title,
  description,
  status,
  ...props
}) => {
  return (
    <Grid
      px="2.4rem"
      pb="1.7rem"
      aI="center"
      gTC="auto 1fr auto"
      gCG="1.6rem"
      borderBottom="0.5px solid"
      borderColor="neutral.200"
      {...props}
    >
      {/* step count */}
      <Flex
        bg="green.50"
        minWidth="3.2rem"
        minHeight="3.2rem"
        borderRadius="50%"
        aI="center"
        jC="center"
      >
        <Text variant="bodyLarge" fontWeight="600" color="success.700">
          {serialNumber?.toString() || ""}
        </Text>
      </Flex>

      {/* description */}
      <Box>
        <Text variant="bodyLarge" fontWeight="600" color="neutral.700">
          {title || ""}
        </Text>
        <Text variant="bodyMedium" color="neutral.600">
          {description || ""}
        </Text>
      </Box>

      {/* <DefaultChips variant="completed" title="Completed" /> */}
      {
        {
          created: (
            <DefaultChips
              title="Created"
              variant="statement"
              ml="1.6rem"
              w="12rem"
            />
          ),
          "in-progress": (
            <DefaultChips
              title="In Progress"
              variant="progress"
              ml="1.6rem"
              w="12rem"
            />
          ),
          pending: (
            <DefaultChips
              title="Pending"
              variant="pending"
              ml="1.6rem"
              w="12rem"
            />
          ),
          overdue: (
            <DefaultChips
              title="Overdue"
              variant="delayed"
              ml="1.6rem"
              w="12rem"
            />
          ),
          completed: (
            <DefaultChips
              title="Completed"
              variant="completed"
              ml="1.6rem"
              w="12rem"
            />
          ),
        }[status]
      }
    </Grid>
  );
};
