import React from "react";

import { Box, Line, Text } from "atoms";

export const UserDetails = ({ serviceDetails }) => {
  return (
    <Box w="100%">
      {(serviceDetails?.details?.activityType === "both" ||
        serviceDetails?.details?.activityType === "primary") && (
        <>
          <Text variant="subtitleMedium" fontWeight="600">
            Primary Business Activity Code:{" "}
            {serviceDetails?.details?.primaryActivityCode || ""}
          </Text>

          <Line mt="1.6rem" />

          <Text
            variant="bodyLarge"
            fontWeight="600"
            color="neutral.700"
            mt="4rem"
          >
            Business details
          </Text>

          <Line mt="1.6rem" />

          <Box
            w="100%"
            bR=".8rem"
            bg="neutral.50"
            mt="1.6rem"
            py="2.4rem"
            pl="2.4rem"
          >
            <Text
              variant="bodyLarge"
              color="neutral.800"
              css={{ whiteSpace: "pre" }}
            >
              {serviceDetails?.details?.primaryBusinessDescription || ""}
            </Text>
          </Box>
        </>
      )}

      <Line mt="1.6rem" />

      {(serviceDetails?.details?.activityType === "both" ||
        serviceDetails?.details?.activityType === "secondary") && (
        <>
          <Text variant="subtitleMedium" fontWeight="600" mt="8rem">
            Secondary Business Activity Code:{" "}
            {serviceDetails?.details?.secondaryActivityCode || ""}
          </Text>

          <Line mt="1.6rem" />

          <Text
            variant="bodyLarge"
            fontWeight="600"
            color="neutral.700"
            mt="4rem"
          >
            Business details
          </Text>

          <Line mt="1.6rem" />

          <Box
            w="100%"
            bR=".8rem"
            bg="neutral.50"
            mt="1.6rem"
            py="2.4rem"
            pl="2.4rem"
          >
            <Text
              variant="bodyLarge"
              color="neutral.800"
              css={{ whiteSpace: "pre" }}
            >
              {serviceDetails?.details?.secondaryBusinessDescription || ""}
            </Text>
          </Box>
        </>
      )}
    </Box>
  );
};
