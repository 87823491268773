import React from "react";

import { Box, Flex, Grid, Line, Text } from "atoms";
import { format, parseISO } from "date-fns";
import MemoFileIcon from "assets/icons/File";

export const UserDetails = ({ serviceDetails }) => {
  const previousSecretaryResignationLetter =
    serviceDetails?.details?.documents?.find(
      (doc) => doc?.type === "previousSecretaryResignationLetter"
    );

  return (
    <Box w="100%">
      <Text
        variant="subtitleMedium"
        fontWeight="600"
        display="flex"
        aI="center"
      >
        Chartswood Corporate Secretarial Services:
        <Text
          variant="subtitleMedium"
          fontWeight="600"
          color={
            serviceDetails?.details?.usingCWCorporateSecretarialServices
              ? "success.700"
              : "secondary.900"
          }
          ml="1rem"
        >
          {serviceDetails?.details?.usingCWCorporateSecretarialServices
            ? "Yes"
            : "No"}
        </Text>
      </Text>

      <Line mt="1.6rem" />

      <Text variant="bodyLarge" fontWeight="600" color="neutral.700" mt="4rem">
        Previous Corporate Secretarial Details
      </Text>

      <Line mt="1.6rem" />

      <Grid gTC="repeat(2,max-content)" gCG="15rem" mt="2.4rem">
        <Text variant="bodyLarge" color="neutral.600">
          Name
        </Text>
        <Text variant="bodyLarge" color="neutral.600">
          Email
        </Text>
        <Text variant="subtitleSmall" fontWeight="600">
          {serviceDetails?.details?.previousCorporateSecretaryName}
        </Text>
        <Text variant="subtitleSmall" fontWeight="600">
          {serviceDetails?.details?.previousCorporateSecretaryEmail}
        </Text>
      </Grid>

      <Line mt="1.6rem" />

      {!serviceDetails?.details?.usingCWCorporateSecretarialServices && (
        <>
          <Text
            variant="bodyLarge"
            fontWeight="600"
            color="neutral.700"
            mt="2.4rem"
          >
            New Corporate Secretarial Details
          </Text>

          <Line mt="1.6rem" />

          <Grid gTC="repeat(2,max-content)" gCG="15rem" mt="2.4rem">
            <Text variant="bodyLarge" color="neutral.600">
              Name
            </Text>
            <Text variant="bodyLarge" color="neutral.600">
              Email
            </Text>
            <Text variant="subtitleSmall" fontWeight="600">
              {serviceDetails?.details?.newCorporateSecretaryName}
            </Text>
            <Text variant="subtitleSmall" fontWeight="600">
              {serviceDetails?.details?.newCorporateSecretaryEmail}
            </Text>
          </Grid>
        </>
      )}

      <Text variant="subtitleMedium" fontWeight="600" mt="8rem">
        Documents
      </Text>

      <Line mt="1.6rem" />

      <Grid gTC="auto 1fr" gCG="2.4rem" aI="center" mt="2.4rem">
        <Text variant="bodyLarge" color="neutral.700">
          Previous Secretary Resignation Letter
        </Text>

        <Flex
          cursor="pointer"
          onClick={() =>
            window.open(previousSecretaryResignationLetter?.url, "_blank")
          }
        >
          <Box mt="1.2rem" color="primary.900">
            <MemoFileIcon height="2.4rem" width="2.4rem" />
          </Box>
          <Box pl="1.2rem">
            <Text variant="bodyLarge" fontWeight="600" color="neutral.700">
              {previousSecretaryResignationLetter?.name}
            </Text>
            <Text variant="bodySmall" color="neutral.500">
              {previousSecretaryResignationLetter?.time &&
                format(
                  parseISO(previousSecretaryResignationLetter?.time),
                  "dd MMM, yyyy hh:mm a"
                )}
            </Text>
          </Box>
        </Flex>
      </Grid>
    </Box>
  );
};
