import React from "react";

import useAuth from "contexts/Auth";
import { AuthenticatedRoutes, UnauthenticatedRoutes } from "routes";

export const AuthGate = () => {
  const {
    state: { isLoggedIn, userData },
  } = useAuth();

  return isLoggedIn ? (
    <AuthenticatedRoutes role={userData?.role} />
  ) : (
    <UnauthenticatedRoutes />
  );
};

export default AuthGate;
