import { Country, State } from "country-state-city";
import currencyToSymbolMap from "currency-symbol-map/map";
import S3 from "react-aws-s3";

import { cwToast } from "molecules";
import { STATUS } from "./constants";
import api from "./api";
import { format } from "date-fns";
window.Buffer = window.Buffer || require("buffer").Buffer;



/**
 * @param {string} userName
 * @returns {string}
 */
export const getInitials = (userName, lastName) =>
  (lastName ? `${userName} ${lastName}` : userName)
    ?.split(" ")
    ?.slice(0, 2)
    ?.map((splitName) => splitName[0])
    ?.join("")
    ?.toUpperCase();

/**
 * @param {any} type key of the filter state
 * @param {any} id id of the item under key filter to be reversed
 *
 * @param {any} data specific for "type === date" : applies the given data to date type filter
 */
export const filterStateHandler = (currentFilterState, type, id, data) => {
  const newRefCurrentFilterState = JSON.parse(
    JSON.stringify(currentFilterState)
  );
  if (type === "date") {
    // gather data
    const date = {
      isApplied: true,
      ...data,
    };

    // return new state
    return { ...newRefCurrentFilterState, date };
  } else if (type === "assignee") {
    return { ...newRefCurrentFilterState, assignee: id };
  } else if (type === "serviceType") {
    return { ...newRefCurrentFilterState, serviceType: id };
  } else if (type === "statusId") {
    return { ...newRefCurrentFilterState, statusId: id };
  } else {
    // target item
    const filterItem = newRefCurrentFilterState?.[type]?.map((item) =>
      item?.id === id ? { ...item, isApplied: !item?.isApplied } : item
    );

    newRefCurrentFilterState[type] = filterItem;

    // return new state
    return newRefCurrentFilterState;
  }
};

export const verifyUploadFileImageExtension = (file) => {
  if (file) {
    const fileExtension = file.name.split(".").pop();
    const allowedExtensions = ["jpg", "jpeg", "png", "webp"];
    return allowedExtensions.includes(fileExtension);
  }
  return false;
};

export const verifyUploadFileDocumentExtension = (file) => {
  if (file) {
    const fileExtension = file.name.split(".").pop();
    const allowedExtensions = [
      "pdf",
      "doc",
      "docx",
      "jpg",
      "jpeg",
      "xlsx",
      "png",
      "webp",
      "zip",
    ];
    return allowedExtensions.includes(fileExtension);
  }
  return false;
};

/**
 * Upload file to S3 bucket
 * @param {File} file
 * @returns returns object with "location" key (url) of the uploaded file to S3
 */
export const uploadFile = (file) => {
  const formData = new FormData();

  formData.append("file", file);

  const config = {
    bucketName: process.env.REACT_APP_S3_BUCKET_CONTENT,
    region: process.env.REACT_APP_S3_REGION_CONTENT,
    accessKeyId: process.env.REACT_APP_AWS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  };

  const ReactS3Client = new S3(config);

  const newFileName = file.name || "test-file";

  return ReactS3Client.uploadFile(file, newFileName);
};

export const getCountries = () => {
  const countries = Country.getAllCountries();

  const formattedCountries = countries?.map((country) => ({
    label: `${country?.flag} ${country?.name}`,
    value: country?.name,
    countryCode: country?.isoCode,
    flag: country?.flag,
  }));

  return formattedCountries;
};

export const getStates = (countryCode) => {
  const states = State?.getStatesOfCountry(countryCode);

  const formattedStates = states?.map((state) => ({
    label: state?.name,
    value: state?.name,
  }));

  return formattedStates;
};

export const onChangeFormUploadFile = (e, itemId, setFieldValue, cb) => {
  const file = e.target.files[0];
  // lastModified: 1666099648083
  // lastModifiedDate: Tue Oct 18 2022 18:57:28 GMT+0530 (India Standard Time) {}
  // name: "dummypassport.pdf"
  // size: 188471
  // type: "application/pdf"
  // webkitRelativePath: ""

  if (verifyUploadFileDocumentExtension(file)) {
    setFieldValue(`${itemId} loading`, true);
    uploadFile(file)
      .then(({ location }) => {
        const fieldData = {
          name: file?.name,
          lastModifiedDate: file?.lastModifiedDate,
          type: file?.type,
          uploadedAt: new Date().toISOString(),
          url: location,
        };
        // file uploaded
        // setFieldValue(`docs.${itemId}`, fieldData);
        cb && cb(fieldData);
        // file data to cookies
        // onSubmit({ docs: { [`${item?.id}`]: fieldData } });
        cwToast("success", "Document Uploaded");
      })
      .catch((e) => {
        cwToast("error", "Failed to Upload");
        console.error(e);
      })
      .finally(() => {
        setFieldValue(`${itemId} loading`, false);
      });
  } else {
    cwToast(
      "error",
      "Invalid extension found. Valid extensions is .pdf, .doc/.docx, .png, .jpg/.jpeg, .webp .xlsx"
    );
  }
};

export const getDocumentById = (id, documents) => {
  return documents?.find((item) => item?.id === id);
};

export const formatCurrency = (value, currency) => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency || "USD",
    minimumFractionDigits: 0,
  });

  return formatter.format(Number(value || 0));
};

export const formatDuration = (duration, isCaps) => {
  const DURATIONS = {
    oneTime: "/one-time",
    annual: "/annually",
    annually: "/annually",
    month: "/monthly",
    monthly: "/monthly",
    year: "/annually",
    yearly: "/annually",
    quarter: "/quarterly",
    quarterly: "/quarterly",
  };
  const DURATIONS_CAPS = {
    oneTime: "One-time",
    annual: "Annual",
    annually: "Annual",
    month: "Monthly",
    monthly: "Monthly",
    year: "Annual",
    yearly: "Annual",
    quarter: "Quarterly",
    quarterly: "Quarterly",
  };
  return (isCaps ? DURATIONS_CAPS : DURATIONS)[duration];
};

export const replaceInArrayOfObjects = (
  { finder, finderValue },
  newObj,
  operatingArray
) => {
  const newOperatingArray = JSON.parse(JSON.stringify(operatingArray));

  const findIndex = newOperatingArray?.findIndex(
    (item) => item?.[finder] === finderValue
  );

  if (findIndex > -1) {
    newOperatingArray?.splice(findIndex, 1, newObj);
  } else {
    newOperatingArray?.push(newObj);
  }

  return newOperatingArray;
};

export const cartFeatureHandler = (
  features,
  featureName,
  featureKey,
  isFeaturePaid
) => {
  if (isFeaturePaid) {
    const newFeatureObject = {
      name: featureName,
      key: featureKey,
    };
    const newFeatureArray = replaceInArrayOfObjects(
      {
        finder: "key",
        finderValue: featureKey,
      },
      newFeatureObject,
      features || []
    );
    return newFeatureArray;
  } else {
    let newFeatureArray = features ? JSON.parse(JSON.stringify(features)) : [];

    const findIndex = newFeatureArray?.findIndex(
      (item) => item?.key === featureKey
    );
    if (findIndex > -1) {
      newFeatureArray = [
        ...newFeatureArray?.slice(0, findIndex),
        ...newFeatureArray?.slice(findIndex + 1),
      ];
      return newFeatureArray;
    }
  }

  return features;
};

export const cartDetailHandler = (
  cartDetail,
  cartObject,
  featureType,
  isFeaturePaid
) => {
  if (isFeaturePaid) {
    const newFeatureArray = replaceInArrayOfObjects(
      {
        finder: "type",
        finderValue: featureType,
      },
      cartObject,
      cartDetail || []
    );
    return newFeatureArray;
  } else {
    let newFeatureArray = cartDetail
      ? JSON.parse(JSON.stringify(cartDetail))
      : [];

    const findIndex = newFeatureArray?.findIndex(
      (item) => item?.type === featureType
    );
    if (findIndex > -1) {
      newFeatureArray = [
        ...newFeatureArray?.slice(0, findIndex),
        ...newFeatureArray?.slice(findIndex + 1),
      ];
      return newFeatureArray;
    }
  }

  return cartDetail;
};

export const evaluateCartTotal = (cart) => {
  let amount = 0;

  cart?.forEach((sub) => {
    sub?.cartDetails?.forEach((item) => {
      amount += item?.price;
    });
  });

  return amount;
};

export const manipIncorporationCartItem = (sub) => {
  const newIncorporationCartItem = JSON.parse(JSON.stringify(sub));

  newIncorporationCartItem.name = "Incorporation";

  let totalPrice = newIncorporationCartItem?.cartDetails?.reduce(
    (pre, cur) => pre + cur?.price,
    0
  );

  if (newIncorporationCartItem?.subscriptionDetails) {
    totalPrice +=
      newIncorporationCartItem?.subscriptionDetails?.prices?.[0]?.amount;
  }

  newIncorporationCartItem.price = totalPrice;

  return newIncorporationCartItem;
};

export const getStatusChip = (status) => {
  return {
    [STATUS.created]: { title: "Created", variant: "statement" },
    [STATUS.inProgress]: { title: "In Progress", variant: "progress" },
    [STATUS.pending]: { title: "Pending", variant: "pending" },
    [STATUS.overdue]: { title: "Overdue", variant: "delayed" },
    [STATUS.completed]: { title: "Completed", variant: "completed" },
  }[status];
};

export const getFormattedAddress = (address) => {
  let formattedAddress = "";

  formattedAddress += address?.addressLine1 ? address?.addressLine1 + "," : "";
  formattedAddress += address?.addressLine2 ? address?.addressLine2 + "," : "";
  formattedAddress += address?.postalCode ? address?.postalCode + "," : "";
  formattedAddress += address?.country ? address?.country + "," : "";

  return formattedAddress;
};

export const getComanyActivityCodes = async (params) => {
  try {
    const { data } = await api(`/codes`, {
      method: "get",
      params,
    });

    const codeMap = data?.data?.map((activity) => ({
      label: `${activity?.Code} - ${activity?.Name}`,
      value: `${activity?.Code} - ${activity?.Name}`,
    }));

    return codeMap;
  } catch (e) {
    console.error(e);
    return [];
  }
};

export const getCurrencies = () => {
  const allCurrencyKeys = Object.keys(currencyToSymbolMap || {});

  const allCurrencyOption = allCurrencyKeys?.map((currency) => ({
    label: `${currency} (${currencyToSymbolMap?.[currency]})`,
    value: `${currency} (${currencyToSymbolMap?.[currency]})`,
  }));

  return allCurrencyOption || [];
};

export const getIdentificationLabel = (identificationType) => {
  let label = "";
  switch (identificationType) {
    case "passport": {
      label += "Passport No.";
      break;
    }
    case "NRIC": {
      label += "NRIC";
      break;
    }
    case "EP_Number": {
      label += "EP Number";
      break;
    }
    default: {
      label += "Details";
      break;
    }
  }

  return label;
};

export const getYears = () => {
  const startingYear = 1900;
  const endingYear = (parseInt(new Date().getFullYear() / 100) + 10) * 100;

  return [...new Array(endingYear - startingYear)].map((_, index) => ({
    label: startingYear + index,
    value: startingYear + index,
  }));
};
export const getMonths = () => {
  const startingMonth = 0;
  const endingMonth = 12;

  return [...new Array(endingMonth - startingMonth)].map((_, index) => ({
    label: format(new Date().setMonth(startingMonth + index), "MMMM"),
    value: startingMonth + index,
  }));
};
