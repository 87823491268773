import React, { useCallback, useEffect, useState } from "react";

import { Box, Button, Flex, Text } from "atoms";
import { Sheet, StatusChips } from "organisms";
import { Form, Formik } from "formik";
import { FormDateInput, FormSelect, ProfileAvatar } from "molecules";
import { filterStateHandler } from "utils/utilities";
import { OWNER_ROLE_CODE, USER_ROLE_CODE } from "utils/constants";

export const FilterModal = ({
  isOpen,
  position,
  initFilterState,
  onClose,
  onClearAll,
  onApplyFilter,
  role,
}) => {
  const [filterState, setFilterState] = useState({});

  const handleFilter = useCallback(
    /**
     * @param {any} type key of the filter state
     * @param {any} id id of the item under key filter to be reversed
     *
     * @param {any} data specific for "type === date" : applies the given data to date type filter
     */
    (type, id, data) => {
      setFilterState(filterStateHandler(filterState, type, id, data));
    },
    [filterState]
  );

  // apply filter button action
  const applyFilterHandler = useCallback(() => {
    onApplyFilter(filterState);
  }, [onApplyFilter, filterState]);

  // clear all button action
  // const clearAllHandler = useCallback(() => {
  //   onClearAll();
  // }, [onClearAll]);

  useEffect(() => {
    setFilterState(initFilterState);
  }, [initFilterState]);

  return (
    <Sheet isOpen={isOpen} onClose={onClose} bg="rgba(0,0,0,0.1)">
      <Box
        p="2.4rem"
        minWidth="51.7rem"
        maxWidth="51.7rem"
        boxShadow="m"
        zIndex="90"
        position="fixed"
        bg="white"
        borderRadius="0.8rem"
        // top="110%"
        // right="0%"
        top={`${position?.bottom}px`}
        right={`calc(100vw - ${position?.right}px)`}
      >
        {/* title */}
        <Text variant="subtitleMedium" fontWeight="600" color="neutral.800">
          Add Filter
        </Text>

        {/* Start Date */}
        <Formik
          initialValues={{
            startDate: filterState?.date?.startDate || "",
            // endDate: filterState?.date?.endDate || "",
            assignee: filterState?.assignee || "",
            statusId: filterState?.statusId || "",
          }}
          enableReinitialize
        >
          {({ values, setFieldValue }) => (
            <Form>
              {/* status */}
              <Flex aI="center" mt="2.4rem" flexWrap="wrap" gRG="2rem">
                <Text variant="bodyLarge" color="neutral.600">
                  Status
                </Text>

                {filterState?.status?.map((item, index) => {
                  return (
                    <StatusChips
                      key={index}
                      title={item?.description}
                      isActive={values?.statusId === item?.id}
                      ml="2rem"
                      onClick={() => {
                        setFieldValue("statusId", item?.id);
                        handleFilter("statusId", item?.id);
                      }}
                    />
                  );
                })}
              </Flex>

              <Flex aI="center" mt="2.4rem">
                <Text variant="bodyLarge" color="neutral.600">
                  Start Date
                </Text>
                <FormDateInput
                  name="startDate"
                  label="Start Date"
                  containerProps={{ ml: "2rem" }}
                  onChange={(value) => {
                    handleFilter("date", "", {
                      ...values,
                      startDate: value?.toISOString(),
                    });
                  }}
                />
                {/* <Text variant="bodyLarge" color="neutral.600" ml="3.7rem">
                  End Date
                </Text>
                <FormDateInput
                  name="endDate"
                  label="End Date"
                  containerProps={{ ml: "2rem" }}
                  onChange={(value) => {
                    handleFilter("date", "", { ...values, endDate: value });
                  }}
                /> */}
              </Flex>

              {/* Assignee */}
              <Flex aI="center" mt="2.4rem" gRG="2rem">
                <Text variant="bodyLarge" color="neutral.600" mr="2.4rem">
                  {
                    { [USER_ROLE_CODE]: "Assignee", [OWNER_ROLE_CODE]: "User" }[
                      role
                    ]
                  }
                </Text>

                <FormSelect
                  name="assignee"
                  label={`Select ${
                    { [USER_ROLE_CODE]: "Assignee", [OWNER_ROLE_CODE]: "User" }[
                      role
                    ]
                  }`}
                  onChange={(value) => {
                    handleFilter("assignee", value?.value);
                  }}
                  options={filterState?.assigneeOptions?.map((item) => {
                    return {
                      label: (
                        <Flex key={item?.id} aI="center">
                          {item?.email && (
                            <ProfileAvatar
                              name={item?.email}
                              // avatar={avatar}
                              size="xs"
                              mr="0.8rem"
                              bg="secondary.50"
                              border="none"
                              textProps={{ color: "secondary.900" }}
                            />
                          )}
                          <Text
                            variant="bodyLarge"
                            // color={isActiveState ? "neutral.50" : "neutral.500"}
                          >
                            {item?.email}
                          </Text>
                        </Flex>
                      ),
                      value: item?.id,
                    };
                  })}
                />
              </Flex>
            </Form>
          )}
        </Formik>

        {/* Buttons */}
        <Flex aI="center" mt="4.2rem">
          {/* clear */}
          <Button variant="outline" w="22.6%" onClick={onClearAll}>
            Clear All
          </Button>
          {/* apply filter */}
          <Button ml="0.8rem" onClick={applyFilterHandler}>
            Apply filter
          </Button>
        </Flex>
      </Box>
    </Sheet>
  );
};
