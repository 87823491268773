import { useLocationQueryValue } from "./useLocationQuery";

export const useSort = () => {
  const sort = useLocationQueryValue("sort");
  const sortKey = Array.isArray(sort) ? sort[0] : sort;

  return sortKey
    ? sortKey?.[0] === "-"
      ? `${sortKey.slice(1)}:desc`
      : `${sortKey}:asc`
    : undefined;
};
