import React from "react";
import { Grid } from "atoms";
import PricingCard from "../PricingCard";

const monthlybulletData = [
  {
    id: "0",
    title: "Provide monthly reports",
  },
  {
    id: "1",
    title: "Preparation of unaudited financial statements",
  },
  {
    id: "2",
    title: "Filing with ACRA",
  },
  {
    id: "3",
    title: "Provide quarterly reports",
  },
  {
    id: "4",
    title: "Advise the business accordingly",
  },
  {
    id: "5",
    title: "Filing of ECI and Corporate Taxes",
  },
  {
    id: "6",
    title: "Annual Filing with AGM Preparation",
  },
  {
    id: "7",
    title: "Prep of registration forms",
  },
  {
    id: "8",
    title: "Company constitution",
  },
  {
    id: "9",
    title: "Share certificates and shareholder agreements",
  },
  {
    id: "10",
    title: "Registrars",
  },
  {
    id: "11",
    title: "Director resolution & minutes",
  },
];

const quarterlyData = [
  {
    id: "0",
    title: "Provide monthly reports",
  },
  {
    id: "1",
    title: "Preparation of unaudited financial statements",
  },
  {
    id: "2",
    title: "Filing with ACRA",
  },
  {
    id: "3",
    title: "Provide quarterly reports",
  },
  {
    id: "4",
    title: "Advise the business accordingly",
  },
  {
    id: "5",
    title: "Filing of ECI and Corporate Taxes",
  },
];

const yearlyData = [
  {
    id: "0",
    title: "Preparation of unaudited financial statements",
  },
  {
    id: "1",
    title: "Filing with ACRA",
  },
  {
    id: "2",
    title: "Provide quarterly reports",
  },
  {
    id: "3",
    title: "Advise the business accordingly",
  },
  {
    id: "4",
    title: "Filing of ECI and Corporate Taxes",
  },
];
export const AccountingService = () => {
  return (
    <>
      <Grid gridAutoFlow="column">
        <PricingCard
          title="Monthly"
          subtitle="Perfect plan for Singaporean & PR"
          price="$350"
          duration="Started from"
          bulletData={monthlybulletData}
        />
        <PricingCard
          title="Quarterly"
          subtitle="Singaporean & PR"
          price="$500"
          duration="Started from"
          bulletData={quarterlyData}
        />
        <PricingCard
          title="Yearly"
          subtitle="Foreigner"
          price="$1599"
          duration="Started from"
          bulletData={yearlyData}
        />
      </Grid>
    </>
  );
};
