import React from "react";
import { useNavigate } from "react-router-dom";

import { Box, Button, Flex, Image, Text } from "atoms";
import PaymentFailure from "assets/images/PaymentFailure.gif";

export const PaymentFailed = () => {
  const navigate = useNavigate();
  return (
    <>
      <Box
        w="112rem"
        h="43.6rem"
        bg="white"
        jC="center"
        p="5.51rem 23.9rem 6.78rem 24rem"
        bR="0.8rem"
      >
        <Box>
          <Box ml="25rem" h="11rem" w="13.8rem">
            <Image src={PaymentFailure} alt="paymentfail" />
          </Box>
          <Box mt="3.2rem" textAlign="center">
            <Text variant="headingLarge" fontWeight="700" color="error.900">
              Payment Failed
            </Text>
            <Box mt="0.8rem">
              <Text variant="bodyLarge" color="neutral.700">
                Don't worry your money is safe!
                <br />
                <Text as="span">
                  If money was debited from your account, it will be refunded
                  automatically in 5-7 working days
                </Text>
              </Text>
            </Box>
          </Box>

          <Flex mt="4.8rem" ml="19.4rem">
            {/* <Button width="11.9rem" variant="outline">
              <Text variant="bodyLarge" fontWeight="700">
                Retry
              </Text>
            </Button>
            <Button width="11.9rem" variant="primary" ml="1.6rem">
              <Text variant="bodyLarge" fontWeight="700">
                Done
              </Text>
            </Button> */}
            <Button width="21.5rem" onClick={() => navigate("/dashboard")}>
              <Text variant="bodyLarge" fontWeight="700" color="white">
                Go to Dashboard
              </Text>
            </Button>
          </Flex>
        </Box>
      </Box>
    </>
  );
};
