import React from "react";

import { Box, Flex, Grid, Line, Text } from "atoms";
import { Accordian } from "organisms";
import { Director, DirectorRemove } from "./components";
// import MemoPdficon from "assets/icons/Pdficon";
import { format, parseISO } from "date-fns";
import MemoFileIcon from "assets/icons/File";

const getDirectorTitle = (firstName, lastName) => {
  if (firstName || lastName) {
    let title = firstName || "";
    if (lastName) {
      title += `${firstName ? " " : ""}${lastName || ""}`;
    }
    return title;
  }
  return "";
};

export const UserDetails = ({ serviceDetails }) => {
  const directorResignationLetter = serviceDetails?.details?.documents?.find(
    (doc) => doc?.type === "directorResignationLetter"
  );

  const directorResolutionOrAppointment =
    serviceDetails?.details?.documents?.find(
      (doc) => doc?.type === "directorResolutionOrAppointment"
    );

  const form45 = serviceDetails?.details?.documents?.find(
    (doc) => doc?.type === "form45"
  );
  return (
    <Box w="100%">
      <Text variant="subtitleMedium" fontWeight="600">
        Director Details
      </Text>

      <Line mt="1.6rem" />

      <Grid gTC="auto 1fr" gCG="2.4rem" gRG="2.4rem" aI="center" mt="2.4rem">
        {serviceDetails?.details?.actionType === "remove" && (
          <>
            {/* Director resignation letter */}
            <Text variant="bodyLarge" color="neutral.700">
              Director resignation letter
            </Text>

            <Flex
              cursor="pointer"
              onClick={() =>
                window.open(directorResignationLetter?.url, "_blank")
              }
            >
              <Box mt="1.2rem" color="primary.900">
                <MemoFileIcon height="2.4rem" width="2.4rem" />
              </Box>
              <Box pl="1.2rem">
                <Text variant="bodyLarge" fontWeight="600" color="neutral.700">
                  {directorResignationLetter?.name}
                </Text>
                <Text variant="bodySmall" color="neutral.500">
                  {directorResignationLetter?.time &&
                    format(
                      parseISO(directorResignationLetter?.time),
                      "dd MMM, yyyy hh:mm a"
                    )}
                </Text>
              </Box>
            </Flex>
          </>
        )}

        {/* Director resolution for Resignation/ Appointment of Director */}

        <Text variant="bodyLarge" color="neutral.700">
          Director resolution for Resignation/ Appointment of Director
        </Text>

        <Flex
          cursor="pointer"
          onClick={() =>
            window.open(directorResolutionOrAppointment?.url, "_blank")
          }
        >
          <Box mt="1.2rem" color="primary.900">
            <MemoFileIcon height="2.4rem" width="2.4rem" />
          </Box>
          <Box pl="1.2rem">
            <Text variant="bodyLarge" fontWeight="600" color="neutral.700">
              {directorResolutionOrAppointment?.name}
            </Text>
            <Text variant="bodySmall" color="neutral.500">
              {directorResolutionOrAppointment?.time &&
                format(
                  parseISO(directorResolutionOrAppointment?.time),
                  "dd MMM, yyyy hh:mm a"
                )}
            </Text>
          </Box>
        </Flex>

        {/* Form 45 for New Director signed by Directors */}

        <Text variant="bodyLarge" color="neutral.700">
          Form 45 for New Director signed by Directors
        </Text>

        <Flex
          cursor="pointer"
          onClick={() => window.open(form45?.url, "_blank")}
        >
          <Box mt="1.2rem" color="primary.900">
            <MemoFileIcon height="2.4rem" width="2.4rem" />
          </Box>
          <Box pl="1.2rem">
            <Text variant="bodyLarge" fontWeight="600" color="neutral.700">
              {form45?.name}
            </Text>
            <Text variant="bodySmall" color="neutral.500">
              {form45?.time &&
                format(parseISO(form45?.time), "dd MMM, yyyy hh:mm a")}
            </Text>
          </Box>
        </Flex>
      </Grid>

      <Line mt="1.6rem" />

      {serviceDetails?.details?.actionType === "add" && (
        <Accordian
          accordians={serviceDetails?.details?.directorDetails?.map(
            (director) => ({
              title: getDirectorTitle(director?.firstName, director?.lastName),
              children: <Director director={director} />,
            })
          )}
          mt="4rem"
          gap="2.4rem"
          isRemovable={false}
          isAutoOpen={false}
        />
      )}

      {serviceDetails?.details?.actionType === "remove" && (
        <Accordian
          accordians={serviceDetails?.details?.removeMembers?.map(
            (director) => ({
              title: director?.name,
              children: <DirectorRemove director={director} />,
            })
          )}
          mt="4rem"
          gap="2.4rem"
          isRemovable={false}
          isAutoOpen={false}
        />
      )}
    </Box>
  );
};
