import * as React from "react";

function Pending(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.0008 0V2C15.3908 2.54 18.5008 6.53 17.9608 10.92C17.74 12.689 16.9358 14.3339 15.6752 15.5944C14.4146 16.855 12.7698 17.6593 11.0008 17.88V19.88C16.5008 19.28 20.4508 14.34 19.8508 8.85C19.3308 4.19 15.6608 0.5 11.0008 0ZM9.00078 0C7.04078 0.18 5.19078 0.95 3.67078 2.2L5.10078 3.74C6.22078 2.84 7.57078 2.26 9.00078 2.06V0.0599999M2.26078 3.67C1.00646 5.18495 0.236481 7.04196 0.0507812 9H2.05078C2.24078 7.58 2.80078 6.23 3.69078 5.1L2.26078 3.67ZM0.0607812 11C0.260781 12.96 1.03078 14.81 2.27078 16.33L3.69078 14.9C2.80771 13.7696 2.24474 12.4226 2.06078 11H0.0607812ZM5.06078 16.37L3.67078 17.74C5.18556 19.0027 7.04005 19.789 9.00078 20V18C7.57818 17.816 6.23119 17.2531 5.10078 16.37H5.06078ZM11.0008 11V5H9.00078V11H11.0008ZM11.0008 15V13H9.00078V15H11.0008Z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoPendingIcon = React.memo(Pending);
export default MemoPendingIcon;
