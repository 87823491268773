import React from "react";
import { Box, Button, Flex, Grid, Text } from "atoms";
import MemoQuestionMarkWithCircle from "assets/icons/QuestionMarkWithCircle";

export const DocumentVerifiedCard = ({
  title,
  subtitle,
  pending: { onPending, onPendingLoading } = {},
  completed: { onCompleted, onCompletedLoading, onCompletedText } = {},
}) => {
  return (
    <>
      <Box bg="primary.50" bR="0.8rem">
        <Flex flexDirection="row" aI="center" jC="space-between" px="2.4rem">
          <Grid gridAutoFlow="column" py="1.6rem" gridColumnGap="1.6rem">
            <Box color="primary.500">
              <MemoQuestionMarkWithCircle width="4.5rem" height="4.5rem" />
            </Box>
            <Grid gridAutoFlow="row">
              <Text
                variant="subtitleMedium"
                color="neutral.700"
                fontWeight="600"
              >
                {title}
              </Text>
              <Text variant="bodySmall" color="neutral.500" mt=".5rem">
                {subtitle}
              </Text>
            </Grid>
          </Grid>
          <Flex aI="center">
            {onPending && (
              <Text
                variant="bodyLarge"
                color="error.500"
                cursor="pointer"
                onClick={onPending}
              >
                {onPendingLoading && "..."}Pending
              </Text>
            )}
            {/* <Button variant="error" w="fit-content" px="2.4rem" h="3.2rem">
              Pending
            </Button> */}
            {onCompleted && (
              <Button
                variant="success"
                w="fit-content"
                px="2.4rem"
                h="3.2rem"
                ml="2.4rem"
                onClick={onCompleted}
                loading={onCompletedLoading}
              >
                {onCompletedText || "Completed"}
              </Button>
            )}
          </Flex>
        </Flex>
      </Box>
    </>
  );
};
