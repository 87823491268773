import React from "react";

import { Flex, Text } from "atoms";
import MemoCross from "styles/icons/Cross";

export const FilterChips = ({ title, onClickCross, ...props }) => {
  return (
    <Flex
      p="0.3rem 0.8rem"
      borderRadius="0.4rem"
      aI="center"
      bg="neutral.300"
      {...props}
    >
      <Text variant="bodyLarge" color="neutral.700">
        {title}
      </Text>

      <Flex
        ml="1.6rem"
        bg="primary.900"
        color="neutral.300"
        p="0.5rem"
        borderRadius="100%"
        w="1.8rem"
        h="1.8rem"
        aI="center"
        jC="center"
        cursor="pointer"
        onClick={onClickCross}
      >
        <MemoCross height="0.8rem" />
      </Flex>
    </Flex>
  );
};
