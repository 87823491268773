import React from "react";
import { Text, Table, Line, Button, Box, Grid } from "atoms";
import { UploadBox, FormSelect, FormInput, FormCheckbox } from "molecules";
import { FormLayout } from "templates/CorporateSecretarial/components";
import MemoUploadIcon from "assets/icons/Upload";

export const FormGSTRegistration = ({ step, onNext, ...props }) => {
  return (
    <>
      <FormLayout
        // step={step}
        onNext={onNext}
        title="Kindly provide Activity Code:"
        // subtitle="Description Text"
        {...props}
      >
        <Text
          mt="4.8rem"
          variant="subtitleSmall"
          color="neutral.700"
          fontWeight="600"
        >
          Upload Choosen Document
        </Text>
        <Line mt="1.6rem" mb="2.4rem" />

        {/* Table for upload doc*/}
        <Table
          tableHeader={[
            { name: "Document Name" },
            { name: "Document Type" },
            { name: "Period" },
            { name: "Upload file" },
          ]}
        >
          <tbody>
            <tr>
              <td>
                <Text variant="bodyLarge" color="neutral.700">
                  Bizfile
                </Text>
              </td>
              <td>
                <FormSelect name="fromGSTRegistration" label="Passport" />
              </td>
              <td>
                <Text variant="bodyLarge" fontWeight="600" color="neuteal.800">
                  One-Time
                </Text>
              </td>
              <td>
                <Button IconProps={{ Icon: MemoUploadIcon }}>Upload</Button>
              </td>
            </tr>

            <tr>
              <td>
                <Text variant="bodyLarge" color="neutral.700">
                  Proof of Identification
                </Text>
              </td>
              <td>
                <FormSelect name="fromGSTRegistration" label="Passport" />
              </td>
              <td>
                <Text variant="bodyLarge" fontWeight="600" color="neuteal.800">
                  One-Time
                </Text>
              </td>
              <td>
                <UploadBox />
              </td>
            </tr>

            <tr>
              <td>
                <Text variant="bodyLarge" color="neutral.700">
                  Proof of Identification
                </Text>
              </td>
              <td>
                <FormSelect name="fromGSTRegistration" label="Passport" />
              </td>
              <td>
                <Text variant="bodyLarge" fontWeight="600" color="neuteal.800">
                  One-Time
                </Text>
              </td>
              <td>
                <UploadBox />
              </td>
            </tr>
          </tbody>
        </Table>
        {/* Director name input */}
        <Box mt="4.8rem">
          {/* title */}
          <Text variant="subtitleSmall" fontWeight="600" color="neutral.700">
            Director name
          </Text>
          <Line mt="1.6rem" />
          {/* inputs */}
          <Grid gTC="repeat(2,1fr)" gridGap="2.4rem" mt="2.4rem">
            <FormInput
              name="firstName"
              placeholder="firstName"
              label="Enter first name"
            />
            <FormInput
              name="lastName"
              placeholder="lastName"
              label="Enter second name"
            />
          </Grid>
        </Box>
        {/* choose identification */}
        <Box mt="5rem">
          <Grid gTC="repeat(4,auto)" gCG="3.5rem" jC="start">
            {/* title */}
            <Text variant="subtitleSmall" fontWeight="600" color="neutral.700">
              Choose Identification
            </Text>
            <FormCheckbox name="GSTResistrationPassport" label="Passport" />
            <FormCheckbox name="GSTResistrationNRC" label="NRC" />
            <FormCheckbox name="GSTResistrationEPNum" label="EP Number" />
          </Grid>
          <Line mt="1.6rem" mb="2.4rem" />
          {/* input */}
          <FormInput name="GSTResistrationname" label="Enter" />
        </Box>

        {/* designation, dob,email, mobile section */}
        <Grid gTC="repeat(2,1fr)" gridGap="2.4rem">
          {/* Designation */}
          <Box mt="5rem">
            <Text variant="subtitleSmall" fontWeight="600" color="neutral.700">
              Designation
            </Text>
            <Line mt="1.6rem" mb="2.4rem" />
            {/* input */}
            <FormInput name="GSTResistrationDesignation" label="Enter" />
          </Box>
          {/* Email */}
          <Box mt="5rem">
            <Text variant="subtitleSmall" fontWeight="600" color="neutral.700">
              Email
            </Text>
            <Line mt="1.6rem" mb="2.4rem" />
            <FormInput
              name="GSTResistrationemail"
              placeholder="email"
              label="Enter"
            />
          </Box>
          {/* Date of Birth */}
          <Box mt="5rem">
            <Text variant="subtitleSmall" fontWeight="600" color="neutral.700">
              Date of Birth
            </Text>
            <Line mt="1.6rem" mb="2.4rem" />
            <FormInput name="GSTResistrationDOB" label="Enter" />
          </Box>
          {/* mobile */}
          <Box mt="5rem">
            <Text variant="subtitleSmall" fontWeight="600" color="neutral.700">
              Mobile
            </Text>
            <Line mt="1.6rem" mb="2.4rem" />
            <FormInput
              name="GSTResistrationphone"
              placeholder="phone"
              label="Enter"
            />
          </Box>
        </Grid>
        {/* Residential Address */}
        <Box mt="5rem">
          <Text variant="subtitleSmall" fontWeight="600" color="neutral.700">
            Residential Address
          </Text>
          <Line mt="1.6rem" mb="2.4rem" />
          {/* inputs */}
          <Grid gTC="repeat(2,1fr)" gridGap="2.4rem">
            <FormInput name="GSTResistrationaddress1" label="Enter" />
            <FormInput name="GSTResistrationaddress2" label="Enter" />
            <FormInput name="GSTResistrationaddress3" label="Enter" />
            <FormSelect name="GSTResistrationCounrty" label="Country" />
          </Grid>
        </Box>
        <Text
          variant="subtitleSmall"
          fontWeight="600"
          color="neutral.700"
          mt="5rem"
        >
          Confirmation of completed courses on IRAS portal
        </Text>
        <Line mt="1.6rem" mb="2.4rem" />
        <Table
          tableHeader={[
            { name: "Document Name" },
            { name: "Document Type" },
            { name: "Period" },
            { name: "Upload file" },
          ]}
        >
          <tbody>
            <tr>
              <td>
                <Text variant="bodyLarge" color="neutral.700">
                  Screenshot of completion the course
                </Text>
              </td>
              <td>
                <FormSelect name="fromxeropassport" label="Passport" />
              </td>
              <td>
                <Text variant="bodyLarge" fontWeight="600" color="neuteal.800">
                  One-Time
                </Text>
              </td>
              <td>
                <Button IconProps={{ Icon: MemoUploadIcon }}>Upload</Button>
              </td>
            </tr>
          </tbody>
        </Table>
        <Line mt="5rem" />
        <Button w="fit-content" mt="4rem" px="4rem" onClick={onNext}>
          Next : Payment
        </Button>
      </FormLayout>
    </>
  );
};
