import React, { useState } from "react";
import { CSSTransition } from "react-transition-group";

import { Box, Button, Flex, Grid, Text } from "atoms";
import { theme } from "styles";
import MemoTickIcon from "assets/icons/Tick";
import { formatDuration } from "utils/utilities";
import MemoWhatsappIcon from "assets/icons/Whatsapp";
import MemoEmailIcon from "assets/icons/Email";
import { cwToast } from "molecules";
import Sheet from "organisms/Sheet";
import { useAuth } from "contexts/Auth";

export const PricingCard = ({
  title,
  titleMaxLines,
  id,
  subtitle,
  price,
  duration,
  bulletData,
  onChooseLoading,
  onChoosePlan,
  templateTitle,
  ...props
}) => {
  const {
    state: { userData },
  } = useAuth();

  const emailbody = `Dear Chartswood,

I would like to inquire about the ${title} ${
    templateTitle || ""
  }service you offer.

Full name: ${userData?.firstName || ""} ${userData?.lastName || ""}
Service Needed: ${title || ""} ${templateTitle || ""}
Email: ${userData?.email || ""}
Mobile: ${userData?.phoneNumber || ""}

Regards,
${userData?.firstName || ""}`;
  //   const emailbody = `Dear Chartswood,%0D%0A
  //   %0D%0A
  // I would like to inquire about the ${title} ${
  //     templateTitle || ""
  //   }service you offer.%0D%0A
  // %0D%0A
  // Full name: ${userData?.firstName || ""} ${userData?.lastName || ""}%0D%0A
  // Service Needed: ${title || ""}%0D%0A
  // Email: ${userData?.email || ""}%0D%0A
  // Mobile: ${userData?.phoneNumber || ""}%0D%0A
  // %0D%0A
  // Regards,%0D%0A
  // ${userData?.firstName || ""}%0D%0A`;

  const whatsappBody = `Dear Chartswood,

I would like to inquire about the ${title} ${
    templateTitle || ""
  }service you offer.

Full name: ${userData?.firstName || ""} ${userData?.lastName || ""}
Service Needed: ${title || ""} ${templateTitle || ""}
Email: ${userData?.email || ""}
Mobile: ${userData?.phoneNumber || ""}

Regards,
${userData?.firstName || ""}`;

  const [isHover, setIsHover] = useState(false);

  const [isContact, setIsContact] = useState(false);

  const handleMouseEnter = () => {
    // setIsHover(true);
  };
  const handleMouseLeave = () => {
    setIsHover(false);
  };

  return (
    <>
      <Box
        // mt="4.6rem"
        px="3.6rem"
        py="4rem"
        bR="2rem"
        // w="34.9rem"
        h="fit-content"
        boxShadow={isHover ? "m" : "none"}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        background={
          isHover
            ? `linear-gradient(90deg, ${theme.colors.primary[700]} 0%, ${theme.colors.primary[900]} 92.21%)`
            : "none"
        }
        {...props}
      >
        <Text
          color={isHover ? "neutral.50" : "neutral.800"}
          variant="subtitleSmall"
          fontWeight="600"
          {...(titleMaxLines && {
            css: {
              display: "-webkit-box",
              "-webkit-line-clamp": titleMaxLines,
              "-webkit-box-orient": "vertical",
              overflow: "hidden",
              "text-overflow": "ellipsis",
              height: `${(parseInt(titleMaxLines) || 0) * 2.1}rem`,
            },
          })}
        >
          {title}
        </Text>
        <Text
          color={isHover ? "neutral.100" : "neutral.500"}
          variant="bodyMedium"
        >
          {subtitle}
        </Text>
        <Text
          mt="3.2rem"
          color={isHover ? "neutral.50" : "neutral.800"}
          fontSize="3.2rem ! important"
          variant="subtitleLarge"
          fontWeight="700"
          display="flex"
          aI="flex-end"
          // aI="baseline"
        >
          {price}
          <Text
            color={isHover ? "neutral.50" : "neutral.500"}
            variant="bodyMedium"
            lineHeight="1.4rem"
          >
            {formatDuration(duration)}
          </Text>
        </Text>

        <Box w="100%" mt="3.2rem" mb="3.2rem">
          <Button
            onClick={() => {
              setIsContact((prev) => !prev);
              // onChoosePlan(id);
            }}
            loading={onChooseLoading}
            css={{
              // "&:active, &:hover": {
              //   bg: "secondary.700 !important",
              //   color: "neutral.50 !important",
              // },
              // "&:focus": {
              //   // outline: "2px solid rgba(255, 136, 0, 1)",
              //   bg: "secondary.700 !important",
              //   color: "neutral.50 !important",
              // },
              outline: isContact
                ? "2px solid rgba(255, 136, 0, 1) !important"
                : "none",
              background: isContact ? "white !important" : "secondary.700",
              color: isContact ? "#FF8800 !important" : "white",
            }}
          >
            Contact Sales
          </Button>

          <Sheet
            isOpen={isContact}
            onClose={() => setIsContact(false)}
            // bg="rgba(0,0,0,0.1)"
          >
            <CSSTransition
              classNames="collapse"
              in={isContact}
              timeout={300}
              unmountOnExit
            >
              <Grid
                position="absolute"
                w="100%"
                gTC="1fr 1fr"
                p=".8rem .6rem"
                // borderLeft="2px solid"
                // borderRight="2px solid"
                // borderBottom="2px solid"
                // borderColor="secondary.700"
                borderRadius="0 0 8px 8px"
                bg="white"
                zIndex={1000}
                top="85%"
                css={{ outline: "2px solid rgba(255, 136, 0, 1)" }}
              >
                <Flex
                  flexDirection="column"
                  aI="center"
                  cursor="pointer"
                  onClick={() => {
                    window.open(
                      `https://wa.me/+6587703896?${new URLSearchParams({
                        text: whatsappBody,
                      }).toString()}`
                    );
                    onChoosePlan(id);
                  }}
                >
                  <Box height="4.5rem">
                    <MemoWhatsappIcon height="4.5rem" />
                  </Box>
                  <Text color="neutral.600" variant="bodyMedium">
                    Via Whatsapp
                  </Text>
                </Flex>

                <Flex
                  flexDirection="column"
                  aI="center"
                  cursor="pointer"
                  onClick={() => {
                    window.open(
                      `mailto:sales@chartswoodassociates.com?body=${encodeURIComponent(
                        emailbody
                      )}`
                    );
                    cwToast(
                      "success",
                      "Contact Us",
                      "Someone from our team will reach out to you via email"
                    );
                    onChoosePlan(id);
                  }}
                >
                  <Flex
                    w="4.5rem"
                    h="4.5rem"
                    bR="50%"
                    bg="success.700"
                    aI="center"
                    jC="center"
                  >
                    <Box height="2rem" color="white">
                      <MemoEmailIcon height="2rem" />
                    </Box>
                  </Flex>

                  <Text color="neutral.600" variant="bodyMedium">
                    Via Mail
                  </Text>
                </Flex>
              </Grid>
            </CSSTransition>
          </Sheet>
        </Box>

        {/* <Grid gTC="1.6rem 1fr" gCG="1.6rem"> */}
        {bulletData?.map((item, index) => {
          return (
            <Grid
              gTC="1.6rem 1fr"
              gCG="1.6rem"
              py=".6rem"
              key={index}
              alignItems="center"
            >
              <Box
                color={isHover ? "neutral.50" : "success.700"}
                // mr="1.6rem"
                // py=".6rem"
                // width="1.6rem"
                height="1.2rem"
              >
                {typeof item === "string" && (
                  <MemoTickIcon width="1.6rem" height="1.2rem" />
                )}
              </Box>
              <Text
                color={isHover ? "neutral.50" : "neutral.600"}
                variant="bodyMedium"
                fontWeight="600"
              >
                {item}
              </Text>
            </Grid>
          );
        })}
      </Box>
    </>
  );
};
