import * as React from "react";

function Tick(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 16 12" fill="none" {...props}>
      <path
        d="M6 9.177L14.666 0 16 1.412 6 12 0 5.647l1.333-1.411L6 9.176z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoTick = React.memo(Tick);
export default MemoTick;
