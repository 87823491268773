import * as React from "react";

function LineArrow(props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.96426 3.828L14.1365 9.192L15.5 7.778L8 0L0.5 7.778L1.86346 9.192L7.03574 3.828L7.03574 16H8.96426L8.96426 3.828Z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoLineArrowIcon = React.memo(LineArrow);
export default MemoLineArrowIcon;
