export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const SWITCH_LAYOUT = "SWITCH_LAYOUT";
export const SIGNUP = "SIGNUP";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const OTP = "OTP";
export const RESEND = "RESEND";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const UPDATE_USER = "UPDATE_USER";
export const SET_LOGGED_IN = "SET_LOGGED_IN";
