import React from "react";
import { useNavigate } from "react-router-dom";

import { Box, Flex, Text } from "atoms";
import { Sheet } from "organisms";
import useAuth from "contexts/Auth";

export const UserDropdown = ({ isOpen, onClose, userName, email }) => {
  const {
    actions: { logout },
  } = useAuth();

  const navigate = useNavigate();
  return (
    <Sheet isOpen={isOpen} onClose={onClose} bg="rgba(0,0,0,0.1)">
      <Box
        // as="span"
        minWidth="16rem"
        position="absolute"
        bg="white"
        right="-29%"
        top="163%"
        bR="1rem"
        boxShadow="m"
        zIndex="1000"
      >
        <Box
          top="-8px"
          w="1.6rem"
          h="1.6rem"
          position="absolute"
          left="calc(100% - 39px)"
          transform="rotate(45deg)"
          bg="white"
        />

        <Flex flexDirection="column">
          {/* user details */}
          <Box p=".8rem 1.6rem">
            <Text variant="bodyLarge" fontWeight="600" color="neutral.700">
              {userName || ""}
            </Text>
            <Text variant="bodySmall" color="neutral.500">
              {email || ""}
            </Text>
          </Box>
          {/* line */}
          <Box
            h="0.5px"
            border="0.5px dashed"
            borderColor="neutral.100"
            mt=".8rem"
          />
          {/* call to action */}
          <Box m=".8rem">
            {/* <Link to="/profile/personal-details"> */}
            <Box
              h="2.8rem"
              bR="0.4rem"
              p="0.2rem 0.9rem"
              _hover={{
                bg: "success.50",
              }}
              cursor="pointer"
              onClick={() => {
                navigate("/profile/personal-details");
                onClose();
              }}
            >
              <Text
                variant="bodyMedium"
                color="neutral.600"
                _hover={{
                  color: "success.900",
                }}
              >
                Profile
              </Text>
            </Box>
            {/* </Link> */}
            <Box
              h="2.8rem"
              p="0.2rem 0.9rem"
              _hover={{
                bg: "success.50",
              }}
              bR="0.4rem"
              cursor="pointer"
              onClick={() => {
                logout();
                onClose();
              }}
            >
              <Text
                variant="bodyMedium"
                color="neutral.600"
                _hover={{
                  color: "success.900",
                }}
              >
                Logout
              </Text>
            </Box>
          </Box>
        </Flex>
      </Box>
    </Sheet>
  );
};
