import React from "react";
import { Box, Flex, Grid, Line, Text } from "atoms";
// import { DefaultChips } from "molecules";
// import MemoPdficon from "assets/icons/Pdficon";
import { format, parseISO } from "date-fns";
import { Accordian } from "organisms";
import { Director } from "templates/Task/types/Director/components/Director";
import {
  CorporateShareholder,
  Shareholder,
} from "templates/Task/types/Shareholder/components";
import MemoFileIcon from "assets/icons/File";

const getDirectorTitle = (firstName, lastName) => {
  if (firstName || lastName) {
    let title = firstName || "";
    if (lastName) {
      title += `${firstName ? " " : ""}${lastName || ""}`;
    }
    return title;
  }
  return "";
};

export const FormDetails = ({ serviceDetails }) => {
  return (
    <>
      <Box w="100%">
        <Text variant="subtitleMedium" color="neutral.700" fontWeight="600">
          User Details
        </Text>
        <Text color="neutral.600" variant="bodySmall" mt=".8rem">
          Description
        </Text>
        <Flex mt="3.2rem">
          {/* Director */}
          <Text variant="subtitleMedium" color="neutral.700" fontWeight="600">
            Director:
          </Text>
          <Text variant="subtitleMedium" ml="0.8rem">
            {
              { local: "Local", foreign: "Foreign" }[
                serviceDetails?.directorType
              ]
            }
          </Text>
        </Flex>
        <Line mt="1.6rem" />

        {/* Foreign director details */}
        {serviceDetails?.directorType === "foreign" && (
          <>
            <Text
              mt="3.2rem"
              color="neutral.700"
              variant="subtitleMedium"
              fontWeight="600"
            >
              Foreign Director Details
            </Text>
            <Line mt="1.6rem" />
            <Grid gTC="auto 1fr" gCG="2.4rem" aI="center" mt="2.4rem">
              <Text variant="bodyLarge" color="neutral.700">
                Local Address Proof
              </Text>

              <Flex
                cursor="pointer"
                onClick={() =>
                  window.open(
                    serviceDetails?.localAddressProofDocuments?.[0]?.url,
                    "_blank"
                  )
                }
              >
                <Box mt="1.2rem" color="primary.900">
                  <MemoFileIcon height="2.4rem" width="2.4rem" />
                </Box>
                <Box pl="1.2rem">
                  <Text
                    variant="bodyLarge"
                    fontWeight="600"
                    color="neutral.700"
                  >
                    {serviceDetails?.localAddressProofDocuments?.[0]?.name}
                  </Text>
                  <Text variant="bodySmall" color="neutral.500">
                    {serviceDetails?.localAddressProofDocuments?.[0]?.time &&
                      format(
                        parseISO(
                          serviceDetails?.localAddressProofDocuments?.[0]?.time
                        ),
                        "dd MMM, yyyy hh:mm a"
                      )}
                  </Text>
                </Box>
              </Flex>
            </Grid>
          </>
        )}

        {/*  Company details */}
        <Text
          mt="3.2rem"
          color="neutral.700"
          variant="subtitleMedium"
          fontWeight="600"
        >
          Company Details
        </Text>
        <Line mt="1.6rem" mb="2.4rem" />
        <Flex mt="3.2rem">
          {/* Director */}
          <Text variant="subtitleMedium" color="neutral.700" fontWeight="500">
            Company Name:
          </Text>
          <Text variant="subtitleMedium" ml="0.8rem">
            {serviceDetails?.companyName}
          </Text>
        </Flex>
        {serviceDetails?.primaryCompanyActivity && (
          <>
            <Flex mt="6.4rem">
              {/* Director */}
              <Text
                variant="subtitleMedium"
                color="neutral.700"
                fontWeight="500"
              >
                Primary Business Activity Code:
              </Text>
              <Text variant="subtitleMedium" ml="0.8rem" flex="1">
                {serviceDetails?.primaryCompanyActivity || ""}
              </Text>
            </Flex>

            <Line mt="1.6rem" />

            <Text
              variant="bodyLarge"
              fontWeight="600"
              color="neutral.700"
              mt="4rem"
            >
              Business details
            </Text>

            <Line mt="1.6rem" />

            <Box
              w="100%"
              bR=".8rem"
              bg="neutral.50"
              mt="1.6rem"
              py="2.4rem"
              pl="2.4rem"
            >
              <Text
                variant="bodyLarge"
                color="neutral.800"
                css={{ whiteSpace: "pre" }}
              >
                {serviceDetails?.primaryBusinessDescription || ""}
              </Text>
            </Box>
          </>
        )}

        {serviceDetails?.secondaryCompanyActivity && (
          <>
            <Flex mt="6.4rem">
              {/* Director */}
              <Text
                variant="subtitleMedium"
                color="neutral.700"
                fontWeight="500"
              >
                Secondary Business Activity Code:
              </Text>
              <Text variant="subtitleMedium" ml="0.8rem" flex="1">
                {serviceDetails?.secondaryCompanyActivity || ""}
              </Text>
            </Flex>

            <Line mt="1.6rem" />

            <Text
              variant="bodyLarge"
              fontWeight="600"
              color="neutral.700"
              mt="4rem"
            >
              Business details
            </Text>

            <Line mt="1.6rem" />

            <Box
              w="100%"
              bR=".8rem"
              bg="neutral.50"
              mt="1.6rem"
              py="2.4rem"
              pl="2.4rem"
            >
              <Text
                variant="bodyLarge"
                color="neutral.800"
                css={{ whiteSpace: "pre" }}
              >
                {serviceDetails?.secondaryBusinessDescription || ""}
              </Text>
            </Box>
          </>
        )}

        {serviceDetails?.financialYear && (
          <>
            <Flex mt="3.2rem">
              <Text
                variant="subtitleMedium"
                color="neutral.700"
                fontWeight="500"
              >
                First Financial Review:
              </Text>
              <Text variant="subtitleMedium" ml="0.8rem">
                {serviceDetails?.financialYear &&
                  format(new Date(serviceDetails?.financialYear), "MM/yyyy")}
              </Text>
            </Flex>
          </>
        )}

        {/*   Registered office address*/}
        <Text
          mt="8rem"
          color="neutral.700"
          variant="subtitleMedium"
          fontWeight="600"
        >
          Registered Office Address{" "}
          {serviceDetails?.usingCorporateAddressDetails &&
            "(using Chartwood Corporate Address Service)"}
        </Text>
        <Line mt="1.6rem" mb="2.4rem" />
        {!serviceDetails?.usingCorporateAddressDetails && (
          <>
            <Box w="100%" bR=".8rem" bg="neutral.50" py="2.4rem" pl="2.4rem">
              <Text variant="bodyLarge" color="neutral.800">
                If you don't have a registered company address, use Chartwood's.
              </Text>
            </Box>
            <Text
              mt="3.2rem"
              color="neutral.700"
              variant="bodyLarge"
              fontWeight="600"
            >
              Company address
            </Text>
            <Line mt="1.4rem" mb="2.4rem" />
            <Grid gridAutoFlow="column" gTC="repeat(4,1fr)" gCG="2.4rem">
              <Box>
                <Text color="neutral.600" variant="bodyLarge">
                  Address Line 1
                </Text>
                <Text
                  color="neutral.800"
                  variant="subtitleSmall"
                  fontWeight="600"
                  mt=".8rem"
                >
                  {serviceDetails?.corporateAddressDetails?.addressLine1 || ""}
                </Text>
              </Box>
              <Box>
                <Text color="neutral.600" variant="bodyLarge">
                  Address Line 2
                </Text>
                <Text
                  color="neutral.800"
                  variant="subtitleSmall"
                  fontWeight="600"
                  mt=".8rem"
                >
                  {serviceDetails?.corporateAddressDetails?.addressLine2 || ""}
                </Text>
              </Box>
              <Box>
                <Text color="neutral.600" variant="bodyLarge">
                  Country
                </Text>
                <Text
                  color="neutral.800"
                  variant="subtitleSmall"
                  fontWeight="600"
                  mt=".8rem"
                >
                  {serviceDetails?.corporateAddressDetails?.country || ""}
                </Text>
              </Box>
              <Box>
                <Text color="neutral.600" variant="bodyLarge">
                  Postal Code
                </Text>
                <Text
                  color="neutral.800"
                  variant="subtitleSmall"
                  fontWeight="600"
                  mt=".8rem"
                >
                  {serviceDetails?.corporateAddressDetails?.postalCode || ""}
                </Text>
              </Box>
            </Grid>
          </>
        )}

        {/*  Director details */}
        <Text
          mt="8rem"
          color="neutral.700"
          variant="subtitleMedium"
          fontWeight="600"
        >
          Director Details{" "}
          {serviceDetails?.usingDirectorDetails &&
            "(using Chartwood Nominee Director Services)"}
        </Text>
        <Line mt="1.6rem" mb="2.4rem" />
        {serviceDetails?.directorDetails && (
          <Accordian
            accordians={serviceDetails?.directorDetails?.map((director) => ({
              title: getDirectorTitle(director?.firstName, director?.lastName),
              children: <Director director={director} />,
            }))}
            mt="4rem"
            gap="2.4rem"
            isRemovable={false}
            isAutoOpen={false}
          />
        )}

        {/*  Shareholder details */}
        <Text
          mt="8rem"
          color="neutral.700"
          variant="subtitleMedium"
          fontWeight="600"
        >
          Shareholder Details{" "}
          {serviceDetails?.usingShareholderDetails &&
            "(using Chartwood Nominee Shareholder Services)"}
        </Text>
        <Line mt="1.6rem" mb="2.4rem" />
        {(serviceDetails?.shareholderDetails ||
          serviceDetails?.corporateShareholderDetails) && (
          <Accordian
            accordians={[
              ...(serviceDetails?.shareholderDetails || []),
              ...(serviceDetails?.corporateShareholderDetails || []),
            ]?.map((shareholder) => ({
              title:
                getDirectorTitle(
                  shareholder?.firstName,
                  shareholder?.lastName
                ) ||
                shareholder?.companyName ||
                `New Shareholder`,
              children: {
                individual: <Shareholder shareholder={shareholder} />,
                corporate: <CorporateShareholder shareholder={shareholder} />,
              }[shareholder?.category],
            }))}
            mt="4rem"
            gap="2.4rem"
            isRemovable={false}
            isAutoOpen={false}
          />
        )}
      </Box>
    </>
  );
};
