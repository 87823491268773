// TYPES CODES FOR TASK CLASSIFICATION

const SERVICE_TYPE_CODE = {
  // INCORPORATION
  INCORPORATION: "",

  // CORPORATE SECRETARIAL
  CORPORATE_SECRETARIAL: {
    transferExistingCompanySecretaryServices: {
      code: "transferExistingCompanySecretaryServices",
      title: "Transfer Existing Company Secretary Services",
    },
    changeInBuisnessCode: {
      code: "changeInBuisnessCode",
      title: "Change Buisness Code",
    },
    additionAndRemovalOfShareholder: {
      code: "additionAndRemovalOfShareholder",
      title: "Add or Remove Shareholder",
    },
    additionAndRemovalOfDirector: {
      code: "additionAndRemovalOfDirector",
      title: "Add or Remove Director",
    },
    changeInCompanyAddress: {
      code: "changeInCompanyAddress",
      title: "Change Company Address",
    },
    certifyTrueCopy: { code: "certifyTrueCopy", title: "Certify True Copy" },
    AGM_AND_ANNUAL_FILING: { code: "agmAndAnnualFiling", title: "" },
    toActNomineeShareholder: {
      code: "toActNomineeShareholder",
      title: "To Act as Nominee Shareholder Service",
    },
    toActNomineeDirector: {
      code: "toActNomineeDirector",
      title: "To Act as Nominee Director Service",
    },
    XBLR_FILING: { code: "xblrFiling", title: "" },
    PREPARE_SHARE_CERTIFICATE: { code: "prepareShareCertificate", title: "" },
  },
};

export default SERVICE_TYPE_CODE;
