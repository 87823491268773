export const PRODUCT_PRICES = {
  corporate_address_services: {
    mode: "incorporation",
    type: "corporate_address_services",
    name: "Corporate Address Services",
    price: 120,
    oneTime: true,
    duration: null,
    priceId: "price_1M6pbhSDKZGOAZnZAQ8UaYR4",
  },
  director_services: {
    mode: "incorporation",
    type: "director_services",
    name: "Director Services",
    price: 1600,
    oneTime: true,
    duration: null,
    priceId: "price_1M6pciSDKZGOAZnZL5BBAzld",
  },
  shareholder_services: {
    mode: "incorporation",
    type: "shareholder_services",
    name: "Shareholder Services",
    price: 1600,
    oneTime: true,
    duration: null,
    priceId: "price_1M6pfpSDKZGOAZnZiAOxwZC0",
  },
  transfer_company_secretary_yes: {
    mode: "cs",
    type: "transfer_company_secretary_yes",
    name: "Transfer Company Secretary",
    price: 330,
    oneTime: false,
    duration: "yearly",
    priceId: "price_1M79Q6SDKZGOAZnZiHNdMWUP",
  },
  transfer_company_secretary_no: {
    mode: "cs",
    type: "transfer_company_secretary_no",
    name: "Transfer Company Secretary",
    price: 50,
    oneTime: false,
    duration: "yearly",
    priceId: "price_1M6pglSDKZGOAZnZwMsDi5QJ",
  },
  change_business_code: {
    mode: "cs",
    type: "change_business_code",
    name: "Change Business Code",
    price: 30,
    oneTime: true,
    duration: null,
    priceId: "price_1Obp5pH7strtYi5D22ors8e3",
  },
  add_director: {
    mode: "cs",
    type: "add_director",
    name: "Add Director",
    price: 150,
    oneTime: true,
    duration: null,
    priceId: "price_1M6pqmSDKZGOAZnZKjvf5BJS",
  },
  remove_director: {
    mode: "cs",
    type: "remove_director",
    name: "Remove Director",
    price: 150,
    oneTime: true,
    duration: null,
    priceId: "price_1M6prHSDKZGOAZnZ8WwhHVX3",
  },
  add_shareholder: {
    mode: "cs",
    type: "add_shareholder",
    name: "Add Shareholder",
    price: 150,
    oneTime: true,
    duration: null,
    priceId: "price_1M6priSDKZGOAZnZ0TZBjQu1",
  },
  remove_shareholder: {
    mode: "cs",
    type: "remove_shareholder",
    name: "Remove Shareholder",
    price: 150,
    oneTime: true,
    duration: null,
    priceId: "price_1M6pseSDKZGOAZnZxaKYBicA",
  },
  change_address_yes: {
    mode: "cs",
    type: "change_address_yes",
    name: "Change Address",
    price: 120,
    oneTime: true,
    duration: null,
    priceId: "price_1M6puDSDKZGOAZnZsH5FR8tk",
  },
  change_address_no: {
    mode: "cs",
    type: "change_address_no",
    name: "Change Address",
    price: 30,
    oneTime: true,
    duration: null,
    priceId: "price_1M6pvUSDKZGOAZnZ8rSGuoz8",
  },
  certify_true_copy: {
    mode: "cs",
    type: "certify_true_copy",
    name: "Certify True Copy",
    price: 50,
    oneTime: true,
    duration: null,
    priceId: "price_1M6pw7SDKZGOAZnZ6Ii8tY6c",
  },
  agm_annual_filing: {
    mode: "cs",
    type: "agm_annual_filing",
    name: "AGM Annual Filing",
    price: 250,
    oneTime: false,
    duration: "yearly",
    priceId: "price_1M6pwdSDKZGOAZnZ8NH5mOTS",
  },
  nominee_shareholder_service: {
    mode: "cs",
    type: "nominee_shareholder_service",
    name: "Nominee Shareholder Service",
    price: 1600,
    oneTime: false,
    duration: "yearly",
    priceId: "price_1M6pxhSDKZGOAZnZv5Z4q3e0",
  },
  nominee_director_service: {
    mode: "cs",
    type: "nominee_director_service",
    name: "Nominee Director Service",
    price: 1600,
    oneTime: false,
    duration: "yearly",
    priceId: "price_1M6pypSDKZGOAZnZLcxrXiSX",
  },
  reallocate_shares: {
    mode: "cs",
    type: "reallocate_shares",
    name: "Reallocate Shares",
    price: 150,
    oneTime: true,
    duration: null,
    priceId: "price_1M6pzJSDKZGOAZnZIHaKHCcv",
  },
  corporate_tax_filing: {
    mode: "tax",
    type: "corporate_tax_filing",
    name: "Corporate Tax Filing",
    price: 350,
    oneTime: false,
    duration: "yearly",
    priceId: null,
  },
  gst_registration: {
    mode: "tax",
    type: "gst_registration",
    name: "GST Registration",
    price: 350,
    oneTime: false,
    duration: "yearly",
    priceId: null,
  },
  gst_review: {
    mode: "tax",
    type: "gst_review",
    name: "GST Review",
    price: 250,
    oneTime: false,
    duration: "yearly",
    priceId: null,
  },
  monthly_payroll_service: {
    mode: "tax",
    type: "monthly_payroll_service",
    name: "Monthly Payroll Service",
    price: 250,
    oneTime: false,
    duration: "yearly",
    priceId: null,
  },
  employment_pass_application: {
    mode: "ev",
    type: "employment_pass_application",
    name: "Employment Pass Application",
    price: 650,
    oneTime: false,
    duration: "yearly",
    priceId: null,
  },
  visa_application: {
    mode: "ev",
    type: "visa_application",
    name: "Visa Application",
    price: 250,
    oneTime: false,
    duration: "yearly",
    priceId: null,
  },
};

export default PRODUCT_PRICES;
