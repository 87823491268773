import React, { useCallback, useEffect, useState } from "react";
// import { matchRoutes, useLocation } from "react-router-dom";

import { Box, Flex, Grid, Loader } from "atoms";
import { TOPBAR_HEIGHT } from "./utilities/constants";
import { DefaultHeader } from "./DefaultHeader";
import { DefaultSidebar } from "./DefaultSidebar";
import MemoArrowIcon from "assets/icons/Arrow";
import useService from "contexts/Service";

// const layoutFTroutes = [{ path: "/service-ft" }];

export const DefaultLayout = ({ children }) => {
  const isLayoutFT = false;
  const [isExpanded, setIsExpanded] = useState(true);
  const [loading, setLoading] = useState(false);

  const toggleSidebar = useCallback(() => {
    setIsExpanded((expanded) => !expanded);
  }, []);

  const {
    state: { initServices },
    actions: { getCart },
  } = useService();

  // useEffect(() => {
  //   setLoading(true);
  //   initServices().finally(() => {
  //     setLoading(false);
  //   });
  // }, []);

  useEffect(() => {
    getCart();
  }, []);

  return (
    <Loader loading={loading}>
      <Grid
        h="100vh"
        overflow="hidden"
        gridAutoFlow="column"
        gTC={isLayoutFT ? "1fr" : "auto 1fr"}
      >
        {/* sidebar start */}
        {!isLayoutFT && (
          <Flex>
            {/* arrow button */}
            <Flex
              position="absolute"
              aI="center"
              jC="center"
              bg="neutral.50"
              bR="100%"
              w="4rem"
              h="4rem"
              top="2.4rem"
              right="-2rem"
              zIndex={200}
              cursor="pointer"
              onClick={toggleSidebar}
            >
              <Flex
                aI="center"
                jC="center"
                bg="primary.900"
                bR="100%"
                w="2.6rem"
                h="2.6rem"
              >
                <Box
                  color="white"
                  transition="all .3sec"
                  css={{
                    "transform-origin": "center",
                  }}
                  transform={isExpanded ? "rotate(-90deg)" : "rotate(90deg)"}
                >
                  <MemoArrowIcon height="1.2rem" />
                </Box>
              </Flex>
            </Flex>
            {/* sidebar */}
            <DefaultSidebar
              isExpanded={isExpanded}
              // hideSidebar={hideSidebar}
            />
          </Flex>
        )}

        {/* sidebar end */}

        {/* right sidebar start */}
        <Grid
          bg="neutral.50"
          overflow="auto"
          aC="flex-start"
          gTR="auto 1fr"
          pt="1.6rem"
        >
          <Box mx="3.2rem">
            <DefaultHeader isHeaderLogo={isLayoutFT} />
          </Box>

          <Grid
            overflow="auto"
            gTR="1fr"
            h={`calc(100vh - ${TOPBAR_HEIGHT} - 1.6rem)`}
            css={{
              "::-webkit-scrollbar": {
                display: "none",
              },
              "::-webkit-scrollbar-track": {
                display: "none",
              },
              "::-webkit-scrollbar-thumb": {
                display: "none",
              },
            }}
          >
            <Box>{children}</Box>
          </Grid>
        </Grid>
        {/* right sidebar end */}
      </Grid>
    </Loader>
  );
};
