import React from "react";

import { Box, Flex, Text } from "atoms";

export const CategoryCards = ({
  iconProps: { Icon, iconColor, iconBg } = {
    Icon: null,
    iconColor: null,
    iconBg: null,
  },
  value,
  description,
  ...props
}) => {
  return (
    <Flex
      bg="white"
      h="10.3rem"
      px="2.4rem"
      pt="2.4rem"
      pb="1.8rem"
      borderRadius="0.8rem"
      // aI="center"
      {...props}
    >
      {Icon && (
        <Flex
          bg={iconBg}
          w="4rem"
          h="4rem"
          borderRadius="100%"
          aI="center"
          jC="center"
          color={iconColor}
        >
          <Icon />
        </Flex>
      )}
      <Box ml="3.2rem">
        <Text
          variant="subtitleLarge"
          fontWeight="700"
          color="neutral.800"
          fontSize="3.2rem !important"
        >
          {value?.toString()?.lenght > 1 ? "" : "0"}
          {value?.toString() || ""}
        </Text>
        <Text variant="bodySmall" color="neutral.500" mt="0.8rem">
          {description || ""}
        </Text>
      </Box>
    </Flex>
  );
};
