import React, { useState } from "react";

import { Form, setNestedObjectValues } from "formik";
import { Box, Button, Flex, Text, Line, Grid } from "atoms";
import { FormDateInput, FormInput, FormSelect } from "molecules";
import FormLayout from "../FormLayout";
import FORM_NAMES from "templates/Incorporation/form.names";
import { useActivityCode } from "hooks/useActivityCode";
import { incorporationCompanyDetailsValidation } from "utils/validation";

const INITIAL_VALUES = {
  companyName: "",
  primaryCompanyActivity: "",
  primaryBusinessDescription: "",
  secondaryCompanyActivity: "",
  secondaryBusinessDescription: "",
  financialYear: "",
};

export const FormCompanyDetails = ({
  breadcrum,
  step,
  onNext,
  onPrevious,
  onSubmit,
  formInitValues,
  ...props
}) => {
  const [submitting, setSubmitting] = useState(false);

  // const [activityCodes, setActivityCodes] = useState([]);

  // const fetchActivityCodes = useCallback(() => {
  //   getComanyActivityCodes()?.then((data) => setActivityCodes(data));
  // }, [getComanyActivityCodes]);

  const [
    primaryActivityCodes,
    loadPrimaryActivityCodes,
    searchPrimaryActivityCodes,
  ] = useActivityCode(formInitValues?.primaryCompanyActivity);
  const [
    secondaryActivityCodes,
    loadSecondaryActivityCodes,
    searchSecondaryActivityCodes,
  ] = useActivityCode(formInitValues?.secondaryCompanyActivity);

  return (
    <FormLayout
      breadcrum={breadcrum}
      step={step}
      onPrevious={onPrevious}
      title="Company Details"
      subtitle="Indicate your preferred company name and business/company activity."
      formikProps={{
        initialValues: {
          ...INITIAL_VALUES,
          ...formInitValues,
        },
        enableReinitialize: true,
        validationSchema: incorporationCompanyDetailsValidation,
      }}
      {...props}
    >
      {({ values, dirty, validateForm, setErrors, setTouched }) => {
        return (
          <>
            <Form>
              <Flex mt="4rem">
                {/* company name */}
                {/* <Box w="70%"> */}
                <FormInput
                  name="companyName"
                  label="Company Name"
                  // hintText="* Company name availability subject to final confirmation from regulating authority."
                />
                {/* </Box> */}

                {/* check name availability button */}
                {/* <Box w="30%" ml=".8rem">
                  <Button disabled>Check name availability</Button>
                </Box> */}
              </Flex>

              {/* Classify your company */}
              <Flex mt="4.8rem">
                <Box
                  w="32%"
                  p="2.4rem 3.2rem"
                  bg="green.50"
                  // bg="primary.50"
                  borderRadius="8px 0px 0px 8px"
                >
                  <Text
                    variant="subtitleLarge"
                    fontWeight="500"
                    color="neutral.800"
                  >
                    Classify your company
                  </Text>
                  <Text variant="bodyMedium" color="neutral.700" mt="1.6rem">
                    Selecting a SSIC: Singapore Standard Industrial
                    Classification Code is essential when incorporating in
                    Singapore.
                  </Text>
                </Box>
                <Box flex="1" ml="1.6rem">
                  <FormSelect
                    name="primaryCompanyActivity"
                    label="Primary Company Activity"
                    options={primaryActivityCodes}
                    onScroll={loadPrimaryActivityCodes}
                    onInputChange={(value) => {
                      searchPrimaryActivityCodes(value);
                    }}
                  />
                  <Box mt="2.4rem">
                    <FormInput
                      name="primaryBusinessDescription"
                      label="Please describe your primary company activity"
                      type="textarea"
                      wrap="hard"
                      height="15.5rem"
                    />
                  </Box>
                </Box>
              </Flex>

              <Box mt="2.4rem">
                <FormSelect
                  name="secondaryCompanyActivity"
                  label="Secondary Company Activity"
                  options={secondaryActivityCodes}
                  onScroll={loadSecondaryActivityCodes}
                  onInputChange={(value) => {
                    searchSecondaryActivityCodes(value);
                  }}
                />
                <Box mt="2.4rem">
                  <FormInput
                    name="secondaryBusinessDescription"
                    label="Please describe your secondary company activity"
                    type="textarea"
                    wrap="hard"
                    height="15.5rem"
                  />
                </Box>
              </Box>

              <Grid
                gTC="1fr 1fr"
                gCG="2.4rem"
                gRG="2.4rem"
                mt="4.8rem"
                w="fit-content"
                aI="center"
              >
                <Text variant="subtitleSmall" fontWeight="600">
                  Choose First Financial Year:
                </Text>
                <Box w="20rem" ml="2.4rem">
                  <FormDateInput
                    name={`financialYear`}
                    label="Year*"
                    datePickerProps={{
                      dateFormat: "MM/yyyy",
                      showMonthYearPicker: true,
                      showFullMonthYearPicker: true,
                    }}
                  />
                </Box>
              </Grid>

              <Line mt="4.8rem" />
              <Button
                type="button"
                w="fit-content"
                px="4rem"
                mt="4rem"
                loading={submitting}
                onClick={() => {
                  validateForm(values)?.then((dataErrorBag) => {
                    const dataErrorBagKeys = Object?.keys(dataErrorBag || {});
                    if (dataErrorBagKeys?.length) {
                      setTouched(setNestedObjectValues(dataErrorBag, true));
                      setErrors(dataErrorBag);
                    } else {
                      if (dirty) {
                        setSubmitting(true);
                        onSubmit(values)
                          .then(() => {
                            onNext(
                              FORM_NAMES.FormCorporateAddressService,
                              true
                            );
                          })
                          .catch(console.error)
                          .finally(() => {
                            setSubmitting(false);
                          });
                      } else {
                        onNext(FORM_NAMES.FormCorporateAddressService, true);
                      }
                    }
                  });
                }}
              >
                NEXT : Corporate Address
              </Button>
            </Form>
          </>
        );
      }}
    </FormLayout>
  );
};
