import React from "react";
// import { CSSTransition } from "react-transition-group";

import { Box, Grid, Text, Flex } from "atoms";
import MemoCartIcon from "assets/icons/Cart";
import MemoCross from "styles/icons/Cross";

export const CartCard = ({
  title,
  description,
  amount,
  duration,
  onRemove,
  // serviceType,
  // bulletData,
}) => {
  // const [isCollapsed, setIsCollapsed] = useState(true);

  // const collapseStateHandler = useCallback(() => {
  //   setIsCollapsed((state) => !state);
  // }, []);
  return (
    <>
      <Grid gTC="3.9rem 1fr" gridGap="1.6rem" mt="2.6rem" mb="4rem">
        {/* left cart section */}
        <Grid h="3.9rem" bg="secondary.50" pI="center" color="secondary.900">
          <MemoCartIcon />
        </Grid>
        {/* right text section */}
        <Box>
          {/* company service /amount */}
          <Flex jC="space-between">
            <Box>
              <Text
                variant="subtitleSmall"
                fontWeight="600"
                color="neutral.800"
              >
                {title}
              </Text>
              <Text
                mt="1.6rem"
                maxWidth="42.7rem"
                variant="bodyMedium"
                color="neutral.600"
              >
                {description}
              </Text>
            </Box>
            <Flex aI="center">
              <Box>
                <Text
                  variant="bodyMedium"
                  fontWeight="600"
                  color="neutral.700"
                  textAlign="right"
                >
                  {amount}
                </Text>
                <Text
                  variant="bodyMedium"
                  color="neutral.600"
                  textAlign="right"
                >
                  {duration}
                </Text>
              </Box>
              <Flex
                bR="100%"
                bg="white"
                color="neutral.800"
                aI="center"
                jC="center"
                w="fit-content"
                minWidth="2.4rem"
                minHeight="2.4rem"
                transition="all .3sec"
                css={{
                  "&:hover": {
                    bg: "error.50",
                    color: "error.900",
                  },
                }}
                cursor="pointer"
                onClick={(e) => {
                  // e?.stopPropagation();
                  onRemove();
                }}
                ml="2.4rem"
              >
                <MemoCross />
              </Flex>
            </Flex>
          </Flex>
          {/* services details */}
          {/* <Grid
            mt="1.6rem"
            bg="primary.50"
            gTC="0.6rem 1fr"
            gridGap="2.2rem"
            bR="0.4rem"
            border="1px solid"
            borderLeft="0"
            borderColor="neutral.200"
          >

            <Box bg="primary.900" bR="0.4rem" zIndex="5" />

            <Box py="1.6rem" pr="2.4rem">
              <Flex jC="space-between">
                <Text variant="bodyMedium" fontWeight="600" color="neutral.800">
                  Services
                </Text>
                <Flex jC="flex-start" aI="center">
                  <Text
                    variant="bodyMedium"
                    color="primary.900"
                    mr="2.4rem"
                    cursor="pointer"
                    onClick={collapseStateHandler}
                  >
                    {isCollapsed ? "Show more" : "Show less"}
                  </Text>
                  <Flex jC="flex-start" aI="center" cursor="pointer">
                    <Box color="neutral.400">
                      <MemoDeleteIcon width="1.6rem" height="1.6rem" />
                    </Box>
                    <Text ml="0.8rem" color="neutral.700" variant="bodyMedium">
                      Delete
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
              <Text mt="0.4rem" variant="bodyMedium" color="neutral.700">
                {serviceType}
              </Text>

              <CSSTransition
                classNames="collapse"
                in={!isCollapsed}
                timeout={300}
                unmountOnExit
              >
                <>
                  {bulletData &&
                    bulletData.map((index) => {
                      return (
                        <Flex key={index} aI="center" mt="0.8rem">
                          <Box
                            w="0.8rem"
                            h="0.8rem"
                            bg="neutral.500"
                            mr="1.6rem"
                            bR="50%"
                          />
                          <Text
                            variant="bodyMedium"
                            color="neutral.600"
                            maxWidth="60rem"
                          >
                            {index?.title}
                          </Text>
                        </Flex>
                      );
                    })}
                </>
              </CSSTransition>
            </Box>
          </Grid> */}
        </Box>
      </Grid>
    </>
  );
};
