import React, { useState } from "react";
import { Box, Flex } from "atoms";
import { ChooseService } from "organisms";
import { TabHeader } from "organisms";
import MemoBulbIcon from "assets/icons/Bulb";
import { useNavigate } from "react-router-dom";
import FAQ from "organisms/FAQ";

const ChooseServiceCards = [
  {
    title: "Transfer Existing Company Secretary Services",
    body: "The Change or Appointment of New Company Secretary",
    button: "Get Started",
    link: "transfer-company-secretary",
  },
  {
    title: "Change in Business Code",
    body: "Update the correct Singapore Standard Industrial Code (SSIC) code for your company based on your current business needs",
    button: "Get Started",
    link: "change-buisness-code",
  },
  {
    title: "Addition and Removal of Shareholder",
    body: "The Change or Appointment of New Shareholder",
    button: "Get Started",
    link: "update-shareholder",
  },
  {
    title: "Addition and Removal of Director",
    body: "The Change or Appointment of New Director",
    button: "Get Started",
    link: "update-director",
  },
  {
    title: "Change in Company Address",
    body: "Add or change company’s registered address",
    button: "Get Started",
    link: "change-company-address",
  },
  {
    title: "Certify True Copy",
    body: "Get your documents notarized cost-effectively and efficiently",
    button: "Get Started",
    link: "certify-true-copy",
  },
  {
    title: "To Act as Nominee Shareholder Service",
    body: "Appointment of Shareholder to act as a legal, to hold shares on behalf of the actual Shareholder",
    button: "Get Started",
    link: "to-act-nominee-shareholder",
  },
  {
    title: "To Act as Nominee Director Service",
    body: "Appointment of Director by business owner to act of their behalf",
    button: "Get Started",
    link: "to-act-nominee-director",
  },

  // {
  //   title: "AGM and Annual Filing",
  //   body: "Descriptive Text",
  //   button: "Get Started",
  //   link: "agm-annual-filing",
  // },
  // {
  //   title: "XBLR",
  //   body: "Descriptive Text",
  //   button: "Get Started",
  //   link: "xblr",
  // },
];

export const CorporateSecretarial = () => {
  const navigate = useNavigate();
  const [isFAQ, setIsFAQ] = useState(false);
  return (
    <Flex flexDirection="column" w="100%" h="100%">
      <TabHeader
        title="Corporate Secretarial Services"
        breadcrum={{
          firstText: "Home",
          firstUrl: "/dashboard",
          secondText: "Corporate Secretarial Services",
        }}
        onPrevious={() => navigate("/dashboard")}
        px="3.2rem"
      />

      {/* service cards */}
      <Box mt="5.6rem" mb="13rem">
        <ChooseService
          title={"Get The Hassle-Free Corporate Secretarial Support You Need"}
          cards={ChooseServiceCards}
          px="3.2rem"
          titleMaxLines="2"
          // subTitleMaxLines="0"
          bodyMaxLines="3"
        />
      </Box>
      <Box
        position="absolute"
        left="90%"
        top="calc(100vh - 22.8rem)"
        cursor="pointer"
        onClick={() => setIsFAQ(true)}
      >
        <Flex
          position="fixed"
          width="5.5rem"
          height="5.5rem"
          bg="primary.900"
          borderRadius="100%"
          aI="center"
          jC="center"
        >
          <MemoBulbIcon height="2.4rem" />
        </Flex>
      </Box>
      <FAQ isOpen={isFAQ} onClose={() => setIsFAQ(false)} />
    </Flex>
  );
};
