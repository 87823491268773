import React, { useEffect, useState } from "react";
import { Box, Flex, Grid, Loader, Text } from "atoms";
import { ChooseService, PricingCard } from "organisms";
import { TabHeader } from "organisms";
import MemoBulbIcon from "assets/icons/Bulb";
import useService from "contexts/Service";
import { formatCurrency } from "utils/utilities";
import FAQ from "organisms/FAQ";

const ChooseServiceCards = [
  {
    title: "Corporate tax filing/ECI filing",
    body: "Nulla aliquam eget mauris quis fringilla. Sed velit augue,mauris quis fringilla.",
    button: "Get Started",
    link: "corporate-tax-and-eci-filling",
  },
  {
    title: "GST Registration",
    body: "Nulla aliquam eget mauris quis fringilla. Sed velit augue,mauris quis fringilla.",
    button: "Get Started",
    link: "gst-registration",
  },
  {
    title: "GST Review and Submission",
    body: "Nulla aliquam eget mauris quis fringilla. Sed velit augue,mauris quis fringilla.",
    button: "Get Started",
    link: "gst-review-and-submission",
  },
  {
    title: "Monthly payroll service",
    body: "Nulla aliquam eget mauris quis fringilla. Sed velit augue,mauris quis fringilla.",
    button: "Get Started",
    link: "monthly-payroll-service",
  },
  {
    title: "Personal income review and submission",
    body: "Nulla aliquam eget mauris quis fringilla. Sed velit augue,mauris quis fringilla.",
    button: "Get Started",
    link: "personal-income-review-and-submission",
  },
];

const PricingTaxing = [
  {
    title: "Corporate tax filing/ECI filing",
    subtitle: "Description Text",
    price: "from $349",
    duration: "one-time",
    bulletData: [
      "Description Text",
      "Description Text",
      "Description Text",
      "Description Text",
      "Description Text",
      "Description Text",
      "Description Text",
    ],
  },
  {
    title: "GST Registration, Review and Submission",
    subtitle: "Description Text",
    price: "from $499",
    duration: "/monthly",
    bulletData: [
      "Description Text",
      "Description Text",
      "Description Text",
      "Description Text",
      "Description Text",
      "Description Text",
      "Description Text",
    ],
  },
  {
    title: "Personal Income & Payroll Services",
    subtitle: "Description Text",
    price: "from $1599",
    duration: "/annual",
    bulletData: [
      "Description Text",
      "Description Text",
      "Description Text",
      "Description Text",
      "Description Text",
      "Description Text",
      "Description Text",
    ],
  },
];

const DESCRIPTORS = [
  {
    displayName: "Annual Tax Filing",
    corporatetax: true,
    gstregistration: false,
    personalincome: false,
  },
  {
    displayName: "ECI Filing",
    corporatetax: true,
    gstregistration: false,
    personalincome: false,
  },
  {
    displayName: "Consultation",
    corporatetax: false,
    gstregistration: true,
    personalincome: false,
  },
  {
    displayName: "Computation & Submission of Quarterly GST returns for IRAS",
    corporatetax: false,
    gstregistration: false,
    personalincome: true,
  },
  // downloads
  {
    displayName: (
      <Flex>
        Download Related Documents &nbsp;
        <a
          href="https://chartswood-dev.s3.ap-southeast-1.amazonaws.com/Corporate+Tax+Filing.xlsm"
          download
          rel="noreferrer"
          target="_blank"
        >
          <Box color="primary.500" textDecoration="underline">
            here
          </Box>
        </a>
      </Flex>
    ),
    corporatetax: true,
    gstregistration: false,
    personalincome: false,
  },
  {
    displayName: (
      <Flex>
        Download Related Documents &nbsp;
        <a
          href="https://chartswood-dev.s3.ap-southeast-1.amazonaws.com/GST+Registration+for+Businesses+-+Final.docx"
          download
          rel="noreferrer"
          target="_blank"
        >
          <Box color="primary.500" textDecoration="underline">
            here
          </Box>
        </a>
      </Flex>
    ),
    corporatetax: false,
    gstregistration: true,
    personalincome: false,
  },
  {
    displayName: (
      <Flex>
        Download Related Documents &nbsp;
        <a
          href="https://chartswood-dev.s3.ap-southeast-1.amazonaws.com/CWA+-+Quarterly+GST+Checklist.pdf"
          download
          rel="noreferrer"
          target="_blank"
        >
          <Box color="primary.500" textDecoration="underline">
            here
          </Box>
        </a>
      </Flex>
    ),
    corporatetax: false,
    gstregistration: false,
    personalincome: true,
  },
];

export const Tax = () => {
  const {
    actions: { getSubscriptionsByServiceType, createTaxes },
  } = useService();

  const [loading, setLoading] = useState([]);
  const [pricingLoading, setPricingLoading] = useState(false);
  const [pricingPlans, setPricingPlans] = useState([]);
  const [isFAQ, setIsFAQ] = useState(false);
  useEffect(() => {
    setPricingLoading(true);
    getSubscriptionsByServiceType("tax")
      .then((data) => {
        setPricingPlans(data);
        setLoading(new Array(data?.length).fill(false));
      })
      .finally(() => {
        setPricingLoading(false);
      });
  }, [getSubscriptionsByServiceType]);

  return (
    <Flex flexDirection="column" w="100%" h="100%" px="3.2rem">
      <TabHeader
        title="Taxation"
        breadcrum={{
          firstText: "Home",
          firstUrl: "/dashboard",
          secondText: "Taxation",
        }}
      />

      <Loader loading={pricingLoading}>
        <Box bg="white" mt="2.4rem" p="3.2rem" mb="2.4rem" boxShadow="m">
          <Text
            variant="headingSmall"
            fontWeight="600"
            // mt="2.4rem"
            color="neutral.800"
          >
            Taxation Packages
          </Text>
          <Text variant="subtitleSmall" color="neutral.500" mt="1.6rem">
            Our professionals will help you fulfil your tax obligations. In
            addition, to income tax compliance, our service also includes filing
            GST returns and providing advice in accordance with tax laws around
            tax planning to facilitate your business growth.
          </Text>
          <Box
            h=".1rem"
            w="100%"
            border="1px solid"
            borderColor="neutral.300"
            mt="1.6rem"
          />

          {/* service cards */}
          <Grid
            my="2.4rem"
            // gridAutoFlow="column"
            gTC={`repeat(${pricingPlans?.length || 0}, 1fr)`}
            jC="center"
            // gCG="2.4rem"
          >
            {pricingPlans?.map((plan, index) => (
              <PricingCard
                key={index}
                id={plan?._id}
                title={plan?.displayName}
                titleMaxLines="2"
                price={formatCurrency(
                  plan?.prices?.[0]?.amount,
                  plan?.prices?.[0]?.currency
                )}
                // duration={plan?.prices?.[0]?.interval}
                bulletData={DESCRIPTORS?.filter(
                  (item) => item?.[plan?.name]
                )?.map((item) => item?.displayName)}
                onChooseLoading={loading?.[index]}
                onChoosePlan={(id) => {
                  setLoading((prev) => {
                    const newLoader = JSON.parse(JSON.stringify(prev));
                    newLoader[index] = true;
                    return newLoader;
                  });
                  id &&
                    createTaxes({ subscription: id }, { plan })
                      .then((data) => {
                        // data && window.open(data, "_self");
                      })
                      .finally(() => {
                        setLoading((prev) => {
                          const newLoader = JSON.parse(JSON.stringify(prev));
                          newLoader[index] = false;
                          return newLoader;
                        });
                      });
                }}
              />
            ))}
          </Grid>
        </Box>
      </Loader>

      {/* service cards */}
      {/* <Box mt="5.6rem" mb="13rem">
        <ChooseService cards={ChooseServiceCards} px="3.2rem" />
      </Box> */}
      <Box
        position="absolute"
        left="90%"
        top="calc(100vh - 22.8rem)"
        cursor="pointer"
        onClick={() => setIsFAQ(true)}
      >
        <Flex
          position="fixed"
          width="5.5rem"
          height="5.5rem"
          bg="primary.900"
          borderRadius="100%"
          aI="center"
          jC="center"
        >
          <MemoBulbIcon height="2.4rem" />
        </Flex>
      </Box>
      <FAQ isOpen={isFAQ} onClose={() => setIsFAQ(false)} />
    </Flex>
  );
};
