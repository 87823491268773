import React from "react";
import { Grid } from "atoms";
import PricingCard from "../PricingCard";

const regularbulletData = [
  {
    id: "0",
    title: "Incorporation Application",
  },
  {
    id: "1",
    title: "Purchase of company name",
  },
  {
    id: "2",
    title: "Purchase of company name",
  },
  {
    id: "3",
    title: "Company constitution",
  },
  {
    id: "4",
    title: "$315 government fees included",
  },
];

const incorporationbulletData = [
  {
    id: "0",
    title: "Incorporation Application",
  },
  {
    id: "1",
    title: "Purchase of company name",
  },
  {
    id: "2",
    title: "Purchase of company name",
  },
  {
    id: "3",
    title: "Company constitution",
  },
  {
    id: "4",
    title: "$315 government fees included",
  },
  {
    id: "5",
    title: "Corporate Secretary routine Annual Filing & AGM Preparation",
  },
  {
    id: "6",
    title: "$60 Annual Filing government fee included",
  },
  {
    id: "7",
    title:
      "Unlimited changes in company name, officers, address, business activity, etc",
  },
];

const compilancebulletData = [
  {
    id: "0",
    title: "Incorporation Application",
  },
  {
    id: "1",
    title: "Purchase of company name",
  },
  {
    id: "2",
    title: "Purchase of company name",
  },
  {
    id: "3",
    title: "Company constitution",
  },
  {
    id: "4",
    title: "$315 government fees included",
  },
  {
    id: "5",
    title: "Bank account opening",
  },
  {
    id: "6",
    title:
      "Corporate Secretary for 1 year, includes Annual Filing and AGM preparation, $60 government fee, etc",
  },
  {
    id: "7",
    title: "Excludes: Nominee Director Fee $1,600 / annum",
  },
];

export const RegisterCompany = () => {
  return (
    <>
      <Grid gridAutoFlow="column">
        <PricingCard
          title="Regular Incorporation"
          subtitle="Perfect plan for Singaporean & PR"
          price="$399"
          duration="For lifetime"
          bulletData={regularbulletData}
        />
        <PricingCard
          title="Incorporation & Secretary"
          subtitle="Singaporean & PR"
          price="$699"
          duration="/annum"
          bulletData={incorporationbulletData}
        />
        <PricingCard
          title="Compliance
          for 1 year"
          subtitle="Foreigner"
          price="$1599"
          duration="/annum"
          bulletData={compilancebulletData}
        />
      </Grid>
    </>
  );
};
