import * as React from "react";

function ShoppingBag(props) {
  return (
    <svg
      width="204"
      height="196"
      viewBox="0 0 204 196"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M190 40.0001H147.6C146.133 28.9456 140.699 18.8005 132.311 11.4523C123.923 4.10415 113.151 0.0532227 102 0.0532227C90.8485 0.0532227 80.0767 4.10415 71.6886 11.4523C63.3006 18.8005 57.8674 28.9456 56.4 40.0001H14C10.287 40.0001 6.72602 41.4751 4.10051 44.1006C1.475 46.7261 0 50.2871 0 54.0001V182C0 185.713 1.475 189.274 4.10051 191.9C6.72602 194.525 10.287 196 14 196H190C193.713 196 197.274 194.525 199.899 191.9C202.525 189.274 204 185.713 204 182V54.0001C204 50.2871 202.525 46.7261 199.899 44.1006C197.274 41.4751 193.713 40.0001 190 40.0001ZM102 12.0001C109.977 12.0118 117.698 14.8199 123.819 19.9358C129.94 25.0517 134.073 32.1515 135.5 40.0001H68.5C69.927 32.1515 74.0605 25.0517 80.1813 19.9358C86.3021 14.8199 94.0228 12.0118 102 12.0001V12.0001ZM192 182C192 182.531 191.789 183.039 191.414 183.414C191.039 183.789 190.53 184 190 184H14C13.4696 184 12.9609 183.789 12.5858 183.414C12.2107 183.039 12 182.531 12 182V54.0001C12 53.4697 12.2107 52.961 12.5858 52.5859C12.9609 52.2108 13.4696 52.0001 14 52.0001H56V78.0001C56 79.5914 56.6321 81.1175 57.7574 82.2428C58.8826 83.368 60.4087 84.0001 62 84.0001C63.5913 84.0001 65.1174 83.368 66.2426 82.2428C67.3679 81.1175 68 79.5914 68 78.0001V52.0001H136V78.0001C136 79.5914 136.632 81.1175 137.757 82.2428C138.883 83.368 140.409 84.0001 142 84.0001C143.591 84.0001 145.117 83.368 146.243 82.2428C147.368 81.1175 148 79.5914 148 78.0001V52.0001H190C190.53 52.0001 191.039 52.2108 191.414 52.5859C191.789 52.961 192 53.4697 192 54.0001V182Z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoShoppingBagIcon = React.memo(ShoppingBag);
export default MemoShoppingBagIcon;
