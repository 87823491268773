import React from "react";
import { Box, Flex, Grid, Line, Text } from "atoms";
import { formatRelative } from "date-fns";
export const NotificationCard = ({
  Icon,
  // name,
  description,
  time,
  isRead,
  ...props
}) => {
  return (
    <>
      <Grid gTC="auto auto 1fr auto" p="1.8rem 0.8rem" aI="center" {...props}>
        {isRead ? (
          <Box ml="0.8rem" alignSelf="center" />
        ) : (
          <Box
            w="0.8rem"
            h="0.8rem"
            bR="50%"
            bg="secondary.500"
            // mt="2rem"
            // alignSelf="center"
          />
        )}
        <Box
          w="3.2rem"
          h="3.2rem"
          bR="50%"
          bg="secondary.50"
          p="1rem"
          // mt="0.8rem"
          ml="0.8rem"
          // alignSelf="center"
        >
          {/* icon */}
          <Icon width="1rem" height="1.1rem" />
        </Box>
        <Text
          variant="bodySmall"
          color="neutral.700"
          ml="0.8rem"
          justifySelf="start"
        >
          {/* {description || ""} */}
          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: description || "" }}
          />
        </Text>
        {time && (
          <Text
            variant="bodySmall"
            color="neutral.600"
            ml="0.8rem"
            // alignSelf="center"
          >
            {formatRelative(new Date(time), new Date())?.[0]?.toUpperCase()}
            {formatRelative(new Date(time), new Date())?.slice(1)}
          </Text>
        )}
      </Grid>
      <Line />
    </>
  );
};
