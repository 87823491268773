import React from "react";
import { Text, Table, Line, Button } from "atoms";
import { FormSelect } from "molecules";
import { FormLayout } from "templates/CorporateSecretarial/components";
import MemoUploadIcon from "assets/icons/Upload";

export const FormGSTReviewAndSubmission = ({ step, onNext, ...props }) => {
  return (
    <>
      <FormLayout
        // step={step}
        onNext={onNext}
        title="Kindly provide Activity Code:"
        // subtitle="Description Text"
        {...props}
      >
        <Text
          mt="4.8rem"
          variant="subtitleSmall"
          color="neutral.700"
          fontWeight="600"
        >
          Upload List of expenses with GST
        </Text>
        <Line mt="1.6rem" mb="2.4rem" />
        {/* Table for upload doc*/}
        <Table
          tableHeader={[
            { name: "Document Name" },
            { name: "Document Type" },
            { name: "Period" },
            { name: "Upload file" },
          ]}
        >
          <tbody>
            <tr>
              <td>
                <Text variant="bodyLarge" color="neutral.700">
                  Financial statements
                </Text>
              </td>
              <td>
                <FormSelect
                  name="fromGSTReviewAndSubmission"
                  label="Passport"
                />
              </td>
              <td>
                <Text variant="bodyLarge" fontWeight="600" color="neuteal.800">
                  One-Time
                </Text>
              </td>
              <td>
                <Button IconProps={{ Icon: MemoUploadIcon }}>Upload</Button>
              </td>
            </tr>

            <tr>
              <td>
                <Text variant="bodyLarge" color="neutral.700">
                  previous years Notice of Assessment
                </Text>
              </td>
              <td>
                <FormSelect
                  name="fromGSTReviewAndSubmission"
                  label="Passport"
                />
              </td>
              <td>
                <Text variant="bodyLarge" fontWeight="600" color="neuteal.800">
                  One-Time
                </Text>
              </td>
              <td>
                <Button IconProps={{ Icon: MemoUploadIcon }}>Upload</Button>
              </td>
            </tr>
          </tbody>
        </Table>
        <Text
          mt="4.8rem"
          variant="subtitleSmall"
          color="neutral.700"
          fontWeight="600"
        >
          Upload list of sales invoices
        </Text>
        <Line mt="1.6rem" mb="2.4rem" />
        {/* Table for upload doc*/}
        <Table
          tableHeader={[
            { name: "Document Name" },
            { name: "Document Type" },
            { name: "Period" },
            { name: "Upload file" },
          ]}
        >
          <tbody>
            <tr>
              <td>
                <Text variant="bodyLarge" color="neutral.700">
                  Financial statements
                </Text>
              </td>
              <td>
                <FormSelect
                  name="fromGSTReviewAndSubmission"
                  label="Passport"
                />
              </td>
              <td>
                <Text variant="bodyLarge" fontWeight="600" color="neuteal.800">
                  One-Time
                </Text>
              </td>
              <td>
                <Button IconProps={{ Icon: MemoUploadIcon }}>Upload</Button>
              </td>
            </tr>

            <tr>
              <td>
                <Text variant="bodyLarge" color="neutral.700">
                  previous years Notice of Assessment
                </Text>
              </td>
              <td>
                <FormSelect
                  name="fromGSTReviewAndSubmission"
                  label="Passport"
                />
              </td>
              <td>
                <Text variant="bodyLarge" fontWeight="600" color="neuteal.800">
                  One-Time
                </Text>
              </td>
              <td>
                <Button IconProps={{ Icon: MemoUploadIcon }}>Upload</Button>
              </td>
            </tr>
          </tbody>
        </Table>
        <Line mt="5rem" />
        <Button w="fit-content" mt="4rem" px="4rem" onClick={onNext}>
          Next : Payment
        </Button>
      </FormLayout>
    </>
  );
};
