import React, { useEffect } from "react";
import { Box, Button, Flex, Table, Text } from "atoms";
import FormLayout from "../FormLayout";
import { UploadCart } from "organisms";
import MemoEyeShowIcon from "assets/icons/EyeShow";
import MemoDeleteIcon from "assets/icons/Delete";
import { replaceInArrayOfObjects } from "utils/utilities";
import PRODUCT_PRICES from "utils/princing.constant";
import { setNestedObjectValues } from "formik";

export const FormCertifyTrueCopy = ({
  step,
  onNext,
  formUpload,
  values,
  setFieldValue,
  onSubmit,
  isLastStep,
  validateForm,
  setTouched,
  setErrors,
  errors,
  touched,
  ...props
}) => {
  useEffect(() => {
    const withCartValues = JSON.parse(JSON.stringify(values));
    let newCartFeatures = withCartValues?.cartSummary?.features || [];
    newCartFeatures = replaceInArrayOfObjects(
      {
        finder: "key",
        finderValue: PRODUCT_PRICES.certify_true_copy.type,
      },
      {
        name: PRODUCT_PRICES.certify_true_copy.name,
        key: PRODUCT_PRICES.certify_true_copy.type,
      },
      newCartFeatures
    );

    let newCartDetails = withCartValues?.cartDetails || [];
    newCartDetails = replaceInArrayOfObjects(
      {
        finder: "type",
        finderValue: PRODUCT_PRICES.certify_true_copy.type,
      },
      PRODUCT_PRICES.certify_true_copy,
      newCartDetails
    );

    setFieldValue("cartSummary.features", newCartFeatures);
    setFieldValue("cartDetails", newCartDetails);
  }, []);

  return (
    <FormLayout
      step={step}
      title="Certify True Copy"
      subtitle="Get your documents notarized cost-effectively and efficiently"
      {...props}
    >
      {/* {({ values, setFieldValue }) => {
        return (
          <Form> */}
      {values?.documents?.length > 0 && (
        <Box mt="4.8rem">
          <Table tableHeader={[{ name: "Document Name" }, { name: "Action" }]}>
            <tbody>
              {values?.documents?.map((doc, index) => (
                <tr key={index}>
                  <td>
                    <Text variant="bodyLarge" color="neutral.700">
                      {doc?.name}
                    </Text>
                  </td>
                  <td>
                    <Flex aI="center">
                      <Box
                        color="neutral.400"
                        css={{
                          "&:hover": {
                            color: "neutral.800",
                          },
                        }}
                      >
                        {/* eslint-disable-next-line react/jsx-no-target-blank */}
                        <a href={doc?.url} target={"_blank"}>
                          <MemoEyeShowIcon />
                        </a>
                      </Box>
                      <Box
                        ml="2.4rem"
                        color="neutral.400"
                        css={{
                          "&:hover": {
                            color: "neutral.800",
                          },
                        }}
                        onClick={() => {
                          let cloneDetails = JSON.parse(
                            JSON.stringify(values?.documents)
                          );
                          cloneDetails = cloneDetails?.filter(
                            (_, cloneIndex) => cloneIndex !== index
                          );
                          setFieldValue("documents", cloneDetails);
                        }}
                      >
                        <MemoDeleteIcon />
                      </Box>
                    </Flex>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Box>
      )}

      {!values?.documents?.length && (
        <UploadCart
          mt="4.8rem"
          onChange={(e) => {
            formUpload(e, "0", setFieldValue, (fieldData) => {
              setFieldValue("documents[0]", {
                type: fieldData?.name,
                name: fieldData?.name,
                url: fieldData?.url,
                time: fieldData?.uploadedAt,
              });
            });
          }}
        />
      )}

      {/* line */}
      <Box w="100%" h="0.5px" bg="neutral.200" mt="4.8rem" />
      <Button
        width="fit-content"
        px="4rem"
        mt="4rem"
        type="button"
        onClick={() => {
          validateForm({ ...values })?.then((dataErrorBag) => {
            const dataErrorBagKeys = Object?.keys(dataErrorBag || {});
            if (dataErrorBagKeys?.length) {
              setTouched(setNestedObjectValues(dataErrorBag, true));
              setErrors(dataErrorBag);
            } else {
              if (isLastStep) {
                onSubmit(values);
              }
              onNext();
            }
          });
        }}
      >
        NEXT : Payment
        {/* NEXT : Upload Documents */}
      </Button>

      {values?.documents?.length && (
        <UploadCart
          mt="4.8rem"
          onChange={(e) => {
            formUpload(e, "0", setFieldValue, (fieldData) => {
              setFieldValue(`documents[${values?.documents?.length}]`, {
                type: fieldData?.name,
                name: fieldData?.name,
                url: fieldData?.url,
                time: fieldData?.uploadedAt,
              });
            });
          }}
        />
      )}
      {/* </Form>
        );
      }} */}
    </FormLayout>
  );
};
