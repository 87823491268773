import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Box, Button, Flex, Grid, Line, Text } from "atoms";
import { Sheet } from "organisms";
import { MyCartCard } from "./MyCartCard";
import useService from "contexts/Service";
import { formatCurrency, manipIncorporationCartItem } from "utils/utilities";
import MemoEmptyCartIcon from "assets/icons/EmptyCartIcon";

const MycartData = [
  {
    id: "1",
    name: "Sevice name",
    description: "Sub Service name",
    money: 22,
  },
  {
    id: "2",
    name: "Sevice name",
    description: "Sub Service name",
    money: 530,
  },
  {
    id: "3",
    name: "Sevice name",
    description: "Sub Service name",
    money: 30,
  },
];

const scrollbar = {
  width: "100%",
  maxHeight: "25rem",
  overflowY: "scroll",

  direction: "ltr",

  "::-webkit-scrollbar": {
    width: "8px",
  },
  "::-webkit-scrollbar-track": {
    borderRadius: "10px",
    background: "#F5F5F5",
  },
  "::-webkit-scrollbar-thumb": {
    background: "#9D9D9D",
    borderRadius: "8px",
  },
};

export const CartDropDown = ({ isOpen, onClose, ...props }) => {
  const navigate = useNavigate();

  const {
    state: { cart },
  } = useService();

  return (
    <>
      <Sheet isOpen={isOpen} onClose={onClose} bg="rgba(0,0,0,0.1)">
        <Box
          minWidth="46.5rem"
          position="absolute"
          bg="white"
          right="-60%"
          top="195%"
          // right="56%"
          // top="174%"
          bR="1rem"
          boxShadow="m"
          zIndex="1000"
          pr="1.6rem"
          pl="1.6rem"
        >
          <Box
            top="-8px"
            w="3.8rem"
            h="3.8rem"
            position="absolute"
            left="calc(100% - 80px)"
            transform="rotate(45deg)"
            bg="white"
          />
          <Flex mt="4.5rem" aI="center" jC="space-between">
            <Text color="neutral.800" variant="bodyLarge" fontWeight="600">
              My Cart
            </Text>
            <Text
              color="primary.900"
              variant="bodySmall"
              fontWeight="600"
              cursor="pointer"
              onClick={() => {
                navigate("/cart");
                onClose();
              }}
            >
              See All
            </Text>
          </Flex>
          {/* <Text
            color="neutral.800"
            variant="bodyLarge"
            fontWeight="600"
            mt="4.5rem"
          >
            My Cart
          </Text> */}
          <Line mt="1.4rem" />
          {/* Mycart card */}
          <Box css={scrollbar}>
            {cart?.cartDetails?.length ? (
              <Grid gridAutoFlow="row" mt="0.8rem">
                {cart?.cartDetails?.map((item, index) => {
                  if (item?.serviceType === "incorporation") {
                    const newIncorporationItem =
                      manipIncorporationCartItem(item);
                    return (
                      <MyCartCard
                        key={index}
                        name={newIncorporationItem?.name}
                        description=""
                        money={formatCurrency(newIncorporationItem?.price)}
                      />
                    );
                  }
                  return (
                    <MyCartCard
                      key={index}
                      name={item?.cartDetails?.[0]?.name}
                      description=""
                      money={formatCurrency(item?.cartDetails?.[0]?.price)}
                    />
                  );
                })}
              </Grid>
            ) : (
              <Flex aI="center" jC="center" py="2.4rem" flexDirection="column">
                <MemoEmptyCartIcon width="8rem" height="8rem" />
                <Text
                  variant="bodyLarge"
                  color="neutral.700"
                  justifySelf="center"
                  ml="2.4rem"
                  textAlign="center"
                >
                  Looks like you have’t added anything in your cart. Go ahead
                  and explore Services
                </Text>
              </Flex>
            )}
          </Box>

          {cart?.eval > 0 && (
            <>
              <Line mt="1.2rem" />
              {/* <Flex jC="space-between" pl="2.4rem" pr="0.8rem" mt="1.4rem">
            <Text variant="bodyLarge" color="neutral.700">
              SubTotal
            </Text>
            <Text variant="bodyLarge" color="neutral.800" fontWeight="600">
              $660
            </Text>
          </Flex> */}
              <Flex jC="space-between" pl="2.4rem" pr="0.8rem" mt="1.6rem">
                <Text variant="bodyLarge" color="neutral.700" fontWeight="600">
                  Grand Total (tax included)
                </Text>
                <Text
                  variant="subtitleMedium"
                  color="neutral.800"
                  fontWeight="600"
                >
                  {formatCurrency(cart?.eval)}
                </Text>
              </Flex>
              <Button
                mt="4.8rem"
                w="fit-content"
                px="13rem"
                ml="5.3rem"
                mb="3.2rem"
                onClick={() => {
                  navigate("/cart");
                  onClose();
                }}
              >
                PAY {formatCurrency(cart?.eval)}
              </Button>
            </>
          )}
        </Box>
      </Sheet>
    </>
  );
};
