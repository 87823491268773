import React, { useCallback, useEffect, useMemo, useState } from "react";

import { Box, Button, Flex, Grid, Line, Loader, Modal, Text } from "atoms";
import MemoCross from "styles/icons/Cross";
import { LineLoader, Sheet } from "organisms";
import { DefaultChips, FormInput, ProfileAvatar, Tab } from "molecules";
import { Step } from "./Step";
import { Form, Formik } from "formik";
import { ChatCard } from "./ChatCard";
import { Link } from "react-router-dom";
import { OWNER_ROLE_CODE, STATUS, USER_ROLE_CODE } from "utils/constants";
import { format } from "date-fns";
import { CS_STEPS, INCORPORATION_STEPS } from "utils/service.steps";
import { getStatusChip } from "utils/utilities";
import MemoSelectArrowUpIcon from "assets/icons/SelectArrowUp";
import useService from "contexts/Service";

const tabData = [
  {
    title: "All",
    // count: 20,
  },
  {
    title: "Chat",
    // count: 2,
  },
];

const tabDataGeneral = [
  {
    title: "Chat",
    // count: 2,
  },
];

const chatData = [
  {
    avatar:
      "https://drive.google.com/uc?export=view&id=1bDKGjLainsIr4v_fFFACmKTNymleP4Qo",
    name: "Robert Fox",
    time: "34 min ago",
    chatContent: "Hi Admin! dolor sit amet, consectetur adipiscing elit.",
  },
  {
    avatar:
      "https://drive.google.com/uc?export=view&id=1bDKGjLainsIr4v_fFFACmKTNymleP4Qo",
    name: "Robert Fox",
    time: "34 min ago",
    chatContent: "Hi Admin! dolor sit amet, consectetur adipiscing elit.",
  },
  {
    avatar:
      "https://drive.google.com/uc?export=view&id=1bDKGjLainsIr4v_fFFACmKTNymleP4Qo",
    name: "Robert Fox",
    time: "34 min ago",
    chatContent: "Hi Admin! dolor sit amet, consectetur adipiscing elit.",
  },
];

const allowedStatus = [STATUS.inProgress, STATUS.pending, STATUS.completed];

export const ActionModal = ({ isOpen, onClose, isRole, taskId }) => {
  // update task
  const [isStatusDropdownOpen, setIsStatusDropdownOpen] = useState(false);
  const [task, setTask] = useState({});
  const [taskLoading, setTaskLoading] = useState(false);
  const {
    actions: { getTasksById, updateTask, addTaskComments },
  } = useService();

  const fetchTask = useCallback(async () => {
    setTaskLoading(true);
    getTasksById(taskId)
      .then((data) => {
        setTask(data?.data);
      })
      .finally(() => {
        setTaskLoading(false);
      });
  }, [getTasksById, taskId]);

  useEffect(() => {
    fetchTask();
  }, [fetchTask]);

  // update master task starts
  const updateMasterTask = useCallback(
    async (status) => {
      const reqData = {
        taskId: task?._id,
        status,
      };

      await updateTask(reqData);
      fetchTask();
    },
    [updateTask, task, fetchTask]
  );
  // update master task ends

  // update master task starts
  const addComments = useCallback(
    async ({ message }) => {
      await addTaskComments(
        task?._id,
        message,
        {
          [USER_ROLE_CODE]: task?.taskOwner?._id,
          [OWNER_ROLE_CODE]: task?.user?._id,
        }[isRole]
      );
      fetchTask();
    },
    [addTaskComments, task, fetchTask, isRole]
  );
  // update master task ends

  // update task ends

  const [activeTab, setActiveTab] = useState(0);

  const stepper = { cs: CS_STEPS, incorporation: INCORPORATION_STEPS }[
    task?.serviceName
  ];

  const nextStep = useMemo(() => {
    const nextStep =
      stepper?.find((step) => {
        return task?.serviceDetails?.[step?.statusFlag] === STATUS.created;
      }) ||
      stepper?.at(-1) ||
      {};

    return nextStep;
  }, [task, stepper]);

  const progress = useMemo(() => {
    const completeSteps =
      stepper?.filter?.(
        (step) =>
          task?.serviceDetails?.[step?.statusFlag] === STATUS.completed ||
          step?.status === STATUS.completed
      )?.length || 0;

    return stepper
      ? { filled: completeSteps, total: stepper?.length }
      : { filled: 0, total: 1 };
  }, [task, stepper]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      shouldCloseOnOverlayClick={true}
    >
      <Box
        py="2.4rem"
        minWidth="80.2rem"
        {...(taskLoading && { minHeight: "70rem" })}
      >
        {/* Close Button */}
        <Flex
          bg="neutral.100"
          color="neutral.900"
          w="fit-content"
          py="0.74rem"
          px="0.72rem"
          aI="center"
          borderRadius="100%"
          position="absolute"
          top="-1.3rem"
          right="-1.3rem"
          cursor="pointer"
          onClick={onClose}
        >
          <MemoCross />
        </Flex>

        <Loader loading={taskLoading}>
          <Box px="2.4rem">
            {/* header */}
            <Flex aI="center" jC="space-between">
              <Box>
                <Text
                  variant="subtitleSmall"
                  fontWeight="600"
                  {...(!stepper && { textTransform: "uppercase" })}
                >
                  {stepper ? nextStep?.title || "" : task?.serviceName}
                </Text>
                {stepper && (
                  <Text variant="bodySmall" color="neutral.600" mt="0.8rem">
                    {nextStep?.description || ""}
                  </Text>
                )}
              </Box>

              {stepper && (
                <Box w="9.6rem">
                  <Link
                    to={
                      {
                        [USER_ROLE_CODE]: `user/details/${task?._id}`,
                        [OWNER_ROLE_CODE]: `owner/details/${task?._id}`,
                      }[isRole]
                    }
                  >
                    <Button>View Task</Button>
                  </Link>
                </Box>
              )}

              {!stepper && isRole === OWNER_ROLE_CODE && (
                <Box w="12.6rem">
                  <Button
                    IconProps={{
                      Icon: MemoSelectArrowUpIcon,
                      IconBoxCss: {
                        transform: "rotate(180deg)",
                        mr: "0rem",
                        ml: "0.8rem",
                        height: "2.4rem",
                      },
                    }}
                    innerContainerProps={{
                      flexDirection: "row-reverse",
                    }}
                    onClick={() => setIsStatusDropdownOpen(true)}
                  >
                    {getStatusChip(task?.status?.name)?.title}
                  </Button>

                  {isStatusDropdownOpen && (
                    <Sheet
                      isOpen={isStatusDropdownOpen}
                      onClose={() => setIsStatusDropdownOpen(false)}
                      // bg="rgba(0,0,0,0.1)"
                    >
                      <Box
                        w="100%"
                        mt=".8rem"
                        // h="10rem"
                        // border="2px solid rgba(255, 136, 0, 1)"
                        bg="white"
                        color="secondary.700"
                        borderRadius="0.8rem"
                        boxShadow="m"
                        position="absolute"
                        zIndex={1000}
                      >
                        <Flex flexDirection="column" p="0.8rem">
                          {allowedStatus
                            ?.filter((status) => status !== task?.status?.name)
                            ?.map((status) => (
                              <Box
                                px="0.8rem"
                                borderRadius="0.4rem"
                                cursor="pointer"
                                css={{
                                  color: "neutral.600",
                                  "&:hover": {
                                    bg: "rgba(255, 136, 0, 0.16)",
                                    color: "rgba(255, 136, 0, 1)",
                                  },
                                }}
                                onClick={() => updateMasterTask(status)}
                              >
                                <Text variant="bodyLarge" color="inherit">
                                  {status}
                                </Text>
                              </Box>
                            ))}
                        </Flex>
                      </Box>
                    </Sheet>
                  )}
                </Box>
              )}
            </Flex>

            {/* loader */}
            {stepper && (
              <LineLoader {...progress} totalWidth="42.8rem" mt="1.6rem" />
            )}
          </Box>

          <Line mt="1.6rem" w="100%" />

          {/* service details */}
          <Grid
            py="4.8rem"
            px="2.4rem"
            gTC="repeat(2,1fr)"
            gRG="3.56rem"
            gCG="5.6rem"
          >
            <Flex aI="center">
              <Text variant="bodyLarge" fontWeight="600" color="neutral.500">
                Service:
              </Text>
              <Text
                variant="bodyLarge"
                fontWeight="600"
                color="neutral.700"
                textTransform="uppercase"
                ml="1.6rem"
              >
                {task?.serviceName}
              </Text>
            </Flex>
            <Flex aI="center">
              <Text variant="bodyLarge" fontWeight="600" color="neutral.500">
                Assignee:
              </Text>
              <Text
                variant="bodyLarge"
                fontWeight="600"
                color="neutral.700"
                ml="1.6rem"
              >
                {task?.taskOwner?.email}
              </Text>
              <ProfileAvatar
                name={task?.taskOwner?.email}
                // avatar="https://drive.google.com/uc?export=view&id=1c0W4Mjak0qH_Y978lvUbbXfxcARSkbSC"
                size="xs"
                avatarProps={{
                  border: "1px solid",
                  borderColor: "secondary.900",
                }}
                ml="0.8rem"
              />
            </Flex>
            {/* <Flex aI="center">
            <Text variant="bodyLarge" fontWeight="600" color="neutral.500">
              Due on:
            </Text>
            <Text
              variant="bodyLarge"
              fontWeight="600"
              color="neutral.700"
              ml="1.6rem"
            >
              Feb 3, 2022
            </Text>
          </Flex> */}
            <Flex aI="center">
              <Text variant="bodyLarge" fontWeight="600" color="neutral.500">
                Date Started:
              </Text>
              <Text
                variant="bodyLarge"
                fontWeight="600"
                color="neutral.700"
                ml="1.6rem"
              >
                {task?.createdAt &&
                  format(new Date(task?.createdAt), "MMM dd, yyyy")}
              </Text>
            </Flex>
            <Flex aI="center">
              <Text variant="bodyLarge" fontWeight="600" color="neutral.500">
                Status:
              </Text>
              <DefaultChips
                {...getStatusChip(task?.status?.name)}
                ml="1.6rem"
              />
            </Flex>
          </Grid>

          <Line mt="1.6rem" w="100%" />

          {/* tabs */}
          <Tab
            tabs={stepper ? tabData : tabDataGeneral}
            activeTab={activeTab}
            setActiveTab={(index) => setActiveTab(index)}
            mt="1.6rem"
          />

          {/* tab box */}
          {stepper
            ? {
                0: (
                  <>
                    {/* <Box mt="2.4rem" px="2.4rem">
                  <Text
                    variant="subtitleSmall"
                    fontWeight="600"
                    color="neutral.700"
                  >
                    Steps
                  </Text>
                  <Text variant="bodySmall" color="neutral.600">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </Text>
                </Box> */}

                    {/* steps */}
                    <Box mt="2.4rem" maxHeight="36rem" overflowY="scroll">
                      {/* singular step */}
                      {stepper &&
                        stepper?.map((step, index) => {
                          return (
                            <Step
                              key={index}
                              serialNumber={index + 1}
                              title={step?.title}
                              description={step?.description}
                              status={
                                step?.status ||
                                task?.serviceDetails?.[step?.statusFlag]
                              }
                              {...(index && { mt: "1.6rem" })}
                            />
                          );
                        })}
                    </Box>
                  </>
                ),
                1: (
                  <>
                    <Box
                      mt="2.4rem"
                      px="2.4rem"
                      maxHeight="40rem"
                      overflowY="scroll"
                    >
                      {/* title */}
                      <Box
                        position="sticky"
                        top="0rem"
                        background="white"
                        zIndex={1}
                      >
                        <Text
                          variant="subtitleSmall"
                          fontWeight="600"
                          color="neutral.700"
                        >
                          Chat with us
                        </Text>
                        <Text variant="bodySmall" color="neutral.600">
                          For quality conversations between merchants and
                          customers
                        </Text>
                      </Box>

                      {/* chat cards */}
                      {task?.comments?.map((chat, index) => (
                        <ChatCard
                          key={index}
                          // avatar={item?.avatar}
                          name={chat?.fromUserName || chat?.fromUserEmail}
                          time={chat?.createdAt}
                          chatContent={chat?.message}
                        />
                      ))}

                      {/* publish */}
                      <Box mt="4rem" position="sticky" bottom="0rem">
                        <Formik
                          initialValues={{ message: "" }}
                          onSubmit={(values) => addComments(values)}
                        >
                          {() => (
                            <Form>
                              <FormInput
                                name="message"
                                label="Type something here ...."
                                type="textarea"
                                wrap="hard"
                                height="15.5rem"
                              />
                              <Box
                                w="9.6rem"
                                position="absolute"
                                bottom="1.6rem"
                                right="1.6rem"
                              >
                                <Button type="submit">Publish</Button>
                              </Box>
                            </Form>
                          )}
                        </Formik>
                      </Box>
                    </Box>
                  </>
                ),
              }[activeTab]
            : {
                0: (
                  <>
                    <Box
                      mt="2.4rem"
                      px="2.4rem"
                      maxHeight="40rem"
                      overflowY="scroll"
                    >
                      {/* title */}
                      <Box
                        position="sticky"
                        top="0rem"
                        background="white"
                        zIndex={1}
                      >
                        <Text
                          variant="subtitleSmall"
                          fontWeight="600"
                          color="neutral.700"
                        >
                          Chat with us
                        </Text>
                        <Text variant="bodySmall" color="neutral.600">
                          For quality conversations between merchants and
                          customers
                        </Text>
                      </Box>

                      {/* chat cards */}
                      {task?.comments?.map((chat, index) => (
                        <ChatCard
                          key={index}
                          // avatar={item?.avatar}
                          name={chat?.fromUserName || chat?.fromUserEmail}
                          time={chat?.createdAt}
                          chatContent={chat?.message}
                        />
                      ))}

                      {/* publish */}
                      <Box mt="4rem" position="sticky" bottom="0rem">
                        <Formik
                          initialValues={{ message: "" }}
                          onSubmit={(values) => addComments(values)}
                        >
                          {() => (
                            <Form>
                              <FormInput
                                name="message"
                                label="Type something here ...."
                                type="textarea"
                                wrap="hard"
                                height="15.5rem"
                              />
                              <Box
                                w="9.6rem"
                                position="absolute"
                                bottom="1.6rem"
                                right="1.6rem"
                              >
                                <Button type="submit">Publish</Button>
                              </Box>
                            </Form>
                          )}
                        </Formik>
                      </Box>
                    </Box>
                  </>
                ),
              }[activeTab]}
        </Loader>
      </Box>
    </Modal>
  );
};
