import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Box, Button, Flex, Grid, Line, Loader, Text } from "atoms";
import { Breadcrum } from "atoms/Breadcrum";
import { CartCard } from "molecules";
import MemoLineArrowIcon from "assets/icons/LineArrow";
import {
  formatCurrency,
  formatDuration,
  manipIncorporationCartItem,
} from "utils/utilities";
import MemoShoppingBagIcon from "assets/icons/ShoppingBag";

// const bulletData = [
//   { id: "0", title: "Preparation & filing of annual return" },
//   {
//     id: "1",
//     title: "Appointment of company directors/company secretary/auditors",
//   },
//   { id: "2", title: "Change in officers' particulars" },
//   { id: "3", title: "And many more" },
// ];

export const Checkout = ({
  breadcrum,
  onPrevious,
  cart,
  removeItemFromCart,
  refreshList,
  total,
  onPay,
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  return (
    <>
      <Box
        w="100%"
        bg="white"
        boxShadow="m"
        my="1.6rem"
        px="10.4rem"
        pb="6.2rem"
        pt="4.4rem"
      >
        {breadcrum && (
          <Flex mb="5.6rem" aI="center">
            <Box
              color="neutral.800"
              transform="rotate(-90deg)"
              mr="2.4rem"
              cursor="pointer"
              onClick={onPrevious}
            >
              <MemoLineArrowIcon width="1.6rem" />
            </Box>
            <Breadcrum {...breadcrum} />
          </Flex>
        )}

        <Grid mt="5.6rem" gTC="2fr 1fr">
          {/* left section */}
          <Box pr="3.2rem" borderRight="0.5px solid" borderColor="neutral.200">
            {/* title */}
            <Flex aI="baseline">
              <Box width="2.5rem" height="2.5rem">
                <MemoShoppingBagIcon width="2.5rem" height="2.5rem" />
              </Box>
              <Text
                variant="headingSmall"
                fontWeight="600"
                color="neutral.800"
                ml=".8rem"
              >
                My cart:
              </Text>
              <Text ml="1rem" variant="headingLarge" color="neutral.800">
                {cart?.length || 0} items
              </Text>
            </Flex>
            <Line />
            {/* card */}
            <Loader loading={loading}>
              {cart?.map((item, index) => {
                // diff incorporation starts
                if (item?.serviceType === "incorporation") {
                  const newIncorporationItem = manipIncorporationCartItem(item);

                  return (
                    <CartCard
                      key={index}
                      title={newIncorporationItem?.name}
                      description=""
                      amount={formatCurrency(newIncorporationItem?.price)}
                      // duration={formatDuration(
                      //   item?.cartDetails?.[0]?.oneTime
                      //     ? "oneTime"
                      //     : item?.cartDetails?.[0]?.duration
                      // )}
                      onRemove={() => {
                        setLoading(true);
                        removeItemFromCart(
                          item?.serviceId,
                          item?.serviceType
                        ).finally(() => {
                          refreshList();
                          setLoading(false);
                        });
                      }}
                    />
                  );
                }
                // diff incorporation end
                return (
                  <CartCard
                    key={index}
                    title={item?.cartDetails?.[0]?.name}
                    description=""
                    amount={formatCurrency(item?.cartDetails?.[0]?.price)}
                    duration={formatDuration(
                      item?.cartDetails?.[0]?.oneTime
                        ? "oneTime"
                        : item?.cartDetails?.[0]?.duration
                    )}
                    onRemove={() => {
                      setLoading(true);
                      removeItemFromCart(
                        item?.serviceId,
                        item?.serviceType
                      ).finally(() => {
                        refreshList();
                        setLoading(false);
                      });
                    }}
                  />
                );
              })}
            </Loader>
          </Box>
          {/* right section */}
          <Box pl="4.8rem">
            <Box w="10.1rem" h="3.2rem">
              <Text variant="bodyLarge" fontWeight="600" color="neutral.600">
                Cart summary
              </Text>
            </Box>
            <Line mt="0.8rem" />
            {cart?.map((item, index) => {
              if (item?.serviceType === "incorporation") {
                const newIncorporationItem = manipIncorporationCartItem(item);
                return (
                  <Grid
                    key={index}
                    mt="2.4rem"
                    gridAutoFlow="column"
                    gCG="10rem"
                    width="100%"
                    jC="space-between"
                  >
                    <Text
                      variant="bodyLarge"
                      fontWeight="600"
                      color="neutral.600"
                    >
                      {newIncorporationItem?.name}
                    </Text>
                    <Flex flexDirection="column" aI="end" jC="center">
                      <Text
                        variant="bodyLarge"
                        fontWeight="600"
                        color="neutral.700"
                      >
                        {formatCurrency(newIncorporationItem?.price)}
                      </Text>
                      <Text variant="bodySmall" color="neutral.600">
                        {/* {formatDuration(
                          item?.cartDetails?.[0]?.oneTime
                            ? "oneTime"
                            : item?.cartDetails?.[0]?.duration,
                          true
                        )} */}
                      </Text>
                    </Flex>
                  </Grid>
                );
              }

              return (
                <Grid
                  key={index}
                  mt="2.4rem"
                  gridAutoFlow="column"
                  gCG="10rem"
                  width="100%"
                  jC="space-between"
                >
                  <Text
                    variant="bodyLarge"
                    fontWeight="600"
                    color="neutral.600"
                  >
                    {item?.cartDetails?.[0]?.name}
                  </Text>
                  <Flex flexDirection="column" aI="end" jC="center">
                    <Text
                      variant="bodyLarge"
                      fontWeight="600"
                      color="neutral.700"
                    >
                      {formatCurrency(item?.cartDetails?.[0]?.price)}
                    </Text>
                    <Text variant="bodySmall" color="neutral.600">
                      {formatDuration(
                        item?.cartDetails?.[0]?.oneTime
                          ? "oneTime"
                          : item?.cartDetails?.[0]?.duration,
                        true
                      )}
                    </Text>
                  </Flex>
                </Grid>
              );
            })}

            <Line mt="0.8rem" />
            <Box mt="2.4rem">
              {/* <Grid
                gridAutoFlow="column"
                // gridGap="28.2rem"
                gCG="10rem"
                width="100%"
                jC="space-between"
              >
                <Text variant="bodyLarge" color="neutral.700">
                  Subtotal
                </Text>
                <Text variant="bodyLarge" color="neutral.800">
                  $660
                </Text>
              </Grid> */}
              <Grid
                // mt="1.6rem"
                gridAutoFlow="column"
                // gridGap="15.3rem"
                gCG="10rem"
                width="100%"
                jC="space-between"
              >
                <Text
                  variant="bodyLarge"
                  fontWeight="600"
                  color="neutral.800"
                  css={{ whiteSpace: "nowrap" }}
                >
                  Grand Total (tax included)
                </Text>
                <Text
                  variant="subtitleMedium"
                  fontWeight="600"
                  color="neutral.800"
                >
                  {formatCurrency(total)}
                </Text>
              </Grid>
            </Box>
            <Button
              mt="4.8rem"
              loading={submitting}
              onClick={() => {
                setSubmitting(true);
                onPay().finally(() => {
                  setSubmitting(false);
                });
              }}
            >
              PAY {formatCurrency(total)}
            </Button>
          </Box>
        </Grid>
      </Box>
    </>
  );
};
