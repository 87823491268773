import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box, Button, Flex, Grid, Line, Loader, Text } from "atoms";
import { DefaultChips, StepperTab } from "molecules";
import { ChatModal, FormDetails } from "../components";
import {
  FormAcknowledgment,
  FormACRA,
  FormCompanyVerification,
  FormDocumentCreation,
  FormDocumentSigning,
  FormPostIncorporation,
  FormPreIncorporation,
} from "./components";
import { UserDetails } from "../types";
import SERVICE_TYPE_CODE from "utils/services.type.constants";
import { CS_STEPS, INCORPORATION_STEPS } from "utils/service.steps";
import { getStatusChip } from "utils/utilities";
import useService from "contexts/Service";
import { useNavigate, useParams } from "react-router-dom";
import { STATUS, USER_ROLE_CODE } from "utils/constants";
import MemoChatIcon from "assets/icons/Chat";

export const User = () => {
  const {
    actions: { getTasksById, addTaskComments },
  } = useService();
  const navigate = useNavigate();
  const chatButtonRef = useRef();
  const [isChatModalVisible, setIsChatModalVisible] = useState(false);

  const { taskId } = useParams();

  const [task, setTask] = useState({});
  const [loading, setLoading] = useState(false);

  const fetchTask = useCallback(async () => {
    setLoading(true);
    getTasksById(taskId)
      .then((data) => {
        setTask(data?.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [getTasksById, taskId]);

  useEffect(() => {
    fetchTask();
  }, [fetchTask]);

  const [activeStep, setActiveStep] = useState(0);
  const stepper = { cs: CS_STEPS, incorporation: INCORPORATION_STEPS }[
    task?.serviceName
  ];

  useEffect(() => {
    if (
      task?.serviceName === "accounting" ||
      task?.serviceName === "ev" ||
      task?.serviceName === "tax"
    ) {
      navigate("/query-list/mytask");
    }
  }, [task]);

  const activeStepHandler = useCallback(
    (newStep) => {
      if (newStep === 0) {
        setActiveStep(newStep);
      }

      if (
        task?.serviceDetails[stepper?.[newStep - 1]?.statusFlag] ===
          STATUS.completed ||
        stepper?.[newStep - 1]?.status === STATUS.completed
      ) {
        setActiveStep(newStep);
      }

      if (newStep >= stepper?.length) {
        setActiveStep(0);
      }
    },
    [stepper, task]
  );

  return (
    <Loader loading={loading}>
      <Box
        h="100%"
        bg="white"
        py="3.2rem"
        px="2.4rem"
        ml="3.2rem"
        mt="2.4rem"
        mr="3.2rem"
        bR=".8rem"
      >
        <Flex jC="space-between">
          <Box>
            <Flex>
              <Text
                variant="subtitleLarge"
                color="neutral.700"
                fontWeight="600"
              >
                {task?.serviceName?.toUpperCase()}
                {task?.serviceName === "cs" &&
                  ` (${
                    SERVICE_TYPE_CODE.CORPORATE_SECRETARIAL?.[
                      task?.serviceDetails?.serviceType
                    ]?.title
                  })`}
              </Text>
              <DefaultChips
                {...getStatusChip(task?.status?.name)}
                ml="2.4rem"
              />
            </Flex>
            {/* <Text color="neutral.600" variant="bodySmall" mt=".8rem">
              Description Text
            </Text> */}
          </Box>
          <Button
            w="fit-content"
            px="4rem"
            onClick={() =>
              // setActiveStep((pre) => (pre + 1 >= stepper?.length ? 0 : pre + 1))
              // activeStep >= stepper?.length
              //   ? navigate("/query-list/mytask")
              //   : activeStepHandler(activeStep + 1)
              activeStep + 1 < stepper?.length
                ? activeStepHandler(activeStep + 1)
                : navigate("/query-list/mytask")
            }
            disabled={
              stepper?.[activeStep]?.status !== STATUS.completed &&
              task?.serviceDetails?.[stepper?.[activeStep]?.statusFlag] !==
                STATUS.completed
            }
          >
            {activeStep + 1 < stepper?.length
              ? `Next : ${stepper[activeStep + 1]?.title}`
              : "Go To Tasks"}
          </Button>
        </Flex>
        <Line mt="2.4rem" mb="3.8rem" />
        {/* User Details */}
        <Grid gTC="16.25% 1fr" gCG="2.4rem" width="100%">
          <StepperTab
            steps={stepper}
            serviceDetails={task?.serviceDetails}
            activeStep={activeStep}
            setActiveStep={activeStepHandler}
          />

          {
            {
              cs: (
                <>
                  {
                    {
                      0: (
                        <UserDetails
                          serviceTypeCode={task?.serviceDetails?.serviceType}
                          serviceDetails={task?.serviceDetails}
                        />
                      ),
                      1: (
                        <FormDocumentCreation
                          serviceDetails={task?.serviceDetails}
                          statusFlag={stepper?.[activeStep]?.statusFlag}
                        />
                      ),
                      2: (
                        <FormDocumentSigning
                          serviceDetails={task?.serviceDetails}
                          statusFlag={stepper?.[activeStep]?.statusFlag}
                        />
                      ),
                      3: (
                        <FormACRA
                          serviceDetails={task?.serviceDetails}
                          statusFlag={stepper?.[activeStep]?.statusFlag}
                          statusLinkFlag={"preCsAcraLink"}
                        />
                      ),
                    }[activeStep]
                  }
                </>
              ),
              incorporation: (
                <>
                  {
                    {
                      0: (
                        <FormDetails
                          serviceDetails={task?.serviceDetails}
                          statusFlag={stepper?.[activeStep]?.statusFlag}
                        />
                      ),
                      1: (
                        <FormCompanyVerification
                          serviceDetails={task?.serviceDetails}
                          statusFlag={stepper?.[activeStep]?.statusFlag}
                        />
                      ),
                      2: (
                        <FormPreIncorporation
                          serviceDetails={task?.serviceDetails}
                          statusFlag={stepper?.[activeStep]?.statusFlag}
                        />
                      ),
                      3: (
                        <FormDocumentSigning
                          serviceDetails={task?.serviceDetails}
                          statusFlag={stepper?.[activeStep]?.statusFlag}
                        />
                      ),
                      4: (
                        <FormAcknowledgment
                          serviceDetails={task?.serviceDetails}
                          statusFlag={stepper?.[activeStep]?.statusFlag}
                          statusLinkFlag={"preIncorporationAcraLink"}
                        />
                      ),
                      5: (
                        <FormPostIncorporation
                          serviceDetails={task?.serviceDetails}
                          statusFlag={stepper?.[activeStep]?.statusFlag}
                        />
                      ),
                      6: (
                        <FormDocumentSigning
                          serviceDetails={task?.serviceDetails}
                          statusFlag={stepper?.[activeStep]?.statusFlag}
                        />
                      ),
                      7: (
                        <FormACRA
                          serviceDetails={task?.serviceDetails}
                          statusFlag={stepper?.[activeStep]?.statusFlag}
                          statusLinkFlag={"postIncorporationAcraLink"}
                        />
                      ),
                    }[activeStep]
                  }
                </>
              ),
            }[task?.serviceName]
          }
        </Grid>
      </Box>
      <Box h="2rem" />
      <Box position="absolute" left="90%" top="calc(100vh - 22.8rem)">
        <Flex
          position="fixed"
          width="5.5rem"
          height="5.5rem"
          bg="primary.900"
          borderRadius="100%"
          aI="center"
          jC="center"
          onClick={() => setIsChatModalVisible(task?._id)}
          ref={chatButtonRef}
        >
          <MemoChatIcon width="5rem" height="5rem" />
        </Flex>
      </Box>
      {isChatModalVisible && (
        <ChatModal
          isOpen={isChatModalVisible}
          position={chatButtonRef?.current?.getBoundingClientRect()}
          onClose={() => setIsChatModalVisible(false)}
          isRole={USER_ROLE_CODE}
          taskId={isChatModalVisible}
        />
      )}
    </Loader>
  );
};
