import * as React from "react";

function BxListCheck(props) {
  return (
    <svg
      width="18"
      height="14"
      viewBox="0 0 18 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0.429688H11.6471V2.54733H0V0.429688ZM0 4.66498H11.6471V6.78263H0V4.66498ZM0 8.90028H7.41176V11.0179H0V8.90028ZM16.1994 6.0332L11.6464 10.5766L10.2784 9.20967L8.78125 10.7079L11.6464 13.571L17.6944 7.5325L16.1994 6.0332Z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoBxListCheckIcon = React.memo(BxListCheck);
export default MemoBxListCheckIcon;
