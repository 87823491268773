const FORM_NAMES = {
  FormActivityCode: "Form Activity Code",
  FormAddDirector: "Form Add Director",
  FormAddShareholder: "Form Add Shareholder",
  FormAnnualFilingDetails: "Form Annual Filing Details",
  FormChangeCompanyAddress: "Form Change Company Address",
  FormCorporateSecretaryDetails: "Form Corporate Secretary Details",
  FormCWCorporateSectretarialServices:
    "Form CW Corporate Sectretarial Services",
  FormDetails: "Form Details",
  FormDirectorDetails: "Form Director Details",
  FormLayout: "Form Layout",
  FormRemoveDirector: "Form Remove Director",
  FormRemoveShareholder: "Form Remove Shareholder",
  FormShareholderDetails: "Form Shareholder Details",
  FormUpdateAddress: "Form Update Address",
  FormUpdateDirector: "Form Update Director",
  FormUpdateShareholder: "Form Update Shareholder",
  FormNomineeShareholderDetails: "Form Nominee Shareholder Details",
  FormTransferShares: "Form Transfer Shares",
  FormCertifyTrueCopy: "Form Certify True Copy",
  Cart: "cart",
  FormNewCorporateSecretaryDetails: "Form New Corporate Secretary Details",
};

export default FORM_NAMES;
