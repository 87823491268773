import React, { useCallback, useMemo } from "react";
import styled from "styled-components";
import { variant } from "styled-system";
import { Box, Flex, Text } from "atoms";
import {
  useLocationQueryAdd,
  useLocationQueryValue,
} from "hooks/useLocationQuery";
import MemoSelectArrowUpIcon from "assets/icons/SelectArrowUp";

const StyledTable = styled.table`
  border-collapse: collapse;
  width: 100%;

  ${variant({
    variants: {
      primary: {
        tr: {
          height: "7.6rem",
        },

        td: {
          height: "8.6rem",
          p: "3.2rem 4rem",
          borderTop: "1px solid",
          borderBottom: "1px solid",
          borderColor: "neutral.100",
        },

        th: {
          zIndex: 1,
          p: "3.2rem 4rem",
          height: "8.6rem",
          textTransform: "uppercase",
          backgroundColor: "neutral.50",
          borderBottom: "1px solid",
          borderColor: "neutral.100",
        },
      },
      secondary: {
        tr: {
          height: "7.6rem",
        },

        td: {
          height: "8.6rem",
          p: "1.2rem 2.4rem",
          borderTop: "1px solid",
          borderBottom: "1px solid",
          borderColor: "neutral.100",
        },

        th: {
          zIndex: 1,
          p: "1.2rem 2.4rem",
          height: "8.6rem",
          textTransform: "uppercase",
          // backgroundColor: "transparent",
          borderBottom: "1px solid",
          borderColor: "neutral.100",
        },
      },
    },
  })}
`;

export const Table = ({
  children,
  variant = "primary",
  tableHeader,
  containerProps,
  ...props
}) => {
  const sortOrder = useLocationQueryValue("sort");
  const setParam = useLocationQueryAdd();

  const isDesc = useMemo(() => !!(sortOrder?.[0] === "-"), [sortOrder]);
  const sort = useMemo(
    () => (isDesc ? sortOrder.slice(1) : sortOrder),
    [sortOrder, isDesc]
  );

  const onSort = useCallback(
    (sortKey) => () => {
      if (sortKey) {
        setParam("sort", !isDesc && sortKey === sort ? `-${sortKey}` : sortKey);
        // setParam("page", "1");
      }
    },
    [sort, setParam, isDesc]
  );

  return (
    <Box
      overflowX={{ xs: "scroll", lg: "unset" }}
      maxWidth="calc(100vw - 6.8rem)"
      {...containerProps}
    >
      <StyledTable variant={variant} as="table" {...props}>
        {tableHeader && (
          <thead>
            <tr>
              {tableHeader.map((t) => (
                <Box
                  as="th"
                  key={t.name}
                  onClick={onSort(t.sortKey)}
                  cursor={t.sortKey ? "pointer" : undefined}
                  {...t.containerProps}
                >
                  <Flex aI="center" jC="left">
                    <Text
                      variant={
                        { primary: "subtitleSmall", secondary: "bodyLarge" }[
                          variant
                        ]
                      }
                      fontWeight={{ primary: "600", secondary: "400" }[variant]}
                      color={
                        { primary: "neutral.800", secondary: "neutral.700" }[
                          variant
                        ]
                      }
                      {...(variant === "secondary" && {
                        textShadow:
                          "-1px -1px 0 #E9E9E9, 1px -1px 0 #E9E9E9, -1px 1px 0 #E9E9E9, 1px 1px 0 #E9E9E9",
                      })}
                    >
                      {t.name}
                    </Text>

                    {t.sortKey ? (
                      <Box ml="1.6rem">
                        {t.sortKey === sort ? (
                          <Box
                            transition="all 0.1s"
                            color="primary.900"
                            transform={
                              isDesc
                                ? "rotate(180deg) translateY(-3px)"
                                : undefined
                            }
                          >
                            <MemoSelectArrowUpIcon width=".9rem" />
                          </Box>
                        ) : (
                          <Box>
                            <Box color="primary.900">
                              <MemoSelectArrowUpIcon width=".9rem" />
                            </Box>
                            <Box transform="rotate(180deg)" color="primary.900">
                              <MemoSelectArrowUpIcon width=".9rem" />
                            </Box>
                          </Box>
                        )}
                      </Box>
                    ) : null}
                  </Flex>
                </Box>
              ))}
            </tr>
          </thead>
        )}
        {children}
      </StyledTable>
    </Box>
  );
};
