import React from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "atoms";
import { Form } from "formik";
import { FormManagerLayout } from "templates/CorporateSecretarial/FormManager";
import { FormXeroAccounting } from "../components";
const breadcrum = {
  firstText: "Accounting",
  secondText: "Step 1",
};

export const XeroAccounting = () => {
  const navigate = useNavigate();
  return (
    <>
      <FormManagerLayout
        tabHeader={{
          title: "Xero accounting sofware implementation",
          breadcrum: breadcrum,
        }}
        formikProps={{
          initialValues: {
            step: 1,
          },
        }}
      >
        {({ values, setFieldValue }) => {
          return (
            <Form>
              <Box w="100%">
                <FormXeroAccounting
                  step={`Step ${values.step}`}
                  onNext={() => navigate("/cart")}
                />
              </Box>
            </Form>
          );
        }}
      </FormManagerLayout>
    </>
  );
};
