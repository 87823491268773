import React from "react";
import { Box, Button, Grid } from "atoms";
import { FormInput } from "molecules";
import FormLayout from "../FormLayout";
import FORM_NAMES from "../form.names";
import { setNestedObjectValues } from "formik";

export const FormCorporateSecretaryDetails = ({
  step,
  onNext,
  values,
  validateForm,
  setTouched,
  setErrors,
  errors,
  touched,
  ...props
}) => {
  return (
    <FormLayout
      step={step}
      onNext={onNext}
      title="Kindly provide the details of your previous Corporate Secretarial"
      subtitle="According to Singapore’s Companies Act, all Singaporean incorporated companies cannot leave the secretary position vacant for more than 30 days at a time"
      {...props}
    >
      {/* {({ values }) => {
        return (
          <Form> */}
      {/* input */}
      <Grid gTC="1fr 1fr" gridGap="1.6rem" mt="4.8rem">
        <FormInput
          name="previousCorporateSecretaryName"
          label="Corporate Secretary Full Name"
        />
        <FormInput
          name="previousCorporateSecretaryEmail"
          label="Corporate Secretary Email"
        />
      </Grid>
      {/* line */}
      <Box w="100%" h="0.5px" bg="neutral.200" mt="4.8rem" />
      <Button
        type="button"
        width="fit-content"
        px="4rem"
        mt="4rem"
        onClick={() => {
          validateForm({
            previousCorporateSecretaryName:
              values?.previousCorporateSecretaryName,
            previousCorporateSecretaryEmail:
              values?.previousCorporateSecretaryEmail,
          })?.then((dataErrorBag) => {
            if (
              dataErrorBag?.previousCorporateSecretaryName ||
              dataErrorBag?.previousCorporateSecretaryEmail
            ) {
              setTouched(
                setNestedObjectValues(
                  {
                    previousCorporateSecretaryName:
                      dataErrorBag?.previousCorporateSecretaryName,
                    previousCorporateSecretaryEmail:
                      dataErrorBag?.previousCorporateSecretaryEmail,
                  },
                  true
                )
              );
              setErrors(dataErrorBag);
            } else {
              values?.usingCWCorporateSecretarialServices
                ? onNext(FORM_NAMES.FormDetails, true)
                : onNext(FORM_NAMES.FormNewCorporateSecretaryDetails, true);
            }
          });
        }}
      >
        {values?.usingCWCorporateSecretarialServices
          ? "NEXT : Upload Documents"
          : "NEXT : New Corporate Secretarial Details"}
      </Button>
      {/* </Form>
        );
      }} */}
    </FormLayout>
  );
};
