import React, { useState } from "react";
import MemoPen from "assets/icons/Pen";
import { Box, Button, Flex, Grid, Text } from "atoms";
import { FormInput, ProfileAvatar, FormPhoneInput } from "molecules";
import { ResetPasswordModal } from "../ResetPasswordModal";
import MemoGoogleColorLogo from "assets/icons/GoogleColorLogo";

export const ProfileDetails = ({
  firstName,
  lastName,
  avatar,
  email,
  setProfilePicture,
  isImageUploading,
  onSubmitResetPassword,
}) => {
  const [isResetpasswordModal, setIsResetpasswordModal] = useState(false);

  return (
    <>
      <Flex>
        {/* Profile card section [Left card] */}
        <Flex
          flexDirection="column"
          aI="center"
          w="23%"
          bg="white"
          bR="0.8rem"
          pt="3.2rem"
          mr="2.4rem"
        >
          <Box
            bR="50%"
            border="0.5px dashed"
            borderColor="neutral.200"
            p=".6rem"
          >
            {/* User Details */}
            <ProfileAvatar
              size="l"
              name={`${firstName || ""} ${lastName || ""}`}
              avatar={avatar}
              isLoading={isImageUploading}
            />
            {/* <Box
              w="3.2rem"
              h="3.2rem"
              bR="100%"
              position="absolute"
              top="6.6rem"
              left="7.4rem"
              bg="secondary.700"
              color="white"
              // p="0.9rem 1rem"
            > */}
            <Button
              as="label"
              type="button"
              cursor="pointer !important"
              w="3.2rem !important"
              h="3.2rem !important"
              borderRadius="100% !important"
              position="absolute !important"
              bottom="10%"
              right="-10%"
              p="0.9rem 1rem"
              display="flex !important"
              alignItems="center !important"
              justifyContent="center !important"
              IconProps={{ Icon: MemoPen, IconCss: {}, IconBoxCss: {} }}
              innerContainerProps={{ pl: "0.8rem", pT: "0.4rem" }}
            >
              <input
                name="logo"
                type="file"
                value={null}
                accept="image/png, image/jpeg, image/jpg"
                onChange={setProfilePicture}
                style={{ display: "none" }}
              />
              {/* <MemoPen width="1.3rem" height="1.3rem" /> */}
            </Button>
            {/* </Box> */}
          </Box>
          <Text
            variant="subtitleSmall"
            color="neutral.700"
            fontWeight="600"
            mt="1.6rem"
          >
            {`${firstName || ""} ${lastName || ""}`}
          </Text>
          <Text variant="bodyMedium" color="neutral.500">
            {email || ""}
          </Text>
          {/* Social Icon */}
          {/* <Flex mt="1.8rem" pb="3.2rem">
            <MemoGoogleColorLogo width="2.4rem" height="2.4rem" />
          </Flex> */}
        </Flex>
        {/* Profile Details Card [Right card] */}
        <Box w="77%" bg="white" bR="0.8rem" p="2rem 3.2rem">
          <Grid gCG="2.4rem" gRG="2.4rem" gTC="1fr 1fr" h="100%" aI="center">
            {/* first name */}
            <FormInput
              name="firstName"
              label="First Name"
              isValidationBehaviour={false}
            />
            {/* last name */}
            <FormInput
              name="lastName"
              label="Last Name"
              isValidationBehaviour={false}
            />
            {/* email */}
            <FormInput
              name="email"
              label="Email"
              disabled
              isValidationBehaviour={false}
            />
            {/* phone number */}
            <FormPhoneInput
              name="phoneNumber"
              label="Phone Number"
              isValidationBehaviour={false}
            />
            {/* password */}
            <Flex>
              <FormInput
                name="password"
                label="Password"
                type="password"
                disabled
                isValidationBehaviour={false}
              />
              <Button
                ml="0.8rem"
                w="13.5rem"
                type="button"
                onClick={() => setIsResetpasswordModal(true)}
              >
                Change
              </Button>
              {/* Reset Password Modal */}
              {isResetpasswordModal && (
                <ResetPasswordModal
                  isOpen={isResetpasswordModal}
                  onSubmitModal={onSubmitResetPassword}
                  onClose={() => {
                    setIsResetpasswordModal(false);
                  }}
                />
              )}
            </Flex>
          </Grid>
        </Box>
      </Flex>
    </>
  );
};
