import React from "react";

import { Box, Grid, Text } from "atoms";

export const ShareholderRemove = ({ shareholder, ...props }) => {
  return (
    <Box {...props}>
      <Grid gTC="repeat(2,1fr)" gCG="15rem" mt="2.4rem">
        {/*  */}
        <Text variant="bodyLarge" color="neutral.600">
          Name
        </Text>
        <Text variant="bodyLarge" color="neutral.600">
          Email
        </Text>

        {/*  */}
        <Text variant="subtitleSmall" fontWeight="600">
          {shareholder?.name}
        </Text>
        <Text variant="subtitleSmall" fontWeight="600">
          {shareholder?.email}
        </Text>
      </Grid>
    </Box>
  );
};
