import React from "react";
import { Box, Button, Flex, Line, Text } from "atoms";
import MemoTickIcon from "assets/icons/Tick";

export const OtherServiceCard = ({ title, price, bulletData, ...props }) => {
  return (
    <>
      <Box
        bR="0.8rem"
        border="1px solid"
        borderColor="neutral.300"
        pt="3.2rem"
        {...props}
      >
        <Flex jC="space-between" px="2.4rem">
          <Text color="neutral.700" variant="subtitleSmall" fontWeight="600">
            {title}
          </Text>
          <Text color="neutral.800" variant="subtitleMedium" fontWeight="700">
            {price}
          </Text>
        </Flex>
        {bulletData &&
          bulletData.map((index) => {
            return (
              <Flex key={index} mt="1.1rem" px="2.4rem">
                <Box color="success.700" mr="1.3rem" py=".6rem">
                  <MemoTickIcon width="1rem" height=".8rem" />
                </Box>
                <Text color="neutral.600" variant="bodyMedium">
                  {index?.title}
                </Text>
              </Flex>
            );
          })}
        <Line mt="3.2rem" mb="1.6rem" />
        <Box px="2.4rem" mb="2.4rem">
          <Button px="20rem">Select Plan</Button>
        </Box>
      </Box>
    </>
  );
};
