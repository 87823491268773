import React from "react";
import { Box, Breadcrum, Flex, Text } from "atoms";
import MemoLineArrowIcon from "assets/icons/LineArrow";
import { CartSummary } from "organisms";

export const FormLayout = ({
  breadcrum,
  step,
  title,
  subtitle,
  children,
  onPrevious,
  cartDetails,
  ...props
}) => {
  return (
    <>
      <CartSummary
        position="absolute"
        top="-11.6rem"
        right="0rem"
        zIndex="100"
        features={cartDetails || []}
      />
      <Box bg="white" w="100%" py="4rem" px="6.4rem" {...props}>
        {breadcrum && (
          <Flex mb="2.4rem" aI="center">
            <Box
              color="neutral.800"
              transform="rotate(-90deg)"
              mr="0.4rem"
              cursor="pointer"
              onClick={onPrevious}
            >
              <MemoLineArrowIcon width="1.6rem" />
            </Box>
            {/* <Breadcrum firstText="Go Back" /> */}
            <Breadcrum {...breadcrum} />
          </Flex>
        )}
        {/* step */}
        {step && (
          <Text variant="subtitleSmall" color="neutral.500">
            {step}
          </Text>
        )}
        {/* title */}
        <Text
          variant="headingLarge"
          fontWeight="700"
          color="neutral.700"
          mt="2.4rem"
        >
          {title}
        </Text>
        <Text variant="subtitleSmall" color="neutral.500" mt="1.6rem">
          {subtitle}
        </Text>
        {/* input */}
        {/* <Formik {...formikProps}>{children}</Formik> */}
        {children}
      </Box>
    </>
  );
};
