export const PAGE_SIZE = 10;
export const OWNER_ROLE_CODE = "taskowner";
export const USER_ROLE_CODE = "user";

export const RESPONSE = {
  success: "SUCCESS",
  failure: "FAILURE",
  expired: "EXPIRED",
};

export const STORAGE = {
  INCOPORATION_FORM_STORAGE: "cw.incorporation.form.data",
  FORM_NAVIGATION_MANGER: "formNavigationManager",
};

// export const CART = {
//   // feature key : feature price
//   CW_ADDRESS_SERVICE: {
//     price: "$ 230",
//     unit: "$",
//     value: 230
//   },
// };

export const STATUS = {
  created: "created",
  inProgress: "in-progress",
  pending: "pending",
  completed: "completed",
  overdue: "overdue",
};

export const FILE_TYPES =
  "image/*, .doc, .docx, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, .xlsx, .zip";
