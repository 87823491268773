/* eslint-disable no-unneeded-ternary */
import React from "react";
import { Grid, Text } from "atoms";
import { Link } from "react-router-dom";

const BreadcrumText = ({ text, isLink }) => (
  <Text
    variant="bodyMedium"
    my={0}
    px="3px"
    color="neutral.800"
    textDecoration={isLink ? "underline" : "initial"}
    lineHeight="unset"
  >
    {text}
  </Text>
);

const BreadcrumItem = ({ url, text }) =>
  url ? (
    <Link to={url}>
      <BreadcrumText text={text} isLink={!!url} />
    </Link>
  ) : (
    <BreadcrumText text={text} isLink={!!url} />
  );

export const Breadcrum = ({
  firstUrl,
  firstText,
  secondUrl,
  secondText,
  thirdText,
  thirdUrl,
  fourthText,
}) => {
  return (
    <Grid gridAutoFlow="column" jC="start" aI="center">
      <BreadcrumItem text={firstText} url={firstUrl} />
      {secondText && (
        <>
          <Text variant="bodyMedium" my={0} px="3px" color="neutral.800">
            /
          </Text>
          <BreadcrumItem text={`${secondText}`} url={secondUrl} />
        </>
      )}
      {thirdText && (
        <>
          <Text variant="bodyMedium" my={0} px="3px" color="neutral.800">
            /
          </Text>{" "}
          <BreadcrumItem text={`${thirdText}`} url={thirdUrl} />
        </>
      )}
      {fourthText && (
        <>
          <Text variant="bodyMedium" my={0} px="3px" color="neutral.800">
            /
          </Text>{" "}
          <BreadcrumText text={`${fourthText}`} />
        </>
      )}
    </Grid>
  );
};
