import React from "react";

import MemoUploadIcon from "assets/icons/Upload";
import { Button } from "atoms";
import { UploadBox } from "molecules";
import { FILE_TYPES } from "utils/constants";

export const Upload = ({ url, name, time, onClose, loading, onChange }) => {
  return url ? (
    <UploadBox name={name} date={time} onClose={onClose} />
  ) : (
    <Button
      as="label"
      type="button"
      IconProps={{ Icon: MemoUploadIcon }}
      w="22.3rem"
      loading={loading}
    >
      <input
        name="logo"
        type="file"
        value={null}
        accept={FILE_TYPES}
        onChange={onChange}
        style={{ display: "none" }}
      />
      Upload
    </Button>
  );
};
