import React from "react";

import { Box, Flex, Text } from "atoms";
import MemoTickWithCircleIcon from "assets/icons/TickWithCircle";
import { toast } from "react-toastify";
import MemoExclaimationIcon from "assets/icons/Exclaimation";
import MemoQuestionMarkIcon from "assets/icons/QuestionMark";

import "./toast.notification.css";
import MemoLightBulbIcon from "assets/icons/LightBulb";
import MemoCrossWithCircleIcon from "assets/icons/CrossWithCircle";

const SuccessToast = ({ title, description }) => {
  return (
    <Flex bg="white" aI="center">
      <Box w="0.3rem" alignSelf="stretch" bg="success.700" bR="0.4rem" />
      <Box color="success.700" ml="1.6rem">
        <MemoTickWithCircleIcon width="1.8rem" height="1.8rem" />
      </Box>
      <Box ml="1.6rem">
        {title && (
          <Text variant="bodyLarge" fontWeight="600">
            {title}
          </Text>
        )}
        {description && (
          <Text variant="bodySmall" color="neutral.600">
            {description}
          </Text>
        )}
      </Box>
    </Flex>
  );
};
const InfoToast = ({ title, description }) => {
  return (
    <Flex bg="white" aI="center">
      <Box w="0.3rem" alignSelf="stretch" bg="primary.500" bR="0.4rem" />
      <Box color="primary.500" ml="1.6rem">
        <MemoQuestionMarkIcon width="1.8rem" height="1.8rem" />
      </Box>
      <Box ml="1.6rem">
        {title && (
          <Text variant="bodyLarge" fontWeight="600">
            {title}
          </Text>
        )}
        {description && (
          <Text variant="bodySmall" color="neutral.600">
            {description}
          </Text>
        )}
      </Box>
    </Flex>
  );
};

const WarningToast = ({ title, description }) => {
  return (
    <Flex bg="white" aI="center">
      <Box w="0.3rem" alignSelf="stretch" bg="secondary.500" bR="0.4rem" />
      <Box color="secondary.500" ml="1.6rem">
        <MemoExclaimationIcon width="1.8rem" height="1.8rem" />
      </Box>
      <Box ml="1.6rem">
        {title && (
          <Text variant="bodyLarge" fontWeight="600">
            {title}
          </Text>
        )}
        {description && (
          <Text variant="bodySmall" color="neutral.600">
            {description}
          </Text>
        )}
      </Box>
    </Flex>
  );
};

const NotificationToast = ({ title }) => {
  return (
    <Flex bg="primary.700" aI="center">
      <Box color="secondary.500" ml="1.6rem">
        <MemoLightBulbIcon width="3rem" height="3rem" />
      </Box>
      <Box ml="1.6rem">
        {title && (
          <Text variant="bodyLarge" fontWeight="600" color="neutral.50">
            {title}
          </Text>
        )}
      </Box>
    </Flex>
  );
};
const ErrorToast = ({ title, description }) => {
  return (
    <Flex bg="white" aI="center">
      <Box w="0.3rem" alignSelf="stretch" bg="error.900" bR="0.4rem" />
      <Box color="error.900" ml="1.6rem">
        <MemoCrossWithCircleIcon width="1.8rem" height="1.8rem" />
      </Box>
      <Box ml="1.6rem">
        {title && (
          <Text variant="bodyLarge" fontWeight="600">
            {title}
          </Text>
        )}
        {description && (
          <Text variant="bodySmall" color="neutral.600">
            {description}
          </Text>
        )}
      </Box>
    </Flex>
  );
};
export const cwToast = (type, title, description, duration) => {
  switch (type) {
    case "success": {
      toast(<SuccessToast title={title} description={description} />, {
        autoClose: duration || 3000,
      });
      break;
    }
    case "info": {
      toast(<InfoToast title={title} description={description} />, {
        autoClose: duration || 3000,
      });
      break;
    }
    case "warning": {
      toast(<WarningToast title={title} description={description} />, {
        autoClose: duration || 3000,
      });
      break;
    }
    case "notification": {
      toast(<NotificationToast title={title} />, {
        className: "notification-toast",
        autoClose: duration || 3000,
      });
      break;
    }
    case "error": {
      toast(<ErrorToast title={title} description={description} />, {
        autoClose: duration || 3000,
      });
      break;
    }

    default:
      break;
  }
};
