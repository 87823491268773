import React from "react";
import { Formik, Form } from "formik";
import { Link, useNavigate } from "react-router-dom";

import { Box, Text, Grid, Image, Button, Error } from "atoms";
import { SocialMediaIcon, FormInput } from "molecules";
import { loginValidationSchema } from "utils/validation";
import MemoGoogleColorLogo from "assets/icons/GoogleColorLogo";
import MemoGoogleWhiteLogo from "assets/icons/GoogleWhiteLogo";
import MemoFb from "assets/icons/Fb";
import MemoLinkedin from "assets/icons/Linkedin";

const iconData = [
  {
    id: "0",
    Icon: MemoGoogleColorLogo,
    Hovericon: MemoGoogleWhiteLogo,
    text: "Sign in with Google",
    onClick: () =>
      window.open(`${process.env.REACT_APP_API_HOST}/auth/google`, "_self"),
  },
  // {
  //   id: "1",
  //   Icon: MemoFb,
  //   Hovericon: MemoFb,
  //   hoverColor: "white",
  //   color: "#395185",
  // },
  // {
  //   id: "2",
  //   Icon: MemoLinkedin,
  //   Hovericon: MemoLinkedin,
  //   hoverColor: "white",
  //   color: "#0A66C2",
  // },
];

export const LoginForm = ({ onSubmit, error, isLoading, ...props }) => {
  const navigate = useNavigate();

  return (
    <Grid {...props}>
      <Box w="6.75rem" h="13.8rem" mx="auto" mb="3rem">
        {/* <Box w="4.5rem" h="9.2rem" mx="auto" mb="2rem"> */}
        <Image src="/images/tree-icon.png" alt="logo" />
      </Box>
      <Grid
        px="3.9rem"
        pt="2.4rem"
        w="43rem"
        boxShadow="m"
        justifySelf="center"
      >
        <Text
          variant="subtitleLarge"
          fontWeight="600"
          color="neutral.800"
          mx="auto"
        >
          Log in to your account
        </Text>
        <Grid
          mt="3.4rem"
          gTC={`repeat(${iconData?.length},1fr)`}
          gridGap="1.6rem"
          justifyItems="center"
        >
          {iconData.map((item, index) => {
            return (
              <Box onClick={item?.onClick}>
                <SocialMediaIcon
                  key={index}
                  Icon={item?.Icon}
                  Hovericon={item?.Hovericon}
                  color={item?.color}
                  hoverColor={item?.hoverColor}
                  text={item?.text}
                  w="100%"
                  aI="center"
                  jC="center"
                />
              </Box>
            );
          })}
        </Grid>
        <Grid mt="3.2rem" gTC="repeat(3,1fr)" aI="center" gridGap="2rem">
          <Box w="10.7" h="1px" bg="neutral.100" />
          <Text variant="bodyMedium" color="neutral.500" textAlign="center">
            OR LOG IN WITH
          </Text>
          <Box w="10.7" h="1px" bg="neutral.100" />
        </Grid>
        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          onSubmit={onSubmit}
          validationSchema={loginValidationSchema}
        >
          {({ values, errors, touched }) => {
            return (
              <Form>
                <Box mt="3.2rem">
                  <FormInput
                    name="email"
                    type="email"
                    placeholder="Email"
                    label="Email"
                  />
                  <Box mt="3rem" />
                  <FormInput
                    name="password"
                    label="Password"
                    type="password"
                    placeholder="Password"
                    // isErrorRelative={values?.password}
                  />
                </Box>
                {error && (
                  <Error
                    text={error}
                    mt="0.8rem"
                    {...(errors?.password && { mt: "2rem" })}
                  />
                )}
                <Button
                  mt="3.2rem"
                  variant="primary"
                  type="submit"
                  h="5rem"
                  loading={isLoading}
                >
                  Sign In
                </Button>
              </Form>
            );
          }}
        </Formik>
        <Text
          mt="1.5rem"
          mx="auto"
          color="primary.900"
          variant="bodyMedium"
          cursor="pointer"
          onClick={() => navigate("/forgot-password")}
        >
          Forgot your password?
        </Text>

        <Box my="2rem">
          <Text color="neutral.800" variant="bodyLarge" textAlign="center">
            Don’t have an account?&nbsp;
            <Link to="/signup">
              <Text
                as="span"
                color="secondary.700"
                variant="bodyLarge"
                cursor="pointer"
              >
                Sign up
              </Text>
            </Link>
          </Text>
        </Box>
      </Grid>
    </Grid>
  );
};
