import React from "react";

import { Box, Grid, Image, Text } from "atoms";
import TreeLogo from "assets/images/tree-logo.png";
import MemoTreeLogoIcon from "assets/icons/TreeLogo";

export const AuthLayoutCommonSection = () => {
  return (
    <Grid
      gTR="repeat(3, auto)"
      gRG="8rem"
      alignItems="start"
      justifyItems="start"
      // h="fit-content"
    >
      {/* header */}
      <Text
        variant="subtitleLarge"
        color="white"
        fontSize="3.6rem !important"
        lineHeight="4.8rem ! important"
        // fontSize="4.6rem !important"
        // lineHeight="5.8rem ! important"
        height="max-content"
        pb="2.4rem"
        borderBottom="8px solid"
        borderRadius="10px"
        borderColor="secondary.900"
      >
        CHARTSWOOD <br />
        ASSOCIATES
      </Text>
      {/* <Box /> */}

      {/* theme logo */}
      <Grid gTC="11rem 1fr" gridGap="3rem" aI="end" justifySelf="center">
        <Box w="11rem" h="20rem" alignSelf="center">
          {/* <Box w="11rem" h="25rem" mt="2rem"> */}
          {/* <Image src={TreeLogo} alt="logo" /> */}
          <MemoTreeLogoIcon width="11rem" height="20rem" />
        </Box>
        <Box borderLeft="3px solid" borderColor="white">
          <Text
            color="white"
            ml="2.4rem"
            variant="headingLarge"
            fontSize="3.8rem ! important"
            lineHeight="5.8rem ! important"
            // fontSize="4.8rem ! important"
            // lineHeight="6.8rem ! important"
            // maxWidth="40rem"
            maxWidth="28rem"
          >
            BACKING BUSINESSES, CREATING SOLUTIONS
          </Text>
        </Box>
      </Grid>

      {/* description */}

      <Text
        color="white"
        alignSelf="center"
        variant="subtitleLarge"
        // fontWeight="600"
      >
        {/* From accounting to business incorporation,  */}
        Chartswood Associates prides itself as the prime choice for any SME’s
        finance and corporate service provider.
      </Text>
    </Grid>
  );
};
