import { useCallback, useEffect, useReducer } from "react";

import api from "utils/api";
import io from "socket.io-client";
import {
  createAsyncActions,
  createContainer,
  createReducer,
} from "utils/context";

import useAuth from "./Auth";
import { useLocation } from "react-router-dom";

export const socket = io(`${process.env.REACT_APP_API_BASE}`, {
  transports: ["websocket"],
  // rejectUnauthorized: null,
  reconnection: false,
  // agent: false,
  // upgrade: false,
  // rejectUnauthorized: false,
});

const initialState = {
  loading: true,
  notifications: [],
  unreadCount: 0,
};

const actions = {
  setNotifications: createAsyncActions("SET_NOTIFICATIONS"),
};

const reducer = createReducer({
  [actions.setNotifications.loading]: (state) => ({
    ...state,
    loading: true,
  }),
  [actions.setNotifications.success]: (state, { payload }) => ({
    ...state,
    notifications: payload,
    unreadCount: payload?.filter((n) => !n.isRead)?.length,
    loading: false,
  }),
  [actions.setNotifications.failure]: (state) => ({
    ...state,
    loading: false,
  }),
});

export const { useContext: useNotifications, Provider: NotificationProvider } =
  createContainer(() => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const location = useLocation();

    const {
      state: { isLoggedIn, userData },
    } = useAuth();

    const getAllNotifications = useCallback(() => {
      api(`/notifications`, {
        params: {
          sortBy: "createdAt:desc",
        },
      })
        .then(({ data }) => {
          dispatch(actions.setNotifications.success(data.data));
        })
        .catch((e) => {
          console.error(e);
          dispatch(actions.setNotifications.failure());
        });
    }, []);

    const onRead = useCallback(
      (notificationId) => {
        socket.emit("noti:read", { notificationId });
        getAllNotifications();
      },
      [socket, getAllNotifications]
    );

    const onReadAll = useCallback(() => {
      socket.emit("noti:readall", { userId: userData?._id });
      getAllNotifications();
    }, [socket, userData, getAllNotifications]);

    const tryReconnect = () => {
      setTimeout(() => {
        socket.io.open((err) => {
          if (err) {
            tryReconnect();
          }
        });
      }, 2000);
    };

    useEffect(() => {
      if (isLoggedIn && userData) {
        // io connection
        socket.io.on("connection", (data) => {
          // console.log(data);
        });
        // io error
        socket.io.on("error", (error) => {
          tryReconnect();
        });
        // io close
        socket.io.on("close", () => {
          tryReconnect();
        });

        // custom event user connection
        socket.emit("user:connect", userData._id);

        // custom event task update
        socket.on("task:update", (data) => {
          if (location?.pathname?.includes("mytask")) {
            window.location.reload();
          }
        });

        // custom event subscription renewal
        socket.on("subscription:renewal", (data) => {
          // console.log("subscription:renewals hit", data);
        });
      }
    }, [socket, isLoggedIn, userData]);

    return {
      state,
      actions: {
        onRead,
        onReadAll,
        getAllNotifications,
      },
    };
  });

export default useNotifications;
