import React from "react";
import { Box, Flex, Text, Grid } from "atoms";
import MemoCross from "styles/icons/Cross";
// import MemoPdficon from "assets/icons/Pdficon";
import { format, parseISO } from "date-fns";
import MemoFileIcon from "assets/icons/File";

export const UploadBox = ({ name, date, onClose }) => {
  return (
    <>
      <Flex>
        <Flex maxWidth="18rem">
          <Box mt="1.2rem" color="primary.900">
            <MemoFileIcon height="2.4rem" width="2.4rem" />
          </Box>
          <Box pl="1.2rem">
            <Text variant="bodyLarge" fontWeight="600" color="neutral.700" wordBreak="break-all">
              {name}
            </Text>
            <Text variant="bodySmall" color="neutral.500">
              {date && format(parseISO(date), "dd MMM, yyyy hh:mm a")}
            </Text>
          </Box>
        </Flex>
        <Grid
          h="2.3rem"
          w="2.3rem"
          bR="50%"
          bg="neutral.100"
          pI="center"
          ml="3.2rem"
          mt="1.3rem"
          cursor="pointer"
        >
          <Box
            color="neutral.900"
            w="1rem"
            h="1rem"
            onClick={onClose}
            cursor="pointer"
          >
            <MemoCross />
          </Box>
        </Grid>
      </Flex>
    </>
  );
};
