// import { Test } from "molecules";
import { theme } from "styles";
import { BrowserRouter as Router } from "react-router-dom";
import "styles/globals.css";
import "./styles/style.css";
import { ThemeProvider } from "styled-components";
import { AuthProvider } from "contexts/Auth";
import AuthGate from "Pages/Auth/AuthGate";
import { StyledContainer } from "atoms";

import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <StyledContainer hideProgressBar autoClose={6000} />
      <Router>
        <AuthProvider>
          <AuthGate />
        </AuthProvider>
      </Router>
    </ThemeProvider>
  );
}

export default App;
