import * as React from "react";

function AccountingIcon(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 16 12" fill="none" {...props}>
      <path
        d="M2.733 11.046L.07 8.383l.788-.787 1.875 1.856 3.356-3.356.788.806-4.144 4.144zm0-6L.07 2.383l.788-.787 1.875 1.856L6.089.096l.788.806-4.144 4.144zM8.32 9.62V8.496h6.75V9.62H8.32zm0-6V2.496h6.75V3.62H8.32z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoAccountingIcon = React.memo(AccountingIcon);
export default MemoAccountingIcon;
