import React, { useCallback, useEffect, useState } from "react";

import { Box, Flex, Grid, Text } from "atoms";
import MemoTickWithCircleIcon from "assets/icons/TickWithCircle";
import MemoTickIcon from "assets/icons/Tick";
import { formatDuration } from "utils/utilities";
import MemoCross from "styles/icons/Cross";

export const PlanCard = ({
  id,
  name,
  price,
  planTenure,
  descriptors,
  onClick,
  isSelected,
  ...props
}) => {
  // used for hover and click actions
  const [isCardActive, setIsCardActive] = useState(false);

  const handleHoverActions = useCallback(() => {
    // let newActiveState = undefined;
    // setIsCardActive((isActive) => {
    //   newActiveState = !isActive;
    //   return !isActive;
    // });
    onClick(id);
  }, [onClick, id]);

  useEffect(() => {
    setIsCardActive(isSelected);
  }, [isSelected]);

  return (
    <Flex
      bg="white"
      p="2.4rem"
      borderRadius="2rem"
      aI="center"
      jC="space-between"
      onClick={() => handleHoverActions()}
      css={{
        "& card-hover": {
          position: "absolute",
          left: "0",
          top: "0",
          width: "100%",
          height: "100%",
          border: "1px solid",
          borderColor: isCardActive ? "success.900" : "neutral.300",
          borderRadius: "2rem",
        },
        "&:hover card-hover": { borderColor: "success.900" },
      }}
      {...props}
    >
      {/* hover action stuff */}

      <Box as="card-hover">
        <Flex h="100%">
          {isCardActive && (
            <Box
              h="fit-content"
              w="fit-content"
              position="absolute"
              top="0"
              right="0"
              m="0.8rem"
              color="success.700"
            >
              <MemoTickWithCircleIcon width="1.6rem" height="1.6rem" />
            </Box>
          )}
        </Flex>
      </Box>

      <Grid gTC="auto 1fr" gCG="4.2rem">
        {/* Price Tag */}
        <Flex
          w="16.3rem"
          h="16.3rem"
          bR="100%"
          bg="primary.900"
          aI="center"
          jC="center"
          flexDirection="column"
        >
          <Text
            variant="headingLarge"
            fontWeight="700"
            color="neutral.50"
            {...(price?.length > 5 && { fontSize: "2.2rem !important" })}
          >
            {price}
          </Text>
          {/* <Text variant="bodyMedium" color="neutral.50">
            {formatDuration(planTenure)}
          </Text> */}
        </Flex>

        {/* Plan Information */}
        <Box>
          {/* Plan name */}
          <Text variant="subtitleLarge" fontWeight="700" color="neutral.700">
            {name}
          </Text>

          {/* Plan descriptors */}
          <Grid mt="3rem" gTC="repeat(3, 1fr)" gRG="1.6rem" gCG="4.8rem">
            {descriptors?.map((item, index) => (
              <Flex aI="baseline" key={index}>
                <Box
                  color={
                    item?.toLowerCase()?.includes("exclude")
                      ? "error.900"
                      : "success.700"
                  }
                >
                  {item?.toLowerCase()?.includes("exclude") ? (
                    <MemoCross width="1.6rem" height="1.2rem" />
                  ) : (
                    <MemoTickIcon width="1.6rem" height="1.2rem" />
                  )}
                </Box>
                <Text
                  ml="1.6rem"
                  variant="bodyMedium"
                  fontWeight="600"
                  color="neutral.600"
                >
                  {item}
                </Text>
              </Flex>
            ))}
          </Grid>
        </Box>
      </Grid>
    </Flex>
  );
};
