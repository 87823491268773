import * as React from "react";

function NotificationFile(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 10 11" fill="none" {...props}>
      <path
        d="M3.333.002V0h6.11C9.75 0 10 .25 10 .546v9.908a.543.543 0 01-.162.386.555.555 0 01-.39.16H.552a.56.56 0 01-.511-.343.545.545 0 01-.041-.21V3.3L3.333.002zM1.573 3.3h1.76V1.557L1.573 3.3zm2.871-2.2v2.75a.547.547 0 01-.162.389.558.558 0 01-.393.161H1.11v5.5H8.89V1.1H4.444z"
        fill="#EF6930"
      />
    </svg>
  );
}

const MemoNotificationFile = React.memo(NotificationFile);
export default MemoNotificationFile;
