import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Flex } from "atoms";
import { PaymentSuccess } from "organisms";
import { useLocationQueryValue } from "hooks/useLocationQuery";
import api from "utils/api";

export const PaymentSuccessPage = () => {
  const navigate = useNavigate();
  const sessionId = useLocationQueryValue("session_id");
  const [invoiceDetails, setInvoiceDetails] = useState(null);

  useEffect(() => {
    if (sessionId) {
      api(`/payments/details?sessionId=${sessionId}`)
        .then(({ data }) => {
          if (data.data) {
            if (data.data.errorCode) {
              navigate(`/payment/failure?session_id=${sessionId}`, {
                replace: true,
              });
            } else {
              setInvoiceDetails(data.data);
            }
          }
        })
        .catch(console.error);
    }
  }, [sessionId]);

  return (
    <Flex w="100%" p="3.2rem" aI="center" jC="center">
      <PaymentSuccess />
    </Flex>
  );
};
/**
 {
    "data": {
        "_id": "637c765aa15aeb7277c11913",
        "services": [
            {
                "serviceId": "637c762ea15aeb7277c1190a",
                "serviceType": "tax",
                "subscriptionId": "636dd7ad4cab9c7b4c14300b"
            }
        ],
        "status": "paid",
        "amount": 49900,
        "taskId": "637c765aa15aeb7277c11910",
        "userId": "636a4368c1cdaec24bae4e54",
        "stripeSubId": "sub_1M6qeYSDKZGOAZnZm64hWDDi",
        "mode": "subscription",
        "stripeSessionId": "cs_test_b1fCrCc4b51caH2Ny0OLB2Qqg7IOZJbbxxGDuK94IaXBjHK2LOP10plGVH",
        "createdAt": "2022-11-22T07:12:26.085Z",
        "updatedAt": "2022-11-22T07:12:26.451Z",
        "invoiceUrl": "https://invoice.stripe.com/i/acct_1M6SdtSDKZGOAZnZ/test_YWNjdF8xTTZTZHRTREtaR09BWm5aLF9NcVhrQ2U0cjdWYmVEQWVvdm5lNWlIN0dsRHIxeXhsLDU5NjQxOTQ102008No9OJ1r?s=ap"
    },
    "message": "get by user",
    "status": "SUCCESS"
}
 */
