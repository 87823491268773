import React from "react";
import { Box, Text, Line, Table, Button, Grid } from "atoms";
import { FormSelect, UploadBox, FormInput } from "molecules";
import { FormLayout } from "templates/CorporateSecretarial/components";
import MemoUploadIcon from "assets/icons/Upload";

export const FormEmploymentPass = ({ step, onNext, ...props }) => {
  return (
    <>
      <FormLayout
        step={step}
        title="Kindly provide Details:"
        // subtitle="Description Text"
        {...props}
      >
        <Text
          mt="4.8rem"
          variant="subtitleSmall"
          color="neutral.700"
          fontWeight="600"
        >
          Upload Choosen Document
        </Text>
        <Line mt="1.6rem" mb="2.4rem" />
        <Table
          tableHeader={[
            { name: "Document Name" },
            { name: "Document Type" },
            { name: "Period" },
            { name: "Upload file" },
          ]}
        >
          <tbody>
            <tr>
              <td>
                <Text variant="bodyLarge" color="neutral.700">
                  Employment contract
                </Text>
              </td>
              <td>
                <FormSelect name="fromxeropassport" label="Passport" />
              </td>
              <td>
                <Text variant="bodyLarge" fontWeight="600" color="neuteal.800">
                  One-Time
                </Text>
              </td>
              <td>
                <Button IconProps={{ Icon: MemoUploadIcon }}>Upload</Button>
              </td>
            </tr>

            <tr>
              <td>
                <Text variant="bodyLarge" color="neutral.700">
                  Employment contract
                </Text>
              </td>
              <td>
                <FormSelect name="fromxeropassport" label="Passport" />
              </td>
              <td>
                <Text variant="bodyLarge" fontWeight="600" color="neuteal.800">
                  One-Time
                </Text>
              </td>
              <td>
                <UploadBox />
              </td>
            </tr>
          </tbody>
        </Table>
        <Box mt="4.8rem">
          <Text variant="subtitleSmall" fontWeight="600" color="neutral.700">
            Passport number
          </Text>
          <Line mt="1.6rem" mb="2.4rem" />
          {/* input */}
          <FormInput name="FormEmploymentPassportNumber" label="Enter" />
        </Box>
        {/* designation, dob,email, mobile section */}
        <Grid gTC="repeat(2,1fr)" gridGap="2.4rem">
          {/* Designation */}
          <Box mt="5rem">
            <Text variant="subtitleSmall" fontWeight="600" color="neutral.700">
              Designation
            </Text>
            <Line mt="1.6rem" mb="2.4rem" />
            {/* input */}
            <FormInput
              name="NomineeShareholderDetailsDesignation"
              label="Enter"
            />
          </Box>
          {/* Email */}
          <Box mt="5rem">
            <Text variant="subtitleSmall" fontWeight="600" color="neutral.700">
              Email
            </Text>
            <Line mt="1.6rem" mb="2.4rem" />
            <FormInput name="email" placeholder="email" label="Enter" />
          </Box>
          {/* Date of Birth */}
          <Box mt="5rem">
            <Text variant="subtitleSmall" fontWeight="600" color="neutral.700">
              Date of Birth
            </Text>
            <Line mt="1.6rem" mb="2.4rem" />
            <FormInput name="NomineeShareholderDetailsDOB" label="Enter" />
          </Box>
          {/* mobile */}
          <Box mt="5rem">
            <Text variant="subtitleSmall" fontWeight="600" color="neutral.700">
              Mobile
            </Text>
            <Line mt="1.6rem" mb="2.4rem" />
            <FormInput name="phone" placeholder="phone" label="Enter" />
          </Box>
        </Grid>
        <Box mt="5rem">
          <Text variant="subtitleSmall" fontWeight="600" color="neutral.700">
            Residential Address
          </Text>
          <Line mt="1.6rem" mb="2.4rem" />
          {/* inputs */}
          <Grid gTC="repeat(2,1fr)" gridGap="2.4rem">
            <FormInput name="address1" placeholder="address" label="Enter" />
            <FormInput name="address2" placeholder="address" label="Enter" />
            <FormInput name="address3" placeholder="address" label="Enter" />
            <FormSelect name="FromEmploymentPassCounrty" label="Country" />
          </Grid>
        </Box>
        <Text
          variant="subtitleSmall"
          fontWeight="600"
          color="neutral.700"
          mt="5rem"
        >
          Confirmation of completed courses on IRAS portal
        </Text>
        <Line mt="1.6rem" mb="2.4rem" />
        <Table
          tableHeader={[
            { name: "Document Name" },
            { name: "Document Type" },
            { name: "Period" },
            { name: "Upload file" },
          ]}
        >
          <tbody>
            <tr>
              <td>
                <Text variant="bodyLarge" color="neutral.700">
                  Screenshot of completion the course
                </Text>
              </td>
              <td>
                <FormSelect name="fromxeropassport" label="Passport" />
              </td>
              <td>
                <Text variant="bodyLarge" fontWeight="600" color="neuteal.800">
                  One-Time
                </Text>
              </td>
              <td>
                <Button IconProps={{ Icon: MemoUploadIcon }}>Upload</Button>
              </td>
            </tr>
          </tbody>
        </Table>
        <Line mt="5rem" />
        <Button w="fit-content" mt="4rem" px="4rem" onClick={onNext}>
          Next : Payment
        </Button>
      </FormLayout>
    </>
  );
};
