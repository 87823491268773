import React from "react";
import { Text, Table, Line, Button, Box, Grid } from "atoms";
import { UploadBox, FormSelect, FormInput } from "molecules";
import { FormLayout } from "templates/CorporateSecretarial/components";
import MemoUploadIcon from "assets/icons/Upload";

export const FormMonthlyPayrollService = ({ step, onNext, ...props }) => {
  return (
    <>
      <FormLayout
        //step={step}
        onNext={onNext}
        title="Kindly provide Activity Code:"
        // subtitle="Description Text"
        {...props}
      >
        <Text
          mt="4.8rem"
          variant="subtitleSmall"
          color="neutral.700"
          fontWeight="600"
        >
          Upload Choosen Document
        </Text>
        <Line mt="1.6rem" mb="2.4rem" />

        {/* Table for upload doc*/}
        <Table
          tableHeader={[
            { name: "Document Name" },
            { name: "Document Type" },
            { name: "Period" },
            { name: "Upload file" },
          ]}
        >
          <tbody>
            <tr>
              <td>
                <Text variant="bodyLarge" color="neutral.700">
                  Employment contract
                </Text>
              </td>
              <td>
                <FormSelect
                  name="formMonthlyPayrollServicePassport"
                  label="Passport"
                />
              </td>
              <td>
                <Text variant="bodyLarge" fontWeight="600" color="neuteal.800">
                  One-Time
                </Text>
              </td>
              <td>
                <Button IconProps={{ Icon: MemoUploadIcon }}>Upload</Button>
              </td>
            </tr>

            <tr>
              <td>
                <Text variant="bodyLarge" color="neutral.700">
                  Employment contract
                </Text>
              </td>
              <td>
                <FormSelect
                  name="formMonthlyPayrollServicePassport"
                  label="Passport"
                />
              </td>
              <td>
                <Text variant="bodyLarge" fontWeight="600" color="neuteal.800">
                  One-Time
                </Text>
              </td>
              <td>
                <UploadBox />
              </td>
            </tr>

            <tr>
              <td>
                <Text variant="bodyLarge" color="neutral.700">
                  Employment contract
                </Text>
              </td>
              <td>
                <FormSelect
                  name="formMonthlyPayrollServicePassport"
                  label="Passport"
                />
              </td>
              <td>
                <Text variant="bodyLarge" fontWeight="600" color="neuteal.800">
                  One-Time
                </Text>
              </td>
              <td>
                <UploadBox />
              </td>
            </tr>
          </tbody>
        </Table>

        {/* title */}
        <Text
          variant="subtitleSmall"
          fontWeight="600"
          color="neutral.700"
          mt="4.8rem"
        >
          Payslip details
        </Text>
        <Line mt="1.6rem" />

        {/* designation, dob,email, mobile section */}
        <Grid gTC="repeat(2,1fr)" gridGap="2.4rem">
          {/* Designation */}
          <Box mt="5rem">
            <Text variant="subtitleSmall" fontWeight="600" color="neutral.700">
              Designation
            </Text>
            <Line mt="1.6rem" mb="2.4rem" />
            {/* input */}
            <FormInput name="formMonthlyPayrollServicename" label="Enter" />
          </Box>
          {/* Email */}
          <Box mt="5rem">
            <Text variant="subtitleSmall" fontWeight="600" color="neutral.700">
              Email
            </Text>
            <Line mt="1.6rem" mb="2.4rem" />
            <FormInput
              name="formMonthlyPayrollServiceEmail"
              placeholder="email"
              label="Enter"
            />
          </Box>
          {/* Date of Birth */}
          <Box mt="5rem">
            <Text variant="subtitleSmall" fontWeight="600" color="neutral.700">
              Date of Birth
            </Text>
            <Line mt="1.6rem" mb="2.4rem" />
            <FormInput name="formMonthlyPayrollServiceDOB" label="Enter" />
          </Box>
          {/* mobile */}
          <Box mt="5rem">
            <Text variant="subtitleSmall" fontWeight="600" color="neutral.700">
              Mobile
            </Text>
            <Line mt="1.6rem" mb="2.4rem" />
            <FormInput
              name="formMonthlyPayrollServicephone"
              placeholder="phone"
              label="Enter"
            />
          </Box>
        </Grid>
        {/* Residential Address */}
        <Box mt="5rem">
          <Text variant="subtitleSmall" fontWeight="600" color="neutral.700">
            Residential Address
          </Text>
          <Line mt="1.6rem" mb="2.4rem" />
          {/* inputs */}
          <Grid gTC="repeat(2,1fr)" gridGap="2.4rem">
            <FormInput name="formMonthlyPayrollServiceaddress1" label="Enter" />
            <FormInput name="formMonthlyPayrollServiceaddress2" label="Enter" />
            <FormInput name="formMonthlyPayrollServiceaddress3" label="Enter" />
            <FormSelect
              name="formMonthlyPayrollServiceCounrty"
              label="Country"
            />
          </Grid>
        </Box>

        <Line mt="5rem" />
        <Button w="fit-content" mt="4rem" px="4rem" onClick={onNext}>
          Next : Payment
        </Button>
      </FormLayout>
    </>
  );
};
