import React, { useEffect } from "react";
import { Box, Button, Grid } from "atoms";
import { FormInput, FormRadio, FormSelect } from "molecules";
import FormLayout from "../FormLayout";
import PRODUCT_PRICES from "utils/princing.constant";
import { replaceInArrayOfObjects } from "utils/utilities";
import { useActivityCode } from "hooks/useActivityCode";
import { setNestedObjectValues } from "formik";

export const FormActivityCode = ({
  step,
  onNext,
  values,
  setFieldValue,
  onSubmit,
  isLastStep,
  validateForm,
  setTouched,
  setErrors,
  errors,
  touched,
  ...props
}) => {
  useEffect(() => {
    const withCartValues = JSON.parse(JSON.stringify(values));
    let newCartFeatures = withCartValues?.cartSummary?.features || [];
    newCartFeatures = replaceInArrayOfObjects(
      {
        finder: "key",
        finderValue: PRODUCT_PRICES.change_business_code.type,
      },
      {
        name: PRODUCT_PRICES.change_business_code.name,
        key: PRODUCT_PRICES.change_business_code.type,
      },
      newCartFeatures
    );

    let newCartDetails = withCartValues?.cartDetails || [];
    newCartDetails = replaceInArrayOfObjects(
      {
        finder: "type",
        finderValue: PRODUCT_PRICES.change_business_code.type,
      },
      PRODUCT_PRICES.change_business_code,
      newCartDetails
    );

    setFieldValue("cartSummary.features", newCartFeatures);
    setFieldValue("cartDetails", newCartDetails);
  }, []);

  const [
    primaryActivityCodes,
    loadPrimaryActivityCodes,
    searchPrimaryActivityCodes,
  ] = useActivityCode();
  const [
    secondaryActivityCodes,
    loadSecondaryActivityCodes,
    searchSecondaryActivityCodes,
  ] = useActivityCode();

  return (
    <FormLayout
      step={step}
      title="Choose New Business Activity Code"
      subtitle="Update the correct Singapore Standard Industrial Code (SSIC) code for your company based on your current business needs."
      {...props}
    >
      {/* {({ values }) => {
        return (
          <Form> */}
      {/* input */}
      <Grid gTC="auto auto auto" gCG="3.5rem" jC="start" mt="4.8rem">
        <FormRadio name="activityType" label="Primary" value="primary" />
        <FormRadio name="activityType" label="Secondary" value="secondary" />
        <FormRadio name="activityType" label="Both" value="both" />
      </Grid>

      {/* primary */}
      {(values.activityType === "primary" ||
        values.activityType === "both") && (
        <Grid gTC="auto 1fr" gCG="2.4rem" mt="4.8rem">
          <Box w="35rem">
            <FormSelect
              name="primaryActivityCode"
              label="Primary Activity Code"
              options={primaryActivityCodes}
              onScroll={loadPrimaryActivityCodes}
              onInputChange={(value) => {
                searchPrimaryActivityCodes(value);
              }}
            />
          </Box>

          <FormInput
            name="primaryBusinessDescription"
            label="Please describe your primary business activity"
            type="textarea"
            wrap="hard"
            height="15.5rem"
          />
        </Grid>
      )}

      {/* secondary */}
      {(values.activityType === "secondary" ||
        values.activityType === "both") && (
        <Grid gTC="auto 1fr" gCG="2.4rem" mt="4.8rem">
          <Box w="35rem">
            <FormSelect
              name="secondaryActivityCode"
              label="Secondary Activity Code"
              options={secondaryActivityCodes}
              onScroll={loadSecondaryActivityCodes}
              onInputChange={(value) => {
                searchSecondaryActivityCodes(value);
              }}
            />
          </Box>

          <FormInput
            name="secondaryBusinessDescription"
            label="Please describe your secondary business activity"
            type="textarea"
            wrap="hard"
            height="15.5rem"
          />
        </Grid>
      )}

      {/* line */}
      <Box w="100%" h="0.5px" bg="neutral.200" mt="4.8rem" />
      <Button
        width="fit-content"
        px="4rem"
        mt="4rem"
        type="button"
        onClick={() => {
          validateForm({ ...values })?.then((dataErrorBag) => {
            const dataErrorBagKeys = Object?.keys(dataErrorBag || {});
            if (dataErrorBagKeys?.length) {
              setTouched(setNestedObjectValues(dataErrorBag, true));
              setErrors(dataErrorBag);
            } else {
              if (isLastStep) {
                onSubmit(values);
              }
              onNext();
            }
          });
        }}
      >
        NEXT : Payment
        {/* NEXT : Upload Documents */}
      </Button>
      {/* </Form>
        );
      }} */}
    </FormLayout>
  );
};
