import React from "react";

import { Box, Flex, Text } from "atoms";

export const SidebarNavItem = ({
  Icon,
  isExpanded,
  sidebarLink,
  hideSidebar,
  ...props
}) => (
  // <Tooltip
  //   render={
  //     isExpanded ? undefined : (
  //       <Text fontSize="1.6rem" lineHeight="2.4rem" color="white">
  //         {sidebarLink.title}
  //       </Text>
  //     )
  //   }
  //   top="-85%"
  //   left="170%"
  // >
  <Flex
    h="4rem"
    bR=".8rem"
    transition="all 0.3s"
    _hover={{
      bg: "rgba(38, 38, 38, 0.16)",
    }}
    cursor="pointer"
    px={isExpanded ? "1.8rem" : "1.2rem"}
    py=".8rem"
    {...(!isExpanded && { aI: "center", jC: "center" })}
    {...props}
  >
    <Flex h="100%" aI="center">
      {Icon ? (
        <Box color="neutral.50">
          <Icon height="1.8rem" width="1.8rem" />
        </Box>
      ) : isExpanded ? (
        <Box w="1.8rem" h="1.8rem" />
      ) : null}
      {isExpanded && (
        <Text variant="bodyMedium" truncate ml="1.6rem" color="white">
          {sidebarLink.title}
        </Text>
      )}
    </Flex>
  </Flex>
  // </Tooltip>
);
