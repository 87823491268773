import MemoAccountdetails from "assets/icons/sidebar/Accountdetails";
import MemoBxListCheckIcon from "assets/icons/sidebar/BxListCheck";
import MemoBxTaskIcon from "assets/icons/sidebar/BxTask";
import MemoCompanydetails from "assets/icons/sidebar/Companydetails";
import MemoInvoicedetails from "assets/icons/sidebar/Invoicedetails";
import MemoAccountingIcon from "assets/icons/sidebar/AccountingIcon";
import MemoTaxIcon from "assets/icons/sidebar/TaxIcon";
import MemoMOMIcon from "assets/icons/sidebar/MOMIcon";
import MemoMyTaskIcon from "assets/icons/sidebar/MyTaskIcon";
import MemoLibraryIcon from "assets/icons/sidebar/LibraryIcon";
import MemoPricingAndPlan from "assets/icons/sidebar/PricingAndPlan";
import MemoPaymentDetails from "assets/icons/sidebar/Paymentdetails";
import MemoPersonalDetails from "assets/icons/sidebar/Personaldetails";

const mapServiceIcons = {
  incorporation: MemoBxTaskIcon,
  "corporate secretarial": MemoBxListCheckIcon,
  accounting: MemoAccountingIcon,
  tax: MemoTaxIcon,
  mom: MemoMOMIcon,
  "my task": MemoMyTaskIcon,
  library: MemoLibraryIcon,
  "company details": MemoCompanydetails,
  "payment details": MemoPaymentDetails,
  "personal details": MemoPersonalDetails,
  "account details": MemoAccountdetails,
  "invoice details": MemoInvoicedetails,
  "pricing and plan": MemoPricingAndPlan,
};

export default mapServiceIcons;
