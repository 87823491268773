import React from "react";

import { Box, Text, Flex, Breadcrum } from "atoms";
import MemoLineArrowIcon from "assets/icons/LineArrow";

export const TabHeader = ({
  title,
  subtitle,
  breadcrum,
  isLine = true,
  onPrevious,
  ...props
}) => {
  return (
    <Box w="100%" {...props}>
      {/* title */}
      <Text
        variant="headingSmall"
        fontWeight="600"
        mt="2.4rem"
        color="neutral.800"
      >
        {title}
      </Text>
      <Text variant="subtitleSmall" color="neutral.500" mt="1.6rem">
        {subtitle}
      </Text>

      {/* breadcrums */}
      {/* {breadcrum && (
        <Flex aI="center" mt="1.6rem">
          <Breadcrum {...breadcrum} />
        </Flex>
      )} */}

      {breadcrum && (
        <Flex mt="1.6rem" aI="center">
          <Box
            color="neutral.800"
            transform="rotate(-90deg)"
            mr="0.4rem"
            cursor="pointer"
            onClick={onPrevious}
          >
            <MemoLineArrowIcon width="1.6rem" />
          </Box>
          {/* <Breadcrum firstText="Go Back" /> */}
          <Breadcrum {...breadcrum} />
        </Flex>
      )}

      {isLine && (
        <Box
          h=".1rem"
          w="100%"
          border="1px solid"
          borderColor="neutral.300"
          mt="1.6rem"
        />
      )}
    </Box>
  );
};
