import * as React from "react";

function Arrow(props) {
  return (
    <svg
      width="13"
      height="8"
      viewBox="0 0 13 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.36256 2.82832L1.41256 7.77832L-0.00143814 6.36432L6.36256 0.000320435L12.7266 6.36432L11.3126 7.77832L6.36256 2.82832Z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoArrowIcon = React.memo(Arrow);
export default MemoArrowIcon;
