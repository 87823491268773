import React from "react";
import { PersonalDetails as TemplatePersonalDetails } from "templates";

export const PersonalDetails = () => {
  return (
    <>
      <TemplatePersonalDetails />
    </>
  );
};
