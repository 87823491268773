import { createReducer } from "utils/context";
import { AUTH_ACTIONS } from "./actions";

export const initialState = {
  // global identifier for auth

  // incorporation
  incorporation: null,

  // layout identifier
  isLayoutFT: false,
};

export const authReducer = createReducer({
  // --- login state management
  // [AUTH_ACTIONS.login.loading]: (state) => ({
  //   ...state,
  //   loginLoading: true,
  //   loginError: null,
  // }),
  // [AUTH_ACTIONS.login.success]: (
  //   state,
  //   { payload: { isLoggedIn, ...payload } }
  // ) => ({
  //   ...state,
  //   userData: payload,
  //   isLoggedIn: isLoggedIn,
  //   loginLoading: false,
  //   loginError: null,
  // }),
  // [AUTH_ACTIONS.login.failure]: (state, { payload }) => ({
  //   ...state,
  //   isLoggedIn: false,
  //   loginLoading: false,
  //   loginError: payload,
  // }),

  // --- logout state management
  // [AUTH_ACTIONS.logout]: (state) => ({
  //   ...state,
  //   isLoggedIn: false,
  //   userData: null,
  // }),

  // --- layout state management
  [AUTH_ACTIONS.switchLayout]: (state, { payload }) => ({
    ...state,
    isLayoutFT: !!payload,
  }),

  // --- incorporation service state management
  // [AUTH_ACTIONS.incorporation]: (state, { payload }) => ({
  //   ...state,
  //   incorporation: payload,
  // }),

  // --- cart state management
  [AUTH_ACTIONS.cart]: (state, { payload }) => ({
    ...state,
    cart: payload,
  }),

  // --- product prices state management
  [AUTH_ACTIONS.productPrices]: (state, { payload }) => ({
    ...state,
    productPrices: payload,
  }),
});
