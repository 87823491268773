import * as React from "react";

function LibraryIcon(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 15 15" fill="none" {...props}>
      <path
        d="M10.731 8.263L6.737 4.269 10.732.275l3.994 3.994-3.994 3.994zM.25 6.913V1.269h5.644v5.644H.25zm7.838 7.837V9.107h5.643v5.643H8.088zm-7.838 0V9.107h5.644v5.643H.25zm1.125-8.962h3.394V2.394H1.375v3.394zm9.412.956l2.42-2.419-2.42-2.418L8.37 4.325l2.418 2.42zm-1.575 6.881h3.394v-3.393H9.213v3.393zm-7.837 0h3.394v-3.393H1.375v3.393z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoLibraryIcon = React.memo(LibraryIcon);
export default MemoLibraryIcon;
