import React, { useCallback, useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { CSSTransition } from "react-transition-group";

import { Box, Flex, Grid, Image, Text } from "atoms";
import { SIDEBAR_COLLAPSE_WIDTH, SIDEBAR_WIDTH } from "./utilities/constants";
import MemoFullLogoIcon from "assets/images/1.png";
// import MemoFullLogoIcon from "assets/images/sidebarLogo.png";
import MemoLogoIcon from "assets/images/sidebarLogoSmall.png";
import { SidebarNavItem } from "./components/SidebarNavItem";
import MemoArrowIcon from "assets/icons/Arrow";
import useAuth from "contexts/Auth";
import { OWNER_ROLE_CODE, USER_ROLE_CODE } from "utils/constants";
import mapServiceIcons from "utils/services.icon.constants";

const SIDEBAR_MAP_USER = [
  {
    title: "Services",
    link: "/",
    children: [
      {
        title: "Register a Company",
        link: "/service/incorporation",
        icon: mapServiceIcons["incorporation"],
      },
      {
        title: "Secretarial Services",
        link: "/service/corporate-secretarial",
        icon: mapServiceIcons["corporate secretarial"],
      },
      {
        title: "Set up Accounting",
        link: "/service/accounting",
        icon: mapServiceIcons["accounting"],
      },
      {
        title: "Taxation",
        link: "/service/tax",
        icon: mapServiceIcons["tax"],
      },
      {
        title: "Applications",
        link: "/service/mom",
        icon: mapServiceIcons["mom"],
      },
    ],
  },
  {
    title: "Query List",
    children: [
      {
        title: "My Task",
        link: "/query-list/mytask",
        icon: mapServiceIcons["my task"],
      },
    ],
  },
  {
    title: "Documents",
    children: [
      {
        title: "Library",
        link: "/documents/library",
        icon: mapServiceIcons["library"],
      },
    ],
  },
  {
    title: "Profile",
    children: [
      // {
      //   title: "Company details",
      //   link: "/profile/user-details",
      //   icon: mapServiceIcons["company details"],
      // },
      {
        title: "Payment details",
        link: "/profile/payment-details",
        icon: mapServiceIcons["payment details"],
      },
      {
        title: "Personal details",
        link: "profile/personal-details",
        icon: mapServiceIcons["personal details"],
      },
      // {
      //   title: "Account details",
      //   link: "/f",
      //   icon: MemoAccountdetails,
      // },
      // {
      //   title: "Invoice details",
      //   link: "/f",
      //   icon: MemoInvoicedetails,
      // },
    ],
  },
  // {
  //   title: "Subscription",
  //   children: [
  //     {
  //       title: "Pricing and Plan",
  //       link: "/subscription/pricing-and-plan",
  //       icon: mapServiceIcons["pricing and plan"],
  //     },
  //   ],
  // },
];
const SIDEBAR_MAP_OWNER = [
  {
    title: "Query List",
    children: [
      {
        title: "My Task",
        link: "/query-list/mytask",
        icon: mapServiceIcons["my task"],
      },
    ],
  },
  {
    title: "Documents",
    children: [
      {
        title: "Library",
        link: "/documents/library",
        icon: mapServiceIcons["library"],
      },
    ],
  },
  {
    title: "Profile",
    children: [
      // {
      //   title: "Payment details",
      //   link: "/profile/payment-details",
      //   icon: mapServiceIcons["payment details"],
      // },
      {
        title: "Personal details",
        link: "profile/personal-details",
        icon: mapServiceIcons["personal details"],
      },
    ],
  },
  // {
  //   title: "Subscription",
  //   children: [
  //     {
  //       title: "Pricing and Plan",
  //       link: "/subscription/pricing-and-plan",
  //       icon: mapServiceIcons["pricing and plan"],
  //     },
  //   ],
  // },
];

export const DefaultSidebar = ({ isExpanded }) => {
  const {
    state: { userData },
  } = useAuth();
  const SIDEBAR_MAP = {
    [USER_ROLE_CODE]: SIDEBAR_MAP_USER,
    [OWNER_ROLE_CODE]: SIDEBAR_MAP_OWNER,
  }[userData?.role];
  const [isDelayedExpanded, setIsDelayedExpanded] = useState(true);
  const [isTabCollapsed, setIsTabCollapsed] = useState(
    new Array(SIDEBAR_MAP?.length)
  );

  const handleTabCollapse = useCallback(
    (index) => {
      let newTabState = JSON.parse(JSON.stringify(isTabCollapsed));
      newTabState[index] = !isTabCollapsed[index];
      setIsTabCollapsed(newTabState);
    },
    [isTabCollapsed]
  );

  useEffect(() => {
    isExpanded
      ? setIsDelayedExpanded(isExpanded)
      : setTimeout(() => {
          setIsDelayedExpanded(isExpanded);
        }, 180);

    !isExpanded && setIsTabCollapsed(new Array(SIDEBAR_MAP?.length));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExpanded]);

  return (
    <Box
      zIndex={100}
      bg="primary.900"
      overflowY="auto"
      minHeight="100vh"
      maxHeight="100vh"
      transition="all 0.3s"
      width={isExpanded ? SIDEBAR_WIDTH : SIDEBAR_COLLAPSE_WIDTH}
      css={{
        "::-webkit-scrollbar": {
          display: "none",
        },
        "::-webkit-scrollbar-track": {
          display: "none",
        },
        "::-webkit-scrollbar-thumb": {
          display: "none",
        },
      }}
    >
      {/* logo */}
      <Box mx="3rem" mt="2.6rem" maxHeight="3.7rem">
        <Link to="/">
          {isDelayedExpanded ? (
            // <Flex aI="center">
            //   <Image src={MemoLogoIcon} height="3.7rem" width="2.2rem" />
            //   <Text
            //     variant="headingSmall"
            //     color="secondary.900"
            //     fontWeight="600"
            //     ml="1.6rem"
            //     lineHeight="3.7rem"
            //   >
            //     Chartswood
            //   </Text>
            // </Flex>
            <Image src={MemoFullLogoIcon} height="3.7rem" />
          ) : (
            <Image src={MemoLogoIcon} height="3.7rem" />
          )}
        </Link>
      </Box>

      {/* sidebar maps */}
      {SIDEBAR_MAP?.map((section, index) => (
        <Grid
          as="nav"
          pl="1.6rem"
          pr="4.4rem"
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          mt="2.4rem"
          pb="1.6rem"
          gRG=".8rem"
        >
          {isDelayedExpanded ? (
            <Flex
              aI="center"
              jC="space-between"
              onClick={() => handleTabCollapse(index)}
              cursor="pointer"
            >
              <Text
                truncate
                px="1.8rem"
                color="white"
                textTransform="uppercase"
                variant="bodyLarge"
              >
                {section?.title}
              </Text>
              <Box
                color="neutral.50"
                transition="all 0.3sec"
                transform={
                  isTabCollapsed[index] ? "rotate(180deg)" : "rotate(0deg)"
                }
              >
                <MemoArrowIcon />
              </Box>
            </Flex>
          ) : (
            <Box height=".1rem" width="100%" bg="white" />
          )}
          <CSSTransition
            classNames="collapse"
            in={!isTabCollapsed[index]}
            timeout={300}
            unmountOnExit
          >
            <Box>
              {section?.children?.map(
                ({ icon: Icon, ...sidebarLink }, index) => (
                  <Grid key={index}>
                    <NavLink
                      to={sidebarLink.link}
                      style={({ isActive }) =>
                        isActive
                          ? {
                              background: "rgba(38, 38, 38, 0.16)",
                              borderRadius: ".8rem",
                            }
                          : undefined
                      }
                    >
                      <SidebarNavItem
                        Icon={Icon}
                        isExpanded={isDelayedExpanded}
                        sidebarLink={sidebarLink}
                      />
                    </NavLink>
                  </Grid>
                )
              )}
            </Box>
          </CSSTransition>
        </Grid>
      ))}
    </Box>
  );
};
