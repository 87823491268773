import React from "react";

import { Box, Error, Grid, Line, Table, Text } from "atoms";
import {
  FormDateInput,
  FormInput,
  FormPhoneInput,
  FormRadio,
  FormSelect,
} from "molecules";
import {
  getCountries,
  getDocumentById,
  getIdentificationLabel,
} from "utils/utilities";
import { Upload } from "organisms";
import { postcodeValidatorExistsForCountry } from "postcode-validator";

// {`directorDetails[${index}].`}

const customIdGenerator = (index, id) => {
  return `directorDetails_${index}_${id}`;
};

export const Director = ({
  values,
  index,
  formUpload,
  setFieldValue,
  errors,
  touched,
}) => {
  return (
    <>
      {/* director name */}
      <Box w="100%" mt="4rem">
        {/* title */}
        <Text variant="subtitleSmall" fontWeight="600" color="neutral.700">
          Director name
        </Text>
        <Line mt="1.6rem" mb="2.4rem" />
        {/* inputs */}
        <Grid gTC="1fr 1fr" gCG="1.6rem">
          <FormInput
            name={`directorDetails[${index}].firstName`}
            label="First Name"
            autoFocus
          />
          <FormInput
            name={`directorDetails[${index}].lastName`}
            label="Last Name"
          />
        </Grid>
      </Box>

      {/* choose identification */}
      <Box w="100%" mt="5rem">
        <Grid gTC="auto auto auto auto" gCG="3.5rem" jC="start">
          {/* title */}
          <Text variant="subtitleSmall" fontWeight="600" color="neutral.700">
            Choose Identification
          </Text>
          <FormRadio
            name={`directorDetails[${index}].identificationType`}
            label="Passport"
            value="passport"
          />
          <FormRadio
            name={`directorDetails[${index}].identificationType`}
            label="NRIC"
            value="NRIC"
          />
          <FormRadio
            name={`directorDetails[${index}].identificationType`}
            label="EP Number"
            value="EP_Number"
          />
        </Grid>
        <Line mt="1.6rem" mb="2.4rem" />
        {/* inputs */}
        <Grid>
          <FormInput
            name={`directorDetails[${index}].identification`}
            label={`Enter ${getIdentificationLabel(
              values?.directorDetails?.[index]?.identificationType
            )}`}
          />
        </Grid>
      </Box>

      {/* Upload Choosen Document */}
      <Box w="100%" mt="5rem">
        {/* title */}
        <Text variant="subtitleSmall" fontWeight="600" color="neutral.700">
          Upload Choosen Document
        </Text>
        <Line mt="1.6rem" mb="2.4rem" />
        {/* inputs */}
        <Table
          tableHeader={[{ name: "Document Name" }, { name: "Upload file" }]}
        >
          <tbody>
            <tr>
              <td>
                <Box>
                  <Text variant="bodyLarge" color="neutral.700">
                    Proof of{" "}
                    {getIdentificationLabel(
                      values?.directorDetails?.[index]?.identificationType
                    ) || "Details"}
                  </Text>
                  {/* <Text variant="bodyLarge" color="neutral.700">
                    (eg. Utilities Bills / Telecommunication Bills / Bank
                    Statements)
                  </Text> */}
                  {touched?.directorDetails?.[index]?.documents &&
                    errors?.directorDetails?.[index]?.documents &&
                    !getDocumentById(
                      "directorPOI",
                      values?.[`directorDetails`]?.[index]?.documents
                    )?.url && <Error text={"* required"} />}
                </Box>
              </td>
              <td>
                <Upload
                  url={
                    getDocumentById(
                      "directorPOI",
                      values?.[`directorDetails`]?.[index]?.documents
                    )?.url
                  }
                  name={
                    getDocumentById(
                      "directorPOI",
                      values?.[`directorDetails`]?.[index]?.documents
                    )?.name
                  }
                  time={
                    getDocumentById(
                      "directorPOI",
                      values?.[`directorDetails`]?.[index]?.documents
                    )?.time
                  }
                  onClose={() => {
                    const delDocs = JSON.parse(JSON.stringify(values))?.[
                      `directorDetails`
                    ]?.[index]?.documents?.filter(
                      (item) => item?.id !== "directorPOI"
                    );

                    setFieldValue(
                      `directorDetails[${index}].documents`,
                      delDocs
                    );
                  }}
                  loading={
                    values?.[
                      `${customIdGenerator(index, "directorPOI")} loading`
                    ]
                  }
                  onChange={(e) => {
                    formUpload(
                      e,
                      customIdGenerator(index, "directorPOI"),
                      setFieldValue,
                      (fieldData) => {
                        const addDocs =
                          JSON.parse(JSON.stringify(values))?.[
                            `directorDetails`
                          ]?.[index]?.documents || [];

                        addDocs?.push({
                          id: "directorPOI",
                          name: fieldData?.name,
                          time: fieldData?.uploadedAt,
                          url: fieldData?.url,
                          type: "directorPOI",
                        });

                        setFieldValue(
                          `directorDetails[${index}].documents`,
                          addDocs
                        );
                      }
                    );
                  }}
                />
              </td>
            </tr>
          </tbody>
        </Table>
      </Box>

      <Grid gTC="repeat(2,1fr)" gridGap="2.4rem">
        {/* Designation */}
        <Box mt="5rem">
          <Text variant="subtitleSmall" fontWeight="600" color="neutral.700">
            Designation
          </Text>
          <Line mt="1.6rem" mb="2.4rem" />
          {/* input */}
          <FormInput
            name={`directorDetails[${index}].designation`}
            label="Enter Designation"
          />
        </Box>

        {/* Email */}
        <Box mt="5rem">
          <Text variant="subtitleSmall" fontWeight="600" color="neutral.700">
            Email
          </Text>
          <Line mt="1.6rem" mb="2.4rem" />
          {/* input */}
          <FormInput
            name={`directorDetails[${index}].email`}
            label="Enter Email"
            type="email"
          />
        </Box>
      </Grid>

      {/* Residential Address */}
      <Box w="100%" mt="5rem">
        {/* title */}
        <Text variant="subtitleSmall" fontWeight="600" color="neutral.700">
          Residential Address
        </Text>
        <Line mt="1.6rem" mb="2.4rem" />
        {/* inputs */}
        <Grid gTC="1fr 1fr" gCG="1.6rem" gRG="2.4rem">
          {/* Address Line 1 */}
          <FormInput
            name={`directorDetails[${index}].address.addressLine1`}
            label="Address Line 1"
          />

          {/* Address Line 2 */}
          <FormInput
            name={`directorDetails[${index}].address.addressLine2`}
            label="Address Line 2"
          />

          {/* Country */}
          <FormSelect
            name={`directorDetails[${index}].address.country`}
            label="Country"
            options={getCountries()}
            onChange={(country) => {
              setFieldValue(
                `directorDetails[${index}].address.countryCode`,
                country?.countryCode
              );
              setFieldValue(`directorDetails[${index}].address.postalCode`, "");
              setFieldValue(`directorDetails[${index}].mobile`, "");
            }}
          />

          {/* Postal Code */}
          <FormInput
            name={`directorDetails[${index}].address.postalCode`}
            label="Postal Code"
            disabled={
              !postcodeValidatorExistsForCountry(
                values?.directorDetails?.[index]?.address?.countryCode
              )
            }
          />
        </Grid>
      </Box>

      <Grid gTC="repeat(2,1fr)" gridGap="2.4rem">
        {/* DOB */}
        <Box mt="5rem">
          <Text variant="subtitleSmall" fontWeight="600" color="neutral.700">
            Date of Birth
          </Text>
          <Line mt="1.6rem" mb="2.4rem" />
          {/* input */}
          {/* <FormInput name={`directorDetails[${index}].dob`} label="Enter" /> */}
          <FormDateInput
            name={`directorDetails[${index}].dob`}
            label="Enter Date of Birth"
            datePickerProps={{ maxDate: new Date() }}
          />
        </Box>

        {/* Mobile */}
        <Box mt="5rem">
          <Text variant="subtitleSmall" fontWeight="600" color="neutral.700">
            Mobile
          </Text>
          <Line mt="1.6rem" mb="2.4rem" />
          {/* input */}
          <FormPhoneInput
            name={`directorDetails[${index}].mobile`}
            label="Enter Mobile"
            {...(values?.directorDetails?.[index]?.address?.countryCode && {
              country:
                values?.directorDetails?.[
                  index
                ]?.address?.countryCode?.toLowerCase(),
            })}
          />
        </Box>
      </Grid>

      {/* Add Local Address Proof */}
      <Box w="100%" mt="5rem">
        {/* title */}
        <Text variant="subtitleSmall" fontWeight="600" color="neutral.700">
          Add Local Address Proof
        </Text>
        <Line mt="1.6rem" mb="2.4rem" />
        {/* inputs */}
        <Table
          tableHeader={[{ name: "Document Name" }, { name: "Upload file" }]}
        >
          <tbody>
            <tr>
              <td>
                <Box>
                  <Text variant="bodyLarge" color="neutral.700">
                    Local Address Proof
                  </Text>
                  <Text variant="bodyLarge" color="neutral.700">
                    (eg. Utilities Bills / Telecommunication Bills / Bank
                    Statements)
                  </Text>
                  {touched?.directorDetails?.[index]?.documents &&
                    errors?.directorDetails?.[index]?.documents &&
                    !getDocumentById(
                      "directorLocalAddressProof",
                      values?.[`directorDetails`]?.[index]?.documents
                    )?.url && <Error text={"* required"} />}
                </Box>
              </td>
              <td>
                <Upload
                  url={
                    getDocumentById(
                      "directorLocalAddressProof",
                      values?.[`directorDetails`]?.[index]?.documents
                    )?.url
                  }
                  name={
                    getDocumentById(
                      "directorLocalAddressProof",
                      values?.[`directorDetails`]?.[index]?.documents
                    )?.name
                  }
                  time={
                    getDocumentById(
                      "directorLocalAddressProof",
                      values?.[`directorDetails`]?.[index]?.documents
                    )?.time
                  }
                  onClose={() => {
                    const delDocs = JSON.parse(JSON.stringify(values))?.[
                      `directorDetails`
                    ]?.[index]?.documents?.filter(
                      (item) => item?.id !== "directorLocalAddressProof"
                    );

                    setFieldValue(
                      `directorDetails[${index}].documents`,
                      delDocs
                    );
                  }}
                  loading={
                    values?.[
                      `${customIdGenerator(
                        index,
                        "directorLocalAddressProof"
                      )} loading`
                    ]
                  }
                  onChange={(e) => {
                    formUpload(
                      e,
                      customIdGenerator(index, "directorLocalAddressProof"),
                      setFieldValue,
                      (fieldData) => {
                        const addDocs =
                          JSON.parse(JSON.stringify(values))?.[
                            `directorDetails`
                          ]?.[index]?.documents || [];

                        addDocs?.push({
                          id: "directorLocalAddressProof",
                          name: fieldData?.name,
                          time: fieldData?.uploadedAt,
                          url: fieldData?.url,
                          type: "directorLocalAddressProof",
                        });

                        setFieldValue(
                          `directorDetails[${index}].documents`,
                          addDocs
                        );
                      }
                    );
                  }}
                />
              </td>
            </tr>
          </tbody>
        </Table>
      </Box>
    </>
  );
};
