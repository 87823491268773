import styled from "styled-components";
import { ToastContainer } from "react-toastify";

export const StyledContainer = styled(ToastContainer).attrs({
  // custom props
})`
  .Toastify__toast {
    background-color: white;
    background-size: 25px;
    background-position: 15px;
    background-repeat: no-repeat;
    /* padding-left: 5.5rem; */
    font-size: 1.4rem;
    box-shadow: 0px 0px 22px rgba(226, 226, 226, 0.3);
    overflow: unset;
  }
  .Toastify__toast-icon {
    /* padding-left: 5.5rem;
    border-left: 1px solid #18aacc; */
  }
  /*.Toastify__toast--info {
    border-left: 6px solid #18aacc;
  }
  .Toastify__toast--error {
    border-left: 6px solid #da1a32;
  }
  .Toastify__toast--warning {
    border-left: 6px solid #f1b75f;
  }
  .Toastify__toast--success {
    border-left: 6px solid #00a699;
  }*/
  .Toastify__toast-body {
    color: black;
  }
  .Toastify__close-button {
    position: absolute;
    top: -12px;
    right: -12px;
    background: #e9e9e9;
    border-radius: 100%;
    width: 23.99px;
    height: 23.99px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;
  }

  button[aria-label="close"] {
    color: black;
    align-self: center;
  }
`;
