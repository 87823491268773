import * as React from "react";

function NotificationHalfCircle(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 10 11" fill="none" {...props}>
      <path
        d="M6.667 1.1H1.11v8.8H8.89V3.3H6.667V1.1zM0 .546A.55.55 0 01.555 0h6.667L10 2.75v7.696a.545.545 0 01-.34.51.56.56 0 01-.212.044H.552a.558.558 0 01-.39-.16.547.547 0 01-.162-.386V.546zM5 3.3v2.2h2.222c0 .435-.13.86-.374 1.222a2.218 2.218 0 01-.998.81 2.243 2.243 0 01-2.421-.476 2.18 2.18 0 01-.482-2.398c.168-.402.453-.745.818-.987C4.131 3.429 4.56 3.3 5 3.3z"
        fill="#EF6930"
      />
    </svg>
  );
}

const MemoNotificationHalfCircle = React.memo(NotificationHalfCircle);
export default MemoNotificationHalfCircle;
