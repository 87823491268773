import React, { useCallback, useEffect, useState } from "react";

import { Flex, Loader } from "atoms";
import {
  FormAddressProof,
  FormCompanyDetails,
  FormCorporateAddressDetails,
  FormCorporateAddressService,
  FormDirector,
  FormDirectorDetails,
  FormDirectorPlan,
  FormLocalDirector,
  FormNomineeServices,
  FormNomineeShareholderDetails,
  FormShareholderDetails,
  FormShareholderServices,
} from "./components";
import { useNavigate } from "react-router-dom";
import FORM_NAMES from "./form.names";
import { onChangeFormUploadFile } from "utils/utilities";
import { CartSummary, TabHeader } from "organisms";
import { useFormNavigation } from "hooks/useFormNavigation";
import useService from "contexts/Service";

const FORM_COMPONENT_NAMED_NAVIGATION = [
  {
    name: FORM_NAMES.FormDirector,
    Component: FormDirector,
  },
  {
    name: FORM_NAMES.FormAddressProof,
    Component: FormAddressProof,
  },
  {
    name: FORM_NAMES.FormCompanyDetails,
    Component: FormCompanyDetails,
  },
  {
    name: FORM_NAMES.FormCorporateAddressDetails,
    Component: FormCorporateAddressDetails,
  },
  {
    name: FORM_NAMES.FormCorporateAddressService,
    Component: FormCorporateAddressService,
  },
  {
    name: FORM_NAMES.FormDirectorDetails,
    Component: FormDirectorDetails,
  },
  {
    name: FORM_NAMES.FormLocalDirector,
    Component: FormLocalDirector,
  },
  {
    name: FORM_NAMES.FormNomineeServices,
    Component: FormNomineeServices,
  },
  {
    name: FORM_NAMES.FormNomineeShareholderDetails,
    Component: FormNomineeShareholderDetails,
  },
  {
    name: FORM_NAMES.FormShareholderDetails,
    Component: FormShareholderDetails,
  },
  {
    name: FORM_NAMES.FormShareholderServices,
    Component: FormShareholderServices,
  },
  {
    name: FORM_NAMES.FormDirectorPlan,
    Component: FormDirectorPlan,
  },
];

const getFormElement = (name) => {
  return (
    FORM_COMPONENT_NAMED_NAVIGATION?.find((item) => item?.name === name) ||
    FORM_COMPONENT_NAMED_NAVIGATION?.find(
      (item) => item?.name === FORM_NAMES.FormDirector
    )
  );
};

const TOTAL_STEPS = 5;

export const Incorporation = ({
  hydrateFormData,
  updateFormData,
  createFormData,
}) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState(undefined);
  const [formLoading, setFormLoading] = useState(false);
  const {
    actions: { getTriggerIncoporationPayments },
  } = useService();

  const formHydration = useCallback(() => {
    setFormLoading(true);
    hydrateFormData()
      .then((data) => {
        setFormData(data?.[0]);
      })
      .finally(() => {
        setFormLoading(false);
      });
  }, [hydrateFormData]);

  useEffect(() => {
    formHydration();
  }, [formHydration]);

  const [FormElement, setFormElement] = useState(getFormElement());

  const [stepCount, currentFormCode, { formNavigationHandler }] =
    useFormNavigation(
      FORM_NAMES.FormDirector,
      FORM_NAMES,
      getFormElement,
      "/service/incorporation",
      false
    );

  useEffect(() => {
    setFormElement(getFormElement(currentFormCode));
  }, [currentFormCode]);

  return (
    <Loader loading={formLoading}>
      <Flex
        w="100%"
        // h="100%"
        jC="flex-start"
        aI="center"
        flexDirection="column"
        px="3.2rem"
      >
        <CartSummary
          position="absolute"
          top="3.2rem"
          right="3.2rem"
          zIndex="100"
          features={formData?.cartDetails}
        />

        <TabHeader
          title="Incorporation of New Entity"
          breadcrum={{
            firstText: "Home",
            firstUrl: "/dashboard",
            secondText: "Incorporation of New Entity",
          }}
          onPrevious={() => navigate("/dashboard")}
        />

        <FormElement.Component
          step={`Step ${stepCount}/${TOTAL_STEPS}`}
          breadcrum={
            currentFormCode !== FORM_NAMES.FormDirector && {
              firstText: "Go back",
            }
          }
          onNext={(next, isStepIncrement) => {
            // if (next === FORM_NAMES.Cart) {
            //   getTriggerIncoporationPayments({
            //     incorporationId: formData?._id,
            //     cartSummary: formData?.cartSummary,
            //   });
            // }
            FormElement?.onNext
              ? formNavigationHandler(
                  FormElement?.onNext?.next,
                  FormElement?.onNext?.isStepIncrement,
                  formHydration
                )
              : formNavigationHandler(next, isStepIncrement, formHydration);
          }}
          onPrevious={() =>
            navigate(
              currentFormCode === FORM_NAMES.FormDirector ? "/dashboard" : -1
            )
          }
          onSubmit={(values) => updateFormData(values, formData?._id)}
          getTriggerIncoporationPayments={() =>
            getTriggerIncoporationPayments(
              {
                incorporationId: formData?._id,
                cartSummary: formData?.cartSummary,
              },
              false
            )
          }
          formInitValues={formData}
          formUpload={onChangeFormUploadFile}
          {...FormElement.attributes}
          {...(currentFormCode === FORM_NAMES.FormDirector && {
            createFormData: createFormData,
          })}
        />
      </Flex>
    </Loader>
  );
};
