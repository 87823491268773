/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */

const breakpoints = ["1em", "30em", "45em", "64em", "80em", "86em", "100em"];

const [xs, sm, md, xm, lg, ml, xl] = breakpoints;
breakpoints.xs = xs; // Mobile - 16px
breakpoints.sm = sm; // Mobile - 480px
breakpoints.md = md; // Tab Portrait - 720px
breakpoints.xm = xm; // Tab Landscape - 1024px
breakpoints.lg = lg; // Desktop - 1280px
breakpoints.ml = ml; // Monitor / Large Desktop - 1360px
breakpoints.xl = xl; // large monitor / 4k - 1600px

// #endregion
// #region typography

// const fontMaps = fontSets.reduce(
//   (fontMap, { name, fontSize, lineHeight, fontWeight }, index) => {
//     const fm = fontMap;
//     fm.fontSizes[index] = fontSize;
//     fm.fontSizes[name] = fontSize;
//     fm.fontWeights[index] = fontWeight;
//     fm.fontWeights[name] = fontWeight;
//     fm.lineHeights[name] = lineHeight;
//     return fm;
//   },
//   {
//     fontFamily: {
//       primaryRegular: "QuireSansRegular",
//       primarySemiBold: "QuireSansSemiBold",
//       primaryBold: "QuireSansBold",
//     },
//     fontSizes: {},
//     fontWeights: {
//       medium: 500,
//     },
//     lineHeights: {},
//   }
// );
// #endregion
// #region colors

const fonts = {
  primaryRegular: "QuireSansRegular",
  primarySemiBold: "QuireSansSemiBold",
  primaryBold: "QuireSansBold",
  secondary: "Montserrat",
  smallRegular: "MyriadProRegular",
  smallSemiBold: "MyriadProSemiBold",
  smallBold: "MyriadProBold",
};
// #endregion
// #region colors
const colorSets = [
  {
    name: "primary",
    colors: [
      { name: 50, hex: "#E2F7FC" },
      { name: 200, hex: "#89DDEF" },
      { name: 500, hex: "#44BAD8" },
      { name: 700, hex: "#3795AA" },
      { name: 900, hex: "#275F66" },
    ],
  },
  {
    name: "secondary",
    colors: [
      { name: 50, hex: "#FFF3E0" },
      { name: 200, hex: "#FFB44C" },
      { name: 500, hex: "#FFA424" },
      { name: 700, hex: "#FF8800" },
      { name: 900, hex: "#EF6930" },
    ],
  },
  {
    name: "green",
    colors: [
      { name: 50, hex: "#E8F6EF" },
      { name: 200, hex: "#A3DABF" },
      { name: 500, hex: "#44B681" },
      { name: 700, hex: "#369468" },
      { name: 900, hex: "#2B6247" },
    ],
  },
  {
    name: "success",
    colors: [
      { name: 50, hex: "#E8F7E9" },
      { name: 200, hex: "#A6DFA6" },
      { name: 500, hex: "#44BF48" },
      { name: 700, hex: "#2D9D33" },
      { name: 900, hex: "#006C11" },
    ],
  },
  {
    name: "error",
    colors: [
      { name: 50, hex: "#FFE7E6" },
      { name: 200, hex: "#FFC8B8" },
      { name: 500, hex: "#FF0019" },
      { name: 700, hex: "#FF5636" },
      { name: 900, hex: "#FF0800" },
    ],
  },
  {
    name: "neutral",
    colors: [
      { name: 50, hex: "#F5F5F5" },
      { name: 100, hex: "#E9E9E9" },
      { name: 200, hex: "#DDD9CE" },
      { name: 300, hex: "#D9D9D9" },
      { name: 400, hex: "#9D9D9D" },
      { name: 500, hex: "#7B7B7B" },
      { name: 600, hex: "#555555" },
      { name: 700, hex: "#434343" },
      { name: 800, hex: "#262626" },
      { name: 900, hex: "#000000" },
    ],
  },
];

export const colors = colorSets.reduce(
  (colorMap, { name, alias = name, colors: colorSet }) => {
    const color = {};
    const cm = colorMap;
    for (let colorIndex = 0; colorIndex < colorSet.length; colorIndex++) {
      const { name: colorName, hex } = colorSet[colorIndex];
      color[colorIndex] = hex;
      color[colorName] = hex;
    }
    cm[name] = color;
    cm[alias] = color;
    return cm;
  },
  {
    // ...defaultTheme.colors,
    white: "#FFFFFF",
    "white.0": "#FFFFFF",
    black: "#000000",
    "black.0": "#000000",
  }
);

// #endregion
const radii = {
  small: "0.125rem",
  normal: "0.1875rem",
  large: "0.375rem",
  full: "10rem",
  square: 0,
};
const zIndices = {
  modal: 2000,
  tooltip: 5000,
  toast: 7000,
};

const shadows = [
  { name: "none", shadow: undefined },
  { name: "sm", shadow: "0 .075rem .15rem rgba(0,0,0,.15)" },
  { name: "m", shadow: "0px 10px 40px 0px rgba(0, 0, 0, 0.08)" },
  { name: "xl", shadow: "0 0 1rem rgba(0,0,0,.15)" },
].reduce((shadowSet, { name, shadow }, index) => {
  const s = shadowSet;
  s[name] = shadow;
  s[index] = shadow;
  return s;
});

export const theme = {
  // ...defaultTheme,
  breakpoints,
  radii,
  colors,
  zIndices,
  shadows,
  fonts,
  // fontMaps,
};
