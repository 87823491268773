import React from "react";

import { Box, Flex, Text } from "atoms";
import { ProfileAvatar } from "molecules";
import { formatRelative } from "date-fns";

export const ChatCard = ({
  avatar,
  name,
  time,
  chatContent,
  chatContentProps,
  ...props
}) => {
  return (
    <Flex p="1.2rem 1.6rem" aI="start" {...props}>
      {/* image */}
      <ProfileAvatar name={name} avatar={avatar} size="xs" />

      {/* chat */}
      <Box ml="1.6rem">
        {/* name and time */}
        <Flex aI="center">
          <Text variant="bodyMedium" fontWeight="600" color="neutral.700">
            {name}
          </Text>
          <Box w="0.4rem" h="0.4rem" bg="neutral.200" ml="1.4rem" />
          {time && (
            <Text variant="bodySmall" color="neutral.600" ml="0.6rem">
              {formatRelative(new Date(time), new Date())?.[0]?.toUpperCase()}
              {formatRelative(new Date(time), new Date())?.slice(1)}
            </Text>
          )}
        </Flex>
        {/* content */}
        <Box>
          <Text
            variant="bodySmall"
            color="neutral.600"
            css={{ whiteSpace: "pre", ...chatContentProps }}
          >
            {chatContent}
          </Text>
        </Box>
      </Box>
    </Flex>
  );
};
