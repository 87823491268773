import React from "react";
import { Button, Line, Text, Grid } from "atoms";
import { FormInput, FormSelect } from "molecules";
import FormLayout from "../FormLayout";
import FORM_NAMES from "../form.names";
import { getCountries } from "utils/utilities";
import { setNestedObjectValues } from "formik";
import { postcodeValidatorExistsForCountry } from "postcode-validator";

export const FormChangeCompanyAddress = ({
  step,
  onNext,
  values,
  onSubmit,
  isLastStep,
  setFieldValue,
  validateForm,
  setTouched,
  setErrors,
  errors,
  touched,
  ...props
}) => {
  return (
    <FormLayout
      step={step}
      title="Change in company address"
      subtitle="Provide a new preferred registered address"
      {...props}
    >
      {/* {({ values }) => {
        return (
          <Form> */}
      {/* <Box
              mt="4.8rem"
              bg="green.50"
              w="48.8rem"
              h="11.2rem"
              pt="2.4rem"
              pl="3.2rem"
            >
              <Text
                variant="subtitleSmall"
                fontWeight="600"
                color="neutral.800"
              >
                Current Address
              </Text>
              <Text variant="bodyMedium" color="neutral.800" mt="1.6rem">
                137 Market St Singapore 048943
              </Text>
            </Box> */}
      <Text
        variant="subtitleSmall"
        fontWeight="600"
        color="neutral.700"
        mt="4.8rem"
      >
        New Registered Address
      </Text>
      {/* line */}
      <Line mt="1.6rem" />
      {/* input */}
      <Grid
        gTC="repeat(2,1fr)"
        gridRowGap="3.8rem"
        gridColumnGap="1.6rem"
        mt="3rem"
      >
        <FormInput name="address.addressLine1" label="Address Line 1" />
        <FormInput name="address.addressLine2" label="Address Line 2" />
        <FormSelect
          name="address.country"
          label="Country"
          options={getCountries()}
          onChange={(country) => {
            setFieldValue(`address.countryCode`, country?.countryCode);
            setFieldValue(`address.postalCode`, "");
          }}
        />
        <FormInput
          name="address.postalCode"
          label="Postal Code"
          disabled={
            !postcodeValidatorExistsForCountry(values?.address?.countryCode)
          }
        />
      </Grid>
      {/* line */}
      <Line mt="4.8rem" />

      <Button
        width="fit-content"
        px="4rem"
        mt="4rem"
        type="button"
        onClick={() => {
          validateForm({ address: values?.address })?.then((dataErrorBag) => {
            if (dataErrorBag?.address) {
              setTouched(
                setNestedObjectValues({ address: dataErrorBag?.address }, true)
              );
              setErrors(dataErrorBag);
            } else {
              if (isLastStep) {
                onSubmit(values);
              }
              onNext(FORM_NAMES.Cart);
            }
          });
        }}
      >
        Next : Payment
      </Button>
      {/* </Form>
        );
      }} */}
    </FormLayout>
  );
};
