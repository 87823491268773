import * as React from "react";

function MyTaskIcon(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 16 12" fill="none" {...props}>
      <path
        d="M1.625 12c-.3 0-.563-.113-.787-.338a1.08 1.08 0 01-.338-.787v-9.75c0-.3.113-.563.338-.787A1.08 1.08 0 011.625 0h12.75c.3 0 .563.113.787.338.225.224.338.487.338.787v9.75c0 .3-.113.563-.338.787a1.08 1.08 0 01-.787.338H1.625zm0-1.125h12.75V2.7H1.625v8.175zM7.213 9.15l-2.4-2.4.825-.825L7.213 7.5l3.15-3.15.825.825L7.212 9.15zm-5.588 1.725v-9.75 9.75z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoMyTaskIcon = React.memo(MyTaskIcon);
export default MemoMyTaskIcon;
