import React, { useRef } from "react";
import styled from "styled-components";
import css from "@styled-system/css";

import { Box } from "atoms/Box";
import { Text } from "atoms/Text";
import { Error } from "atoms/Error";

import { Warning } from "atoms/Warning";
import { Input } from "atoms/Input";

import mergeRefs from "utils/mergeRefs";

export const InputFieldBase = styled(Box)`
  position: relative;
  width: 100%;
  display: grid;

  align-items: center;
  /* margin: 0 0 25px 0; */
  font-size: 1.6rem;

  ${({ labelHide, disabled, touched, fieldValue, type }) =>
    css({
      borderRadius: "8px",
      fontFamily: "smallRegular",
      span: {
        position: "absolute",
        top: type === "textarea" ? "0%" : "50%",
        bg: "white",
        left: "22px",
        transform: "translateY(calc(-50% + 1.5px))",
        // transform: "translateY(-50%)",
        transition: "0.2s all",
        color: "neutral.500",
        maxWidth: "calc(100% - 14px)",
      },

      ...{
        "& hint-text": {
          // display: "none",
          position: "absolute",
          left: "0%",
          fontSize: "1.3rem",
          fontWeight: "400",
          lineHeight: "2.4rem",
          mt: ".4rem",
          color: "neutral.600",
        },

        // "&:focus-within hint-text, &:active hint-text, &:focus hint-text": {
        //   display: "block",
        // },

        "& hint-text-success": {
          display: "none",
        },

        ...(touched &&
          !disabled && {
            "& hint-text-success": {
              display: "block",
              position: "absolute",
              left: "0%",
              fontSize: "1.4rem",
              fontWeight: "400",
              lineHeight: "2.4rem",
              mt: ".4rem",
              color: "success.900",
            },
          }),

        // error classes

        "&.input-field-error span": {
          color: "error.500",
          transform: "translateY(-50%)",
        },

        "&.input-field-error input": {
          borderColor: "error.500",
        },
        "&.input-field-error textarea": {
          borderColor: "error.500",
        },

        "&.input-field-error input:focus, input:active": {
          borderColor: "error.500",
        },

        // success classes
        ...(fieldValue && {
          "&.input-field-success span": {
            color: "success.900",
          },
        }),
        "&.input-field-success input": {
          borderColor: "success.900",
        },
      },

      ...(!labelHide && {
        "input, textarea": {
          "::placeholder": {
            opacity: 0,
            visibility: "hidden",
          },
          "&:focus": {
            borderColor: "neutral.400",
            "& + span": {
              color: "gray.900",
              px: "0.3rem",
            },
          },
          "&:focus + span, &:not(:placeholder-shown) + span": {
            top: "0px",
            fontSize: "1.2rem",
            px: "0.3rem",
            left: "16px",
          },
        },
        textarea: {
          "& + span": {
            top: "14px",
            transform: "none",
          },
          "&:focus + span, &:not(:placeholder-shown) + span": {
            top: "-10px",
            px: "0.3rem",
            left: "16px",
          },
        },
      }),

      ...(disabled && {
        "input, textarea": {
          "& + span": {
            top: "0px",
            fontSize: "1.2rem",
            px: "0.3rem",
            left: "12px",
          },
        },
      }),
    })}
`;

/**
 * JSX Input Field Fragment
 *
 * @param {string} label : label of the input field
 * @param {string} error : validation error
 * @param {string} warning : warning message
 * @param {boolean} labelHide : whether to show label or not {by default true}
 * @param {boolean} showLength : shows the length of the input field
 * @param {boolean} noMargin : gives 0 margin to the InputFieldBase
 * @param {boolean} noMargin : gives 0 margin to the InputFieldBase
 * @param {MutableRefObject} forwardRef : parent ref
 * @param {boolean} touched : formik touched if the input field is touched
 * @param {boolean} disabled : whether the input field is disabled
 * @param {string} hintText : suggestion hint text
 * @param {string} hintSuccessText : suggestion hint success text
 */
export const InputField = ({
  label,
  error,
  warning,
  labelHide,
  showLength,
  noMargin,
  forwardRef,
  touched,
  disabled,
  hintText,
  hintSuccessText,
  isValidationBehaviour,
  isErrorRelative,
  errorProps,
  ...props
}) => {
  const ref = (useRef < HTMLInputElement) | (null > null);

  const inputRef = mergeRefs(ref, forwardRef);
  return (
    <InputFieldBase
      as={"label"}
      labelHide={labelHide}
      disabled={disabled}
      touched={touched}
      fieldValue={props?.value}
      type={props?.type}
      margin={noMargin ? "0 !important" : undefined}
      className={
        isValidationBehaviour &&
        (error
          ? "input-field-error"
          : touched && !disabled
          ? "input-field-success"
          : "")
      }
    >
      {/* <Text
        as="label"
        color="gray.700"
        className={error ? "input-error" : ""}
        fontSize={{ xs: 7 }}
      > */}
      <Input
        placeholder={label}
        ref={inputRef}
        touched={touched}
        status={
          isValidationBehaviour
            ? error
              ? false
              : touched && !disabled
              ? true
              : null
            : null
        }
        disabled={disabled}
        {...props}
      />
      {!labelHide && (
        <Text as="span" variant="bodyLarge" color="neutral.400" ml="-0.2rem">
          {/* {disabled ? "Disabled" : label} */}
          {label}
        </Text>
      )}
      {/* </Text> */}
      {showLength && (
        <Text
          fontSize="1.2rem"
          position="absolute"
          bg="white"
          fontWeight="300"
          color="gray.700"
          right="0.7rem"
          bottom="1rem"
          css={{ pointerEvents: "none" }}
        >
          {inputRef?.current?.value?.length || 0} / {props.maxLength}
        </Text>
      )}
      {error && (
        <Box css={{ pointerEvents: "none" }} mt=".4rem">
          <Error
            text={error}
            {...(!isErrorRelative && {
              position: "absolute",
              left: "0%",
            })}
            {...errorProps}
          />
        </Box>
      )}
      {hintText && (
        <Box css={{ pointerEvents: "none" }} mt=".4rem">
          <Box as={"hint-text"}>{hintText}</Box>
        </Box>
      )}
      {!error && touched && !disabled && hintSuccessText && (
        <Box css={{ pointerEvents: "none" }} mt=".4rem">
          <Box as={"hint-text-success"}>{hintSuccessText}</Box>
        </Box>
      )}
      {warning && <Warning text={warning} />}
    </InputFieldBase>
  );
};
