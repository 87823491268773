import React, { useEffect } from "react";

import { Box, Button, Error, Grid, Line, Text } from "atoms";
import { Accordian } from "organisms";
import FormLayout from "../FormLayout";
import FORM_NAMES from "../form.names";
import { FormInput } from "molecules";
import PRODUCT_PRICES from "utils/princing.constant";
import { setNestedObjectValues } from "formik";

export const FormRemoveDirector = ({
  step,
  onAdd,
  onNext,
  values,
  setFieldValue,
  validateForm,
  setTouched,
  setErrors,
  errors,
  touched,
  ...props
}) => {
  useEffect(() => {
    if (
      Array.isArray(values?.removeMembers) &&
      values?.removeMembers?.length > 0
    ) {
      const newCartFeatures = [
        ...new Array(values?.removeMembers?.length).fill({
          name: PRODUCT_PRICES.remove_director.name,
          key: PRODUCT_PRICES.remove_director.type,
        }),
      ];

      const newCartDetails = [
        ...new Array(values?.removeMembers?.length).fill(
          PRODUCT_PRICES.remove_director
        ),
      ];

      setFieldValue("cartSummary.features", newCartFeatures);
      setFieldValue("cartDetails", newCartDetails);
    } else {
      setFieldValue("cartSummary.features", []);
      setFieldValue("cartDetails", []);
    }
  }, [values?.removeMembers]);

  return (
    <FormLayout
      step={step}
      title="Remove Directors"
      subtitle="Please provide the relevant information/documents needed to remove existing Director respectively."
      {...props}
    >
      {/* {({ values, setFieldValue }) => {
        return (
          <Form> */}
      {/* input */}

      {/* +ADD Director */}
      {(values?.removeMembers || [])?.length <= 0 && (
        <Grid gTC="1fr" gCG="3.2rem" mt="4.8rem">
          <Button
            w="30rem"
            type="button"
            onClick={() => {
              setFieldValue("removeMembers", [
                ...(values?.removeMembers || []),
                {
                  name: "",
                  email: "",
                },
              ]);
            }}
          >
            +ADD MORE RECORDS
          </Button>{" "}
        </Grid>
      )}

      <Accordian
        accordians={(values?.removeMembers || [])?.map((member, index) => {
          return {
            title: member?.name || "Exisiting Director",
            children: (
              <Grid gTC="1fr 1fr" gridGap="1.6rem" mt="2.4rem">
                <FormInput
                  name={`removeMembers[${index}].name`}
                  label="Director Full Name"
                />
                <FormInput
                  name={`removeMembers[${index}].email`}
                  label="Director Email"
                  type="email"
                />
              </Grid>
            ),
          };
        })}
        // mt="4.8rem"
        gap="2.4rem"
        onRemove={(index) => {
          let cloneDetails = JSON.parse(JSON.stringify(values?.removeMembers));
          cloneDetails = cloneDetails?.filter(
            (_, cloneIndex) => cloneIndex !== index
          );
          setFieldValue("removeMembers", cloneDetails);
        }}
      />

      {touched?.removeMembers &&
        errors?.removeMembers &&
        typeof errors?.removeMembers === "string" && (
          <Error text={errors?.removeMembers || ""} mt="2rem" />
        )}

      {(values?.removeMembers || [])?.length > 0 && (
        <Box
          mt="5rem"
          cursor="pointer"
          onClick={() => {
            setFieldValue("removeMembers", [
              ...(values?.removeMembers || []),
              {
                name: "",
                email: "",
              },
            ]);
          }}
        >
          <Text
            variant="bodyMedium"
            fontWeight="600"
            color="secondary.700"
            cursor="pointer"
          >
            +ADD MORE RECORDS
          </Text>
        </Box>
      )}

      <Line mt="4.8rem" />
      <Button
        type="button"
        width="fit-content"
        px="4rem"
        mt="4rem"
        onClick={() => {
          validateForm({
            actionType: values?.actionType,
            removeMembers: values?.removeMembers,
          })?.then((dataErrorBag) => {
            if (dataErrorBag?.removeMembers) {
              setTouched(
                setNestedObjectValues(
                  { removeMembers: dataErrorBag?.removeMembers },
                  true
                )
              );
              setErrors(dataErrorBag);
            } else {
              onNext(FORM_NAMES.FormDetails, true);
            }
          });
        }}
      >
        NEXT : Upload Documents
      </Button>
      {/* </Form>
        );
      }} */}
    </FormLayout>
  );
};
