import React from "react";
import { Box, Button, Flex, Text } from "atoms";
import MemoDownload from "assets/icons/Download";
import StatusCard from "../StatusCard";

export const FormAcknowledgment = ({
  serviceDetails,
  statusFlag,
  statusLinkFlag,
}) => {
  return (
    <Box>
      <Box>
        <StatusCard variant={serviceDetails?.[statusFlag]} />
      </Box>
      <Flex
        mt="3.2rem"
        flexDirection="column"
        bg="green.50"
        px="5.8rem"
        py="2.4rem"
        pI="center"
        jC="center"
        bR=".8rem"
        h="21.7rem"
      >
        <Text color="neutral.800" variant="subtitleSmall" fontWeight="600">
          Acknowledgment
        </Text>
        <Text
          mt="1.6rem"
          textAlign="center"
          variant="bodySmall"
          color="neutral.700"
        >
          {serviceDetails?.[statusLinkFlag]
            ? "Kindly download your acknowledgement document."
            : "Acknowledgment hasn't been uploaded yet."}
          {/* unc nunc feugiat tellus, nec viverra sem metus id dolor. Suspendisse
          potenti. Aenean enim nisi, aliquam eu consequat ultricies, posuere
          vitae arcu. Quisque lacinia nibh nec justo lobortis, et sodales dolor
          placerat. Quisque elementum, */}
        </Text>
        {serviceDetails?.[statusLinkFlag] && (
          <a href={serviceDetails?.[statusLinkFlag]} download>
            <Button
              IconProps={{ Icon: MemoDownload }}
              w="fit-content"
              px="4rem"
              mt="3.2rem"
            >
              Download
            </Button>
          </a>
        )}
      </Flex>
    </Box>
  );
};
