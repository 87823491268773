import React from "react";
import { Box, Button, Flex } from "atoms";
import { cwToast, FormToggle } from "molecules";
import FormLayout from "../FormLayout";
import MemoPlus from "assets/icons/Plus";
import MemoMinus from "assets/icons/Minus";
import FORM_NAMES from "../form.names";
import { setNestedObjectValues } from "formik";

let actionType = "";
export const FormUpdateDirector = ({
  step,
  onNext,
  values,
  setFieldValue,
  validateForm,
  setTouched,
  setErrors,
  errors,
  touched,
  ...props
}) => {
  return (
    <FormLayout
      step={step}
      title="Please select how you would like to manage the director(s) of your company."
      subtitle="Kindly select on the action you would like to take with the directors of your company. If you would like to add a new director to your company, select the add option. If you wish to terminate a current director, select the remove option."
      {...props}
    >
      {/* {({ values }) => {
        return (
          <Form> */}
      {/* input */}
      <Flex mt="4.8rem" w="35rem">
        <FormToggle name="actionType" label="Add" value="add" Icon={MemoPlus} />
        <FormToggle
          name="actionType"
          label="Remove"
          value="remove"
          ml="3.2rem"
          Icon={MemoMinus}
          isWrong
        />
      </Flex>
      {/* line */}
      <Box w="100%" h="0.5px" bg="neutral.200" mt="4.8rem" />
      <Button
        type="button"
        width="fit-content"
        px="4rem"
        mt="4rem"
        onClick={() => {
          validateForm({ actionType: values?.actionType })?.then(
            (dataErrorBag) => {
              if (dataErrorBag?.actionType) {
                setTouched(
                  setNestedObjectValues(
                    { actionType: dataErrorBag?.actionType },
                    true
                  )
                );
                setErrors(dataErrorBag);
                dataErrorBag?.actionType &&
                  cwToast("error", dataErrorBag?.actionType);
              } else {
                if (actionType !== values?.actionType) {
                  setFieldValue("cartSummary.features", []);
                  setFieldValue("cartDetails", []);
                }
                values?.actionType === "add"
                  ? onNext(FORM_NAMES.FormDirectorDetails, true)
                  : onNext(FORM_NAMES.FormRemoveDirector, true);
                actionType = values?.actionType;
              }
            }
          );
        }}
      >
        {/* NEXT : Upload Documents */}
        {values?.actionType === "add"
          ? "NEXT : Director Details"
          : "NEXT : Remove Directors"}
      </Button>
      {/* </Form>
        );
      }} */}
    </FormLayout>
  );
};
