import React from "react";
import { Box, Flex, Text } from "atoms";
import { DefaultChips } from "molecules";
import StatusCard from "../StatusCard";
import { getStatusChip } from "utils/utilities";

export const FormDocumentCreation = ({ serviceDetails, statusFlag }) => {
  return (
    <>
      <Box>
        <Flex>
          <Text variant="subtitleMedium" color="neutral.700" fontWeight="600">
            Document Creation
          </Text>
          <DefaultChips
            {...getStatusChip(serviceDetails?.[statusFlag])}
            ml="2.4rem"
          />
        </Flex>
        {/* <Text color="neutral.600" variant="bodySmall" mt=".8rem">
          Description Text
        </Text> */}

        {/* Card */}

        <Box mt="3.2rem">
          <StatusCard variant={serviceDetails?.[statusFlag]}/>
        </Box>
      </Box>
    </>
  );
};
