import React, { useCallback, useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { fontSize, variant } from "styled-system";
import { Box, Flex, Text, Loader } from "atoms";

const ButtonBase = styled(Box)`
  position: relative;
  cursor: pointer;
  outline: none;
  display: grid;
  align-items: center;

  ${variant({
    variants: {
      primary: {
        color: "neutral.50",
        bg: "secondary.700",
        //  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.16)",
        //   fontFamily: "primaryRegular",
        fontSize: "1.6rem",
        borderRadius: "8px",
        border: "none",
        cursor: "pointer",
        transition: "all .3s",
        fontWeight: 700,
        "&:active, &:hover": {
          bg: "secondary.900",
          color: "neutral.50",
        },
        "&:focus": {
          outline: "2px solid rgba(255, 136, 0, 1)",
          bg: "white",
          color: "secondary.700",
        },
      },
      outline: {
        background: "white",
        color: "secondary.700",
        fontSize: "1.6rem",
        borderRadius: "8px",
        border: "2px solid rgba(255, 136, 0, 1)",
        cursor: "pointer",
        transition: "all .3s",
        fontWeight: 600,
        "&:active, &:hover": {
          bg: "secondary.900",
          color: "neutral.50",
        },
        "&:focus": {
          bg: "secondary.700",
          color: "neutral.50",
        },
      },
      outlineSuccess: {
        background: "white",
        color: "success.900",
        fontSize: "1.6rem",
        borderRadius: "8px",
        border: "2px solid rgba(0, 108, 17, 1)",
        cursor: "pointer",
        transition: "all .3s",
        fontWeight: 600,
        "&:active, &:hover": {
          bg: "success.900",
          color: "neutral.50",
        },
        "&:focus": {
          bg: "success.900",
          color: "neutral.50",
        },
      },
      success: {
        color: "green.50",
        bg: "green.700",
        //  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.16)",
        //   fontFamily: "primaryRegular",
        fontSize: "1.6rem",
        borderRadius: "8px",
        border: "none",
        cursor: "pointer",
        transition: "all .3s",
        fontWeight: 700,
        "&:active, &:hover": {
          bg: "green.900",
          color: "green.50",
        },
        "&:focus": {
          outline: "2px solid rgba(54, 148, 104, 1)",
          bg: "white",
          color: "green.700",
        },
      },
      error: {
        bg: "white",
        color: "error.900",
        fontSize: "1.6rem",
        borderRadius: "8px",
        border: "2px solid rgba(255, 8, 0, 1)",
        cursor: "pointer",
        transition: "all .3s",
        fontWeight: 600,
        "&:active, &:hover": {
          bg: "error.50",
          color: "error.900",
        },
        "&:focus": {
          bg: "error.900",
          color: "neutral.50",
        },
      },
      dash: {
        bg: "white",
        color: "success.900",
        fontSize: "1.6rem",
        borderRadius: "8px",
        border: "2px dashed rgba(0, 108, 17, 1)",
        cursor: "pointer",
        transition: "all .3s",
        fontWeight: 600,
        "&:active, &:hover": {
          bg: "success.900",
          color: "neutral.50",
        },
        "&:focus": {
          bg: "success.700",
          color: "neutral.50",
        },
      },
      disabled: {
        color: "neutral.400",
        bg: "neutral.200",
        cursor: "not-allowed",
        borderRadius: "8px",
        border: "none",
      },
    },
  })}
  &[disabled] {
    color: "neutral.400";
    background-color: "neutral.200";
    cursor: not-allowed;
  }
`;

export const Button = ({
  disabled,
  loading,
  IconProps,
  children,
  variant: v = "primary",
  onClick,
  showAsyncLoad,
  fontWeight,
  classes,
  label,
  height,
  innerContainerProps,
  ...rest
}) => {
  const { Icon, IconCss, IconBoxCss } = IconProps || {};

  const [asyncLoading, setLoading] = useState(false);
  const onClickHandler = useCallback(
    async (...arg) => {
      setLoading(true);
      try {
        await onClick?.(arg);
      } catch (e) {
      } finally {
        setLoading(false);
      }
    },
    [onClick]
  );

  return (
    <ButtonBase
      variant={v}
      as="button"
      color="white"
      onClick={onClickHandler}
      disabled={disabled || loading || (showAsyncLoad && asyncLoading)}
      overflow="hidden"
      className={classes}
      height={height}
      {...rest}
    >
      {(loading || (showAsyncLoad && asyncLoading)) && (
        <Flex
          left={0}
          right={0}
          position="absolute"
          justifyContent="center"
          alignItems="center"
          fontSize={2}
          height="15px"
          width="15px"
          mx="auto"
        >
          <Loader loading />
        </Flex>
      )}
      <Flex aI="center" jC="center" {...innerContainerProps}>
        {Icon && (
          <Box mr=".8rem" width="1.5rem" {...IconBoxCss}>
            <Icon {...IconCss} />
          </Box>
        )}
        {label ? (
          <Text
            color="inherit"
            opacity={loading || (showAsyncLoad && asyncLoading) ? 0 : 1}
            fontSize={fontSize}
            fontWeight={fontWeight}
          >
            {label}
          </Text>
        ) : (
          <Text
            color="inherit"
            opacity={loading || (showAsyncLoad && asyncLoading) ? 0 : 1}
            fontSize={fontSize}
            fontWeight={fontWeight}
          >
            {children}
          </Text>
        )}
      </Flex>
    </ButtonBase>
  );
};

Button.defaultProps = {
  as: "button",
  width: "100%",
  fontSize: "1.6rem",
  height: "5rem",
};

Button.propTypes = {
  // label: PropTypes.string,
  fontSize: PropTypes.string,
  as: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};
