import React from "react";
import { Box, Button, Flex, Grid, Text } from "atoms";
import MemoVerifiedSign from "assets/icons/VerifiedSign";
import MemoPendingIcon from "assets/icons/Pending";
import MemoProgressIcon from "assets/icons/Progress";

const variants = {
  pending: {
    bg: "rgba(255, 86, 54, 0.16)",
    textColor: "error.700",
    borderColor: "error.200",
    Icon: MemoPendingIcon,
  },
  overdue: {
    bg: "rgba(255, 8, 0, 0.16)",
    textColor: "error.900",
    borderColor: "error.200",
    Icon: MemoPendingIcon,
  },
  completed: {
    bg: "rgba(0, 108, 17, 0.16)",
    textColor: "green.700",
    borderColor: "success.500",
    Icon: MemoVerifiedSign,
  },
  "in-progress": {
    bg: "rgba(255, 164, 36, 0.16)",
    textColor: "secondary.700",
    borderColor: "secondary.200",
    Icon: MemoProgressIcon,
  },
  created: {
    bg: "primary.50",
    textColor: "primary.700",
    borderColor: "primary.700",
    Icon: MemoProgressIcon,
  },
};

export const StatusCard = ({ variant = "created" }) => {
  return (
    <>
      <Box bg={variants?.[variant]?.bg} bR="0.8rem">
        <Flex flexDirection="row" aI="center" jC="space-between" px="2.4rem">
          <Box
            position="absolute"
            top="0"
            left="0"
            w="0.7rem"
            h="100%"
            bg={variants?.[variant]?.textColor}
            bR=".8rem 0 0 .8rem"
          />
          <Grid gridAutoFlow="column" py="2.4rem" gridColumnGap="1.6rem">
            <Box color={variants?.[variant]?.textColor} mt="1.1rem">
              {
                {
                  pending: <MemoPendingIcon width="2.6rem" height="3.2rem" />,
                  overdue: <MemoPendingIcon width="2.6rem" height="3.2rem" />,
                  completed: (
                    <MemoVerifiedSign width="2.6rem" height="3.2rem" />
                  ),
                  "in-progress": (
                    <MemoProgressIcon width="2.6rem" height="3.2rem" />
                  ),
                  created: <MemoProgressIcon width="2.6rem" height="3.2rem" />,
                }[variant]
              }
            </Box>
            <Grid gridAutoFlow="row">
              <Text
                variant="subtitleMedium"
                color="neutral.700"
                fontWeight="600"
              >
                Your task is{" "}
                {
                  {
                    pending: "pending",
                    overdue: "ovedue",
                    completed: "is completed",
                    "in-progress": "in process",
                    created: "in process",
                  }[variant]
                }
              </Text>
              <Text variant="bodySmall" color="neutral.500" mt=".5rem">
                {/* Kindly wait service is under processing or chat with us. */}
                {
                  {
                    pending: "Service is pending. Kindly chat with us.",
                    overdue: "Service is overdue. Kindly chat with us.",
                    completed: "Enjoy! Service is completed.",
                    "in-progress":
                      "Kindly wait service is under processing or chat with us.",
                    created:
                      "Kindly wait service is under processing or chat with us.",
                  }[variant]
                }
              </Text>
            </Grid>
          </Grid>

          {/* <Button w="fit-content" px="2.4rem" h="3.2rem">
            Chat with us
          </Button> */}
        </Flex>
      </Box>
    </>
  );
};
