import * as Yup from "yup";
import {
  postcodeValidator,
  postcodeValidatorExistsForCountry,
} from "postcode-validator";
import { compareAsc } from "date-fns";

// const phoneEmailRegExp = /(^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$)|(^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$)/
// const phoneRegExp = /^((\([0-9]{3}\))[ ][0-9]{3}-[0-9]{4})?$/
const name = /^[aA-zZ\s]+$/;
const address = /^(?=[a-zA-Z0-9&/()_[\]{}|\\,.?: -]*$)(?!.*[<;>'";`%@#$^~*])/;
const postalCode = /^[0-9]{6}/;

// const decimalNumber = /^(?:\d{1,9}(?:\.\d{0,6})?)?$/
// const latLong = /^([-+]?\d{1,3}[.]\d+)$/
// const costRegExp = /^(?:\d{1,7}(?:\.\d{0,2})?)?$/
// const freighNoRegExp = /^[_A-z0-9]*((|\s)[_A-z0-9])*$/
// const domain = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-zA-Z0-9]+([-.]{1}[a-zA-Z0-9]+)*\.[a-zA-Z0-9]{2,20}(^\/*)?$/
export const domainRegex =
  /^((http|https):\/\/)[a-zA-Z0-9]+([-.]{1}[a-zA-Z0-9]+)*\.[a-zA-Z]{2,15}(:[0-9]{1,5})?(\/.*)?$/;
// const number = /^[0-9]*$/;
const passwordRegExp =
  /^(?=.*?[0-9])(?=.*?[#?!@$%^&*-])(?=.*[A-Z])[A-Za-z\d$@$!%*#?&]{7,20}$/;

export const loginValidationSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .min(3, "Mininum 3 characters")
    .max(100, "Maximum 100 characters")
    .email("Please enter valid email")
    .required("Please enter email"),
  password: Yup.string()
    .required("Please enter password")
    .min(7, "Mininum 7 characters")
    .max(20, "Maximum 20 characters"),
});

export const signupValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .trim()
    .min(1, "Mininum 1 characters")
    .max(50, "Maximum 50 characters")
    .matches(name, "Please enter valid first name")
    .required("Please enter first name"),
  lastName: Yup.string()
    .trim()
    .max(50, "Maximum 50 characters")
    .matches(name, "Please enter valid last name")
    .required("Please enter last name"),
  email: Yup.string()
    .trim()
    .min(3, "Mininum 3 characters")
    .max(100, "Maximum 100 characters")
    .email("Please enter valid email")
    .required("Please enter email"),
  password: Yup.string()
    .required("Please enter password")
    .matches(
      passwordRegExp,
      "Enter a password between 7 to 20 characters which contain at least one numeric digit, one uppercase letter and a special character."
    ),
  confirm_password: Yup.string()
    .oneOf([Yup.ref("password")], "Passwords don't match")
    .required("Please re-enter password"),
  // phone: Yup.object().shape({
  //   phoneNumber: Yup.string().nullable(true),
  // }),
  phone: Yup.string().nullable(true),
});

export const resetpwdValidationSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .min(3, "Mininum 3 characters")
    .max(100, "Maximum 100 characters")
    .email("Please enter valid email")
    .required("Please enter email"),
});

export const compareValidationSchema = Yup.object().shape({
  portfolio: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required("Please select portfolio"),
      page: Yup.string().required("Please select page"),
      device: Yup.string().required("Please select device"),
    })
  ),
});

export const setpwdValidationSchema = Yup.object().shape({
  password: Yup.string()
    .required("Please enter password")
    .matches(
      passwordRegExp,
      "Enter a password between 7 to 20 characters which contain at least one numeric digit, one uppercase letter and a special character."
    ),
  confirm_password: Yup.string()
    .oneOf([Yup.ref("password")], "Passwords do not match")
    .required("Please re-enter password")
    .trim(),
});

export const changePasswordValidationSchema = Yup.object().shape({
  oldPassword: Yup.string().required("Please enter password"),
  newPassword: Yup.string()
    .required("Please enter password")
    .matches(
      passwordRegExp,
      "Enter a password between 7 to 20 characters which contain at least one numeric digit, one uppercase letter and a special character."
    ),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("newPassword")], "Password's not match")
    .required("Please re-enter password")
    .trim(),
});

// export const otpValidationSchema = Yup.object().shape({
//   digit_1: Yup.string().min(1).max(1).required().matches(number).trim(),
//   digit_2: Yup.string().min(1).max(1).required().matches(number).trim(),
//   digit_3: Yup.string().min(1).max(1).required().matches(number).trim(),
//   digit_4: Yup.string().min(1).max(1).required().matches(number).trim(),
// });

export const personaldetailValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .max(50, "Maximum 50 characters")
    .required("Please enter first name")
    .matches(name, "Please enter valid first name")
    .trim(),
  lastName: Yup.string()
    .max(50, "Maximum 50 characters")
    .required("Please enter last name")
    .matches(name, "Please enter valid last name")
    .trim(),
  email: Yup.string()
    .email("Please enter valid email")
    .required("Please enter email")
    .min(3, "Mininum 3 characters")
    .max(100, "Maximum 100 characters")
    .trim(),
  phone: Yup.string()
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      "Phone number is not valid"
    )
    .trim(),
});

export const teamDetailValidationSchema = Yup.object().shape({
  name: Yup.string()
    .max(50, "Maximum 50 characters")
    .required("Please enter first name")
    .trim(),
});

export const teamMemberInviteValidation = Yup.object().shape({
  members: Yup.array().of(
    Yup.object().shape({
      email: Yup.string()
        .email("Please enter valid email")
        .required("Please enter email")
        .min(3, "Mininum 3 characters")
        .max(100, "Maximum 100 characters")
        .trim(),
    })
  ),
});

export const scheduleAuditValidation = Yup.object().shape({
  formFactor: Yup.string().nullable(true).required("Choose a device"),
  portfolio: Yup.string().nullable(true).required("Choose a portfolio"),
  pages: Yup.array().of(
    Yup.object().shape({
      page: Yup.object().required("Required").typeError("Required"),
      pickerTool: Yup.string().nullable(true).required("Choose a frequency"),
      startingTime: Yup.string().required("Required"),
      hourInterval: Yup.number()
        .typeError("Invalid number")
        .integer("Invalid Number")
        .when("pickerTool", {
          is: { label: `Every "x" hours`, value: "everyxhours" },
          then: Yup.number()
            .typeError("Invalid number")
            .integer("Invalid Number")
            .moreThan(0, "Enter a non-zero number"),
        }),
    })
  ),
  isSlackChannelEnable: Yup.boolean(),
  channel: Yup.string()
    .nullable(true)
    .when("isSlackChannelEnable", {
      is: true,
      then: Yup.string().nullable(true).required("Choose a channel"),
    }),
});

export const pagesMultipleCompareValidation = Yup.object().shape({
  pages: Yup.array().of(
    Yup.object().shape({
      pageId: Yup.string().required("Choose a page"),
      portfolioId: Yup.object().required("Choose a portfolio"),
    })
  ),
  device: Yup.string().required("Choose a device"),
});

export const changePageModalSchema = Yup.object().shape({
  page: Yup.string().required("Please choose a page"),
});

export const incorporationValidateSchema = Yup.object().shape({
  firstName: Yup.string()
    .max(50, "Maximum 50 characters")
    .required("Please enter first name")
    .matches(name, "Please enter valid first name")
    .trim(),
  lastName: Yup.string()
    .max(50, "Maximum 50 characters")
    .required("Please enter last name")
    .matches(name, "Please enter valid last name")
    .trim(),
  email: Yup.string()
    .email("Please enter valid email")
    .required("Please enter email")
    .min(3, "Mininum 3 characters")
    .max(100, "Maximum 100 characters")
    .trim(),
  phone: Yup.string()
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      "Phone number is not valid"
    )
    .trim(),
  address: Yup.string()
    .min(3, "Mininum 3 characters")
    .max(100, "Maximum 100 characters")
    .required("Please enter address")
    .matches(address, "Please enter valid address")
    .trim(),

  postalCode: Yup.string()
    .max(6, "Postal code cannot exceed 6 characters")
    .required("Postal code required")
    .matches(postalCode, "Please enter correct postal code"),
});

export const otpValidationSchema = Yup.object().shape({
  otp: Yup.string()
    .min(6, "Mininum 6 characters")
    .matches(/^(\S+$)/g, "* This field cannot contain only blankspaces")
    .required("Please enter OTP"),
});

export const resetPasswordValidation = Yup.object().shape({
  password: Yup.string()
    .required("Please enter password")
    .matches(
      passwordRegExp,
      "Enter a password between 7 to 20 characters which contain at least one numeric digit, one uppercase letter and a special character."
    ),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password")], "Passwords don't match")
    .required("Please re-enter password"),
});

export const profileDetailsValidation = Yup.object().shape({
  firstName: Yup.string().required("First Name can't be empty"),
  lastName: Yup.string().required("Last Name can't be empty"),
});

export const changePasswordValidation = Yup.object().shape({
  oldPassword: Yup.string()
    .required("Please enter password")
    .matches(
      passwordRegExp,
      "Enter a password between 7 to 20 characters which contain at least one numeric digit, one uppercase letter and a special character."
    ),
  newPassword: Yup.string()
    .required("Please enter password")
    .matches(
      passwordRegExp,
      "Enter a password between 7 to 20 characters which contain at least one numeric digit, one uppercase letter and a special character."
    ),
});

// --- #########
// --- #########
// --- #########
// --- #########
// --- #########
// --- #########

// common access objects yup

const addressObject = Yup.object().shape({
  addressLine1: Yup.string().required("* required"),
  addressLine2: Yup.string().nullable(true),
  country: Yup.string().required("* required"),
  countryCode: Yup.string().required("* required"),
  postalCode: Yup.string()
    .nullable(true)
    .when("countryCode", {
      is: (v) => !!v && postcodeValidatorExistsForCountry(v),
      then: Yup.string()
        .required("* required")
        .test("is-postalCode", "Invalid Postal Code", (val, valBag) => {
          const isTestified =
            // valBag?.parent?.countryCode &&
            // postcodeValidatorExistsForCountry(valBag?.parent?.countryCode) &&
            postcodeValidator(val, valBag?.parent?.countryCode);
          return isTestified;
        }),
      otherwise: Yup.string().nullable(true),
    }),
});

const directorObj = Yup.array().of(
  Yup.object().shape({
    firstName: Yup.string().required("* required"),
    lastName: Yup.string().required("* required"),
    identificationType: Yup.string().required("* required"),
    identification: Yup.string().required("* required"),
    designation: Yup.string().required("* required"),
    email: Yup.string()
      .trim()
      .min(3, "Mininum 3 characters")
      .max(100, "Maximum 100 characters")
      .email("Please enter valid email")
      .required("* required"),
    dob: Yup.string().required("* required"),
    mobile: Yup.string().required("* required"),
    address: addressObject,
    documents: Yup.array()
      .of(
        Yup.object().shape({
          id: Yup.string().required("* required"),
          url: Yup.string().required("* required"),
        })
      )
      .min(2, "upload all documents"),
  })
);
const individualShareholderObj = Yup.array().of(
  Yup.object().shape({
    firstName: Yup.string().required("* required"),
    lastName: Yup.string().required("* required"),
    identificationType: Yup.string().required("* required"),
    identification: Yup.string().required("* required"),
    designation: Yup.string().required("* required"),
    email: Yup.string()
      .trim()
      .min(3, "Mininum 3 characters")
      .max(100, "Maximum 100 characters")
      .email("Please enter valid email")
      .required("* required"),
    dob: Yup.string().required("* required"),
    phone: Yup.string().required("* required"),
    address: addressObject,
    numberOfCompanyShares: Yup.number()
      .typeError("Invalid number")
      .required("* required"),
    valuePerShare: Yup.number()
      .typeError("Invalid number")
      .required("* required"),
    // firstFinancialYearRevenue: Yup.string()
    //   .typeError("Invalid number")
    //   .required("* required"),
    currency: Yup.string().typeError("Invalid number").required("* required"),
    documents: Yup.array()
      .of(
        Yup.object().shape({
          id: Yup.string().required("* required"),
          url: Yup.string().required("* required"),
        })
      )
      .min(2, "upload all documents"),
  })
);

const corporateShareholderObj = Yup.array().of(
  Yup.object().shape({
    companyName: Yup.string().required("* required"),
    uen: Yup.string().required("* required"),
    countryOfIncorporation: Yup.string().required("* required"),
    doi: Yup.string().required("* required"),
    website: Yup.string().required("* required"),
    industry: Yup.string().required("* required"),
    email: Yup.string()
      .trim()
      .min(3, "Mininum 3 characters")
      .max(100, "Maximum 100 characters")
      .email("Please enter valid email")
      .required("* required"),
    address: addressObject,
    numberOfCompanyShares: Yup.number()
      .typeError("Invalid number")
      .required("* required"),
    valuePerShare: Yup.number()
      .typeError("Invalid number")
      .required("* required"),
    // firstFinancialYearRevenue: Yup.string()
    //   .typeError("Invalid number")
    //   .required("* required"),
    currency: Yup.string().typeError("Invalid number").required("* required"),
    documents: Yup.array()
      .of(
        Yup.object().shape({
          id: Yup.string().required("* required"),
          url: Yup.string().required("* required"),
        })
      )
      .min(3, "upload all documents"),
  })
);

// cs begins
export const tranferCompanySecretaryValidation = Yup.object().shape({
  usingCWCorporateSecretarialServices:
    Yup.string().required("Choose an action"),
  previousCorporateSecretaryName: Yup.string().required("* required"),
  previousCorporateSecretaryEmail: Yup.string()
    .trim()
    .min(3, "Mininum 3 characters")
    .max(100, "Maximum 100 characters")
    .email("Please enter valid email")
    .test("verify_email", "Unverified Email", (val) => {
      return true;
      // return emailExistence.check(val);
      // return new Promise((resolve, reject) => {
      //   validate(val)
      //     .then((res) => {
      //       // exists
      //       res?.validators?.smtp?.valid ? resolve(false) : resolve(true);
      //     })
      //     .catch(() => {
      //       // note exists
      //       resolve(true);
      //     });
      // });
    })
    .required("* required"),
  newCorporateSecretaryName: Yup.string().when(
    "usingCWCorporateSecretarialServices",
    {
      is: (v) => !v,
      then: Yup.string().required("* required"),
    }
  ),
  newCorporateSecretaryEmail: Yup.string()
    .trim()
    .min(3, "Mininum 3 characters")
    .max(100, "Maximum 100 characters")
    .email("Please enter valid email")
    .when("usingCWCorporateSecretarialServices", {
      is: (v) => !v,
      then: Yup.string()
        .trim()
        .min(3, "Mininum 3 characters")
        .max(100, "Maximum 100 characters")
        .email("Please enter valid email")
        .required("* required"),
    }),
  documents: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.string().required("* required"),
        url: Yup.string().required("* required"),
      })
    )
    .min(1, "upload all documents"),
});

export const csChangeInBuisnessValidation = Yup.object().shape({
  activityType: Yup.string().required("* required"),
  primaryActivityCode: Yup.string().when("activityType", {
    is: (v) => v === "primary" || v === "both",
    then: Yup.string().required("* required"),
  }),
  primaryBusinessDescription: Yup.string().when("activityType", {
    is: (v) => v === "primary" || v === "both",
    then: Yup.string().required("* required"),
  }),
  secondaryActivityCode: Yup.string().when("activityType", {
    is: (v) => v === "secondary" || v === "both",
    then: Yup.string().required("* required"),
  }),
  secondaryBusinessDescription: Yup.string().when("activityType", {
    is: (v) => v === "secondary" || v === "both",
    then: Yup.string().required("* required"),
  }),
});

export const csChangeComapnyAddressValidation = Yup.object().shape({
  useCWaddress: Yup.string().required("Choose an action"),
  effectiveDate: Yup.string()
    .nullable()
    .when("useCWaddress", {
      is: (v) => v,
      then: Yup.string()
        .required("* required")
        .test("is_old", "Only future dates allowed", (val) => {
          const valDate = new Date(val);
          const curDate = new Date();

          valDate.setHours(0, 0, 0, 0);
          curDate.setHours(0, 0, 0, 0);

          return (
            compareAsc(valDate, curDate) === 1 ||
            compareAsc(valDate, curDate) === 0
          );
        }),
    }),
  address: addressObject,
});

export const csToActDirectorValidation = Yup.object().shape({
  documents: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.string().required("* required"),
        url: Yup.string().required("* required"),
      })
    )
    .min(1, "upload all documents"),
});
export const csToActShareholderValidation = Yup.object().shape({
  documents: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.string().required("* required"),
        url: Yup.string().required("* required"),
      })
    )
    .min(1, "upload all documents"),
});

export const csDirectorValidation = Yup.object().shape({
  actionType: Yup.string().required("Choose an action"),
  directorDetails: directorObj.when("actionType", {
    is: (v) => v === "add",
    then: directorObj.min(1, "Add atleast 1 director"),
  }),
  removeMembers: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string().required("* required"),
        email: Yup.string()
          .trim()
          .min(3, "Mininum 3 characters")
          .max(100, "Maximum 100 characters")
          .email("Please enter valid email")
          .required("* required"),
      })
    )
    .when("actionType", {
      is: (v) => v === "remove",
      then: Yup.array()
        .of(
          Yup.object().shape({
            name: Yup.string().required("* required"),
            email: Yup.string()
              .trim()
              .min(3, "Mininum 3 characters")
              .max(100, "Maximum 100 characters")
              .email("Please enter valid email")
              .required("* required"),
          })
        )
        .min(1, "Add atleast 1 director"),
    }),
  documents: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.string().required("* required"),
        url: Yup.string().required("* required"),
      })
    )
    .test("check_docs", "upload all document", (val, valBag) => {
      if (valBag?.parent?.actionType === "add") {
        if (val?.length !== 2) {
          return false;
        }
      }
      if (valBag?.parent?.actionType === "remove") {
        if (val?.length !== 3) {
          return false;
        }
      }
      return true;
    }),
});

export const csShareholderValidation = Yup.object().shape({
  actionType: Yup.string().required("Choose an action"),
  shareholderDetails: individualShareholderObj.test(
    "validate",
    "Add atleast 1 shareholder",
    (val, valBag) => {
      if (valBag?.parent?.actionType === "add") {
        if (
          (val?.length || 0) +
            (valBag?.parent?.corporateShareholderDetails?.length || 0) <=
          0
        ) {
          return false;
        }
      }

      return true;
    }
  ),
  corporateShareholderDetails: corporateShareholderObj.test(
    "validate",
    "Add atleast 1 shareholder",
    (val, valBag) => {
      if (valBag?.parent?.actionType === "add") {
        if (
          (val?.length || 0) +
            (valBag?.parent?.shareholderDetails?.length || 0) <=
          0
        ) {
          return false;
        }
      }

      return true;
    }
  ),
  removeMembers: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string().required("* required"),
        email: Yup.string()
          .trim()
          .min(3, "Mininum 3 characters")
          .max(100, "Maximum 100 characters")
          .email("Please enter valid email")
          .required("* required"),
      })
    )
    .test("validate", "Add atleast 1 shareholder", (val, valBag) => {
      if (valBag?.parent?.actionType === "remove") {
        if (val?.length <= 0) {
          return false;
        }
      }

      return true;
    }),
  transferShares: Yup.array()
    .of(
      Yup.object().shape({
        from: Yup.object().shape({
          name: Yup.string().required("* required"),
          email: Yup.string()
            .trim()
            .min(3, "Mininum 3 characters")
            .max(100, "Maximum 100 characters")
            .email("Please enter valid email")
            .required("* required"),
        }),
        to: Yup.object().shape({
          name: Yup.string().required("* required"),
          email: Yup.string()
            .trim()
            .min(3, "Mininum 3 characters")
            .max(100, "Maximum 100 characters")
            .email("Please enter valid email")
            .required("* required"),
        }),
        numberOfShares: Yup.number()
          .typeError("Invalid number")
          .required("* required"),
        valuePerShare: Yup.number()
          .typeError("Invalid number")
          .required("* required"),
      })
    )
    .test("validate", "Add atleast 1 shareholder", (val, valBag) => {
      if (valBag?.parent?.actionType === "update") {
        if (val?.length <= 0) {
          return false;
        }
      }

      return true;
    }),
});

// cs ends
// incorporation begins

export const incorporationLocalForeignValidation = Yup.object().shape({
  directorType: Yup.string().required("Select your residency status"),
});

export const incorporationCompanyDetailsValidation = Yup.object().shape({
  companyName: Yup.string().required("* required"),
  primaryCompanyActivity: Yup.string().required("* required"),
  primaryBusinessDescription: Yup.string().required("* required"),
  secondaryCompanyActivity: Yup.string().required("* required"),
  secondaryBusinessDescription: Yup.string().required("* required"),
  financialYear: Yup.string()
    .nullable(true)
    .required("* required")
    .typeError("* required"),
});
export const incorporationCorporateAddressServiceValidation =
  Yup.object().shape({
    usingCorporateAddressDetails: Yup.string().required(
      "Would you like to use Chartswood’s corporate address services?"
    ),
  });
export const incorporationCorporateAddressDetailsValidation =
  Yup.object().shape({
    corporateAddressDetails: addressObject,
  });
export const incorporationDirectorServiceValidation = Yup.object().shape({
  usingDirectorDetails: Yup.string().required("Choose an option"),
});
export const incorporationDirectorValidation = Yup.object().shape({
  directorDetails: directorObj.test(
    "validate",
    "Add atleast 1 director",
    (val, valBag) => {
      if (val?.length <= 0) {
        return false;
      }

      return true;
    }
  ),
});
export const incorporationShareholderServiceValidation = Yup.object().shape({
  usingShareholderDetails: Yup.string().required("Choose an option"),
});
export const incorporationShareholderValidation = Yup.object().shape({
  shareholderDetails: individualShareholderObj.test(
    "validate",
    "Add atleast 1 shareholder",
    (val, valBag) => {
      if (
        (val?.length || 0) +
          (valBag?.parent?.corporateShareholderDetails?.length || 0) <=
        0
      ) {
        return false;
      }

      return true;
    }
  ),
  corporateShareholderDetails: corporateShareholderObj.test(
    "validate",
    "Add atleast 1 shareholder",
    (val, valBag) => {
      if (
        (val?.length || 0) +
          (valBag?.parent?.shareholderDetails?.length || 0) <=
        0
      ) {
        return false;
      }

      return true;
    }
  ),
});

// incorporation ends

export const personaldetailValidation = Yup.object().shape({
  firstName: Yup.string().required("First Name can't be empty"),
  lastName: Yup.string().required("Last Name can't be empty"),
  postalCode: Yup.string()
    .nullable(true)
    .when("countryCode", {
      is: (v) => !!v && postcodeValidatorExistsForCountry(v),
      then: Yup.string().test(
        "is-postalCode",
        "Invalid Postal Code",
        (val, valBag) => {
          const isTestified =
            // valBag?.parent?.countryCode &&
            // postcodeValidatorExistsForCountry(valBag?.parent?.countryCode) &&
            postcodeValidator(val, valBag?.parent?.countryCode);
          return isTestified;
        }
      ),
      otherwise: Yup.string().nullable(true),
    }),
});
