import * as React from "react";

function Accountdetails(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 14 14" fill="none" {...props}>
      <path
        d="M1.375 11.931a9.41 9.41 0 012.55-1.697A7.35 7.35 0 017 9.588c1.1 0 2.125.215 3.075.646.95.432 1.8.997 2.55 1.697V1.375H1.375v10.556zm5.662-3.825c.726 0 1.338-.25 1.838-.75s.75-1.112.75-1.837c0-.725-.25-1.338-.75-1.838s-1.112-.75-1.838-.75c-.724 0-1.337.25-1.837.75s-.75 1.113-.75 1.838c0 .725.25 1.337.75 1.837s1.112.75 1.837.75zM1.375 13.75c-.3 0-.563-.113-.787-.338a1.08 1.08 0 01-.338-.787V1.375c0-.3.112-.563.338-.787A1.08 1.08 0 011.375.25h11.25c.3 0 .563.112.787.338.225.225.338.487.338.787v11.25c0 .3-.113.563-.338.787a1.08 1.08 0 01-.787.338H1.375zm1.031-1.125h9.188v-.169a7.377 7.377 0 00-2.175-1.303A6.77 6.77 0 007 10.713a6.77 6.77 0 00-2.419.44c-.775.294-1.5.728-2.175 1.303v.169zm4.631-5.644c-.4 0-.743-.143-1.03-.431a1.407 1.407 0 01-.432-1.031c0-.413.144-.76.431-1.04a1.423 1.423 0 011.031-.423c.413 0 .76.14 1.041.422.281.281.422.628.422 1.04 0 .4-.14.745-.422 1.032a1.398 1.398 0 01-1.04.431z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoAccountdetails = React.memo(Accountdetails);
export default MemoAccountdetails;
