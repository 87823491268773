import { useCallback, useReducer } from "react";
import { useNavigate } from "react-router-dom";

import api from "utils/api";
import { createContainer } from "utils/context";
import { removeCookie } from "utils/cookie";
import { persist, hydrate } from "utils/persist";
import { AUTH_TOKEN_COOKIE } from "utils/api/getTokens";
import { AUTH_ACTIONS } from "./actions";
import { authReducer, initialState } from "./reducers";
import { cwToast } from "molecules";

export const { useContext: useAuth, Provider: AuthProvider } = createContainer(
  () => {
    const [state, dispatch] = useReducer(authReducer, {
      ...initialState,
      isLoggedIn:
        !!hydrate(AUTH_TOKEN_COOKIE) && hydrate(AUTH_TOKEN_COOKIE) !== "null",
      // isLayoutFT: hydrate("isLayoutFT") === "true" ? true : false,
      ...(!!hydrate(AUTH_TOKEN_COOKIE) &&
        hydrate(AUTH_TOKEN_COOKIE) !== "null" && {
          userData: hydrate("userData"),
        }),
    });
    const navigate = useNavigate();

    const login = useCallback(
      /**
       * @param {object} formData {email, password}
       */
      async (formData) => {
        dispatch(AUTH_ACTIONS.login.loading());
        try {
          const { data } = await api("/auth/login", {
            method: "post",
            data: formData,
          });

          if (data?.token === null) {
            dispatch(
              AUTH_ACTIONS.login.success({
                isLoggedIn: false,
                ...data?.data,
                is2StepVerification: true,
              })
            );
            persist("userData", { ...data?.data });
          } else {
            persist(AUTH_TOKEN_COOKIE, data?.token);
            dispatch(
              AUTH_ACTIONS.login.success({
                isLoggedIn: true,
                ...data?.data,
                is2StepVerification: false,
              })
            );
            persist("userData", { ...data?.data });
          }

          // switchLayoutFT();
        } catch (e) {
          dispatch(AUTH_ACTIONS.login.failure(e?.message));
        }
      },
      []
    );

    const loginOTPVerify = useCallback(
      /**
       * @param {string} email
       * @param {string} otp
       */
      async (email, otp) => {
        dispatch(AUTH_ACTIONS.otp.loading());
        try {
          const { data } = await api("/auth/verify", {
            method: "post",
            data: { email, otp },
          });

          persist(AUTH_TOKEN_COOKIE, data?.token);
          dispatch(
            AUTH_ACTIONS.login.success({ isLoggedIn: true, ...data?.data })
          );
          persist("userData", { ...data?.data });

          dispatch(AUTH_ACTIONS.otp.success());
        } catch (e) {
          dispatch(AUTH_ACTIONS.otp.failure(e?.message));
        }
      },
      []
    );

    const loginOTPResend = useCallback(
      /**
       * @param {string} email
       */
      async (email) => {
        dispatch(AUTH_ACTIONS.resend.loading());
        try {
          await api("/auth/resend", {
            method: "post",
            data: { email },
          });

          dispatch(AUTH_ACTIONS.resend.success());
        } catch (e) {
          dispatch(AUTH_ACTIONS.resend.failure(e?.message));
        }
      },
      []
    );

    const signup = useCallback(
      /**
       * @param {string} firstName
       * @param {string} lastName
       * @param {string} email
       * @param {string} password
       * @param {string} phoneNumber
       * @param {boolean} loginWithOtp
       * @param {boolean} allowProductUpdates
       */
      async ({
        firstName,
        lastName,
        email,
        password,
        phoneNumber,
        loginWithOtp,
        allowProductUpdates,
      }) => {
        try {
          const reqData = {
            firstName,
            lastName,
            email,
            password,
            phoneNumber,
            // phoneNumber: phoneNumber?.dialCode
            //   ? `${phoneNumber?.dialCode} ${phoneNumber?.phoneNumber}`
            //   : "",
            loginWithOtp,
            allowProductUpdates,
          };

          const { data } = await api("/auth/signup", {
            method: "post",
            data: reqData,
          });

          dispatch(AUTH_ACTIONS.signup(data.data));
          // navigate("/resend-email");
          navigate("/login");
          cwToast("success", "Account Created", "Login to continue");
        } catch (e) {
          console.error(e);
          throw e;
        }
      },
      [navigate]
    );

    const forgotPassword = useCallback(
      /**
       * @param {string} email
       */
      async ({ email }) => {
        dispatch(AUTH_ACTIONS.forgotPassword.loading());
        try {
          await api("/auth/forgot", {
            method: "post",
            data: { email },
          });

          dispatch(AUTH_ACTIONS.forgotPassword.success({ email }));
        } catch (e) {
          dispatch(AUTH_ACTIONS.forgotPassword.failure(e?.message));
        }
      },
      []
    );

    const forgotPasswordErrorStateReset = useCallback(async () => {
      dispatch(AUTH_ACTIONS.forgotPassword.failure(null));
    }, []);

    const forgotPasswordOTPVerify = useCallback(
      /**
       * @param {string} email
       * @param {string} otp
       */
      async (email, otp) => {
        dispatch(AUTH_ACTIONS.otp.loading());
        try {
          await api("/auth/forgot/verify", {
            method: "post",
            data: { email, otp },
          });

          dispatch(AUTH_ACTIONS.otp.success());
        } catch (e) {
          dispatch(AUTH_ACTIONS.otp.failure(e?.message));
        }
      },
      []
    );

    const forgotPasswordOTPVerifyErrorStateReset = useCallback(async () => {
      dispatch(AUTH_ACTIONS.otp.failure(null));
    }, []);

    const forgotPasswordOTPResend = useCallback(
      /**
       * @param {string} email
       */
      async (email) => {
        dispatch(AUTH_ACTIONS.resend.loading());
        try {
          await api("/auth/forgot/resend", {
            method: "post",
            data: { email },
          });

          dispatch(AUTH_ACTIONS.resend.success());
        } catch (e) {
          dispatch(AUTH_ACTIONS.resend.failure(e?.message));
        }
      },
      []
    );

    const resetPassword = useCallback(
      /**
       * @param {string} email
       */
      async (email, password) => {
        dispatch(AUTH_ACTIONS.resetPassword.loading());
        try {
          const { data } = await api("/auth/reset", {
            method: "post",
            data: { email, password },
          });

          persist(AUTH_TOKEN_COOKIE, data?.token);
          dispatch(AUTH_ACTIONS.login.success(data?.data));
          persist("userData", { ...data?.data });

          dispatch(AUTH_ACTIONS.resetPassword.success(data?.data));
          cwToast("success", "Password Updated", "Yay! Everything worked!");
        } catch (e) {
          dispatch(AUTH_ACTIONS.resetPassword.failure(e?.message));
        }
      },
      []
    );

    const updateUser = useCallback(
      /**
       * @param {string} firstName
       * @param {string} lastName
       * @param {string} dob
       * @param {string} city
       * @param {string} state
       * @param {string} country
       * @param {string} countryCode
       * @param {string} avatar
       * @param {string} phoneNumber
       * @param {string} addressOne
       * @param {string} addressTwo
       * @param {string} postalCode
       */
      async (reqData) => {
        dispatch(AUTH_ACTIONS.resetPassword.loading());
        try {
          const { data } = await api(`/users/${state?.userData?._id}`, {
            method: "put",
            data: { ...reqData },
          });
          dispatch(
            AUTH_ACTIONS.updateUser({ ...state?.userData, ...data?.data })
          );
          persist("userData", { ...state?.userData, ...data?.data });
        } catch (e) {
          console.error(e);
        }
      },
      [state?.userData]
    );

    const changePassword = useCallback(
      /**
       * @param {string} email
       */
      async ({ oldPassword, newPassword }) => {
        try {
          await api("/auth/change", {
            method: "post",
            data: { email: state?.userData?.email, oldPassword, newPassword },
          });

          cwToast("success", "Password Updated", "Yay! Everything worked!");
        } catch (e) {
          cwToast("error", "Reset Password Failed", e?.message);
        }
      },
      [state]
    );

    const logout = useCallback(async () => {
      try {
        await api("/auth/logout", {
          method: "post",
        });
      } catch (e) {}
      dispatch(AUTH_ACTIONS.logout);
      removeCookie(AUTH_TOKEN_COOKIE);
      removeCookie("isLayoutFT");
      removeCookie("userData");
      navigate("/");
    }, []);

    const getUserDetails = useCallback(async () => {
      try {
        const { data } = await api(`/users/me`, {
          method: "get",
        });

        dispatch(
          AUTH_ACTIONS.login.success({
            isLoggedIn: true,
            ...data?.data,
          })
        );
        persist("userData", { ...data?.data });

        return data;
      } catch (e) {
        console.error(e);
      }
    }, []);

    const setLoggedIn = useCallback(
      (isLoggedIn, isGoogleSSO) => {
        if (isLoggedIn) {
          getUserDetails();
        }
        dispatch(AUTH_ACTIONS.setLoggedIn({ isLoggedIn, isGoogleSSO }));
      },
      [getUserDetails]
    );

    return {
      state,
      actions: {
        login,
        loginOTPVerify,
        loginOTPResend,
        signup,
        forgotPassword,
        forgotPasswordErrorStateReset,
        forgotPasswordOTPVerify,
        forgotPasswordOTPVerifyErrorStateReset,
        forgotPasswordOTPResend,
        resetPassword,
        updateUser,
        changePassword,
        logout,
        setLoggedIn,
        getUserDetails,
      },
    };
  }
);
