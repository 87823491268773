import React from "react";
import { Grid } from "atoms";
import OtherServiceCard from "../OtherServiceCard";

const corporatebulletData = [
  {
    id: "0",
    title: "Being Named Corporate Secretary for one year",
  },
  {
    id: "1",
    title: "Maintenance of Directors resolution & minutes",
  },
  {
    id: "2",
    title: " Reminder on datelines for statutory filing",
  },
];

const acrabulletData = [
  {
    id: "0",
    title: "Prepare AGM documents",
  },
  {
    id: "1",
    title: "Holding the AGM",
  },
  {
    id: "2",
    title: "Filing the annual returns with ACRA",
  },
];

const irasbulletData = [
  {
    id: "0",
    title: "Annual Tax Return Filing",
  },
  {
    id: "1",
    title: "ECI Filing",
  },
  {
    id: "2",
    title: "Form C and Form C-S Filing",
  },
];

export const OtherServices = () => {
  return (
    <>
      <Grid gridTemplateColumns="repeat(2,1fr)" gridGap="2.4rem" mt="3rem">
        <OtherServiceCard
          title="Corporate Secretarial"
          price="$330"
          bulletData={corporatebulletData}
        />
        <OtherServiceCard
          title="Filing with ACRA"
          price="$250"
          bulletData={acrabulletData}
        />
        <OtherServiceCard
          title="File ECI & Actual Tax with IRAS"
          price="$250"
          bulletData={irasbulletData}
        />
        <OtherServiceCard
          title="GST Registration & Setup"
          price="$350"
          bulletData={corporatebulletData}
        />
        <OtherServiceCard
          title="Quarterly GST Filing"
          price="$200"
          bulletData={corporatebulletData}
        />
      </Grid>
    </>
  );
};
