import * as React from "react";

function LightBulb(props) {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.4193 6.60231C11.2937 6.61745 11.1666 6.58958 11.0588 6.52322C10.9509 6.45686 10.8686 6.3559 10.8251 6.23672L8.61589 0.782107C8.58788 0.712972 8.57372 0.638982 8.57423 0.564362C8.57474 0.489742 8.5899 0.415954 8.61884 0.347209C8.64779 0.278464 8.68995 0.21611 8.74292 0.163705C8.79589 0.111301 8.85865 0.0698707 8.92758 0.0417848C8.99652 0.0136989 9.0703 -0.000495447 9.14471 1.32017e-05C9.21911 0.00052185 9.2927 0.015724 9.36125 0.0447498C9.4298 0.0737755 9.49198 0.116056 9.54423 0.16918C9.59649 0.222304 9.6378 0.285232 9.66581 0.354366L11.875 5.80898C11.9283 5.94734 11.9258 6.10108 11.8679 6.23758C11.8101 6.37409 11.7015 6.48262 11.5651 6.54016C11.5199 6.56801 11.4707 6.58896 11.4193 6.60231Z"
        fill="#FCD34D"
      />
      <path
        d="M29.4048 21.6868C29.3057 21.699 29.2052 21.6851 29.1131 21.6466L23.5792 19.3032C23.449 19.2389 23.3483 19.1272 23.2976 18.9909C23.2469 18.8546 23.25 18.704 23.3064 18.5699C23.3627 18.4359 23.468 18.3285 23.6008 18.2698C23.7335 18.211 23.8835 18.2053 24.0203 18.2539L29.5542 20.59C29.6722 20.6372 29.771 20.7226 29.8349 20.8326C29.8989 20.9426 29.9243 21.0709 29.9071 21.197C29.8899 21.3232 29.8311 21.4399 29.74 21.5287C29.649 21.6175 29.531 21.6731 29.4048 21.6868Z"
        fill="#FCD34D"
      />
      <path
        d="M6.01928 11.7826C5.92023 11.7946 5.81978 11.7808 5.72763 11.7424L0.419714 9.49768C0.281453 9.43902 0.172089 9.32768 0.115686 9.18816C0.0592832 9.04863 0.0604621 8.89236 0.118957 8.7537C0.177452 8.61505 0.288474 8.50538 0.427602 8.44881C0.566729 8.39225 0.722567 8.39343 0.860828 8.45209L6.15416 10.6932C6.2721 10.7403 6.37091 10.8257 6.43485 10.9357C6.49879 11.0457 6.52418 11.174 6.50699 11.3001C6.4898 11.4263 6.43101 11.5431 6.33998 11.6318C6.24895 11.7206 6.13093 11.7762 6.0047 11.7899L6.01928 11.7826Z"
        fill="#FCD34D"
      />
      <path
        d="M23.8838 12.0209C23.7395 12.0566 23.587 12.0338 23.4594 11.9575C23.3317 11.8812 23.2392 11.7575 23.2018 11.6133C23.1644 11.4691 23.1852 11.3159 23.2596 11.1869C23.334 11.0579 23.4561 10.9635 23.5994 10.9242L29.2209 8.65018C29.2898 8.62209 29.3636 8.6079 29.438 8.60841C29.5124 8.60892 29.586 8.62412 29.6545 8.65315C29.7231 8.68217 29.7853 8.72445 29.8375 8.77758C29.8898 8.8307 29.9311 8.89363 29.9591 8.96276C29.9871 9.0319 30.0012 9.10589 30.0007 9.18051C30.0002 9.25513 29.9851 9.32891 29.9561 9.39766C29.9272 9.4664 29.885 9.52876 29.8321 9.58117C29.7791 9.63357 29.7163 9.675 29.6474 9.70308L24.0405 11.9844C23.9905 12.0048 23.9376 12.0171 23.8838 12.0209Z"
        fill="#FCD34D"
      />
      <path
        d="M0.642649 21.4853C0.504873 21.5034 0.36525 21.4703 0.250142 21.3922C0.135033 21.3142 0.0524014 21.1965 0.0178558 21.0616C-0.0166899 20.9266 -0.000762694 20.7836 0.0626297 20.6596C0.126022 20.5356 0.232494 20.4392 0.36194 20.3885L5.63341 18.2425C5.70235 18.2144 5.77613 18.2002 5.85054 18.2007C5.92495 18.2012 5.99853 18.2164 6.06708 18.2454C6.13563 18.2745 6.1978 18.3167 6.25006 18.3699C6.30232 18.423 6.34363 18.4859 6.37163 18.555C6.39964 18.6242 6.41379 18.6982 6.41329 18.7728C6.41278 18.8474 6.39762 18.9212 6.36868 18.9899C6.33974 19.0587 6.29757 19.121 6.2446 19.1735C6.19162 19.2259 6.12888 19.2673 6.05994 19.2954L0.784826 21.445C0.739629 21.4653 0.691735 21.4788 0.642649 21.4853Z"
        fill="#FCD34D"
      />
      <path
        d="M18.7887 6.77037C18.6897 6.78395 18.5888 6.77004 18.4971 6.73015C18.3615 6.67206 18.2541 6.56281 18.1982 6.426C18.1423 6.28919 18.1423 6.1358 18.1982 5.99897L20.5313 0.460271C20.5512 0.381272 20.5878 0.307497 20.6387 0.243982C20.6896 0.180467 20.7535 0.128704 20.8262 0.0922198C20.8988 0.0557352 20.9784 0.0353876 21.0596 0.0325664C21.1408 0.0297452 21.2217 0.0445169 21.2967 0.0758721C21.3717 0.107227 21.439 0.154429 21.4942 0.21426C21.5493 0.274091 21.5909 0.345144 21.6162 0.422574C21.6415 0.500004 21.6498 0.58199 21.6406 0.662942C21.6315 0.743895 21.605 0.821911 21.563 0.891668L19.2299 6.43037C19.1924 6.5208 19.132 6.5998 19.0546 6.65946C18.9772 6.71913 18.8855 6.75737 18.7887 6.77037Z"
        fill="#FCD34D"
      />
      <path
        d="M9.00952 29.9967C8.91016 30.0077 8.80965 29.9926 8.71788 29.9528C8.58226 29.8947 8.4747 29.7856 8.41816 29.649C8.36162 29.5124 8.36059 29.359 8.4153 29.2217L10.6537 23.8804C10.7178 23.7499 10.8291 23.6488 10.9651 23.598C11.101 23.5471 11.2511 23.5503 11.3848 23.6068C11.5185 23.6633 11.6255 23.7689 11.6841 23.902C11.7427 24.0351 11.7484 24.1856 11.6999 24.3227L9.46157 29.6384C9.42422 29.7327 9.36278 29.8154 9.28343 29.8783C9.20407 29.9412 9.1096 29.982 9.00952 29.9967Z"
        fill="#FCD34D"
      />
      <path
        d="M21.6455 12.3714C20.8892 10.5977 19.4667 9.19396 17.686 8.46419C15.9052 7.73443 13.9095 7.73731 12.1309 8.47222C10.3523 9.20713 8.93382 10.6149 8.18257 12.3909C7.43132 14.1668 7.40772 16.1681 8.1169 17.9613C9.23244 20.6777 12.127 21.6172 14.3909 23.2149C15.0872 23.7084 16.0642 26.22 16.0642 26.22L21.8023 23.851C21.8023 23.851 20.7341 21.3759 20.88 20.5351C21.3612 17.8224 22.7611 15.1061 21.6455 12.3714Z"
        fill="#1E293B"
      />
      <path
        d="M21.6455 12.3714C20.8892 10.5977 19.4667 9.19396 17.686 8.46419C15.9052 7.73443 13.9095 7.73731 12.1309 8.47222C10.3523 9.20713 8.93382 10.6149 8.18257 12.3909C7.43132 14.1668 7.40772 16.1681 8.1169 17.9613C9.23244 20.6777 12.127 21.6172 14.3909 23.2149C15.0872 23.7084 16.0642 26.22 16.0642 26.22L21.8023 23.851C21.8023 23.851 20.7341 21.3759 20.88 20.5351C21.3612 17.8224 22.7611 15.1061 21.6455 12.3714Z"
        fill="#F09F16"
      />
      <path
        d="M20.9908 26.5639L18.541 27.5766C18.1044 27.7556 17.6148 27.7538 17.1795 27.5715C16.7443 27.3892 16.3988 27.0414 16.2188 26.6042L21.9569 24.2461C22.1331 24.6821 22.1305 25.1703 21.9496 25.6044C21.7686 26.0385 21.424 26.3834 20.9908 26.5639Z"
        fill="#1E293B"
      />
      <path
        d="M20.4702 28.8008C20.1142 28.9449 19.7158 28.9425 19.3616 28.794C19.0074 28.6454 18.7258 28.3628 18.5781 28.0074L21.2613 26.9106C21.4028 27.2668 21.3992 27.6645 21.2512 28.018C21.1033 28.3715 20.8228 28.6526 20.4702 28.8008Z"
        fill="#1E293B"
      />
      <path
        d="M20.4702 28.8008C20.1142 28.9449 19.7158 28.9425 19.3616 28.794C19.0074 28.6454 18.7258 28.3628 18.5781 28.0074L21.2613 26.9106C21.4028 27.2668 21.3992 27.6645 21.2512 28.018C21.1033 28.3715 20.8228 28.6526 20.4702 28.8008Z"
        fill="#475569"
      />
      <path
        d="M20.9908 26.5639L18.541 27.5766C18.1044 27.7556 17.6148 27.7538 17.1795 27.5715C16.7443 27.3892 16.3988 27.0414 16.2188 26.6042L21.9569 24.2461C22.1331 24.6821 22.1305 25.1703 21.9496 25.6044C21.7686 26.0385 21.424 26.3834 20.9908 26.5639Z"
        fill="#475569"
      />
      <path
        opacity="0.3"
        d="M20.3319 11.4501C22.0636 15.7275 21.3563 21.6647 18.0608 23.01C14.7652 24.3554 9.89473 20.0158 8.39641 16.3088C7.47044 14.0275 9.39529 11.0881 12.6909 9.72812C15.9865 8.36812 19.4096 9.16145 20.3319 11.4501Z"
        fill="white"
      />
      <path
        opacity="0.4"
        d="M19.9177 16.4659C20.2267 17.2283 20.3033 18.0655 20.1378 18.8716C19.9723 19.6777 19.5722 20.4164 18.988 20.9944C18.4038 21.5724 17.6617 21.9636 16.8558 22.1186C16.0498 22.2736 15.2161 22.1854 14.4601 21.8652C13.7042 21.5449 13.0599 21.0071 12.6088 20.3196C12.1577 19.6321 11.92 18.8258 11.9259 18.0029C11.9318 17.1799 12.1809 16.3772 12.6417 15.6962C13.1026 15.0153 13.7545 14.4867 14.515 14.1773C15.0195 13.9715 15.5596 13.8675 16.1042 13.8712C16.6489 13.8749 17.1875 13.9861 17.6893 14.1987C18.1911 14.4112 18.6461 14.7209 19.0285 15.1099C19.4108 15.4989 19.713 15.9597 19.9177 16.4659Z"
        fill="white"
      />
      <path
        opacity="0.4"
        d="M19.1416 17.9171C19.7467 19.4124 18.7223 20.3593 17.2313 20.9662C15.7403 21.5731 14.3477 21.6096 13.7425 20.1143C13.1373 18.6191 14.169 17.6941 15.6601 17.0873C17.1511 16.4804 18.5328 16.4219 19.1416 17.9171Z"
        fill="white"
      />
      <path
        d="M14.6373 18.4449L14.2969 18.6123L17.6942 25.5576L18.0346 25.3901L14.6373 18.4449Z"
        fill="#647488"
      />
      <path
        d="M17.7589 17.2014L17.3984 17.3193L19.7912 24.6737L20.1516 24.5558L17.7589 17.2014Z"
        fill="#647488"
      />
      <path
        d="M17.191 20.7545L15.0547 19.8478L15.2005 19.5005L16.9905 20.2573L17.7342 18.4951L18.0805 18.6414L17.191 20.7545Z"
        fill="#647488"
      />
    </svg>
  );
}

const MemoLightBulbIcon = React.memo(LightBulb);
export default MemoLightBulbIcon;
