import * as React from "react";

function UploadCloudIcon(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 32 32" fill="none" {...props}>
      <mask
        id="prefix__a"
        style={{
          maskType: "alpha",
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={32}
        height={32}
      >
        <path d="M.023 31.996V.016h31.97v31.98H.023z" fill="#fff" />
      </mask>
      <g mask="url(#prefix__a)" fillRule="evenodd" clipRule="evenodd">
        <path
          d="M31.992 16c0 8.837-7.163 16-16 16-8.836 0-16-7.163-16-16s7.164-16 16-16c8.837 0 16 7.163 16 16z"
          fill="currentColor"
        />
        <path
          d="M23.992 24h-5a1 1 0 01-1-1v-2.75a.75.75 0 01.75-.75h1.53c.737 0 .942-.505.458-1.122l-3.857-4.915c-.242-.308-.561-.463-.88-.463-.32 0-.64.155-.882.463l-3.856 4.915c-.485.617-.28 1.122.457 1.122h1.53a.75.75 0 01.75.75V23a1 1 0 01-1 1h-4a4.996 4.996 0 01-.754-9.937C9.106 10.583 12.243 8 15.992 8a8 8 0 018 8 4 4 0 110 8z"
          fill="currentColor"
        />
        <path
          d="M23.992 15a8 8 0 00-8-8c-3.749 0-6.886 2.582-7.754 6.063A4.996 4.996 0 008.992 23h4a1 1 0 001-1v-2.75a.75.75 0 00-.75-.75h-1.53c-.736 0-.942-.505-.457-1.122l3.856-4.915c.485-.617 1.278-.617 1.762 0l3.857 4.915c.484.617.279 1.122-.457 1.122h-1.53a.75.75 0 00-.75.75V22a1 1 0 001 1h5a4 4 0 100-8z"
          fill="#FFFFFE"
        />
      </g>
    </svg>
  );
}

const MemoUploadCloudIcon = React.memo(UploadCloudIcon);
export default MemoUploadCloudIcon;
