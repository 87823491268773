import * as React from "react";

function Tick(props) {
  return (
    <svg
      width="12"
      height="9"
      viewBox="0 0 12 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.57023 6.2268L10.3589 0.4375L11.25 1.32797L4.57023 8.00773L0.5625 4L1.45297 3.10953L4.57023 6.2268Z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoTickIcon = React.memo(Tick);
export default MemoTickIcon;
