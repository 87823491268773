import * as React from "react";

function MOMIcon(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 14 10" fill="none" {...props}>
      <path
        d="M.25 9.5V8.375h9V9.5h-9zm0-3.938V4.438h13.5v1.125H.25zm0-3.937V.5h13.5v1.125H.25z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoMOMIcon = React.memo(MOMIcon);
export default MemoMOMIcon;
