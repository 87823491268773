import React from "react";
import { Box, Flex, Table, Text } from "atoms";
import { DefaultChips } from "molecules";
import StatusCard from "../StatusCard";
import { getStatusChip } from "utils/utilities";
import MemoEyeShowIcon from "assets/icons/EyeShow";
import MemoDownload from "assets/icons/Download";

export const FormACRA = ({ serviceDetails, statusFlag, statusLinkFlag }) => {
  return (
    <>
      <Box w="100%">
        <Flex>
          <Text variant="subtitleMedium" color="neutral.700" fontWeight="600">
            ACRA
          </Text>
          <DefaultChips
            {...getStatusChip(serviceDetails?.[statusFlag])}
            ml="2.4rem"
          />
        </Flex>
        {/* <Text color="neutral.600" variant="bodySmall" mt=".8rem">
          Description Text
        </Text> */}
        {/* Card */}

        <Box mt="3.2rem">
          <StatusCard variant={serviceDetails?.[statusFlag]} />
        </Box>

        {/* preCsAcraLink */}
        {serviceDetails?.[statusLinkFlag] && (
          <Box mt="4.8rem">
            <Table
              tableHeader={[{ name: "Document Name" }, { name: "Action" }]}
            >
              <tbody>
                <tr>
                  <td>
                    <Text variant="bodyLarge" color="neutral.700">
                      ACRA Acknowledgement
                    </Text>
                  </td>
                  <td>
                    <Flex aI="center">
                      <Box
                        color="neutral.400"
                        css={{
                          "&:hover": {
                            color: "neutral.800",
                          },
                        }}
                      >
                        {/* eslint-disable-next-line react/jsx-no-target-blank */}
                        <a
                          href={serviceDetails?.[statusLinkFlag]}
                          target={"_blank"}
                        >
                          <MemoEyeShowIcon />
                        </a>
                      </Box>

                      <Box
                        color="neutral.400"
                        cursor="pointer"
                        ml="2.4rem"
                        css={{
                          "&:hover": {
                            color: "neutral.800",
                          },
                        }}
                      >
                        <a href={serviceDetails?.[statusLinkFlag]} download>
                          <MemoDownload width="1.5rem" />
                        </a>
                      </Box>
                    </Flex>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Box>
        )}
      </Box>
    </>
  );
};
