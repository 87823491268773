import React, { useCallback } from "react";
import ReactDatePicker from "react-datepicker";

import { Box, Flex, Text, InputField } from "atoms";
import { format, parseISO } from "date-fns";
import { getMonths, getYears } from "utils/utilities";
import "react-datepicker/dist/react-datepicker.css";
import MemoArrowIcon from "assets/icons/Arrow";
import { FormSelect } from "molecules";

const DatePickerCss = {
  "& .react-datepicker-popper": {
    zIndex: 9000,
  },
  // header
  "& .react-datepicker__header": {
    background: "#275F66",
    paddingLeft: "2.5rem",
    paddingRight: "2.5rem",
    paddingTop: "2rem",
    // paddingBottom: "2.5rem",
    border: "none",
  },

  // apply if custom header removed
  // "& .react-datepicker__current-month": {
  //   marginBottom: "3rem",
  //   fontSize: "1.6rem",
  //   fontWeight: "600",
  //   color: "white",
  // },

  "& .react-datepicker__day-name": {
    marginBottom: "3rem",
    fontSize: "1.8rem",
    fontWeight: "600",
    color: "white",
    marginRight: "1.6rem",
    marginLeft: "1.6rem",
  },

  "& .react-datepicker__day--disabled": {
    marginBottom: "3rem",
    fontSize: "1.8rem",
    fontWeight: "400 !important",
    color: "#D9D9D9 !important",
    marginRight: "1.6rem",
    marginLeft: "1.6rem",
  },

  "& .react-datepicker__month-container": {
    border: "none",
    background: "#275F66",
    borderRadius: "2px",
  },
  "& .react-datepicker": {
    border: "none",
    background: "#275F66",
    borderRadius: "2px",
  },
  "& .react-datepicker__month": {
    background: "#275F66",
    margin: 0,
  },
  "& .react-datepicker__week": {
    marginBottom: "2.2rem",
    marginRight: "0px",
    display: "grid",
    gridTemplateColumns: "repeat(7, 1fr)",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: "2.5rem",
    paddingRight: "2.5rem",
  },
  "& .react-datepicker__day": {
    fontSize: "1.6rem",
    color: "white",
    // paddingLeft: "2rem",
    // paddingRight: "2rem",
    padding: "1.3rem",
    border: "1px solid transparent",
    display: "flex",
    flexDirection: "row",
    width: "fit-content",
    textAlign: "center",
    margin: 0,
  },
  "& .react-datepicker__day:hover": {
    border: "1px solid white",
    background: "#275F66",
  },
  "& .react-datepicker__day--selected": {
    background: "white",
    color: "black",
  },
  "& .react-datepicker__day--today": {
    background: "white",
    color: "black",
  },

  "& .react-datepicker__triangle": {
    display: "none",
  },

  "& .react-datepicker__monthPicker": {
    padding: "1rem",
  },
  "& .react-datepicker__month-wrapper": {
    // display: "flex",
    // flexDirection: "row",
    // justifyContent: "space-around",
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    alignItems: "center",
    justifyItems: "center",
  },

  "& .react-datepicker__month-text": {
    fontSize: "1.6rem",
    color: "white",
    background: "#275F66",
    // paddingLeft: "2rem",
    // paddingRight: "2rem",
    padding: "1.3rem",
    border: "1px solid transparent",
    display: "flex",
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    margin: 0,
  },
  "& .react-datepicker__month-text:hover": {
    border: "1px solid white",
    background: "#275F66",
  },
  "& .react-datepicker__month-text--today": {
    color: "white",
  },
  "& .react-datepicker__month--selected": {
    background: "white",
    color: "black",
  },
};

export const DatePickerCard = ({
  value,
  onChange,
  containerProps,
  datePickerProps,
  ...props
}) => {
  const customHeader = useCallback(
    ({
      date,
      changeYear,
      changeMonth,
      decreaseMonth,
      increaseMonth,
      prevMonthButtonDisabled,
      nextMonthButtonDisabled,
      prevYearButtonDisabled,
      nextYearButtonDisabled,
      increaseYear,
      decreaseYear,
    }) => {
      return (
        <Flex
          w="100%"
          bg="primary.900"
          aI="center"
          {...(!datePickerProps?.showMonthYearPicker && {
            mb: "3rem",
            jC: "space-between",
          })}
          {...(datePickerProps?.showMonthYearPicker && {
            jC: "center",
          })}
        >
          {/* month Change */}
          {!datePickerProps?.showMonthYearPicker && (
            <Flex aI="center">
              {/* <Box
                color="white"
                transform="rotate(-90deg)"
                cursor="pointer"
                onClick={decreaseMonth}
              >
                <MemoArrowIcon width="1.6rem" />
              </Box>


              <Text
                variant="bodyLarge"
                fontWeight="600"
                color="white"
                mx="1.2rem"
              >
                {format(date, "LLLL")}
              </Text>


              <Box
                color="white"
                transform="rotate(90deg)"
                cursor="pointer"
                onClick={increaseMonth}
              >
                <MemoArrowIcon width="1.6rem" />
              </Box> */}

              <FormSelect
                name="monthChange"
                placeholder="Month"
                options={getMonths()}
                customDefaultValue={{
                  label: format(date, "MMMM"),
                  value: date.getMonth(),
                }}
                onChange={(v) => {
                  // console.log(v);
                  changeMonth(v?.value);
                }}
                width="8rem"
              />
            </Flex>
          )}

          {/* year Change */}
          <Flex
            aI="center"
            {...(!datePickerProps?.showMonthYearPicker && { ml: "6rem" })}
          >
            {/* <Box
              color="white"
              transform="rotate(-90deg)"
              cursor="pointer"
              onClick={decreaseYear}
            >
              <MemoArrowIcon width="1.6rem" />
            </Box>


            <Text
              variant="bodyLarge"
              fontWeight="600"
              color="white"
              mx="1.2rem"
            >
              {format(date, "yyyy")}
            </Text>


            <Box
              color="white"
              transform="rotate(90deg)"
              cursor="pointer"
              onClick={increaseYear}
            >
              <MemoArrowIcon width="1.6rem" />
            </Box> */}
            <FormSelect
              name="yearChange"
              placeholder="Year"
              options={getYears()}
              customDefaultValue={{
                label: date.getFullYear(),
                value: date.getFullYear(),
              }}
              onChange={(v) => {
                // console.log(v);
                changeYear(v?.value);
              }}
              width="8rem"
            />
          </Flex>
        </Flex>
      );
    },
    [datePickerProps]
  );

  return (
    <Box css={DatePickerCss} {...containerProps}>
      <ReactDatePicker
        {...(value && { selected: parseISO(value) })}
        autoComplete="off"
        onChange={onChange}
        renderCustomHeader={customHeader}
        wrapperClassName="custom-calendar"
        customInput={
          <Box>
            <InputField
              {...props}
              value={
                value
                  ? format(
                      parseISO(value),
                      datePickerProps?.dateFormat || "dd/MM/yyyy"
                    )
                  : ""
              }
              isDate
              autoComplete={"off"}
            />
          </Box>
        }
        disabled={props?.disabled}
        {...datePickerProps}
      />
    </Box>
  );
};
