import React, { useCallback, useEffect, useState } from "react";
import { CSSTransition } from "react-transition-group";

import MemoCartIcon from "assets/icons/Cart";
import MemoSelectArrowUpIcon from "assets/icons/SelectArrowUp";
import { Box, Flex, Grid, Line, Text } from "atoms";
import { formatCurrency, formatDuration } from "utils/utilities";

export const CartSummary = ({ features, ...props }) => {
  const [isDropped, setIsDropped] = useState(false);

  const dropHandler = useCallback(() => {
    setIsDropped((prev) => !prev);
  }, []);

  const [totalPrice, setTotalPrice] = useState("$0");

  useEffect(() => {
    const total = features?.reduce((pre, cur) => pre + cur?.price, 0);
    setTotalPrice(`$${total || 0}`);
  }, [features]);
  return (
    <Box
      boxShadow="m"
      borderRadius="2rem 0% 0% 2rem"
      bg="white"
      minWidth="31.1rem"
      // maxWidth="31.1rem"
      minHeight="4.7rem"
      {...props}
    >
      <Grid
        aI="center"
        gTC="auto auto 1fr"
        p="0.6rem 0.9rem"
        pr="1.8rem"
        cursor="pointer"
        onClick={dropHandler}
      >
        <Flex
          color="white"
          background="linear-gradient(73.03deg, #369468 28.59%, #2B6247 88.58%)"
          p="1rem"
          bR="100%"
        >
          <MemoCartIcon width="1.6rem" height="1.6rem" />
          {features?.length > 0 && (
            <Flex
              bg="secondary.700"
              position="absolute"
              bR="100%"
              w="1.8rem"
              h="1.8rem"
              left="67.33%"
              bottom="72%"
              aI="center"
              jC="center"
            >
              <Text color="white" variant="bodyMedium" fontWeight="600">
                {features?.length}
              </Text>
            </Flex>
          )}
        </Flex>
        <Box ml="0.8rem">
          <Text variant="bodySmall">My cart summary</Text>
          <Text variant="bodyLarge" fontWeight="600" color="neutral.700">
            {totalPrice || 0}
          </Text>
        </Box>

        {/* dropdown bell */}
        {features?.length > 0 && (
          <Box ml="2.4rem" justifySelf="end">
            <Flex cursor="pointer">
              <Flex
                // transform="rotate(180deg)"
                bg="neutral.50"
                w="2.4rem"
                h="2.4rem"
                aI="center"
                jC="center"
                bR="100%"
                transition="all 0.3sec"
                transform={isDropped ? "rotate(0deg)" : "rotate(180deg)"}
              >
                <MemoSelectArrowUpIcon width="0.7rem" height="1rem" />
              </Flex>

              {/* <Flex
                bg="secondary.700"
                position="absolute"
                bR="100%"
                w="1.8rem"
                h="1.8rem"
                left="55.33%"
                bottom="60%"
                aI="center"
                jC="center"
              >
                <Text color="white" variant="bodyMedium" fontWeight="600">
                  {features?.length}
                </Text>
              </Flex> */}
            </Flex>
          </Box>
        )}
      </Grid>

      <CSSTransition
        classNames="collapse"
        in={isDropped}
        timeout={300}
        unmountOnExit
      >
        <Box pb="1.6rem">
          <Line />
          <Box mt="1.6rem" px="2.4rem">
            {features?.map((feature, index) => (
              <Grid
                key={index}
                gTC="14.7rem 1fr"
                gCG="6.9rem"
                {...(index && { mt: "2.4rem" })}
              >
                {/* identifier */}
                <Text variant="bodyLarge" color="neutral.600">
                  {feature?.name}
                </Text>

                <Flex flexDirection="column">
                  {/* price */}
                  <Text
                    variant="bodyMedium"
                    fontWeight="600"
                    color="neutral.700"
                  >
                    {formatCurrency(feature?.price)}
                  </Text>
                  {/* descriptor */}
                  <Text variant="bodySmall" color="neutral.600">
                    {formatDuration(
                      feature?.duration
                        ? feature?.duration
                        : feature?.oneTime
                        ? "oneTime"
                        : "",
                      true
                    )}
                  </Text>
                </Flex>
              </Grid>
            ))}
          </Box>
          <Box mt="2.4rem" p="2.4rem" bg="primary.50">
            {/* <Grid gTC="14.7rem 1fr" gCG="6.9rem">
              <Text variant="bodyLarge" color="neutral.600">
                Subtotal
              </Text>
              <Text variant="bodyMedium" fontWeight="600" color="neutral.700">
                {totalPrice}
              </Text>
            </Grid> */}
            {/* <Grid gTC="14.7rem 1fr" gCG="5rem" mt="2.4rem" aI="baseline"> */}
            <Grid gTC="14.7rem 1fr" gCG="5rem" aI="baseline">
              {/* identifier */}
              <Text variant="bodyLarge" color="neutral.700" fontWeight="600">
                Total
              </Text>

              {/* price */}
              <Text
                variant="subtitleMedium"
                fontWeight="600"
                color="neutral.700"
              >
                {totalPrice}
              </Text>
            </Grid>
          </Box>
        </Box>
      </CSSTransition>
    </Box>
  );
};
