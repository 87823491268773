import React from "react";
import { Box, Flex, Text } from "atoms";
import MemoRoundCross from "styles/icons/RoundCross";

const variants = {
  pending: {
    bg: "rgba(255, 86, 54, 0.16)",
    textColor: "error.700",
    borderColor: "error.200",
  },
  delayed: {
    bg: "rgba(255, 8, 0, 0.16)",
    textColor: "error.900",
    borderColor: "error.200",
  },
  completed: {
    bg: "rgba(0, 108, 17, 0.16)",
    textColor: "success.900",
    borderColor: "success.500",
  },
  progress: {
    bg: "rgba(255, 164, 36, 0.16)",
    textColor: "secondary.500",
    borderColor: "secondary.200",
  },
  statement: {
    bg: "primary.50",
    textColor: "primary.900",
    borderColor: "primary.700",
  },
};

export const DefaultChips = ({ variant, svgBg, title, isBorder, ...props }) => {
  return (
    <Flex
      w="fit-content"
      h="auto"
      bR="0.4rem"
      bg={variants?.[variant]?.bg}
      cursor="pointer"
      px="2.4rem"
      jC="center"
      aI="center"
      {...(isBorder && {
        border: "0.5px solid",
        borderColor: variants?.[variant]?.borderColor,
      })}
      {...props}
    >
      {/* <Flex jC="center" aI="center"> */}
      <Text
        variant="bodySmall"
        fontWeight="600"
        color={variants?.[variant]?.textColor}
      >
        {title}
      </Text>
      {svgBg && (
        <Box ml="0.5rem" w="fit-content" pt="0.2rem" color={svgBg}>
          <MemoRoundCross w={12} h={12} />
        </Box>
      )}
      {/* </Flex> */}
    </Flex>
  );
};
