import * as React from "react";

function GoogleColorLogo(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 25 25"
      fill="currentColor"
      {...props}
    >
      <path
        d="M24.267 10.322H23.3v-.05H12.5v4.8h6.782c-.99 2.795-3.648 4.8-6.782 4.8a7.2 7.2 0 010-14.4c1.835 0 3.505.693 4.777 1.824l3.394-3.394A11.945 11.945 0 0012.5.672c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12c0-.804-.083-1.59-.233-2.35z"
        fill="#FFC107"
      />
      <path
        d="M1.883 7.087l3.942 2.891A7.197 7.197 0 0112.5 5.472c1.836 0 3.505.693 4.777 1.824l3.394-3.394A11.945 11.945 0 0012.5.672c-4.61 0-8.607 2.603-10.617 6.415z"
        fill="#FF3D00"
      />
      <path
        d="M12.5 24.672c3.099 0 5.915-1.186 8.045-3.115l-3.714-3.143a7.146 7.146 0 01-4.332 1.458c-3.12 0-5.771-1.99-6.77-4.767L1.817 18.12c1.986 3.886 6.02 6.552 10.683 6.552z"
        fill="#4CAF50"
      />
      <path
        d="M24.267 10.322H23.3v-.05H12.5v4.8h6.782a7.225 7.225 0 01-2.452 3.343l.001-.001 3.714 3.143c-.262.238 3.955-2.885 3.955-8.885 0-.804-.083-1.59-.233-2.35z"
        fill="#1976D2"
      />
    </svg>
  );
}

const MemoGoogleColorLogo = React.memo(GoogleColorLogo);
export default MemoGoogleColorLogo;
