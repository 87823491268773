import React from "react";
import { Box, Button, Flex, Text } from "atoms";
import MemoLink from "assets/icons/Link";

export const RedirectModal = ({ title, description, link, ...props }) => {
  return (
    <>
      <Box bg="neutral.50" px="2.4rem" py="2.4rem" bR=".8rem" {...props}>
        <Text color="neutral.800" variant="subtitleSmall" fontWeight="600">
          {/* U-Reg */} {title}
        </Text>
        <Text mt="1.6rem" variant="bodyMedium" color="neutral.700">
          {description}
        </Text>
        <Flex mt="2.5rem" bg="white" bR=".8rem" jC="space-between">
          <Flex ml="2.3rem" pI="center">
            <Box color="neutral.700">
              <MemoLink width="2rem" height="1rem" />
            </Box>
            <Text ml="1.4rem" color="neutral.700" variant="bodyMedium">
              {link}
            </Text>
          </Flex>
          <Button
            w="fit-content"
            px="5.9rem"
            onClick={() => window.open(link, "_blank")}
          >
            Go
          </Button>
        </Flex>
      </Box>
    </>
  );
};
