import React from "react";
import MemoCross from "styles/icons/Cross";
import { Box, Button, Modal, Text, Grid } from "atoms";
import { Form, Formik } from "formik";
import { changePasswordValidation } from "utils/validation";
import { FormInput } from "molecules";

export const ResetPasswordModal = ({ isOpen, onSubmitModal, onClose }) => {
  const onSubmitHandler = (values) => {
    onSubmitModal(values);
    onClose();
  };
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      shouldCloseOnOverlayClick={true}
      style={{
        content: {
          borderRadius: "8px",
        },
      }}
    >
      <Formik
        initialValues={{
          oldPassword: "",
          newPassword: "",
        }}
        onSubmit={onSubmitHandler}
        validationSchema={changePasswordValidation}
      >
        {({ values }) => {
          return (
            <Form>
              <Grid
                h="2.4rem"
                w="2.4rem"
                bR="50%"
                bg="neutral.100"
                pI="center"
                cursor="pointer"
                position="absolute"
                top="-3.5%"
                right="-3.5%"
                onClick={onClose}
              >
                <Box color="neutral.900" w="1rem" h="1rem">
                  <MemoCross />
                </Box>
              </Grid>
              <Box p="3.2rem 4rem">
                {/* heading Text */}
                <Text
                  variant="subtitleLarge"
                  fontWeight="600"
                  color="neutral.800"
                  textAlign="center"
                >
                  Reset your password
                </Text>
                {/* New password and Confirm new password */}
                <Box mt="4rem">
                  <FormInput
                    name="oldPassword"
                    label="Old password"
                    type="password"
                    // errorTooltip
                    // tooltipProps={{ maxWidth: "30rem !important" }}
                    {...(values?.oldPassword && {
                      isErrorRelative: true,
                      errorProps: { maxWidth: "25.4rem !important" },
                    })}
                  />
                </Box>
                <Box mt="1.6rem">
                  <FormInput
                    name="newPassword"
                    label="New password"
                    type="password"
                    {...(values?.newPassword && {
                      isErrorRelative: true,
                      errorProps: { maxWidth: "25.4rem !important" },
                    })}
                  />
                </Box>

                {/* <Text
                  mt="4rem"
                  variant="bodyMedium"
                  color="neutral.600"
                  textAlign="center"
                >
                  Please check your password and click on the Confirm button,
                </Text> */}
                {/* Reset Password Button */}
                <Button mt="2.4rem" mb="3.7rem" type="submit">
                  Reset Password
                </Button>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};
