import * as React from "react";

function Bulb(props) {
  return (
    <svg
      width="19"
      height="26"
      viewBox="0 0 19 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.13508 19.3583H11.865C12.019 17.9559 12.7342 16.7985 13.8951 15.5349C14.0269 15.3926 14.8658 14.5234 14.965 14.3997C15.789 13.3702 16.3055 12.1289 16.455 10.8188C16.6045 9.50862 16.3809 8.18287 15.81 6.99422C15.239 5.80557 14.3439 4.80235 13.2278 4.10009C12.1117 3.39784 10.82 3.02511 9.50129 3.02483C8.18263 3.02455 6.8907 3.39672 5.77429 4.0985C4.65788 4.80028 3.76239 5.80312 3.19094 6.99153C2.61949 8.17994 2.39532 9.50559 2.54426 10.8158C2.69319 12.126 3.20917 13.3675 4.03277 14.3974C4.13311 14.5222 4.97432 15.3926 5.10382 15.5338C6.26588 16.7985 6.98108 17.9559 7.13508 19.3583ZM11.8335 21.6917H7.16659V22.8584H11.8335V21.6917ZM2.21268 15.8581C1.11385 14.4852 0.425205 12.8299 0.226065 11.0827C0.0269246 9.33552 0.325395 7.56763 1.08709 5.98268C1.84879 4.39773 3.04273 3.06019 4.53138 2.12414C6.02002 1.18809 7.74281 0.691613 9.50129 0.691895C11.2598 0.692176 12.9824 1.18921 14.4707 2.12573C15.9591 3.06226 17.1526 4.40018 17.9138 5.98537C18.675 7.57057 18.9729 9.33855 18.7732 11.0857C18.5735 12.8328 17.8843 14.4879 16.785 15.8604C16.0617 16.7612 14.1669 18.1916 14.1669 19.9416V22.8584C14.1669 23.4773 13.9211 24.0708 13.4835 24.5084C13.0459 24.9461 12.4523 25.1919 11.8335 25.1919H7.16659C6.54772 25.1919 5.9542 24.9461 5.51659 24.5084C5.07899 24.0708 4.83314 23.4773 4.83314 22.8584V19.9416C4.83314 18.1916 2.93722 16.7612 2.21268 15.8581ZM10.6668 10.0292H13.5836L8.33331 17.0295V12.3626H5.4165L10.6668 5.35761V10.0303V10.0292Z"
        fill="white"
      />
    </svg>
  );
}

const MemoBulbIcon = React.memo(Bulb);
export default MemoBulbIcon;
