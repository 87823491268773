import React, { useState } from "react";

import { Form, setNestedObjectValues } from "formik";
import { Box, Button, Flex, Line } from "atoms";
import { FormInput, FormSelect } from "molecules";
import FormLayout from "../FormLayout";
import { getCountries } from "utils/utilities";
import FORM_NAMES from "templates/Incorporation/form.names";
import { postcodeValidatorExistsForCountry } from "postcode-validator";
import { incorporationCorporateAddressDetailsValidation } from "utils/validation";

// requested defaults
// country Singapore
const INITIAL_VALUES = {
  corporateAddressDetails: {
    addressLine1: "",
    addressLine2: "",
    postalCode: "",
    country: "Singapore",
    countryCode: "SG",
  },
};

const defaultComparison = (formInitValues) => {
  if (
    !formInitValues?.corporateAddressDetails?.addressLine1 &&
    !formInitValues?.corporateAddressDetails?.addressLine2 &&
    !formInitValues?.corporateAddressDetails?.postalCode &&
    !formInitValues?.corporateAddressDetails?.country
  ) {
    return INITIAL_VALUES;
  }

  return {};
};

export const FormCorporateAddressDetails = ({
  breadcrum,
  step,
  onNext,
  onPrevious,
  onSubmit,
  formInitValues,
  ...props
}) => {
  const [submitting, setSubmitting] = useState(false);
  return (
    <FormLayout
      breadcrum={breadcrum}
      step={step}
      onPrevious={onPrevious}
      title="Corporate Address Details"
      // subtitle="Description Text"
      formikProps={{
        initialValues: {
          ...INITIAL_VALUES,
          ...formInitValues,
          ...defaultComparison(formInitValues),
        },
        enableReinitialize: true,
        validationSchema: incorporationCorporateAddressDetailsValidation,
      }}
      {...props}
    >
      {({
        values,
        dirty,
        setFieldValue,
        validateForm,
        setFieldTouched,
        setFieldError,
        setTouched,
        setErrors,
      }) => {
        return (
          <Form>
            <Flex mt="4rem">
              <Box flex="1">
                <FormInput
                  name="corporateAddressDetails.addressLine1"
                  label="Address Line 1"
                />
              </Box>
              <Box flex="1" ml=".8rem">
                <FormInput
                  name="corporateAddressDetails.addressLine2"
                  label="Address Line 2"
                />
              </Box>
            </Flex>
            <Flex mt="3.8rem">
              <Box flex="1">
                <FormSelect
                  name="corporateAddressDetails.country"
                  label="Country"
                  options={getCountries()}
                  onChange={(country) => {
                    setFieldValue(
                      `corporateAddressDetails.countryCode`,
                      country?.countryCode
                    );
                    setFieldValue(
                      `corporateAddressDetails.address.postalCode`,
                      ""
                    );
                  }}
                />
              </Box>
              <Box flex="1" ml=".8rem">
                <FormInput
                  name="corporateAddressDetails.postalCode"
                  label="Postal Code"
                  disabled={
                    !postcodeValidatorExistsForCountry(
                      values?.corporateAddressDetails?.countryCode
                    )
                  }
                />
              </Box>
            </Flex>
            <Line mt="4.8rem" />
            <Button
              w="fit-content"
              px="4rem"
              mt="4rem"
              type="button"
              loading={submitting}
              onClick={() => {
                validateForm(values)?.then((dataErrorBag) => {
                  const dataErrorBagKeys = Object?.keys(dataErrorBag || {});
                  if (dataErrorBagKeys?.length) {
                    setTouched(setNestedObjectValues(dataErrorBag, true));
                    setErrors(dataErrorBag);
                  } else {
                    if (dirty) {
                      setSubmitting(true);
                      onSubmit(values)
                        .then(() => {
                          onNext(FORM_NAMES.FormLocalDirector, true);
                        })
                        .catch(console.error)
                        .finally(() => {
                          setSubmitting(false);
                        });
                    } else {
                      onNext(FORM_NAMES.FormLocalDirector, true);
                    }
                  }
                });
              }}
            >
              NEXT : Nominee Director
            </Button>
          </Form>
        );
      }}
    </FormLayout>
  );
};
