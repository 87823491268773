import { createAction, createAsyncActions } from "utils/context";
import * as constants from "./constants";

export const AUTH_ACTIONS = {
  login: createAsyncActions(constants.LOGIN),
  forgotPassword: createAsyncActions(constants.FORGOT_PASSWORD),
  otp: createAsyncActions(constants.OTP),
  resend: createAsyncActions(constants.RESEND),
  resetPassword: createAsyncActions(constants.RESET_PASSWORD),
  logout: createAction(constants.LOGOUT),
  switchLayout: createAction(constants.SWITCH_LAYOUT),
  signup: createAction(constants.SIGNUP),
  updateUser: createAction(constants.UPDATE_USER),
  setLoggedIn: createAction(constants.SET_LOGGED_IN),
};
