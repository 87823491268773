import React from "react";

import SERVICE_TYPE_CODE from "utils/services.type.constants";
import { UserDetails as TransferExistingCompanyUserDetails } from "./TransferExistingCompany";
import { UserDetails as ChangeInBuisnessCodeUserDetails } from "./ChangeInBuisnessCode";
import { UserDetails as ShareholderUserDetails } from "./Shareholder";
import { UserDetails as DirectorUserDetails } from "./Director";
import { UserDetails as ChangeInCompanyAddressUserDetails } from "./ChangeInCompanyAddress";
import { UserDetails as CertifyTrueCopyUserDetails } from "./CertifyTrueCopy";
import { UserDetails as ToActNomineeDirectorUserDetails } from "./ToActNomineeDirector";
import { UserDetails as ToActNomineeShareholderUserDetails } from "./ToActNomineeShareholder";

export const UserDetails = ({ serviceTypeCode, ...props }) => {
  let jsxComponent = <></>;
  switch (serviceTypeCode) {
    case SERVICE_TYPE_CODE.CORPORATE_SECRETARIAL
      .transferExistingCompanySecretaryServices.code: {
      jsxComponent = <TransferExistingCompanyUserDetails {...props} />;
      break;
    }
    case SERVICE_TYPE_CODE.CORPORATE_SECRETARIAL.changeInBuisnessCode.code: {
      jsxComponent = <ChangeInBuisnessCodeUserDetails {...props} />;
      break;
    }
    case SERVICE_TYPE_CODE.CORPORATE_SECRETARIAL.additionAndRemovalOfShareholder
      .code: {
      jsxComponent = <ShareholderUserDetails {...props} />;
      break;
    }
    case SERVICE_TYPE_CODE.CORPORATE_SECRETARIAL.additionAndRemovalOfDirector
      .code: {
      jsxComponent = <DirectorUserDetails {...props} />;
      break;
    }
    case SERVICE_TYPE_CODE.CORPORATE_SECRETARIAL.changeInCompanyAddress.code: {
      jsxComponent = <ChangeInCompanyAddressUserDetails {...props} />;
      break;
    }
    case SERVICE_TYPE_CODE.CORPORATE_SECRETARIAL.certifyTrueCopy.code: {
      jsxComponent = <CertifyTrueCopyUserDetails {...props} />;
      break;
    }
    case SERVICE_TYPE_CODE.CORPORATE_SECRETARIAL.toActNomineeDirector.code: {
      jsxComponent = <ToActNomineeDirectorUserDetails {...props} />;
      break;
    }
    case SERVICE_TYPE_CODE.CORPORATE_SECRETARIAL.toActNomineeShareholder.code: {
      jsxComponent = <ToActNomineeShareholderUserDetails {...props} />;
      break;
    }

    default: {
      break;
    }
  }

  return jsxComponent;
};
