import React, { useState } from "react";
import { Form, setNestedObjectValues } from "formik";
import { Button, Flex, Line } from "atoms";
import { cwToast, FormToggle } from "molecules";
import FormLayout from "../FormLayout";
import MemoProfile from "assets/icons/Profile";
import FORM_NAMES from "templates/Incorporation/form.names";
import { incorporationLocalForeignValidation } from "utils/validation";

const INITIAL_VALUES = {
  directorType: "",
};

export const FormDirector = ({
  breadcrum,
  step,
  onNext,
  onPrevious,
  onSubmit,
  formInitValues,
  createFormData,
  ...props
}) => {
  const [submitting, setSubmitting] = useState(false);

  return (
    <FormLayout
      breadcrum={breadcrum}
      step={step}
      onPrevious={onPrevious}
      title="Are you a Singaporean Citizen / PR or Foreign Resident?"
      subtitle="Select your residency status"
      formikProps={{
        initialValues: {
          ...INITIAL_VALUES,
          ...formInitValues,
        },
        enableReinitialize: true,
        validationSchema: incorporationLocalForeignValidation,
      }}
      {...props}
    >
      {({ values, dirty, errors, validateForm, setTouched, setErrors }) => {
        return (
          <>
            <Form>
              <Flex mt="4rem">
                <FormToggle
                  name="directorType"
                  label="Local"
                  value="local"
                  Icon={MemoProfile}
                />
                <FormToggle
                  name="directorType"
                  label="Foreign"
                  value="foreign"
                  ml="3.2rem"
                  Icon={MemoProfile}
                  // isWrong
                />
              </Flex>

              {/* {errors?.directorType && <Error text={errors?.directorType} />} */}

              <Line mt="4.8rem" />
              <Button
                w="fit-content"
                type="submit"
                px="4rem"
                mt="4rem"
                loading={submitting}
                onClick={async () => {
                  validateForm(values)?.then((dataErrorBag) => {
                    const dataErrorBagKeys = Object?.keys(dataErrorBag || {});
                    if (dataErrorBagKeys?.length) {
                      dataErrorBagKeys?.forEach((field) => {
                        setTouched(setNestedObjectValues(dataErrorBag, true));
                        setErrors(dataErrorBag);
                        dataErrorBag?.directorType &&
                          cwToast("error", dataErrorBag?.directorType);
                      });
                    } else {
                      if (dirty) {
                        setSubmitting(true);
                        (formInitValues?._id
                          ? onSubmit({ ...values, planType: "" })
                          : createFormData({ ...values, planType: "" })
                        )
                          .then(() => {
                            onNext(FORM_NAMES.FormDirectorPlan, false);
                          })
                          .catch(console.error)
                          .finally(() => {
                            setSubmitting(false);
                          });
                      } else {
                        onNext(FORM_NAMES.FormDirectorPlan, false);
                      }
                    }
                  });
                }}
              >
                Next: Choose Plan
              </Button>
            </Form>
          </>
        );
      }}
    </FormLayout>
  );
};
