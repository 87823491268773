import * as React from "react";

function Search(props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.0194 11.0767L14.8747 13.9314L13.9314 14.8747L11.0767 12.0194C10.0145 12.8708 8.69337 13.334 7.33203 13.332C4.02003 13.332 1.33203 10.644 1.33203 7.33203C1.33203 4.02003 4.02003 1.33203 7.33203 1.33203C10.644 1.33203 13.332 4.02003 13.332 7.33203C13.334 8.69337 12.8708 10.0145 12.0194 11.0767ZM10.682 10.582C11.5281 9.71196 12.0006 8.54565 11.9987 7.33203C11.9987 4.75336 9.91003 2.66536 7.33203 2.66536C4.75336 2.66536 2.66536 4.75336 2.66536 7.33203C2.66536 9.91003 4.75336 11.9987 7.33203 11.9987C8.54565 12.0006 9.71196 11.5281 10.582 10.682L10.682 10.582Z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoSearchIcon = React.memo(Search);
export default MemoSearchIcon;
