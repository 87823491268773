import * as React from "react";

function Download(props) {
  return (
    <svg
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.77778 6.22222H14.2222L8.88889 11.5556L3.55556 6.22222H8V0H9.77778V6.22222ZM1.77778 14.2222H16V8H17.7778V15.1111C17.7778 15.3469 17.6841 15.573 17.5174 15.7397C17.3507 15.9064 17.1246 16 16.8889 16H0.888889C0.653141 16 0.427049 15.9064 0.26035 15.7397C0.0936505 15.573 0 15.3469 0 15.1111V8H1.77778V14.2222Z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoDownload = React.memo(Download);
export default MemoDownload;
