import React from "react";
import { useNavigate } from "react-router-dom";

import { Box, Button, Image, Text } from "atoms";
import PaymentDone from "assets/images/PaymentDone.gif";

export const PaymentSuccess = () => {
  const navigate = useNavigate();
  return (
    <>
      <Box
        w="112rem"
        h="43.6rem"
        bg="white"
        jC="center"
        p="5.51rem 23.9rem 6.78rem 24rem"
        bR="0.8rem"
      >
        <Box ml="25rem" h="11rem" w="13.8rem">
          <Image src={PaymentDone} alt="paymentdone" />
        </Box>
        <Box mt="3.2rem" textAlign="center">
          <Text variant="headingLarge" fontWeight="700" color="success.900">
            Payment Successful
          </Text>
          <Box mt="0.8rem">
            <Text variant="bodyLarge" color="neutral.700">
              Thank you for your payment. An automatic payment receipt will be
              sent to your registered email.
            </Text>
          </Box>
        </Box>
        <Box mt="4.8rem" ml="21.4rem">
          <Button width="21.5rem" onClick={() => navigate("/dashboard")}>
            <Text variant="bodyLarge" fontWeight="700" color="white">
              Go to Dashboard
            </Text>
          </Button>
        </Box>
      </Box>
    </>
  );
};
