import React from "react";

import { Box, Error, Grid, Line, Table, Text } from "atoms";
import { FormDateInput, FormInput, FormSelect } from "molecules";
import { getCountries, getCurrencies, getDocumentById } from "utils/utilities";
import { Upload } from "organisms";
import { postcodeValidatorExistsForCountry } from "postcode-validator";

const customIdGenerator = (index, id) => {
  return `corporateShareholderDetails_${index}_${id}`;
};
// {`corporateShareholderDetails[${index}].`}

export const CorporateShareholder = ({
  values,
  index,
  // docsList1,
  // docsList2,
  setFieldValue,
  // onSubmit,
  formUpload,
  errors,
  touched,
}) => {
  return (
    <>
      {/* <Box as="fieldset" m="0rem" p="0rem" border="none" disabled={true}> */}
      {/* Shareholder details */}
      <Box mt="2.4rem">
        <Text variant="subtitleSmall" fontWeight="600" color="neutral.700">
          Shareholder Name
        </Text>
        <Line mt="1.6rem" />
        {/* Firstname and lastname */}
        <Grid mt="2rem" gridAutoFlow="column" gridGap="2.4rem">
          <FormInput
            name={`corporateShareholderDetails[${index}].companyName`}
            label="Company Name*"
          />
        </Grid>
      </Box>

      <Grid gTC="repeat(2,1fr)" gCG="2.4rem">
        {/* UEN Number */}
        <Box mt="2.4rem">
          <Text variant="subtitleSmall" fontWeight="600" color="neutral.700">
            UEN or Other Company Registration Number
          </Text>
          <Line mt="1.6rem" mb="2.4rem" />
          {/* input */}
          <FormInput
            name={`corporateShareholderDetails[${index}].uen`}
            label="Enter UEN or Other Company Registration Number"
          />
        </Box>
        {/* Country of incorporation */}
        <Box mt="2.4rem">
          <Text variant="subtitleSmall" fontWeight="600" color="neutral.700">
            Country of Incorporation
          </Text>
          <Line mt="1.6rem" mb="2.4rem" />
          {/* input */}
          <FormSelect
            name={`corporateShareholderDetails[${index}].countryOfIncorporation`}
            label="Country"
            options={getCountries()}
          />
        </Box>
        {/* Date of incorporation */}
        <Box mt="2.4rem">
          <Text variant="subtitleSmall" fontWeight="600" color="neutral.700">
            Date of Incorporation
          </Text>
          <Line mt="1.6rem" mb="2.4rem" />
          {/* input */}
          <FormDateInput
            name={`corporateShareholderDetails[${index}].doi`}
            label="Enter Date of Incorporation"
            datePickerProps={{ maxDate: new Date() }}
          />
        </Box>
        {/* Website */}
        <Box mt="2.4rem">
          <Text variant="subtitleSmall" fontWeight="600" color="neutral.700">
            Website
          </Text>
          <Line mt="1.6rem" mb="2.4rem" />
          {/* input */}
          <FormInput
            name={`corporateShareholderDetails[${index}].website`}
            label="Enter Website"
          />
        </Box>
        {/* Industry */}
        <Box mt="2.4rem">
          <Text variant="subtitleSmall" fontWeight="600" color="neutral.700">
            Industry
          </Text>
          <Line mt="1.6rem" mb="2.4rem" />
          {/* input */}
          <FormInput
            name={`corporateShareholderDetails[${index}].industry`}
            label="Enter Industry"
          />
        </Box>
        {/* Email */}
        <Box mt="2.4rem">
          <Text variant="subtitleSmall" fontWeight="600" color="neutral.700">
            Email
          </Text>
          <Line mt="1.6rem" mb="2.4rem" />
          {/* input */}
          <FormInput
            name={`corporateShareholderDetails[${index}].email`}
            label="Enter Email"
            type="email"
          />
        </Box>
      </Grid>

      {/* Address Details */}
      <Box mt="2.4rem">
        <Text variant="subtitleSmall" fontWeight="600" color="neutral.700">
          Corporate Address
        </Text>
      </Box>
      <Line mt="1.6rem" />
      <Grid gridTemplateColumns="repeat(2,1fr)" gridGap="2.4rem" mt="2rem">
        <FormInput
          name={`corporateShareholderDetails[${index}].address.addressLine1`}
          label="Address Line 1"
        />
        <FormInput
          name={`corporateShareholderDetails[${index}].address.addressLine2`}
          label="Address Line 2"
        />
        <FormSelect
          name={`corporateShareholderDetails[${index}].address.country`}
          label="Country"
          options={getCountries()}
          onChange={(country) => {
            setFieldValue(
              `corporateShareholderDetails[${index}].address.countryCode`,
              country?.countryCode
            );
            setFieldValue(
              `corporateShareholderDetails[${index}].address.postalCode`,
              ""
            );
          }}
        />
        <FormInput
          name={`corporateShareholderDetails[${index}].address.postalCode`}
          label="Postal Code"
          disabled={
            !postcodeValidatorExistsForCountry(
              values?.corporateShareholderDetails?.[index]?.address?.countryCode
            )
          }
        />
      </Grid>

      {/* Upload Doc for identification */}
      <Box mt="2.4rem">
        <Text variant="subtitleSmall" fontWeight="600" color="neutral.700">
          Upload Choosen Document
        </Text>
      </Box>
      <Line mt="1.6rem" mb="2.4rem" />
      <Table
        tableHeader={[
          { name: "Document Name" },
          // { name: "Document Type" },
          { name: "Upload file" },
          // { name: "Period" },
        ]}
      >
        <tbody>
          {/* Biz File */}
          <tr>
            <td>
              <Box>
                <Text variant="bodyLarge" color="neutral.700">
                  Biz File
                </Text>
                <Text variant="bodyLarge" color="neutral.700">
                  (jpeg, jpg, png, pdf)
                </Text>
                {touched?.corporateShareholderDetails?.[index]?.documents &&
                  errors?.corporateShareholderDetails?.[index]?.documents &&
                  !getDocumentById(
                    "bizFile",
                    values?.[`corporateShareholderDetails`]?.[index]?.documents
                  )?.url && <Error text={"* required"} />}
              </Box>
            </td>
            <td>
              <Upload
                url={
                  getDocumentById(
                    "bizFile",
                    values?.[`corporateShareholderDetails`]?.[index]?.documents
                  )?.url
                }
                name={
                  getDocumentById(
                    "bizFile",
                    values?.[`corporateShareholderDetails`]?.[index]?.documents
                  )?.name
                }
                time={
                  getDocumentById(
                    "bizFile",
                    values?.[`corporateShareholderDetails`]?.[index]?.documents
                  )?.time
                }
                onClose={() => {
                  const delDocs = JSON.parse(JSON.stringify(values))?.[
                    `corporateShareholderDetails`
                  ]?.[index]?.documents?.filter(
                    (item) => item?.id !== "bizFile"
                  );

                  setFieldValue(
                    `corporateShareholderDetails[${index}].documents`,
                    delDocs
                  );
                }}
                loading={
                  values?.[`${customIdGenerator(index, "bizFile")} loading`]
                }
                onChange={(e) => {
                  formUpload(
                    e,
                    customIdGenerator(index, "bizFile"),
                    setFieldValue,
                    (fieldData) => {
                      const addDocs =
                        JSON.parse(JSON.stringify(values))?.[
                          `corporateShareholderDetails`
                        ]?.[index]?.documents || [];

                      addDocs?.push({
                        id: "bizFile",
                        name: fieldData?.name,
                        time: fieldData?.uploadedAt,
                        url: fieldData?.url,
                        type: "bizFile",
                      });

                      setFieldValue(
                        `corporateShareholderDetails[${index}].documents`,
                        addDocs
                      );
                    }
                  );
                }}
              />
            </td>
          </tr>
          {/* Certificate of Incumbency */}
          <tr>
            <td>
              <Box>
                <Text variant="bodyLarge" color="neutral.700">
                  Certificate of Incumbency
                </Text>
                <Text variant="bodyLarge" color="neutral.700">
                  (pdf)
                </Text>
                {touched?.corporateShareholderDetails?.[index]?.documents &&
                  errors?.corporateShareholderDetails?.[index]?.documents &&
                  !getDocumentById(
                    "incumbencyCertificate",
                    values?.[`corporateShareholderDetails`]?.[index]?.documents
                  )?.url && <Error text={"* required"} />}
              </Box>
            </td>
            <td>
              <Upload
                url={
                  getDocumentById(
                    "incumbencyCertificate",
                    values?.[`corporateShareholderDetails`]?.[index]?.documents
                  )?.url
                }
                name={
                  getDocumentById(
                    "incumbencyCertificate",
                    values?.[`corporateShareholderDetails`]?.[index]?.documents
                  )?.name
                }
                time={
                  getDocumentById(
                    "incumbencyCertificate",
                    values?.[`corporateShareholderDetails`]?.[index]?.documents
                  )?.time
                }
                onClose={() => {
                  const delDocs = JSON.parse(JSON.stringify(values))?.[
                    `corporateShareholderDetails`
                  ]?.[index]?.documents?.filter(
                    (item) => item?.id !== "incumbencyCertificate"
                  );

                  setFieldValue(
                    `corporateShareholderDetails[${index}].documents`,
                    delDocs
                  );
                }}
                loading={
                  values?.[
                    `${customIdGenerator(
                      index,
                      "incumbencyCertificate"
                    )} loading`
                  ]
                }
                onChange={(e) => {
                  formUpload(
                    e,
                    customIdGenerator(index, "incumbencyCertificate"),
                    setFieldValue,
                    (fieldData) => {
                      const addDocs =
                        JSON.parse(JSON.stringify(values))?.[
                          `corporateShareholderDetails`
                        ]?.[index]?.documents || [];

                      addDocs?.push({
                        id: "incumbencyCertificate",
                        name: fieldData?.name,
                        time: fieldData?.uploadedAt,
                        url: fieldData?.url,
                        type: "incumbencyCertificate",
                      });

                      setFieldValue(
                        `corporateShareholderDetails[${index}].documents`,
                        addDocs
                      );
                    }
                  );
                }}
              />
            </td>
          </tr>
          {/* Corporate Address Proof */}
          <tr>
            <td>
              <Box>
                <Text variant="bodyLarge" color="neutral.700">
                  Corporate Address Proof
                </Text>
                <Text variant="bodyLarge" color="neutral.700">
                  (eg. Utilities Bills / Telecommunication Bills / Bank
                  Statements)
                </Text>
                {touched?.corporateShareholderDetails?.[index]?.documents &&
                  errors?.corporateShareholderDetails?.[index]?.documents &&
                  !getDocumentById(
                    "corporateAddressProof",
                    values?.[`corporateShareholderDetails`]?.[index]?.documents
                  )?.url && <Error text={"* required"} />}
              </Box>
            </td>
            <td>
              <Upload
                url={
                  getDocumentById(
                    "corporateAddressProof",
                    values?.[`corporateShareholderDetails`]?.[index]?.documents
                  )?.url
                }
                name={
                  getDocumentById(
                    "corporateAddressProof",
                    values?.[`corporateShareholderDetails`]?.[index]?.documents
                  )?.name
                }
                time={
                  getDocumentById(
                    "corporateAddressProof",
                    values?.[`corporateShareholderDetails`]?.[index]?.documents
                  )?.time
                }
                onClose={() => {
                  const delDocs = JSON.parse(JSON.stringify(values))?.[
                    `corporateShareholderDetails`
                  ]?.[index]?.documents?.filter(
                    (item) => item?.id !== "corporateAddressProof"
                  );

                  setFieldValue(
                    `corporateShareholderDetails[${index}].documents`,
                    delDocs
                  );
                }}
                loading={
                  values?.[
                    `${customIdGenerator(
                      index,
                      "corporateAddressProof"
                    )} loading`
                  ]
                }
                onChange={(e) => {
                  formUpload(
                    e,
                    customIdGenerator(index, "corporateAddressProof"),
                    setFieldValue,
                    (fieldData) => {
                      const addDocs =
                        JSON.parse(JSON.stringify(values))?.[
                          `corporateShareholderDetails`
                        ]?.[index]?.documents || [];

                      addDocs?.push({
                        id: "corporateAddressProof",
                        name: fieldData?.name,
                        time: fieldData?.uploadedAt,
                        url: fieldData?.url,
                        type: "corporateAddressProof",
                      });

                      setFieldValue(
                        `corporateShareholderDetails[${index}].documents`,
                        addDocs
                      );
                    }
                  );
                }}
              />
            </td>
          </tr>
        </tbody>
      </Table>

      <Grid
        gTC="1fr 1fr"
        gCG="2.4rem"
        gRG="2.4rem"
        mt="4.8rem"
        w="fit-content"
        aI="center"
      >
        {/* company shares */}
        <Text variant="subtitleSmall" fontWeight="600">
          Add Number of Company Shares:
        </Text>
        <Box w="20rem" ml="2.4rem">
          <FormInput
            name={`corporateShareholderDetails[${index}].numberOfCompanyShares`}
            label="Company Shares*"
            type="number"
          />
        </Box>

        {/* company shares */}
        <Text variant="subtitleSmall" fontWeight="600">
          Add Value per Share:
        </Text>
        <Box width="20rem" ml="2.4rem">
          <FormInput
            name={`corporateShareholderDetails[${index}].valuePerShare`}
            label="Value per Share*"
            type="number"
          />
        </Box>

        {/* Financial revenue */}
        {/* <Text variant="subtitleSmall" fontWeight="600">
          Choose First Financial Year:
        </Text>
        <Box w="20rem" ml="2.4rem">
          <FormDateInput
            name={`corporateShareholderDetails[${index}].firstFinancialYearRevenue`}
            label="Year*"
            datePickerProps={{
              dateFormat: "MM/yyyy",
              showMonthYearPicker: true,
              showFullMonthYearPicker: true,
            }}
          />
        </Box> */}

        {/* Financial revenue */}
        <Text variant="subtitleSmall" fontWeight="600">
          Choose Currency:
        </Text>
        <Box w="20rem" ml="2.4rem">
          <FormSelect
            name={`corporateShareholderDetails[${index}].currency`}
            label="Currency*"
            options={[...getCurrencies()]}
          />
        </Box>
      </Grid>
      {/* <Line mt="4rem" />
      <Button
        w="fit-content"
        px="4rem"
        mt="4rem"
        type="submit"
        onClick={() => {
          // onNext("Cart", true);
          // switchLayoutST();
        }}
      >
        NEXT : Checkout
      </Button> */}
    </>
  );
};
