import React from "react";
import { Form } from "formik";
import { Button, Flex, Line } from "atoms";
import { FormToggle } from "molecules";
import FormLayout from "../FormLayout";
import MemoTick from "styles/icons/Tick";
import MemoCross from "styles/icons/Cross";
import useService from "contexts/Service";

/**
 * reserved formik values
 *
 * @value {"local", "foriegn"} directorRole
 * @value {"yes" , "no"} localDirector
 *
 */
export const FormShareholderServices = ({
  breadcrum,
  step,
  onNext,
  onPrevious,
  ...props
}) => {
  const {
    actions: { switchLayoutST },
  } = useService();

  return (
    <FormLayout
      breadcrum={breadcrum}
      step={step}
      onPrevious={onPrevious}
      title="Are you using Chartswood Corporate Shareholder Services ?"
      // subtitle="Description Text"
      {...props}
    >
      {({ values }) => {
        return (
          <Form>
            <Flex mt="4rem">
              <FormToggle
                name="corporateShareHolderService"
                label="Yes"
                value="yes"
                Icon={MemoTick}
              />
              <FormToggle
                name="corporateShareHolderService"
                label="No"
                value="no"
                ml="3.2rem"
                Icon={MemoCross}
                isWrong
              />
            </Flex>
            <Line mt="4.8rem" />
            <Button
              w="fit-content"
              px="4rem"
              mt="4rem"
              onClick={() => {
                if (values?.corporateShareHolderService === "yes") {
                  onNext("Cart", true);
                  switchLayoutST();
                } else {
                  onNext("FormShareholderDetails");
                }
              }}
            >
              NEXT
            </Button>
          </Form>
        );
      }}
    </FormLayout>
  );
};
