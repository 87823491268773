import React, { useEffect } from "react";
import { Box, Button, Text, Line, Grid, Error } from "atoms";
import FormLayout from "../FormLayout";
import { Director } from "./components";
import { Accordian } from "organisms";
import FORM_NAMES from "../form.names";
import { cartDetailHandler, cartFeatureHandler } from "utils/utilities";
import PRODUCT_PRICES from "utils/princing.constant";
import { setNestedObjectValues } from "formik";

const DEFAULT_DIRECTOR_VALUES = {
  firstName: "",
  lastName: "",
  identificationType: "",
  identification: "",
  designation: "",
  email: "",
  dob: "",
  mobile: "",
  address: {
    addressLine1: "",
    addressLine2: "",
    postalCode: "",
    country: "",
    countryCode: "",
  },
  directorPOI: "",
  directorLocalAddressProof: "",
  documents: [],
};

const getDirectorTitle = (firstName, lastName) => {
  if (firstName || lastName) {
    let title = firstName || "";
    if (lastName) {
      title += `${firstName ? " " : ""}${lastName || ""}`;
    }
    return title;
  }
  return "";
};

export const FormDirectorDetails = ({
  step,
  onNext,
  formUpload,
  values,
  setFieldValue,
  validateForm,
  setTouched,
  setErrors,
  errors,
  touched,
  ...props
}) => {
  useEffect(() => {
    if (
      Array.isArray(values?.directorDetails) &&
      values?.directorDetails?.length > 0
    ) {
      const newCartFeatures = [
        ...new Array(values?.directorDetails?.length).fill({
          name: PRODUCT_PRICES.add_director.name,
          key: PRODUCT_PRICES.add_director.type,
        }),
      ];

      const newCartDetails = [
        ...new Array(values?.directorDetails?.length).fill(
          PRODUCT_PRICES.add_director
        ),
      ];

      setFieldValue("cartSummary.features", newCartFeatures);
      setFieldValue("cartDetails", newCartDetails);
    } else {
      setFieldValue("cartSummary.features", []);
      setFieldValue("cartDetails", []);
    }
  }, [values?.directorDetails?.length]);

  return (
    <FormLayout
      step={step}
      title={"Add New Director’s Detail"}
      subtitle="Please provide all relevant information of the new director."
      {...props}
    >
      {/* {({ values, setFieldValue }) => {
        return (
          <Form> */}

      {/* +ADD Director */}
      {(values?.directorDetails || [])?.length <= 0 && (
        <Grid gTC="1fr" gCG="3.2rem" mt="4rem">
          <Button
            w="30rem"
            type="button"
            onClick={() => {
              setFieldValue("directorDetails", [
                ...(values?.directorDetails || []),
                { ...DEFAULT_DIRECTOR_VALUES },
              ]);
            }}
          >
            +Add Director
          </Button>{" "}
        </Grid>
      )}

      <Accordian
        accordians={(values?.directorDetails || [])?.map((director, index) => ({
          title:
            getDirectorTitle(director?.firstName, director?.lastName) ||
            `New Director`,
          children: (
            <Director
              values={values}
              index={index}
              formUpload={formUpload}
              setFieldValue={setFieldValue}
              errors={errors}
              touched={touched}
            />
          ),
        }))}
        // mt="8rem"
        gap="3.6rem"
        isRemovable={(values?.directorDetails || [])?.length > 1}
        onRemove={(index) => {
          let cloneDirectorDetails = JSON.parse(
            JSON.stringify(values?.directorDetails || [])
          );
          cloneDirectorDetails = cloneDirectorDetails?.filter(
            (_, cloneIndex) => cloneIndex !== index
          );
          setFieldValue("directorDetails", cloneDirectorDetails);
        }}
      />
      {touched?.directorDetails &&
        errors?.directorDetails &&
        typeof errors?.directorDetails === "string" && (
          <Error text={errors?.directorDetails || ""} mt="2rem" />
        )}

      {(values?.directorDetails || [])?.length > 0 && (
        <Box
          mt="5rem"
          cursor="pointer"
          onClick={() => {
            setFieldValue("directorDetails", [
              ...(values?.directorDetails || []),
              { ...DEFAULT_DIRECTOR_VALUES },
            ]);
          }}
        >
          <Text variant="bodyMedium" fontWeight="600" color="secondary.700">
            +Add Director
          </Text>
        </Box>
      )}
      <Line mt="4.8rem" />
      <Button
        w="fit-content"
        px="4rem"
        mt="4rem"
        type="button"
        onClick={() => {
          validateForm({
            actionType: values?.actionType,
            directorDetails: values?.directorDetails,
          })?.then((dataErrorBag) => {
            if (dataErrorBag?.directorDetails) {
              setTouched(
                setNestedObjectValues(
                  { directorDetails: dataErrorBag?.directorDetails },
                  true
                )
              );
              setErrors(dataErrorBag);
            } else {
              onNext(FORM_NAMES.FormDetails, true);
            }
          });
        }}
      >
        Next: Upload Documents
      </Button>
      {/* </Form>
        );
      }} */}
    </FormLayout>
  );
};
