import React from "react";
import { Line, Text, Table, Button, Box, Grid } from "atoms";
import { FormSelect, UploadBox, FormInput } from "molecules";
import { FormLayout } from "templates/CorporateSecretarial/components";
import MemoUploadIcon from "assets/icons/Upload";

export const FormXeroAccounting = ({ step, onNext, ...props }) => {
  return (
    <>
      <FormLayout
        //step={step}
        title="Kindly provide Details:"
        // subtitle="Description Text"
        {...props}
      >
        <Text
          mt="4.8rem"
          variant="subtitleSmall"
          color="neutral.700"
          fontWeight="600"
        >
          Upload Choosen Document
        </Text>
        <Line mt="1.6rem" mb="2.4rem" />
        {/* Table for upload doc*/}
        <Table
          tableHeader={[
            { name: "Document Name" },
            { name: "Document Type" },
            { name: "Period" },
            { name: "Upload file" },
          ]}
        >
          <tbody>
            <tr>
              <td>
                <Text variant="bodyLarge" color="neutral.700">
                  Bizfile
                </Text>
              </td>
              <td>
                <FormSelect name="fromxeropassport" label="Passport" />
              </td>
              <td>
                <Text variant="bodyLarge" fontWeight="600" color="neuteal.800">
                  One-Time
                </Text>
              </td>
              <td>
                <Button IconProps={{ Icon: MemoUploadIcon }}>Upload</Button>
              </td>
            </tr>

            <tr>
              <td>
                <Text variant="bodyLarge" color="neutral.700">
                  Bizfile
                </Text>
              </td>
              <td>
                <FormSelect name="fromxeropassport" label="Passport" />
              </td>
              <td>
                <Text variant="bodyLarge" fontWeight="600" color="neuteal.800">
                  One-Time
                </Text>
              </td>
              <td>
                <UploadBox />
              </td>
            </tr>

            <tr>
              <td>
                <Text variant="bodyLarge" color="neutral.700">
                  Bizfile
                </Text>
              </td>
              <td>
                <FormSelect name="fromxeropassport" label="Passport" />
              </td>
              <td>
                <Text variant="bodyLarge" fontWeight="600" color="neuteal.800">
                  One-Time
                </Text>
              </td>
              <td>
                <UploadBox />
              </td>
            </tr>
          </tbody>
        </Table>
        {/* Email and Mobile */}
        <Grid gTC="repeat(2,auto)" gridGap="2.4rem" mt="4.8rem">
          {/* Email */}
          <Box>
            <Text variant="subtitleSmall" fontWeight="600" color="neutral.700">
              Email
            </Text>
            <Line mt="1.6rem" mb="2.4rem" />
            {/* input */}
            <FormInput
              name="FormXeroAccountingEmail"
              label="Enter"
              placeholder="email"
            />
          </Box>
          {/* Mobile */}
          <Box>
            <Text variant="subtitleSmall" fontWeight="600" color="neutral.700">
              Mobile
            </Text>
            <Line mt="1.6rem" mb="2.4rem" />
            <FormInput name="FormXeroAccountingMobile" label="Enter" />
          </Box>
        </Grid>
        {/* Address Details */}
        <Box mt="4.8rem">
          <Text variant="subtitleSmall" fontWeight="600" color="neutral.700">
            Residential Address
          </Text>
        </Box>
        <Line mt="1.6rem" />
        <Grid gridTemplateColumns="repeat(2,1fr)" gridGap="2.4rem" mt="2rem">
          <FormInput name="address1" placeholder="address" label="Enter" />
          <FormInput name="address2" placeholder="address" label="Enter" />
          <FormInput name="address3" placeholder="address" label="Enter" />
          <FormSelect name="FormXeroAccountingCountry" label="Country" />
        </Grid>
        <Line mt="4.8rem" />
        <Button
          w="fit-content"
          px="4rem"
          mt="4rem"
          type="submit"
          onClick={onNext}
        >
          Next : Payment
        </Button>
      </FormLayout>
    </>
  );
};
