import { cwToast } from "molecules";
import { useCallback, useReducer } from "react";
// import { useNavigate } from "react-router-dom";

import api from "utils/api";
import { createContainer } from "utils/context";
// import { removeCookie } from "utils/cookie";
import { persist, hydrate } from "utils/persist";
import { AUTH_ACTIONS } from "./actions";
import { authReducer, initialState } from "./reducers";
import { evaluateCartTotal } from "utils/utilities";
import { useNavigate } from "react-router-dom";
// import { cwToast } from "molecules";

export const { useContext: useService, Provider: ServiceProvider } =
  createContainer(() => {
    const [state, dispatch] = useReducer(authReducer, {
      ...initialState,
      isLayoutFT: hydrate("isLayoutFT") === "true" ? true : false,
    });
    const navigate = useNavigate();

    /**
     * A layout function that enables application to switch to first time user layout
     */
    const switchLayoutFT = useCallback(() => {
      // dispatch(AUTH_ACTIONS.switchLayout(true));
      // persist("isLayoutFT", true);
      dispatch(AUTH_ACTIONS.switchLayout(false));
      persist("isLayoutFT", false);
    }, []);

    /**
     * A layout function that enables application to switch to regular layout
     */
    const switchLayoutST = useCallback(() => {
      dispatch(AUTH_ACTIONS.switchLayout(false));
      persist("isLayoutFT", false);
    }, []);

    const getIncorporation = useCallback(async () => {
      try {
        const { data } = await api("/incorporations", {
          method: "get",
        });

        return data?.data;
      } catch (e) {
        console.error(e);
      }
    }, []);

    const createIncorporation = useCallback(
      async ({
        shareholderDetails,
        corporateShareholderDetails,
        directorType,
        documents,
        companyName,
        primaryCompanyActivity,
        primaryBusinessDescription,
        secondaryCompanyActivity,
        secondaryBusinessDescription,
        corporateAddressDetails,
        directorDetails,
        usingShareholderDetails,
        usingDirectorDetails,
        usingCorporateAddressDetails,
        financialYear,
      }) => {
        try {
          const reqData = {
            shareholderDetails: shareholderDetails || [],
            corporateShareholderDetails: corporateShareholderDetails || [],
            directorType: directorType || "",
            documents: documents || [],
            companyName: companyName || "",
            primaryCompanyActivity: primaryCompanyActivity || "",
            primaryBusinessDescription: primaryBusinessDescription || "",
            secondaryCompanyActivity: secondaryCompanyActivity || "",
            secondaryBusinessDescription: secondaryBusinessDescription || "",
            corporateAddressDetails: corporateAddressDetails || "",
            directorDetails: directorDetails || [],
            usingShareholderDetails: usingShareholderDetails,
            usingDirectorDetails: usingDirectorDetails,
            usingCorporateAddressDetails: usingCorporateAddressDetails,
            cartSummary: null,
            financialYear: financialYear || "",
          };

          const { data } = await api("/incorporations", {
            method: "post",
            data: reqData,
          });

          return data?.data;
        } catch (e) {
          console.error(e);
          cwToast(
            "error",
            "Incorporation not created",
            "Failed to create new incorporation"
          );
          throw e;
        }
      },
      []
    );
    const updateIncorporation = useCallback(
      async (
        {
          shareholderDetails,
          corporateShareholderDetails,
          directorType,
          documents,
          companyName,
          primaryCompanyActivity,
          primaryBusinessDescription,
          secondaryCompanyActivity,
          secondaryBusinessDescription,
          corporateAddressDetails,
          directorDetails,
          usingShareholderDetails,
          usingDirectorDetails,
          usingCorporateAddressDetails,
          cartSummary,
          financialYear,
        },
        id
      ) => {
        try {
          const reqData = {
            shareholderDetails: shareholderDetails || [],
            corporateShareholderDetails: corporateShareholderDetails || [],
            directorType: directorType || "",
            documents: documents || [],
            companyName: companyName || "",
            primaryCompanyActivity: primaryCompanyActivity || "",
            primaryBusinessDescription: primaryBusinessDescription || "",
            secondaryCompanyActivity: secondaryCompanyActivity || "",
            secondaryBusinessDescription: secondaryBusinessDescription || "",
            corporateAddressDetails: corporateAddressDetails || "",
            directorDetails: directorDetails || [],
            usingShareholderDetails: usingShareholderDetails,
            usingDirectorDetails: usingDirectorDetails,
            usingCorporateAddressDetails: usingCorporateAddressDetails,
            cartSummary: cartSummary || null,
            financialYear: financialYear || "",
          };

          const { data } = await api(`/incorporations/${id}`, {
            method: "put",
            data: reqData,
          });

          return data?.data;
        } catch (e) {
          console.error(e);
          cwToast(
            "error",
            "Incorporation not update",
            "Failed to update incorporation"
          );
          throw e;
        }
      },
      []
    );

    const initServices = useCallback(async () => {
      // change it back to false
      let isFirstTimeUser = true; // dummy value

      // // load services
      // const incorporation = await getIncorporation();

      // // if any incorporation service does not exists
      // if (incorporation?.length === 0) {
      //   isFirstTimeUser = true;
      // }

      isFirstTimeUser ? switchLayoutFT() : switchLayoutST();
    }, [switchLayoutFT, switchLayoutST]);

    const getCSByUser = useCallback(async () => {
      try {
        const { data } = await api("/cs/all", {
          method: "get",
        });

        return data?.data;
      } catch (e) {
        console.error(e);
      }
    }, []);

    const getCSByServiceType = useCallback(async (serviceType) => {
      try {
        const { data } = await api(`/cs?serviceType=${serviceType}`, {
          method: "get",
        });

        return data?.data;
      } catch (e) {
        console.error(e);
      }
    }, []);

    const createCS = useCallback(async (details, serviceType, cartSummary) => {
      try {
        const reqData = {
          details,
          serviceType,
          cartSummary: cartSummary || null,
        };

        const { data } = await api(`/cs`, {
          method: "post",
          data: reqData,
        });

        // cwToast("success", "CS Created", "Service has been created for you");

        // payment redirection
        // data?.data && window.open(data?.data, "_self");

        return data?.data;
      } catch (e) {
        console.error(e);
        cwToast("error", "CS not created", "Failed to create CS");
        // throw e;
      }
    }, []);

    const getSubscriptionsByServiceType = useCallback(async (serviceType) => {
      // incorporation , tax , accounting , ev
      try {
        const { data } = await api(
          `/subscriptions?serviceType=${serviceType}`,
          {
            method: "get",
          }
        );

        return data?.data;
      } catch (e) {
        console.error(e);
      }
    }, []);

    const getSubscriptionsById = useCallback(async (id) => {
      try {
        const { data } = await api(`/subscriptions/${id}`, {
          method: "get",
        });

        return data?.data;
      } catch (e) {
        console.error(e);
      }
    }, []);

    const createEV = useCallback(async (cartSummary, details) => {
      try {
        const reqData = {
          details: details,
          serviceType: "ev",
          cartSummary: cartSummary || null,
        };

        const { data } = await api(`/ev`, {
          method: "post",
          data: reqData,
        });

        cwToast("success", "EV Created", "Service has been created for you");
        return data?.data;
      } catch (e) {
        console.error(e);
        cwToast("error", "EV not created", "Failed to create EV");
        // throw e;
      }
    }, []);

    const createTaxes = useCallback(async (cartSummary, details) => {
      try {
        const reqData = {
          details: details,
          serviceType: "taxes",
          cartSummary: cartSummary || null,
        };

        const { data } = await api(`/taxes`, {
          method: "post",
          data: reqData,
        });

        cwToast("success", "Taxes Created", "Service has been created for you");
        return data?.data;
      } catch (e) {
        console.error(e);
        cwToast("error", "Taxes not created", "Failed to create Taxes");
        // throw e;
      }
    }, []);

    const createAccounting = useCallback(async (cartSummary, details) => {
      try {
        const reqData = {
          details: details,
          serviceType: "accounting",
          cartSummary: cartSummary || null,
        };

        const { data } = await api(`/accounting`, {
          method: "post",
          data: reqData,
        });

        cwToast(
          "success",
          "Accounting Created",
          "Service has been created for you"
        );
        return data?.data;
      } catch (e) {
        console.error(e);
        cwToast(
          "error",
          "Accounting not created",
          "Failed to create Accounting"
        );
        // throw e;
      }
    }, []);

    const getCart = useCallback(async () => {
      try {
        const { data } = await api(`/cart`, {
          method: "get",
        });

        // check sub prices
        let totalSubPrice = 0;
        data?.data?.cartDetails?.forEach((sub) => {
          if (sub?.subscriptionDetails) {
            totalSubPrice += sub?.subscriptionDetails?.prices?.[0]?.amount;
          }
        });
        // ---

        dispatch(
          AUTH_ACTIONS.cart({
            ...data?.data,
            eval: evaluateCartTotal(data?.data?.cartDetails) + totalSubPrice,
          })
        );
        // return data?.data;
      } catch (e) {
        console.error(e);
      }
    }, []);

    const removeItemFromCart = useCallback(async (serviceId, serviceType) => {
      try {
        const { data } = await api(`/cart`, {
          method: "put",
          data: { serviceId, serviceType },
        });

        cwToast("success", "Cart Item Removed");
        return data?.data;
      } catch (e) {
        console.error(e);
        cwToast("error", "Item not removed", "Failed to delete cart item");
      }
    }, []);

    const getTasksByUser = useCallback(async (params) => {
      try {
        const { data } = await api(`/tasks/user`, {
          method: "get",
          params,
        });

        return data;
      } catch (e) {
        console.error(e);
      }
    }, []);

    const getTasksByOwner = useCallback(async (params) => {
      try {
        const { data } = await api(`/tasks/taskowner`, {
          method: "get",
          params,
        });

        return data;
      } catch (e) {
        console.error(e);
      }
    }, []);

    const getTaskCount = useCallback(async (params) => {
      try {
        const { data } = await api(`/tasks/status/count`, {
          method: "get",
          params,
        });

        return data;
      } catch (e) {
        console.error(e);
      }
    }, []);

    const getTasksById = useCallback(async (id) => {
      try {
        const { data } = await api(`/tasks/${id}`, {
          method: "get",
        });

        return data;
      } catch (e) {
        console.error(e);
      }
    }, []);

    const updateTask = useCallback(async (reqData) => {
      try {
        const { data } = await api(`/tasks`, {
          method: "put",
          data: reqData,
        });

        return data?.data;
      } catch (e) {
        console.error(e);
      }
    }, []);

    const updateTaskSubsteps = useCallback(async (reqData) => {
      try {
        const { data } = await api(`/tasks/substeps`, {
          method: "put",
          data: reqData,
        });

        return data?.data;
      } catch (e) {
        console.error(e);
      }
    }, []);

    const addTaskComments = useCallback(async (taskId, message, to) => {
      try {
        const reqData = {
          taskId,
          message,
          to,
        };

        const { data } = await api(`/tasks/comments`, {
          method: "post",
          data: reqData,
        });

        return data?.data;
      } catch (e) {
        console.error(e);
        // throw e;
      }
    }, []);

    const getDocuments = useCallback(async (params) => {
      try {
        const { data } = await api(`/documents/all`, {
          method: "get",
          params,
        });

        return data;
      } catch (e) {
        console.error(e);
      }
    }, []);

    const updateDocument = useCallback(
      async ({
        serviceId,
        documentLink,
        documentName,
        documentTime,
        documentType,
      }) => {
        try {
          const reqData = {
            serviceId: serviceId || "",
            documentLink: documentLink || "",
            documentName: documentName || "",
            documentTime: documentTime || "",
            documentType: documentType || "",
          };

          const { data } = await api(`/documents`, {
            method: "post",
            data: reqData,
          });

          return data?.data;
        } catch (e) {
          console.error(e);
          throw e;
        }
      },
      []
    );

    const getPayments = useCallback(async (params) => {
      try {
        const { data } = await api(`/payments`, {
          method: "get",
          params,
        });

        return data;
      } catch (e) {
        console.error(e);
      }
    }, []);

    const getTriggerIncoporationPayments = useCallback(
      async (params, isStripe = true) => {
        try {
          const { data } = await api(`/incorporations/payment`, {
            method: "post",
            data: params,
          });

          // payment redirection
          isStripe
            ? data?.data && window.open(data?.data, "_self")
            : navigate("/cart");

          return data;
        } catch (e) {
          console.error(e);
        }
      },
      []
    );

    const getProductPrices = useCallback(async () => {
      try {
        const { data } = await api(`/products`, {
          method: "get",
        });

        dispatch(AUTH_ACTIONS.productPrices(data?.data));
      } catch (e) {
        console.error(e);
      }
    }, []);

    return {
      state,
      actions: {
        switchLayoutFT,
        switchLayoutST,
        getIncorporation,
        createIncorporation,
        updateIncorporation,
        initServices,
        createCS,
        getCSByUser,
        getCSByServiceType,
        getSubscriptionsByServiceType,
        getSubscriptionsById,
        createEV,
        createTaxes,
        createAccounting,
        getCart,
        getTasksByUser,
        getTasksByOwner,
        getTaskCount,
        getTasksById,
        updateTask,
        updateTaskSubsteps,
        addTaskComments,
        getDocuments,
        updateDocument,
        getPayments,
        removeItemFromCart,
        getTriggerIncoporationPayments,
        getProductPrices,
      },
    };
  });
