import React, { useState } from "react";
import { Box, Text, Line } from "atoms";
import { Tab } from "molecules";
import {
  AccountingService,
  OtherServices,
  RegisterCompany,
} from "./components";

const tabData = [
  {
    title: "Register a company",
  },
  {
    title: "Accounting services",
  },

  {
    title: "Other services",
  },
];

export const PricingAndPlan = () => {
  //for tabs
  const [activeTab, setActiveTab] = useState(0);

  return (
    <>
      <Box px="3.2rem" mt="2.4rem">
        <Text
          variant="subtitleLarge"
          fontWeight="600"
          color="neutral.800"
          mb="1.6rem"
        >
          Pricing
        </Text>
        <Line mt="1.6rem" mb="2.4rem" />

        <Box bg="white" pt="3.2rem" px="2.4rem" boxShadow="m">
          <Text color="neutral.800" variant="subtitleSmall" fontWeight="700">
            Wallet-friendly Price Packages
          </Text>
          <Text color="neutral.500" variant="bodyMedium" mt=".8rem" mb="3.2rem">
            Simple Pricing .No Hidden fees
          </Text>
          <Tab
            tabs={tabData}
            activeTab={activeTab}
            setActiveTab={(index) => setActiveTab(index)}
          />

          {
            {
              0: <RegisterCompany />,
              1: <AccountingService />,
              2: <OtherServices />,
            }[activeTab]
          }
        </Box>
      </Box>
    </>
  );
};
