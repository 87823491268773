import * as React from "react";

function EyeShow(props) {
  return (
    <svg
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8 0.600098C11.9871 0.600098 15.3042 3.35921 16 7.0001C15.3049 10.641 11.9871 13.4001 8 13.4001C4.01294 13.4001 0.695813 10.641 0 7.0001C0.695073 3.35921 4.01294 0.600098 8 0.600098ZM8 11.9779C9.50806 11.9776 10.9714 11.4849 12.1504 10.5807C13.3294 9.67638 14.1543 8.41398 14.4901 7.0001C14.1531 5.58734 13.3276 4.32632 12.1487 3.42323C10.9698 2.52013 9.5072 2.02838 8 2.02838C6.4928 2.02838 5.03018 2.52013 3.85128 3.42323C2.67238 4.32632 1.84695 5.58734 1.50994 7.0001C1.84571 8.41398 2.67062 9.67638 3.84963 10.5807C5.02865 11.4849 6.49194 11.9776 8 11.9779ZM8 10.2001C7.1175 10.2001 6.27114 9.86296 5.64712 9.26284C5.02309 8.66272 4.67252 7.84879 4.67252 7.0001C4.67252 6.1514 5.02309 5.33747 5.64712 4.73736C6.27114 4.13724 7.1175 3.8001 8 3.8001C8.8825 3.8001 9.72886 4.13724 10.3529 4.73736C10.9769 5.33747 11.3275 6.1514 11.3275 7.0001C11.3275 7.84879 10.9769 8.66272 10.3529 9.26284C9.72886 9.86296 8.8825 10.2001 8 10.2001ZM8 8.77788C8.49028 8.77788 8.96048 8.59057 9.30716 8.25718C9.65384 7.92378 9.8486 7.47159 9.8486 7.0001C9.8486 6.5286 9.65384 6.07642 9.30716 5.74302C8.96048 5.40962 8.49028 5.22232 8 5.22232C7.50972 5.22232 7.03952 5.40962 6.69284 5.74302C6.34616 6.07642 6.1514 6.5286 6.1514 7.0001C6.1514 7.47159 6.34616 7.92378 6.69284 8.25718C7.03952 8.59057 7.50972 8.77788 8 8.77788Z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoEyeShowIcon = React.memo(EyeShow);
export default MemoEyeShowIcon;
