import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { format } from "date-fns";
import { Form, Formik } from "formik";

import { Box, Flex, Grid, Pagination, Text } from "atoms";
import { TabHeader, FilterChips } from "organisms";
import { ActionModal, CategoryCards, FilterModal } from "./components";
import MemoBxTaskIcon from "assets/icons/sidebar/BxTask";
import MemoBxListCheckIcon from "assets/icons/sidebar/BxListCheck";
import MemoBxNewsIcon from "assets/icons/sidebar/BxNews";
import Table from "atoms/Table";
import { DefaultChips, FormInput, ProfileAvatar } from "molecules";
import MemoEyeShowIcon from "assets/icons/EyeShow";
import MemoFilterIcon from "assets/icons/Filter";
import useAuth from "contexts/Auth";
import { OWNER_ROLE_CODE, PAGE_SIZE, USER_ROLE_CODE } from "utils/constants";
import useService from "contexts/Service";
import { useSort } from "hooks/useSort";
import { usePageNumber, usePageSize } from "hooks/usePageNumber";
import { useNavigate, useSearchParams } from "react-router-dom";
import { filterStateHandler, getStatusChip } from "utils/utilities";
import { useLocationQueryAdd } from "hooks/useLocationQuery";
import SERVICE_TYPE_CODE from "utils/services.type.constants";

const getStatusByName = (name, statuses) => {
  return statuses?.find((status) => status?.statusName === name) || {};
};

export const MyTask = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const {
    state: { userData },
  } = useAuth();

  const {
    actions: {
      getTasksByUser,
      getTasksByOwner,
      getTaskCount,
      getSubscriptionsByServiceType,
    },
  } = useService();

  // pricing plans
  const [pricingPlans, setPricingPlans] = useState([]);

  useEffect(() => {
    getSubscriptionsByServiceType("incorporation").then((data) => {
      setPricingPlans(data);
    });
  }, [getSubscriptionsByServiceType]);

  // filter
  const filterRef = useRef();
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [filterState, setFilterState] = useState({});
  const fromDateFilter = searchParams.get("fromDate");
  const statusFilter = searchParams.get("status");
  const taskOwnerFilter = searchParams.get("taskOwner");
  const userIdFilter = searchParams.get("userId");
  const searchFilter = searchParams.get("serviceType");
  const setParam = useLocationQueryAdd();

  // action modal
  const [isActionModalOpen, setIsActionModalOpen] = useState(false);

  const [total, setTotal] = useState(0);
  const sort = useSort();
  const page = Number(usePageNumber());
  const pageSize = Number(usePageSize());

  // tasks handling starts
  const [tasks, setTasks] = useState([]);
  const [statusCount, setStatusCount] = useState([]);
  // const [activeTasks, setActiveTasks] = useState([]);

  const queryHydrateHandler = useCallback(
    (allFilters) => {
      setFilterState(allFilters);

      allFilters?.date?.startDate &&
        setParam(
          "fromDate",
          allFilters?.date?.startDate &&
            new Date(allFilters?.date?.startDate).toISOString()
        );
      allFilters?.statusId && setParam("status", allFilters?.statusId);
      allFilters?.search && setParam("serviceType", allFilters?.search);

      allFilters?.assignee &&
        userData?.role === USER_ROLE_CODE &&
        setParam("taskOwner", allFilters?.assignee);
      allFilters?.assignee &&
        userData?.role === OWNER_ROLE_CODE &&
        setParam("userId", allFilters?.assignee);
    },
    [userData?.role]
  );

  // filterParamHandler starts
  const filterParamHandler = useCallback(() => {
    const params = {
      fromDate: fromDateFilter,
      status: statusFilter,
      taskOwner: taskOwnerFilter,
      userId: userIdFilter,
      serviceType: searchFilter,
    };

    return params;
  }, [
    fromDateFilter,
    statusFilter,
    taskOwnerFilter,
    userIdFilter,
    searchFilter,
  ]);

  const fetchTasks = useCallback(async () => {
    if (userData?.role === USER_ROLE_CODE) {
      getTasksByUser({
        sortBy: sort || "createdAt:desc",
        limit: pageSize,
        skip: (page - 1) * pageSize,
        ...filterParamHandler(),
      }).then((data) => {
        setTasks(data?.data);
        setTotal(data?.total);
      });
    }
    if (userData?.role === OWNER_ROLE_CODE) {
      getTasksByOwner({
        sortBy: sort || "createdAt:desc",
        limit: pageSize,
        skip: (page - 1) * pageSize,
        ...filterParamHandler(),
      }).then((data) => {
        setTasks(data?.data);
        setTotal(data?.total);
      });
    }
  }, [
    userData?.role,
    getTasksByUser,
    getTasksByOwner,
    sort,
    pageSize,
    page,
    filterParamHandler,
  ]);

  useEffect(() => {
    fetchTasks();
  }, [fetchTasks]);

  // task handling ends

  // status fetch starts
  const fetchStatusCount = useCallback(() => {
    getTaskCount().then((data) => {
      setStatusCount(data?.data);
    });
  }, [getTaskCount]);

  useEffect(() => {
    fetchStatusCount();
  }, [fetchStatusCount]);
  // status fetch ends

  // status card handling starts
  const categoryCards = useMemo(() => {
    return [
      {
        iconProps: {
          Icon: MemoBxNewsIcon,
          iconColor: "primary.700",
          iconBg: "primary.50",
        },
        id: getStatusByName("created", statusCount)?.statusId,
        value: getStatusByName("created", statusCount)?.count,
        description: "Created",
      },
      {
        iconProps: {
          Icon: MemoBxTaskIcon,
          iconColor: "secondary.900",
          iconBg: "secondary.50",
        },
        id: getStatusByName("completed", statusCount)?.statusId,
        value: getStatusByName("completed", statusCount)?.count,
        description: "Completed",
      },
      {
        iconProps: {
          Icon: MemoBxListCheckIcon,
          iconColor: "primary.700",
          iconBg: "primary.50",
        },
        id: getStatusByName("in-progress", statusCount)?.statusId,
        value: getStatusByName("in-progress", statusCount)?.count,
        description: "In Progress",
      },
      {
        iconProps: {
          Icon: MemoBxTaskIcon,
          iconColor: "secondary.900",
          iconBg: "secondary.50",
        },
        id: getStatusByName("pending", statusCount)?.statusId,
        value: getStatusByName("pending", statusCount)?.count,
        description: "Pending",
      },
      {
        iconProps: {
          Icon: MemoBxNewsIcon,
          iconColor: "primary.700",
          iconBg: "primary.50",
        },
        id: getStatusByName("overdue", statusCount)?.statusId,
        value: getStatusByName("overdue", statusCount)?.count,
        description: "Overdue",
      },
    ];
  }, [statusCount]);
  // status card handling ends

  // hydrating filter starts
  const hydrateFilter = useCallback(() => {
    let chosenRoleBasedAssignee = [];

    if (userData?.role === USER_ROLE_CODE) {
      chosenRoleBasedAssignee = tasks
        ?.map((task) => ({
          id: task?.taskOwner?._id,
          email: task?.taskOwner?.email,
        }))
        ?.filter(
          (task, index, self) =>
            self?.findIndex((o) => o?.id === task?.id) === index
        );
    }

    if (userData?.role === OWNER_ROLE_CODE) {
      chosenRoleBasedAssignee = tasks
        ?.map((task) => ({
          id: task?.user?._id,
          email: `${task?.user?.firstName} ${task?.user?.lastName}`,
        }))
        ?.filter(
          (task, index, self) =>
            self?.findIndex((o) => o?.id === task?.id) === index
        );
    }

    const allFilters = {
      status: categoryCards,
      date: { startDate: "", isApplied: false },
      assigneeOptions: chosenRoleBasedAssignee,
    };

    if (fromDateFilter) {
      allFilters.date.startDate = fromDateFilter;
      allFilters.date.isApplied = true;
    }

    if (statusFilter) {
      allFilters.statusId = statusFilter;
    }

    if (taskOwnerFilter) {
      allFilters.assignee = taskOwnerFilter;
    }

    if (userIdFilter) {
      allFilters.assignee = userIdFilter;
    }

    if (searchFilter) {
      allFilters.search = searchFilter;
    }

    // console.log("hanlder Filter ran", allFilters);
    setFilterState(allFilters);
    queryHydrateHandler(allFilters);
  }, [
    tasks,
    fromDateFilter,
    statusFilter,
    taskOwnerFilter,
    userIdFilter,
    userData?.role,
    categoryCards,
    searchFilter,
    queryHydrateHandler,
  ]);

  useEffect(() => {
    hydrateFilter();
  }, [hydrateFilter]);
  // hydrating filter ends

  // handle Filter removal starts
  const handleFilter = useCallback(
    /**
     * @param {any} type key of the filter state
     * @param {any} id id of the item under key filter to be reversed
     *
     * @param {any} data specific for "type === date" : applies the given data to date type filter
     */
    (type, id, data) => {
      setFilterState(filterStateHandler(filterState, type, id, data));
    },
    [filterState]
  );
  // handle Filter removal ends

  // handle filter query starts
  // useEffect(() => {
  // console.log(filterState);
  // setParam(
  //   "fromDate",
  //   filterState?.date?.startDate &&
  //     new Date(filterState?.date?.startDate).toISOString()
  // );
  // setParam("status", filterState?.statusId);
  // userData?.role === USER_ROLE_CODE &&
  //   setParam("taskOwner", filterState?.assignee);
  // userData?.role === OWNER_ROLE_CODE &&
  //   setParam("userId", filterState?.assignee);
  // }, [filterState]);
  // handle filter query ends

  return (
    <Box px="3.2rem">
      <TabHeader
        title="My Task"
        breadcrum={{
          firstText: "Home",
          firstUrl: "/dashboard",
          secondText: "My Task",
        }}
        onPrevious={() => navigate("/dashboard")}
      />

      <Grid gTC="repeat(5,1fr)" gCG="2.4rem" mt="2.4rem">
        {categoryCards?.map((item, index) => {
          return <CategoryCards key={index} {...item} />;
        })}
      </Grid>

      <Box bg="#fff" mt="2.6rem">
        <Flex width="100%" aI="center" jC="space-between" px="2.4rem">
          <Box>
            {/* title */}
            <Text
              variant="subtitleMedium"
              fontWeight="600"
              color="neutral.800"
              pt="4rem"
            >
              Task list
            </Text>

            {/* filter chips */}
            <Grid
              mt="1.6rem"
              // gridAutoFlow="column"
              gCG="1.7rem"
              gRG="1.7rem"
              gTC="repeat(4, auto)"
            >
              {/* status chips */}
              {filterState?.statusId &&
                [
                  categoryCards?.find(
                    (status) => status?.id === filterState?.statusId
                  ),
                ]?.map((task, index) => (
                  <FilterChips
                    key={index}
                    title={task?.description}
                    onClickCross={() => setParam("status", "")}
                  />
                ))}
              {/* date chips */}
              {filterState?.date?.isApplied && (
                <FilterChips
                  title={`${
                    filterState?.date?.startDate &&
                    format(new Date(filterState?.date?.startDate), "dd MMM")
                  }`}
                  onClickCross={() =>
                    // handleFilter("date", "", {
                    //   isApplied: false,
                    //   startDate: "",
                    // })
                    setParam("fromDate", "")
                  }
                />
              )}
              {/* assignee chips */}
              {filterState?.assignee &&
                [
                  tasks?.find(
                    (task) =>
                      ({
                        [USER_ROLE_CODE]: task?.taskOwner?._id,
                        [OWNER_ROLE_CODE]: task?.user?._id,
                      }[userData?.role] === filterState?.assignee)
                  ),
                ]?.map((task, index) => (
                  <FilterChips
                    key={index}
                    title={
                      {
                        [USER_ROLE_CODE]: task?.taskOwner?.email,
                        [OWNER_ROLE_CODE]: `${task?.user?.firstName} ${task?.user?.lastName}`,
                      }[userData?.role]
                    }
                    onClickCross={() => {
                      // handleFilter("assignee", "");
                      userData?.role === USER_ROLE_CODE &&
                        setParam("taskOwner", "");
                      userData?.role === OWNER_ROLE_CODE &&
                        setParam("userId", "");
                    }}
                  />
                ))}
            </Grid>
          </Box>

          <Flex aI="center">
            {/* Search Input */}
            <Box>
              <Formik initialValues={{ search: "" }}>
                {({ setFieldValue }) => (
                  <Form>
                    <Box w="25.8rem" h="5rem">
                      <FormInput
                        name="search"
                        label="Search"
                        isSearchable
                        isValidationBehaviour={false}
                        onChange={(e) => {
                          setFieldValue("search", e?.currentTarget?.value);
                          setParam("serviceType", e?.currentTarget?.value);
                        }}
                      />
                    </Box>
                  </Form>
                )}
              </Formik>
            </Box>

            {/* Filter */}
            <Box
              color="neutral.500"
              ml="2rem"
              cursor="pointer"
              onClick={() => {
                setIsFilterModalOpen(true);
              }}
              ref={filterRef}
            >
              <MemoFilterIcon height="2.4rem" />
            </Box>
          </Flex>
        </Flex>

        {/* tabular data */}
        <Table
          variant="secondary"
          tableHeader={[
            { name: "Service Name", sortKey: "serviceName" },
            { name: "Description" },
            ...[
              {
                [USER_ROLE_CODE]: { name: "Assignee" },
                [OWNER_ROLE_CODE]: { name: "User" },
              }[userData?.role],
            ],
            // { name: "Due Date"},
            { name: "Status" },
            { name: "Actions" },
          ]}
        >
          <tbody>
            {tasks?.map((task, index) => {
              return (
                <tr key={index}>
                  {/* column: Task */}
                  <td>
                    <Text
                      variant="bodyMedium"
                      color="neutral.700"
                      textTransform="uppercase"
                    >
                      {task?.serviceName}
                    </Text>
                  </td>
                  {/* column: Description */}
                  <td>
                    <Text
                      variant="bodyMedium"
                      color="neutral.700"
                      textTransform="uppercase"
                    >
                      {task?.serviceDetails?.details?.plan?.displayName ||
                        `${
                          SERVICE_TYPE_CODE.CORPORATE_SECRETARIAL?.[
                            task?.serviceDetails?.serviceType
                          ]?.title || ""
                        }` ||
                        `${
                          pricingPlans?.find(
                            (plan) =>
                              plan?._id ===
                              task?.serviceDetails?.cartSummary?.subscription
                          )?.displayName || "-"
                        }`}
                    </Text>
                  </td>

                  {/* column Assignee */}
                  <td>
                    <Flex aI="center" width="fit-content">
                      <ProfileAvatar
                        name={
                          {
                            [USER_ROLE_CODE]: `${task?.taskOwner?.email}`,
                            [OWNER_ROLE_CODE]: `${task?.user?.firstName} ${task?.user?.lastName}`,
                          }[userData?.role]
                        }
                        size="xs"
                        bg="secondary.50"
                        border="none"
                        textProps={{ color: "secondary.900" }}
                      />
                      <Text
                        variant="bodyLarge"
                        fontWeight="600"
                        color="neutral.600"
                        ml="0.6rem"
                      >
                        {
                          {
                            [USER_ROLE_CODE]: `${task?.taskOwner?.email}`,
                            [OWNER_ROLE_CODE]: `${task?.user?.firstName} ${task?.user?.lastName}`,
                          }[userData?.role]
                        }
                      </Text>
                    </Flex>
                  </td>

                  {/* column Due Date */}
                  {/* <td>
                    <Text variant="bodyMedium" color="neutral.700">
                      12-09-22
                    </Text>
                  </td> */}

                  {/* column Status */}
                  <td>
                    <DefaultChips {...getStatusChip(task?.status?.name)} />
                  </td>

                  {/* column Actions */}
                  <td>
                    <Box
                      color="neutral.300"
                      cursor="pointer"
                      onClick={() => setIsActionModalOpen(task?._id)}
                    >
                      <MemoEyeShowIcon width="1.9rem" />
                    </Box>
                  </td>
                </tr>
              );
            })}
            {total === 0 && (
              <tr>
                <td colSpan="10">
                  <Text textAlign="center">No Tasks Found</Text>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        {total > PAGE_SIZE && (
          // <Box mt="2rem">
          <Pagination totalRecords={total} />
          // </Box>
        )}

        {/* ActionModal */}
        {isActionModalOpen && (
          <ActionModal
            isOpen={isActionModalOpen}
            onClose={() => {
              setIsActionModalOpen(false);
              fetchTasks();
            }}
            isRole={userData?.role}
            // task={tasks?.find((task) => task?._id === isActionModalOpen) || {}}
            taskId={isActionModalOpen}
          />
        )}

        {/* filterModal */}
        {isFilterModalOpen && (
          <FilterModal
            isOpen={isFilterModalOpen}
            initFilterState={filterState}
            position={filterRef?.current?.getBoundingClientRect()}
            onClose={() => {
              setIsFilterModalOpen(false);
            }}
            onApplyFilter={(data) => {
              // a new
              queryHydrateHandler(data);
              // console.log("apply filter", data);
              setFilterState(data);
              setIsFilterModalOpen(false);
            }}
            onClearAll={() => {
              setParam("fromDate", undefined);
              setParam("status", undefined);
              setParam("taskOwner", "");
              setParam("userId", "");
              setIsFilterModalOpen(false);
            }}
            role={userData?.role}
          />
        )}
      </Box>
    </Box>
  );
};
