import React, { useCallback, useEffect, useState } from "react";
import { Box } from "atoms/Box";
import { Flex } from "atoms/Flex";
import MemoTickIcon from "assets/icons/Tick";
import Text from "atoms/Text";

export const Checkbox = ({
  children,
  size = 19,
  onChange,
  disabled,
  value = false,
  activeColor = "success.900",
  classes,
  label,
  ...props
}) => {
  const [checked, setChecked] = useState(value);

  useEffect(() => {
    setChecked(value);
  }, [value]);

  const onToggle = useCallback(() => {
    setChecked((prev) => !prev);
  }, []);

  const onChangeHandler = useCallback(() => {
    if (typeof onChange === "function") {
      onChange(!checked);
    }
  }, [onChange, checked]);

  return (
    <Flex
      as="label"
      flexWrap="nowrap"
      aI="center"
      className={classes}
      cursor={disabled ? "not-allowed" : "pointer"}
      {...props}
    >
      <Flex
        mr="1.6rem"
        minWidth={size}
        minHeight={size}
        bR="4px"
        border="1px solid"
        bg={checked ? activeColor : undefined}
        borderColor={checked ? activeColor : "neutral.500"}
        aI="center"
        jC="center"
      >
        <input
          hidden
          type="checkbox"
          checked={checked}
          disabled={disabled}
          onChange={onChange ? onChangeHandler : onToggle}
        />
        <Box color={checked ? "white" : "transparent"}>
          <MemoTickIcon />
        </Box>
      </Flex>
      <Box style={{ userSelect: "none" }}>
        <Text variant="bodyMedium" fontSize="1.4rem" color="neutral.500">
          {label}
        </Text>
      </Box>
    </Flex>
  );
};
