import React from "react";

import { Box } from "atoms";
import { TabHeader } from "organisms";
import { Formik } from "formik";

export const FormManagerLayout = ({ tabHeader, formikProps, children }) => {
  return (
    <Box w="100%" px="3rem" mb="2.4rem">
      {tabHeader && <TabHeader {...tabHeader} />}

      {/* <CartSummary position="absolute" top="0rem" right="3.2rem" zIndex="100" /> */}

      <Box mt="2.4rem">
        {/* input */}
        <Formik {...formikProps}>{children}</Formik>
      </Box>
    </Box>
  );
};
