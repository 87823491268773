import React, { useCallback, useEffect } from "react";

import useService from "contexts/Service";
import { Incorporation as TemplateIncorporation } from "templates";
// import { hydrate, persist } from "utils/persist";
// import { STORAGE } from "utils/constants";

// const cookieUpdateFormData = async (values) => {
//   // first check existing data
//   const existingData = hydrate(STORAGE.INCOPORATION_FORM_STORAGE) || {};
//   persist(STORAGE.INCOPORATION_FORM_STORAGE, {
//     ...existingData,
//     ...values,
//   });
// };

// const cookieHydrateFormData = async () => {
//   const data = [];
//   data.push(hydrate(STORAGE.INCOPORATION_FORM_STORAGE));

//   return data;
// };

export const Incorporation = () => {
  const {
    state: { productPrices },
    actions: {
      getIncorporation,
      createIncorporation,
      updateIncorporation,
      getProductPrices,
      getSubscriptionsById,
    },
  } = useService();

  const formHydration = useCallback(async () => {
    const data = await getIncorporation();

    // const chosenIncorporation = data?.[0];
    const chosenIncorporation = data?.find((inc) => !inc?.paymentStatus);

    const cartDetails = [];

    // subscription feature
    if (chosenIncorporation?.cartSummary?.subscription) {
      const subData = await getSubscriptionsById(
        chosenIncorporation?.cartSummary?.subscription
      );

      cartDetails.push({
        name: subData?.displayName,
        price: subData?.prices?.[0]?.amount,
      });
    }

    // normal features
    chosenIncorporation?.cartSummary?.features?.forEach((feature) => {
      cartDetails?.push(
        productPrices?.find((product) => product?.type === feature?.key) || {}
      );
    });

    const definedData = [
      {
        ...chosenIncorporation,
        cartDetails,
      },
    ];

    return definedData;
  }, [getIncorporation, productPrices, getSubscriptionsById]);

  useEffect(() => {
    if (!productPrices) {
      getProductPrices();
    }
  }, [productPrices]);

  return (
    <TemplateIncorporation
      // hydrateFormData={cookieHydrateFormData}
      // updateFormData={cookieUpdateFormData}
      // createFormData={cookieUpdateFormData}
      hydrateFormData={formHydration}
      // hydrateFormData={getIncorporation}
      updateFormData={updateIncorporation}
      createFormData={createIncorporation}
    />
  );
};
