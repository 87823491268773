import React from "react";
import { useNavigate } from "react-router-dom";

import { Box, Button, Error, Flex, Image, Text } from "atoms";
import { FormInput } from "molecules/FormInput";
import { Formik, Form } from "formik";
import { resetpwdValidationSchema } from "utils/validation";

export const ForgotPassword = ({
  onSubmit,
  error,
  isLoading,
  forgotPasswordErrorStateReset,
}) => {
  const navigate = useNavigate();

  return (
    <>
      <Formik
        initialValues={{
          email: "",
        }}
        onSubmit={onSubmit}
        validationSchema={resetpwdValidationSchema}
      >
        {({ setFieldValue }) => {
          return (
            <Form>
              <Box
                // w="80rem"
                // h="58rem"
                boxShadow="m"
                bR="0.8rem"
                // pl="8rem"
                // pt="8rem"
                py="2.4rem"
                px="3.9rem"
                w="43rem"
                bg="white"
              >
                <Box>
                  <Box>
                    {/* <Box h="4.9rem" w="2.4rem">
                      <Image
                        src="images/logo.png"
                        alt="logo"
                        objectFit="cover"
                      />
                    </Box> */}
                    <Text
                      variant="subtitleLarge"
                      fontWeight="600"
                      // mt="5rem"
                      color="neutral.800"
                    >
                      Forgot password
                    </Text>
                    <Text pt="4rem" variant="bodyMedium" color="neutral.600">
                      Don’t worry, this happens to us from time to time.
                      {/* Enter your registered email below to recieve OTP */}
                    </Text>
                    <Box mt="1.6rem">
                      <FormInput
                        name="email"
                        label="Email"
                        error={error}
                        onChange={(e) => {
                          setFieldValue("email", e?.target?.value);
                          if (error) {
                            forgotPasswordErrorStateReset();
                          }
                        }}
                      />
                      {/* {error && <Error text={error} mt="0.8rem" />} */}
                      <Text pt="4rem" variant="bodyMedium" color="neutral.600">
                        We’ll send you an OTP to reset your password
                      </Text>
                      <Flex mt="4.8rem">
                        <Button
                          variant="outline"
                          w="11rem"
                          mr="1.6rem"
                          onClick={() => navigate(-1)}
                        >
                          Back
                        </Button>
                        <Button
                          type="submit"
                          variant="primary"
                          w="11rem"
                          loading={isLoading}
                        >
                          Send
                        </Button>
                      </Flex>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
