import * as React from "react";

function Pen(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 14 14" fill="none" {...props}>
      <path
        d="M7.85 2.799l3.068 3.07-7.162 7.16H.688V9.96L7.848 2.8zm1.022-1.023L10.406.241a.724.724 0 011.023 0l2.047 2.047a.723.723 0 010 1.022L11.94 4.845 8.872 1.776z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoPen = React.memo(Pen);
export default MemoPen;
