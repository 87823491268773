import React, { useState } from "react";
import { Form, setNestedObjectValues } from "formik";
import { Button, Error, Flex, Line } from "atoms";
import { cwToast, FormToggle } from "molecules";
import FormLayout from "../FormLayout";
import MemoCross from "styles/icons/Cross";
import MemoTick from "styles/icons/Tick";
import FORM_NAMES from "templates/Incorporation/form.names";
import { cartFeatureHandler } from "utils/utilities";
import PRODUCT_PRICES from "utils/princing.constant";
import { incorporationShareholderServiceValidation } from "utils/validation";

const INITIAL_VALUES = {
  usingShareholderDetails: "",
};

export const FormNomineeServices = ({
  breadcrum,
  step,
  onNext,
  onPrevious,
  onSubmit,
  formInitValues,
  ...props
}) => {
  const [submitting, setSubmitting] = useState(false);
  return (
    <FormLayout
      breadcrum={breadcrum}
      step={step}
      onPrevious={onPrevious}
      title="Would you like a Nominee Shareholder from Chartswood?"
      subtitle="Chartswood will help provide a Nominee Shareholder Service to meet your individual needs for shareholders."
      formikProps={{
        initialValues: {
          ...INITIAL_VALUES,
          ...formInitValues,
        },
        enableReinitialize: true,
        validationSchema: incorporationShareholderServiceValidation,
      }}
      {...props}
    >
      {({
        values,
        dirty,
        setFieldValue,
        validateForm,
        setTouched,
        setErrors,
        errors,
      }) => {
        return (
          <>
            <Form>
              <Flex mt="4rem">
                <FormToggle
                  name="usingShareholderDetails"
                  label="Yes"
                  value={true}
                  Icon={MemoTick}
                />
                <FormToggle
                  name="usingShareholderDetails"
                  label="No"
                  value={false}
                  ml="3.2rem"
                  Icon={MemoCross}
                  isWrong
                />
              </Flex>

              {/* {errors?.usingShareholderDetails && (
                <Error text={errors?.usingShareholderDetails} />
              )} */}

              <Line mt="4.8rem" />
              <Button
                type="button"
                w="fit-content"
                px="4rem"
                mt="4rem"
                loading={submitting}
                onClick={() => {
                  validateForm(values)?.then((dataErrorBag) => {
                    const dataErrorBagKeys = Object?.keys(dataErrorBag || {});
                    if (dataErrorBagKeys?.length) {
                      setTouched(setNestedObjectValues(dataErrorBag, true));
                      setErrors(dataErrorBag);
                      dataErrorBag?.usingShareholderDetails &&
                        cwToast("error", dataErrorBag?.usingShareholderDetails);
                    } else {
                      if (dirty) {
                        setSubmitting(true);

                        const withCartValues = JSON.parse(
                          JSON.stringify(values)
                        );
                        const newCartFeatures = cartFeatureHandler(
                          withCartValues?.cartSummary?.features,
                          PRODUCT_PRICES.shareholder_services.name,
                          PRODUCT_PRICES.shareholder_services.type,
                          withCartValues?.usingShareholderDetails
                        );
                        if (withCartValues?.cartSummary) {
                          withCartValues.cartSummary.features = newCartFeatures;
                        } else {
                          withCartValues.cartSummary = {
                            features: newCartFeatures,
                          };
                        }
                        setFieldValue("cartSummary.features", newCartFeatures);

                        onSubmit(withCartValues)
                          .then(() => {
                            onNext(FORM_NAMES.FormNomineeShareholderDetails);
                          })
                          .catch(console.error)
                          .finally(() => {
                            setSubmitting(false);
                          });
                      } else {
                        onNext(FORM_NAMES.FormNomineeShareholderDetails);
                      }
                    }
                  });
                }}
              >
                Next: Shareholder Details
              </Button>
            </Form>
          </>
        );
      }}
    </FormLayout>
  );
};
