import { Box, Grid } from "atoms";
import React from "react";

const AuthLayout = ({ children }) => {
  return (
    <Grid gTC={{ lg: "1fr 1fr" }} height="100vh">
      <Grid bg="white">
        <Box>{children}</Box>
      </Grid>
    </Grid>
  );
};

export default AuthLayout;
