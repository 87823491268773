import React, { useCallback, useState } from "react";
import { Box, Flex, Table, Text } from "atoms";
import { cwToast, DefaultChips } from "molecules";
import { RedirectModal } from "templates/Task/components";
import DocumentVerifiedCard from "../DocumentVerifiedCard";
import {
  getStatusChip,
  uploadFile,
  verifyUploadFileDocumentExtension,
} from "utils/utilities";
import { UploadCart } from "organisms";
import MemoEyeShowIcon from "assets/icons/EyeShow";
import MemoRefreshIcon from "assets/icons/Refresh";
import { FILE_TYPES, STATUS } from "utils/constants";

export const FormOwnerACRA = ({
  serviceDetails,
  statusFlag,
  statusLinkFlag,
  updateTask,
  pending,
  completed,
}) => {
  const [uploadingFile, setUploadingFile] = useState(false);

  const uploadAcknowledgement = useCallback(
    (e) => {
      const file = e.target.files[0];
      if (verifyUploadFileDocumentExtension(file)) {
        setUploadingFile("start");
        uploadFile(file)
          .then(({ location }) => {
            // todo: updateLocation
            setUploadingFile(" updating");
            updateTask({ [statusLinkFlag]: location }).finally(() => {
              setUploadingFile(false);
            });
            cwToast("success", "Acknowledgement Uploaded");
          })
          .catch(console.error)
          .finally(() => setUploadingFile(false));
      } else {
        cwToast(
          "error",
          "Invalid extension found.",
          "Valid extensions are .pdf, .doc/.docx, .png, .jpg/.jpeg, .webp"
        );
      }
    },
    [updateTask, statusLinkFlag]
  );

  return (
    <>
      <Box w="100%">
        <Flex>
          <Text variant="subtitleMedium" color="neutral.700" fontWeight="600">
            ACRA
          </Text>
          <DefaultChips
            {...getStatusChip(serviceDetails?.[statusFlag])}
            ml="2.4rem"
          />
        </Flex>
        {/* <Text color="neutral.600" variant="bodySmall" mt=".8rem">
          Description Text
        </Text> */}
        {/* Redirect Modal */}
        <Box mt="3.2rem">
          <RedirectModal
            title="ACRA"
            description="ACRA is the regulator of business entities, public accountants and corporate service providers. ACRA also plays the role as a facilitator for the development of business entities and the public accountants."
            link="https://www.bizfile.gov.sg/ngbbizfileinternet/faces/oracle/webcenter/portalapp/pages/eServicesListing.jspx"
          />
        </Box>
        {/* preCsAcraLink */}
        {serviceDetails?.[statusLinkFlag] && (
          <Box mt="4.8rem">
            <Table
              tableHeader={[{ name: "Document Name" }, { name: "Action" }]}
            >
              <tbody>
                <tr>
                  <td>
                    <Text variant="bodyLarge" color="neutral.700">
                      ACRA Acknowledgement
                    </Text>
                  </td>
                  <td>
                    <Flex aI="center">
                      <Box
                        color="neutral.400"
                        css={{
                          "&:hover": {
                            color: "neutral.800",
                          },
                        }}
                      >
                        {/* eslint-disable-next-line react/jsx-no-target-blank */}
                        <a
                          href={serviceDetails?.[statusLinkFlag]}
                          target={"_blank"}
                        >
                          <MemoEyeShowIcon />
                        </a>
                      </Box>
                      <Box
                        // color="neutral.300"
                        color={
                          uploadingFile
                            ? uploadingFile?.includes("updating")
                              ? "primary.500"
                              : "secondary.500"
                            : "neutral.300"
                        }
                        cursor="pointer"
                        ml="2.4rem"
                        as="label"
                        type="button"
                        className={uploadingFile ? "rotate-circular" : ""}
                      >
                        <input
                          name="logo"
                          type="file"
                          value={null}
                          accept={FILE_TYPES}
                          onChange={(e) => {
                            // reuploadDocumentHandler(e, doc);
                            uploadAcknowledgement(e);
                          }}
                          style={{ display: "none" }}
                        />
                        <MemoRefreshIcon width="1.6rem" />
                      </Box>
                    </Flex>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Box>
        )}

        {!serviceDetails?.[statusLinkFlag] && (
          <UploadCart
            mt="2.4rem"
            title="Upload ACRA acknowledgement"
            onChange={(e) => {
              uploadAcknowledgement(e);
            }}
          />
        )}
        {/* document verified card */}
        {serviceDetails?.[statusFlag] !== STATUS.completed && (
          <Box mt="2.4rem">
            <DocumentVerifiedCard
              title="Are ACRA regulations finished?"
              subtitle="Facilitate with ACRA regulations or chat with user."
              {...(serviceDetails?.[statusFlag] !== STATUS.pending && {
                pending,
              })}
              completed={completed}
            />
          </Box>
        )}
      </Box>
    </>
  );
};
