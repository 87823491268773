import * as React from "react";

function Whatsapp(props) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clip-path="url(#clip0_510_9)">
        <path
          d="M39.9977 29.8888C39.9977 30.1078 39.991 30.5828 39.9778 30.9497C39.9457 31.8474 39.8745 33.0059 39.7669 33.5337C39.6051 34.3269 39.3609 35.0756 39.0426 35.6988C38.6659 36.4359 38.1854 37.0962 37.6145 37.6661C37.0448 38.2348 36.3851 38.7134 35.6491 39.0886C35.0226 39.4078 34.2693 39.6521 33.4716 39.8132C32.949 39.9187 31.7992 39.9885 30.9072 40.0202C30.5399 40.0333 30.0648 40.0399 29.8466 40.0399L10.1503 40.0368C9.93128 40.0368 9.45636 40.03 9.08944 40.0169C8.19167 39.9847 7.03323 39.9135 6.50538 39.8059C5.71218 39.6441 4.96346 39.4 4.34034 39.0816C3.60315 38.7049 2.94288 38.2245 2.373 37.6535C1.80425 37.0838 1.32565 36.4242 0.950535 35.6881C0.631238 35.0617 0.386941 34.3083 0.225886 33.5106C0.120417 32.988 0.0506128 31.8383 0.018855 30.9462C0.00580811 30.579 -0.000793457 30.1039 -0.000793457 29.8857L0.00233154 10.1893C0.00233154 9.97032 0.00901123 9.49536 0.0221753 9.12844C0.0544019 8.23067 0.125535 7.07223 0.233152 6.54442C0.39491 5.75122 0.63905 5.0025 0.957449 4.37934C1.33413 3.64219 1.81464 2.98188 2.38553 2.412C2.95522 1.84329 3.61491 1.36465 4.35096 0.989497C4.97741 0.670278 5.73081 0.425981 6.5285 0.264927C7.05104 0.159419 8.20085 0.0896143 9.09288 0.0578955C9.46014 0.0448096 9.93526 0.038208 10.1534 0.038208L29.8498 0.0413721C30.0688 0.0413721 30.5437 0.0480518 30.9107 0.0612158C31.8084 0.0934424 32.9668 0.164575 33.4947 0.272192C34.2879 0.43395 35.0366 0.678091 35.6598 0.996489C36.3969 1.37317 37.0572 1.85368 37.6271 2.42457C38.1958 2.99426 38.6744 3.65391 39.0495 4.39C39.3688 5.01645 39.6131 5.76981 39.7742 6.5675C39.8796 7.09008 39.9494 8.23985 39.9812 9.13192C39.9943 9.49918 40.0009 9.97426 40.0009 10.1925L39.9977 29.8888V29.8888Z"
          fill="url(#paint0_linear_510_9)"
        />
        <path
          d="M30.5977 9.50178C27.8895 6.79135 24.288 5.29791 20.4507 5.29639C12.5444 5.29639 6.10958 11.7287 6.10642 19.6351C6.1054 22.1624 6.76591 24.6294 8.02114 26.804L5.98615 34.2348L13.5902 32.2408C15.6854 33.3831 18.0442 33.9851 20.445 33.9862H20.4509C28.3565 33.9862 34.7918 27.553 34.7951 19.6465C34.7966 15.815 33.3059 12.2122 30.5977 9.50178V9.50178ZM20.4509 31.5643H20.446C18.3067 31.5635 16.2084 30.989 14.3779 29.903L13.9425 29.6447L9.43013 30.8281L10.6346 26.4299L10.351 25.979C9.15759 24.0814 8.52728 21.888 8.52821 19.6361C8.53079 13.0646 13.8792 7.71826 20.4555 7.71826C23.6399 7.71947 26.6334 8.96088 28.8844 11.2137C31.1354 13.4664 32.3743 16.461 32.3731 19.6456C32.3706 26.2176 27.0222 31.5643 20.4509 31.5643V31.5643ZM26.9906 22.6379C26.6321 22.4586 24.87 21.5918 24.5415 21.4722C24.2129 21.3526 23.974 21.2928 23.735 21.6516C23.4961 22.0103 22.8093 22.8173 22.6001 23.0564C22.3911 23.2956 22.182 23.3255 21.8236 23.1461C21.4652 22.9668 20.3103 22.5884 18.9413 21.3678C17.8759 20.4178 17.1565 19.2444 16.9475 18.8856C16.7384 18.5269 16.9252 18.333 17.1047 18.1544C17.2659 17.9938 17.4631 17.7359 17.6423 17.5267C17.8215 17.3175 17.8812 17.168 18.0007 16.9288C18.1202 16.6897 18.0604 16.4805 17.9708 16.3011C17.8812 16.1218 17.1644 14.3582 16.8657 13.6408C16.5748 12.9421 16.2793 13.0367 16.0593 13.0257C15.8504 13.0153 15.6113 13.0131 15.3724 13.0131C15.1334 13.0131 14.7451 13.1028 14.4166 13.4615C14.0881 13.8202 13.1622 14.687 13.1622 16.4506C13.1622 18.2141 14.4465 19.9179 14.6257 20.157C14.8049 20.3962 17.153 24.0153 20.7484 25.5672C21.6035 25.9364 22.2711 26.1569 22.7917 26.322C23.6502 26.5946 24.4316 26.5562 25.0492 26.464C25.7378 26.3611 27.1697 25.5972 27.4684 24.7603C27.7671 23.9233 27.7671 23.2059 27.6775 23.0565C27.5879 22.907 27.349 22.8173 26.9906 22.6379V22.6379Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_510_9"
          x1="20"
          y1="0.038208"
          x2="20"
          y2="40.04"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#61FD7D" />
          <stop offset="1" stop-color="#2BB826" />
        </linearGradient>
        <clipPath id="clip0_510_9">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

const MemoWhatsappIcon = React.memo(Whatsapp);
export default MemoWhatsappIcon;
